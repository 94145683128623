/**
 * Checks if a parameter is empty (empty if null, undefined, empty string, empty object or empty array)
 * @param obj {Object}
 * @returns {boolean} true if obj is empty, false otherwise
 */
export function isEmpty(obj) {
  if (typeof obj === "number") {
    return false;
  }
  if (!obj) {
    return true;
  }
  return !!(typeof obj === "object" && Object.keys(obj).length === 0);
}

/**
 * lodash.clonedeep has given us some headaches recently and apparently this is even faster performance-wise
 * @param obj {Object}
 */
export function cloneDeep(obj) {
  return JSON.parse(JSON.stringify(obj));
}

/**
 * Transform a string or number into a string with N decimals
 * @param number {String|Number} to be transformed
 * @param decimals {Number} decimals to display, defaults to 1
 * @returns {string} Formatted number
 */
export const toFixed = (number, decimals = 1) => {
  const value = typeof number === "string" ? parseFloat(number) : number;
  return value.toFixed(decimals);
};

/**
 * Searches up the DOM tree until it finds an element with the class 'targetClass'
 * @param element {DOM} element to start from
 * @param targetClass {String} target class to search for
 * @returns {boolean}
 */
export function hasParent(element, targetClass) {
  let found = false;

  // Check current element first
  if (
    element.getAttribute("class") &&
    element.getAttribute("class").indexOf(targetClass) > -1
  ) {
    return true;
  }

  // Iterate through the parents until we find the target element we're searching for and set
  while (!found && element.parentNode) {
    const parent = element.parentNode;
    const parentClass = parent.getAttribute && parent.getAttribute("class");
    if (parentClass && parentClass.indexOf(targetClass) > -1) {
      // We found the element
      found = parent;
    } else {
      element = parent;
    }
  }

  return found;
}

/**
 * Format the phone number like 111-222-3333 and limit it to 10 chars max
 * Handle both the case for char index 3 and 4 (7 and 8) respectively for backspace pressed
 * @param phoneNumber {String} phone number
 * @returns {String} formatted phone number
 */
export const setPhoneNumberFormat = (phoneNumber) => {
  let newNumber = "";
  let hasDash = false;

  // Remove country code
  if (phoneNumber.indexOf("+1") === 0) {
    phoneNumber = phoneNumber.slice(2);
  }

  for (let i = 0; i < phoneNumber.length; i++) {
    if (i === 3) {
      if (phoneNumber.charAt(i) !== "-") {
        newNumber += "-";
      } else {
        hasDash = true;
      }
    }

    if ((!hasDash && i === 6) || (hasDash && i === 7)) {
      if (phoneNumber.charAt(i) !== "-") {
        newNumber += "-";
      }
    }

    newNumber += phoneNumber.charAt(i);
  }
  return newNumber;
};

/**
 * Handle Phone Number change while typing.
 * @param phoneNumber {String} phone number
 * @returns {String|null} null if phone number should not change, or new phone number otherwise
 */
export const onPhoneNumberChange = (phoneNumber) => {
  let digitCount = 0;

  for (let i = 0; i < phoneNumber.length; i++) {
    if (
      phoneNumber.charAt(i) !== "-" &&
      Number.isNaN(parseInt(phoneNumber.charAt(i), 10))
    ) {
      return null;
    }
    if (!Number.isNaN(parseInt(phoneNumber.charAt(i), 10))) {
      digitCount++;
    }
  }

  if (digitCount === 11) {
    return null;
  }

  return phoneNumber;
};

export const Range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

/**
 * Helper function to get initials from the name and also make sure it doesn't crash if one is empty
 * @param name
 * @returns {string}
 */
export function getInitials(name) {
  const firstNameInitial =
    name.firstName && typeof name.firstName === "string"
      ? name.firstName.slice(0, 1).toUpperCase()
      : "";
  const lastNameInitial =
    name.lastName && typeof name.lastName === "string"
      ? name.lastName.slice(0, 1).toUpperCase()
      : "";
  return `${firstNameInitial}${lastNameInitial}`;
}
