@import "../../../src/scss/variables";

.subscription-label {
  color: $teal;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
}

.email-subscription {
  border: 1px solid rgba(84, 88, 90, 0.2);
  border-radius: 4px;
  height: 40px;
  padding: 0 12px;
  gap: 1rem;
}

.active-status {
  color: $teal;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.change-status {
  color: #ff596f;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
}
