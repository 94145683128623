import React, { Component } from "react";
import PropTypes from "prop-types";
import AliceCarousel from "react-alice-carousel";
import ExifOrientationImg from "react-exif-orientation-img";

import "react-alice-carousel/lib/alice-carousel.css";
import "./style.scss";

class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      stagePadding: props.padding,
      responsive: props.responsive || {
        0: { items: 3 },
        768: { items: 4 },
        1000: { items: 3 },
        1300: { items: 4 },
        1800: { items: 5 },
        2000: { items: 6 },
        2200: { items: 7 },
      },
    };
  }

  onSlideChange = (e) => {};

  onSlideChanged = (e) => {
    this.props.returnActiveSlide && this.props.returnActiveSlide(e.item);
    this.setState({ currentIndex: e.item });
  };

  render() {
    const { currentIndex, stagePadding, responsive } = this.state;
    let galleryItems = this.props.photos.map((photo, p) => (
      <img src={photo.src} key={p} />
    ));

    return (
      <div>
        <AliceCarousel
          items={galleryItems}
          responsive={responsive}
          stagePadding={stagePadding}
          autoPlayInterval={10000}
          autoPlayDirection="rtl"
          autoPlay={false}
          startIndex={currentIndex}
          fadeOutAnimation={true}
          dotsDisabled={true}
          disableAutoPlayOnAction={true}
          slideToIndex={currentIndex}
          onSlideChange={this.onSlideChange}
          onSlideChanged={this.onSlideChanged}
        />
      </div>
    );
  }
}

Slider.propTypes = {
  photos: PropTypes.array,
  padding: PropTypes.object,
  responsive: PropTypes.any,
  returnActiveSlide: PropTypes.func,
};

export default Slider;
