import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import InputText from "../input-text";
// import Validator from '../utils/validator/index';
import "./style.scss";

class InstrumentCheckbox extends Component {
  constructor(props) {
    super();
    this.state = {
      checked: props.checked || false,
      selectedInput: props.selectedInput || null,
      isTextComponent: props.hasInputText,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.checked !== prevState.checked) {
      return { checked: nextProps.checked };
    }
    return null;
  }

  checkForValidationErrors = (value, stateError, validField, listElement) => {
    const valid = true;
    if (valid) {
      this.setState({ [validField]: true });
      this.props.returnInputValue({ ...listElement, value });
    } else {
      this.setState({ [stateError]: "Invalid" });
    }
  };

  render() {
    const {
      customClass,
      clicked,
      labelName,
      disabled,
      hasInputText,
      listElement,
      placeholder,
    } = this.props;
    const { checked, selectedInput, fieldError, fieldValid } = this.state;
    const classes = classNames("input-checkbox flex-row", customClass, {
      "input-checkbox--disabled": disabled,
    });

    return (
      <div className={classes}>
        <input
          style={{ display: "none" }}
          type="checkbox"
          checked={checked}
          onChange={() => {}}
        />
        <div
          onClick={!disabled ? clicked : () => {}}
          style={{ height: "20px" }}
          className="checkbox-icon"
        >
          {checked ? (
            <span className="icon-check-full">
              <span className="path1" />
              <span className="path2" />
            </span>
          ) : (
            <span className="icon-check-empty" />
          )}
        </div>
        <div className="checkbox__label checkbox__label__instrument">
          {labelName}
        </div>
        <InputText
          wrapperClassName="flex-column special-length instrument-input"
          placeholder={placeholder ? placeholder : "Other"}
          error={fieldError}
          isValid={!fieldError && fieldValid}
          onChange={(e) =>
            this.setState({
              selectedInput: e.target.value,
              fieldError: null,
              fieldValid: null,
            })
          }
          onBlur={(e) =>
            selectedInput &&
            this.checkForValidationErrors(
              e.target.value,
              "fieldError",
              "fieldValid",
              listElement
            )
          }
          disabled={!checked}
          value={checked ? selectedInput : null}
        />
      </div>
    );
  }
}

InstrumentCheckbox.propTypes = {
  checked: PropTypes.bool,
  clicked: PropTypes.func,
  labelName: PropTypes.any,
  disabled: PropTypes.bool,
  customClass: PropTypes.string,
  hasInputText: PropTypes.bool,
  selectedInput: PropTypes.string,
  listElement: PropTypes.object,
  returnInputValue: PropTypes.func,
};

export default InstrumentCheckbox;
