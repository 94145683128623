import React from "react";
import PropTypes from "prop-types";
import "./Input.scss";
const Input = ({
  label,
  inputType = "text",
  value = "",
  onChange = () => {},
  placeholder = "",
  name = "",
  inputClassName,
  ...rest
}) => {
  return (
    <div {...rest} className="input-container">
      { label && <label className="input-label">{label}</label> }
      <input
        name={name}
        type={inputType}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={`input-field ${inputClassName}`}
      />
    </div>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  inputType: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  inputClassName: PropTypes.string,
};

export default Input;
