@import '../../../../src/scss/utilities';

.main-header-wrapper {
    justify-content: space-between;
    overflow: visible;
    box-shadow: 0 1px 10px 0 rgba(84,88,90,0.3);
    height: 100%;
    align-items: unset !important;
}

.main-header {
    background: #fff;
    height: 81px;
    user-select: none;
    z-index: 100;
    width: 100%;

    align-items: center;
    justify-content: center;

    &.home-pages-white-theme, &.login-page {
        background-color: #fff;
        left: 0;
        right: 0;
        transition: all 0.5s ease;
        padding: 0 0 0 80px;
        .date-spot-logo img {
            height: 60px;
            transition: all 0.5s ease;
        }
    }

    &__tab {
        height: 100%;
        &.date-spot-logo {
            // position: absolute;
            // left: 80px;
            margin-right: 20px;
            img {
                cursor: pointer;
                height: 60px;
            }
        }
        &.hide-tab {
            display: none;
        }
        // &.login {
        //     margin: 0 0 0 auto;
        //     padding: 2px;
        // }
        &.team {
            min-width: 163px;
        }
    }

    &__item {
        color: #53585A;
        cursor: pointer;
        font-family: Montserrat, sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        height: 100%;
        letter-spacing: 1.4px;
        padding: 0 30px;


        .tab-icon {
            margin-right: 20px;
        }

        &:hover {
            color: #ffbe00;
            .tab-icon:before {
                color: #ffbe00;
            }

        }

        &.selected-tab {
            //background: #FFD758;
            border-bottom-color: #FFD758;
            border-bottom-width: 5px;
            border-bottom-style: solid;
            font-weight: 600 !important;
            color: #53585A;
            .tab-icon:before {
                color: #53585A;
            }
        }
    }

    @include for-lg {
        &__item {
            font-size: 14px;
            padding: 0 16px;
        }
    }

    &.userDashboard {
        justify-content: unset;
    }

    &.matchmakerDashboard, &.adminDashboard, &.userDashboard {
        // box-shadow: 0 1px 10px 0 rgba(84,88,90,0.3);
        padding: 0 0 0 80px;

        .main-header__tab.date-spot-logo {
                // margin-right: 88px;
         }

        .main-header__item {
            padding: 0 20px;
        }
    }
    &.adminDashboard {
        .main-header__tab.date-spot-logo {
            // margin-right: 143px;
        }
        .main-header__item {
            width: 150px;
        }
    }
    &.matchmakerDashboard {
        .main-header__item {
            max-width: 150px;
            text-align: center;
        }
        @media screen and (max-width: 1310px) {
            .main-header__item {
                padding: 0 10px;
                font-size: 12px;
                max-width: 100px;
                .tab-icon {
                    margin-right: 5px;
                    font-size: 14px;
                }
            }
        }
    }
    &.userDashboard {
        .main-header__tab.date-spot-logo {
            margin-right: 253px;
        }
    }

    @media screen and (max-device-width: 1310px) {
        &__tab {
            &.date-spot-logo {
                margin-right: 5%;
            }
        }
        &__item {
            padding: 0 15px;
        }
    }

    @media screen and (max-device-width: 1024px) {
        &.home-pages-white-theme, &.login-page {
            padding-left: 80px;
        }
        &__tab {
            &.date-spot-logo {
                margin-right: 5%;
            }
            &.team {
                min-width: 30px;
            }
        }
        &__item {
            padding: 0 10px;
        }
    }
}
