import React from "react";
import { toggleMedia, setGlobal, getGlobal, Setting } from "launchpad";
import { AccordionPanel, Accordion } from "widgets";

const settings = {
  "Social Media": [
    { type: "text", label: "facebook" },
    { type: "text", label: "twitter" },
    { type: "text", label: "instagram" },
    { type: "text", label: "youtube" },
  ],
};

export class AdminPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      activeSection: "",
    };
  }

  show() {
    this.setState({ open: true });
  }

  hide() {
    this.setState({ open: false });
  }

  componentDidMount() {
    setGlobal({ AdminPanel: this });
  }

  render() {
    let settingElements = [];
    for (let key in settings) {
      settingElements.push(
        <AccordionPanel label={key} key={key} accordion={this.accordion}>
          {settings[key].map((s) => {
            return <Setting type={s.type} label={s.label} key={s.label} />;
          })}
        </AccordionPanel>
      );
    }
    // only render if user is signed in as admin
    return getGlobal("is_admin") ? (
      <section className={"admin-panel" + (this.state.open ? " open" : "")}>
        <div className="admin-options">
          <Accordion
            ref={(acc) => {
              this.accordion = acc;
            }}
          >
            {settingElements}
          </Accordion>
          <div className="text-center">
            <button onClick={() => this.setState({ open: false })}>
              Close
            </button>
          </div>
        </div>
      </section>
    ) : (
      <div />
    );
  }
}
