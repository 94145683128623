import React from "react";
import plusIconSvg from "../../images/plus-icon.svg";



export const PlusIcon = ({
   width = 20,
   height = 20,
   style = {},
   className,
   ...rest
 }) => {
  return (
    <img
      style={{ width, height, ...style }}
      src={plusIconSvg}
      alt="Plus icon"
      className={className}
      {...rest}
    />
  );
};
