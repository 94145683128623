export const APPRECIATION_OPTIONS = [
  {
    label: "Words of Affirmation",
    key: "wordsOfAffirmation",
    options: [1, 2, 3, 4, 5],
  },
  {
    label: "Acts of Service",
    key: "actsOfService",
    options: [1, 2, 3, 4, 5],
  },
  {
    label: "Gifts",
    key: "gifts",
    options: [1, 2, 3, 4, 5],
  },
  {
    label: "Receiving Gifts",
    key: "receivingGifts",
    options: [1, 2, 3, 4, 5],
  },
  {
    label: "Quality Time",
    key: "qualityTime",
    options: [1, 2, 3, 4, 5],
  },
  {
    label: "Physical Touch",
    key: "physicalTouch",
    options: [1, 2, 3, 4, 5],
  },
];

export const LONGEST_RELATIONSHIP_OPTIONS = [
  {
    label: "Less than 3 months",
    value: "lessThan3Months",
  },
  {
    label: "3-6 months",
    value: "3To6Months",
  },
  {
    label: "7-11 months",
    value: "7To11Months",
  },
  {
    label: "1-2 years",
    value: "1To2Years",
  },
  {
    label: "2-5 years",
    value: "2To5Years",
  },
  {
    label: "Over 5 years",
    value: "over5Years",
  },
];

export const LANGUAGE_PROFICIENCY_OPTIONS = ["Basic", "Intermediate", "Fluent"];

export const HABITS = [
  { label: "Arrogance", value: "arrogance" },
  { label: "Avoidant or insecure attachment style", value: "avoidant" },
  { label: "Bossiness", value: "bossiness" },
  { label: "Insecurity with self", value: "insecurityWithSelf" },
  { label: "Jealousy", value: "jealousy" },
  {
    label: "Lack of financial contribution",
    value: "lackOfFinancialContribution",
  },
  { label: "Laziness", value: "laziness" },
  { label: "Materialism", value: "materialism" },
  { label: "Messiness / disorganization", value: "messinessDisorganization" },
  { label: "Negativity", value: "negativity" },
  { label: "Extreme cleanliness", value: "extremeCleanliness" },
  { label: "Perpetual lateness", value: "perpetualLateness" },
  { label: "Shyness / awkwardness", value: "shynessAwkwardness" },
  { label: "Stinginess", value: "stinginess" },
  { label: "Stubbornness", value: "stubbornness" },
  { label: "Ignorance (about the world)", value: "ignorance" },
  { label: "Workaholism", value: "workaholism" },
  { label: "Yelling tendencies", value: "yellingTendencies" },
  { label: "Other", value: "Other" },
];

export const BLIND_DATES_OPTIONS = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
  {
    label: "Maybe",
    value: "maybe",
  },
];
