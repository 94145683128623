.user-response-content {
    color: #54585A;
    font-size: 30px;
    font-family: Montserrat, sans-serif;
    padding: 100px;

    &__text {
        padding-top: 30px;
        white-space: pre-wrap;
    }
}