import React, { useState, useEffect } from "react";
import { getGlobal, setGlobal } from "launchpad";
import InputRange from "react-input-range";
import Radio from "@material-ui/core/Radio";
import Input from "@material-ui/core/Input";
import "react-input-range/lib/css/index.css";
import Select from "react-select";
import Tooltip from "src/components/Tooltip";
import memberSchema from "../pages/dashboards/member-data";
import { Icon, makeStyles } from "@material-ui/core";
import Checkbox from "_old/src/components/check-box";


// get a filter by name
export const getFilter = (name) => {
  for (let category of Object.keys(memberSchema)) {
    for (let filter of Object.keys(memberSchema[category].attributes)) {
      if (filter === name) return memberSchema[category].attributes[name];
    }
  }
};

// get complete list of available filters, ignoring category
export const getAllFilters = () => {
  let ret = [];
  for (let category of Object.keys(memberSchema)) {
    for (let filter of Object.keys(memberSchema[category].attributes)) {
      if (memberSchema[category].attributes[filter].filterType) {
        const retNames = ret.map((x) => x.name);
        if (!retNames.includes(filter))
          ret.push({
            ...memberSchema[category].attributes[filter],
            name: filter,
          });
      }
    }
  }
  return ret;
};

// get value of a filter by name
export const getFilterValue = (name) => {
  if (name === "user_id") {
    const filter = getGlobal("userFilters") || {};

    // as the input field for filtering by user_id returns a 0 when empty, we need to set it to an empty string
    // so that the filter is not applied
    if (filter["user_id"] === 0) {
      setFilters({ user_id: '' });
    }

    return (getGlobal("userFilters") || {})[name];
  }
  return (getGlobal("userFilters") || {})[name];
};

// set filters global
export const setFilters = (f, options) => {
  options = options || {};
  const { overwrite } = options;
  let updated = overwrite ? f : { ...getGlobal("userFilters"), ...f };
  for (let key of Object.keys(updated)) {
    if (Array.isArray(updated[key]) && !updated[key].length) {
      delete updated[key];
    }
    if (!updated[key] && updated[key] !== 0) delete updated[key];
  }
  setGlobal({ userFilters: updated });
};

// individual checkbox field
const CheckboxField = ({ label, onChange, value }) => {
  return (
      <Checkbox checked={!!value} selectedInput={value} clicked={onChange} labelName={label} customClass={"checkbox"} />
  )
};

// filter containing several checkboxes, returns array on change
const CheckboxFilter = ({
                          options,
                          label,
                          name,
                          writeIn,
                          minLabel,
                          maxLabel,
                          hideLabel,
                          helpText
                        }) => {
  const [writeInValue, setWriteIn] = useState("");
  const [writeInSelected, setWriteInSelected] = useState(false);
  const currentUser = getGlobal("activeUser")

  const values = getFilterValue(name) || [];

  const getSelected = () => {
    let selected = [...values.filter((x) => options.includes(x))];
    if (writeInSelected && writeInValue && !selected.includes(writeInValue))
      selected.push(writeInValue);
    return [...new Set(selected)];
  };

  const toggle = (label) => {
    const selected = getSelected();
    if (selected.includes(label)) {
      setFilters({ [name]: selected.filter((x) => x !== label) });
    } else {
      setFilters({ [name]: selected.concat(label) });
    }
  };

  useEffect(() => {
    let writeInFound = false;
    for (let v of values) {
      if (!options.includes(v)) {
        writeInFound = true;
        setWriteInSelected(true);
        if (writeInValue !== v) {
          setWriteIn(v);
        }
      }
    }
    if (!writeInFound) {
      setWriteInSelected(false);
    }
  }, [JSON.stringify(values)]);

  useEffect(() => {
    setFilters({ [name]: getSelected() });
  }, [writeIn, writeInSelected]);

  return (
      <div className="checkboxes">
        {!hideLabel &&
            <div className="filter-label">
              <div className="filter-label-text">{label}</div>
              {helpText && (
                  <Tooltip
                      className="filter-label-question-icon"
                      text={helpText}
                      popOverClassName="filter-label-popOver-tooltip"
                  />
              )}
            </div>}
        <div
            className={`options ${minLabel || maxLabel ? "range" : ""} `}
        >
          {/* add to the div above ^^ if there are only 2 values: ${name === "clientStatus" && "half-grid"} */}
          {minLabel && <span className="range-label">{minLabel}</span>}
          {name === "clientStatus"
              ? (options || []).map((x) => {

                const mmLabel = {
                  "Candidate": "Candidate (You pay to connect)",
                  "Client": "Client (Free for you to connect)"
                }

                return (
                    <CheckboxField
                        key={x}
                        label={currentUser?.role === "admin" ? x : mmLabel[x]}
                        onChange={() => toggle(x)}
                        value={values.includes(x)}
                    />
                );
              })
              : (options || []).map((x) => {
                return (
                    <CheckboxField
                        key={x}
                        label={x}
                        onChange={() => toggle(x)}
                        value={values.includes(x)}
                    />
                );
              })}
          {writeIn && (
              <CheckboxField
                  label={
                    <input
                        value={writeInValue}
                        onChange={(e) => setWriteIn(e.target.value)}
                        placeholder="Other"
                    />
                  }
                  value={writeInSelected}
                  onChange={() => setWriteInSelected(!writeInSelected)}
              />
          )}
          {maxLabel && <span className="range-label">{maxLabel}</span>}
        </div>
      </div>
  );
};

// filter that returns a range of values on change (e.g. {min: x, max: y})
const RangeFilter = ({ min, max, label, name, translateValue, hideLabel }) => {
  const value = getFilterValue(name) || { min, max };
  const setValue = (v) => setFilters({ [name]: v });
  const setRange = (v) => {
    if ((v.min || v.max || "").length <= ("" + max).length) {
      if (typeof v.min != "undefined")
        setValue({
          ...value,
          min: parseInt(v.min) || "",
        });
      if (typeof v.max != "undefined")
        setValue({
          ...value,
          max: parseInt(v.max) || "",
        });
    }
  };
  return (
      <div>
        {!hideLabel && <div className="filter-label">{label}</div>}
        <InputRange
            step={1}
            maxValue={max}
            minValue={min}
            value={value}
            // onChangeComplete={val => this.changeFilters([val], 'ageValue')}
            onChange={(val) => setValue(val)}
        />
        <div className="range-inputs">
          {translateValue ? (
              <div className="range-value">{translateValue(value.min)}</div>
          ) : (
              <input
                  value={value.min}
                  onChange={(e) => setRange({ min: e.target.value })}
              />
          )}
          {translateValue ? (
              <div className="range-value">{translateValue(value.max)}</div>
          ) : (
              <input
                  value={value.max}
                  onChange={(e) => setRange({ max: e.target.value })}
              />
          )}
        </div>
      </div>
  );
};

const SelectFilter = ({ label, name, options, hideLabel, isMulti }) => {
  const [optionSet, setOptions] = React.useState([]);
  const value = getFilterValue(name) || [];
  const selected = optionSet.filter((x) => value.includes(x.value));

  const loadOptions = async () => {
    if (typeof options == "function") {
      setOptions(await options());
    } else {
      setOptions(options);
    }
  };

  useEffect(() => {
    loadOptions();
  }, []);

  return (
      <div>
        {!hideLabel && <div className="filter-label">{label}</div>}
        <Select
            onChange={(v) => setFilters({ [name]: v.map((x) => x.value) })}
            isMulti={isMulti}
            isSearchable
            options={optionSet}
            value={selected}
            placeholder={"Select or Search"}
        />
      </div>
  );
};

// radio button filter, returns the currently selected option on change
const RadioFilter = ({ label, name, options, hideLabel }) => {
  const value = getFilterValue(name);
  return (
      <div>
        {!hideLabel && <div className="filter-label">{label}</div>}
        <div className="radio-inputs">
          {options.map((x) => {
            return (
                <label key={x}>
                  <Radio
                      checked={value === x}
                      onChange={() => { setFilters({ [name]: x }) }}
                  />{" "}
                  {x}
                </label>
            );
          })}
        </div>
      </div>
  );
};

const InputFilter = ({ label, name, hideLabel }) => {
  return (
      <div>
        {!hideLabel && <div className="filter-label">{label}</div>}
        <div className="radio-inputs">
          <Input
              onChange={(e) => setFilters({ [name]: Number(e.target.value) })}
              value={getFilterValue(name) || ""}
              disableUnderline={true}
          />
        </div>
      </div>
  );
};

// generic container to pull any filter by name, e.g. <Filter name={'gender'} />
export function Filter({ name, hideLabel }) {
  const filter = getFilter(name);
  let props = { ...filter, name, hideLabel };
  if (filter.filterType === "select-multi") props.isMulti = true;
  if (filter.filterLabel) props.label = filter.filterLabel;
  if (filter.helpText) props.helpText = filter.helpText;
  const components = {
    checkboxes: CheckboxFilter,
    range: RangeFilter,
    radio: RadioFilter,
    select: SelectFilter,
    "select-multi": SelectFilter,
    "input-text": InputFilter,
  };
  const C = components[filter.filterType];
  return (
      <div className="datespot-filter">
        <C {...props} />
      </div>
  );
}

// renders a list of filter widgets within given category
export const FilterCategory = ({ name, hideLabel }) => {
  const category = memberSchema[name];
  if (!category) return <div />;
  const filters = Object.keys(category.attributes).filter(
      (x) => category.attributes[x].filterType
  );
  if (!filters.length) return <span />;
  return (
      <div
          className={
              "filter-category filter-category-" +
              category.label.replace(/\s+/g, "-").toLowerCase()
          }
      >
        {!hideLabel && <h3>{category.label}</h3>}
        <div className="filters">
          {filters.map((filter) => (
              <Filter key={filter} name={filter} />
          ))}
        </div>
      </div>
  );
};

// renders all filter widgets, split up by category
export const AllFilters = () => (
    <div className="all-filters">
      {Object.keys(memberSchema)
          .filter((key) => {
            const hasFilter = Object.keys(memberSchema[key].attributes).reduce(
                (isFilter, att) => {
                  return isFilter || !!memberSchema[key].attributes[att].filterType;
                },
                false
            );
            return hasFilter;
          })
          .map((catName) => (
              <FilterCategory key={catName} name={catName} />
          ))}
    </div>
);

// alias to allow "all" as a category
export default function Filters({ type, showLabel }) {
  if (type === "all") return <AllFilters />;
  return <FilterCategory name={type} hideLabel={!showLabel} />;
}
