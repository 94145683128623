import React from "react";
import {
  toggleMedia,
  setGlobal,
  getGlobal,
  setSetting,
  getSetting,
  Input,
} from "launchpad";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";

const Container = styled.div`
  margin: 10px 0;
  > div {
    display: flex;
  }
`;

export class Setting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      initialized: false,
      editing: false,
    };
  }

  componentDidMount() {
    this.checkValue();
  }

  componentDidUpdate() {
    this.checkValue();
  }

  get name() {
    return this.props.name || this.props.label.replace(/ /g, "_");
  }

  checkValue() {
    if (!this.state.value && !this.state.editing && !this.state.initialized) {
      this.setState({
        value: getSetting(this.name) || this.props.default,
        initialized: true,
      });
    }
  }

  update(val) {
    this.setState({ value: val }, () => {
      setSetting({ [this.name]: this.state.value });
    });
  }

  render() {
    let components = {
      text: (
        <Input
          label={this.props.label}
          onChange={(e) => this.update(e.target.value)}
          value={this.state.value || ""}
          onFocus={() => this.setState({ editing: true })}
        />
      ),
    };
    return (
      <Container className="setting">
        {components[this.props.type || "text"]}
      </Container>
    );
  }
}
