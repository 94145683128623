import {
  LOAD_MATCHMAKERS,
  APPROVED_MATCHMAKER,
  LOAD_PLANS,
  SAVING,
  PLAN_SAVED,
  LOAD_USERS_COUNT,
  SIGN_UP_MATCH_MAKER,
  REJECT_MATCHMAKER,
  ADD_MM_FILTER,
  SET_MM_SHOW
} from "../constants/actionTypes";

const initialState = {
  filters: { query: "" },
  pendingMatchMakers: [],
  approvedMatchMakers: [],
  totalMembersCount: null,
  premiumMembersCount: null,
  freeRouteMembersCount: null,
  plans: null,
  saving: false,
  showApprovedMM: true,
};

export default function matchmakersReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_MATCHMAKERS: {
      return {
        ...state,
        pendingMatchMakers: action.response.filter((item) => !item.approved),
        approvedMatchMakers: action.response.filter((item) => item.approved),
      };
    }

    case APPROVED_MATCHMAKER: {
      if (action.response.error) {
        return state;
      }
      return {
        ...state,
        approvedMatchMakers: [
          ...state.approvedMatchMakers,
          { ...action.response },
        ],
        pendingMatchMakers: state.pendingMatchMakers.filter(
          (item) => item._id !== action.response.id
        ),
      };
    }

    case REJECT_MATCHMAKER: {
      if (action.response.error) {
        return state;
      }
      return {
        ...state,
        approvedMatchMakers: state.approvedMatchMakers.filter(
          (item) => item._id !== action.response.id
        ),
        pendingMatchMakers: state.pendingMatchMakers.filter(
          (item) => item._id !== action.response.id
        ),
      };
    }

    case LOAD_PLANS: {
      return {
        ...state,
        plans: action.response,
      };
    }

    case SAVING: {
      return {
        ...state,
        saving: true,
      };
    }

    case PLAN_SAVED: {
      const plans = [...state.plans];
      const planIdx = plans.findIndex((plan) => plan.id === action.response.id);
      if (planIdx > -1) {
        plans[planIdx] = action.response;
      } else {
        plans.unshift(action.response);
      }
      return {
        ...state,
        plans,
        saving: false,
      };
    }

    case LOAD_USERS_COUNT: {
      const {
        totalMembersCount,
        premiumMembersCount,
        freeRouteMembersCount,
        freemiumMembersCount,
        historicalMembersCount,
      } = action.response;
      return {
        ...state,
        totalMembersCount,
        premiumMembersCount,
        freeRouteMembersCount,
        freemiumMembersCount,
        historicalMembersCount,
      };
    }

    case SIGN_UP_MATCH_MAKER: {
      if (action.response) {
        const { matchMaker } = action.response;
        if (matchMaker.approved) {
          return {
            ...state,
            approvedMatchMakers: [...state.approvedMatchMakers, matchMaker],
          };
        }
        return {
          ...state,
          pendingMatchMakers: [...state.pendingMatchMakers, matchMaker],
        };
      }
      return { ...state };
    }

    case ADD_MM_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.filter,
        },
      };
    }

    case SET_MM_SHOW:{
      return {
        ...state,
        showApprovedMM: action.showMatchmakers,
      };
    }

    default:
      return state;
  }
}
