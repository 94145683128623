import LoadingModal from "_old/src/components/loading-modal";
import React, { useEffect } from "react";
import MMList from "./mmList/MMList";
import AdminSingles from "./adminSingles/AdminSingles";
import { bindActionCreators } from "redux";
import * as generalActions from "_old/src/actions/generalActions";
import * as adminActions from "_old/src/actions/adminActions";
import { connect } from "react-redux";
import "./AdminDashboard.scss"
import PlanList from "./planList/PlanList";
import { ADMIN_URLS_TO_TABS } from "_old/src/constants";
import { FilterSideBar } from "../filters/FIlterSideBar";
import { ToastContainer } from "react-toastify";
import MediaQueryProvider from "src/components/MediaQueryProvider";
import { FOR_TABLET_DOWN_QUERY } from "src/data/constants";
import theme from "src/styles/theme";
import UserInfoModal from "src/components/Modal/CustomModals/UserInfoModal/UserInfoModal";
import Metrics from "./metrics/Metrics";
import MMListSideBar from "./mmList/MMListSideBar";

function AdminDashboard({ history, generalReducer, generalActions, adminReducer, adminActions }) {
    const { selectedTab, loadingModal, requestedUserData, showUserInfo } = generalReducer;
    const { closeUserInfoModal } = generalActions;

    useEffect(() => {
        const urlSection = ADMIN_URLS_TO_TABS[history.location.pathname];
        if (urlSection) {
            generalActions.setSelectedHeaderTab(urlSection);
        }
    }, [])



    const renderLists = (isTabletDown) => ({
        // "requests": <MMList  adminReducer={adminReducer} />,
        "singles": <AdminSingles adminActions={adminActions} isTabletDown={isTabletDown} />,
        "matchmakers": <MMList adminActions={adminActions} isTabletDown={isTabletDown} filters={adminReducer.filters} adminReducer={adminReducer} />,
        "plans": <PlanList
            adminReducer={adminReducer}
            history={history} />,
        "metrics": <Metrics adminReducer={adminReducer} generalActions={generalActions} />,
    })

    const sideBars = (isTabletDown) => ({
        // "requests": <FilterSideBar isTabletDown={isTabletDown} />,
        "singles": <FilterSideBar isTabletDown={isTabletDown} />,
        "matchmakers": <MMListSideBar adminActions={adminActions} adminReducer={adminReducer} />,
        // "plans": <FilterSideBar isTabletDown={isTabletDown} />,
        // "metrics": <FilterSideBar isTabletDown={isTabletDown} />,
    })
    return (
        <>
            <ToastContainer position="top-right" />
            <MediaQueryProvider query={FOR_TABLET_DOWN_QUERY}>
                {(isTabletDown) => (
                    <div className={loadingModal.show ? "loading-modal-opened" : ""}>

                        {!isTabletDown
                            ?
                            <div className="admin-dashboard-container flex-column">
                                <div className={"flex-row admin-dashboard-container__sections"}>
                                    <div className="admin-dashboard-container__left-panel ">
                                        <FilterSideBar isTabletDown={isTabletDown} />
                                    </div>
                                    <div className="admin-dashboard-container__right-panel">
                                        {selectedTab ? renderLists(isTabletDown)[selectedTab] : renderLists(isTabletDown)["singles"]}
                                    </div>
                                </div>

                            </div>
                            : <div className="admin-dashboard-container dashboard" style={{ padding: 0 }}>
                                {selectedTab ? sideBars(isTabletDown)[selectedTab] : sideBars(isTabletDown)["singles"]}
                                <div style={{ alignItems: "center", flex: 1, backgroundColor: theme.colors.secondaryBlue, padding: "8px", display: "flex", justifyContent: "space-between", height: "5svh" }}>
                                    <span style={{ fontSize: "20px", fontWeight: 600, color: theme.colors.white, textTransform:"capitalize" }}>{selectedTab ?? "Singles"}</span>
                                    <span style={{ fontSize: "15px", fontWeight: 400, color: theme.colors.white }}>Rated</span>
                                </div>
                                {selectedTab ? renderLists(isTabletDown)[selectedTab] : renderLists(isTabletDown)["singles"]}
                            </div>}

                        {loadingModal.show && (
                            <LoadingModal
                                error={loadingModal.error}
                                loading={loadingModal.loading}
                            />
                        )}


                        <UserInfoModal
                            activeUser={requestedUserData}
                            showProfile={showUserInfo}
                            onClose={closeUserInfoModal}
                            adminActions={adminActions}
                            generalActions={generalActions}
                            role="admin" />

                    </div>
                )}
            </MediaQueryProvider>
        </>
    )

}

const mapDispatchToProps = (dispatch) => {
    return {
        generalActions: bindActionCreators(generalActions, dispatch),
        adminActions: bindActionCreators(adminActions, dispatch),
    };
};

const mapStateToProps = (state) => {
    return {
        generalReducer: state.generalReducer,
        adminReducer: state.adminReducer,
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard)