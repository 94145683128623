@import "src/scss/utilities.scss";

.main-content__layout {
  display: flex;
  flex-direction: column;
  padding: 0 5rem;
  overflow-x: clip;
  height: fit-content;

  &.main-content__w-border {
    border-bottom: 1px solid #d7d8db;
  }

  .main-content__large {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 80rem;
    margin-right: auto;
    margin-left: auto;
  }
}

// Tablet
@include for-tablet {
  .main-content__layout {
    padding: 0 48px;
  }
}

// Mobile
@include for-mobile {
  .main-content__layout {
    padding: 0 1.25rem;
  }
}