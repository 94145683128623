import UserSession from "_old/src/components/user-session";
import { REGIONS } from "src/constants/onboarding-data";
import { statesIndex } from "src/data/states";

export const redirectToHome = () => {
  window.location.href = UserSession.hasValidToken()
      ? window.location.origin
      : process.env.MARKETING_SITE_URL;
};

export const capitalize = (string) =>
    string?.charAt(0).toUpperCase() + string?.slice(1);

export const removeDiacritics = (string) =>
    string?.normalize("NFD").replace(/\p{Diacritic}/gu, "");

export const sortArrayByStringField = (array, field, options) =>
    array.sort((a, b) => {
      if (options.lastElement === a[field]) return 1;
      if (options.lastElement === b[field]) return -1;
      return a[field].localeCompare(b[field]);
    });

export const compareStringContents = (a, b) =>
    a?.trim()?.toLowerCase() === b?.trim()?.toLowerCase();

export function downloadImage(dataUrl, filename) {
  const a = document.createElement("a");
  const extension = dataUrl.url.split(".").pop().toLowerCase();
  a.setAttribute("download", `${filename}.${extension}`);
  a.setAttribute("href", dataUrl.url);
  a.setAttribute("target", "_blank");
  a.click();
}
/**
 * Human readable elapsed or remaining time (example: 3 minutes ago)
 * @param  {Date|Number|String} date A Date object, timestamp or string parsable with Date.parse()
 * @param  {Date|Number|String} [nowDate] A Date object, timestamp or string parsable with Date.parse()
 * @param  {Intl.RelativeTimeFormat} [trf] A Intl formater
 * @return {string} Human readable elapsed or remaining time
 * @author github.com/victornpb
 * @see https://stackoverflow.com/a/67338038/938822
 */
export const fromNow = (
    date,
    nowDate = Date.now(),
    rft = new Intl.RelativeTimeFormat(undefined, { numeric: "auto" })
) => {
  const SECOND = 1000;
  const MINUTE = 60 * SECOND;
  const HOUR = 60 * MINUTE;
  const DAY = 24 * HOUR;
  const WEEK = 7 * DAY;
  const MONTH = 30 * DAY;
  const YEAR = 365 * DAY;
  const intervals = [
    { ge: YEAR, divisor: YEAR, unit: "year" },
    { ge: MONTH, divisor: MONTH, unit: "month" },
    { ge: WEEK, divisor: WEEK, unit: "week" },
    { ge: DAY, divisor: DAY, unit: "day" },
    { ge: HOUR, divisor: HOUR, unit: "hour" },
    { ge: MINUTE, divisor: MINUTE, unit: "minute" },
    { ge: 30 * SECOND, divisor: SECOND, unit: "seconds" },
    { ge: 0, divisor: 1, text: "just now" },
  ];
  const now =
      typeof nowDate === "object"
          ? nowDate.getTime()
          : new Date(nowDate).getTime();
  const diff =
      now - (typeof date === "object" ? date : new Date(date)).getTime();
  const diffAbs = Math.abs(diff);
  for (const interval of intervals) {
    if (diffAbs >= interval.ge) {
      const x = Math.round(Math.abs(diff) / interval.divisor);
      const isFuture = diff < 0;
      return interval.unit
          ? rft.format(isFuture ? x : -x, interval.unit)
          : interval.text;
    }
  }
};

const GENDER_INTEREST_LABELS_MAP = {
  Female: "Women",
  Male: "Men",
  Other: "Other",
};

/*
  true if:
  - user's gender is Other
  - user's interest is Other
  - user's interest is the same gender
*/
export const shouldRenderLGBTLabel = (user) =>
    user?.gender &&
    (user.gender.label === "Other" ||
        user.interestedIn?.some(
            (gender) => gender.label === GENDER_INTEREST_LABELS_MAP[user.gender.label]
        ));

export const shouldRenderPolyLabel = (user) => {
  if (user?.relationshipType) {
    if (
        (user.relationshipType.find((other) => other.label === "Other") &&
            user.relationshipType.length === 1) ||
        (user.relationshipType.find((other) => other.label === "Poly") &&
            user.relationshipType.length === 1) ||
        user.relationshipType.find(
            (type) => type.label === "Ethically non-monogamous"
        )
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const shouldRenderPausedLabel = (user) => {
  if (user?.accountStatus) {
    if (user.accountStatus === "paused") {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const insertAt = (array, index, ...items) => [
  ...array.slice(0, index),
  ...items,
  ...array.slice(index),
];

export function formatHeight(inches) {
  const inch = inches % 12;
  const feet = Math.floor(inches / 12);
  return `${feet}'${inch}"`;
}

export function getRegion(user) {
  if (!user.regionData) user.regionData = [];
  let closestRegion = {};

  for (let region of user.regionData) {
    if (!closestRegion.distance || region.distance < closestRegion.distance) {
      closestRegion = region;
    }
  }
  let label;
  if (closestRegion.distance || closestRegion.distance === 0) {
    label =
        closestRegion.label || `${closestRegion.city}, ${closestRegion.state}`;
  }
  if (!label && user.regions?.length)
    label =
        statesIndex[user.regions[1]] ??
        REGIONS.find((x) => x.value === user.regions[0])?.label ??
        label;
  return label;
}

export function formatLocation(user) {
  const zip = user?.zip ?? "";
  const state = user?.state ?? "";
  const city = user?.city ?? "";
  return state || zip ? `${city}, ${state} ${zip}` : city;
}

export function consecutiveArray(length, startNumber= 1){
  return Array.from({ length }, (_, index) => index + startNumber);
}
