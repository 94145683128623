import React from "react";

export const Column = ({ schema, attributes, user }) => {
    return (
        <>
            {Object.keys(schema)
                .filter((x) => !attributes || attributes.includes(x))
                .map((attribute, i) => {
                    const data =
                        typeof schema[attribute].data == "function"
                            ? schema[attribute].data(user)
                            : user[schema[attribute].data || attribute];
                    return (
                        <td key={attribute} className={attribute}>
                            {data}
                        </td>
                    );
                })}
        </>
    );
}