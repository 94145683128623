.search-bar {
    position: relative;
    width: 60%;

    .input-text {
        background-color: #FFFFFF;
        border: transparent;
        color: #53585a;
        font-family: 'Montserrat', sans-serif;
        font-size: 18px;
        font-style: italic;
        line-height: 24px;
        height: 50px;
        outline: none;
        width: 100%;
        &::placeholder {
            color: rgba(84,88,90,0.2);
            font-size: 14px;
        }

        &:focus, &:active {
        }
    }
    &.filter-modal-search {
        background-color: #EDEEEE;
        border-radius: 4px;
        margin-left: 32px;
        width: 294px;
        .icon-search {
            padding: 0 10px;
        }
        .input-text {
            background-color: #EDEEEE;
            border: none;
            height: 36px;
        }
    }
}
