.spinner-container{
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(#000000, 0.3);

}

.spinner{
  --size:30px;
  width: var(--size);
  height: var(--size);

  &::before{
    font-size: var(--size);
  }
}