import React from "react";
import {
  getGlobal,
  getSetting,
  setSetting,
  DraggableLink,
  Input,
  Collapsible,
  reloadSettings,
  Modal,
  confirm,
} from "launchpad";
// import { NavLink } from 'react-router-dom';
import styled from "styled-components";
import { getCachedRouteInfo, getCachedTitles } from "launchpad/routes";
import { Link } from "widgets";
import {
  getLinkId,
  registerMenu,
  unregisterMenu,
  resetMenus,
  checkMenus,
  setMenu,
  getMenu,
  menus,
  targetMenu,
  targetLink,
  activeMenu,
  activeLink,
  setTarget,
  setActive,
} from "./menu_logic";
import MenuLink from "./DynamicMenuLink";

export const MenuContainer = styled.ul`
  box-sizing: border-box;
  border: 1px solid transparent;
  margin-top: -1px;
  position: relative;

  .end-target {
    min-width: 80px;
    min-height: 30px;
    position: absolute;
    pointer-events: none;
  }

  &.highlighted {
    background: rgba(0, 0, 0, 0.05);
    border: 1px dashed #ddd;
    pointer-events: auto;
  }

  .menu-link {
    transition: padding 0.3s;
    position: relative;
    .openMenu {
      padding-left: 20px;
      padding-right: 20px;
      transition: transform 0.3s;
    }
    &.highlighted {
      border-left: 2px solid #ccc;
    }
    a {
      position: relative;
    }
  }

  .dynamic-link-container {
    position: relative;
    padding-right: 10px;
    transition: padding 0.3s;

    border: 2px solid transparent;

    &.inserted {
      border: 2px dashed rgba(0, 0, 0, 0.4);
    }

    .menu-tools {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      padding: 5px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      & > .fa {
        cursor: pointer;
        display: none;
      }
    }

    &:hover > .menu-tools > .fa {
      display: block;
    }
  }

  &.vertical {
    padding-bottom: 30px;
  }

  .edit-menu {
    position: absolute;
    bottom: 5px;
    right: 5px;
    cursor: pointer;
    display: none;
  }

  &:hover.is_admin,
  &.highlighted {
    background: rgba(0, 0, 0, 0.03);
    border: 1px dashed #ddd;
    .edit-menu {
      display: block;
    }
  }

  &.submenu-edit,
  .submenu-edit {
    ul {
      list-style: none;
      padding-left: 0;
      background: rgba(0, 0, 0, 0.02);
      display: flex;
      flex-direction: column;
      text-align: left;
      padding-bottom: 20px;
      li a {
        display: block;
        padding: 10px;
        border-bottom: 1px solid #ddd;
      }
      .instructions {
        padding: 20px;
        font-size: 1.5em;
      }
    }
  }

  h5.instructions {
    padding: 10px;
    opacity: 0.7;
    text-align: center;
  }
`;

export class DynamicMenu extends React.Component {
  state = {
    dragging: null,
    orphan: null,
    highlighted: null,
    received: false,
    newItem: "",
    newLink: "",
    menu: null,
  };

  componentDidMount() {
    registerMenu(this, this.props.menuId);
    this.componentDidUpdate();
  }

  componentDidUpdate() {
    if (!this.state.menu && this.getMenu() && this.getMenu().length) {
      this.setState({ menu: this.getMenu() });
    }
  }

  componentWillUnmount() {
    unregisterMenu(this, this.props.menuId);
  }

  getMenu = () => {
    return getMenu(this.props.menuId) || [];
  };

  check = () => {
    if (this.isTarget()) {
      this.insertLink(activeLink);
    } else {
      this.removeLink(activeLink);
      this.setState({ highlighted: null });
    }
  };

  isActive = () => {
    return activeMenu === this.props.menuId;
  };

  isTarget = () => {
    return targetMenu === this.props.menuId;
  };

  // get index of menu item
  getIndexById = (id) => {
    if (!this.state.menu) return 0;
    if (!id) return this.state.menu.length;
    const menu = this.state.menu || [];
    let idx = menu.length - 1;
    menu.forEach((l, i) => {
      if (l && l.id === id) idx = i;
    });
    return idx > -1 ? idx : 0;
  };

  // triggered externally for all menus after drag operation is complete
  cleanUp = () => {
    this.setState({ highlighted: null });
  };

  // returns menu with given item or 'active' item removed
  removeLink = (l, cb) => {
    const remove = l || activeLink;
    if (remove) {
      let m = this.state.menu || [];
      m = m.filter((link) => link.id !== remove.id);
      this.setState({ menu: m }, cb);
      return m;
    } else {
      if (cb) cb();
    }
  };

  // insert link
  insertLink = (l, options) => {
    options = options || {};

    // get position of currently hovered/active link if exists
    const position = this.getIndexById(targetLink && targetLink.id);

    // remove link from its current list if target is a different list
    if (activeMenu && activeMenu !== targetMenu) {
      menus[activeMenu].forEach((m) => {
        m.removeLink(activeLink);
      });
    }

    // get menu with inserted item removed
    let m = this.removeLink(l);

    // re-insert the item at the desired position
    m.splice(position, 0, l);

    // if list is empty (splice didn't work), push to the end of the list
    if (m.length < 1) m.push(l);

    // update all menus with the same id with the new list of items
    menus[this.props.menuId].forEach((menu) => {
      if (menu !== this) menu.setState({ menu: m });
    });

    // update this menu and save
    this.setState({ menu: m }, () => {
      if (options.save) this.save();
    });
    return m;
  };

  // commit state to database
  save = () => {
    if (!this.props.noSave) {
      setMenu(this.props.menuId, this.state.menu);
    }
  };

  // manually add new menu item (without dragging)
  addMenuItem = (e) => {
    if (this.state.newItem) {
      const newLink = {
        title: this.state.newItem,
        base: this.state.newItem,
        url: this.state.newLink,
        id: getLinkId(),
      };
      this.insertLink(newLink, { save: true });
      this.setState({ newItem: "", newLink: "", edit: false });
    }
  };

  // set state only if this menu is active
  setIfActive = (obj) => {
    if (this.isActive()) {
      this.setState(obj);
    }
  };

  highlight = (e, id) => {
    if (e) e.stopPropagation();
    if (!activeLink) {
      let link = getGlobal("activeMenuLink");
      link.id = getLinkId();
      setActive("link", link);
    }
    if (targetMenu !== this.props.menuId) {
      setTarget("menu", this.props.menuId);
      checkMenus();
    }
    if (id && this.state.highlighted !== id) {
      this.setIfActive({ highlighted: id });
      this.check();
    }
  };

  startDragging = (l) => {
    setActive("menu", this.props.menuId);
    setActive("link", l);
  };

  // mark that a link has left this list (only remove if it's added elsewhere)
  setOrphan = (e) => {
    if (this.state.dragging && !this.state.orphan)
      this.setState({ orphan: this.state.dragging });
  };

  render() {
    const { className, layout } = this.props;
    const is_submenu =
      this.props.menuId.includes("=>") || this.props.disableSubmenu;
    const menu_links = this.state.menu || [];
    const is_empty = getGlobal("is_admin") && menu_links.length < 1;
    let cn =
      (className ? " " + className : "") +
      (layout === "vertical" ? " vertical" : "");
    cn += getGlobal("is_admin") ? " is_admin" : "";
    return (
      <MenuContainer
        draggable={false}
        isActive={getGlobal("activeMenuLink") ? true : false}
        key="main-menu"
        className={
          (getGlobal("activeMenuLink") || is_empty ? "highlighted" : "") + cn
        }
        onDrop={() => resetMenus()}
        onDragOver={(e) => this.highlight(e)}
        onDragLeave={this.setOrphan}
      >
        {menu_links.map((l, i) => {
          return (
            <MenuLink
              highlighted={this.state.highlighted === l.id}
              onHighlight={() => this.highlight(null, l.id)}
              layout={this.props.layout}
              onStartDrag={(e) => this.startDragging(l)}
              onStopDrag={(e) => setActive("link", null)}
              link={l}
              index={i}
              menuId={this.props.menuId}
              key={l.id}
              isSubmenu={is_submenu}
              toggleSubmenu={this.props.toggleSubmenu}
            />
          );
        })}
        <div
          onDragOver={() => this.setIfActive({ highlighted: "end" })}
          className="end-target"
        >
          {" "}
        </div>

        {is_empty && (
          <h5 className="instructions">
            Click the plus icon or drag links here from "All Pages"
          </h5>
        )}
        {getGlobal("is_admin") && (
          <div
            className="material-icons edit-menu"
            onClick={() => this.setState({ edit: true })}
          >
            add
          </div>
        )}

        {getGlobal("is_admin") && (
          <Modal
            open={this.state.edit}
            onClose={() => this.setState({ edit: false })}
          >
            <Input
              label="New Item Label"
              onChange={(e) => this.setState({ newItem: e.target.value })}
              value={this.state.newItem}
            />
            <Input
              label="New Item Link (optional)"
              onChange={(e) => this.setState({ newLink: e.target.value })}
              value={this.state.newLink}
            />
            <button onClick={this.addMenuItem}>
              <span className="material-icons">add</span> Add Menu Item
            </button>
          </Modal>
        )}
      </MenuContainer>
    );
  }
}

// if(module.hot) module.hot.accept()
