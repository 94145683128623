import isObject from "lodash.isobject";
import isNumber from "lodash.isnumber";
import isString from "lodash.isstring";
import { isEmpty } from "../../util-functions";
import ERRORS from "./errors";

/** Generic Validator */
const Validator = {
  /**
   * General password validator. Checks for at least 1 digit, 1 letter and at least 6 characters.
   * @param { string } password {String} loto be checked
   * @returns {boolean} true/false
   */
  validatePassword(password) {
    if (!password) {
      return false;
    }
    // const regex = (/^(?=.*\d)(?=.*[a-zA-Z])[A-Za-z\d$@$#+!%*?&.]{6,}$/);
    const regex = /^(?=.*[a-zA-Z])[A-Za-z\d$@$#+!%*?\-\[\]\_&.]{6,}$/;

    /*
        // until discussed removed digit validation
         (/^
         (?=.*\d)                //should contain at least one digit
         (?=.*[a-zA-Z])          //should contain at least one letter
         [A-Za-z\d$@$!%*?&.]{8,} //should contain at least 6 from the mentioned characters (allow special symbols)
         $/)
         */
    return regex.test(password);
  },

  /**
   * General Number validator
   * @param number {String} to be checked
   * @returns {boolean} true/false
   */
  validateNumber(number) {
    if (!isEmpty(number)) {
      const regex = /^-?\d*(\.\d+)?$/;
      const parsed = parseFloat(number);
      return isNumber(parsed) && !Number.isNaN(parsed) && regex.test(number);
    }
  },

  /**
   * General Number validator
   * @param phoneNumber {String} to be checked
   * @returns {boolean} true/false
   */
  validatePhoneNumber(phoneNumber) {
    // const regex = /^[0-9]{3}?[-]?[0-9]{3}[-]?[0-9]{4}$/;
    const regex =
      /^((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\W*\d){0,13}\d$/gm;
    return regex.test(phoneNumber);
  },

  /**
   * Date format validator. Must be in the format MM/DD/YYYY
   * @param date {String} to be checked
   * @returns {boolean}
   */
  validateAmericanDateFormat(date) {
    const regex = /^[0-9]{2}?[/]?[0-9]{2}[/]?[0-9]{4}$/;
    let valid = regex.test(date);
    if (valid && Number.isNaN(Date.parse(date))) {
      valid = false;
    }
    return valid;
  },

  /**
   * Validate time format to be HH:MM
   * @param {String} time time to be checked
   * @returns {boolean}
   */
  validateTimeFormat(time) {
    const regex = /^([0-9]|0[0-9]|1[0-2]):[0-5][0-9]$/;
    return regex.test(time);
  },

  /**
   * General email validator
   * @param email {String} to be checked
   * @returns {boolean} true/false
   */
  validateEmail(email) {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z ]{2,}))$/;
    return regex.test(email);
  },

  /**
   * General Character validator
   * @param value {String} to be checked
   * @returns {boolean} true/false
   */
  validateCharacter(value) {
    const regex = /^[a-zA-Z]'?([a-zA-Z]|\.| |-)+$/;
    return regex.test(value);
  },

  /**
   * Name Validator
   * @param value {String} to be checked
   * @returns {boolean} true/false
   */
  validateName(value) {
    const regex = /^[a-z ,.'-]+$/i;
    return regex.test(value);
  },

  /**
   * Creates an error map based on the properties you want to validate
   * @param validationMap {Object} map of properties you want to validate
   * @returns {Object} map of keys that matches your validationMap object
   */
  createErrorMap(validationMap) {
    const errorMap = {};
    Object.keys(validationMap).forEach((key) => {
      const item = validationMap[key];
      if (this._isNested(item)) {
        errorMap[key] = {};
        Object.keys(item).forEach((childKey) => {
          errorMap[key][childKey] = null;
        });
      } else {
        errorMap[key] = null;
      }
    });
    return errorMap;
  },

  /**
   * Validate the state of a component
   * @param state {Object} the state
   * @param validationMap {Object} map of properties to be validated
   * @returns { {valid, error} } Object with {valid, error}
   */
  validateState(state, validationMap) {
    let isValid = true;
    const { errors } = state;

    // Iterate over each key in the state. If it's an object, iterate on its children also
    Object.keys(state).forEach((key) => {
      const value = state[key];
      if (key === "error" || !validationMap[key]) {
        return true; // Break the loop
      }
      if (this._isNested(validationMap[key])) {
        Object.keys(validationMap[key]).forEach((innerKey) => {
          const corresponding = value[innerKey];
          isValid =
            Validator._setError(
              errors[key],
              validationMap[key],
              innerKey,
              corresponding
            ) && isValid;
        });
      } else {
        isValid =
          Validator._setError(errors, validationMap, key, value) && isValid;
      }
    });

    return { isValid, errors };
  },

  /** Checks for errors for each field */
  _setError(errors, validationMap, key, value) {
    const validationItem = validationMap[key];
    if (validationItem) {
      // Check required
      if (validationItem.checked && !value) {
        errors[key] = isString(validationItem.checked)
          ? validationItem.checked
          : ERRORS.REQUIRED;
        return false;
      }

      // Value required
      if (validationItem.required && isEmpty(value) && !isNumber(value)) {
        errors[key] = isString(validationItem.required)
          ? validationItem.required
          : ERRORS.REQUIRED;
        return false;
      }

      // Check email to be valid
      if (validationItem.email && !this.validateEmail(value)) {
        errors[key] = isString(validationItem.email)
          ? validationItem.email
          : ERRORS.EMAIL_FORMAT;
        return false;
      }

      // Check characterState to be valid
      if (validationItem.characterState && !this.validateCharacter(value)) {
        errors[key] = isString(validationItem.characterState)
          ? validationItem.characterState
          : ERRORS.STATE_CHARACTER_FORMAT;
        return false;
      }
      // Check characterCity to be valid
      if (validationItem.characterCity && !this.validateCharacter(value)) {
        errors[key] = isString(validationItem.characterCity)
          ? validationItem.characterCity
          : ERRORS.CITY_CHARACTER_FORMAT;
        return false;
      }

      // Check password to be valid
      if (validationItem.password && value && !this.validatePassword(value)) {
        errors[key] = isString(validationItem.password)
          ? validationItem.password
          : ERRORS.PASSWORD_FORMAT;
        return false;
      }

      // Check strong password to be valid (contains mandatory special characters)
      if (
        validationItem.strongPassword &&
        !this.validatePassword(value, true)
      ) {
        errors[key] = isString(validationItem.strongPassword)
          ? validationItem.strongPassword
          : ERRORS.PASSWORD_FORMAT;
        return false;
      }

      // Check to be positive number
      if (validationItem.number && !this.validateNumber(value)) {
        errors[key] = isString(validationItem.number)
          ? validationItem.number
          : ERRORS.NUMBER_FORMAT;
        return false;
      }
      // Check to be positive number or empty
      if (
        validationItem.numberOrEmpty &&
        value &&
        !this.validateNumber(value)
      ) {
        errors[key] = isString(validationItem.numberOrEmpty)
          ? validationItem.numberOrEmpty
          : ERRORS.NUMBER_FORMAT;
        return false;
      }

      // Number > 0
      if (validationItem.nonZeroNumber) {
        // Validate number first
        if (!this.validateNumber(value)) {
          errors[key] = isString(validationItem.number)
            ? validationItem.number
            : ERRORS.NUMBER_FORMAT;
          return false;
        }
        // If valid number, check if it's > 0
        if (parseInt(value, 10) <= 0) {
          errors[key] = isString(validationItem.nonZeroNumber)
            ? validationItem.nonZeroNumber
            : ERRORS.NUMBER_NON_ZERO;
          return false;
        }
      }

      // Check to be positive phone number
      if (validationItem.phoneNumber && !this.validatePhoneNumber(value)) {
        errors[key] = isString(validationItem.phoneNumber)
          ? validationItem.phoneNumber
          : ERRORS.PHONE_NUMBER_FORMAT;
        return false;
      }

      // Date field MM/DD/YYYY
      if (
        validationItem.americanDate &&
        value &&
        !this.validateAmericanDateFormat(value)
      ) {
        errors[key] = isString(validationItem.americanDate)
          ? validationItem.americanDate
          : ERRORS.AMERICAN_DATE_FORMAT;
        return false;
      }

      // Time field HH:MM
      if (validationItem.time && value && !this.validateTimeFormat(value)) {
        errors[key] = isString(validationItem.time)
          ? validationItem.time
          : ERRORS.TIME_FORMAT;
        return false;
      }
    }

    // Else, mark that there's no error for the field
    errors[key] = null;
    return true;
  },

  /**
   * Checks if an object is nested (has depth > 1)
   * @param obj {Object} to be checked
   * @returns {boolean}
   * @private
   */
  _isNested(obj) {
    let nested = false;
    Object.keys(obj).forEach((key) => {
      if (isObject(obj[key])) {
        nested = true;
        return true;
      }
    });

    return nested;
  },
};

export default Validator;
