@import "../../../../src/scss/utilities";
@import "../../../../src/scss/variables";

.matchmaker-dashboard {
  height: 100%;
}

.matchmaker-dashboard-container,
.user-dashboard-container,
.admin-dashboard-container {
  &__sections {
    background: linear-gradient(
      315deg,
      rgba(101, 196, 192, 0.1) 0%,
      rgba(255, 218, 101, 0.1) 100%
    );
    height: calc(100vh - 121px);
    padding: 20px;
    width: calc(100%);
  }

  &__left-panel {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.4);
    height: 100%;
    overflow: auto;
    width: 240px;
  }

  &__right-panel {
    background-color: #fff;
    border: 1px solid rgba(84, 88, 90, 0.1);
    border-radius: 2px;
    box-shadow: 0 0 10px 0 rgba(84, 88, 90, 0.2);
    height: 100%;
    width: calc(100%);

    .search-bar-container {
      border-bottom: 1px solid rgba(84, 88, 90, 0.2);
      padding: 20px 0 0 0;
      width: 50%;

      .search-bar {
        padding-left: 20px;
      }
    }

    .areas-selection {
      width: 50%;
      padding: 21px 21px 0 0;
      position: relative;

      .select {
        color: rgba(84, 88, 90, 0.5);
        font-family: Montserrat, sans-serif;
        font-size: 14px;
      }

      .icon-question-mark {
        cursor: pointer;
        margin: 0 16px 0 8px;
      }

      .drop-down-wrapper {
        max-width: 240px;
      }

      .tool-tip-component {
        top: 70px;
        right: 50%;
      }
    }

    .selected-filters {
      margin: 0 30px;
      padding: 15px 0;

      &__title {
        padding: 10px 0 15px 0;
      }

      .selected-filter {
        background-color: #fff;
        margin: 0 9px 9px 0;
        font-family: Montserrat, sans-serif;

        &__label {
          color: rgba(84, 88, 90, 0.5);

          font-size: 14px;
          margin: 0 0 4px 9px;
        }

        &__content {
          border: 1px solid $teal;
          border-radius: 6px;
          box-shadow: 0 0 10px 0 rgba(84, 88, 90, 0.2);
          padding: 8px 10px;
          position: relative;
        }

        .filter-value {
          color: $teal;
          font-size: 14px;
          min-width: 40px;
          text-align: center;
        }

        .icon-arrow {
          margin: 0 0 0 6px;
          transform: rotate(90deg);
        }

        .vertical-line {
          height: 20px;
          border-left: 1px solid rgba(84, 88, 90, 0.2);
          margin: 0 5.5px;
        }

        .icon-close-button {
          font-size: 14px;
        }
      }

      .save-filters {
        margin-left: auto;
      }
    }
  }

  .header-back-button {
    cursor: pointer;
    left: 30px;
    position: absolute;
    top: 30px;
    z-index: 6;
  }

  .rating-modal .modal {
    color: #53585a;
    max-width: 700px;
    min-height: 340px;
    padding: 40px;

    .title {
      font-family: Montserrat, sans-serif;
      font-size: 24px;
    }

    .radio-list-horizontal {
      padding-bottom: 75px;
    }

    .right-block-title {
      font-size: 18px;
      font-family: "Montserrat", sans-serif;
      text-align: center;
      padding: 20px 0;
    }

    .custom-radio-button {
      margin-right: 10px;

      .label {
        top: 25px;
      }
    }

    .custom-radio-button:last-child {
      margin-right: 0;

      .label {
        left: -20px;
      }
    }
  }

  .matchmaker-stats {
    height: 80px;
    // position: absolute;
    // top: 0;
    // right: 160px;
    // float:right;
    z-index: 11;

    &__label {
      color: rgba(84, 88, 90, 0.5);
      font-family: Montserrat, sans-serif;
      font-size: 14px;
      margin-bottom: 7px;
    }

    &__stats {
      .icon-contacted,
      .icon-lock {
        margin: 0 17px 0 4px;

        &:before {
          color: #a9abac;
        }
      }

      .green,
      .red {
        font-family: Montserrat, sans-serif;
        font-size: 18px;
        font-weight: 900;
      }

      .green {
        color: $teal;
      }

      .red {
        color: #ff596f;
      }
    }

    .tool-tip-component {
      padding: 10px;
      top: 77px;
      width: 270px;
      right: -50px;

      .icon-close-button {
        display: none;
      }
    }
  }
}

// moved here so it's globally available
.icon-plus-yellow {
  background-color: #ffda65;
  border-radius: 22px;
  cursor: pointer;
  height: 22px;
  position: relative;
  width: 22px;

  &:after,
  &:before {
    content: "";
    position: absolute;
    width: 12.25px;
    height: 2px;
    background: #fff;
  }

  &:before {
    top: 10px;
    left: 5px;
    transform: rotate(90deg);
  }

  &:after {
    top: 10px;
    left: 4.8px;
  }
}

.user-info-modal {
  top: 0;

  .modal {
    background-color: #ffffff;
    border: 1px solid rgba(84, 88, 90, 0.1);
    box-shadow: 0 0 10px 0 rgba(84, 88, 90, 0.2);
    font-family: Montserrat, sans-serif;
    padding: 20px 40px;
    min-width: 98%;

    &__header {
      border-radius: 2px 2px 0 0;
      box-shadow: 0 1px 0 0 rgba(84, 88, 90, 0.1);
      height: 47px;
      padding-bottom: 20px;
      position: relative;

      .button {
        height: 46px;
        width: 204px;
        padding: 0;

        &.yellow {
          margin: 0 40px 0 20px;
        }
      }

      .icon-close-button {
        position: relative;
        right: -20px;
      }

      &__name {
        padding-left: 30px;

        span {
          color: #54585a;
          font-family: Montserrat, sans-serif;
          font-size: 24px;
          font-weight: 500;
          margin-right: 8px;
          text-transform: capitalize;
        }
      }

      &__lgbtq-label {
        width: 100px;
        margin: 0 0 0 1.5rem;
      }

      &__polyOther-label {
        width: 100px;
        margin: 0 0 0 1.5rem;
      }
    }

    .admin-menu {
      border: 1px solid rgba(84, 88, 90, 0.1);
      border-radius: 2px;
      background-color: #fff;
      box-shadow: 0 0 10px 0 rgba(84, 88, 90, 0.2);
      height: auto;
      width: 213px;
      position: absolute;
      right: 20px;
      top: 50px;
      z-index: 4;

      > span {
        border-bottom: 1px solid rgba(84, 88, 90, 0.1);
        cursor: pointer;
        height: 40px;
        padding: 0 11px;
        user-select: none;
        background: #fff;
        color: #54585a;
        font-family: Montserrat, sans-serif;
        font-size: 14px;

        &:hover {
          background-color: #ffda65;
        }
      }

      > span:last-child {
        border-bottom: none;
      }

      &.photo-menu {
        right: -21px;
        top: 40px;
        width: 129px;
      }
    }

    .section-header {
      color: #ffc134;
      font-size: 32px;
      font-weight: 500;
      margin-bottom: 20px;
    }

    .section-grid {
      display: grid;
      grid-template-columns: repeat(3, minmax(auto, 1fr));
      grid-gap: 0.5rem 1rem;
    }

    .the-basics-section,
    .background-section,
    .lifestyle-section {
      font-family: Montserrat, sans-serif;

      &__sub-header {
        color: $teal;
        font-size: 16px;
        font-weight: normal;
        margin-bottom: 6px;
      }

      &__value {
        margin-top: 0px;
        margin-bottom: 0px;
        color: #54585a;
        font-family: "Montserrat", sans-serif;
        font-size: 16px;
        line-height: 22px;
        max-width: 100%;
        text-align: left;
      }

      &__small-text {
        color: rgba(84, 88, 90, 0.5);
        font-family: "Montserrat", sans-serif;
        font-size: 14px;
        font-weight: 600;
        margin-right: 12px;
      }

      .half-width {
        align-items: flex-start;
        width: 50%;
        margin-bottom: 20px;
      }

      .third-width {
        align-items: flex-start;
        width: 33%;
        margin-bottom: 20px;
      }

      .user-rating {
        color: #ff596f;
        cursor: pointer;
        font-family: "Montserrat", sans-serif;
        font-size: 14px;
        font-weight: 600;
        margin-left: 12px;
        text-decoration: underline;
      }

      .color-scale {
        span {
          color: rgba(84, 88, 90, 0.5);
          font-family: "Montserrat", sans-serif;
          font-size: 14px;
          font-weight: 600;
          max-width: 65px;
        }

        &__container {
          border: 1px solid $teal;
          border-radius: 15px;
          height: 32px;
          margin: 0 12px;
          width: 185px;

          .option {
            height: 32px;
            width: 37px;
            font-size: 14px;

            &.selected {
              background: $teal;
              color: #fff;
              font-size: 20px;
              font-weight: bold;
            }
          }

          .option:first-child {
            border-radius: 14px 0 0 14px;
          }

          .option:last-child {
            border-radius: 0 14px 14px 0;
          }
        }
      }
    }

    .left-section {
      background: #fff;
      min-height: 600px;
      width: 37%;

      &__main-image {
        background: #fff;
        border-radius: 4px;
        min-height: 477px;
        position: relative;
        width: 100%;

        .photo-actions {
          position: absolute;
          top: 20px;
          right: 10px;
          z-index: 5;

          .icon-more {
            cursor: pointer;
            font-size: 30px;
            //&:before {
            //    color: #fff;
            //}
          }
        }

        img {
          max-height: 564px;
          max-width: 100%;
        }

        .alice-carousel {
          height: 564px;
          width: 477px;
          border-radius: 4px;

          .alice-carousel__stage-item {
            text-align: center;

            &__active {
              text-align: center;
            }

            img {
              height: 564px;
              border-radius: 4px;
              max-width: 100%;
              object-fit: cover;
            }
          }
        }
      }

      &__images-slider {
        margin-top: 20px;
        position: relative;

        .pics {
          height: 62px;
          // width: 62px;
          border: 1px solid $teal;
          border-radius: 2px;
          background-color: rgba(255, 255, 255, 0.5);
          margin: 0 10px;

          img {
            border-radius: 1px;
            height: 100%;
            // width: 100%;
            // max-width: 62px;
          }
        }

        .right-arrow {
          transform: rotate(180deg);
        }
      }

      .input-checkbox .checkbox__label {
        color: $teal;
        font-family: Montserrat, sans-serif;
        font-size: 18px;
        line-height: 21px;
        padding-left: 10px;
        margin-top: -15px;
      }
    }

    .right-section {
      background: #fff;
      font-family: Montserrat, sans-serif;
      padding: 0 0 0 40px;
      width: 63%;
      height: auto;
      position: relative;
    }
  }
}

.terms-and-conditions-for-mm-modal {
  .modal {
    max-width: 50%;
  }

  &__content {
    padding: 60px;
  }

  &__title {
    color: #54585a;
    font-family: Montserrat, sans-serif;
    font-size: 33px;
    font-weight: 500;
    text-align: center;
    padding-bottom: 40px;
  }

  &__text {
    color: #54585a;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 45px;

    span:nth-child(2) {
      cursor: pointer;
      margin-left: 10px;
      text-decoration: underline;
    }
  }

  &__buttons .button {
    margin: 0 20px;
  }
}

.loading-modal-opened {
  .matchmaker-dashboard-container,
  .admin-dashboard-container,
  .basic-single-info-container {
    opacity: 0.8;
    pointer-events: none;
  }
}

.admin-dashboard-container {
  &__left-panel {
    padding: 0;
    margin-right: 10px;
    width: 280px;

    .button {
      margin-bottom: 16px;
      width: 100%;
    }

    &__content {
      background: #fff;
      border: 1px solid rgba(84, 88, 90, 0.1);
      border-radius: 2px;
      box-shadow: 0 0 10px 0 rgba(84, 88, 90, 0.2);
      padding: 13px;

      .general-info {
        > div {
          height: 48px;
          color: #54585a;
          font-family: "Montserrat", sans-serif;
          font-size: 16px;

          .column-title {
            max-width: 100px;
          }

          .numbers {
            background-color: $teal;
            border-radius: 22px;
            color: #fff;
            height: 35px;
            min-width: 35px;
            padding: 0 4px;
          }

          .percentage {
            background-color: #ff596f;
            border-radius: 18px;
            color: #fff;
            height: 35px;
            min-width: 45px;
            margin-left: 10px;
            padding: 0 4px;

            &.empty {
              background: #fff;
            }
          }
        }
      }

      .button {
        margin-bottom: 12px;
        width: 100%;
      }
    }
  }

  &__right-panel {
    width: calc(100% - 280px);

    .search-bar-container {
      padding: 22px 0 20px 21px;

      .search-bar {
        border-bottom: 1px solid rgba(84, 88, 90, 0.2);

        .input-text {
          padding-left: 12px;
        }
      }
    }
  }
}

.user-dashboard-container {
  &__sections {
    box-shadow: 0 0 10px 0 rgba(84, 88, 90, 0.2);
    max-width: 1050px;
    margin: 19px auto;
    padding: 0;
  }

  &__header-first {
    z-index: 10;
    background-color: #fff;
    border-bottom: 1px solid rgba(84, 88, 90, 0.1);
    padding: 10px 40px;

    .profile-image {
      background: rgba(84, 88, 90, 0.1);
      border-radius: 50%;
      height: 80px;
      margin-right: 41px;
      text-align: center;
      width: 80px;

      img {
        border-radius: 50%;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .profile-name {
      font-family: Montserrat, sans-serif;

      span:first-child {
        color: rgba(84, 88, 90, 0.5);
        font-size: 16px;
        font-weight: 500;
      }

      span:nth-child(2) {
        color: #54585a;
        font-size: 24px;
        font-weight: 500;
        margin-top: 7px;
        text-transform: capitalize;
      }
    }

    .profile-title {
      color: #ffda65;
      font-size: 30px;
      font-weight: 900;
      max-width: 500px;
      padding-left: 2rem;
    }

    .question-tip {
      color: $helperText;
      font-size: 12px;
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
    }
  }

  &__left-panel,
  &__right-panel {
    box-shadow: none;
    overflow-x: hidden !important;
  }

  &__left-panel {
    background-color: rgba(84, 88, 90, 0.05);
    box-shadow: 1px 0 0 0 rgba(84, 88, 90, 0.2);
    height: 100%;
    width: 240px;

    .completion-level {
      height: 105px;
      width: 200px;

      .icon-yellow-bird,
      .icon-green-bird {
        font-size: 64px;
      }

      .icon-yellow-bird {
        margin-right: 20px;
      }

      .icon-green-bird {
        margin-left: 20px;
      }
    }

    .progress-bar {
      border-radius: 16px;
      background-color: #ff596f;
      font-weight: 500;
      height: 10px;
      position: relative;

      &__text {
        color: #54585a;
        font-size: 14px;
        margin: 8px 0 21px 0;

        span:nth-child(2) {
          font-size: 16px;
        }
      }

      .completion {
        border-radius: 16px;
        background-color: #ffda65;
        left: 0;
        height: 10px;
        position: absolute;
        // z-index: 4;
      }
    }
  }

  &__left-tab {
    cursor: pointer;
    color: #54585a;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    height: 60px;

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
      cursor: not-allowed;
    }

    &.is-selected {
      background: #3ec6c0;
      color: #fff;
    }

    .wrapper {
      height: 100%;
      margin: 0 35px 0 30px;
      border-bottom: 1px solid rgba(84, 88, 90, 0.2);
    }

    .bubble {
      background-color: #ff596f;
      border-radius: 50%;
      height: 12px;
      width: 11.57px;
    }
  }

  &__right-panel {
    width: calc(100% - 240px);
    overflow-y: auto;
    border: none;

    @include for-tablet-down {
      .the-basics,
      .more-about,
      .your-partner,
      .photos,
      .settings {
        width: unset !important;
        height: unset !important;
        padding: 0 !important;

        .input-checkbox-full {
          .input-checkbox {
            width: 100%;
          }
        }
      }
    }

    &__required-legend {
      color: $red;
      font-size: 0.75rem;
    }

    .the-basics,
    .more-about,
    .your-partner,
    .photos,
    .settings {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 40px 60px;

      .continue-button-container {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin-top: 1rem;
      }

      .continue-button-container {
        display: flex;
        justify-content: flex-start;
        margin-top: 1rem;
      }

      .birthdate-container,
      .height-container,
      .range-age-container {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }

      .error-label {
        bottom: 13px;
      }

      .labels-container {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }

      .label {
        color: #ff6666;
        font-family: Montserrat, sans-serif;
        font-size: 18px;
        font-weight: 500;

        &.valid {
          color: $teal;
        }
      }

      .date-input {
        &__inner-container {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 20px;
        }

        .month-drop-down {
          width: 140px;
        }

        .day-drop-down {
          width: 90px;
        }

        .year-drop-down {
          width: 100px;
        }
      }

      .height-input {
        border: 1px solid rgba(84, 88, 90, 0.2);
        border-radius: 4px;
        padding: 0 12px;
        width: 134px;

        .input-wrapper {
          width: 32px;

          .input-text {
            padding: 0 10px;
          }
        }

        .input-wrapper.small {
          width: 42px;

          .input-text {
            padding: 0 9px;
          }
        }

        .input-tag {
          color: rgba(84, 88, 90, 0.3);
          font-family: "Montserrat", sans-serif;
          font-size: 18px;
          font-style: italic;
          line-height: 24px;
          width: 33px;
          height: 40px;
        }

        &.is-valid {
          border: 1px solid #7dc0bd;
        }

        &.has-error {
          border: 1px solid #ff6666;
        }

        &.age-range {
          width: 110px;

          .input-wrapper {
            width: 44px;
          }
        }

        &.kids {
          width: 88px;

          .input-tag {
            width: 50px;
          }
        }
      }

      .kids-info {
        .kids-ages {
          height: 40px;
          margin-bottom: 10px;
        }

        .kid-label {
          color: #54585a;
          font-family: "Montserrat", sans-serif;
          font-size: 18px;
          margin-right: 12px;
          width: 112px;
          text-align: left;
        }
      }

      .input-text-area-wrapper .input-text-area {
        max-width: 665px;
        min-height: 63px;
        max-height: 200px;
      }

      .zipcode {
        max-width: 100px;

        .error-label {
          top: 66px;
        }
      }

      .state-drop-down {
        width: 100px;
        margin-right: 20px;
      }

      .title-gap {
        gap: 0.5rem;
      }
    }
  }
}

.terms-and-conditions-modal {
  .modal {
    max-width: 600px;
    min-height: 300px;

    &__content {
      padding: 60px;

      .title {
        color: #54585a;
        font-family: Montserrat, sans-serif;
        font-size: 26px;
        font-weight: 500;
        text-align: center;
        padding-bottom: 20px;
      }

      .agree-terms {
        font-size: 16px;
        line-height: 12px;
        letter-spacing: -0.1px;
        margin: 25px 0 25px 50px;

        .underlined {
          color: #7dc0bd;
          cursor: pointer;
          padding: 0 5px;
          text-decoration: underline;
        }
      }

      .button {
        margin: 0 10px;
      }
    }
  }
}

.matchmaker-dashboard-container {
  .safari-custom {
    display: block;

    .matchmaker-dashboard-container__right-panel {
      float: right;
    }

    .matchmaker-dashboard-container__left-panel {
      float: left;
    }
  }
}

@include for-tablet-down {
  .user-dashboard-container {
    height: 100%;

    &__sections {
      margin: 0;
      width: 100%;
      height: 100%;
      background: unset !important;

      &__mobile-header {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1rem;
      }
    }

    &__right-panel {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: unset;
      padding: 0 1rem 1rem 1rem;
      box-sizing: border-box;
    }
  }
}
