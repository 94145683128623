import React, { useEffect, useRef, useState } from "react";
import Filters, { Filter, getAllFilters, getFilter, setFilters } from "src/components/filters/Filters";
import theme from "src/styles/theme";
import { getGlobal, setGlobal } from "launchpad";
import { getHeight } from "global_data";
import { FilterIcon } from "src/components/icons";
import { SavedFilters } from "src/components/filters/savedFilters/SavedFilters";
import Modal from "src/components/Modal/Modal";
import { saveFilters as persistFilters } from "../UserList";
import { Slideshow } from "src/components/widgets";
import memberSchema from "../member-data";
import "./FilterSideBar.scss"
import SearchInput from "_old/src/components/search-input";
import { toast } from "react-toastify";

const FilterModals = () => {
    const { selectedUserFilter } = getGlobal();
    return (
        <>
            {getAllFilters().map((x) => {
                return (
                    <Modal
                        key={x.name}
                        open={selectedUserFilter === x.name}
                        title={x.filterLabel || x.label}
                        onClose={() => setGlobal({ selectedUserFilter: "" })}
                    >
                        <div className={`filter-modal ${x.name}`}>
                            <Filter name={x.name} hideLabel />
                        </div>
                    </Modal>
                );
            })}
        </>
    );
};

export const FilterSideBar = ({ toggleFilter, isTabletDown }) => {
    const [showFilters, setShowFilters] = useState(false);
    const filters = getGlobal("userFilters") || {};
    const [filter, _setFilter] = useState("");
    const user = getGlobal("activeUser");
    const saved = user?.savedSelectedFilters ?? {};
    const filtersModalBodyRef = useRef(null);
    const [search, setSearch] = useState("");
    const [state, setState] = useState({
        allFiltersType: "basic",
        selectedUser: null,
        showProfile: false,
        onEnterText: null,
        filterName: "",
        refresh: () => { },
    });

    const {
        allFiltersType,
        onEnterText,
        filterName,
    } = state;

    const saveFilters = async (name) => {
        persistFilters({
            ...getGlobal("activeUser").savedSelectedFilters,
            ...{ [name]: filters },
        });
        toast.success("Filter saved successfully");
    };

    const filterUsed = () => {
        const current = JSON.stringify(filters);
        const mmFilters = user?.savedSelectedFilters ?? {};
        for (let x of Object.keys(mmFilters)) {
            if (JSON.stringify(mmFilters[x]) === current) return x;
        }
    };

    const filterButton = (value) => { setShowFilters(value) }

    const setFilter = (v) => {
        _setFilter(v);
        setFilters(flatten(saved[v]), { overwrite: true });
    };

    const currentFilter = filterUsed();

    useEffect(() => {
        if (currentFilter !== filter) {
            _setFilter(currentFilter);
        }
    }, [currentFilter]);

    useEffect(() => {
        const timeOut = setTimeout(() => {
            if (filters.user_id && search == "") {
                const { user_id, ...rest } = filters
                setFilters(rest, { overwrite: true })
            }
            if (+search) setFilters({ user_id: search })
            else setFilters({ query: search })
        }, 3000);

        return () => clearTimeout(timeOut);
    }, [search]);


    return <>
        <Modal
            title="Save Filter"
            open={!!onEnterText}
            onClose={() => setState({ onEnterText: null })}
        >
            <p>Choose a name for your filter</p>
            <form
                className="save-filter-form"
                onSubmit={(e) => {
                    e.preventDefault();
                    setState({ onEnterText: null, filterName: e.target.filterName.value });
                    saveFilters(e.target.filterName.value);
                }}
            >
                <input
                    name="filterName"
                />
                <button className="button">Save</button>
            </form>
        </Modal>
        <Modal
            ref={filtersModalBodyRef}
            key="all"
            open={showFilters}
            onClose={() => setShowFilters(false)}
            header={
                <div className="tab-header">
                    <nav className="tab-nav">
                        <a
                            title="Basic"
                            className={allFiltersType === "basic" ? "active" : ""}
                            onClick={(e) => setState({ allFiltersType: "basic" })}
                        >
                            Basic
                        </a>
                        <a
                            title="Advanced"
                            className={allFiltersType === "advanced" ? "active" : ""}
                            onClick={(e) => setState({ allFiltersType: "advanced" })}
                        >
                            Advanced
                        </a>
                    </nav>
                    <p
                        className="filter-info"
                    >
                        By ignoring a filter, all members within that category will be
                        displayed. Adjust filters to narrow your search.
                    </p>
                </div>
            }
        >
            <Slideshow
                className="filters-slider"
                slide={allFiltersType === "basic" ? 1 : 2}
                dynamicHeight
            >
                <div id="basic-filters">
                    <Filters type="basic" />
                </div>

                <div id="advanced-filters">
                    {Object.keys(memberSchema).map(
                        (x, index) => x !== "basic" && <Filters type={x} key={`${index}-filter`} showLabel />
                    )}
                </div>
            </Slideshow>
        </Modal>
        <FilterModals />
        {!isTabletDown ? (
            <div className="sidebar dashboard-panel">
                <div className="search-bar-container">
                    <SearchInput
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Search by name or #"
                    />
                </div>
                <button
                    style={{ marginTop: '8px', backgroundColor: theme.colors.yellow }}
                    onClick={() => filterButton(true)}
                    className={"filter-button"}
                >
                    <div>
                        <span style={{ fontWeight: 500, color: "#2E383A", fontSize: "16px" }} className="text">FILTER MEMBERS</span>
                    </div>
                </button>
                {hasFiltersApplied() && (
                    <button
                        className={"active filter-button"}
                        style={{ backgroundColor: '#3FC6C0', marginBottom: '8px' }}
                        onClick={() => removeAllFilters()}
                    >
                        CLEAR ALL FILTERS
                    </button>
                )}
                <CurrentFilters />
                <SavedFilters filters={saved} setFilter={setFilter} selectedFilter={filter} setAllFilters={setFilters} />
                {Object.keys(filters).length > 0 &&
                    !(filters.connected || filters.potentials) && (
                        <div className="save-filters">
                            <button className="button" onClick={() => setState(prev => ({ ...prev, onEnterText: true }))}>
                                Save Filter
                            </button>
                        </div>
                    )}
            </div>
        ) : (
            <div style={{ display: "flex", justifyContent: "space-around", padding: "2vh", height: "8svh" }}>
                <FilterMobileButton text={"Filter Members"} isPressed={hasFiltersApplied()} handleFilterButtonClick={filterButton} selectedColor={theme.colors.yellow} />
                <SavedFilters filters={saved} setFilter={setFilter} selectedFilter={filter} setAllFilters={setFilters} isMobile={true} />
            </div>
        )}
    </>
};

const flatten = (obj) => {
    if (typeof obj !== "object") return obj;
    if (obj.value) return obj.value;
    if (Array.isArray(obj)) {
        return obj.map((x) => flatten(x));
    }
    let flat = {};
    for (let key of Object.keys(obj)) {
        flat[key] = flatten(obj[key]);
    }
    return flat;
};

const CurrentFilters = () => {
    const filters = getGlobal("userFilters") || {};
    return (
        <div className="current-filters">
            {Object.keys(filters)
                .filter((x) => getFilter(x))
                .map((filter, index) => {
                    const v = filters[filter];
                    const filterData = getFilter(filter);
                    let valueLabel;
                    const getLabel = (v) => {
                        if (filterData.filterDisplay) return filterData.filterDisplay(v);
                        return v;
                    };
                    if (Array.isArray(v)) {
                        valueLabel =
                            v.length === 1 ? getLabel(v[0]) : `${v.length} Selected`;
                    } else if (filter === "height") {
                        valueLabel = `${getHeight(v.min)} - ${getHeight(v.max)}`;
                    } else if (typeof v.min != "undefined") {
                        valueLabel = `${v.min} - ${v.max}`;
                    } else {
                        valueLabel = v;
                    }
                    return (
                        <div className="current-filter" key={index}>
                            <label>{filterData.filterLabel || filterData.label}</label>
                            <div
                                className="filter-box"
                                onClick={() => setGlobal({ selectedUserFilter: filter })}
                            >
                                <span className="filter-value">{valueLabel}</span>
                                <span className="icon-arrow" />
                                <span
                                    className="icon-close-button"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setFilters({ [filter]: null });
                                    }}
                                />
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};

const removeAllFilters = () => {
    const filters = getGlobal("userFilters") || {};
    Object.keys(filters).forEach((x) => setFilters({ [x]: null }));
};

const hasFiltersApplied = () => {
    const filters = getGlobal("userFilters") || {};
    return Object.keys(filters).some((x) => getFilter(x));
};

const FilterMobileButton = ({ text, isPressed, selectedColor, handleFilterButtonClick }) => {

    return (
        <div onClick={() => handleFilterButtonClick(true)}
             style={{
                 display: "flex", backgroundColor: `${isPressed ? selectedColor : ""}`,
                 border: "1px solid rgba(0, 0, 0, 0.15)", borderRadius: "5px",
                 padding: "4px 8px", alignItems: "center", width: "170px", justifyContent: "space-between"
             }}>
            <span style={{ color: theme.colors.black, fontSize: "14px", fontWeight: 400 }}>
                {text}
            </span>
            <FilterIcon />
        </div>
    )
}
