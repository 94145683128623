import React, { Component } from "react";
import { TEAM } from "../../constants/index";
import { REPEAT_SECTION_TWO } from "../home-pages/texts";
import { repeatingSectionTwo } from "../home-pages/components";
import Button from "../../components/button";

import "./style.scss";

class Team extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="team-page-content flex-column flex-center">
        {repeatingSectionTwo(
          REPEAT_SECTION_TWO.teamPageFirst.title,
          null,
          null,
          null,
          null,
          null
        )}
        {TEAM.map((member, m) => {
          return (
            <div className="team-page-content__section flex-row" key={m}>
              {member.alignLeft && (
                <div
                  className={`image-panel left flex-center ${
                    m === 0 && "extra-margin"
                  }`}
                >
                  <img src={member.photo} />
                </div>
              )}
              <div className="info-panel flex-column">
                <span className="info-panel__name">{member.name}</span>
                <span className="info-panel__job">{member.job}</span>
                {member.currentRole && (
                  <span className="info-panel__current-role">
                    {member.currentRole}
                  </span>
                )}
                <span
                  className={`${
                    m === 0 ? "info-panel__text spacing" : "info-panel__text"
                  }`}
                >
                  {member.text1}
                </span>
                {member.previousRole && (
                  <span className="info-panel__current-role">
                    {member.previousRole}
                  </span>
                )}
                <span
                  className={`${
                    m === 0 ? "info-panel__text spacing" : "info-panel__text"
                  }`}
                >
                  {member.text2}
                </span>
                {member.passionateAbout && (
                  <span className="info-panel__current-role">
                    {member.passionateAbout}
                  </span>
                )}
                <span
                  className={`${
                    m === 0 ? "info-panel__text spacing" : "info-panel__text"
                  }`}
                >
                  {member.text3}
                </span>
                {member.whyChoose && (
                  <span className="info-panel__current-role">
                    {member.whyChoose}
                  </span>
                )}
                <span
                  className={`${
                    m === 0 ? "info-panel__text spacing" : "info-panel__text"
                  }`}
                >
                  {member.text4}
                </span>
                {member.contact && (
                  <div className="contact-carla flex-column">
                    <span className={`info-panel__text`}>{member.text5}</span>
                    <Button
                      value="Contact Carla"
                      click={() =>
                        (window.location = "mailto:carla@datespot.love")
                      }
                    />
                  </div>
                )}
              </div>
              {!member.alignLeft && (
                <div className="image-panel right flex-center">
                  <img src={member.photo} />
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  }
}

export default Team;
