import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

class MainFooter extends React.Component {
  static propTypes = {
    history: PropTypes.object,
  };

  state = {
    selectedTab: null,
  };

  render() {
    const { selectedTab } = this.state;
    const { history } = this.props;

    return (
      <div className="main-footer flex-column">
        <div className="flex-row social">
          <div className="flex-center main-footer__tab">
            <p className="main-footer__item">STAY CONNECTED: </p>
            <a
              href="https://www.facebook.com/datespot.love/"
              className="main-footer__item"
              target="_blank"
            >
              <i className="fab fa-facebook-square"></i>
            </a>
            <a
              href="https://www.instagram.com/datespot.love/"
              className="main-footer__item"
              target="_blank"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              href="https://www.linkedin.com/company/datespot/"
              className="main-footer__item"
              target="_blank"
            >
              <i className="fab fa-linkedin"></i>
            </a>
            <a
              href="https://twitter.com/thedatespot"
              className="main-footer__item"
              target="_blank"
            >
              <i className="fab fa-twitter"></i>
            </a>
          </div>
        </div>
        <div className="flex-row">
          <div className="flex-center main-footer__tab">
            <span
              className={`${
                selectedTab === "singles"
                  ? "main-footer__item selected-tab"
                  : "main-footer__item"
              }`}
              onClick={() => {
                this.setState({ selectedTab: "faq" });
                history.push({ pathname: "/faqs" });
              }}
            >
              FAQ
            </span>
          </div>
          <div className="flex-center main-footer__tab">
            <span
              className={`${
                selectedTab === "terms-of-service"
                  ? "main-footer__item selected-tab"
                  : "main-footer__item"
              }`}
              onClick={() => {
                this.setState({ selectedTab: "terms-of-service" });
                history.push({ pathname: "/terms-of-service" });
              }}
            >
              TERMS OF SERVICE
            </span>
          </div>
          <div className="flex-center main-footer__tab">
            <span
              className={`${
                selectedTab === "privacy-policy"
                  ? "main-footer__item selected-tab"
                  : "main-footer__item"
              }`}
              onClick={() => {
                this.setState({ selectedTab: "privacy-policy" });
                history.push({ pathname: "/privacy-policy" });
              }}
            >
              PRIVACY POLICY
            </span>
          </div>
          <div className="flex-center main-footer__tab">
            <span
              className={`${
                selectedTab === "contact"
                  ? "main-footer__item selected-tab"
                  : "main-footer__item"
              }`}
              onClick={() => {
                this.setState({ selectedTab: "contact" });
                history.push({ pathname: "/contact" });
              }}
            >
              CONTACT
            </span>
          </div>
        </div>
        <div className="copyright">
          Copyright © {new Date().getFullYear()} DateSpot
        </div>
      </div>
    );
  }
}

export default MainFooter;
