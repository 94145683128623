import React, { useState } from "react";
import './Help.scss';
import helpPhoto from 'src/images/help-image.png';
import carlaPhoto from 'src/images/carla&greg.png';
import paypalLogo from 'src/images/paypal.svg';
import venmoLogo from 'src/images/venmo.svg';
import stripeLogo from 'src/images/stripe.svg';
import zelleLogo from 'src/images/zelle.svg';
import MediaQueryProvider from "src/components/MediaQueryProvider";
import { FOR_TABLET_DOWN_QUERY } from "src/data/constants";
import { PlusIcon } from "src/components/icons/Plusicon";
import { MinusIcon } from "src/components/icons/MinusIcon";
import Input from "src/components/Input";
import TextArea from "src/components/TextArea";
import { Button } from "src/components/widgets";
import Api from "_old/src/actions/api";
import { validateEmail } from "src/utils/validation";
import validator from "_old/src/components/utils/validator";
import theme from "src/styles/theme";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const matchLeadPricing = [
  '1. FREE (see section below on client-to-client matches)',
  '2. Identified by you, no client profile shared: $150',
  '3. Identified by you, client profile accepted: $250',
  'In the scenarios where a profile is accepted by a candidate, this can essentially be considered pay-per-date as you can talk with your client about the member first too and even share info or photo screenshots.'
];

const paymentMethods = [
  {
    img: zelleLogo,
    name: 'Zelle',
    mail: '<a href="mailto:carla@datespot.love">carla@datespot.love</a> (preferred method)',
  },
  {
    img: venmoLogo,
    name: 'Venmo',
    mail: '@DateSpot'
  },
  {
    img: paypalLogo,
    name: 'Paypal',
    mail: '@cswiryn'
  },
  {
    img: stripeLogo,
    name: 'Stripe',
    mail: '<a href="https://payments.datespot.love/matchmaker-leads-checkout">Credit card via Stripe:<a/> payment page',
  }
]

const faqs = [
  {
    question: 'Client-To-Client Matches',
    answer: 'To explore a match with your client & a DateSpot client (Client):\n' +
      '\n' +
      'If the DateSpot team does NOT approve/support this particular pairing, you have the ability to secure the person into your network treating them as if they were a free route member. The DateSpot team would ask the Client if he/she is interested in talking with you and joining your network.\n' +
      '\n' +
      'If you prefer to get a Client into your network immediately without exploring a match with the DateSpot team, you can still choose to secure them by paying what they would pay for an intro ($499).'
  },
  {
    question: 'How Do I Refer People?',
    answer: 'We\'re happy to accept ANY leads for any route. You have the ability to receive commission for any revenue generated - that means they can even join the free route and when they buy a service through DateSpot or match with a member or partner client through DateSpot, you get paid!\n' +
      '\n' +
      'No need for a referral links - just have someone sign up at <a href="https://www.datespot.love" target="_blank">www.datespot.love</a> and add you into the "How did you hear about us?" section on the initial signup page. If you\'re a matchmaker, they should select "Matchmaker" and then enter your first name and company, OR your email. If you\'re not a matchmaker, they can select "Other" and will need to enter both your first name and email address so we can correctly attribute people.\n' +
      '\n' +
      'As an added bonus, we do not cap commission at the first payment. You will get paid EVERY TIME your referral matches with someone new.'
  },
  {
    question: 'Where Are My Referrals?',
    answer: 'Your referrals are listed in the "Your Referrals" tab, when you scroll to the bottom. If you referred someone who is not on that list and they completed most of their account, feel free to contact us at <a href="mailto:support@datespot.love">support@datespot.love</a> along with any screenshots of your referral communication with them so we can manually get them attributed.'
  }
]


const HelpSection = () => {

  const [inputError, setInputError] = useState(false);
  const [contactInfo, setContactInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: ''
  });

  const handleContactInfoChange = (e) => {
    setContactInfo({
      ...contactInfo,
      [e.target.name]: e.target.value
    })
  }

  const validateInfo =  () => {
    const { firstName, lastName, email, message } = contactInfo;
    return !!firstName && !!lastName && !!email && !!validator.validateEmail(email) && !!message;
  }

  const handleSend = async () => {
    const isValid = await validateInfo();
    if (!isValid) { setInputError(true); return; }
    setInputError(false);
    try {
      await Api.post({
      url: '/api/send-mm-question',
      data: { ...contactInfo  }
      })
      toast.success("Email sent successfully!")
    } catch (e) {
      toast.error('Something went wrong. Please try again later.')
    }
    setContactInfo({
      firstName: '',
      lastName: '',
      email: '',
      message: ''
    })
  }

  return (
    <MediaQueryProvider query={FOR_TABLET_DOWN_QUERY}>
      {(isTableDown) => (
        <div>
          <section className='helpSection'>
            <div className="helpSection-title">
              {!isTableDown && <h2>Need Help?</h2>}
            </div>
            {isTableDown && <Separator text="Match leading pricing" />}
            <div className='helpSection-content'>
              <div className='helpSection-content__left'>
                <h3>Match Lead Pricing</h3>
                {matchLeadPricing.map((item, index) => (
                    <p key={index}>{item}</p>
                ))}
                <h5>Payment methods</h5>
                {paymentMethods.map((item, index) => (
                    <PaymentMethod
                      key={index}
                      img={item.img}
                      name={item.name}
                      mail={item.mail}
                      link={item?.link}
                    />
                  )
                )}
              </div>
              <div className='helpSection-content__right'>
                <div>
                  <img src={helpPhoto} alt="Help image"/>
                </div>
              </div>
            </div>
          </section>
          <Separator text="FAQs"/>
          <section>
            {faqs.map((item, index) => (
                <Question
                  key={index}
                  question={item.question}
                  answer={item.answer}
                  isSelected={true}
                />
            ))}
          </section>
          <Separator style={{ marginTop: "47px" }}  text="Using the database"/>
          <section className="databaseSection">
            <p>{'For other questions, please reference the '}
              <a href="https://docs.google.com/document/d/1OwOBbz-zB2GGWFjGtxi25f9XdQMJFCAsj9IJ-XsMFWc/edit?usp=sharing">
                 Partner Matchmaker Training Guide.
              </a>
            </p>
            <div className="databaseSection-content">
              <div className="databaseSection-content__left">
                <h3>Need More Help? Send Us a Note!</h3>
                {inputError && <p style={{ color: "red" }}>Please fill all the fields and enter a valid email</p>}
                <div className="databaseSection-content__left-name">
                  <Input
                    name="firstName"
                    onChange={(e) => handleContactInfoChange(e)}
                    placeholder="First Name"
                    inputClassName={inputError ? "input-error" : ""}
                    value={contactInfo.firstName}
                  />
                  <Input
                    name="lastName"
                    onChange={(e) => handleContactInfoChange(e)}
                    placeholder="Last Name"
                    inputClassName={inputError ? "input-error" : ""}
                    value={contactInfo.lastName}
                  />
                </div>
                <Input
                  style={{ marginBottom: "22px" }}
                  name="email"
                  onChange={(e) => handleContactInfoChange(e)}
                  placeholder="Email"
                  inputClassName={inputError ? "input-error" : ""}
                  value={contactInfo.email}
                />
                <div>
                  <TextArea
                    name="message"
                    style={{ borderRadius: "8px", width: "100%", boxSizing: "border-box" }}
                    rows="7"
                    onChange={(e) => handleContactInfoChange(e)}
                    placeholder="Type here"
                    className={inputError ? "input-error" : ""}
                    value={contactInfo.message}
                  />
                </div>
                <div onClick={handleSend}>
                  <Button  text={"Send"} style={{ marginTop: "22px" }} />
                </div>
              </div>
              <div className="databaseSection-content__image">
                <div>
                  <img src={carlaPhoto} alt="Contact image" />
                </div>
              </div>
            </div>
          </section>
          <ToastContainer />
        </div>
      )}
    </MediaQueryProvider>
  )
}

const PaymentMethod = ({ img, name, mail, link }) => {
  return (
    <div className="paymentMethod">
      <div>
        <img src={img} alt={name} />
        <span>{name}</span>
      </div>
      <span dangerouslySetInnerHTML={{ __html: mail }} className="paymentMethod-mail" />
    </div>
  )
}

const Separator = ({ text, ...rest }) => {
  return (
    <div {...rest} className="sectionSeparator">
      <p>{text}</p>
    </div>
  )
}

const Question = ({question, answer}) => {

  const [isSelected, setIsSelected] = useState(false);

  return (
    <>
      <div className="questionContainer">
        <div className="questionAnswer">
          <p className={`questionAnswer-question ${isSelected ? "questionSelected" : ""}`}>{question}</p>
          {isSelected && <p className="questionAnswer-answer" dangerouslySetInnerHTML={{ __html: answer }} />}
        </div>
        <span onClick={() => setIsSelected(!isSelected)}
              className={`question-icon ${isSelected ? "minusIcon" : ""}`}>
        {isSelected ?
          <MinusIcon style={{ marginBottom: "8px", marginTop: "17px" }} />
          :
          <PlusIcon style={{ marginTop: "17px" }} />
        }
      </span>
      </div>
      <div className="questionBorder-container">
        <div className="questionBorder-border" />
      </div>
    </>
  )
}



export default HelpSection;