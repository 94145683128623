import React from "react";
import facebookSvg from "../../images/facebook.svg";

export const FacebookIcon = ({
  width = 24,
  height = 24,
  style = {},
  className,
  ...rest
}) => {
  return (
    <img
      style={{ width, height, ...style }}
      src={facebookSvg}
      alt="Facebook logo"
      className={className}
      {...rest}
    />
  );
};
