import React from "react";

const thumbUrl = (url) => url;

export const Thumbnail = ({ user }) => {
    const initials = user.firstName[0] + user.lastName[0];
    const image = thumbUrl(user.images && user.images[0] && user.images[0].url);
    return (
        <div
            className="user-thumbnail user-image"
            style={{ backgroundImage: `url(${image})`, display: "flex", justifyContent: "center", alignItems: "center" }}
        >
            {!image && initials}
        </div>
    );
};