import prettyName from "pretty-name";
import React from "react";
import { Thumbnail } from "src/components/Thumbnail/Thumbnail";
import { calculateIndividualPercentages, getRegion } from "src/utils/user-data";

export const tableSchemas = {
    user: {
        thumbnail: {
            heading: "Main Photo",
            data: (user) => <Thumbnail user={user} />,
        },
        user_id: {
            heading: "Id",
            data: (user) => (
                <span>
                    {user.user_id}{" "}
                    {user.connected && (
                        <>
                            <br /> {user.firstName} {user.lastName.substring(0, 1)}
                        </>
                    )}
                </span>
            ),
        },
        gender: {
            heading: "Gender",
            data: (user) => user.gender && user.gender.label.substring(0, 1),
        },
        age: { heading: "Age", data: "age" },
        attractiveness: { heading: "Attractiveness", data: "userRating" },
        location: {
            heading: "Location",
            data: (user) => (
                <div>
                    <p>{prettyName(user.city)}</p>
                    <p>{getRegion(user)}</p>
                    <p>{user.country !== "United States of America" && prettyName(user.country) }</p>
                </div>
            ),
        },
        status: {
            heading: "Member Type",
            data: (user) => (
                <span>
          {user.clientStatus === "candidate"
              ? "Candidate (paid connection)"
              : "Client (free connection)"}
        </span>
            ),
        },
        completePercent: {
            heading: "Completed Profile Percent",
            data: (user) =>
                calculateIndividualPercentages(user.images, user.userProgress) + "%",
        },
    },

    matchmaker: {
        name: {
            heading: "Matchmaker",
            data: (u) => `${u.firstName} ${u.lastName}`,
        },
        company: {
            data: "companyName",
        },
        email: {},
        phone: {},
        credits: {
            data: (u) => {
                if (u.referralCredits) {
                    const availableCredits = u.referralCredits?.filter(
                        (x) => x.spendDate === ""
                    );
                    return `${availableCredits.length}`;
                }
                return "0";
            },
        },
    },
};