// Deps
import React, { useState } from "react";
import "./RadioCheckboxField.scss";
// Icons
import { QuestionIcon, CheckIcon } from "../icons";
// Components
import { FormInput } from "./FormInput";

const MoreInfo = ({ option }) => {
  const [showInfo, setShowInfo] = useState(false);

  const handleClick = () => {
    const closeOlderMoreInfo = document.querySelector('.radio-form__more-info__popover-container.mount');
    if (closeOlderMoreInfo) {
      closeOlderMoreInfo.classList.remove('mount');
    }
    setShowInfo(!showInfo);
  };

  const handleDocumentClick = (event) => {
    if (!event.target.closest('.radio-form__more-info')) {
      setShowInfo(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  return (
    <div className="radio-form__more-info">
      <QuestionIcon
        className="radio-form__more-info__icon"
        onClick={handleClick}
      />
      <div
        className={`radio-form__more-info__popover-container${showInfo ? ' mount' : ''
          }`}
      >
        <ul
          role="list"
          className={`radio-form__more-info__popover-content${showInfo ? '' : ' unmount'
            }`}
          onAnimationEnd={() => {
            if (!showInfo) {
              setShowInfo(false);
            }
          }}
        >
          {option.moreInfo.map((info, idx) => (
            <li key={`${info}_${idx}`}>{info}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export const CheckInput = ({
  id,
  value,
  type,
  onCheck,
  className,
  error,
  ...rest
}) => (
  <label
    className={`radio-form__custom-radio${type === "checkbox" ? " checkbox" : ""
      }${error ? " error" : ""}${className ? ` ${className}` : ""}`}
  >
    <input type={type} id={id} value={value} onChange={onCheck} {...rest} />
    <div
      className={`radio-form__checkmark${type === "checkbox" ? " checkbox" : ""
        }`}
    >
      <CheckIcon width="100%" height="100%" />
    </div>
  </label>
);

const RadioCheckboxField = ({
  options = {},
  onCheck,
  onInputChange,
  type = "radio",
  error,
  referralSource = [],
  checkboxRequired,
  ...rest
}) => {
  const shouldDisableInput = (option) =>
    !referralSource.filter((source) => source.id === option.value.id).length;

  const fieldsWithError = ["Free", "Premium", "Freemium"];
  return options.map((option) => (
    <div className="radio-form__field-container" key={option.optionName}>
      <div className="radio-form__checkbox-label-grid">
        <CheckInput
          id={option.id}
          onCheck={(e) => onCheck(option.optionName === "Other" ? "Other" : option.value, e.target.checked)}
          type={type}
          error={((option.optionName === "Other" && option.input4) || fieldsWithError.includes(option.optionName)) && error}
          checked={type === "radio" ? option.value === rest.value : undefined}
          {...rest}
        />
        <label htmlFor={option.id}>{`${option.optionName}${option.input ? ":" : ""
          }`}</label>
      </div>
      {option.input && (
        <div className="radio-form__input-container">
          <span className="radio-form__input-container">
            <FormInput
              disabled={shouldDisableInput(option)}
              className="radio-form__input"
              placeholder={option.placeholder}
              onChange={(value) => onInputChange(value, option.optionName, option.placeholder)}
              error={(option.optionName === "Other" && option.input4) && error}
            />
          </span>

          {option.input2 && (
            <span className="radio-form__input-container">
              <FormInput
                disabled={shouldDisableInput(option)}
                className="radio-form__input"
                placeholder={`at ${option.placeholder2}`}
                onChange={(value) => onInputChange(value, option.optionName, option.placeholder2)}
              />
            </span>
          )}

          {option.input3 && (
            <span className="radio-form__input-container">
              <FormInput
                disabled={shouldDisableInput(option)}
                className="radio-form__input"
                placeholder={`or ${option.placeholder3}`}
                onChange={(value) => onInputChange(value, option.optionName, option.placeholder3)}
              />
            </span>
          )}

          {option.input4 && (
            <span className="radio-form__input-container">
              <FormInput
                disabled={shouldDisableInput(option)}
                className="radio-form__input"
                placeholder={`and ${option.placeholder4}`}
                onChange={(value) => onInputChange(value, option.optionName, option.placeholder4)}
                error={error}
              />
            </span>
          )}
        </div>
      )}
      {option.moreInfo && <MoreInfo option={option} />}
    </div>
  ));
};

export default RadioCheckboxField;
