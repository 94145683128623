// ===============  AUTOMATICALLY GENERATED  =======================
// Any edits made to this file will be overwritten on the next build
// See scripts/build-scripts/index-pages.js for more information

import React from "react";
// import Loadable from 'react-loadable'
import loadable from "@loadable/component";

export const pageList = [
  "ForgotPassword",
  "admin/AdminRegistration",
  "admin/BlogManager",
  "admin/RegionManager",
  "dashboards/AdminSingles",
  "dashboards/MatchmakerDashboard",
];

export const pageIds = [
  {
    url: "/forgot-password",
    id: "forgot-password",
    title: "Forgot Password",
  },
  {
    url: "/admin/user-register",
    id: "admin-user-login",
    title: "Admin User Login",
  },
  {
    url: "/admin/blog",
    id: "page-manager",
    title: "Blog Manager",
  },
  {
    url: "/admin/regions",
    id: "region-manager",
    title: "Region Manager",
  },
  {
    url: "/dashboards/adminsingles",
    id: "singles-admin",
    title: "Singles Admin",
  },
  {
    url: "/dashboards/matchmakerdashboard",
    id: "matchmaker-dashboard",
    title: "Matchmaker Dashboard",
  },
];

export const pageMap = {
  directory: "/",
  children: [
    {
      title: " Forgot Password",
      href: "ForgotPassword",
    },
    {
      directory: "admin",
      children: [
        {
          title: " Admin Registration",
          href: "admin/AdminRegistration",
        },
        {
          title: " Blog Manager",
          href: "admin/BlogManager",
        },
        {
          title: " Region Manager",
          href: "admin/RegionManager",
        },
      ],
    },
    {
      directory: "dashboards",
      children: [
        {
          title: " Admin Singles",
          href: "dashboards/AdminSingles",
        },
        {
          title: " Matchmaker Dashboard",
          href: "dashboards/MatchmakerDashboard",
        },
      ],
    },
  ],
};

export const pageProps = {
  ForgotPassword: {
    id: "forgot-password",
    launchpad_url: "forgot-password",
    url: "/forgot-password",
  },
  "admin/AdminRegistration": {
    id: "admin-user-login",
    className: "about-page",
    launchpad_url: "admin/user-register",
    launchpad_type: "admin",
    url: "/admin/user-register",
  },
  "admin/BlogManager": {
    id: "page-manager",
    className: "padded",
    launchpad_title: "Blog Manager",
    launchpad_url: "admin/blog",
    launchpad_type: "admin",
    launchpad_admin_panel: "true",
    launchpad_icon: "list",
    url: "/admin/blog",
  },
  "admin/RegionManager": {
    id: "region-manager",
    launchpad_title: "Region Manager",
    launchpad_url: "admin/regions",
    launchpad_type: "admin",
    launchpad_admin_panel: "true",
    launchpad_icon: "map-pin",
    url: "/admin/regions",
  },
  "dashboards/AdminSingles": {
    id: "singles-admin",
    className: "dashboard",
    url: "/dashboards/adminsingles",
  },
  "dashboards/MatchmakerDashboard": {
    url: "/dashboards/matchmakerdashboard",
  },
};

export const pageLinks = pageIds.map((page) => page.url);

export const pageComponents = {
  ForgotPassword: loadable(() => import("pages/ForgotPassword.jsx")),
  "admin/AdminRegistration": loadable(() =>
    import("pages/admin/AdminRegistration.jsx")
  ),
  "admin/BlogManager": loadable(() => import("pages/admin/BlogManager.jsx")),
  "admin/RegionManager": loadable(() =>
    import("pages/admin/RegionManager.jsx")
  ),
  "dashboards/AdminSingles": loadable(() =>
      import("pages/dashboards/admin/adminSingles/AdminSingles.jsx")
  ),
  "dashboards/MatchmakerDashboard": loadable(() =>
    import("pages/dashboards/matchmaker/MatchmakerDashboard.jsx")
  ),
};

// ===============  AUTOMATICALLY GENERATED  =======================
// Any edits made to this file will be overwritten on the next build
// See scripts/build-scripts/index-pages.js for more information
