import React, {useState} from "react";
import {tableSchemas} from "src/constants/tables-schemas";
import {DropDown} from "src/components/widgets";
import {UserAction} from "src/components/Table/Row/UserAction";
import {Column} from "src/components/Table/Column/Column";
import {postReload} from "src/api/api";
import {Thumbnail} from "src/components/Thumbnail/Thumbnail";
const favoriteUser = async (user) => {
    await postReload(`/favorite-user/${user._id}`);
}
const unFavoriteUser = async (user) => {
    await postReload(`/unfavorite-user/${user._id}`);
}
const lockUser = async (user) => {
    await postReload(`/lock-user/${user._id}`);
}
const unlockUser = async (user) => {
    await postReload(`/unlock-user/${user._id}`);
}
const contactUser = async (user) => {
    console.log("hey in here", user);
    await postReload(`/contact-user/${user._id}`, {
        message: `We just emailed Carla about Member #${user.user_id} to explore this potential match!`,
    });
};

export const UserRow = ({ user, attributes, onSelectUser, loadUsers, isMobile }) => {

    const [menu, setMenu] = useState(false);
    const schema = tableSchemas.user;

    const showMenu = (e) => {
        e.stopPropagation();
        setMenu(true);
    };

    return (
        <tr
            className="user-row"
            key={`user-row-${user._id}`}
            onMouseLeave={() => setMenu(false)}
            onClick={() => onSelectUser && onSelectUser(user)}
        >
            {!isMobile ? (
                <Column {...{schema, attributes, user}} />
            ) : (
                <>
                    {user &&
                        <div onClick={() => {}}
                             style={{ display: "flex", gap: "12px" }}>
                            <div>
                                <Thumbnail user={user} />
                            </div>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <span style={{ fontSize: "15px", color: "#3FC6C0", fontWeight: 500, marginTop: "8px", marginBottom: "4px" }}>{user.user_id}</span>
                                <span style={{ fontSize: "12px" }}><b>Location: </b>{user.city}, {user.country}</span>
                                <span style={{ fontSize: "12px" }}> <b>Age: </b>{user.age}</span>
                                <span style={{ fontSize: "12px" }}><b>Attractiveness: </b>{user.userRating ? user.userRating : "Not rated"}</span>
                                <span style={{ fontSize: "12px" }}><b>Member Type: </b>{user.clientStatus === "client" ? "Client (free connection)" : "Candidate (paid connection)"}</span>
                            </div>
                        </div>
                    }
                </>
            )}
            <td className={!isMobile && "indicators"} onClick={(e) => e.stopPropagation()}>
                {user.potential && <span className="icon-heart red"/>}
                {user.contacted && <span className="icon-contacted"/>}
                {user.locked && <span className="icon-lock cyan"/>}
                {user.connected && <span className="fa fa-link"/>}
            </td>

            <td style={isMobile ? { verticalAlign: "top", paddingTop: "12px" } : {}} onClick={showMenu} className="actions">
                <span className="icon-more"/>
                <div style={{ position: "relative" }}  className="action-wrapper">
                    <DropDown className="action-menu" open={menu}>
                        <UserAction
                            icon="eye"
                            onClick={() => onSelectUser && onSelectUser(user)}
                        >
                            View Profile
                        </UserAction>
                        {!user.potential && (
                            <UserAction icon="heart" onClick={() => favoriteUser(user)}>
                                Add to My Potentials
                            </UserAction>
                        )}
                        {user.favorited && (
                            <UserAction icon="heart" onClick={() => unFavoriteUser(user)}>
                                Remove from My Potentials
                            </UserAction>
                        )}
                        {!user.locked && !user.otherLocked && (
                            <UserAction icon="lock" onClick={() => lockUser(user)}>
                                Lock User
                            </UserAction>
                        )}
                        {user.locked && (
                            <UserAction icon="lock" onClick={() => unlockUser(user)}>
                                Unlock User
                            </UserAction>
                        )}
                        {!user.contacted && !user.otherLocked && !user.connected && (
                            <UserAction icon="contacted" onClick={() => contactUser(user)}>
                                Contact Member
                            </UserAction>
                        )}
                    </DropDown>
                </div>
            </td>
        </tr>
    );
}