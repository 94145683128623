import React from "react";
import {
  getGlobal,
  setGlobal,
  loadGlobal,
  createDoc,
  Spinner,
  updateDoc,
  deleteDoc,
  Modal,
  Collapsible,
  apiPost,
  helpers,
} from "launchpad";
import _ from "lodash";
import styled from "styled-components";
export { notify, NotificationContainer } from "./notifications";
export {
  getUpload,
  asyncUpload,
  getBase64,
  ImageUploader,
  getThumbUrl,
} from "./upload";
export { confirm, confirmDelete, ConfirmModal } from "./confirm";
export { validate, Validator } from "./validation";
export { useSetState } from "./custom-hooks";

let loaders = [];
let loadIndicator = null;

export const money = (m) => `$${m.toFixed(2)}`;

export function titleCase(string) {
  return _.startCase(_.toLower(string));
}

export const startLoad = () => {
  const ts = new Date().getTime();
  loaders.push(ts);
  if (loadIndicator) loadIndicator.forceUpdate();
  return ts;
};

export const stopLoad = (ts) => {
  loaders = loaders.filter((x) => x !== ts);
  if (loadIndicator) loadIndicator.forceUpdate();
};

const LoadContainer = styled.div`
  pointer-events: none;
  position: fixed;
  bottom: 0px;
  left: 0px;
  height: 300px;
  width: 300px;
  color: white;
  transition: opacity 0.3s;
  background: linear-gradient(
    215deg,
    rgba(240, 249, 255, 0) 37%,
    rgba(203, 235, 255, 0.22) 56%,
    rgba(2, 1, 1, 0.7) 97%
  );
  .fa-spinner {
    position: absolute;
    bottom: 30px;
    left: 30px;
    font-size: 40px;
  }
`;

export class LoadIndicator extends React.Component {
  componentDidMount = () => (loadIndicator = this);
  render = () => (
    <LoadContainer
      className="load-container"
      style={{ opacity: loaders.length ? 1 : 0 }}
    >
      <Spinner />
    </LoadContainer>
  );
}

//TODO: some of these sections are getting big enough to justify their own files

export function updateDB(collection, key, entry, options) {
  let col = getGlobal(collection);
  let existing = col.find((d) => d._id === key);
  let operation = null;
  if (existing) {
    operation = updateDoc(
      collection,
      Object.assign({ _id: existing._id, slug: existing.slug }, entry)
    );
  } else {
    operation = createDoc(collection, entry);
  }

  operation.then(() => {
    if (!options.skipReload) {
      loadGlobal(collection);
    }
    if (typeof options.complete === "function") {
      options.complete();
    }
  });
}

let debounceTimer = null;
let updated = {};

// debounced updates (consolidates rapid updates to the same object to one request)
export function updateDBAttributes(collection, key, atts, options) {
  if (!updated[collection]) {
    updated[collection] = {};
  }
  let item = updated[collection][key];
  if (typeof item === "undefined" && getGlobal(collection)) {
    item = getGlobal(collection)[key];
  }
  if (!item) {
    item = {};
  }
  for (let key in atts) {
    item[key] = atts[key];
  }
  updated[collection][key] = item;

  if (debounceTimer) {
    clearTimeout(debounceTimer);
  }
  debounceTimer = setTimeout(() => {
    // push all updates
    for (let col in updated) {
      for (let key in updated[col]) {
        updateDB(col, key, updated[col][key], {
          skipReload: options.skipReload,
        });
        delete updated[col][key];
      }
    }
  }, 500);
}

// lookup item in collection by given attribute/value
export function getBy(collection, attr, val) {
  let col = getGlobal(collection) || [];
  return col.find((d) => d[attr] === val);
}

// lookup item in collection by its slug
export function getBySlug(collection, slug, options) {
  if (options.exceptions && options.exceptions.includes(slug)) return false;
  return getBy(collection, "slug", slug);
}

// get slug that's unique for given collection based on given string (title)
export function getSlug(title, collection, options) {
  collection = collection || [];
  options = options || {};

  let slug = title.trim().replace(/ /g, "-");
  if (options.allowSlashes) {
    slug = slug.replace(/[^a-zA-Z_0-9\/\-]/g, "");
  } else {
    slug = slug.replace(/[^a-zA-Z_0-9\-]/g, "");
  }

  if (options.skipValidation) return slug;

  slug = slug.toLowerCase();

  let unique = false;
  let increment = 1;

  // try appending _2, _3, etc until slug is unique
  while (unique === false) {
    let test = slug;
    let existing = false;
    if (increment > 1) {
      test = slug + "-" + increment;
    }
    if (collection[0] && typeof collection[0] == "string") {
      existing =
        collection.map((l) => l.toLowerCase()).includes(test) &&
        !(options.exceptions && options.exceptions.includes(test));
    } else {
      existing = getBySlug(collection, test, options);
    }
    if (existing) {
      increment++;
    } else {
      unique = true;
      return test;
    }
  }
}

// if(module.hot) module.hot.accept()
