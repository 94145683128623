import React, { Component } from "react";
import PropTypes from "prop-types";
import InputText from "../input-text";
import Button from "../button";
import Validator from "../utils/validator";
import CheckBox from "../check-box";
import ReactGA from "react-ga";

import "./style.scss";

class MMSignUpForm extends Component {
  state = {
    email: null,
    password: null,
    lastName: null,
    firstName: null,
    phone: null,
    companyName: null,
    siteUrl: null,
    role: "matchmaker",
    errors: {},
    validFields: {},
    agreeWithTermsAndPolicies: false,
  };

  signUp = () => {
    const {
      email,
      password,
      firstName,
      lastName,
      phone,
      role,
      companyName,
      siteUrl,
    } = this.state;

    if (this.validate()) {
      let approved = false;
      if (this.props.comesFromAdmin) {
        approved = true;
      }

      const callback = (err, res) => {
        if (res && res.data) {
          this.props.signUpCallback(res.data);
        }
      };

      this.props.signUpUser(
        {
          email,
          password,
          firstName,
          lastName,
          phone,
          role,
          companyName,
          approved,
          siteUrl,
        },
        callback
      );

      ReactGA.event({
        category: "Matchmaker signing in",
        action: "Created an Account",
      });
    }
  };
  // Validation
  validate = () => {
    let isValid = true;
    const {
      errors,
      email,
      password,
      retypePassword,
      firstName,
      lastName,
      agreeWithTermsAndPolicies,
      phone,
      companyName,
      siteUrl,
    } = this.state;
    if (!firstName) {
      errors.firstNameError = "Required";
      isValid = false;
    }
    if (!lastName) {
      errors.lastNameError = "Required";
      isValid = false;
    }
    if (!email) {
      errors.emailError = "Required";
      isValid = false;
    }
    if (!phone) {
      errors.phoneError = "Required";
      isValid = false;
    }
    if (!companyName) {
      errors.companyNameError = "Required";
      isValid = false;
    }
    if (!siteUrl) {
      errors.siteUrlError = "Required";
      isValid = false;
    }
    if (!password) {
      errors.passwordError = "Required";
      isValid = false;
    }

    if (!retypePassword) {
      errors.retypePasswordError = "Required";
      isValid = false;
    }
    if (!agreeWithTermsAndPolicies) {
      errors.agreeWithTermsAndPoliciesError =
        "Please check this box if you want to proceed";
      isValid = false;
    }
    Object.values(errors).forEach((error) => {
      if (error) {
        isValid = false;
      }
    });

    this.setState({ errors });

    return isValid;
  };

  setData = (field, value, fieldError, fieldValid) => {
    const { errors, validFields } = this.state;
    this.setState({
      [field]: value,
      errors: { ...errors, [fieldError]: null },
      validFields: { ...validFields, [fieldValid]: null },
    });
  };

  checkIfMailExists = (email) => {
    const { errors, validFields } = this.state;
    const callback = (err, res) => {
      if (res && res.data.emailAlreadyExists) {
        this.setState({
          errors: { ...errors, emailError: "Email exists" },
          validFields: { ...validFields, emailValid: false },
        });
      }
    };
    this.props.authActions.verifyIfEmailExists(email, callback, "matchmaker");
  };

  render() {
    const { agreeWithTermsAndPolicies, errors } = this.state;
    const { history } = this.props;
    const renderInputs = this._renderInputs();
    return (
      <div className="sign-up-container flex-column matchmaker-sign-up">
        <div className="sign-up-container__new-title">Partner Signup</div>
        <div className="sign-up-container__form">
          {renderInputs}
          <div className="agree-terms flex-row flex-vertical-center flex-horizontal-center">
            <CheckBox
              checked={agreeWithTermsAndPolicies}
              clicked={() =>
                this.setState({
                  agreeWithTermsAndPolicies: !agreeWithTermsAndPolicies,
                  errors: { ...errors, agreeWithTermsAndPoliciesError: null },
                })
              }
            />
            <div style={{ lineHeight: 14 + "px" }}>
              <span>You agree to the</span>
              <span
                onClick={() => history.push({ pathname: "/terms-of-service" })}
                className="underlined"
              >
                Terms of Service
              </span>
              <span> and if you're applying as a matchmaker, the </span>
              <span
                onClick={() =>
                  history.push({ pathname: "/matchmaker-agreement" })
                }
                className="underlined"
              >
                Matchmaker Agreement
              </span>
            </div>
          </div>
          {errors.agreeWithTermsAndPoliciesError && (
            <div className="error-custom agree-terms">
              {errors.agreeWithTermsAndPoliciesError}
            </div>
          )}
          <div className="flex-center">
            <Button
              click={() => this.signUp()}
              value="SIGN UP"
              customClass="yellow two-twenty-width"
            />
          </div>
        </div>
      </div>
    );
  }

  _renderInputs = () => {
    const {
      email,
      password,
      retypePassword,
      lastName,
      firstName,
      phone,
      errors,
      validFields,
      companyName,
      siteUrl,
    } = this.state;
    return (
      <div>
        <InputText
          wrapperClassName="flex-column email-input"
          placeholder="Email"
          error={errors.emailError}
          isValid={email && validFields.emailValid}
          onChange={(e) =>
            this.setData("email", e.target.value, "emailError", "emailValid")
          }
          onBlur={(e) =>
            email &&
            this.checkForValidationErrors(
              "email",
              e.target.value,
              "emailError",
              "emailValid"
            )
          }
          value={email}
        />
        <div className="flex-row">
          <InputText
            wrapperClassName="flex-column half-width names-first"
            placeholder="First Name"
            error={errors.firstNameError}
            isValid={firstName && validFields.firstNameValid}
            onBlur={(e) =>
              firstName &&
              this.checkForValidationErrors(
                "name",
                e.target.value,
                "firstNameError",
                "firstNameValid"
              )
            }
            onChange={(e) =>
              this.setData(
                "firstName",
                e.target.value,
                "firstNameError",
                "firstNameValid"
              )
            }
            value={firstName}
          />
          <InputText
            wrapperClassName="flex-column half-width names-second"
            placeholder="Last Name"
            error={errors.lastNameError}
            isValid={lastName && validFields.lastNameValid}
            onBlur={(e) =>
              lastName &&
              this.checkForValidationErrors(
                "name",
                e.target.value,
                "lastNameError",
                "lastNameValid"
              )
            }
            onChange={(e) =>
              this.setData(
                "lastName",
                e.target.value,
                "lastNameError",
                "lastNameValid"
              )
            }
            value={lastName}
          />
        </div>

        <InputText
          wrapperClassName="flex-column"
          placeholder="Phone number"
          error={errors.phoneError}
          isValid={phone && validFields.phoneValid}
          onBlur={(e) =>
            phone &&
            this.checkForValidationErrors(
              "phone",
              e.target.value,
              "phoneError",
              "phoneValid"
            )
          }
          onChange={(e) =>
            this.setData("phone", e.target.value, "phoneError", "phoneValid")
          }
          value={phone}
        />
        <InputText
          wrapperClassName="flex-column"
          placeholder="Company Name"
          error={errors.companyNameError}
          isValid={companyName && validFields.companyNameValid}
          onBlur={(e) =>
            companyName &&
            this.checkForValidationErrors(
              "no-validation",
              e.target.value,
              "companyNameError",
              "companyNameValid"
            )
          }
          onChange={(e) =>
            this.setData(
              "companyName",
              e.target.value,
              "companyNameError",
              "companyNameValid"
            )
          }
          value={companyName}
        />
        <InputText
          wrapperClassName="flex-column"
          placeholder="Website URL"
          error={errors.siteUrlError}
          isValid={siteUrl && validFields.siteUrlValid}
          onBlur={(e) =>
            siteUrl &&
            this.checkForValidationErrors(
              "no-validation",
              e.target.value,
              "siteUrlError",
              "siteUrlValid"
            )
          }
          onChange={(e) =>
            this.setData(
              "siteUrl",
              e.target.value,
              "siteUrlError",
              "siteUrlValid"
            )
          }
          value={siteUrl}
        />
        <InputText
          wrapperClassName="flex-column"
          placeholder="Password (at least 6 characters)"
          type="password"
          error={errors.passwordError}
          isValid={password && validFields.passwordValid}
          onChange={(e) =>
            this.setData(
              "password",
              e.target.value,
              "passwordError",
              "passwordValid"
            )
          }
          onBlur={(e) =>
            password &&
            this.checkForValidationErrors(
              "password",
              e.target.value,
              "passwordError",
              "passwordValid"
            )
          }
          value={password}
        />
        <InputText
          wrapperClassName="flex-column"
          placeholder="Retype Password"
          type="password"
          error={errors.retypePasswordError}
          isValid={retypePassword && validFields.retypePasswordValid}
          onChange={(e) =>
            this.setData(
              "retypePassword",
              e.target.value,
              "retypePasswordError",
              "retypePasswordValid"
            )
          }
          onBlur={() => retypePassword && this.checkPasswords()}
          onEnterKeyPressed={() => this.signUp()}
          value={retypePassword}
        />
      </div>
    );
  };

  checkPasswords = () => {
    const { password, retypePassword, errors, validFields } = this.state;
    if (Validator.validatePassword(password)) {
      if (password !== retypePassword) {
        this.setState({
          errors: {
            ...errors,
            passwordError: "Doesn't match password",
            retypePasswordError: "Doesn't match password",
          },
        });
      } else {
        this.setState({
          validFields: {
            ...validFields,
            passwordValid: true,
            retypePasswordValid: true,
          },
          errors: { ...errors, passwordError: null, retypePasswordError: null },
        });
      }
    } else {
      this.setState({
        errors: {
          ...errors,
          passwordError:
            "Password must be at least 6 characters and have at least 1 letter and 1 number",
        },
      });
    }
  };

  checkForValidationErrors = (type, value, stateError, validField) => {
    let valid;
    switch (type) {
      case "name":
        valid = Validator.validateName(value);
        this.setErrorsOrValids(valid, validField, stateError, "Invalid");
        break;
      case "email":
        valid = Validator.validateEmail(value);
        this.checkIfMailExists(value);
        this.setErrorsOrValids(valid, validField, stateError, "Invalid format");
        break;
      case "password":
        valid = Validator.validatePassword(value);
        this.setErrorsOrValids(
          valid,
          validField,
          stateError,
          "Password must be at least 6 characters and have at least 1 letter and 1 number"
        );
        break;
      case "phone":
        valid = Validator.validatePhoneNumber(value);
        this.setErrorsOrValids(valid, validField, stateError, "Invalid");
        break;
      case "no-validation":
        this.setErrorsOrValids(true, validField, stateError, "");
        break;
      default:
        break;
    }
  };

  setErrorsOrValids = (valid, validField, stateError, message) => {
    const { validFields, errors } = this.state;
    valid
      ? this.setState({ validFields: { ...validFields, [validField]: true } })
      : this.setState({ errors: { ...errors, [stateError]: message } });
  };
}

MMSignUpForm.propTypes = {
  signUpUser: PropTypes.func,
  signUpCallback: PropTypes.func,
  history: PropTypes.object,
  comesFromAdmin: PropTypes.bool,
  authActions: PropTypes.object,
};

export default MMSignUpForm;
