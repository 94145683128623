@import "src/scss/variables";
@import "src/scss/utilities";

.other-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @include for-desktop {
    padding: 40px 60px;
  }

  &__field-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &__title {
      color: $teal;
      font-size: 1.125rem;
      font-family: Montserrat, sans-serif;
      margin: 0;
      font-weight: 500;
      &.error {
        color: $red;
      }
    }

    &__language {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      &__add {
        color: $aqua;
        font-size: 1rem;
        cursor: pointer;
        margin-top: 0.5rem;

        .underline {
          text-decoration: underline;
        }
      }
    }
  }

  &__language-fields-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__language-field {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 1rem;

    &__proficiency {
      span {
        font-size: 0.875rem;
      }
    }

    @include for-tablet-down {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  }

  &__continue-button-container {
    display: flex;
    justify-content: flex-start;
    margin-top: 1rem;
  }
}
