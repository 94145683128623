import React, { useEffect, useRef, useState } from "react";
import { titleCase, useSetState } from "../../../launchpad/helpers";
import { apiGet, apiPost, getGlobal, setGlobal, Spinner } from "../../../launchpad";
import moment from "moment/moment";
import SearchInput from "_old/src/components/search-input";
import PaginationBar from "src/components/pages/dashboards/PaginationBar";
import { tableSchemas } from "src/constants/tables-schemas";
import { MMRow } from "src/components/Table/Row/MMRow";
import { UserRow } from "src/components/Table/Row/UserRow";
import { LoadingScreen } from "../loadingScreen/LoadingScreen";

function getShowingText(users) {
    let base = (users.page - 1) * users.per_page;
    base += users.total > 0 ? 1 : 0;
    const max = Math.min(users.total, base + (users.per_page - 1));
    return `${Object.keys(getGlobal("userFilters") || {}).length > 0 ? "Filters " : ""
    } Showing ${base}-${max} of ${users.total} ${Object.keys(getGlobal("userFilters") || {}).length > 0
        ? "filtered members"
        : "total members"
    }`;
}
function setupUser(user, type = "user") {
    let newUser = { ...user };
    const viewer = getGlobal("activeUser");
    if (viewer.role === "matchmaker" && type === "user") {
        for (const x of [
            "connected",
            "contacted_by",
            "favorited_by",
            "locked_by",
            "rejected",
        ]) {
            if (!newUser[x]) newUser[x] = [];
        }

        newUser.mmRating = newUser.ratings.find(
            (x) => x.matchMakerId === viewer._id
        );

        newUser.connections = newUser.connected;
        newUser.connected = !!newUser.connected.find((x) => x.mm === viewer._id);
        newUser.contactRequest = newUser.contacted_by.find(
            (x) => x.mm === viewer._id
        );
        newUser.contacted = !!newUser.contactRequest;
        newUser.favorited = !!newUser.favorited_by.find(
            (x) => x.mm === viewer._id
        );
        newUser.locked = !!newUser.locked_by.find((x) => x.mm === viewer._id);
        newUser.otherLocked = !!newUser.locked_by.find(
            (x) => x.mm !== viewer._id
        );
        newUser.potential =
            newUser.favorited || newUser.contacted || newUser.locked;

        newUser.getContactTiming = () => {
            const today = moment();
            const hoursSinceContact =
                newUser.contactRequest && newUser.contactRequest.since
                    ? today.diff(newUser.contactRequest.since, "hours")
                    : 0;
            const contactDays = Math.floor((72 - hoursSinceContact) / 24);
            const contactHours = (72 - hoursSinceContact) % 24;
            return { hoursSinceContact, contactDays, contactHours };
        };
    }
    return newUser;
};

export const Table = ({
                          filters,
                          per_page,
                          role,
                          className,
                          query,
                          onSelectUser,
                          onSelectUserCredits,
                          onLoadUsers,
                          headers,
                          isMobile,
                      }) => {

    const containerRef = useRef(null);
    const [usersLoaded, setUsersLoaded] = useState(false);

    const [search, setSearch] = useState('');

    const [state, setState] = useSetState({
        users: {
            data: [],
            per_page: 10,
            page: 1,
            total: 0,
            all: 0,
        },
        page: 1,
        query: "",
    });

    const { users, page } = state;
    const [filteredUsers, setFilteredUsers] = useState(users.data)

    const loadUsers = async () => {
        await setUsersLoaded(false);
        let users = [];
        users = await apiPost(role + "s", {
            filters: filters,
            page,
            per_page,
            query,
        });
        users.data = users.data.map((user) => setupUser(user, role));
        if (role === "user") {
            users.data = users.data.filter((x) => x.suspicion?.profile === false);
        }
        if (onLoadUsers) onLoadUsers(users);
        setState({ users });
        setUsersLoaded(true);
    };

    useEffect(() => {
        setFilteredUsers(users.data);
    }, [users.data]);

    useEffect(() => {
        loadUsers();
    }, [JSON.stringify(filters), page, query]);

    useEffect(() => {
        // I really dislike this pattern, unnecessary render
        if (getGlobal("usersTableNeedsRefresh")) {
            loadUsers();
            setGlobal({ usersTableNeedsRefresh: false });
        }
    });

    const handleTextChange = async (text) => {
        await setSearch(text)
        const matchmakers = await apiGet(`get-filtered-matchmakers/${text}`)
        if (matchmakers && Array.isArray(matchmakers)) {
            await setFilteredUsers(matchmakers);
        }
    }
    const setPage = async (page) => {
        setState({ page });
        if (containerRef.current) containerRef.current.scrollTop = 0;
    };

    // modify these lists to dynamically set which columns are shown
    let headerTemplate = {
        user: [
            "thumbnail",
            "user_id",
            "gender",
            "age",
            "attractiveness",
            "location",
            "status",
            "connections",
        ],
        matchmaker: [
            // 'Matchmaker', 'Company', 'Email', 'Phone', 'Actions'
        ],
    };

    const columns = headers || headerTemplate[role];
    const Row = role === "matchmaker" ? MMRow : UserRow;

    return (
        <>
            {!usersLoaded && <LoadingScreen/>}
            {!isMobile && (
                <div className={`${className || ""} ${role} users-list`}>
                    <div className="table-container" ref={containerRef}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', flexDirection: 'row' }}>
                            {role === "matchmaker" &&
                                (<div className="search-bar-container">
                                        <SearchInput
                                            value={search}
                                            onChange={(e) => handleTextChange(e.target.value)}
                                            placeholder="Search by name..."
                                        />
                                    </div>
                                )
                            }
                            <div className="member-count">{getShowingText(users)}</div>
                        </div>
                        <table>
                            <thead style={{ position: "relative", zIndex: 2, height: "49px" }}>
                            <tr>
                                {columns.map((x, i) =>
                                    tableSchemas[role][x] ? (
                                        <th style={{ fontWeight: 600 }} key={i}>
                                            {tableSchemas[role][x].heading.toUpperCase() || titleCase(x)}
                                        </th>
                                    ) : (
                                        <></>
                                    )
                                )}
                                <th colSpan={2}>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {filteredUsers &&
                                filteredUsers.map((user) => (
                                    <Row
                                        onSelectUser={onSelectUser}
                                        onSelectUserCredits={onSelectUserCredits}
                                        key={user._id}
                                        user={user}
                                        attributes={columns}
                                        loadUsers={loadUsers}
                                    />
                                ))}
                            </tbody>
                        </table>
                        {filteredUsers && !filteredUsers.length && (
                            <p className="empty-table">No results found for those criteria</p>
                        )}
                    </div>
                </div>
            )}
            {isMobile && role === "user" && (
                <div style={{ overflowY: "scroll" }} className="user-row-mobile">
                    {users && users.data.map((user) => (
                        <UserRow onSelectUser={onSelectUser} isMobile={true} user={user} />
                    ))}
                </div>

            )}
            <PaginationBar
                usersLoaded={usersLoaded}
                total={users.total}
                per_page={users.per_page}
                page={page}
                onChange={(page) => setPage(page)}
            />
        </>
    );
}
