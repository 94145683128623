import theme from "src/styles/theme";
import React, { useState } from 'react';
const MMListSideBar = ({ adminActions, adminReducer }) => {
    const { showApprovedMM } = adminReducer;
    const handleTabChange = (value) => {
        adminActions.addMatchMakerFilter({ pending: !value })
        adminActions.setShowMatchmakers(value)
    }

    return <div style={{ display: "flex", justifyContent: "space-around", padding: "2vh", height: "8svh" }}>
        <Button onChange={() => handleTabChange(true)} active={showApprovedMM}>Matchmakers</Button>
        <Button onChange={() => handleTabChange(false)} active={!showApprovedMM}>Pending</Button>
    </div>
}

const Button = ({ children, onChange, active }) => {
    return <div onClick={onChange}
        style={{
            display: "flex", backgroundColor: `${active ? theme.colors.yellow : ""}`,
            border: "1px solid rgba(0, 0, 0, 0.15)", borderRadius: "5px",
            padding: "4px 8px", alignItems: "center", width: "170px", justifyContent: "space-between"
        }}>
        <span style={{ color: theme.colors.black, fontSize: "14px", fontWeight: 400, textAlign: "center", width:"100%" }}>
            {children}
        </span>
    </div>
}

export default MMListSideBar;