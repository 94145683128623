import React from "react"
const EditIcon = ({size=20, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 15 16"
        {...props}
    >
        <path
            stroke="#43C2BC"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m5.886 12.77-4.011 1.338 1.337-4.011m2.674 2.674 6.688-6.688A1.892 1.892 0 0 0 9.899 3.41l-6.687 6.688m2.674 2.674-2.674-2.675m1.337 1.338L9.45 6.532M8.56 4.748l2.674 2.675"
        />
    </svg>
)
export default EditIcon
