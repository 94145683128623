@import "../../../../../src/scss/variables";
@import "../../../../../src/scss/utilities";

.photo-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__message {
    color: $teal;
    font-size: 18px;
    font-family: Montserrat, sans-serif;
    margin-bottom: 6px;
    font-weight: 500;
  }

  .allow-photos {
    .checkbox__label {
      color: #53585a;
      font-family: Montserrat, sans-serif;
      font-size: 16px;
    }
  }
  .upload-photos {
    min-height: 173px;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(171px, 1fr));

    .upload-thumbs-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      > div:first-child {
        margin-left: 172px;
      }
    }

    .upload-thumb-inner {
      display: flex;
      min-width: 0;
      width: 100%;
    }

    .upload-thumb {
      border: 1px solid $greyLightest;
      border-radius: 8px;
      display: inline-flex;
      width: 100%;
      aspect-ratio: 1;

      .profile-icon {
        position: absolute;
        top: 10px;
        left: 10px;
        margin: 0;
        width: 30px;
        height: 30px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .icon-more {
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
        border: 1px solid #ffda65;
        border-radius: 50%;
        background-color: #ffda65;
        padding: 2px;
      }

      .photo-menu {
        border: 1px solid rgba(84, 88, 90, 0.1);
        border-radius: 2px;
        background-color: #fff;
        box-shadow: 0 0 10px 0 rgba(84, 88, 90, 0.2);
        height: auto;
        width: 120px;
        position: absolute;
        right: -10px;
        top: 50px;
        z-index: 11;
        > span {
          border-bottom: 1px solid rgba(84, 88, 90, 0.1);
          cursor: pointer;
          height: 40px;
          padding: 0 11px;
          user-select: none;
          background: #fff;
          color: #54585a;
          font-family: Montserrat, sans-serif;
          font-size: 14px;
          &:hover {
            background-color: #ffda65;
          }
        }
        > span:last-child {
          border-bottom: none;
        }
      }
    }

    .upload-thumb-img {
      border-radius: 4px;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__template {
      border: 1px solid $greyLightest;
      border-radius: 8px;
      background-color: #fff;
      cursor: pointer;
      width: 100%;
      aspect-ratio: 1;

      > div {
        padding: 2rem;
      }
    }

    &__zone {
      display: flex;
      flex-direction: column;
      height: 100%;
      box-sizing: border-box;
      align-items: center;
      justify-content: center;
      gap: 2rem;

      @include for-tablet-down {
        gap: 1rem;
      }
    }

    &__plus {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 34px;
      width: 34px;
      position: relative;

      &::before,
      &::after {
        content: "";
        display: block;
        width: 34px;
        height: 6px;
        background-color: $textLighter;
        position: absolute;
      }

      &::before {
        transform: rotate(90deg);
      }
    }
    &__placeholder {
      color: #54585a;
      font-size: 14px;
      font-family: Montserrat, sans-serif;
      text-align: center;
    }
  }
}
