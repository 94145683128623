import { Spinner } from "launchpad"
import React from "react"
import { createPortal } from "react-dom"
import "./LoadingScreen.scss"

export const LoadingScreen = () => {
    return createPortal(
        <div className="spinner-container">
            <Spinner className="spinner"/>
        </div>, document.body)
}