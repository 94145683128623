@import "src/scss/variables.scss";
@import "src/scss/utilities.scss";

.sign-up__container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 4rem;

  .sign-up__left-side {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    padding: 48px 0 80px;

    .sign-up__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;

      .sign-up__title {
        font-size: 2.25rem;
        line-height: 2.875rem;
        color: #2a2d2e;
        margin: 0;
        text-transform: capitalize;
      }

      .sign-up__divider {
        width: 6.25rem;
        height: 0.5rem;
        border-radius: 10rem;
        background-color: #34c7c0;
      }
    }
  }

  .sign-up__form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    .sign-up__fields-container {
      display: grid;
      grid-template-rows: repeat(3, 1fr);
      grid-gap: .6rem;
      width: 100%;

      .sign-up__input-pair {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        width: 100%;
      }
    }

    .sign-up__forgot-password {
      font-weight: 600;
      text-decoration: none;
      align-self: flex-start;
    }

    .sign-up__selects-container {
      display: grid;
      gap: .35rem;
    }

    .sign-up__expandable-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .sign-up__about-us-expandable {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: .5rem;

        .sign-up__circle-chevron {
          transition: transform .2s ease-in-out;
          transform: rotate(0deg);
          cursor: pointer;

          &.expanded {
            transform: rotate(180deg);
          }
        }
      }

      .sign-up__about-us-title{
        display: flex;
        align-self: flex-start;
        font-weight: 600;
        color: #003b4d;
        font-size: 1rem;
        margin: 0;
      }

      .sign-up__about-us-content {
        display: flex;
        flex-direction: column;
        gap: .5rem;

        .sign-up__subtitle {
          align-self: flex-start;
          margin-left: 1.2rem;
        }
      }
    }

    .sign-up__agreement-w-error {
      display: flex;
      flex-direction: column;

      .sign-up__agreement-container {
        display: flex;
        flex-direction: row;
        gap: .5rem;
        align-items: center;

        label {
          cursor: pointer;
        }

        .sign-up__agree-checkbox {
          display: flex;
          align-items: center;
          width: 16px;
          height: 16px;

          span {
            height: 100%;
          }
        }

        .sign-up__agreement-label {
          font-size: .75rem;
          color: rgba(42, 45, 46, .8);

          a {
            color: #34c7c0;
            font-weight: 500;
          }
        }
      }
    }

    .sign-up__buttons-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }

    .sign-up__subtitle {
      display: flex;
      align-self: center;
      font-weight: 600;
      color: #003b4d;
      font-size: 1rem;
      margin: 0;

      &.sign-in {
        font-size: 16px;
        margin-top: 2rem;
        font-weight: 600;
        text-transform: none;
        align-self: center;
      }

      &.lighter {
        color: #8d8d8d;
      }

      &.or {
        font-weight: bold;
        color: rgba(42, 45, 46, .8);
        font-size: 14px;
      }
    }
  }

  .sign-up__right-side-singles {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 10;
    height: 100%;

    .sign-up__image-container {
      height: 32rem;
      width: 80%;
      margin: 0 0 0 5rem;
      position: relative;

      .sign-up__hero-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-width: none;
      }

      .sign-up__image-legend {
        position: absolute;
        left: 0;
        top: auto;
        right: auto;
        bottom: 0;
        margin-bottom: 0.75rem;
        margin-left: 0.75rem;
        padding: 0.5rem 0.8rem;
        background-color: rgba(0, 0, 0, .65);
        font-size: .875rem;
        color: white;
      }
    }
  }
}

.sign-up__right-side-matchmaker {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 10;
  height: 100%;
  position: relative;

  .sign-up__image-container {
    height: 30rem;
    width: 100%;
    margin: 0;
    z-index: 10;
    filter: grayscale(100%);

    .sign-up__hero-image {
      width: 37vw;
      height: 100%;
      object-fit: cover;
      max-width: 538px;
    }
  }

  .sign-up__image-legend {
    position: absolute;
    left: 0;
    top: auto;
    right: auto;
    bottom: 0;
    margin-bottom: 0.75rem;
    margin-left: 0.75rem;
    padding: 0.5rem 0.8rem;
    background-color: rgba(0, 0, 0, .65);
    font-size: .875rem;
    color: white;
  }

  .sign-up__background-color {
    position: absolute;
    left: 22.5%;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    background-color: $bright-teal;
  }
}

@include for-desktop {
  .sign-up__container {
    &.matchmaker {
      grid-template-rows: minmax(680px, auto);
    }
  }

  .sign-up__image-container {
    &.matchmaker {
      position: absolute;
      top: 0;
      margin-top: 5rem !important;
    }
  }
}

@include for-tablet {
  .sign-up__container {
    grid-template-columns: 1fr !important;
    grid-template-rows: 1fr 506px;

    &.matchmaker {
      grid-template-rows: 1fr 640px !important;
    }
  }

  .sign-up__image-container {
    height: 100%;
  }

  .sign-up__hero-image {
    width: 100% !important;
    max-width: unset !important;
  }

  .sign-up__background-color {
    left: 25% !important;
  }
}

@include for-mobile {
  .sign-up__container {
    grid-template-columns: 1fr !important;
    grid-template-rows: 1fr 506px;

    &.matchmaker {
      grid-template-rows: 1fr 416px !important;
    }
  }

  .sign-up__input-pair {
    grid-gap: .6rem !important;
  }

  .sign-up__image-container {
    margin: 0 !important;
    width: 100% !important;

    &.matchmaker {
      height: 20rem !important;
    }
  }

  .sign-up__image-container-matchmaker {
    height: 20rem !important;
  }

  .sign-up__agree-checkbox {
    width: 25px !important;
  }

  .sign-up__input-pair {
    grid-template-columns: 1fr !important;
  }
}