import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import MainHeader from "../../components/main-header";
import MainFooter from "../../components/main-footer";

class AboutPage extends Component {
  render() {
    const { history } = this.props;

    return (
      <div className="about-page-container flex-column">
        <MainHeader history={history} />
        <div>About Page content</div>
        <MainFooter history={history} />
      </div>
    );
  }
}

AboutPage.propTypes = {
  history: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    generalReducer: state.generalReducer,
  };
};

export default connect(mapStateToProps)(AboutPage);
