import { logoutUser } from "../utils/client";

export const SINGLES_ROUTES = {
  CANDIDATE: "candidate",
  CLIENT: "client",
};

export const SINGLE_ROUTES_MORE_INFO = {
  CANDIDATE: [
    "Eligible to meet clients only",
    "No level of service",
    "Free for any intro",
  ],
  CLIENT: [
    "Meet anyone (most amount of options)",
    "We focus on your search",
    "Can recruit online and from partners",
    "Candidates vetted by video",
    "$195 for one-time video call",
    "$499 per accepted intro",
  ],
};

export const USER_ROLES = {
  ADMIN: "admin",
  USER: "user",
  MATCHMAKER: "matchmaker",
};

export const CLIENT_STATUSES = {
  CANDIDATE: "candidate",
  CLIENT: "client",
};

export const MARKETING_NAVBAR_ITEMS = [
  {
    id: "for-singles-nav-bar",
    label: "For Singles",
    children: [
      {
        id: "paid-matchmaking-nav-bar",
        label: "Matchmaking",
        url: "/for-singles/matchmaking",
      },
      {
        id: "free-matchmaking-nav-bar",
        label: "Be a candidate",
        url: "/for-singles/become-a-candidate",
      },
      {
        id: "deep-dive-call-nav-bar",
        label: "Deep-Dive Call",
        url: "/video-call",
      },
      {
        id: "guide-to-dating-nav-bar",
        label: "Guide to Dating",
        url: "/dating-guide",
      },
      {
        id: "blog-nav-bar",
        label: "Blog",
        url: "/blog",
      },
      {
        id: "dating-dictionary-nav-bar",
        label: "Dating Dictionary",
        url: "/dating-guide",
      },
      {
        id: "faqs-nav-bar",
        label: "FAQS",
        url: "/for-singles/faqs",
      },
    ],
  },
  {
    id: "for-matchmakers-nav-bar",
    label: "For Matchmakers",
    url: "/for-matchmakers",
  },
  {
    id: "about-us-nav-bar",
    label: "About Us",
    url: "/about-us",
  },
  {
    id: "refer-friends-nav-bar",
    label: "Refer Friends",
    url: "/referrals",
  },
  {
    id: "contact-us-nav-bar",
    label: "Contact",
    url: "/contact",
  },
  {
    id: "log-in-nav-bar",
    label: "Log In",
    url: "/log-in",
    isApp: true,
  },
];

export const NAVBAR_ITEMS = {
  user: [
    {
      id: "singles-account-settings-nav-bar",
      label: "Home",
      url: "/dashboard?section=theBasics",
    },
    {
      id: "singles-account-settings-nav-bar",
      label: "Account Settings",
      url: "/dashboard?section=settings",
    },
    {
      id: "user-about-us-nav-bar",
      label: "About Us",
      url: `${process.env.MARKETING_SITE_URL}/about-us`,
    },
    {
      id: "user-contact-nav-bar",
      label: "Contact",
      url: `${process.env.MARKETING_SITE_URL}/contact`,
    },
  ],
  matchmaker: [
    {
      id: "singles-account-settings-nav-bar",
      label: "Dashboard",
      url: "/dashboard?section=settings",
      isApp: true,
    },
    {
      id: "singles-about-us-nav-bar",
      label: "Your referrals",
      url: "/matchmaker-referrals",
    },
    {
      id: "singles-contact-us-nav-bar",
      label: "Requested",
      url: "/contact",
    },
    {
      id: "singles-contact-us-nav-bar",
      label: "Help",
      url: "/help",
    },
    {
      id: "singles-contact-us-nav-bar",
      label: "Badges",
      url: "/badges",
    },
    // {
    //   id: "singles-sign-out-nav-bar",
    //   label: "Sign Out",
    //   isApp: true,
    //   action: logoutUser,
    // },
  ],
  admin: [
    {
      id: "admin-singles-nav-bar",
      label: "Singles",
      url: "/dashboard",
    },
    {
      id: "admin-matchmakers-nav-bar",
      label: "Matchmakers",
      url: "/matchmakers-list",
    },
    {
      id: "admin-plans-nav-bar",
      label: "Plans",
      url: "/plans",
    },
    {
      id: "admin-metrics",
      label: "Metrics",
      url: "/metrics",
    }
  ],
  ambassador: [
    {
      id: "ambassador-your-referrals-nav-bar",
      label: "Your Referrals",
      url: "/matchmaker-referrals",
    },
    {
      id: "ambassador-contact-us-nav-bar",
      label: "Contact",
      url: `${process.env.MARKETING_SITE_URL}/contact`,
    },
  ],
};

export const CLIENT_LEAD_STATUS = [
  "Unknown",
  "Yes",
  "No (Candidate Only)",
  "Current Client",
  "Former Client",
];

export const USER_INFO_TABLE_DATA = (user) => ({
  theBasics: [
    {
      label: "Gender",
      key: "gender",
      type: "object",
      value: user["gender"]?.value,
    },
    {
      label: "Attractiveness",
      key: "userRating",
      type: "string",
      value: user["userRating"] ?? "",
    },
    {
      label: "Interested in",
      key: "interestedIn",
      type: "array",
      value: user["interestedIn"]?.map((field) => field.value).join(","),
    },
    {
      label: "Has Children",
      key: "hasKids",
      type: "object",
      value: user["hasKids"]?.value,
    },
    { label: "Age", key: "age", type: "string", value: user["age"] ?? "0" },
    {
      label: "Children's Ages (+/- 1 year)",
      key: "kids",
      type: "nestedObject",
      value: Object.values(user["kids"].kidsAges).length
          ? `${Object.values(user["kids"].kidsAges).join(" & ") ?? ""} years old`
          : "--",
    },
    {
      label: "Height",
      key: "height",
      type: "string",
      value: user["height"] ?? "",
    },
    {
      label: "Future Child Scenarios Open To",
      key: "futureChildScenarios",
      type: "array",
      value: user["futureChildScenarios"]
          ?.map((field) => field.value)
          .join(","),
    },
    {
      label: "Body Type",
      key: "bodyTypes",
      type: "array",
      value: user["bodyTypes"]?.map((field) => field.value).join(","),
    },
    { label: "Location", key: "city", type: "string", value: user["city"] },
    {
      label: "Ethnicity",
      key: "ethnicity",
      type: "array",
      value: user["ethnicity"]?.map((field) => field.value).join(","),
    },
    {
      label: "Religion",
      key: "religionPracticed",
      type: "array",
      value: user["religionPracticed"]?.map((field) => field.value).join(","),
    },
    { label: "Client Lead?", key: "clientLead", type: "object", value: "" },
    {
      label: "Member Type",
      key: "clientStatus",
      type: "string",
      value: user["clientStatus"] ?? "",
    },
  ],
  backGround: [
    { label: "Marriage Status", key: "marriageStatus", type: "object" },
    { label: "Education", key: "education", type: "object" },
    { label: "Employment Status", key: "employmentStatus", type: "object" },
    { label: "Occupation", key: "occupation", type: "string" },
    { label: "School Attended", key: "schoolAttended", type: "string" },
    { label: "Company/ Org", key: "company", type: "string" },
    {
      label: "Where are you from?",
      key: "upbringing",
      type: "string",
    },
  ],
  lifeStyle: [
    { label: "Drinking", key: "drinking", type: "object" },
    { label: "Eating Habits", key: "eatingHabits", type: "array" },
    { label: "Pets", key: "pets", type: "nestedPets" },
    { label: "Smoking", key: "smoking", type: "object" },
    { label: "Politics", key: "politicalOrientation", type: "object" },
    { label: "Fitness Level", key: "workout", type: "object" },
    { label: "Drugs", key: "does_drugs", type: "nestedDrugs" },
    { label: "Do you have any tattoos?", key: "tattoos", type: "object" },
    { label: "Music Genres", key: "music", type: "nestedMusic" },
    { label: "Hobbies", key: "hobbies", type: "array" },
    { label: "Passionate About", key: "passionateAbout", type: "string" },
    {
      label: "Financial Stability",
      key: "financialStability",
      type: "component",
    },
    {
      label: "Average yearly income",
      key: "yearlyIncome",
      type: "object",
      accessor: "label",
    },
    {
      label: "Net worth estimate",
      key: "netWorthEstimate",
      type: "object",
      accessor: "label",
    },
    {
      label: "How important is travel to you?",
      key: "travel.personalImportance",
      type: "object",
    },
    {
      label: "What's your international travel experience?",
      key: "travel.personalExperience",
      type: "object",
    },
    {
      label: "Feel free to elaborate on any travel or living abroad",
      key: "travel.personalComments",
      type: "string",
    },
  ],
  family: [
    {
      label: "Have you frozen any eggs/sperm?",
      key: "personalFrozenEggsOrSperm",
      type: "object",
    },
    {
      label: "Any other details about kids?",
      key: "kidsAnythingElse",
      type: "string",
    },
    {
      label: "Describe your family & upbringing",
      key: "familyAndUpbringing",
      type: "string",
    },
    {
      label: "Are you (or were you) close with the family you grew up with?",
      key: "familyCloseness",
      type: "object",
    },
    {
      label: "Should you loan money to family?",
      key: "familyLoan",
      type: "object",
    },
  ],
  partnerCriteria: [
    {
      label: "Relationship Type(s)",
      key: "relationshipType",
      type: "array",
    },
    { label: "Height", key: "heightPreference", type: "heightNested" },
    { label: "Age range", key: "ageRange", type: "string" },
    { label: "Ethnicities", key: "ethnicityRequired", type: "array" },
    {
      label: "Religious alignment",
      key: "religionPreference",
      type: "component",
    },
    {
      label: "Political alignment",
      key: "politicalPreference",
      type: "component",
    },
    {
      label: "Drugs",
      key: "does_drugs",
      type: "nestedDrugs",
    },
    {
      label: "Could you be with someone who has a tattoo?",
      key: "partnerTattoos",
      type: "object",
    },
    {
      label: "Open to relocation",
      key: "relocationOptions",
      type: "object",
    },
    { label: "Match areas", key: "matchArea", type: "matchAreaArray" },
    {
      label: "Match area details",
      key: "matchAreaDetails",
      type: "string",
    },
    {
      label: "Looking for in a partner",
      key: "partnerPreferences",
      type: "string",
    },
  ],
  other: [
    {
      label: "Of the 5 Love Languages, how do you naturally GIVE appreciation?",
      key: "giveAppreciation",
      type: "object",
    },
    {
      label:
          "Of the 5 Love Languages, how do you like to RECEIVE appreciation?",
      key: "receiveAppreciation",
      type: "object",
    },
    {
      label: "Longest relationship duration",
      key: "longestRelationship",
      type: "object",
    },
    {
      label: "Languages spoken",
      key: "languages",
      type: "array",
    },
    {
      label: "Descriptors (from list)",
      key: "qualities",
      type: "array",
    },
    {
      label: "Habits to work on",
      key: "habitsToWorkOn",
      type: "array",
    },
    {
      label: "Dealbreaker traits (from list)",
      key: "dealbreakers",
      type: "array",
    },
    {
      label: "Open to blind dates",
      key: "blindDates",
      type: "object",
    },
    {
      label: "Anything else?",
      key: "anythingElse",
      type: "string",
    },
  ],
  dateSpotHistory: [
    { label: "Signup Date", key: "signupDate", type: "created" },
    { label: "Referral Source", key: "referralSource", type: "array" },
    {
      label: "Last Known Relationship Status",
      key: "relationshipStatus",
      type: "object",
    },
    { label: "Contact Requests", key: "requests", type: "string" },
    {
      label: "Contact Requests Responded to",
      key: "requestsResponded",
      type: "string",
    },
    {
      label: "Number of dates through DateSpot",
      key: "requestsResponded",
      type: "string",
    },
  ],
});

export const FOR_TABLET_DOWN_QUERY = "(max-width: 768px)";
