import React from "react";
import PropTypes from "prop-types";
import DropDown from "../drop-down";
import InputText from "../input-text";
import { PETS } from "../../constants/onboarding-data-elements";
import "./style.scss";
import { capitalize } from "src/utils/functions";
import MediaQueryProvider from "src/components/MediaQueryProvider";
import { FOR_TABLET_DOWN_QUERY } from "src/data/constants";

class PetsDropdown extends React.Component {
  state = {
    dogs: this.props.pets ? this.props.pets.dogs : null,
    cats: this.props.pets ? this.props.pets.cats : null,
    other: this.props.pets ? this.props.pets.other : null,
  };

  render() {
    const { dogs, cats, other } = this.state;
    const { saveUserFieldData, required } = this.props;
    const pet_options = ["dogs", "cats", "other"];
    return (
      <MediaQueryProvider query={FOR_TABLET_DOWN_QUERY}>
        {(isTabletDown) => (
          <div className="pets-dropdown flex-column">
            <div className="pets-dropdown__label flex-row flex-vertical-center">
              <div
                className={`${
                  dogs || cats || other ? "p-label green" : "p-label"
                }`}
                style={{ textAlign: "left" }}
              >
                Pets{required ? <span className="text-alert-red">*</span> : ""}
              </div>
            </div>
            <div className="pets-dropdown__options flex-column">
              {pet_options.map((petType, i) => (
                <div
                  key={`${petType}-${i}`}
                  className="pets-dropdown__option flex-vertical-center"
                >
                  <span>{capitalize(petType)}</span>
                  {petType !== "other" ? (
                    <DropDown
                      listOfValues={PETS[petType] || []}
                      selectedValue={this.state[petType]}
                      name={petType}
                      placeholder="Choose one..."
                      onSelectValue={(val) => {
                        this.setState({ [petType]: val });
                        saveUserFieldData("pets", {
                          ...this.state,
                          [petType]: val,
                        });
                      }}
                    />
                  ) : (
                    <InputText
                      style={{ maxWidth: "390px" }}
                      wrapperClassName={`drop-down-wrapper${
                        isTabletDown ? " full-width" : " special-length"
                      }`}
                      placeholder="Other types of pets"
                      onChange={(e) => {
                        this.setState({ other: e.target.value });
                        saveUserFieldData("pets", {
                          ...this.state,
                          other: e.target.value,
                        });
                      }}
                      value={this.state.other || ""}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </MediaQueryProvider>
    );
  }
}

PetsDropdown.propTypes = {
  saveUserFieldData: PropTypes.func,
  pets: PropTypes.object,
};
export default PetsDropdown;
