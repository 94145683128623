import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as matchmakerActions from "../../actions/matchmakersActions";
import { SINGLE_USER_EMAIL_RESPONSE } from "../../constants/index";
import logo from "../main-header/assets/date-spot-logo.svg";

import "./style.scss";

class UserEmailResponse extends Component {
  static propTypes = {
    matchmakerActions: PropTypes.object,
    history: PropTypes.object,
  };

  constructor(props) {
    super(props);
    const url = props.history.location;
    const parts = url.pathname.split("/");
    const requestId = parts[2];
    // const singleId = parts[3]
    // const requestId = parts[2]

    const answer = url.search.replace("?", "");
    this.state = { answer };

    matchmakerActions.saveUserResponse(answer, requestId);
  }

  render() {
    const { answer } = this.state;
    const { history } = this.props;

    return (
      <div className="user-response-content">
        <div className="flex-center  main-header__tab date-spot-logo">
          <img src={logo} onClick={() => history.push("/")} />
        </div>

        <div className="user-response-content__text">
          {SINGLE_USER_EMAIL_RESPONSE[answer]}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    matchmakerActions: bindActionCreators(matchmakerActions, dispatch),
  };
};

export default connect(mapDispatchToProps)(UserEmailResponse);
