import {
  LOGIN_USER,
  SIGN_UP_USER,
  SIGN_UP_MATCH_MAKER,
  RESET_SIGN_UP_FLAG,
  GET_LOGGED_IN_USER,
  REMOVE_ERRORS,
  SAVE_USER_FIELD_DATA,
  SET_DELETE_PHOTO_USER,
} from "../constants/actionTypes";
import cloneDeep from "lodash.clonedeep";

const initialState = {
  errors: null,
  isSignedUpMatchMaker: false,
  user: null,
};

export default function authenticationReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_USER: {
      if (action.response) {
        if (action.response.error) {
          return { ...state, errors: action.response.error || {} };
        }
        return { ...state, user: action.response.user };
      }
      return { ...state };
    }

    case REMOVE_ERRORS: {
      return { ...state, errors: null };
    }

    case SIGN_UP_USER: {
      if (action.response) {
        if (action.response.error) {
          return { ...state, errors: action.response.error || {} };
        }
        return { ...state, user: action.response.user };
      }
      return { ...state };
    }

    case SIGN_UP_MATCH_MAKER: {
      if (action.response && action.response.error) {
        return {
          ...state,
          errors: action.response.error || {},
        };
      }
      return {
        ...state,
        isSignedUpMatchMaker: true,
        user: action.response.user,
      };
    }

    case RESET_SIGN_UP_FLAG: {
      return {
        ...state,
        isSignedUpMatchMaker: false,
      };
    }

    case GET_LOGGED_IN_USER: {
      if (action.response && action.response.user) {
        return {
          ...state,
          user: action.response.user,
        };
      }
      return { ...state };
    }

    case SAVE_USER_FIELD_DATA: {
      if (action.response && action.response.user) {
        return {
          ...state,
          user: action.response.user,
        };
      }
      return { ...state };
    }

    case SET_DELETE_PHOTO_USER: {
      if (action.response) {
        const { images } = action.response;
        const clonedUser = cloneDeep(state.user);
        clonedUser.images = images;
        clonedUser.changedPhotos = true;
        return {
          ...state,
          user: clonedUser,
        };
      }
      return { ...state };
    }

    default:
      return { ...state };
  }
}
