import React, { useEffect, useState } from "react";
import "./ResetPassword.scss";
import { apiPost, notify } from "launchpad";
import { Page } from "launchpad";
import styled from "styled-components";
import { RegForm } from "../pages/ForgotPassword";
import { Button, FormInput, Image } from "../widgets";
import { validatePassword } from "../../utils/validation";
import DateSpotLogo from "../../images/datespot-logo-w-label.svg";

const Container = styled.div`
  padding: 80px 0;
`;

//TODO: Redo this page, there's no design for it so we'll use the old one for now.
const ResetPassword = ({ history }) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [disableButton, setDisableButton] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const token = params.get("token");

  useEffect(() => {
    if (!token) {
      setDisableButton(true);
      notify("You need a valid token to reset your password", {
        type: "error",
      });
      setTimeout(() => {
        history.push("/log-in");
      }, 3000);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const passwordError = validatePassword(newPassword);
    const confirmPasswordError =
      newPassword !== confirmPassword ? "Passwords do not match" : "";
    if (passwordError || confirmPasswordError) {
      setErrors({
        password: passwordError,
        confirmPassword: confirmPasswordError,
      });
      return;
    }
    setErrors({});
    apiPost(`confirm-reset-password/${token}`, { newPassword }).then((data) => {
      if (data.error) {
        notify(data.error, { type: "error" });
      } else {
        setDisableButton(true);
        notify(
          "Password has been reset! You can now login or close this window.",
          { type: "success" }
        );
        setTimeout(() => {
          history.push("/log-in");
        }, 3000);
      }
    });
  };

  return (
    <Page id="reset-password" className="about-page">
      <Container>
        <Image src={DateSpotLogo} alt="DateSpot Logo" />
        <RegForm>
          <form onSubmit={handleSubmit}>
            <h1 className="text-center">Enter a New Password</h1>
            <FormInput
              value={newPassword}
              onChange={setNewPassword}
              type="password"
              placeholder="New Password"
              error={errors.password}
            />
            <FormInput
              value={confirmPassword}
              onChange={setConfirmPassword}
              type="password"
              placeholder="Confirm Password"
              error={errors.confirmPassword}
            />
            <Button
              type="submit"
              className="reset-password__submit-button"
              disabled={disableButton}
            >
              Submit New Password
            </Button>
          </form>
        </RegForm>
      </Container>
    </Page>
  );
};

export default ResetPassword;
