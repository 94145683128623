import React, { Component } from "react";
import { FAQ } from "../../constants/index";

import { REPEAT_SECTION_TWO } from "../home-pages/texts";
import { repeatingSectionTwo } from "../home-pages/components";

import "./style.scss";

class Faqs extends Component {
  render() {
    return (
      <div className="faq-page-content flex-column">
        {repeatingSectionTwo(
          REPEAT_SECTION_TWO.faqPageFirst.title,
          null,
          null,
          null,
          null,
          null
        )}
        {FAQ.map((faq, f) => {
          return (
            <div className="faq-page-content__section flex-column" key={f}>
              <div className="faq-page-content__questions">
                <span className="text">{faq.question}</span>
              </div>
              <div className="answers">
                <span className="text">{faq.answer}</span>
              </div>
              {faq.answerTwo && (
                <div className="answer-two">
                  <span className="text">{faq.answerTwo}</span>
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  }
}

export default Faqs;
