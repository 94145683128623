import React, { Component } from "react";

class MediaQueryProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matches: false,
    };
  }

  componentDidMount() {
    this.updateMediaQuery();
    window.addEventListener("resize", this.updateMediaQuery);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateMediaQuery);
  }

  updateMediaQuery = () => {
    const matches = window.matchMedia(this.props.query).matches;
    if (matches !== this.state.matches) {
      this.setState({ matches });
    }
  };

  render() {
    return this.props.children(this.state.matches);
  }
}

export default MediaQueryProvider;
