import Api from "./api";
import * as types from "../constants/actionTypes";
import Cookies from "js-cookie";

export const verifyIfEmailExists = (email, callback, role) => {
  return () => {
    Api.post({
      url: "/api/verify-email",
      data: {
        email,
        role,
      },
      callback,
    });
  };
};

export const verifyReferralEmail = (email, callback) => {
  return () => {
    Api.post({
      url: "/api/verify-referral-email",
      data: { email },
      callback,
    });
  };
};

export const loginUser = (email, password, callback) => {
  return () => {
    Api.post({
      url: "/api/login",
      data: {
        email,
        password,
      },
      callback,
      responseAction: types.LOGIN_USER,
    });
  };
};

export const signUpUser = (data, callback) => {
  return () => {
    window.gtag("event", "conversion", {
      send_to: "AW-720967997/EvNwCN2H56YBEL2y5NcC",
    });
    window.gtag("config", "AW-753431149");

    Api.post({
      url: "/api/sign-up",
      data,
      callback,
      responseAction: types.SIGN_UP_USER,
    });
  };
};

export const signUpMatchMaker = (data, callback) => {
  return () => {
    Api.post({
      url: "/api/sign-up-matchmaker",
      data,
      callback,
      responseAction: types.SIGN_UP_MATCH_MAKER,
    });
  };
};

export const updateUser = (data, callback) => {
  return () => {
    Api.put({
      url: "/api/update-user",
      data,
      callback,
    });
  };
};

export const resetSignupFlag = () => {
  return {
    type: types.RESET_SIGN_UP_FLAG,
  };
};

export const getUserData = (callback) => {
  return () => {
    Api.get({
      url: "/api/me",
      responseAction: types.GET_LOGGED_IN_USER,
      callback,
    });
  };
};

export const removeError = () => {
  return {
    type: types.REMOVE_ERRORS,
  };
};
