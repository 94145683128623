import React, { Component } from "react";
import PropTypes from "prop-types";
import { MATCH_MAKER_TABLE_HEADERS } from "../../../../constants/index";
import { getInitials } from "../../../../components/util-functions";
import "../../user-list.scss";

class FavoritesList extends Component {
  render() {
    const {
      matchmakersReducer,
      matchMakerActions,
      filtersHeight,
      filteredUsers,
    } = this.props;
    const { favoriteUsersList } = matchmakersReducer;
    const readjustedHeight = `${248 + filtersHeight}px`;
    const listHeight = filtersHeight
      ? `calc(100vh - ${readjustedHeight})`
      : "calc(100vh - 234px)";

    return (
      <div className="users-list-container favorites flex-column">
        <div className="labels favorites flex-row">
          {MATCH_MAKER_TABLE_HEADERS.favorites.map((tab, t) => {
            return (
              <span
                className={tab.class}
                key={t}
                style={{ width: `${tab.width}px` }}
              >
                {tab.name}
              </span>
            );
          })}
        </div>
        <div className="users-list" style={{ height: listHeight }}>
          {favoriteUsersList.length > 0 &&
            favoriteUsersList.map((user, u) => {
              const renderFavoriteUser = this._renderFavoriteUser(
                user,
                matchMakerActions,
                filteredUsers
              );
              return (
                <div className="users-list__item" key={u}>
                  {renderFavoriteUser}
                </div>
              );
            })}
        </div>
      </div>
    );
  }

  _renderFavoriteUser = (user, matchMakerActions, filteredUsers) => {
    const favoriteUserFromUsersArray =
      filteredUsers &&
      filteredUsers.find(
        (us) => user.favoriteUser && us._id === user.favoriteUser._id
      );
    const { matchMakerId } = this.props;
    let showUserName = false;
    if (favoriteUserFromUsersArray) {
      const { connectionsRequests } = favoriteUserFromUsersArray;
      const connectionRequestSentByMyself =
        connectionsRequests.length > 0 &&
        connectionsRequests.find((conn) => conn.matchMakerId === matchMakerId);
      const requestStatus =
        connectionRequestSentByMyself && connectionRequestSentByMyself.status;
      const requestAccepted = requestStatus === "APPROVED";
      const isUserLockedByMe = !!connectionsRequests.find(
        (conn) => conn.lockingMatchmaker === matchMakerId && conn.isLocked
      );
      showUserName = requestAccepted || isUserLockedByMe;
    }

    return (
      <div className="users-list__item-container flex-row">
        <div
          className="flex-row user-info"
          onClick={() => {
            matchMakerActions.getClickedUserInfo(
              favoriteUserFromUsersArray._id
            );
          }}
        >
          {user.favoriteUser &&
          user.favoriteUser.images &&
          user.favoriteUser.images[0] ? (
            <img
              src={user.favoriteUser.images[0].url}
              alt=""
              className="avatar flex-center"
            />
          ) : (
            <span className="avatar flex-center">
              {user.favoriteUser && getInitials(user.favoriteUser)}
            </span>
          )}
          <div className="member element flex-column">
            <span>
              #{favoriteUserFromUsersArray && favoriteUserFromUsersArray.number}
            </span>
            {showUserName ? (
              <span>
                {favoriteUserFromUsersArray.firstName}{" "}
                {favoriteUserFromUsersArray.lastName.charAt(0)}
              </span>
            ) : null}
          </div>
          <span className="gender element">
            {user.favoriteUser &&
              user.favoriteUser.gender &&
              user.favoriteUser.gender.value}
          </span>
          <span className="age element">
            {(user.favoriteUser && user.favoriteUser.age) || ""}
          </span>
          <span className="looks element">
            {Math.round(user.favoriteUser && user.favoriteUser.userRating) ||
              "No rating"}
          </span>
        </div>
        <span
          className="icon-close-button float-right"
          onClick={() => matchMakerActions.removeUserFromFavorites(user._id)}
        />
      </div>
    );
  };
}

FavoritesList.propTypes = {
  history: PropTypes.object,
  matchMakerActions: PropTypes.object,
  generalActions: PropTypes.object,
  generalReducer: PropTypes.object,
  matchmakersReducer: PropTypes.object,
  searchValue: PropTypes.string,
  filtersHeight: PropTypes.any,
  filteredUsers: PropTypes.array,
  matchMakerId: PropTypes.string,
  matchMakerActions: PropTypes.object,
};

export default FavoritesList;
