import Api from "./api";
import * as types from "../constants/actionTypes";
import configureStore from "../store/configureStore";

const store = configureStore;

export function setSelectedHeaderTab(tab) {
  return {
    type: types.SET_SELECTED_HEADER_TAB,
    tab,
  };
}

export function setOnBoardingData(section, field, value) {
  return {
    type: types.SET_ON_BOARDING_DATA,
    response: {
      section,
      field,
      value,
    },
  };
}

export function removeOnBoardingData() {
  return {
    type: types.REMOVE_ON_BOARDING_DATA,
  };
}

export function saveTemporaryUserData(userData) {
  return () => {
    Api.post({
      url: "/api/user-data",
      data: {
        firstName: userData.name,
        lastName: userData.lastName,
        email: userData.email,
        gender: userData.gender,
        genderInterest: userData.genderInterest,
        city: userData.city,
        relationshipStatus: userData.relationshipStatus,
        religionPracticed: userData.religion,
      },
      responseAction: types.SAVE_USER_DATA,
    });
  };
}

export function setSignedUser(data) {
  return {
    type: types.SET_SIGNED_UP_USER,
    data,
  };
}

export function setLocalStorageDataOnReducer(onBoardingData, activeUser) {
  return {
    type: types.SET_LOCAL_STORAGE_DATA,
    onBoardingData,
    activeUser,
  };
}

export const setLeftPanelFilters = (filters) => {
  return {
    type: types.SET_LEFT_PANEL_FILTERS,
    response: filters,
  };
};

export function resetLeftPanelFilter(filter) {
  return {
    type: types.RESET_LEFT_PANEL_FILTER,
    filter,
  };
}

export function saveSelectedFilters(name, filter, allSavedFilters) {
  const savedSelectedFilters = { ...allSavedFilters, [name]: filter };
  return () => {
    Api.post({
      url: "/api/save-selected-filters",
      data: { savedSelectedFilters },
      responseAction: types.SAVE_SELECTED_FILTERS,
    });
  };
}

export function loadUsers() {
  return () => {
    Api.get({
      url: "/api/get-users",
      responseAction: types.LOAD_USERS,
    });
  };
}

export const sendUserWelcomeEmail = (data) => {
  return () => {
    Api.post({
      url: "/api/send-user-welcome-email",
      data,
    });
  };
};

export function reloadUsers() {
  return loadUsers();
}

export function reCountUsers() {
  return getTotalMembersCount();
}

export function loadMatchmakers() {
  return () => {
    Api.get({
      url: "/api/get-matchmakers",
      responseAction: types.LOAD_MATCHMAKERS,
    });
  };
}

export function getTotalMembersCount() {
  return () => {
    Api.get({
      url: "/api/get-members-counts",
      responseAction: types.LOAD_USERS_COUNT,
    });
  };
}

/**
 * Toggle the loading modal with params
 * @param {Boolean} [show] show or hide the modal
 * @param {String} [error] error text to be displayed
 * @param {Boolean} [loading] show or not the loading spinner
 * @returns {{type: string, data: {show: *, error: *, loading: *}}}
 */
export function toggleLoadingModal({ show, error, loading }) {
  return store.dispatch({
    type: types.TOGGLE_LOADING_MODAL,
    data: {
      show,
      error,
      loading,
    },
  });
}

export function closeUserInfoModal() {
  return {
    type: types.CLOSE_USER_INFO_MODAL,
  };
}

export function openCloseFilterModalAndSetFilter(mode, filter) {
  return {
    type: types.OPEN_CLOSE_FILTER_MODAL_AND_SET_FILTER,
    response: { mode, filter },
  };
}

export function openCloseSaveFiltersModal(mode) {
  return {
    type: types.OPEN_CLOSE_SAVE_FILTER_MODAL,
    response: mode,
  };
}

export function setSavedFilterUserFilter(filter) {
  return store.dispatch({
    type: types.SET_SAVED_FILTER,
    response: filter,
  });
}

export function loadSavedFilters() {
  return () => {
    Api.get({
      url: "/api/get-saved-filters",
      responseAction: types.LOAD_SAVED_FILTERS,
    });
  };
}

export const sendUserContactData = (data) => {
  return () => {
    Api.post({
      url: "/api/send-contact-message",
      data,
      responseAction: types.SEND_CONTACT_MESSAGE,
    });
  };
};

export const removeContactMessageSentFlag = () => {
  return store.dispatch({
    type: types.SENT_CONTACT_MESSAGE,
  });
};

export const searchDb = (filters) => {
  return () => {
    Api.post({
      url: "/api/filter-users",
      data: filters,
      responseAction: types.FILTER_USERS,
    });
  };
};

export const talkToAMmContactData = (data) => {
  return () => {
    Api.post({
      url: "/api/talk-to-a-mm-message",
      data,
      responseAction: types.SEND_CONTACT_MESSAGE,
    });
  };
};

export const setDeletePhotosUser = (actionType, userId, photo) => {
  Api.post({
    url: "/api/set-delete-photo",
    data: { actionType, userId, photo },
    responseAction: types.SET_DELETE_PHOTO_USER,
  });
};
