import React, { Component } from "react";
import PropTypes from "prop-types";
// import moment from 'moment';
import InputText from "../input-text";
import DropDown from "../drop-down";
import Button from "../button";
import Validator from "../utils/validator";
import CheckBox from "../check-box";
import Modal from "../modal";
import {
  MONTHS,
  DAYS,
  generateYears,
  PREMIUM_SERVICE_TERMS,
} from "../../constants/index";
import {
  USER_WILLING_TO_PAY,
  CLIENT_STATUS,
} from "../../constants/onboarding-data-elements";
import ReferralSources from "../../containers/dashboards/user/referral-sources";
import ReactGA from "react-ga";
import LoginWithFb from "../../containers/facebook/index";

import "./style.scss";

const YEARS = generateYears();

import { history } from "app";

let wtp = () => {
  let wtp = false;
  if (history.location.search) {
    history.location.search.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function (m, key, value) {
        if (key === "wtp") wtp = true;
      }
    );
  }
  return wtp;
};

let phoneRequired = false;

class SignUpForm extends Component {
  state = {
    email: null,
    password: null,
    lastName: null,
    firstName: null,
    role: "user",
    selectedDay: null,
    selectedMonth: null,
    selectedYear: null,
    willingToPay: wtp()
      ? [
          {
            id: 1,
            label: "Get matched free",
            value: false,
          },
          {
            id: 2,
            label: "Contact a matchmaker",
            value: true,
          },
        ]
      : [
          {
            id: 1,
            label: "Get matched free",
            value: false,
          },
        ],
    clientStatus: "free",
    agreeWithTermsAndPolicies: false,
    errors: {},
    validFields: {},
    referralSource: [],
    phoneNumber: null,

    premium_modal: false,
    premium_modal_accept: false,
  };

  signUp = () => {
    const {
      email,
      password,
      firstName,
      lastName,
      role,
      willingToPay,
      clientStatus,
      referralSource,
    } = this.state;

    if (this.validate()) {
      let cleanEmail = email.replace(/\s/g, ""); // Remove any spaces
      this.props.signUpUser(
        {
          email: cleanEmail,
          password,
          firstName,
          lastName,
          role,
          willingToPay,
          clientStatus,
          acceptedTC: true,
          referralSource,
          suspicion: { profile: false, email: false },
          verification: {
            verified: false,
            date_verified: null,
            verified_by: "",
          },
        },
        this.props.signUpCallback
      );

      ReactGA.event({
        category: "member signing in",
        action: "Created an user account",
      });
    }
  };

  // Validation
  validate = () => {
    let isValid = true;
    const {
      errors,
      email,
      password,
      retypePassword,
      firstName,
      lastName,
      willingToPay,
      agreeWithTermsAndPolicies,
      phoneNumber,
    } = this.state;
    if (!firstName) {
      errors.firstNameError = "Required";
      isValid = false;
    }
    if (!lastName) {
      errors.lastNameError = "Required";
      isValid = false;
    }
    if (!email) {
      errors.emailError = "Required";
      isValid = false;
    }
    if (!password) {
      errors.passwordError = "Required";
      isValid = false;
    }
    if (!retypePassword) {
      errors.retypePasswordError = "Required";
      isValid = false;
    }
    // if (referralSource.length < 1) {
    //     errors.referralSourceError = 'Required';
    //     isValid = false;
    // }
    // if (!selectedDay || !selectedMonth || !selectedYear) {
    //     errors.dateError = 'Required';
    //     isValid = false;
    // }
    if (willingToPay.length < 1) {
      errors.radioValueError = "Required";
      isValid = false;
    }
    if (!agreeWithTermsAndPolicies) {
      errors.agreeWithTermsAndPoliciesError = "Required";
      isValid = false;
    }
    if (!phoneNumber && phoneRequired) {
      errors.phoneError = "Required";
      isValid = false;
    } else if (!phoneRequired) {
      errors.phoneError = null;
    }
    Object.values(errors).forEach((error) => {
      if (error) {
        isValid = false;
      }
    });
    this.setState({ errors });

    return isValid;
  };

  fbValidate = () => {
    let isValid = true;

    const { errors, agreeWithTermsAndPolicies, willingToPay } = this.state;

    if (willingToPay.length < 1) {
      errors.radioValueError = "Required";
      isValid = false;
    }

    if (!agreeWithTermsAndPolicies) {
      errors.agreeWithTermsAndPoliciesError = "Required";
      isValid = false;
    }

    this.setState({ errors });

    return isValid;
  };

  setData = (field, value, fieldError, fieldValid) => {
    const { errors, validFields } = this.state;
    this.setState({
      [field]: value,
      errors: { ...errors, [fieldError]: null },
      validFields: { ...validFields, [fieldValid]: null },
    });
  };

  handleSelectedDropDownValue = (selectedValue, name) => {
    this.setState({ [name]: selectedValue });
  };

  checkIfMailExists = (email) => {
    const { errors, validFields } = this.state;
    const callback = (err, res) => {
      if (res && res.data.emailAlreadyExists) {
        this.setState({
          errors: { ...errors, emailError: "Email already exists" },
          validFields: { ...validFields, emailValid: false },
        });
      }
    };
    this.props.authActions.verifyIfEmailExists(email, callback);
  };

  agree_terms = () => {
    this.setState({ clientStatus: "current", premium_modal_accept: false });
  };

  render() {
    const { errors, premium_modal, premium_modal_accept } = this.state;
    const inputSection = this._renderInputs();
    const renderReferralSources = (
      <ReferralSources
        user={{ referralSource: [] }}
        returnValues={(values) => this.returnValues(values)}
      />
    );
    const renderInterestedIn = this._renderInterestedIn();
    const renderTermsAndPolicies = this._renderTermsAndPolicies();
    const renderAgreeTerms = this._renderAgreeTerms();
    return (
      <div className="sign-up-container flex-column">
        <div className="sign-up-container__form">
          {inputSection}
          {renderInterestedIn}
          {renderReferralSources}
          {renderTermsAndPolicies}
          {/* <LoginWithFb formValid={() => this.fbValidate()} loginFbCallback={(mess, response) => this.signUpCallback(mess, response)} page={true}/> */}

          {/* <div className='separator'>
                        Or
                    </div> */}

          {premium_modal && (
            <Modal
              isOpen={premium_modal}
              closeOnClickOutside={true}
              modalContent={
                <div className="premium-modal">
                  <span
                    onClick={() => this.setState({ premium_modal: false })}
                    className="icon-close-button"
                  ></span>
                  <h1>Premium Matching Service Terms</h1>
                  {PREMIUM_SERVICE_TERMS.map((term, i) => {
                    return (
                      <div id={i} className="premium-modal-terms">
                        <span
                          style={{
                            "font-size": "0.85em",
                            "padding-right": "1em",
                          }}
                        >
                          {i + 1}.{" "}
                        </span>
                        <span style={{ "font-size": "0.85em" }}>{term}</span>
                      </div>
                    );
                  })}
                </div>
              }
            />
          )}

          {premium_modal_accept && (
            <Modal
              isOpen={premium_modal_accept}
              closeOnClickOutside={true}
              modalContent={
                <div className="premium-modal">
                  <span
                    onClick={() =>
                      this.setState({ premium_modal_accept: false })
                    }
                    className="icon-close-button"
                  ></span>
                  <h1>Premium Matching Service Terms</h1>
                  {PREMIUM_SERVICE_TERMS.map((term, i) => {
                    return (
                      <div id={i} className="premium-modal-terms">
                        <span
                          style={{ fontSize: ".85em", paddingRight: "1em" }}
                        >
                          {i + 1}.{" "}
                        </span>
                        <span style={{ fontSize: ".85em" }}>{term}</span>
                      </div>
                    );
                  })}
                  {renderAgreeTerms}
                </div>
              }
            />
          )}

          <div className="flex-center">
            <Button
              click={() => this.signUp()}
              value="SIGN UP"
              customClass="yellow sign-up-with-email"
            />
          </div>
        </div>
      </div>
    );
  }

  _renderInputs = () => {
    const {
      email,
      lastName,
      firstName,
      password,
      retypePassword,
      errors,
      validFields,
      phoneNumber,
      willingToPay,
    } = this.state;
    // const renderBirthDateSection = this._renderBirthDateSection();

    let phoneNumberRequired = () => {
      phoneRequired = false;
      willingToPay.map((option, o) => {
        if (option.value === true) phoneRequired = true;
      });
      return phoneRequired;
    };

    return (
      <div className="flex-column">
        <div className="flex-column">
          <div class="flex-row wrap">
            <InputText
              wrapperClassName="flex-column half-size email-input"
              placeholder="Email"
              value={email}
              error={errors.emailError}
              isValid={email && validFields.emailValid}
              onChange={(e) =>
                this.setData(
                  "email",
                  e.target.value,
                  "emailError",
                  "emailValid"
                )
              }
              onBlur={(e) =>
                email &&
                this.checkForValidationErrors(
                  "email",
                  e.target.value,
                  "emailError",
                  "emailValid"
                )
              }
            />

            <InputText
              id="phone-number"
              wrapperClassName="flex-column contact-phone half-size"
              placeholder={
                phoneNumberRequired()
                  ? "Phone Number (required)"
                  : "Phone Number (optional)"
              }
              error={errors.phoneError}
              isValid={phoneNumber && validFields.phoneValid}
              onBlur={(e) =>
                phoneNumber &&
                this.checkForValidationErrors(
                  "phoneNumber",
                  e.target.value,
                  "phoneError",
                  "phoneValid"
                )
              }
              onChange={(e) =>
                this.setData(
                  "phoneNumber",
                  e.target.value,
                  "phoneError",
                  "phoneValid"
                )
              }
              value={phoneNumber}
            />
          </div>

          <div className="flex-row">
            <InputText
              wrapperClassName="flex-column half-size"
              placeholder="First name"
              error={errors.firstNameError}
              isValid={firstName && validFields.firstNameValid}
              onBlur={(e) =>
                firstName &&
                this.checkForValidationErrors(
                  "name",
                  e.target.value,
                  "firstNameError",
                  "firstNameValid"
                )
              }
              onChange={(e) =>
                this.setData(
                  "firstName",
                  e.target.value,
                  "firstNameError",
                  "firstNameValid"
                )
              }
              value={firstName}
            />
            <InputText
              wrapperClassName="flex-column half-size"
              placeholder="Last name"
              value={lastName}
              error={errors.lastNameError}
              isValid={lastName && validFields.lastNameValid}
              onBlur={(e) =>
                lastName &&
                this.checkForValidationErrors(
                  "name",
                  e.target.value,
                  "lastNameError",
                  "lastNameValid"
                )
              }
              onChange={(e) =>
                this.setData(
                  "lastName",
                  e.target.value,
                  "lastNameError",
                  "lastNameValid"
                )
              }
            />
          </div>
          <div className="flex-row">
            <InputText
              wrapperClassName="flex-column half-size password"
              placeholder="Password"
              value={password}
              type="password"
              label="Password"
              error={errors.passwordError}
              isValid={password && validFields.passwordValid}
              onChange={(e) =>
                this.setData(
                  "password",
                  e.target.value,
                  "passwordError",
                  "passwordValid"
                )
              }
              onBlur={(e) =>
                password &&
                this.checkForValidationErrors(
                  "password",
                  e.target.value,
                  "passwordError",
                  "passwordValid"
                )
              }
            />
            <InputText
              wrapperClassName="flex-column half-size password"
              placeholder="Retype password"
              type="password"
              error={errors.retypePasswordError}
              isValid={retypePassword && validFields.retypePasswordValid}
              onChange={(e) => {
                this.setData(
                  "retypePassword",
                  e.target.value,
                  "retypePasswordError",
                  "retypePasswordValid"
                );
                this.setState({
                  errors: {
                    ...errors,
                    passwordError: false,
                    retypePasswordError: false,
                  },
                });
              }}
              onBlur={() => retypePassword.length > 0 && this.checkPasswords()}
              value={retypePassword}
            />
          </div>
          {/* { renderBirthDateSection }*/}
        </div>
      </div>
    );
  };

  _renderInterestedIn_ = () => {
    const { willingToPay, errors, premium_modal } = this.state;

    const labelName1 = (
      <div className="flex-column special-label">
        <span>Free</span>
        <span style={{ color: "#C8C9C9" }}>
          Eligible to meet paying members only
        </span>
      </div>
    );
    const labelName2 = (
      <div className="flex-column special-label">
        <span>Premium - $600/intro</span>
        <span style={{ color: "#C8C9C9" }}>
          Eligible to meet paying & free members
        </span>
      </div>
    );

    return (
      <div className="interested-in flex-column">
        <div className="flex-row">
          <span className="cell-title">{`Route(s) interested in:`}</span>
          {errors.radioValueError && (
            <span
              className="error-custom"
              style={{ marginLeft: ".5em", marginTop: "-.5em" }}
            >
              {errors.radioValueError}
            </span>
          )}
        </div>
        <div className="flex-column">
          {USER_WILLING_TO_PAY.map((option, o) => {
            const isChecked = !!willingToPay.find(
              (item) => item.id === option.id
            );
            return (
              <div key={o} className="flex-row">
                <CheckBox
                  labelName={o === 0 ? labelName1 : labelName2}
                  customClass="sign-up"
                  clicked={() => {
                    !isChecked
                      ? this.setState({
                          willingToPay: [...willingToPay, option],
                          errors: { ...errors, radioValueError: false },
                          clientStatus:
                            option.value === true ? "current" : "free",
                        })
                      : this.setState({
                          willingToPay: willingToPay.filter(
                            (opt) => opt.id !== option.id
                          ),
                          errors: { ...errors, radioValueError: false },
                          clientStatus: option.value === true ? "free" : "free",
                        });
                  }}
                  checked={isChecked}
                />
                {o === 1 && (
                  <span
                    className="icon-question-mark"
                    onClick={() => this.setState({ premium_modal: true })}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  _renderInterestedIn = () => {
    const { clientStatus } = this.state;
    const labelName1 = (
      <div className="flex-column special-label">
        <span>Free</span>
        <span style={{ color: "#C8C9C9" }}>
          Eligible to meet paying members only
        </span>
      </div>
    );
    const labelName2 = (
      <div className="flex-column special-label">
        <span>Premium - $600/intro</span>
        <span style={{ color: "#C8C9C9" }}>
          Eligible to meet paying & free members
        </span>
      </div>
    );

    return (
      <div className="interested-in flex-column">
        <div className="flex-row">
          <span className="subscription-label">{`Route(s) interested in:`}</span>
        </div>
        <div className="flex-column">
          {CLIENT_STATUS.map((option, o) => {
            let isChecked = option.value === clientStatus;
            return (
              <div key={o} className="flex-row">
                <CheckBox
                  labelName={o === 0 ? labelName1 : labelName2}
                  customClass="sign-up"
                  clicked={() => {
                    if (!isChecked) {
                      if (option.value === "free") {
                        this.setState({ clientStatus: "free" });
                      } else if (option.value === "current") {
                        this.setState({ premium_modal_accept: true });
                      }
                    } else {
                      this.setState({ clientStatus: "free" });
                    }
                  }}
                  checked={isChecked}
                />
                {o === 1 && (
                  <span
                    className="icon-question-mark"
                    onClick={() => this.setState({ premium_modal: true })}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  _renderAgreeTerms = () => {
    return (
      <div className="agree-terms flex-row">
        <Button
          value="Accept Terms"
          customClass="accept-terms-button yellow"
          click={() => this.agree_terms()}
        />
      </div>
    );
  };

  _renderTermsAndPolicies = () => {
    const { errors, agreeWithTermsAndPolicies } = this.state;
    const { history } = this.props;
    return (
      <div className="agree-terms flex-row">
        <CheckBox
          checked={agreeWithTermsAndPolicies}
          required
          clicked={() =>
            this.setState({
              agreeWithTermsAndPolicies: !agreeWithTermsAndPolicies,
              errors: { ...errors, agreeWithTermsAndPoliciesError: null },
            })
          }
        />
        <div>
          <span>I agree with the</span>
          <span
            onClick={() => history.push({ pathname: "/terms-of-service" })}
            className="underlined"
          >
            Terms of Service
          </span>
          <span>and</span>
          <span
            onClick={() => history.push({ pathname: "/privacy-policy" })}
            className="underlined"
          >
            Privacy Policy
          </span>
          <span>and am over age 18.</span>
          {errors.agreeWithTermsAndPoliciesError && (
            <span className="error-custom agree-terms">
              {errors.agreeWithTermsAndPoliciesError}
            </span>
          )}
        </div>
      </div>
    );
  };

  _renderBirthDateSection = () => {
    const { selectedMonth, selectedDay, selectedYear, errors } = this.state;
    return (
      <div className="flex-column birthday">
        <div className="flex-row">
          <span className="cell-title"> Birthday </span>
          {errors.dateError && (
            <span
              className="error-custom float-right"
              style={{ marginRight: "10px" }}
            >
              {errors.dateError}
            </span>
          )}
        </div>
        <div className="flex-row">
          <DropDown
            wrapperClassName="month-drop-down"
            listOfValues={MONTHS || []}
            hasError={errors.dateError}
            selectedValue={selectedMonth}
            name="selectedMonth"
            placeholder="Month"
            onSelectValue={(val) => {
              this.setState({ errors: { ...errors, dateError: null } });
              this.handleSelectedDropDownValue(val, "selectedMonth");
            }}
          />
          <DropDown
            wrapperClassName="day-drop-down"
            listOfValues={DAYS || []}
            hasError={errors.dateError}
            selectedValue={selectedDay}
            name="selectedDay"
            placeholder="Day"
            onSelectValue={(val) => {
              this.setState({ errors: { ...errors, dateError: null } });
              this.handleSelectedDropDownValue(val, "selectedDay");
            }}
          />
          <DropDown
            wrapperClassName="year-drop-down"
            listOfValues={YEARS || []}
            hasError={errors.dateError}
            selectedValue={selectedYear}
            name="selectedYear"
            placeholder="Year"
            onSelectValue={(val) => {
              this.setState({ errors: { ...errors, dateError: null } });
              this.handleSelectedDropDownValue(val, "selectedYear");
            }}
          />
        </div>
        <span className="little-notation">
          To sign up you must be 18 or older.
        </span>
        <span className="little-notation">
          We use this info to automatically update your age.
        </span>
      </div>
    );
  };

  checkPasswords = () => {
    const { password, retypePassword, errors, validFields } = this.state;
    if (Validator.validatePassword(password)) {
      if (password !== retypePassword) {
        this.setState({
          errors: { ...errors, retypePasswordError: "Passwords don't match" },
        });
      } else {
        this.setState({
          validFields: {
            ...validFields,
            passwordValid: true,
            retypePasswordValid: true,
          },
          errors: { ...errors, passwordError: null, retypePasswordError: null },
        });
      }
    } else {
      if (password.length < 6) {
        this.setState({
          errors: {
            ...errors,
            passwordError: "Password must be at least 6 characters",
          },
        });
      } else {
        this.setState({
          errors: { ...errors, passwordError: "Password error" },
        });
      }
    }
  };

  returnValues = (values) => {
    console.log(values);
    this.setState({ referralSource: values });
  };

  checkForValidationErrors = (type, value, stateError, validField) => {
    let valid;
    switch (type) {
      case "name":
        valid = Validator.validateName(value);
        this.setErrorsOrValids(valid, validField, stateError, "Invalid");
        break;
      case "email":
        valid = Validator.validateEmail(value);
        this.checkIfMailExists(value);
        this.setErrorsOrValids(valid, validField, stateError, "Invalid format");
        break;
      case "password":
        valid = Validator.validatePassword(value);
        let err_msg =
          value.length > 6
            ? "Password error"
            : "Password must be at least 6 characters";
        this.setErrorsOrValids(valid, validField, stateError, err_msg);
        break;
      case "no-validation":
        this.setErrorsOrValids(true, validField, stateError, "");
        break;
      default:
        break;
    }
  };

  setErrorsOrValids = (valid, validField, stateError, message) => {
    const { validFields, errors } = this.state;
    valid
      ? this.setState({ validFields: { ...validFields, [validField]: true } })
      : this.setState({ errors: { ...errors, [stateError]: message } });
  };
}

SignUpForm.propTypes = {
  signUpUser: PropTypes.func,
  signUpCallback: PropTypes.func,
  history: PropTypes.object,
  isMatchMakerForm: PropTypes.bool,
  authActions: PropTypes.object,
};

export default SignUpForm;
