import React, { useState, useEffect, useRef } from "react";
import { Page, getGlobal, setGlobal } from "../../../../../launchpad";
import { history, reloadUser } from "app";
import { useSetState } from "../../../../../launchpad/helpers";
import {
  favoriteUser,
  contactUser,
  cancelContact,
  saveFilters as persistFilters,
} from "../UserList";
import { Slideshow } from "widgets";
import UserProfile from "../UserProfile";
import Filters, {
  Filter,
  getAllFilters,
  setFilters,
} from "../../../filters/Filters";
import Modal from "../../../Modal/Modal";
import LGBTQ from "src/images/lgbtq-plus.png";
import Paused from "src/images/Datespot_Paused.png"
import { Image } from "src/components/widgets";
import { shouldRenderLGBTLabel, shouldRenderPausedLabel, shouldRenderPolyLabel } from "src/utils/functions";
import PolyOther from "../../../../images/poly-other.png";
import { Table } from "src/components/Table/Table";
import { MMSideBar } from "src/components/SideBar/MMSideBar";
import MediaQueryProvider from "src/components/MediaQueryProvider";
import { FOR_TABLET_DOWN_QUERY } from "src/data/constants";
import theme from "src/styles/theme";
import memberSchema from "../member-data";
import UserInfoModal from "src/components/Modal/CustomModals/UserInfoModal/UserInfoModal";

const FilterModals = () => {
  const { selectedUserFilter } = getGlobal();
  return (
      <>
        {getAllFilters().map((x) => {
          return (
              <Modal
                  key={x.name}
                  open={selectedUserFilter === x.name}
                  title={x.filterLabel || x.label}
                  onClose={() => setGlobal({ selectedUserFilter: "" })}
              >
                <div className={`filter-modal ${x.name}`}>
                  <Filter name={x.name} hideLabel />
                </div>
              </Modal>
          );
        })}
      </>
  );
};

export default function Dashboard() {
  const [state, setState] = useSetState({
    query: "",
    allFiltersType: "basic",
    selectedUser: null,
    showProfile: false,
    users: { data: [] },
    onEnterText: null,
    filterName: "",
    refresh: () => { },
  });

  const {
    query,
    selectedUser,
    showProfile,
    refresh,
    users,
    allFiltersType,
    onEnterText,
    filterName,
  } = state;


  const [showFilters, setShowFilters] = useState(false);

  const filters = getGlobal("userFilters") || {};
  const mm = getGlobal("activeUser") || {};

  const filtersModalBodyRef = useRef(null);

  useEffect(() => {
    reloadUser().then((user) => {
      if (!user || user?.ambassadorAccount) {
        history.push("/matchmaker-referrals");
      } else if (!user || user.role !== "matchmaker") {
        history.push("/");
      }
    });
  }, []);

  useEffect(() => {
    if (filtersModalBodyRef.current) {
      filtersModalBodyRef.current.scrollTop = 0;
    }
  }, [allFiltersType]);

  if (!mm || mm?.ambassadorAccount) return <div />;
  if (!mm || mm.role !== "matchmaker") return <div />;

  const toggleFilter = (f) => {
    let updated = { ...filters };
    const toggles = [
      "favorited",
      "contacted",
      "all",
      "locked",
      "connected",
      "associated",
      "potentials",
    ];
    toggles.forEach((t) => (updated[t] = false));
    updated[f] = true;
    delete updated.all;
    setFilters(updated);
  };

  const saveFilters = async () => {
    const name = await new Promise((resolve) =>
        setState({ onEnterText: resolve })
    );
    persistFilters({
      ...getGlobal("activeUser").savedSelectedFilters,
      ...{ [name]: filters },
    });
  };

  const activeUser = users.data.find((x) => x._id === selectedUser);

  const { hoursSinceContact, contactHours, contactDays } = activeUser ? activeUser.getContactTiming() : {};

  const handleFilterButtonClick = (value) => { setShowFilters(value) }

  return (
      <MediaQueryProvider query={FOR_TABLET_DOWN_QUERY}>
        {(isTabletDown) => (
            <Page id="matchmaker-dashboard" className="dashboard">
              <Modal
                  title="Save Filter"
                  open={!!onEnterText}
                  onClose={() => setState({ onEnterText: null })}
              >
                <p>Choose a name for your filter</p>
                <form
                    className="save-filter-form"
                    onSubmit={(e) => {
                      e.preventDefault();
                      onEnterText(filterName);
                      setState({ onEnterText: null, filterName: "" });
                    }}
                >
                  <input
                      value={filterName}
                      onChange={(e) => setState({ filterName: e.target.value })}
                  />
                  <button onClick={saveFilters} className="button">Save</button>
                </form>
              </Modal>

              <Modal
                  ref={filtersModalBodyRef}
                  key="all"
                  open={showFilters}
                  onClose={() => setShowFilters(false)}
                  header={
                    <div className="tab-header">
                      <nav className="tab-nav">
                        <a
                            title="Basic"
                            className={allFiltersType === "basic" ? "active" : ""}
                            onClick={(e) => setState({ allFiltersType: "basic" })}
                        >
                          Basic
                        </a>
                        <a
                            title="Advanced"
                            className={allFiltersType === "advanced" ? "active" : ""}
                            onClick={(e) => setState({ allFiltersType: "advanced" })}
                        >
                          Advanced
                        </a>
                      </nav>
                      <p
                          className="filter-info"
                      >
                        By ignoring a filter, all members within that category will be
                        displayed. Adjust filters to narrow your search.
                      </p>
                    </div>
                  }
              >
                <Slideshow
                    className="filters-slider"
                    slide={allFiltersType === "basic" ? 1 : 2}
                    dynamicHeight
                >
                  <div id="basic-filters">
                    <Filters type="basic" />
                  </div>

                  <div id="advanced-filters">
                    {Object.keys(memberSchema).map(
                        (x, index) => x !== "basic" && <Filters type={x} key={`${index}-filter`} showLabel />
                    )}
                  </div>
                </Slideshow>
              </Modal>
              <FilterModals />

              <UserInfoModal activeUser={activeUser} showProfile={showProfile} onClose={() => setState({ showProfile: false })} />
              {!isTabletDown ? (
                  <div className="dashboard-container">
                    <div className="row dashboard-layout">
                      <MMSideBar isTabletDown={false} filterButton={handleFilterButtonClick} filters={filters} saveFilters={saveFilters} toggleFilter={toggleFilter} />
                      <div className="main dashboard-panel">
                        <div className="dashboard-column">
                          <Table
                              role="user"
                              query={query}
                              filters={filters}
                              onSelectUser={(user) =>
                                  setState({
                                    selectedUser: user._id,
                                    showProfile: true,
                                  })
                              }
                              onLoadUsers={(users) => setState({ users })}
                              refresh={(refresh) => setState({ refresh })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
              ) : (
                  <>
                    <MMSideBar isTabletDown={true} filterButton={handleFilterButtonClick} filters={filters} saveFilters={saveFilters} toggleFilter={toggleFilter} />
                    <div style={{ alignItems: "center", flex: 1, backgroundColor: theme.colors.secondaryBlue, padding: "8px", display: "flex", justifyContent: "space-between", height: "5svh" }}>
                      <span style={{ fontSize: "20px", fontWeight: 600, color: theme.colors.white }}>Members</span>
                      <span style={{ fontSize: "15px", fontWeight: 400, color: theme.colors.white }}>Total: {users.total}</span>
                    </div>
                    <Table isMobile={true} role="user"
                           query={query}
                           filters={filters}
                           onSelectUser={(user) =>
                               setState({
                                 selectedUser: user._id,
                                 showProfile: true,
                               })
                           }
                           onLoadUsers={(users) => setState({ users })}
                           refresh={(refresh) => setState({ refresh })} />
                  </>
              )}
            </Page>
        )}

      </MediaQueryProvider>
  );
}