import React from "react";
import { Image } from "launchpad";
import Fade, { FadeBlocks } from "react-move-stuff";

export default class Hero extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Image
        name={this.props.id || this.props.name}
        className={
          "hero " +
          (this.props.className || "") +
          (this.props.half ? " half" : "")
        }
        alwaysEdit={this.props.alwaysEdit}
        useEditIcon
      >
        <FadeBlocks zoom-out up>
          {this.props.children}
        </FadeBlocks>
      </Image>
    );
  }
}
