import React from "react";
import checkSvg from "../../images/check.svg";

export const CheckIcon = ({
  width = 24,
  height = 24,
  style = {},
  className,
  ...rest
}) => {
  return (
    <img
      style={{ width, height, ...style }}
      src={checkSvg}
      alt="Check icon"
      className={className}
      {...rest}
    />
  );
};
