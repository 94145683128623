  export const RELOCATION_OPTIONS = [
    { value: "No", label: "No", id: 1 },
    { value: "Maybe", label: "Maybe", id: 2 },
    { value: "Yes, nearly anywhere", label: "Yes, nearly anywhere", id: 3 },
    { value: "Yes, major cities", label: "Yes, major cities", id: 4 },
    { value: "Yes, but I'm not a big city person", label: "Yes, but I'm not a big city person", id: 5,},
  ];
  
  export const HOBBIES = [
    { value: "Art/art projects", label: "Art/art projects", id: 1 },
    { value: "Biking/cycling", label: "Biking/cycling", id: 2 },
    { value: "Board games", label: "Board games", id: 49},
    { value: "Cooking", label: "Cooking", id: 3 },
    { value: "Fishing", label: "Fishing", id: 5 },
    { value: "Gardening", label: "Gardening", id: 6 },
    { value: "Golf", label: "Golf", id: 7 },
    { value: "Gym/lifting weights", label: "Gym/lifting weights", id: 8 },
    { value: "Hiking", label: "Hiking", id: 9 },
    { value: "Hosting dinner parties", label: "Hosting dinner parties", id: 10,},
    { value: "Hunting", label: "Hunting", id: 11 },
    { value: "Movies", label: "Movies", id: 12 },
    { value: "Museums", label: "Museums", id: 13 },
    { value: "Reading", label: "Reading", id: 15 },
    { value: "Religious services/activities", label: "Religious services/activities", id: 16,},
    { value: "Running", label: "Running", id: 17 },
    { value: "Shopping", label: "Shopping", id: 18 },
    { value: "Skiing/snowboarding", label: "Skiing/snowboarding", id: 19 },
    { value: "Surfing", label: "Surfing", id: 20 },
    { value: "Swimming", label: "Swimming", id: 21 },
    { value: "Team Sports", label: "Team Sports", id: 22 },
    { value: "Tennis", label: "Tennis", id: 23 },
    { value: "Theatre/plays", label: "Theatre/plays", id: 24 },
    { value: "Traveling", label: "Traveling", id: 25 },
    { value: "Video games", label: "Video games", id: 26 },
    { value: "Volunteering", label: "Volunteering", id: 27 },
    { value: "Walking", label: "Walking", id: 28 },
    { value: "Watching sports", label: "Watching sports", id: 29 },
    { value: "Watching TV", label: "Watching TV", id: 30 },
    { value: "Other", label: "Other", id: 31 },
    { value: "Burning Man", label: "Burning Man", id: 32 },
    { value: "Camping", label: "Camping", id: 33 },
    { value: "Dancing", label: "Dancing", id: 34 },
    { value: "Meditation", label: "Meditation", id: 35 },
    { value: "Yoga", label: "Yoga", id: 36 },
    { value: "Aviation", label: "Aviation", id: 37 },
    { value: "Boating/sailing", label: "Boating/sailing", id: 38 },
    { value: "Concerts", label: "Concerts", id: 39 }, //music
    { value: "Music festivals", label: "Music festivals", id: 40 }, // music
    { value: "Symphony", label: "Symphony", id: 41 }, // music
    { value: "Singing", label: "Singing", id: 42 }, // music
    { value: "Playing an instrument", label: "Playing an instrument", id: 43 }, // music
    { value: "Photography", label: "Photography", id: 44 },
    { value: "Kayaking", label: "Kayaking", id: 45 },
    { value: "Paddleboarding", label: "Paddleboarding", id: 46 },
    { value: "Scuba diving", label: "Scuba diving", id: 47 },
    { value: "Wineries", label: "Wineries", id: 48 },
  ];
  
  export const MUSIC = {
    activities: [
      { value: "Concerts", label: "Concerts", id: 1 },
      { value: "Music festivals", label: "Music festivals", id: 2 },
      { value: "Symphony", label: "Symphony", id: 3 },
      { value: "Singing", label: "Singing", id: 4 },
      { value: "Playing an instrument", label: "Playing an instrument", id: 5,},
      { value: "Other", label: "Other", id: 999 },
    ],
    genres: [
      { value: "Bluegrass", label: "Bluegrass", id: 1 },
      { value: "Blues", label: "Blues", id: 2 },
      { value: "Classical", label: "Classical", id: 3 },
      { value: "Country", label: "Country", id: 4 },
      { value: "Electronic", label: "Electronic", id: 5 },
      { value: "Folk/singer-songwriter", label: "Folk/singer-songwriter", id: 6,},
      { value: "International/world", label: "International/world", id: 7,},
      { value: "Jazz", label: "Jazz", id: 8 },
      { value: "New age", label: "New age", id: 9 },
      { value: "Pop", label: "Pop", id: 10 },
      { value: "R&B/soul", label: "R&B/soul", id: 11 },
      { value: "Rap/hip-hop", label: "Rap/hip-hop", id: 12 },
      { value: "Religious", label: "Religious", id: 13 },
      { value: "Rock", label: "Rock", id: 14 },
    ],
  };
  
  export const TRAVEL_IMPORTANCE = [
    { value: "Very (I want to take 3+ trips per year) ", label: "Very (I want to take 3+ trips per year)", id: 1 },
    { value: "Somewhat (1-2 trips per year is great)", label: "Somewhat (1-2 trips per year is great)", id: 2 },
    { value: "Not very (It's not a desire or priority)", label: "Not very (It's not a desire or priority)", id: 3 },
  ];
  
  export const TRAVEL_EXPERIENCE = [
    { value: "More than 30 countries", label: "More than 30 countries", id: 1 },
    { value: "15-29 countries", label: "15-29 countries", id: 2 },
    { value: "5-14 countries", label: "5-14 countries", id: 3 },
    { value: "1-4 countries", label: "1-4 countries", id: 4 },
    { value: "None", label: "None", id: 5 },
  ];

export const WORKOUT = [
  { value: "Daily", label: "Daily", id: 1 },
  { value: "Most days per week", label: "Most days per week", id: 2 },
  { value: "2-3 days per week", label: "2-3 days per week", id: 3 },
  { value: "Once a week", label: "Once a week", id: 4 },
  {
    value: "Less than once a week but I want to do it more",
    label: "Less than once a week but I want to do it more",
    id: 5,
  },
  {
    value: "Less than once a week and I don't want to do more",
    label: "Less than once a week and I don't want to do more",
    id: 6,
  },
];

export const SMOKING_TYPE = [
  { value: "Never", label: "Never", id: 1 },
  { value: "Socially", label: "Socially", id: 2 },
  { value: "Often", label: "Often", id: 3 },
];

export const EATING_HABITS = [
  {
    value: "I eat pretty much everything",
    label: "I eat pretty much everything",
    id: 1,
  },
  {
    value: "I'm kind of a picky eater",
    label: "I'm kind of a picky eater",
    id: 2,
  },
  { value: "Very health-conscious", label: "Very health-conscious", id: 3 },
  { value: "Vegetarian", label: "Vegetarian", id: 4 },
  { value: "Vegan", label: "Vegan", id: 5 },
  { value: "Gluten free", label: "Gluten free", id: 6 },
  { value: "Other", label: "Other", id: 7 },
];

export const DRINKING_TYPE = [
  { value: "Never", label: "Never", id: 1 },
  {
    value: "A few times a year",
    label: "A few times a year",
    id: 2,
  },
  { value: "2-3 times a month", label: "2-3 times a month", id: 3 },
  { value: "1-3 times a week", label: "1-3 times a week", id: 4 },
  { value: "4-6 times a week", label: "4-6 times a week", id: 5 },
  { value: "Every day", label: "Every day", id: 6 },
];

export const DO_DRUGS = [
  {
    value: "Never",
    label: "Never",
    id: 4,
  },
  {
    value: "Special Occasions",
    label: "Special Occasions",
    id: 1,
  },
  {
    value: "Once a month to once a week",
    label: "Once a month to once a week",
    id: 2,
  },
  {
    value: "Multiple times a week",
    label: "Multiple times a week",
    id: 3,
  },
];
  
