@import "src/scss/variables";

.select {
  height: 40px;
  min-width: 73px;
  border: 1px solid $tcolor3;
  border-radius: 4px;
  background-color: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.7em;
  color: $textPrimary;
  box-sizing: border-box;

  &.expandable {
    cursor: pointer;
  }

  &.full-width {
    width: 100%;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &__value {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__placeholder {
    color: $helperText;
  }

  &__icons {
    display: flex;
    align-items: center;
    height: 32px;
    padding-left: 8px;
    border-left: 1px solid $tcolor3;

    &__chevron {
      &.padding {
        padding: 0 0.7em;
      }
    }

    &__delete {
      padding-left: 0.7em;
      border-left: solid 1px $greyLightest;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    min-width: inherit;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.4);
    z-index: 1;
    margin: 0;
    padding: 0;
    transform: translateY(44px);
    list-style: none;
    border-radius: 4px;
    cursor: pointer;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;

    &__option {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      box-sizing: border-box;
      padding: 0.5rem;

      &.hovereable {
        &:hover {
          background-color: $yellow;
        }
      }

      &.selected {
        font-weight: bold;
      }
    }
  }

  &__search {
    position: relative;
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;

    &__input {
      border: none;
      -webkit-appearance: none;
      -ms-appearance: none;
      -moz-appearance: none;
      appearance: none;
      width: 100%;
      border-bottom: 1px solid $greyLightest;
      box-sizing: border-box;

      &:focus {
        outline: none;
      }
    }

    &__label {
      position: absolute;
      top: 1rem;
      left: 1.5rem;
      color: $textLighter;
      font-size: 0.8rem;
      pointer-events: none;
      transition: all 0.2s ease-in-out;
      font-style: italic;
      display: flex;
      flex-direction: row;
      gap: 0.4rem;
      align-items: center;
    }
  }
}
