// general

export const LOAD_USERS = "LOAD_USERS";
export const LOAD_USER_INFO = "LOAD_USER_INFO";
export const CLOSE_USER_INFO_MODAL = "CLOSE_USER_INFO_MODAL";
export const OPEN_CLOSE_FILTER_MODAL_AND_SET_FILTER =
  "OPEN_CLOSE_FILTER_MODAL_AND_SET_FILTER";
export const OPEN_CLOSE_SAVE_FILTER_MODAL = "OPEN_CLOSE_SAVE_FILTER_MODAL";
export const SET_SAVED_FILTER = "SET_SAVED_FILTER";
export const LOAD_SAVED_FILTERS = "LOAD_SAVED_FILTERS";
export const LOAD_USERS_COUNT = "LOAD_USERS_COUNT";
export const SEND_CONTACT_MESSAGE = "SEND_CONTACT_MESSAGE";
export const SENT_CONTACT_MESSAGE = "SENT_CONTACT_MESSAGE";
export const FILTER_USERS = "FILTER_USERS";

// authentication

export const LOGIN_USER = "LOGIN_USER";
export const SIGN_UP_USER = "SIGN_UP_USER";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const GET_USER_TOKEN = "GET_USER_TOKEN";
export const GET_LOGGED_IN_USER = "GET_LOGGED_IN_USER";
export const REMOVE_ERRORS = "REMOVE_ERRORS";

// header
export const SET_SELECTED_HEADER_TAB = "SET_SELECTED_HEADER_TAB";

// onboarding

export const SET_ON_BOARDING_DATA = "SET_ON_BOARDING_DATA";
export const SAVE_USER_DATA = "SAVE_USER_DATA";
export const SET_SIGNED_UP_USER = "SET_SIGNED_UP_USER";
export const SET_LOCAL_STORAGE_DATA = "SET_LOCAL_STORAGE_DATA";
export const REMOVE_ON_BOARDING_DATA = "REMOVE_ON_BOARDING_DATA";

// matchmakers
export const SET_LEFT_PANEL_FILTERS = "SET_LEFT_PANEL_FILTERS";
export const RESET_LEFT_PANEL_FILTER = "RESET_LEFT_PANEL_FILTER";
export const SAVE_SELECTED_FILTERS = "SAVE_SELECTED_FILTERS";
export const REQUEST_ACCESS_FOR_USER = "REQUEST_ACCESS_FOR_USER";
export const CANCEL_REQUEST_ACCESS_FOR_USER = "CANCEL_REQUEST_ACCESS_FOR_USER";
export const LOAD_MATCHMAKER_MATCHES = "LOAD_MATCHMAKER_MATCHES";
export const LOAD_MATCHMAKERS = "LOAD_MATCHMAKERS";
export const SIGN_UP_MATCH_MAKER = "SIGN_UP_MATCH_MAKER";
export const RESET_SIGN_UP_FLAG = "RESET_SIGN_UP_FLAG";
export const LOCK_USER = "LOCK_USER";
export const UN_LOCK_USER = "UN_LOCK_USER";
export const OPEN_CLOSE_RATING_MODAL = "OPEN_CLOSE_RATING_MODAL";
export const RATE_USER = "RATE_USER";
export const LOAD_FAVORITE_USERS = "LOAD_FAVORITE_USERS";
export const ADD_USER_TO_FAVORITES = "ADD_USER_TO_FAVORITES";
export const REMOVE_USER_FROM_FAVORITES = "REMOVE_USER_FROM_FAVORITES";
export const TOGGLE_LOADING_MODAL = "TOGGLE_LOADING_MODAL";
export const LOAD_MM_PLAN_INFO = "LOAD_MM_PLAN_INFO";
export const ADD_CHANGE_USER_STATUS = "ADD_CHANGE_USER_STATUS";
export const GET_MM_AGREED_TERMS = "GET_MM_AGREED_TERMS";
export const MM_AGREED_TERMS = "MM_AGREED_TERMS";
export const CHANGE_MM_PLAN = "CHANGE_MM_PLAN";
export const GET_MM_REFERRALS = "GET_MM_REFERRALS";
export const ADD_MM_REFERRAL = "ADD_MM_REFERRAL";

// admin
export const APPROVED_MATCHMAKER = "APPROVED_MATCHMAKER";
export const REJECT_MATCHMAKER = "REJECT_MATCHMAKER";
export const LOAD_PLANS = "LOAD_PLANS";
export const PLAN_SAVED = "PLAN_SAVED";
export const SAVING = "SAVING";
export const USER_INACTIVATE = "USER_INACTIVATE";
export const LOAD_USER_INFO_ADMIN = "LOAD_USER_INFO_ADMIN";
export const SET_DELETE_PHOTO = "SET_DELETE_PHOTO";
export const CREATE_NOTE = "CREATE_NOTE";
export const GET_USER_NOTES = "GET_USER_NOTES";
export const UPDATE_NOTE = "UPDATE_NOTE";
export const ADD_MM_FILTER = "ADD_MM_FILTER";
export const DELETE_MM_FILTER = "DELETE_MM_FILTER";
export const CLEAN_MM_FILTERS = "CLEAN_MM_FILTERS";
export const SET_MM_SHOW = "SET_MM_SHOW";

// users
export const SAVE_USER_FIELD_DATA = "SAVE_USER_FIELD_DATA";
export const SAVE_USER_FIELD_DATA_FROM_ADMIN =
  "SAVE_USER_FIELD_DATA_FROM_ADMIN";
export const SET_PERCENTAGE = "SET_PERCENTAGE";
export const CLOSE_TC_MODAL = "CLOSE_TC_MODAL";
export const USER_EMAILS_PHOTOS_ADDED = "USER_EMAILS_PHOTOS_ADDED";
export const USER_NO_DATA_OR_PHOTOS_FILLED = "USER_NO_DATA_OR_PHOTOS_FILLED";
export const SET_DELETE_PHOTO_USER = "SET_DELETE_PHOTO_USER";
export const UPDATE_USER_REGION = "UPDATE_USER_REGION";
