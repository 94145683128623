import React, { Component } from "react";
import PropTypes from "prop-types";
import InputText from "../../../components/input-text";
import Checkbox from "../../../components/check-box/index";
import "./style.scss";
const MM_VALUES = [
  { id: 1, label: "City", value: "", hasInput: true, stateKey: null },
  { id: 2, label: "Gender", value: "", hasInput: true, stateKey: "gender" },
  {
    id: 3,
    label: "Age Range",
    value: "",
    hasInput: true,
    stateKey: "ageRange",
  },
  { id: 4, label: "Religion", value: "", hasInput: true, stateKey: "religion" },
  {
    id: 5,
    label: "Ethnicity",
    value: "",
    hasInput: true,
    stateKey: "ethnicity",
  },
  {
    id: 6,
    label: "Sexual Orientation",
    value: "",
    hasInput: true,
    stateKey: "sexO",
  },
  { id: 7, label: "Other", value: "", hasInput: true, stateKey: "other" },
  { id: 8, label: "Don't care", value: "", hasInput: false },
];

class MatchmakerOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matchmakerOptions: [],
      inputs: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.resetValues) {
      return { matchmakerOptions: [], inputs: {} };
    }
    return null;
  }

  render() {
    const { matchmakerOptions, inputs } = this.state;
    const { returnOptionsValues, error } = this.props;
    return (
      <div className="matchmaker-options flex-column">
        <div className="flex-row" style={{ position: "relative" }}>
          <div className="matchmaker-options__title">
            Do you care about your Matchmaker’s…:
          </div>
          {error && <div className="custom-error-label">{error}</div>}
        </div>
        {MM_VALUES.map((val, v) => {
          const isChecked = !!matchmakerOptions.find(
            (item) => item.id === val.id
          );
          return (
            <div className="flex-row" key={v}>
              <Checkbox
                labelName={val.label}
                checked={isChecked}
                clicked={() => {
                  !isChecked
                    ? this.setState(
                        { matchmakerOptions: [...matchmakerOptions, val] },
                        () => {
                          this.afterSetStateFinished();
                        }
                      )
                    : this.setState(
                        {
                          matchmakerOptions: matchmakerOptions.filter(
                            (item) => item.id !== val.id
                          ),
                          inputs: { ...inputs, [val.stateKey]: null },
                        },
                        () => {
                          this.afterSetStateFinished();
                        }
                      );
                }}
              />
              {val.hasInput && (
                <InputText
                  wrapperClassName="flex-column float-right"
                  placeholder=""
                  disabled={!isChecked}
                  isValid={inputs[val.stateKey]}
                  onBlur={() => this.returnValues(returnOptionsValues)}
                  onChange={(e) =>
                    this.setState({
                      inputs: { ...inputs, [val.stateKey]: e.target.value },
                    })
                  }
                  value={inputs[val.stateKey]}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  }

  afterSetStateFinished = () => {
    const { returnOptionsValues } = this.props;
    this.returnValues(returnOptionsValues);
  };

  returnValues = (returnOptionsValues) => {
    const { matchmakerOptions, inputs } = this.state;
    const values = [];
    matchmakerOptions.forEach((item) => {
      values.push({ [item.label]: inputs[item.stateKey] || "" });
    });
    returnOptionsValues(values);
  };
}

MatchmakerOptions.propTypes = {
  returnOptionsValues: PropTypes.func,
  resetValues: PropTypes.bool,
  error: PropTypes.any,
};

export default MatchmakerOptions;
