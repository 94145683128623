// refactoring of old header, TODO: would like to make this dynamic at some point

import React, { useState } from "react";
import UserSession from "_old/src/components/user-session";
import logo from "_old/src/components/main-header/assets/date-spot-logo.svg";
import "_old/src/components/main-header/style.scss";
import { history } from "app";
import { getGlobal } from "launchpad";
import { DropDown, Link } from "widgets";
import { logoutUser } from "../../utils/client";

const menuOptions = {
    "ADD PERSON": "/the-basics",
    SINGLES: "/dashboard",
    "USER DATABASE": { href: "/", icon: "icon-list" },
    "MY POTENTIALS": { href: "/my-favorites", icon: "icon-heart" },
    "CONTACTED BY ME": { href: "/my-contacted", icon: "icon-contacted" },
    MATCHMAKERS: "/matchmakers-list",
    "MY LOCKS": { href: "/my-locks", icon: "icon-lock" },
    PLANS: "/plans",
    "ABOUT US": `${process.env.MARKETING_SITE_URL}/about-us`,
    CONTACT: `${process.env.MARKETING_SITE_URL}/contact`,
    "MY PROFILE": "/my-profile",
    DASHBOARD: "/dashboard",
    "YOUR REFERRALS": "/matchmaker-referrals",
    "REQUESTED": "/requested",
    "HELP": "/help",
    "BADGES": "/badges",
    "METRICS": "/metrics",
};

const menus = {
    admin: ["SINGLES","MATCHMAKERS", "PLANS", "METRICS"],
    matchmaker: ["DASHBOARD", "YOUR REFERRALS", "HELP", "BADGES"],
    //matchmaker: ["DASHBOARD", "YOUR REFERRALS", "REQUESTED",  "HELP", "BADGES"],
    ambassador: ["YOUR REFERRALS", "CONTACT"],
    user: ["ABOUT US", "CONTACT"],
};

const Tab = ({ link }) => {
    const tab = menuOptions[link];
    const { icon, href } = tab;
    const redirectUrl = href || tab;
    const selected = window.location.pathname === redirectUrl;

    const redirectToSelectedTab = () =>{
        redirectUrl.startsWith(process.env.MARKETING_SITE_URL)
            ? window.open(redirectUrl)
            :
            history.push(redirectUrl);}

    return (
        <div
            className={`flex-center  main-header__tab ${link
                .replace(/ |\//g, "")
                .toLowerCase()}-tab`}
        >
            <div
                style={{ color: "#000000", fontWeight: 500 }}
                className={`main-header__item flex-center ${
                    selected ? "selected-tab" : ""
                }`}
                onClick={redirectToSelectedTab}
            >
                {icon && <span className={`tab-icon ${icon}`} />} {link}
            </div>
        </div>
    );
};

export default function MainHeader() {
    const [menu, showMenu] = useState(false);
    const { activeUser } = getGlobal();
    let userRole = activeUser && activeUser.role;
    if (UserSession.getToken().ambassadorAccount === true) {
        userRole = "ambassador";
    }

    return !userRole ? null : (
        <div style={{ justifyContent: "space-evenly" }} className={`main-header flex-row home-pages-white-theme`}>
            <div className="flex-center  main-header__tab date-spot-logo">
                <div className="flex-column">
                    <img src={logo} onClick={() => history.push("/dashboard")} />
                </div>
            </div>
            <div className={`main-header flex-row home-pages-white-theme`} style={{ display: 'flex', justifyContent: 'center' }}>
                {menus[userRole].map((x) => (
                    <Tab link={x} key={x} />
                ))}
                <div className="header-menu-spacer"></div>
            </div>
            <div>
                {/* {userRole == 'matchmaker' && <Limits user={activeUser} />} */}
                {activeUser && (
                    <div
                        style={{ color: "#54595B", fontWeight: 500 }}
                        className="user-menu"
                        onClick={() => showMenu(true)}
                        onMouseLeave={() => showMenu(false)}
                    >
                        {activeUser.firstName} {activeUser.lastName}
                        <DropDown open={menu}>
                            <Link href="#" onClick={logoutUser}>
                                Sign Out
                            </Link>
                        </DropDown>
                    </div>
                )}
            </div>
        </div>
    );
}

const Limits = ({ user }) => {
    if (!user || !user.limits) return <div />;
    const { limits } = user;
    const connected = limits.max.connected - limits.current.connected;
    return (
        <div className="matchmaker-status">
            <label>Currently Available</label>
            <div className="status">
                {limits.connections > 999999 ? "Unlimited" : limits.connections}{" "}
                <span className="icon icon-contacted" />
                {connected} <span className="fa fa-link" />
                {user.planCanceled ? 0 : limits.locks}{" "}
                <span className="icon icon-lock" />
                <Link href="/matchmaker-referrals">
                    <span className="icon-plus-yellow" onClick={() => {}} />
                </Link>
            </div>
        </div>
    );
};
