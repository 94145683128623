import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./DropdownMenu.scss";
import { useEventListener } from "../hooks";

const DropdownMenu = ({
  showCompleted = false,
  value = "",
  onChange = () => {},
  options = [],
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(
    options?.filter((option) => option.value !== options[0].value) || []
  );
  const [selectedOption, setSelectedOption] = useState(
    options?.find((option) => option.value === value) || {}
  );

  useEffect(() => {
    if (
      value !== selectedOption.value ||
      (showCompleted &&
        options.find(
          (option) => option.value.completed !== selectedOption.completed
        ))
    )
      setSelectedOption(
        options?.find((option) => option.value === value) || {}
      );
    setFilteredOptions(
      options?.filter((option) => option.value !== value) || []
    );
  }, [value, options]);

  useEventListener(
    "click",
    (e) => {
      if (!e.target.closest(".dropdown-menu__options-container") && open) {
        setOpen(false);
      }
    },
    document
  );

  const handleHeaderClick = () => {
    if (filteredOptions.length) setOpen(!open);
  };

  const handleRowClick = (e, newSelectedOption) => {
    setSelectedOption(newSelectedOption);
    setOpen(false);
    setFilteredOptions(
      options.filter((option) => option.value !== newSelectedOption.value)
    );
    onChange(newSelectedOption.value);
  };

  return (
    <div
      className={`dropdown-menu${disabled ? " dropdown-menu--disabled" : ""}`}
      id="dropdown-menu"
    >
      <DropdownMenuRow
        isHeader
        open={open}
        expandable={!!filteredOptions.length}
        option={selectedOption}
        onClick={handleHeaderClick}
        showCompleted={showCompleted}
      />
      <div
        className={`dropdown-menu__options-container${
          open ? " dropdown-menu__options-container--open" : ""
        }`}
      >
        {filteredOptions.map((option) =>
          option.value === selectedOption.value ? null : (
            <DropdownMenuRow
              key={`dropdown-menu-option-${option.value}`}
              option={option}
              onClick={handleRowClick}
              showCompleted={showCompleted}
            />
          )
        )}
      </div>
    </div>
  );
};

const DropdownMenuRow = ({
  isHeader = false,
  showCompleted = false,
  open = false,
  expandable = false,
  option = {},
  onClick = () => {},
}) => {
  return (
    <div
      className={`dropdown-menu-row${
        isHeader ? " dropdown-menu-row--header" : ""
      }`}
      onClick={(e) => onClick(e, option)}
    >
      <div className="dropdown-menu-row__label-container">
          <div className="dropdown-menu-row__label-container__check">
            <i className="fas fa-check" />
          </div>
        {option.icon}
        {option.text}
      </div>
      {isHeader && expandable ? (
        <i className={`fas fa-chevron-down${open ? " fa-rotate-180" : ""}`} />
      ) : null}
    </div>
  );
};

DropdownMenu.propTypes = {
  showCompleted: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.string,
      icon: PropTypes.node,
      completed: PropTypes.bool,
    })
  ),
};

DropdownMenuRow.propTypes = {
  isHeader: PropTypes.bool,
  showCompleted: PropTypes.bool,
  open: PropTypes.bool,
  expandable: PropTypes.bool,
  option: PropTypes.shape({
    value: PropTypes.string,
    text: PropTypes.string,
    icon: PropTypes.node,
    completed: PropTypes.bool,
  }),
  onClick: PropTypes.func,
};

export default DropdownMenu;
