import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import InputText from "../../../../components/input-text";
import CheckBoxList from "../../../../components/check-box-list";
import RadioList from "../../../../components/radio-list";
import DropDown from "../../../../components/drop-down";
import Validator from "../../../../components/utils/validator/index";
import debounce from "lodash.debounce";

import {
  GENDERS,
  INTERESTS,
  MARRIAGE_STATUSES,
  RELIGION,
  ETHNICITY,
  RELATIONSHIP_TYPE,
  BODY_TYPE,
} from "../../../../constants/onboarding-data-elements";
import { MONTHS, DAYS, generateYears } from "../../../../constants/index";

const YEARS = generateYears();
import { default as OldButton } from "../../../../components/button/index";
import { post } from "src/utils/client";
import { compareStringContents, insertAt } from "src/utils/functions";
import { Autocomplete } from "src/components/widgets";
import { COUNTRIES } from "src/data/countries";
import { Button } from "src/components/widgets";
import Select from "../../../../../../src/components/Select";

function CountryField(props) {
  return null;
}

CountryField.propTypes = {
  onDelete: PropTypes.any,
  onChange: PropTypes.any,
  options: PropTypes.any,
  proficiencyOptions: PropTypes.arrayOf(PropTypes.string),
  proficiency: PropTypes.any,
};

const REQUIRED_FIELDS = [
  "firstName",
  "lastName",
  "gender",
  "interestedIn",
  "day",
  "month",
  "year",
  "country",
  "city",
  "marriageStatus",
  "ethnicity",
  "feet",
  "inches",
  "bodyTypes",
];
// ! TODO: see if there's a case in which kidsAges is not an array
class TheBasics extends Component {
  constructor(props) {
    super(props);
    let day = null;
    let month = null;
    let year = null;
    if (props.user.birthDate) {
      day = DAYS.find((day) => day.value === props.user.birthDate.slice(3, 5));
      month = MONTHS.find(
        (mo) => mo.value === props.user.birthDate.trim().slice(0, 2)
      );
      year = YEARS.find(
        (y) => y.value === parseInt(props.user.birthDate.slice(6, 10), 10)
      );
    }
    this.state = {
      firstName: props.user.firstName || null,
      lastName: props.user.lastName || null,
      gender: props.user.gender || null,
      interestedIn: props.user.interestedIn || [],
      bodyTypes: props.user.bodyTypes || null,
      day: day || null,
      month: month || null,
      year: year || null,
      country: props.user.country || null,
      city: props.user.city || null,
      state: props.user.state || null,
      marriageStatus: props.user.marriageStatus || null,
      relationshipType: props.user.relationshipType || null,
      ethnicity: props.user.ethnicity || [],
      feet: props.user.height
        ? this.inchesToHeight(props.user.height)[0]
        : null,
      inches: props.user.height
        ? this.inchesToHeight(props.user.height)[1]
        : null,
      // hasKids: props.user.hasKids || null,
      // futureChildScenarios: props.user.futureChildScenarios || [],
      errors: {},
      validFields: {
        firstNameValid: !!props.user.firstName,
        lastNameValid: !!props.user.lastName,
        cityValid: !!props.user.city,
      },
      cityOptions: [
        { city: props.user.city || null, state: props.user.state || null },
      ],
      citiesLoading: false,
      cityOptionWasSelected: !!props.user.city,
    };
  }

  render() {
    const { isTabletDown } = this.props;
    const renderNameInputs = this._renderNameInputs(isTabletDown);
    const renderGenders = this._renderGenders();
    const renderInterests = this._renderInterests();
    const renderBirthDate = this._renderBirthDate();
    const renderLocationFields = this._renderLocationFields(isTabletDown);
    const renderMarriageStatus = this._renderMarriageStatus();
    const renderRelationshipType = this._renderRelationshipType();
    const renderEthnicity = this._renderEthnicity();
    const renderHeight = this._renderHeight();
    const renderBodyType = this._renderBodyType();

    return (
      <div className="the-basics">
        {renderNameInputs}
        {renderGenders}
        {renderInterests}
        {renderBirthDate}
        {renderLocationFields}
        {renderMarriageStatus}
        {renderRelationshipType}
        {renderEthnicity}
        {renderHeight}
        {renderBodyType}
        <div className="continue-button-container">
          {!this.props.isCompleted && (
            <span className="helper-text">
              Please fill out required questions above to continue.
            </span>
          )}
          {isTabletDown ? (
            <Button
              fullWidth
              variant="primary"
              disabled={!this.props.isCompleted}
              onClick={() => this.props.setSectionFromNextButtons("photos")}
            >
              CONTINUE
            </Button>
          ) : (
            <OldButton
              value="CONTINUE"
              click={() => this.props.setSectionFromNextButtons("photos")}
              customClass="yellow"
              disabled={!this.props.isCompleted}
            />
          )}
        </div>
      </div>
    );
  }

  _renderNameInputs = (isTabletDown) => {
    const { firstName, lastName, errors, validFields } = this.state;
    return (
      <div
        className={isTabletDown ? "flex-column gap-20" : "flex-row"}
        style={{ alignItems: "flex-start" }}
      >
        <InputText
          required
          wrapperClassName="flex-column margin-20 full-width"
          placeholder="First Name"
          label="First Name"
          showLabel={true}
          classNameForLabel={`${
            !firstName || errors.firstNameError
              ? "show-label redish"
              : "show-label greenish"
          }`}
          error={errors.firstNameError}
          isValid={firstName && validFields.firstNameValid}
          onBlur={(e) =>
            this.checkForValidationErrors(
              "name",
              e.target.value,
              "firstNameError",
              "firstNameValid"
            )
          }
          onChange={(e) =>
            this.setState({
              firstName: e.target.value,
              validFields: {
                ...validFields,
                firstNameValid: null,
              },
              errors: { ...errors, firstNameError: null },
            })
          }
          value={firstName}
        />
        <InputText
          required
          wrapperClassName="flex-column full-width"
          placeholder="Last Name"
          label="Last Name"
          showLabel={true}
          classNameForLabel={`${
            !lastName || errors.lastNameError
              ? "show-label redish"
              : "show-label greenish"
          }`}
          error={errors.lastNameError}
          isValid={lastName && validFields.lastNameValid}
          onBlur={(e) =>
            this.checkForValidationErrors(
              "name",
              e.target.value,
              "lastNameError",
              "lastNameValid"
            )
          }
          onChange={(e) =>
            this.setState({
              lastName: e.target.value,
              validFields: {
                ...validFields,
                lastNameValid: null,
              },
              errors: { ...errors, lastNameError: null },
            })
          }
          value={lastName}
          afterLabel="Last names are never shared with any other members"
        />
      </div>
    );
  };

  _renderGenders = () => {
    const { gender } = this.state;
    return (
      <RadioList
        required
        list={GENDERS}
        title="Gender"
        selectedValue={gender}
        stateCallback={(val) => this.changeData("gender", val)}
        returnInputValue={(val) => this.changeData("gender", val)}
        radioWrapperClassName="full-width"
      />
    );
  };

  _renderInterests = () => {
    const { interestedIn } = this.state;
    return (
      <CheckBoxList
        required
        list={INTERESTS}
        title="Interested in"
        selectedValue={interestedIn}
        returnInputValue={(val) => this.changeData("interestedIn", val)}
        stateCallback={(val) => this.changeData("interestedIn", val)}
      />
    );
  };

  _renderBirthDate = () => {
    const { validFields, errors } = this.state;
    const { month, day, year } = this.state;
    return (
      <div className="birthdate-container flex-column">
        <div className={`${!month || !day || !year ? "label" : "label valid"}`}>
          Birthdate{(!month || !day || !year) && "*"}
        </div>
        <div
          className={`date-input ${errors.monthError ? "has-error" : ""}
                        ${validFields.validDate ? "is-valid" : ""}`}
        >
          <div className="date-input__inner-container">
            <DropDown
              wrapperClassName="month-drop-down"
              listOfValues={MONTHS || []}
              selectedValue={month}
              name="selectedMonth"
              placeholder="Month"
              onSelectValue={(val) => {
                this.processDate(val, day, year);
                this.setState({ month: val });
              }}
            />
            <DropDown
              wrapperClassName="day-drop-down"
              listOfValues={DAYS || []}
              selectedValue={day}
              name="selectedDay"
              placeholder="Day"
              onSelectValue={(val) => {
                this.processDate(month, val, year);
                this.setState({ day: val });
              }}
            />
            <DropDown
              wrapperClassName="year-drop-down"
              listOfValues={YEARS || []}
              selectedValue={year}
              name="selectedYear"
              placeholder="Year"
              onSelectValue={(val) => {
                this.processDate(month, day, val);
                this.setState({ year: val });
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  validateLocation = async (locationSearchData) => {
    return await post({
      url: "/v1/utilities/geolocation",
      data: locationSearchData,
    });
  };

  fetchCities = async (city) => {
    this.setState({ citiesLoading: true });
    // If the string is valid, check if it's a valid location
    const locationMatches = (
      await this.validateLocation({
        country: this.state.country,
        city,
      })
    ).data;

    const cityOptions = locationMatches
      .map((match) => ({
        city: match.city,
        state: match.state,
      }))
      .filter((option) => option.city);
    this.setState({
      cityOptions,
      citiesLoading: false,
    });
  };

  debouncedFetchCities = debounce(this.fetchCities, 1000);

  updateUserRegion = async (userId) => {
    return await post({
      url: "/update-user-region",
      data: { userId },
    });
  };

  _renderLocationFields = (isTabletDown) => {
    const {
      country,
      city,
      state,
      errors,
      validFields,
      citiesLoading,
      cityOptions,
    } = this.state;

    const handleCountryChange = (country) => {
      if (
        country === "US" ||
        country === "USA" ||
        country === "U.S" ||
        country === "U.S."
      ) {
        country = "United States of America";
      }
      this.setState({
        country,
        state: "",
        city: "",
        errors: {
          ...errors,
          // The field should display redish unless the user has selected a country from the dropdown
          countryError: true,
        },
      });
      const { _id: userId } = this.props.user;
      city && this.saveUserFieldData("city", "");
      state && this.saveUserFieldData("state", "");
    };

    const handleCityChange = (city) => {
      this.setState({ cityOptionWasSelected: false });
      this.setState({ state: null });
      this.setState({ city });
      if (city) this.debouncedFetchCities(city.trim());
    };

    const handleCitySelect = (cityState) => {
      this.setState({ cityOptionWasSelected: true });
      const { city, state } = cityState;
      const { city: serverStoredCity, state: serverStoredState } =
        this.props.user;

      if (
        compareStringContents(serverStoredCity || "", city || "") &&
        compareStringContents(serverStoredState || "", state || "")
      )
        return;

      this.setState({
        city,
        state,
      });

      const { _id: userId } = this.props.user;

      this.saveUserFieldData("state", state);
      this.saveUserFieldData("city", city, () => this.updateUserRegion(userId));
    };

    const handleCountrySelect = (country) => {
      handleCountryChange(country);
      this.setState({
        country,
      });
      return this.debouncedSaveUserFieldData("country", country);
    };

    return (
      <div className="flex-column">
        <div
          className={isTabletDown ? "flex-column gap-20" : "flex-row gap-20"}
          style={{ alignItems: "flex-start" }}
        >
          <div className="flex-column full-width" style={{ gap: "0.5rem" }}>
            <h3
              className={`other-section__field-container__title${
                !country ? " error" : ""
              }`}
            >
              Country{!country && "*"}
            </h3>
            <CountryFieldSelect
              required
              options={COUNTRIES}
              fullWidth
              value={country}
              onChange={handleCountrySelect}
            />
          </div>
          <Autocomplete
            required
            fullWidth
            value={city + (state ? ", " + state : "")}
            loading={citiesLoading}
            options={cityOptions}
            primaryAccessor="city"
            secondaryAccessor="state"
            onChange={handleCityChange}
            onOptionSelected={handleCitySelect}
            inputProps={{
              required: this.state.cityOptionWasSelected,
              disabled: !country,
              wrapperClassName: "flex-column full-width",
              placeholder: "City",
              label: "City",
              afterLabel:
                "Type your city and then choose an option from the dropdown menu",
              showLabel: true,
              classNameForLabel: `${
                !this.state.cityOptionWasSelected
                  ? "show-label redish"
                  : "show-label greenish"
              }`,
              isValid: validFields.cityValid,
            }}
          />
        </div>
      </div>
    );
  };

  _renderMarriageStatus = () => {
    const handleRelationshipStatusChange = (status) => {
      let newStatus = {
        ...status,
        date: new Date(),
      };

      this.props.setOnboardingSectionsDisabledState(
        newStatus.value === "Not Single"
      );

      this.saveUserFieldData("relationshipStatus", newStatus);
    };

    const handleMarriageStatusChange = (status) => {
      if (status.value === "Engaged/married") {
        this.saveUserFieldData("accountStatus", "inactive");
        handleRelationshipStatusChange({
          value: "Not Single",
          label: "Not Single",
        });
      } else if (this.state.accountStatus === "inactive") {
        this.saveUserFieldData("accountStatus", "active");
      }

      this.saveUserFieldData("marriageStatus", status);
    };

    const getMarriageStatuses = () => {
      const { createdAt } = this.props.user;
      // This way we ensure that the Engaged/married option only gets added if a user logs back in after some time
      let createdAtOneDayAfter = new Date(createdAt);
      createdAtOneDayAfter.setDate(createdAtOneDayAfter.getDate() + 1);

      if (createdAtOneDayAfter.getTime() < new Date().getTime()) {
        return insertAt(MARRIAGE_STATUSES, -1, {
          value: "Engaged/married",
          label: "Engaged/married",
        });
      }

      return MARRIAGE_STATUSES;
    };

    const { marriageStatus } = this.state;
    return (
      <RadioList
        required
        list={getMarriageStatuses()}
        title="Marriage status"
        selectedValue={marriageStatus}
        stateCallback={handleMarriageStatusChange}
        returnInputValue={(val) => this.changeData("marriageStatus", val)}
        radioWrapperClassName="full-width"
      />
    );
  };

  _renderRelationshipType = () => {
    const { relationshipType } = this.state;
    return (
      <CheckBoxList
        required
        list={RELATIONSHIP_TYPE}
        title="Relationship Type"
        selectedValue={relationshipType}
        stateCallback={(val) => this.changeData("relationshipType", val)}
        returnInputValue={(val) => this.changeData("relationshipType", val)}
        radioWrapperClassName="full-width"
        columns={1}
      />
    );
  };

  _renderEthnicity = () => {
    const { ethnicity } = this.state;
    return (
      <CheckBoxList
        required
        list={ETHNICITY}
        title="What is your ethnicity?"
        subTitle="Select all that apply"
        selectedValue={ethnicity}
        stateCallback={(val) => this.changeData("ethnicity", val)}
        columns={1}
      />
    );
  };

  _renderHeight = () => {
    const { feet, inches, errors } = this.state;
    return (
      <div className="height-container">
        <div className={`${!feet && !inches ? "label" : "label valid"}`}>
          Height
          {!feet && !inches && "*"}
        </div>
        <div
          className={`flex-row height-input
                            ${inches || feet ? " is-valid" : ""}`}
        >
          <InputText
            wrapperClassName="flex-column no-borders"
            placeholder="0"
            value={feet}
            maxLength="1"
            error={errors.feetError}
            onBlur={(e) => this.processHeight(e.target.value, inches)}
            onChange={(e) => this.setState({ feet: e.target.value })}
          />
          <span className="input-tag flex-center">ft</span>
          <InputText
            wrapperClassName="flex-column small no-borders"
            placeholder="00"
            maxLength="2"
            error={errors.inchesError}
            value={inches}
            onBlur={(e) => this.processHeight(feet, e.target.value)}
            onChange={(e) => this.setState({ inches: e.target.value })}
          />
          <span className="input-tag flex-center">in</span>
        </div>
      </div>
    );
  };

  _renderBodyType = () => {
    const { bodyTypes } = this.state;
    return (
      <CheckBoxList
        list={BODY_TYPE}
        title="What is your body type?"
        selectedValue={bodyTypes}
        returnInputValue={(val) => this.changeData("bodyTypes", val)}
        stateCallback={(val) => this.changeData("bodyTypes", val)}
        columns={1}
      />
    );
  };

  changeData = (stateVal, val) => {
    this.setState({ [stateVal]: val });
    this.debouncedSaveUserFieldData(stateVal, val);
  };

  inchesToHeight = (inches) => {
    return [Math.floor(inches / 12), inches % 12];
  };

  processHeight = (feet, inches) => {
    const parsedFeet = parseInt(feet, 10) || 0;
    const parsedInches = parseInt(inches, 10) || 0;

    const height = Math.max(parsedFeet, 0) * 12 + Math.max(parsedInches, 0);

    this.saveUserFieldData("height", height || null);
  };

  processDate = (month, day, year) => {
    if (month && day && year) {
      const birthDate = `${month.value}/${day.value}/${year.value}`;
      const formatDate = moment(birthDate, "MM-DD-YYYY");
      const age = parseInt(moment().diff(formatDate, "years"), 10);
      this.saveUserFieldData("birthDate", birthDate);
      this.debouncedSaveUserFieldData("age", age);
    }
  };

  saveUserFieldData = (field, value, callback) => {
    const { _id: userId } = this.props.user;

    this.props.saveUserFieldData(userId, field, value, callback);

    // Exclude fields from percentage calculation
    // don't grab from state, optimistically update with the new data
    let { cityOptions, citiesLoading, state, ...filteredState } = this.state;
    if (Object.keys(filteredState).find((x) => x === field.replace(".", "_")))
      filteredState[field] = value;
    this.props.calculatePercentage(
      filteredState,
      REQUIRED_FIELDS.length,
      "theBasics"
    );
  };

  debouncedSaveUserFieldData = debounce(this.saveUserFieldData, 285);

  checkForValidationErrors = (type, value, stateError, validField) => {
    let valid;
    switch (type) {
      case "char":
        valid = Validator.validateCharacter(value);
        this.setErrorsOrValids(valid, validField, stateError, "Invalid");
        valid &&
        this.debouncedSaveUserFieldData(
          stateError.replace("Error", ""),
          value
        );
        break;
      case "name":
        valid = Validator.validateName(value);
        this.setErrorsOrValids(valid, validField, stateError, "Invalid");
        valid &&
        this.debouncedSaveUserFieldData(
          stateError.replace("Error", ""),
          value
        );
        break;
      case "number":
        valid = Validator.validateNumber(value);
        this.setErrorsOrValids(valid, validField, stateError, "Numbers Only");
        valid &&
        this.debouncedSaveUserFieldData(
          stateError.replace("Error", ""),
          value
        );
        break;
      default:
        break;
    }
  };

  setErrorsOrValids = (valid, validField, stateError, message) => {
    const { validFields, errors } = this.state;
    valid
      ? this.setState({
        validFields: { ...validFields, [validField]: true },
      })
      : this.setState({ errors: { ...errors, [stateError]: message } });
  };
}

TheBasics.propTypes = {
  saveUserFieldData: PropTypes.func,
  user: PropTypes.object,
  calculatePercentage: PropTypes.func,
  setSectionFromNextButtons: PropTypes.func,
};

const CountryFieldSelect = ({
                              value,
                              onChange,
                              options,
                              required,
                              fullWidth,
                            }) => (
  <div>
    <Select
      id="country"
      fullWidth={fullWidth}
      searchable
      defaultValue={value}
      options={options}
      onChange={onChange}
      expandable={!!options.length}
      placeholder="Select Country"
      required={required}
    />
  </div>
);

export default TheBasics;
