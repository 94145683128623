// left section elements
const basicsElements = [
  { value: "Name", key: 1, state: "firstName" },
  { value: "Email", key: 2, state: "email" },
  { value: "Gender", key: 4, state: "gender" },
  { value: "Interest", key: 5, state: "interestedIn" },
  { value: "Birthdate", key: 6, state: "year" },
  { value: "City & zipcode", key: 7, state: "zip" },
  { value: "Relationship status", key: 8, state: "relationshipStatus" },
  { value: "Religion", key: 9, state: "religionPracticed" },
  { value: "Ethnicity", key: 10, state: "ethnicity" },
  { value: "Height", key: 10, state: "inches" },
  { value: "Kids", key: 11, state: "hasKids" },
  { value: "Anything Else", key: 12, state: "anythingElse" },
];

const moreAboutYouElements = [
  { value: "Education Level", key: 1, state: "education" },
  { value: "Schools Attended", key: 2, state: "schoolAttended" },
  { value: "Body type", key: 3, state: "bodyType" },
  { value: "Drinking", key: 4, state: "drinking" },
  { value: "Smoking", key: 5, state: "smoking" },
  { value: "Recreational drugs", key: 6, state: "drugs" },
  { value: "Political Orientation", key: 7, state: "politicalOrientation" },
  { value: "Upbringing", key: 8, state: "upbringing" },
  { value: "Match area", key: 9, state: "matchArea" },
  { value: "Relocation option", key: 10, state: "relocationOptions" },
  { value: "Hobbies", key: 11, state: "hobbies" },
  { value: "Eating Habits", key: 12, state: "eatingHabit" },
  { value: "Pets", key: 13, state: "pets" },
  { value: "Workout", key: 14, state: "workout" },
  { value: "Qualities", key: 15, state: "qualities" },
  { value: "Financial Stability", key: 16, state: "financialStability" },
];

const yourPartnersElements = [
  { value: "Relationship interest", key: 1, state: "relationshipInterest" },
  { value: "Partner preferences", key: 2, state: "partnerPreferences" },
  { value: "Religion", key: 3, state: "religionPreference" },
  { value: "Political", key: 4, state: "politicalPreference" },
  { value: "Ethnicity", key: 5, state: "ethnicityRequired" },
];

export const bigBlockElements = [
  {
    value: "The Basics",
    key: 1,
    elements: basicsElements,
    state: "theBasics",
  },
  {
    value: "More About You",
    key: 2,
    elements: moreAboutYouElements,
    state: "moreAbout",
  },
  {
    value: "Your Partner(s)",
    key: 3,
    state: "yourPartners",
    elements: yourPartnersElements,
  },
  {
    value: "Photos",
    key: 4,
    state: "photos",
    elements: [{ value: "Add Photos", key: 1, state: "addedPhotos" }],
  },
];

// THE BASICS SECTION ELEMENTS

export const GENDERS = [
  { value: "Female", label: "Female", id: 1 },
  { value: "Male", label: "Male", id: 2 },
  // { value: 'Transgender', label: 'Transgender', id: 3 },
  { value: "Other", label: "Other", id: 4 },
];

export const INTERESTS = [
  { value: "Men", label: "Men", id: 1 },
  { value: "Women", label: "Women", id: 2 },
  { value: "Other", label: "Other", id: 3 },
];

export const MARRIAGE_STATUSES = [
  { value: "Never married", label: "Never married", id: 1 },
  { value: "Divorced", label: "Divorced", id: 2 },
  { value: "Separated", label: "Separated", id: 5 },
  { value: "Widowed", label: "Widowed", id: 7 },
  { value: "Other", label: "Other", id: 8 },
];

export const RELATIONSHIP_TYPE = [
  { value: "Monogamous", label: "Long term monogamous", id: 1 },
  { value: "Casual", label: "Casual for now", id: 2 },
  { value: "Non-Monogamous", label: "Ethically non-monogamous", id: 4 },
  { value: "Other", label: "Other", id: 3 },
];

export const RELIGION = [
  { value: "Agnostic", label: "Agnostic", id: 1 },
  { value: "Atheist", label: "Atheist", id: 2 },
  { value: "Buddhist", label: "Buddhist", id: 3 },
  { value: "Catholic", label: "Catholic", id: 4 },
  { value: "Christian", label: "Christian", id: 5 },
  { value: "Jewish", label: "Jewish", id: 6 },
  { value: "Hindu", label: "Hindu", id: 7 },
  { value: "Latter-day Saints", label: "Latter-day Saints", id: 8 },
  { value: "Muslim", label: "Muslim", id: 9 },
  { value: "Sikh", label: "Sikh", id: 10 },
  {
    value: "Spiritual but not religious",
    label: "Spiritual but not religious",
    id: 11,
  },
  { value: "Other", label: "Other", id: 12 },
];

export const ETHNICITY = [
  { value: "Asian/Pacific Islander", label: "Asian/Pacific Islander", id: 2 },
  {
    value: "Black or African-American",
    label: "Black or African-American",
    id: 1,
  },
  { value: "Caucasian", label: "Caucasian", id: 3 },
  { value: "Hispanic/Latin", label: "Hispanic/Latin", id: 4 },
  { value: "Indian", label: "Indian", id: 5 },
  { value: "Middle Eastern", label: "Middle Eastern", id: 6 },
  { value: "Native American", label: "Native American", id: 8 },
  { value: "Prefer not to say", label: "Prefer not to say", id: 7 },
];

export const HAS_KIDS = [
  { value: "Yes", label: "Yes", id: 1 },
  {
    value: "No",
    label: "No",
    id: 2,
  },
];

export const FUTURE_CHILD_SCENARIOS = [
  {
    value: "Having my own kids",
    label: "Having my own kids",
    id: 1,
  },
  {
    value: "A partner's kids if they want/are open to more biologically",
    label: "A partner's kids if they want/are open to more biologically",
    id: 3,
  },
  {
    value: "A partner's kids if they don't want more biologically",
    label: "A partner's kids if they don't want more biologically",
    id: 5,
  },
  // {
  //     value: "I don't want kids/more kids",
  //     label: "I don't want kids/more kids",
  //     id: 4,
  // },
  {
    value: "Adopting/fostering",
    label: "Adopting/fostering",
    id: 2,
  },
  {
    value: "No kids/no more kids",
    label: "No kids/no more kids",
    id: 4,
  },
];

export const FUTURE_CHILD_SCENARIOS_1 = [
  {
    value: "Having more of my own kids",
    label: "Having more of my own kids",
    id: 1,
  },
  {
    value: "A partner's kids if they want/are open to more biologically",
    label: "A partner's kids if they want/are open to more biologically",
    id: 3,
  },
  {
    value: "A partner's kids if they don't want more biologically",
    label: "A partner's kids if they don't want more biologically",
    id: 5,
  },
  {
    value: "Adopting/fostering",
    label: "Adopting/fostering",
    id: 2,
  },
  // { value: "I don't want kids/more kids", label: "I don't want kids/more kids", id: 4 },
  { value: "No kids/no more kids", label: "No kids/no more kids", id: 4 },
];
// MORE ABOUT SECTION ELEMENTS

export const LEVEL_OF_EDUCATION = [
  { value: "Less than high school", label: "Less than high school", id: 1 },
  { value: "High school diploma", label: "High school diploma", id: 2 },
  {
    value: "Some college, no degree",
    label: "Some college, no degree",
    id: 3,
  },
  { value: "Associates degree", label: "Associates degree", id: 4 },
  { value: "Bachelors degree", label: "Bachelors degree", id: 5 },
  { value: "Masters degree", label: "Masters degree", id: 6 },
  { value: "Doctoral degree", label: "Doctoral degree", id: 7 },
];

export const EMPLOYMENT_STATUSES = [
  { value: "employee_contractor_temp", label: "Employee/contractor/temp" },
  { value: "self_employed", label: "Self-employed" },
  {
    value: "unemployed_in_between_jobs",
    label: "Unemployed (in between jobs)",
  },
  { value: "retired", label: "Retired" },
];

export const BODY_TYPE = [
  { value: "Thin", label: "Thin", id: 1 },
  { value: "Muscular/Athletic", label: "Muscular/Athletic", id: 2 },
  { value: "Average", label: "Average", id: 3 },
  { value: "Curvy/Well-endowed", label: "Curvy/Well-endowed", id: 4 },
  { value: "A few extra pounds", label: "A few extra pounds", id: 5 },
  {
    value: "More than a few extra pounds",
    label: "More than a few extra pounds",
    id: 6,
  },
];

export const DRINKING_TYPE = [
  { value: "Never", label: "Never", id: 1 },
  {
    value: "A few times a year",
    label: "A few times a year",
    id: 2,
  },
  { value: "2-3 times a month", label: "2-3 times a month", id: 3 },
  { value: "1-3 times a week", label: "1-3 times a week", id: 4 },
  { value: "4-6 times a week", label: "4-6 times a week", id: 5 },
  { value: "Every day", label: "Every day", id: 6 },
];

export const SMOKING_TYPE = [
  { value: "Never", label: "Never", id: 1 },
  { value: "Socially", label: "Socially", id: 2 },
  { value: "Often", label: "Often", id: 3 },
];

export const DRUGS_TYPE = [
  {
    value: "Not tolerated in a partner",
    label: "Not tolerated in a partner",
    id: 1,
  },
  {
    value: "Fine if a partner does pot occasionally",
    label: "Fine if a partner does pot occasionally",
    id: 2,
  },
  {
    value: "Fine if a partner does any drugs occasionally",
    label: "Fine if a partner does any drugs occasionally",
    id: 3,
  },
  {
    value: "Great if a partner does drugs",
    label: "Great if a partner does drugs",
    id: 4,
  },
  { value: "Other", label: "Other", id: 5 },
];

// export const DO_DRUGS = [{ value: 'Yes', label: 'Yes', id: 1 }, { value: 'No', label: 'No', id: 2 }, { value: 'Prefer not to say', label: 'Prefer not to say', id: 3 }];
export const DO_DRUGS = [
  {
    value: "Never",
    label: "Never",
    id: 4,
  },
  {
    value: "Special Occasions",
    label: "Special Occasions",
    id: 1,
  },
  {
    value: "Once a month to once a week",
    label: "Once a month to once a week",
    id: 2,
  },
  {
    value: "Multiple times a week",
    label: "Multiple times a week",
    id: 3,
  },
];

export const DRUGS = [
  {
    value: "Marijuana/Cannabis",
    label: "Marijuana/Cannabis",
    id: 1,
  },
  {
    value: "Psychedelics",
    label: "Psychedelics",
    id: 2,
  },
  {
    value: "Cocaine",
    label: "Cocaine",
    id: 3,
  },
  {
    value: "MDMA",
    label: "MDMA",
    id: 4,
  },
  {
    value: "Prescription",
    label: "Prescription",
    id: 5,
  },
  {
    value: "Other",
    label: "Other",
    id: 6,
  },
  {
    value: "Prefer not to say now",
    label: "Prefer not to say now",
    id: 7,
  },
];

export const DRUGS_FREQUENCY = [
  {
    name: "marijuana",
    label: "Cannabis",
    options: [
      {
        value: "Never",
        label: "Never",
        id: 4,
      },
      {
        value: "Special Occasions",
        label: "Special Occasions",
        id: 1,
      },
      {
        value: "Once a month to once a week",
        label: "Once a month to once a week",
        id: 2,
      },
      {
        value: "Multiple times a week",
        label: "Multiple times a week",
        id: 3,
      },
    ],
  },
  {
    name: "psychedelics",
    label: "Psychedelics",
    options: [
      {
        value: "Never",
        label: "Never",
        id: 4,
      },
      {
        value: "Special Occasions",
        label: "Special Occasions",
        id: 1,
      },
      {
        value: "Once a month to once a week",
        label: "Once a month to once a week",
        id: 2,
      },
      {
        value: "Multiple times a week",
        label: "Multiple times a week",
        id: 3,
      },
    ],
  },
  {
    name: "cocaine",
    label: "Cocaine",
    options: [
      {
        value: "Never",
        label: "Never",
        id: 4,
      },
      {
        value: "Special Occasions",
        label: "Special Occasions",
        id: 1,
      },
      {
        value: "Once a month to once a week",
        label: "Once a month to once a week",
        id: 2,
      },
      {
        value: "Multiple times a week",
        label: "Multiple times a week",
        id: 3,
      },
    ],
  },
  {
    name: "mdma",
    label: "MDMA",
    options: [
      {
        value: "Never",
        label: "Never",
        id: 4,
      },
      {
        value: "Special Occasions",
        label: "Special Occasions",
        id: 1,
      },
      {
        value: "Once a month to once a week",
        label: "Once a month to once a week",
        id: 2,
      },
      {
        value: "Multiple times a week",
        label: "Multiple times a week",
        id: 3,
      },
    ],
  },
  {
    name: "ketamine",
    label: "Ketamine",
    options: [
      {
        value: "Never",
        label: "Never",
        id: 4,
      },
      {
        value: "Special Occasions",
        label: "Special Occasions",
        id: 1,
      },
      {
        value: "Once a month to once a week",
        label: "Once a month to once a week",
        id: 2,
      },
      {
        value: "Multiple times a week",
        label: "Multiple times a week",
        id: 3,
      },
    ],
  },
];

export const DRUGS_FREQUENCY_OTHER = [
  {
    name: "other",
    label: "Other",
    options: [
      {
        value: "Special Occasions",
        label: "Special Occasions",
        id: 1,
      },
      {
        value: "Once a month to once a week",
        label: "Once a month to once a week",
        id: 2,
      },
      {
        value: "Multiple times a week",
        label: "Multiple times a week",
        id: 3,
      },
      {
        value: "Never",
        label: "Never",
        id: 4,
      },
      {
        value: "Prefer not to say",
        label: "Prefer not to say",
        id: 5,
      },
    ],
  },
  {
    name: "nosay",
    label: "Prefer not to say",
    options: [
      {
        value: "Special Occasions",
        label: "Special Occasions",
        id: 1,
      },
      {
        value: "Once a month to once a week",
        label: "Once a month to once a week",
        id: 2,
      },
      {
        value: "Multiple times a week",
        label: "Multiple times a week",
        id: 3,
      },
      {
        value: "Never",
        label: "Never",
        id: 4,
      },
    ],
  },
  {
    name: "prescription",
    label: "Prescription",
    options: [
      {
        value: "Special Occasions",
        label: "Special Occasions",
        id: 1,
      },
      {
        value: "Once a month to once a week",
        label: "Once a month to once a week",
        id: 2,
      },
      {
        value: "Multiple times a week",
        label: "Multiple times a week",
        id: 3,
      },
      {
        value: "Never",
        label: "Never",
        id: 4,
      },
    ],
  },
];

export const TATTOOS = [
  { value: "No, and never would", label: "No, and never would" },
  { value: "No, but would consider one", label: "No, but would consider one" },
  {
    value: "Yes, one discreet (generally hidden)",
    label: "Yes, one discreet (generally hidden)",
  },
  {
    value: "Yes, more than one, or it's easily seen",
    label: "Yes, more than one, or it's easily seen",
  },
];

export const PARTNER_TATTOOS = [
  {
    value: "No, that type of person is not for me",
    label: "No, that type of person is not for me",
  },
  {
    value: "Maybe, if it's one that's discreet",
    label: "Maybe, if it's one that's discreet",
  },
  { value: "Sure, multiple can be fine", label: "Sure, multiple can be fine" },
  {
    value: "Definitely, they're cool and sexy",
    label: "Definitely, they're cool and sexy",
  },
];

export const POLITICAL_ORIENTATION = [
  { value: "Democrat", label: "Democrat", id: 1 },
  { value: "Republican", label: "Republican", id: 2 },
  {
    value: "Moderate",
    label: "Moderate",
    id: 3,
  },
  { value: "Independent", label: "Independent", id: 4 },
  { value: "Apolitical", label: "Apolitical", id: 5 },
];

export const RELOCATION_OPTIONS = [
  { value: "No", label: "No", id: 1 },
  { value: "Maybe", label: "Maybe", id: 2 },
  { value: "Yes, nearly anywhere", label: "Yes, nearly anywhere", id: 3 },
  { value: "Yes, major cities", label: "Yes, major cities", id: 4 },
  {
    value: "Yes, but I'm not a big city person",
    label: "Yes, but I'm not a big city person",
    id: 5,
  },
];

export const HOBBIES = [
  { value: "Art/art projects", label: "Art/art projects", id: 1 },
  { value: "Biking/cycling", label: "Biking/cycling", id: 2 },
  { value: "Cooking", label: "Cooking", id: 3 },
  { value: "Fishing", label: "Fishing", id: 5 },
  { value: "Gardening", label: "Gardening", id: 6 },
  { value: "Golf", label: "Golf", id: 7 },
  { value: "Gym/lifting weights", label: "Gym/lifting weights", id: 8 },
  { value: "Hiking", label: "Hiking", id: 9 },
  {
    value: "Hosting dinner parties",
    label: "Hosting dinner parties",
    id: 10,
  },
  { value: "Hunting", label: "Hunting", id: 11 },
  { value: "Movies", label: "Movies", id: 12 },
  { value: "Museums", label: "Museums", id: 13 },
  { value: "Reading", label: "Reading", id: 15 },
  {
    value: "Religious services/activities",
    label: "Religious services/activities",
    id: 16,
  },
  { value: "Running", label: "Running", id: 17 },
  { value: "Shopping", label: "Shopping", id: 18 },
  { value: "Skiing/snowboarding", label: "Skiing/snowboarding", id: 19 },
  { value: "Surfing", label: "Surfing", id: 20 },
  { value: "Swimming", label: "Swimming", id: 21 },
  { value: "Team Sports", label: "Team Sports", id: 22 },
  { value: "Tennis", label: "Tennis", id: 23 },
  { value: "Theatre/plays", label: "Theatre/plays", id: 24 },
  { value: "Traveling", label: "Traveling", id: 25 },
  { value: "Video games", label: "Video games", id: 26 },
  { value: "Volunteering", label: "Volunteering", id: 27 },
  { value: "Walking", label: "Walking", id: 28 },
  { value: "Watching sports", label: "Watching sports", id: 29 },
  { value: "Watching TV", label: "Watching TV", id: 30 },
  { value: "Other", label: "Other", id: 31 },
  { value: "Burning Man", label: "Burning Man", id: 32 },
  { value: "Camping", label: "Camping", id: 33 },
  { value: "Dancing", label: "Dancing", id: 34 },
  { value: "Meditation", label: "Meditation", id: 35 },
  { value: "Yoga", label: "Yoga", id: 36 },
  { value: "Aviation", label: "Aviation", id: 37 },
  { value: "Boating/sailing", label: "Boating/sailing", id: 38 },
  { value: "Concerts", label: "Concerts", id: 39 }, //music
  { value: "Music festivals", label: "Music festivals", id: 40 }, // music
  { value: "Symphony", label: "Symphony", id: 41 }, // music
  { value: "Singing", label: "Singing", id: 42 }, // music
  { value: "Playing an instrument", label: "Playing an instrument", id: 43 }, // music
  { value: "Photography", label: "Photography", id: 44 },
  { value: "Kayaking", label: "Kayaking", id: 45 },
  { value: "Paddleboarding", label: "Paddleboarding", id: 46 },
  { value: "Scuba diving", label: "Scuba diving", id: 47 },
  { value: "Wineries", label: "Wineries", id: 48 },
];

export const MUSIC = {
  activities: [
    { value: "Concerts", label: "Concerts", id: 1 },
    { value: "Music festivals", label: "Music festivals", id: 2 },
    { value: "Symphony", label: "Symphony", id: 3 },
    { value: "Singing", label: "Singing", id: 4 },
    {
      value: "Playing an instrument",
      label: "Playing an instrument",
      id: 5,
    },
    { value: "Other", label: "Other", id: 999 },
  ],
  genres: [
    { value: "Bluegrass", label: "Bluegrass", id: 1 },
    { value: "Blues", label: "Blues", id: 2 },
    { value: "Classical", label: "Classical", id: 3 },
    { value: "Country", label: "Country", id: 4 },
    { value: "Electronic", label: "Electronic", id: 5 },
    {
      value: "Folk/singer-songwriter",
      label: "Folk/singer-songwriter",
      id: 6,
    },
    {
      value: "International/world",
      label: "International/world",
      id: 7,
    },
    { value: "Jazz", label: "Jazz", id: 8 },
    { value: "New age", label: "New age", id: 9 },
    { value: "Pop", label: "Pop", id: 10 },
    { value: "R&B/soul", label: "R&B/soul", id: 11 },
    { value: "Rap/hip-hop", label: "Rap/hip-hop", id: 12 },
    { value: "Religious", label: "Religious", id: 13 },
    { value: "Rock", label: "Rock", id: 14 },
    // {value: 'Other', label: 'Other', id: 999},
  ],
};

export const TRAVEL_IMPORTANCE = [
  {
    value: "Very (I want to take 3+ trips per year) ",
    label: "Very (I want to take 3+ trips per year)",
    id: 1,
  },
  {
    value: "Somewhat (1-2 trips per year is great)",
    label: "Somewhat (1-2 trips per year is great)",
    id: 2,
  },
  {
    value: "Not very (It's not a desire or priority)",
    label: "Not very (It's not a desire or priority)",
    id: 3,
  },
];

export const TRAVEL_EXPERIENCE = [
  { value: "More than 30 countries", label: "More than 30 countries", id: 1 },
  { value: "15-29 countries", label: "15-29 countries", id: 2 },
  { value: "5-14 countries", label: "5-14 countries", id: 3 },
  { value: "1-4 countries", label: "1-4 countries", id: 4 },
  { value: "None", label: "None", id: 5 },
];

export const EATING_HABITS = [
  {
    value: "I eat pretty much everything",
    label: "I eat pretty much everything",
    id: 1,
  },
  {
    value: "I'm kind of a picky eater",
    label: "I'm kind of a picky eater",
    id: 2,
  },
  { value: "Very health-conscious", label: "Very health-conscious", id: 3 },
  { value: "Vegetarian", label: "Vegetarian", id: 4 },
  { value: "Vegan", label: "Vegan", id: 5 },
  { value: "Gluten free", label: "Gluten free", id: 6 },
  { value: "Other", label: "Other", id: 7 },
];

export const PETS = {
  dogs: [
    { value: "Have Them", label: "Have Them", id: 1 },
    {
      value: "Don't have them but like them",
      label: "Don't have them but like them",
      id: 2,
    },
    {
      value: "Don't have them and don't like them",
      label: "Don't have them and don't like them",
      id: 3,
    },
  ],
  cats: [
    { value: "Have Them", label: "Have Them", id: 1 },
    {
      value: "Don't have them but like them",
      label: "Don't have them but like them",
      id: 2,
    },
    {
      value: "Don't have them and don't like them",
      label: "Don't have them and don't like them",
      id: 3,
    },
  ],
};

export const WORKOUT = [
  { value: "Daily", label: "Daily", id: 1 },
  { value: "Most days per week", label: "Most days per week", id: 2 },
  { value: "2-3 days per week", label: "2-3 days per week", id: 3 },
  { value: "Once a week", label: "Once a week", id: 4 },
  {
    value: "Less than once a week but I want to do it more",
    label: "Less than once a week but I want to do it more",
    id: 5,
  },
  {
    value: "Less than once a week and I don't want to do more",
    label: "Less than once a week and I don't want to do more",
    id: 6,
  },
];

export const QUALITIES = [
  { value: "Adaptable", label: "Adaptable", id: 1 },
  { value: "Adventurous", label: "Adventurous", id: 2 },
  { value: "Athletic", label: "Athletic", id: 3 },
  { value: "Attractive", label: "Attractive", id: 4 },
  { value: "Brave", label: "Brave", id: 5 },
  { value: "Confident", label: "Confident", id: 6 },
  { value: "Driven", label: "Driven", id: 7 },
  { value: "Easygoing", label: "Easygoing", id: 8 },
  { value: "Fun", label: "Fun", id: 9 },
  { value: "Funny", label: "Funny", id: 10 },
  { value: "Generous", label: "Generous", id: 11 },
  { value: "Humble", label: "Humble", id: 12 },
  {
    value: "Intellectually curious",
    label: "Intellectually curious",
    id: 13,
  },
  { value: "Intelligent", label: "Intelligent", id: 14 },
  { value: "Interesting", label: "Interesting", id: 15 },
  { value: "Kind/considerate", label: "Kind / considerate", id: 16 },
  { value: "Organized", label: "Organized", id: 17 },
  {
    value: "Passionate about what you do",
    label: "Passionate about what you do",
    id: 18,
  },
  { value: "Quirky", label: "Quirky", id: 19 },
  { value: "Reliable/stable", label: "Reliable / stable", id: 20 },
  { value: "Social", label: "Social", id: 21 },
  { value: "Spontaneous", label: "Spontaneous", id: 22 },
  { value: "Successful", label: "Successful", id: 23 },
  { value: "Well-traveled/worldly", label: "Well-traveled / worldly", id: 24 },
  { value: "Other", label: "Other", id: 25 },
  { value: "Outdoorsy", label: "Outdoorsy", id: 26 },
  { value: "Family-oriented", label: "Family-oriented", id: 27 },
  { value: "Growth-oriented", label: "Growth-oriented", id: 28 },
  {
    value: "An excellent communicator",
    label: "An excellent communicator",
    id: 29,
  },
  { value: "Empathetic", label: "Empathetic", id: 30 },
  { value: "Sophisticated", label: "Sophisticated", id: 31 },
  { value: "Happy", label: "Happy", id: 31 },
];

export const FINANCIAL_STABILITY = [
  { value: 1, label: "1", id: 1 },
  { value: 2, label: "2", id: 2 },
  { value: 3, label: "3", id: 3 },
  { value: 4, label: "4", id: 4 },
  { value: 5, label: "5", id: 5 },
];

export const YEARLY_INCOME = [
  { value: "0_59k", label: "$0-$59K" },
  { value: "60k_99k", label: "$60K-$99K" },
  { value: "100k_199k", label: "$100K-$199K" },
  { value: "200k_499k", label: "$200K-$499K" },
  { value: "500k_999k", label: "$500K-$999K" },
  { value: "1m+", label: "$1M+" },
];

export const NET_WORTH = [
  { value: "0_99k", label: "$0-$99K" },
  { value: "100k_499k", label: "$100K-$499K" },
  { value: "500k_999k", label: "$500K-$999K" },
  { value: "1m_4.9m", label: "$1M-$4.9M" },
  { value: "5m+", label: "$5M+" },
  { value: "dont_know", label: "Don't know" },
];

export const LAST_RELATIONSHIP = [
  { value: "Less than 3 months", label: "Less than 3 months", id: 1 },
  { value: "3-6 months", label: "3-6 months", id: 2 },
  { value: "7-11 months", label: "7-11 months", id: 3 },
  { value: "1-2 years", label: "1-2 years", id: 4 },
  { value: "2-5 years", label: "2-5 years", id: 5 },
  { value: "Over 5 years", label: "Over 5 years", id: 6 },
];

// YOUR PARTNER(S) SECTION ELEMENTS

export const RELIGION_PREFERENCE = [
  { value: 1, label: "1", id: 1 },
  { value: 2, label: "2", id: 2 },
  { value: 3, label: "3", id: 3 },
  { value: 4, label: "4", id: 4 },
  { value: 5, label: "5", id: 5 },
];

export const POLITICAL_PREFERENCE = [
  { value: 1, label: "1", id: 1 },
  { value: 2, label: "2", id: 2 },
  { value: 3, label: "3", id: 3 },
  { value: 4, label: "4", id: 4 },
  { value: 5, label: "5", id: 5 },
];

export const ETHNICITY_REQUIREMENTS = [
  { value: "All", label: "All", id: 1 },
  { value: "Asian/Pacific Islander", label: "Asian/Pacific Islander", id: 3 },
  {
    value: "Black or African-American",
    label: "Black or African-American",
    id: 2,
  },
  { value: "Caucasian", label: "Caucasian", id: 4 },
  { value: "Hispanic/Latin", label: "Hispanic/Latin", id: 5 },
  { value: "Indian", label: "Indian", id: 6 },
  { value: "Middle Eastern", label: "Middle Eastern", id: 7 },
  { value: "Native American", label: "Native American", id: 8 },
];

// export const ETHNICITY_REQUIREMENTS = [
//     { value: 'I prefer it', label: 'I prefer it', id: 1 },
//     { value: 'All equal to me', label: 'All equal to me', id: 2 },
//     { value: 'Somewhat open', label: 'Somewhat open', id: 3 },
//     { value: 'Not open', label: 'Not open', id: 4 }
// ];

export const HEIGHT_REQUIREMENTS = [
  { value: "choose", label: "choose", id: 1 },
  { value: "Open to all", label: "Open to all", id: 2 },
];

export const HEIGHT_ATTRIBUTES = [
  { value: "shorter", label: "shorter", id: 1 },
  { value: "taller", label: "taller", id: 2 },
];

// PHOTOS SECTION ELEMENTS

export const PHOTOS_TEXT = [
  "Variety is excellent - give people a visual snapshot of who you are.",
  "Matchmakers will select the photos they prefer to share with clients.",
  "Include at least one solo shot.",
  "Photos with family are great to include.",
];

// SETTINGS SECTION ELEMENTS

export const SETTINGS_TEXT = [
  "Marking yourself not single ensures that matchmakers don't reach out unnecessarily.",
  "If you're not single anymore, but not married/engaged, we'll wait at least 3 months before contacting you about any options.",
  'If you say you\'re married/engaged, we consider you "not single" and deactivate your account.',
  "If you're in a relationship that doesn't work out, you can return to reactivate your account.",
];

export const RELATIONSHIP_STATUSES = [
  { value: "Single", label: "Single" },
  {
    value: "Not Single",
    label: "Not Single",
    tooltip:
      "Choose your Account Status below so we know whether to check in with you later.",
  },
  { value: "Other", label: "Other" },
];

export const USER_WILLING_TO_PAY = [
  { value: false, label: "Get matched free", id: 1 },
  { value: true, label: "Contact a matchmaker", id: 2 },
];

export const CLIENT_STATUS = [
  { value: "candidate", label: "Candidate", color: "orange", id: 1 },
  // {value: 'potential', label: 'Potential', color: 'hotpink'},
  // { value: "freemium", label: "Freemium", color: "blue", id: 3 },
  { value: "client", label: "Client", color: "green", id: 2 },
  // {value: 'former-potential', label: 'Former Potential', color: 'blue'},
  // {value: 'former-client', label: 'Former Premium', color: 'brown'},
];

export const SINGLES_ROUTES = [
  { value: "client", label: "Client" },
  { value: "candidate", label: "Candidate" },
];

export const CLIENT_LEAD_STATUS = [
  { status: "Yes" },
  { status: "No" },
  { status: "Previously yes" },
];

// REQUIRED FIELDS

export const REQUIRED_FIELDS_PER_SECTION = {
  theBasics: {
    firstName: true,
    lastName: true,
    email: true,
    gender: true,
    interestedIn: true,
    year: true,
    relationshipStatus: true,
    city: true,
  },
  moreAbout: {
    education: true,
  },
  yourPartners: {
    relationshipInterest: true,
  },
  photos: {},
  account: {},
};

export const STATES = [
  { id: 1, label: "AK", value: "AK", text: "Alaska" },
  { id: 2, label: "AL", value: "AL", text: "Alabama" },
  { id: 3, label: "AR", value: "AR", text: "Arkansas" },
  { id: 4, label: "AS", value: "AS", text: "American Samoa" },
  { id: 5, label: "AZ", value: "AZ", text: "Arizona" },
  { id: 6, label: "CA", value: "CA", text: "California" },
  { id: 7, label: "CO", value: "CO", text: "Colorado" },
  { id: 8, label: "CT", value: "CT", text: "Connecticut" },
  { id: 9, label: "DC", value: "DC", text: "District of Columbia" },
  { id: 10, label: "DE", value: "DE", text: "Delaware" },
  { id: 11, label: "FL", value: "FL", text: "Florida" },
  { id: 12, label: "GA", value: "GA", text: "Georgia" },
  { id: 13, label: "GU", value: "GU", text: "Guam" },
  { id: 14, label: "HI", value: "HI", text: "Hawaii" },
  { id: 15, label: "IA", value: "IA", text: "Iowa" },
  { id: 16, label: "ID", value: "ID", text: "Idaho" },
  { id: 17, label: "IL", value: "IL", text: "Illinois" },
  { id: 18, label: "IN", value: "IN", text: "Indiana" },
  { id: 19, label: "KS", value: "KS", text: "Kansas" },
  { id: 20, label: "KY", value: "KY", text: "Kentucky" },
  { id: 21, label: "LA", value: "LA", text: "Louisiana" },
  { id: 22, label: "MA", value: "MA", text: "Massachusetts" },
  { id: 23, label: "MD", value: "MD", text: "Maryland" },
  { id: 24, label: "ME", value: "ME", text: "Maine" },
  { id: 25, label: "MI", value: "MI", text: "Michigan" },
  { id: 26, label: "MN", value: "MN", text: "Minnesota" },
  { id: 27, label: "MO", value: "MO", text: "Missouri" },
  { id: 28, label: "MS", value: "MS", text: "Mississippi" },
  { id: 29, label: "MT", value: "MT", text: "Montana" },
  { id: 30, label: "NC", value: "NC", text: "North Carolina" },
  { id: 31, label: "ND", value: "ND", text: "North Dakota" },
  { id: 32, label: "NE", value: "NE", text: "Nebraska" },
  { id: 33, label: "NH", value: "NH", text: "New Hampshire" },
  { id: 34, label: "NJ", value: "NJ", text: "New Jersey" },
  { id: 35, label: "NM", value: "NM", text: "New Mexico" },
  { id: 36, label: "NV", value: "NV", text: "Nevada" },
  { id: 37, label: "NY", value: "NY", text: "New York" },
  { id: 38, label: "OH", value: "OH", text: "Ohio" },
  { id: 39, label: "OK", value: "OK", text: "Oklahoma" },
  { id: 40, label: "OR", value: "OR", text: "Oregon" },
  { id: 41, label: "PA", value: "PA", text: "Pennsylvania" },
  { id: 42, label: "PR", value: "PR", text: "Puerto Rico" },
  { id: 43, label: "RI", value: "RI", text: "Rhode Island" },
  { id: 44, label: "SC", value: "SC", text: "South Carolina" },
  { id: 45, label: "SD", value: "SD", text: "South Dakota" },
  { id: 46, label: "TN", value: "TN", text: "Tennessee" },
  { id: 47, label: "TX", value: "TX", text: "Texas" },
  { id: 48, label: "UT", value: "UT", text: "Utah" },
  { id: 49, label: "VA", value: "VA", text: "Virginia" },
  { id: 50, label: "VI", value: "VI", text: "Virgin Islands" },
  { id: 51, label: "VT", value: "VT", text: "Vermont" },
  { id: 52, label: "WA", value: "WA", text: "Washington" },
  { id: 53, label: "WI", value: "WI", text: "Wisconsin" },
  { id: 54, label: "WV", value: "WV", text: "West Virginia" },
  { id: 55, label: "WY", value: "WY", text: "Wyoming" },
];

export const matchAreas = [
  { label: "International", value: "International", id: "International" },
  { label: "All U.S.", value: "All U.S.", id: "All U.S." }, // Hoa moved Dec 21
  // { label: 'Other U.S.', value: 'Other U.S.', id: 'Other U.S.'}, // Hoa removed Jan 11
  {
    label: "Atlanta",
    value: "5deaebc4770cec6bfb53fbcc",
    id: "5deaebc4770cec6bfb53fbcc",
  },
  {
    label: "Austin",
    value: "5deaf1ac770cec6bfb53fbe1",
    id: "5deaf1ac770cec6bfb53fbe1",
  },
  {
    label: "Boston",
    value: "5deaec39770cec6bfb53fbcd",
    id: "5deaec39770cec6bfb53fbcd",
  },
  {
    label: "Charlotte",
    value: "5deaee92770cec6bfb53fbd6",
    id: "5deaee92770cec6bfb53fbd6",
  },
  {
    label: "Chicago",
    value: "5deae6c6770cec6bfb53fbc6",
    id: "5deae6c6770cec6bfb53fbc6",
  },
  {
    label: "Cincinnati",
    value: "5deaf108770cec6bfb53fbdc",
    id: "5deaf108770cec6bfb53fbdc",
  },
  {
    label: "Cleveland",
    value: "5deaf16b770cec6bfb53fbdf",
    id: "5deaf16b770cec6bfb53fbdf",
  },
  {
    label: "Columbus",
    value: "5deaf193770cec6bfb53fbe0",
    id: "5deaf193770cec6bfb53fbe0",
  },
  {
    label: "D.C.",
    value: "5deaea8c770cec6bfb53fbc9",
    id: "5deaea8c770cec6bfb53fbc9",
  },
  {
    label: "Dallas / Ft. Worth",
    value: "5deae6e2770cec6bfb53fbc7",
    id: "5deae6e2770cec6bfb53fbc7",
  },
  {
    label: "Denver",
    value: "5deaee0b770cec6bfb53fbd4",
    id: "5deaee0b770cec6bfb53fbd4",
  },
  {
    label: "Detroit",
    value: "5deaed4d770cec6bfb53fbcf",
    id: "5deaed4d770cec6bfb53fbcf",
  },
  {
    label: "Houston",
    value: "5deaea29770cec6bfb53fbc8",
    id: "5deaea29770cec6bfb53fbc8",
  },
  {
    label: "Indianapolis",
    value: "5deaf1c4770cec6bfb53fbe2",
    id: "5deaf1c4770cec6bfb53fbe2",
  },
  {
    label: "Kansas City",
    value: "5deaf14d770cec6bfb53fbde",
    id: "5deaf14d770cec6bfb53fbde",
  },
  {
    label: "L.A.",
    value: "5deae6ad770cec6bfb53fbc5",
    id: "5deae6ad770cec6bfb53fbc5",
  },
  {
    label: "Las Vegas",
    value: "5deaf129770cec6bfb53fbdd",
    id: "5deaf129770cec6bfb53fbdd",
  },
  {
    label: "Miami",
    value: "5deaebac770cec6bfb53fbcb",
    id: "5deaebac770cec6bfb53fbcb",
  },
  {
    label: "Minneapolis",
    value: "5deaed92770cec6bfb53fbd1",
    id: "5deaed92770cec6bfb53fbd1",
  },
  {
    label: "NYC",
    value: "5deae540770cec6bfb53fbc4",
    id: "5deae540770cec6bfb53fbc4",
  },
  {
    label: "Orlando",
    value: "5deaeedf770cec6bfb53fbd8",
    id: "5deaeedf770cec6bfb53fbd8",
  },
  {
    label: "Philadelphia",
    value: "5deaeb71770cec6bfb53fbca",
    id: "5deaeb71770cec6bfb53fbca",
  },
  {
    label: "Phoenix",
    value: "5deaec65770cec6bfb53fbce",
    id: "5deaec65770cec6bfb53fbce",
  },
  {
    label: "Pittsburgh",
    value: "5deaef4f770cec6bfb53fbda",
    id: "5deaef4f770cec6bfb53fbda",
  },
  {
    label: "Portland",
    value: "5deaeebb770cec6bfb53fbd7",
    id: "5deaeebb770cec6bfb53fbd7",
  },
  {
    label: "SF Bay Area",
    value: "5deabb673d331e646bd5d04a",
    id: "5deabb673d331e646bd5d04a",
  },
  {
    label: "Sacramento",
    value: "5deaf0e3770cec6bfb53fbdb",
    id: "5deaf0e3770cec6bfb53fbdb",
  },
  {
    label: "San Antonio",
    value: "5deaeef9770cec6bfb53fbd9",
    id: "5deaeef9770cec6bfb53fbd9",
  },
  {
    label: "San Diego",
    value: "5deaedcb770cec6bfb53fbd2",
    id: "5deaedcb770cec6bfb53fbd2",
  },
  {
    label: "Seattle",
    value: "5deaed71770cec6bfb53fbd0",
    id: "5deaed71770cec6bfb53fbd0",
  },
  {
    label: "St. Louis",
    value: "5deaee25770cec6bfb53fbd5",
    id: "5deaee25770cec6bfb53fbd5",
  },
  {
    label: "Tampa",
    value: "5deaede3770cec6bfb53fbd3",
    id: "5deaede3770cec6bfb53fbd3",
  },
];

export const REGIONS = [
  { label: "United States", value: "All U.S." },
  { value: "5deabb673d331e646bd5d04a", label: "SF Bay Area" },
  { label: "NYC", value: "5deae540770cec6bfb53fbc4" },
  { label: "L.A.", value: "5deae6ad770cec6bfb53fbc5" },
  { label: "Chicago", value: "5deae6c6770cec6bfb53fbc6" },
  { label: "Dallas / Ft. Worth", value: "5deae6e2770cec6bfb53fbc7" },
  { label: "Houston", value: "5deaea29770cec6bfb53fbc8" },
  { label: "D.C.", value: "5deaea8c770cec6bfb53fbc9" },
  { label: "Philadelphia", value: "5deaeb71770cec6bfb53fbca" },
  { label: "Miami", value: "5deaebac770cec6bfb53fbcb" },
  { label: "Atlanta", value: "5deaebc4770cec6bfb53fbcc" },
  { label: "Boston", value: "5deaec39770cec6bfb53fbcd" },
  { label: "Phoenix", value: "5deaec65770cec6bfb53fbce" },
  { label: "Detroit", value: "5deaed4d770cec6bfb53fbcf" },
  { label: "Seattle", value: "5deaed71770cec6bfb53fbd0" },
  { label: "Minneapolis", value: "5deaed92770cec6bfb53fbd1" },
  { label: "San Diego", value: "5deaedcb770cec6bfb53fbd2" },
  { label: "Tampa", value: "5deaede3770cec6bfb53fbd3" },
  { label: "Denver", value: "5deaee0b770cec6bfb53fbd4" },
  { label: "St. Louis", value: "5deaee25770cec6bfb53fbd5" },
  { label: "Charlotte", value: "5deaee92770cec6bfb53fbd6" },
  { label: "Portland", value: "5deaeebb770cec6bfb53fbd7" },
  { label: "Orlando", value: "5deaeedf770cec6bfb53fbd8" },
  { label: "San Antonio", value: "5deaeef9770cec6bfb53fbd9" },
  { label: "Pittsburgh", value: "5deaef4f770cec6bfb53fbda" },
  { label: "Sacramento", value: "5deaf0e3770cec6bfb53fbdb" },
  { label: "Cincinnati", value: "5deaf108770cec6bfb53fbdc" },
  { label: "Las Vegas", value: "5deaf129770cec6bfb53fbdd" },
  { label: "Kansas City", value: "5deaf14d770cec6bfb53fbde" },
  { label: "Cleveland", value: "5deaf16b770cec6bfb53fbdf" },
  { label: "Columbus", value: "5deaf193770cec6bfb53fbe0" },
  { label: "Austin", value: "5deaf1ac770cec6bfb53fbe1" },
  { label: "Indianapolis", value: "5deaf1c4770cec6bfb53fbe2" },
  { label: "Alabama", value: "AL" },
  { label: "Alaska", value: "AK" },
  { label: "American Samoa", value: "AS" },
  { label: "Arizona", value: "AZ" },
  { label: "Arkansas", value: "AR" },
  { label: "California", value: "CA" },
  { label: "Colorado", value: "CO" },
  { label: "Connecticut", value: "CT" },
  { label: "Delaware", value: "DE" },
  { label: "District Of Columbia", value: "DC" },
  { label: "Federated States Of Micronesia", value: "FM" },
  { label: "Florida", value: "FL" },
  { label: "Georgia", value: "GA" },
  { label: "Guam", value: "GU" },
  { label: "Hawaii", value: "HI" },
  { label: "Idaho", value: "ID" },
  { label: "Illinois", value: "IL" },
  { label: "Indiana", value: "IN" },
  { label: "Iowa", value: "IA" },
  { label: "Kansas", value: "KS" },
  { label: "Kentucky", value: "KY" },
  { label: "Louisiana", value: "LA" },
  { label: "Maine", value: "ME" },
  { label: "Marshall Islands", value: "MH" },
  { label: "Maryland", value: "MD" },
  { label: "Massachusetts", value: "MA" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Mississippi", value: "MS" },
  { label: "Missouri", value: "MO" },
  { label: "Montana", value: "MT" },
  { label: "Nebraska", value: "NE" },
  { label: "Nevada", value: "NV" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "New Mexico", value: "NM" },
  { label: "New York", value: "NY" },
  { label: "North Carolina", value: "NC" },
  { label: "North Dakota", value: "ND" },
  { label: "Northern Mariana Islands", value: "MP" },
  { label: "Ohio", value: "OH" },
  { label: "Oklahoma", value: "OK" },
  { label: "Oregon", value: "OR" },
  { label: "Palau", value: "PW" },
  { label: "Pennsylvania", value: "PA" },
  { label: "Puerto Rico", value: "PR" },
  { label: "Rhode Island", value: "RI" },
  { label: "South Carolina", value: "SC" },
  { label: "South Dakota", value: "SD" },
  { label: "Tennessee", value: "TN" },
  { label: "Texas", value: "TX" },
  { label: "Utah", value: "UT" },
  { label: "Vermont", value: "VT" },
  { label: "Virgin Islands", value: "VI" },
  { label: "Virginia", value: "VA" },
  { label: "Washington", value: "WA" },
  { label: "West Virginia", value: "WV" },
  { label: "Wisconsin", value: "WI" },
  { label: "Wyoming", value: "WY" },
  { label: "International", value: "International" },
  { label: "Other U.S.", value: "Other U.S." },
];
