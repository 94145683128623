// Deps
import React, { useState } from "react";
import "./UserLogin.scss";
// Components
import { Button, FormInput } from "../widgets";
import { Link } from "../widgets/Link";
import { FacebookIcon } from "../icons";
import LaylaAndSteve from "../../images/Layla&Steve.jpg";
// Utils
import { post, get } from "../../utils/client";
import UserSession from "../../../_old/src/components/user-session";
import { setGlobal } from "../../../launchpad";
import { useFacebookLogin } from "../../hooks";
import { validateEmail, validatePassword } from "../../utils/validation";

const UserLogin = ({ history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [logInError, setLogInError] = useState("");
  const [errors, setErrors] = useState({});

  const loginCallback = (response) => {
    const { error, user, token } = response.data;
    if (!error) {
      UserSession.setToken(token, user, false);
      setGlobal({ activeUser: user });
      if (user.role === "user") {
        history.push("/dashboard?section=theBasics");
      } else {
        history.push("/dashboard");
      }
    }
  };

  const facebookLogin = useFacebookLogin(loginCallback);

  const loginUser = async () => {
    await post({
      url: "/login",
      data: { email, password },
      onSuccess: loginCallback,
      onError: (err) => {
        setLogInError(err.message);
        console.error(err);
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailError = validateEmail(email);
    const passwordError = validatePassword(password);

    if (emailError || passwordError) {
      setErrors({ email: emailError, password: passwordError });
    } else {
      setErrors({});
      await loginUser();
    }
  };

  return (
    <div className="user-login__container">
      <div className="user-login__left-side">
        <div className="user-login__header">
          <h1 className="user-login__title">Sign In</h1>
          <div className="user-login__divider" />
        </div>
        <form noValidate className="user-login__form" onSubmit={handleSubmit}>
          <FormInput
            value={email}
            onChange={setEmail}
            className="user-login__input"
            type="email"
            placeholder="Email"
            error={errors.email}
          />
          <FormInput
            value={password}
            onChange={setPassword}
            className="user-login__input"
            type="password"
            placeholder="Password"
            error={errors.password}
          />
          <Link className="user-login__forgot-password" to="/forgot-password">
            Forgot Password?
          </Link>
          <div className="user-login__buttons-container">
            <Button type="submit" className="user-login__button-mobile">
              Login
            </Button>
            <h2 className="user-login__subtitle">OR</h2>
            <Button
              className="user-login__button-mobile w-icon-button-mobile"
              icon={<FacebookIcon />}
              variant="facebook"
              onClick={facebookLogin}
            >
              Facebook Login
            </Button>
            {logInError && <span className="error-message">{logInError}</span>}
          </div>
          <h2 className="user-login__subtitle sign-up">
            Don't have an account?&nbsp;
            <Link to={"/welcome-singles"} style={{ fontSize: 16 }}>
              Sign up free.
            </Link>
          </h2>
        </form>
      </div>
      <div className="user-login__right-side">
        <figure className="user-login__image-container">
          <img
            src={LaylaAndSteve}
            alt="Layla and Steve"
            className="user-login__hero-image"
          />
          <span className="user-login__image-legend">Layla and Steve</span>
        </figure>
        <div className="user-login__background-color" />
      </div>
    </div>
  );
};

export default UserLogin;
