import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import moment from "moment";
import ErrorBoundary from "react-error-boundary";
import * as generalActions from "../../actions/generalActions";
import * as authActions from "../../actions/authentication";
import * as matchMakerActions from "../../actions/matchmakersActions";
import Button from "../../components/button/index";
import logo from "../../components/main-header/assets/date-spot-logo.svg";
import {
  bigBlockElements,
  GENDERS,
  INTERESTS,
  MARRIAGE_STATUSES,
  RELIGION,
  HAS_KIDS,
  LEVEL_OF_EDUCATION,
  BODY_TYPE,
  DRINKING_TYPE,
  SMOKING_TYPE,
  DRUGS_TYPE,
  POLITICAL_ORIENTATION,
  RELOCATION_OPTIONS,
  EATING_HABITS,
  HOBBIES,
  WORKOUT,
  QUALITIES,
  LAST_RELATIONSHIP,
  RELATIONSHIP_INTEREST,
  ETHNICITY_REQUIREMENTS,
  ETHNICITY,
  REQUIRED_FIELDS_PER_SECTION,
  RELIGION_PREFERENCE,
  FUTURE_CHILD_SCENARIOS,
  POLITICAL_PREFERENCE,
  STATES,
  HEIGHT_REQUIREMENTS,
} from "../../constants/onboarding-data-elements";
import {
  SECTIONS_ORDER,
  ORDERED_SECTION,
  ON_BOARDING_URLS_TO_TABS,
  TITLE_TABS,
} from "../../constants/singles-onboarding";
import UserSession from "../../components/user-session/index";
import InputText from "../../components/input-text";
import TextArea from "../../components/input-textarea";
import RadioList from "../../components/radio-list";
import RadioHorizontalList from "../../components/radio-list-horizontal";
import DropDown from "../../components/drop-down";
import CheckBoxList from "../../components/check-box-list/index";
import Validator from "../../components/utils/validator/index";
import LoadingModal from "../../components/loading-modal";
import {
  loadDataFromLocalStorage,
  saveDataToLocalStorage,
  removeDataFromLocalStorage,
} from "../../components/utils/local-storage";
import PhotoSection from "./photo-section/photo-section";
import { MONTHS, DAYS, generateYears } from "../../constants/index";
const YEARS = generateYears();
import PetsRadio from "../../components/pets-radio";
import HeightPreference from "../../components/height-requirements";
import StatisticalAreas from "../../components/statistical-areas";
import "./style.scss";

class BasicSingleInfo extends Component {
  constructor(props) {
    super(props);
    const { section } = props.history.location;
    const loggedUser = UserSession.getToken();
    const isEmptyLoggedUser =
      Object.entries(loggedUser).length === 0 &&
      loggedUser.constructor === Object;
    this.state = {
      currentSection: section || null,
      loggedUser: isEmptyLoggedUser ? null : loggedUser,
      errors: {},
      validFields: {},
      kids: {
        nrOfKids: null,
        kidsAges: {},
      },
    };
    const onBoardingData = loadDataFromLocalStorage("onBoardingData") || {
      theBasics: {},
      moreAbout: {},
      yourPartners: {},
      photos: {},
    };
    const activeUser = loadDataFromLocalStorage("activeUser") || null;
    if (!section) {
      this.props.generalActions.setLocalStorageDataOnReducer(
        onBoardingData,
        activeUser
      );
    }
  }

  componentDidMount() {
    const { currentSection } = this.state;
    document.body.classList.add("no-scroll");
    saveDataToLocalStorage("currentSection", currentSection);
    this.createTemporaryUserPassword();
  }

  componentWillUnmount() {
    document.body.classList.remove("no-scroll");
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { section } = nextProps.history.location;
    if (!section) {
      section = loadDataFromLocalStorage("currentSection");
      if (!section) {
        section = ON_BOARDING_URLS_TO_TABS[nextProps.history.location.pathname];
      }
      return { currentSection: section };
    }
    if (section !== prevState.currentSection) {
      return { currentSection: section };
    }
    return null;
  }

  handleSelectedDropDownValue = (selectedValue, name) => {
    const { currentSection, errors } = this.state;
    const errorKey = `${name}Error`;
    this.setState({ errors: { ...errors, [errorKey]: null } });
    this.setData(selectedValue, name, currentSection);
  };

  saveUserData = (currentSection) => {
    const { loggedUser, kids } = this.state;
    const { onBoardingData } = this.props.generalReducer;
    const {
      firstName,
      lastName,
      email,
      gender,
      interestedIn,
      month,
      day,
      year,
      city,
      relationshipStatus,
      feet,
      state,
      religionPracticed,
      inches,
      hasKids,
      password,
      ethnicity,
      futureChildScenarios,
    } = onBoardingData[currentSection];
    const birthDate = `${month.value}/${day.value}/${year.value}`;
    const formatDate = moment(birthDate, "MM-DD-YYYY");
    const age = parseInt(moment().diff(formatDate, "years"), 10);

    const height = feet && inches ? `${feet} ft ${inches} in` : null;

    const dataToSave = {
      firstName,
      lastName,
      email,
      gender,
      interestedIn,
      birthDate,
      city,
      relationshipStatus,
      age,
      ...(religionPracticed ? { religionPracticed } : null),
      height,
      state,
      ...(hasKids ? { hasKids } : null),
      kids: kids || {},
      role: "user",
      password,
      ...(ethnicity ? { ethnicity } : null),
      futureChildScenarios,
      ...(loggedUser ? { loggedUserId: loggedUser.id } : null),
    };
    const callback = (err, res) => {
      if (res && res.data.errors) {
        this.setState({ generalError: res.data.errors.generalError });
      }
      if (res && res.data) {
        const { user, token } = res.data;
        const savedUser = { id: user._id, name: user.name, role: user.role };
        this.props.generalActions.setSignedUser(savedUser);
        saveDataToLocalStorage("activeUser", savedUser);
      }
    };
    this.props.authActions.signUpUser(dataToSave, callback);
  };

  reduceObject = (dataToSave) => {
    const {
      day,
      month,
      year,
      feet,
      inches,
      ageFrom,
      ageTo,
      ...objWithoutTheseProperties
    } = dataToSave;
    return objWithoutTheseProperties;
  };

  patchUserData = (currentSection) => {
    const { onBoardingData } = this.props.generalReducer;
    const data = onBoardingData[currentSection];
    const { ageFrom, ageTo } = data;
    const userId = this.props.generalReducer.activeUser.id;
    let ageRange = null;
    if (ageTo && ageFrom) {
      if (parseInt(ageFrom, 10) > parseInt(ageTo, 10)) {
        ageRange = `${ageTo} to ${ageFrom}`;
      } else {
        ageRange = `${ageFrom} to ${ageTo}`;
      }
    }
    const tempDataToSave = { ...data, id: userId };
    const newObject = this.reduceObject(tempDataToSave);
    let dataToSave = { ...newObject, ageRange };
    if (currentSection === "theBasics") {
      const { month, day, year, feet, inches } =
        this.props.generalReducer.onBoardingData.theBasics;
      const birthDate = `${month.value}/${day.value}/${year.value}`;
      const formatDate = moment(birthDate, "MM-DD-YYYY");
      const age = parseInt(moment().diff(formatDate, "years"), 10);
      const height = feet && inches ? `${feet} ft ${inches} in` : null;
      const newObject = this.reduceObject(dataToSave);
      dataToSave = { ...newObject, height, age, birthDate };
    }

    const callback = (err, res) => {
      if (res && res.data.errors) {
        this.setState({ generalError: res.data.errors.generalError });
      }
    };
    this.props.authActions.updateUser(dataToSave, callback);
  };

  setData = (value, stateField, currentSection, error, validField) => {
    const { setOnBoardingData } = this.props.generalActions;
    const { errors, validFields } = this.state;
    if (error) {
      this.setState({
        errors: { ...errors, [error]: null },
        generalError: null,
        validFields: { ...validFields, [validField]: null },
      });
    }
    setOnBoardingData(currentSection, stateField, value);
  };

  render() {
    const { history } = this.props;
    const { currentSection } = this.state;
    const leftSection = this._renderLeftSection(currentSection);
    const rightSection = this._renderRightSection();
    const topButtons = this._renderNavButtons(history, currentSection);
    const { loadingModal } = this.props.generalReducer;

    return (
      <ErrorBoundary>
        <div
          className={loadingModal.show ? "loading-modal-opened" : ""}
          style={{ height: "calc(100% - 5rem)" }}
        >
          <div className="basic-single-info-container flex-column">
            <div className="basic-single-info-container__top flex-row">
              <img
                src={logo}
                onClick={() => history.push("/")}
                style={{ cursor: "pointer" }}
              />
              <div className="basic-info-title">
                {TITLE_TABS[currentSection]}
              </div>
              {topButtons}
            </div>
            <div className="flex-row basic-single-info-container__sections">
              <div className="left-section">{leftSection}</div>
              <div className="right-section">{rightSection}</div>
            </div>
          </div>
        </div>
        {loadingModal.show && (
          <LoadingModal
            error={loadingModal.error}
            loading={loadingModal.loading}
          />
        )}
      </ErrorBoundary>
    );
  }

  checkRequiredFields = (currentSection, dataToSaveToLocalStorage) => {
    const permissionsPerSection = REQUIRED_FIELDS_PER_SECTION[currentSection];
    let result = [];
    let tempErrors = {};
    Object.keys(permissionsPerSection).forEach((key) => {
      const hasError = this.state.errors[`${key}Error`];
      if (hasError || !dataToSaveToLocalStorage[currentSection][key]) {
        const stateErrorField = `${key}Error`;
        tempErrors = {
          ...tempErrors,
          [stateErrorField]: hasError || "Required",
        };
        this.setState({ generalError: "Some fields require your attention" });
        result = [...result, key];
      }
    });
    this.setState({ errors: tempErrors });
    return result.length > 0;
  };

  buttonsAction = (
    history,
    direction,
    elem,
    prevSection,
    nextSection,
    currentSection,
    activeUser,
    dataToSaveToLocalStorage,
    isLastPage
  ) => {
    const {
      removeOnBoardingData,
      reloadUsers,
      reCountUsers,
      sendUserWelcomeEmail,
    } = this.props.generalActions;
    const isBackButton = direction === "back";
    if (isBackButton) {
      saveDataToLocalStorage("currentSection", prevSection.section);
      history.push({
        pathname: prevSection.pathname,
        section: prevSection.section,
      });
    } else {
      const hasUnfilledFields = this.checkRequiredFields(
        currentSection,
        dataToSaveToLocalStorage
      );
      if (!hasUnfilledFields) {
        if (!isLastPage) {
          if (currentSection === "theBasics") {
            if (activeUser && activeUser.id) {
              this.patchUserData(currentSection);
            } else {
              this.saveUserData(currentSection);
            }
          } else {
            this.patchUserData(currentSection);
          }
          saveDataToLocalStorage("onBoardingData", dataToSaveToLocalStorage);
          !isLastPage &&
            saveDataToLocalStorage("currentSection", nextSection.section);
          history.push({
            pathname: nextSection.pathname,
            section: nextSection.section,
          });
        } else {
          history.push({ pathname: "/" });
          removeOnBoardingData();
          reloadUsers();
          reCountUsers();
          removeDataFromLocalStorage("onBoardingData");
          removeDataFromLocalStorage("activeUser");
          removeDataFromLocalStorage("currentSection");
          matchMakerActions.loadLoggedMMPlan();
          const { firstName, email, password } =
            this.props.generalReducer.onBoardingData.theBasics;
          sendUserWelcomeEmail({ firstName, email, password });
        }
      }
    }
    elem[0].scrollTo(0, 0);
  };

  _renderNavButtons = (history, currentSection) => {
    const nextSection = SECTIONS_ORDER[ORDERED_SECTION[currentSection] + 1];
    const prevSection = SECTIONS_ORDER[ORDERED_SECTION[currentSection] - 1];
    const isFirstPage = currentSection === "theBasics";
    const isLastPage = currentSection === "photos";
    const { activeUser } = this.props.generalReducer;
    const elem = document.getElementsByClassName("right-section");
    const dataToSaveToLocalStorage = this.props.generalReducer.onBoardingData;
    return (
      <div style={{ marginLeft: "auto" }}>
        {!isFirstPage && (
          <Button
            customClass="cyan"
            value="BACK"
            onEnterKeyPressed={() => {}}
            click={() =>
              this.buttonsAction(
                history,
                "back",
                elem,
                prevSection,
                nextSection,
                currentSection
              )
            }
          />
        )}
        <Button
          customClass="cyan-full"
          value={!isLastPage ? "NEXT" : "FINISH"}
          onEnterKeyPressed={() => {}}
          click={() =>
            this.buttonsAction(
              history,
              "forward",
              elem,
              prevSection,
              nextSection,
              currentSection,
              activeUser,
              dataToSaveToLocalStorage,
              isLastPage
            )
          }
        />
      </div>
    );
  };

  _renderLeftSection = (currentSection) => {
    const { onBoardingData } = this.props.generalReducer;
    return (
      <div className="flex-column" style={{ padding: "20px 0 0 40px" }}>
        {bigBlockElements.map((bb, b) => {
          let bbClass = "left-section__block flex-row";
          let bbNumberClass = "number flex-center";
          if (b === 0) {
            bbNumberClass += " first-child";
          }
          if (b === bigBlockElements.length - 1) {
            bbNumberClass += " last-child";
          }
          const isActiveSection = bb.state === currentSection;
          if (ORDERED_SECTION[bb.state] < ORDERED_SECTION[currentSection]) {
            bbClass += " is-completed-section";
          }
          if (isActiveSection) {
            bbClass += " is-completed-blue is-active-section";
          }
          return (
            <div key={b}>
              <div className={bbClass}>
                <span className={bbNumberClass}>{b + 1}</span>
                <span className="title">{bb.value}</span>
              </div>
              {bb.elements &&
                isActiveSection &&
                bb.elements.map((sb, k) => {
                  let sbClass = "left-section__block-small flex-row";
                  let sbNumberClass = "number flex-center";
                  if (k === 0) {
                    sbNumberClass += " first-child";
                  }
                  if (k === bb.elements.length - 1) {
                    sbNumberClass += " last-child";
                  }
                  if (onBoardingData[currentSection][sb.state]) {
                    sbClass += " is-completed-blue";
                  }
                  return (
                    <div className={sbClass} key={k}>
                      <span className={sbNumberClass} />
                      <span className="title">{sb.value}</span>
                    </div>
                  );
                })}
            </div>
          );
        })}
      </div>
    );
  };

  _renderRightSection = () => {
    const { currentSection, generalError } = this.state;
    const { activeUser, onBoardingData } = this.props.generalReducer;

    return (
      <div style={{ padding: "80px 0 0 80px" }}>
        {generalError && <div className="general-error">{generalError}</div>}

        {currentSection === "theBasics" && this._renderTheBasics()}

        {currentSection === "moreAbout" && this._renderMoreAboutYou()}

        {currentSection === "yourPartners" && this._renderYourPartners()}

        {currentSection === "photos" && (
          <PhotoSection
            isOldOnboarding
            setData={this.setData.bind(this)}
            userId={activeUser && activeUser.id}
            files={onBoardingData.photos.images}
            generalActions={generalActions}
          />
        )}
      </div>
    );
  };

  _renderTheBasics = () => {
    const { currentSection, loggedUser, errors, validFields } = this.state;
    const {
      firstName,
      lastName,
      email,
      city,
      gender,
      interestedIn,
      relationshipStatus,
      month,
      day,
      year,
      hasKids,
      futureChildScenarios,
      religionPracticed,
      inches,
      state,
      feet,
      ethnicity,
    } = this.props.generalReducer.onBoardingData[currentSection];
    const renderKidsInfo = this._renderKidsInfo();
    return (
      <div>
        <div className="flex-column" style={{ paddingBottom: "20px" }}>
          <div style={{ display: "flex", flexDirection: "flex-row" }}>
            <InputText
              wrapperClassName="flex-column margin-20"
              placeholder="First Name"
              label="First Name"
              showLabel={true}
              classNameForLabel={`${
                !firstName || errors.firstNameError
                  ? "show-label redish"
                  : "show-label greenish"
              }`}
              error={errors.firstNameError}
              isValid={firstName && validFields.firstNameValid}
              onBlur={(e) =>
                firstName &&
                this.checkForValidationErrors(
                  "name",
                  e.target.value,
                  "firstNameError",
                  "firstNameValid"
                )
              }
              onChange={(e) =>
                this.setData(
                  e.target.value,
                  "firstName",
                  currentSection,
                  "firstNameError",
                  "firstNameValid"
                )
              }
              value={firstName}
            />

            <InputText
              wrapperClassName="flex-column"
              placeholder="Last Name"
              label="Last Name"
              showLabel={true}
              classNameForLabel={`${
                !lastName || errors.lastNameError
                  ? "show-label redish"
                  : "show-label greenish"
              }`}
              error={errors.lastNameError}
              isValid={lastName && validFields.lastNameValid}
              onBlur={(e) =>
                lastName &&
                this.checkForValidationErrors(
                  "name",
                  e.target.value,
                  "lastNameError",
                  "lastNameValid"
                )
              }
              onChange={(e) =>
                this.setData(
                  e.target.value,
                  "lastName",
                  currentSection,
                  "lastNameError",
                  "lastNameValid"
                )
              }
              value={lastName}
              afterLabel="Last names are never shared with any other members"
            />
          </div>
        </div>
        <div className="flex-column" style={{ paddingBottom: "20px" }}>
          <InputText
            wrapperClassName="flex-column"
            placeholder="Email"
            label="Email"
            showLabel={true}
            classNameForLabel={`${
              !email || errors.emailError
                ? "show-label redish"
                : "show-label greenish"
            }`}
            error={errors.emailError}
            isValid={email && !errors.emailError}
            onChange={(e) =>
              this.setData(
                e.target.value,
                "email",
                currentSection,
                "emailError",
                "errorValid"
              )
            }
            onBlur={() => email && this.checkIfMailExists(email)}
            value={email}
          />
        </div>
        <RadioList
          list={GENDERS}
          title="Gender"
          selectedValue={gender}
          isRequiredField={true}
          error={errors.genderError}
          stateCallback={(val) =>
            this.setData(val, "gender", currentSection, "genderError")
          }
          returnInputValue={(val) =>
            this.setData(val, "gender", currentSection, "genderError")
          }
        />
        <CheckBoxList
          list={INTERESTS}
          title="Interested In"
          selectedValue={interestedIn}
          error={errors.interestedInError}
          isRequiredField={true}
          stateCallback={(val) =>
            this.setData(
              val,
              "interestedIn",
              currentSection,
              "interestedInError"
            )
          }
          returnInputValue={(val) =>
            this.setData(
              val,
              "interestedIn",
              currentSection,
              "interestedInError"
            )
          }
        />
        <div className="flex-column">
          <div
            className={`${!month || !day || !year ? "label" : "label valid"}`}
          >
            Birthdate
          </div>
          <div
            className={`flex-row date-input ${
              errors.yearError ? "has-error" : ""
            }
                        ${validFields.validDate ? "is-valid" : ""}`}
          >
            <div className="flex-row">
              <DropDown
                wrapperClassName="month-drop-down"
                listOfValues={MONTHS || []}
                error={errors.yearError}
                selectedValue={month}
                name="selectedMonth"
                placeholder="Month"
                onSelectValue={(val) =>
                  this.handleSelectedDropDownValue(val, "month")
                }
              />
              <DropDown
                wrapperClassName="day-drop-down"
                listOfValues={DAYS || []}
                error={errors.yearError}
                selectedValue={day}
                name="selectedDay"
                placeholder="Day"
                onSelectValue={(val) =>
                  this.handleSelectedDropDownValue(val, "day")
                }
              />
              <DropDown
                wrapperClassName="year-drop-down"
                listOfValues={YEARS || []}
                error={errors.yearError}
                selectedValue={year}
                name="selectedYear"
                placeholder="Year"
                onSelectValue={(val) =>
                  this.handleSelectedDropDownValue(val, "year")
                }
              />
            </div>
          </div>
        </div>
        <div className="flex-column" style={{ paddingBottom: "20px" }}>
          <div style={{ display: "flex", flexDirection: "flex-row" }}>
            <InputText
              wrapperClassName="flex-column margin-right"
              placeholder="City"
              label="City"
              showLabel={true}
              classNameForLabel={`${
                !city || errors.cityError
                  ? "show-label redish"
                  : "show-label greenish"
              }`}
              error={errors.cityError}
              isValid={city}
              onChange={(e) =>
                this.setData(
                  e.target.value,
                  "city",
                  currentSection,
                  "cityError",
                  "cityValid"
                )
              }
              value={city}
            />

            <div className="flex-column">
              <div className={`${state ? "label valid" : "label"}`}>State</div>
              <DropDown
                wrapperClassName="state-drop-down"
                listOfValues={STATES || []}
                selectedValue={state}
                name="selectedState"
                placeholder="Select..."
                label="State"
                onSelectValue={(val) =>
                  this.handleSelectedDropDownValue(val, "state")
                }
              />
            </div>
          </div>
        </div>
        <RadioList
          list={MARRIAGE_STATUSES}
          title="Relationship status"
          isRequiredField={true}
          error={errors.relationshipStatusError}
          selectedValue={relationshipStatus}
          stateCallback={(val) =>
            this.setData(
              val,
              "relationshipStatus",
              currentSection,
              "relationshipStatusError"
            )
          }
        />
        <RadioList
          list={RELIGION}
          selectedValue={religionPracticed}
          title={
            loggedUser
              ? "What does he or she identify with religiously?"
              : "What do you identify with religiously?"
          }
          stateCallback={(val) =>
            this.setData(val, "religionPracticed", currentSection)
          }
          returnInputValue={(val) =>
            this.setData(val, "religionPracticed", currentSection)
          }
        />
        <CheckBoxList
          list={ETHNICITY}
          title="Ethnicity"
          selectedValue={ethnicity}
          stateCallback={(val) =>
            this.setData(val, "ethnicity", currentSection)
          }
        />
        <div>
          <div className={`${!feet || !inches ? "label" : "label valid"}`}>
            Height
          </div>
          <div
            className={`flex-row height-input
                            ${
                              errors.feetError || errors.inchesError
                                ? " has-error"
                                : ""
                            }
                            ${
                              inches &&
                              feet &&
                              !(errors.feetError || errors.inchesError)
                                ? " is-valid"
                                : ""
                            }
                            `}
          >
            <InputText
              wrapperClassName="flex-column no-borders"
              placeholder="0"
              value={feet}
              maxLength="1"
              error={errors.feetError}
              onBlur={(e) =>
                feet &&
                this.checkForValidationErrors(
                  "number",
                  e.target.value,
                  "feetError"
                )
              }
              onChange={(e) =>
                this.setData(
                  e.target.value,
                  "feet",
                  currentSection,
                  "feetError",
                  "feetValid"
                )
              }
            />
            <span className="input-tag flex-center">ft</span>
            <InputText
              wrapperClassName="flex-column small no-borders"
              placeholder="00"
              maxLength="2"
              error={errors.inchesError}
              value={inches}
              onBlur={(e) =>
                inches &&
                this.checkForValidationErrors(
                  "number",
                  e.target.value,
                  "feetError"
                )
              }
              onChange={(e) =>
                this.setData(
                  e.target.value,
                  "inches",
                  currentSection,
                  "inchesError",
                  "inchesValid"
                )
              }
            />
            <span className="input-tag flex-center">in</span>
          </div>
        </div>
        <RadioList
          list={HAS_KIDS}
          title="Does the user have children?"
          selectedValue={hasKids}
          stateCallback={(val) => this.setData(val, "hasKids", currentSection)}
        />
        {renderKidsInfo}
        <CheckBoxList
          list={FUTURE_CHILD_SCENARIOS}
          title={"Future child scenarios you're open to"}
          selectedValue={futureChildScenarios}
          stateCallback={(val) =>
            this.setData(val, "futureChildScenarios", currentSection)
          }
        />
      </div>
    );
  };

  _renderKidsInfo = () => {
    const { currentSection, kids } = this.state;
    const { hasKids } =
      this.props.generalReducer.onBoardingData[currentSection];
    const { nrOfKids, kidsAges } = kids;
    const nrOfInputsGivenByNumberOfKids =
      nrOfKids && Array.from(new Array(parseInt(nrOfKids, 10)), (_, x) => x);
    if (hasKids && hasKids.label === "Yes") {
      return (
        <div className="kids-info">
          <div className={`${!nrOfKids ? "label" : "label valid"}`}>
            How many children does the member have and what are their ages?
          </div>
          <div
            className={`flex-row height-input kids ${
              nrOfKids ? " is-valid" : ""
            }`}
          >
            <InputText
              wrapperClassName="flex-column small no-borders"
              placeholder="0"
              value={nrOfKids}
              maxLength="2"
              type="number"
              onChange={(e) =>
                this.setState({ kids: { ...kids, nrOfKids: e.target.value } })
              }
            />
            <span className="input-tag flex-center">kid(s)</span>
          </div>
          {nrOfKids &&
            nrOfInputsGivenByNumberOfKids.map((kid, k) => {
              let numberLabel = "th";
              if (k === 0) {
                numberLabel = "st";
              }
              if (k === 1) {
                numberLabel = "nd";
              }
              if (k === 2) {
                numberLabel = "rd";
              }
              return (
                <div className="flex-row kids-ages" key={k}>
                  <span className="kid-label">
                    {" "}
                    {kid + 1}
                    {`${numberLabel} kid's age`}
                  </span>
                  <InputText
                    wrapperClassName="flex-column kids-age"
                    placeholder="00"
                    value={
                      kidsAges && Object.keys(kidsAges).length > 0
                        ? kidsAges[k]
                        : null
                    }
                    maxLength="2"
                    isValid={
                      kidsAges && Object.keys(kidsAges).length > 0
                        ? kidsAges[k]
                        : null
                    }
                    type="number"
                    onChange={(e) =>
                      this.setState({
                        kids: {
                          ...kids,
                          kidsAges: { ...kidsAges, [k]: e.target.value },
                        },
                      })
                    }
                  />
                </div>
              );
            })}
        </div>
      );
    }
  };

  _renderMoreAboutYou = () => {
    const { currentSection, errors, validFields } = this.state;
    const {
      education,
      schoolAttended,
      bodyType,
      drinking,
      smoking,
      drugs,
      politicalOrientation,
      upbringing,
      matchArea,
      matchAreaDetails,
      relocationOptions,
      hobbies,
      eatingHabit,
      workout,
      qualities,
      longestRelationship,
      occupation,
    } = this.props.generalReducer.onBoardingData[currentSection];
    console.log(education);
    return (
      <div>
        <RadioList
          list={LEVEL_OF_EDUCATION}
          title="Highest level of education"
          selectedValue={education}
          error={errors.educationError}
          isRequiredField={true}
          stateCallback={(val) =>
            this.setData(val, "education", currentSection, "educationError")
          }
        />
        {education.id >= 4 ? (
          <div className="flex-column" style={{ paddingBottom: "20px" }}>
            <InputText
              wrapperClassName="flex-column extra-length"
              label="Higher education school(s) attended"
              showLabel={true}
              classNameForLabel="show-label greenish"
              isValid={schoolAttended}
              onChange={(e) =>
                this.setData(
                  e.target.value,
                  "schoolAttended",
                  currentSection,
                  "schoolAttendedError"
                )
              }
              value={schoolAttended}
            />
          </div>
        ) : (
          <div></div>
        )}

        <div className="flex-column" style={{ paddingBottom: "20px" }}>
          <InputText
            wrapperClassName="flex-column extra-length"
            placeholder="Occupation"
            label="Member Occupation"
            showLabel={true}
            classNameForLabel={`${
              !occupation ? "show-label redish" : "show-label greenish"
            }`}
            isValid={occupation}
            onChange={(e) =>
              this.setData(
                e.target.value,
                "occupation",
                currentSection,
                "occupationError"
              )
            }
            value={occupation}
          />
        </div>

        <RadioList
          list={BODY_TYPE}
          title={`Member's body type?`}
          selectedValue={bodyType}
          stateCallback={(val) => this.setData(val, "bodyType", currentSection)}
        />

        <RadioList
          list={DRINKING_TYPE}
          title="How often does the member drink?"
          selectedValue={drinking}
          stateCallback={(val) => this.setData(val, "drinking", currentSection)}
        />

        <RadioList
          list={SMOKING_TYPE}
          title="How often does the member smoke cigarettes?"
          selectedValue={smoking}
          stateCallback={(val) => this.setData(val, "smoking", currentSection)}
        />
        <RadioList
          list={DRUGS_TYPE}
          title={`Member's thoughts on recreational drugs?`}
          error={errors.drugsError}
          selectedValue={drugs}
          returnInputValue={(val) =>
            this.setData(val, "drugs", currentSection, "drugsError")
          }
          stateCallback={(val) => this.setData(val, "drugs", currentSection)}
        />
        <RadioList
          list={POLITICAL_ORIENTATION}
          title="Member political orientation?"
          selectedValue={politicalOrientation}
          stateCallback={(val) =>
            this.setData(val, "politicalOrientation", currentSection)
          }
        />
        <div className="flex-column" style={{ paddingBottom: "20px" }}>
          <div className={`${upbringing ? "label valid" : "label"}`}>
            Where is the member from and how his upbringing shaped her or him?
          </div>
          <TextArea
            value={upbringing}
            onChange={(e) => {
              this.setData(e.target.value, "upbringing", currentSection);
              this.setState({
                validFields: { ...validFields, validUpbringing: false },
              });
            }}
            isValid={validFields.validUpbringing}
            onBlur={() =>
              this.setState({
                validFields: { ...validFields, validUpbringing: true },
              })
            }
          />
        </div>
        <div className="flex-column" style={{ paddingBottom: "40px" }}>
          <StatisticalAreas
            userId={null}
            saveUserFieldData={(v, f, c) => this.setData(v, f, c)}
            isOnBoarding={true}
            statisticalAreas={matchArea || []}
            matchAreaDetails={matchAreaDetails}
          />
        </div>
        <RadioList
          list={RELOCATION_OPTIONS}
          title="Is the member open to moving outside of metro area for a partner?"
          selectedValue={relocationOptions}
          stateCallback={(val) =>
            this.setData(val, "relocationOptions", currentSection)
          }
        />
        <CheckBoxList
          list={HOBBIES}
          title="What does the member like to do for fun?"
          selectedValue={hobbies}
          extraClass="half-size-list"
          returnInputValue={(val) =>
            this.setData(val, "hobbies", currentSection, "hobbiesError")
          }
          stateCallback={(val) => this.setData(val, "hobbies", currentSection)}
        />
        <RadioList
          list={EATING_HABITS}
          title={`What are the member's eating habits?`}
          selectedValue={eatingHabit}
          error={errors.eatingHabitError}
          returnInputValue={(val) =>
            this.setData(val, "eatingHabit", currentSection, "eatingHabitError")
          }
          stateCallback={(val) =>
            this.setData(val, "eatingHabit", currentSection)
          }
        />
        <PetsRadio
          saveUserFieldData={(field, value) =>
            this.setData(value, field, currentSection)
          }
        />
        <RadioList
          list={WORKOUT}
          title="How often does the member work out?"
          selectedValue={workout}
          stateCallback={(val) => this.setData(val, "workout", currentSection)}
        />
        <CheckBoxList
          list={QUALITIES}
          title="Would people say you are especially...?"
          extraClass="qualities-class"
          selectedValue={qualities}
          stateCallback={(val) =>
            this.setData(val, "qualities", currentSection)
          }
        />
        <RadioList
          list={LAST_RELATIONSHIP}
          title="How  long was your longest relationship?"
          selectedValue={longestRelationship}
          stateCallback={(val) =>
            this.setData(val, "longestRelationship", currentSection)
          }
        />
      </div>
    );
  };

  _renderYourPartners = () => {
    const { currentSection, errors, validFields } = this.state;
    const {
      relationshipInterest,
      partnerPreferences,
      religionPreference,
      politicalPreference,
      ethnicityRequired,
    } = this.props.generalReducer.onBoardingData[currentSection];
    const renderAgeRange = this._renderAgeRange();
    const renderHeightRequirements = this._renderHeightRequirements();
    return (
      <div>
        <CheckBoxList
          list={RELATIONSHIP_INTEREST}
          title="Current Desired Relationship"
          isRequiredField={true}
          error={errors.relationshipInterestError}
          selectedValue={relationshipInterest}
          returnInputValue={(val) =>
            this.setData(
              val,
              "relationshipInterest",
              currentSection,
              "relationshipInterestError"
            )
          }
          stateCallback={(val) =>
            this.setData(
              val,
              "relationshipInterest",
              currentSection,
              "relationshipInterestError"
            )
          }
        />
        <div className="flex-column" style={{ paddingBottom: "20px" }}>
          <div className={`${partnerPreferences ? "label valid" : "label"}`}>
            What is the member looking for in a partner?
          </div>
          <TextArea
            value={partnerPreferences}
            isValid={validFields.partnerPreferencesValid}
            afterLabel="Please specify between any preferences or criteria."
            onChange={(e) => {
              this.setData(
                e.target.value,
                "partnerPreferences",
                currentSection
              );
              this.setState({
                validFields: { ...validFields, partnerPreferencesValid: false },
              });
            }}
            onBlur={() =>
              this.setState({
                valdFields: { ...validFields, partnerPreferencesValid: true },
              })
            }
          />
        </div>
        <RadioHorizontalList
          list={RELIGION_PREFERENCE}
          selectedValue={religionPreference}
          title="How important is it to have a partner who is the same religiously?"
          stateCallback={(val) =>
            this.setData(val, "religionPreference", currentSection)
          }
          startLabel="Not important at all"
          endLabel="Very important"
        />
        <RadioHorizontalList
          list={POLITICAL_PREFERENCE}
          selectedValue={politicalPreference}
          title="How important is it to have a partner who is the same as you politically?"
          stateCallback={(val) =>
            this.setData(val, "politicalPreference", currentSection)
          }
          startLabel="Not important"
          endLabel="Very important"
        />
        <CheckBoxList
          list={ETHNICITY_REQUIREMENTS}
          title="What ethnicities is the member open to dating?"
          selectedValue={ethnicityRequired}
          stateCallback={(val) =>
            this.setData(val, "ethnicityRequired", currentSection)
          }
        />

        {renderHeightRequirements}
        {renderAgeRange}
      </div>
    );
  };

  _renderHeightRequirements = () => {
    const { currentSection } = this.state;
    const { heightPreference } =
      this.props.generalReducer.onBoardingData[currentSection];
    return (
      <HeightPreference
        list={HEIGHT_REQUIREMENTS}
        title="Do you have any requirements on height?"
        selectedValue={heightPreference}
        stateCallback={(val) =>
          this.setData(
            val,
            "heightPreference",
            currentSection,
            "heightPreferenceError"
          )
        }
      />
    );
  };

  _renderAgeRange = () => {
    const { errors, currentSection } = this.state;
    const { ageFrom, ageTo } =
      this.props.generalReducer.onBoardingData[currentSection];
    return (
      <div>
        <div className={`${!ageFrom || !ageTo ? "label" : "label valid"}`}>
          What age range would you consider?
        </div>
        <div
          className={`flex-row height-input age-range
                            ${
                              ageFrom && ageTo && !errors.ageRangeError
                                ? " is-valid"
                                : ""
                            }`}
        >
          <InputText
            wrapperClassName="flex-column no-borders"
            placeholder="18"
            value={ageFrom}
            maxLength="2"
            onBlur={(e) =>
              ageTo && this.processAgeRange(e.target.value, ageFrom)
            }
            onChange={(e) => {
              this.setData(e.target.value, "ageFrom", currentSection);
              this.setState({ errors: { ...errors, ageRangeError: null } });
            }}
          />
          <span className="input-tag flex-center">to</span>
          <InputText
            wrapperClassName="flex-column small no-borders"
            placeholder="99"
            value={ageTo}
            maxLength="2"
            onBlur={(e) =>
              ageTo &&
              this.processAgeRange(ageFrom, e.target.value, currentSection)
            }
            onChange={(e) => {
              this.setData(e.target.value, "ageTo", currentSection);
              this.setState({ errors: { ...errors, ageRangeError: null } });
            }}
          />
        </div>
        {errors.ageRangeError && (
          <span className="error-label">{errors.ageRangeError}</span>
        )}
      </div>
    );
  };

  processAgeRange = (ageFrom, ageTo) => {
    const { errors } = this.state;
    if (ageFrom && ageTo) {
      if (
        Validator.validateNumber(ageFrom) &&
        Validator.validateNumber(ageTo)
      ) {
        if (parseInt(ageFrom, 10) < 18 || parseInt(ageTo, 10) < 18) {
          this.setState({
            errors: { ...errors, ageRangeError: "Greater than 18" },
          });
        }
      } else {
        this.setState({ errors: { ...errors, ageRangeError: "Numbers only" } });
      }
    }
  };

  createTemporaryUserPassword = () => {
    const { currentSection } = this.state;
    const { password, passwordCheck } =
      this.props.generalReducer.onBoardingData["theBasics"];
    if (currentSection === "theBasics" && !password && !passwordCheck) {
      const tempPassWord = Math.random().toString(36).slice(-8);
      this.setData(tempPassWord, "password", currentSection, "passwordError");
      this.setData(
        tempPassWord,
        "passwordCheck",
        currentSection,
        "passwordError"
      );
    }
  };

  checkIfMailExists = (email) => {
    const { activeUser } = this.props.generalReducer;
    const { errors } = this.state;

    if (Validator.validateEmail(email)) {
      const callback = (err, res) => {
        if (res.data.emailAlreadyExists) {
          this.setState({
            errors: { ...errors, emailError: "This email already exists" },
          });
        } else {
          this.setState({ errors: { ...errors, emailError: null } });
        }
      };
      if (!activeUser) {
        this.props.authActions.verifyIfEmailExists(email, callback, "user");
      }

      this.setState({ errors: { ...errors, emailError: null } });
    } else {
      this.setState({
        errors: { ...errors, emailError: "E-mail is not valid" },
      });
    }
  };

  checkForValidationErrors = (type, value, stateError, validField) => {
    let valid;
    switch (type) {
      case "char":
        valid = Validator.validateCharacter(value);
        this.setErrorsOrValids(valid, validField, stateError, "Invalid");
        break;
      case "name":
        valid = Validator.validateName(value);
        this.setErrorsOrValids(valid, validField, stateError, "Invalid");
        break;
      case "number":
        valid = Validator.validateNumber(value);
        this.setErrorsOrValids(valid, validField, stateError, "Numbers Only");
        break;
      case "date":
        valid = Validator.validateAmericanDateFormat(value);
        this.setErrorsOrValids(valid, "validDate", stateError, "Invalid date");
        break;
      default:
        break;
    }
  };

  setErrorsOrValids = (valid, validField, stateError, message) => {
    const { validFields, errors } = this.state;
    valid
      ? this.setState({ validFields: { ...validFields, [validField]: true } })
      : this.setState({ errors: { ...errors, [stateError]: message } });
  };
}

BasicSingleInfo.propTypes = {
  history: PropTypes.object,
  generalActions: PropTypes.object,
  authActions: PropTypes.object,
  matchMakerActions: PropTypes.object,
  generalReducer: PropTypes.object,
  authenticationReducer: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    generalReducer: state.generalReducer,
    authenticationReducer: state.authenticationReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    generalActions: bindActionCreators(generalActions, dispatch),
    authActions: bindActionCreators(authActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BasicSingleInfo);
