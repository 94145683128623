@import "src/scss/variables";

.select-with-label {
  display: flex;

  &.horizontal {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__label {
    color: $textPrimary;
    font-size: 1rem;
  }
}
