@import "../../../../src/scss/variables";

.left-filters-container {
    font-family: Montserrat, sans-serif;
    padding: 0 19px 0 0;
    position: relative;

    &__add-user-matchmaker {
        box-shadow: 0 0 10px 0 rgba(84,88,90,0.2);
        width: 100%;
        .button {
            width: 100%;
        }
    }

    &__my-selected-filters {
        background-color: #FFF;
        border: 1px solid rgba(84,88,90,0.1);
        border-radius: 2px;
        box-shadow: 0 0 10px 0 rgba(84,88,90,0.2);
        margin: 0 0 19px 0;
        padding: 15px;

        > div:first-child {
            margin: 0 0 4px 0;
            position: relative;
            .title {
                color: $teal;
                font-family: Montserrat, sans-serif;
                font-size: 16px;
            }
            .icon-question-mark {
                margin-left: 15px;
                font-size: 14px;
                &:hover {
                    &:before {
                        color: #53585a;
                    }
                }
            }
        }

    }

    &__filters {
        background-color: #FFF;
        border: 1px solid rgba(84,88,90,0.1);
        border-radius: 2px;
        box-shadow: 0 0 10px 0 rgba(84,88,90,0.2);
        padding: 15px 0;
        .filters-section {
            margin: 0 0 4px 15px;
            color: $teal;
            font-family: Montserrat, sans-serif;
            font-size: 16px;
        }
        &.more-padding {
            padding-bottom: 75px;
        }

    }

    &__filter-list {
        .filter {
            color: #54585A;
            cursor: pointer;
            font-family: 'Montserrat', sans-serif;
            height: 40px;
            padding: 0 15px;
            span {
                font-size: 18px;
            }
            .plus {
                font-size: 30px;
                margin-bottom: 3px;
                visibility: hidden;
            }
            &:hover {
                background: #FFD758;
                .plus { visibility: visible}
            }
        }
    }

    &__save-current-filters {
        background-color: #fff;
        bottom: 0;
        box-shadow: 0 0 10px 0 rgba(84, 88, 90, 0.2);
        position: fixed;
        padding: 21px;
        left: 21px;
        width: 177px;
        .button {
            width: 100%;
            padding: 0;
            .value {
                font-size: 12px;
            }

        }
    }

    .input-range {
        margin-left: 10px;
        width: 230px;
        &__label {
            color: rgba(0,0,0,0.7);
        }
    }

    .filters-modal {
        &.modal-overlay:before {
            background-color: rgba(0,0,0,0.5);
            opacity: 1;
        }
        .modal {
            border: 1px solid rgba(84,88,90,0.1);
            border-radius: 2px;
            background-color: #fff;
            box-shadow: 0 0 10px 0 rgba(84,88,90,0.2);
            height: 365px;
            left: 47.5%;
            top: 30%;
            padding: 11px 21px 21px 21px;
            width: 622px;
            .modal-header {
                color: #54585A;
                font-family: Montserrat, sans-serif;
                font-size: 18px;
                font-weight: 500;
                padding: 10px 0 20px;
                border-bottom: 1px solid rgba(84,88,90,0.2);
            }
            .modal-content {
                padding: 10px 20px 0;
                height: 286px;
                overflow: auto;
                .check-box-container {
                    .input-checkbox {
                        height: 40px;
                    }
                    &.show-multiple {
                        .checkbox-list {
                            flex-direction: row;
                            flex-wrap: wrap;
                        }
                        .input-checkbox {
                            width: 49%;
                        }
                    }
                }
            }
        }
        &.gender .modal, &.interestedIn .modal, &.politicalOrientation .modal,
        &.ethnicityExpectations .modal, &.futureChildScenarios .modal {
            height: 275px;
            min-height: 275px;
        }
        &.smoking .modal {
            height: 235px;
            min-height: 235px;
        }
        &.drugs .modal, &.relocation .modal, &.desiredRelationship .modal, &.relationshipInterest .modal {
            height: 290px;
            min-height: 290px;
        }
        &.hasKids .modal {
            height: 190px;
            min-height: 190px;
            width: 300px;
        }
        &.financialStability .modal, &.religionPreference .modal, &.politicalPreference .modal {
            width: 270px;
            min-height: 280px;
            .modal-content {
                height: 265px;
                padding: 10px;
            }
        }
        &.age .modal {
            height: 275px;
            min-height: 275px;
            width: 362px;
            .modal-content {
                .input-range {
                    padding: 25px 0;
                    width: 280px;
                    margin: 0 auto;
                    &__track--background {
                        height: 10px;
                        width: 280px;
                        border-radius: 16px;
                        background-color: rgba(84,88,90,0.2);
                    }
                    &__track--active {
                        height: 10px;
                        background-color: $teal;
                    }
                    &__slider {
                        background-color: $teal;
                        border: 1px solid #53585a;
                        height: 20px;
                        top: -5px;
                        width: 20px;
                    }
                    &__label-container {
                        display: none;
                    }
                }
                .input-wrapper {
                    width: 130px;
                    &:first-child {
                        margin-right: 20px;
                    }
                }

                .text-right {
                  text-align: right;
                }

                button {
                  background-color: #FFD758;
                  // color: white;
                  border-radius: 5px;
                  margin-top: 20px;
                  padding: 7px 15px;
                }
            }
        }
    }

    .save-filters-modal {
        top: 30%;
        .modal {
            height: 250px;
            min-height: 250px;
            width: 562px;

            .save-filters {
                padding: 17px 31px 21px;
                text-align: center;
                &__title {
                    color: #54585A;
                    font-family: Montserrat, sans-serif;
                    font-size: 18px;
                    font-weight: 600;
                }
                .input-wrapper {
                    padding: 44px 0 40px 0;
                    width: 100%;
                }
                &__buttons {
                    .button {
                        padding: 0 10px;
                        width: 124px;
                        &:first-child {
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }


    @media screen and (max-width: 1200px) {
        &__save-current-filters {
            width: 217px;
            .button {
                padding: 0 10px;
            }
        }
    }
}
