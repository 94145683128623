import React, { Component } from "react";
import PropTypes from "prop-types";
import { MATCHMAKER_AGREEMENT } from "../../constants/terms-and-policies";
import { REPEAT_SECTION_TWO } from "../home-pages/texts";
import { repeatingSectionTwo } from "../home-pages/components";

import "./style.scss";

class MatchMakerAgreement extends Component {
  render() {
    const { history } = this.props;
    return (
      <div className="matchmaker-agreement flex-column">
        <span
          className="icon-back-button"
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/")}
        />
        {repeatingSectionTwo(
          REPEAT_SECTION_TWO.mmAgreementPageFirst.title,
          null,
          null,
          null,
          null,
          null
        )}
        <div className="flex-column">
          <div className="matchmaker-agreement__section">
            <span className="matchmaker-agreement__first-text">
              {MATCHMAKER_AGREEMENT.title}
            </span>
          </div>
          {MATCHMAKER_AGREEMENT.sections.map((sec, s) => {
            return (
              <div
                className="matchmaker-agreement__section flex-column"
                key={s}
              >
                <div className="matchmaker-agreement__section__title">
                  {sec.title}
                </div>
                <div className="matchmaker-agreement__section__text">
                  {sec.text}
                </div>
                {sec.list &&
                  sec.list.map((list, l) => {
                    return (
                      <div
                        className="matchmaker-agreement__section__list"
                        key={l}
                      >
                        <div
                          className="matchmaker-agreement__section__list__title flex-row"
                          style={{ alignItems: "flex-start" }}
                        >
                          <div style={{ minWidth: "250px" }}>{list.title}</div>
                          <div
                            className="flex-column"
                            style={{ marginLeft: "30px" }}
                          >
                            {list.elements.map((el, e) => {
                              return <span key={e}>{el}</span>;
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {sec.bulletList &&
                  sec.bulletList.map((bl, b) => {
                    return (
                      <div
                        className="matchmaker-agreement__section__text"
                        key={b}
                      >
                        {bl.text}
                      </div>
                    );
                  })}
              </div>
            );
          })}
          <div className="matchmaker-agreement__section">
            <div className="matchmaker-agreement__section__title">
              Partner Matchmaker Acceptance:
            </div>
            <div className="matchmaker-agreement__section__text">
              By checking the box online, the subscribing Partner Matchmaker
              acknowledges he/she has reviewed and accepts this Agreement
              including the
              <span
                className="terms-and-privacy"
                onClick={() => history.push("/terms-of-service")}
              >
                Terms of Service
              </span>{" "}
              and
              <span
                className="terms-and-privacy"
                onClick={() => history.push("/privacy-policy")}
              >
                Privacy Policy
              </span>
              incorporated herein by reference.
            </div>
          </div>

          <div className="flex-column" style={{ marginBottom: "50px" }}>
            <span className="matchmaker-agreement__section__text small">
              DateSpot, LLC
            </span>
            <span className="matchmaker-agreement__section__text small">
              130 Oakview Drive, San Carlos, CA 94070
            </span>
            <span className="matchmaker-agreement__section__text small">
              support@datespot.love
            </span>
          </div>
        </div>
      </div>
    );
  }
}

MatchMakerAgreement.propTypes = {
  history: PropTypes.object,
};

export default MatchMakerAgreement;
