import React from "react";
import { rateUser } from "./UserList";

export default function UserRating({ user }) {
  const [rate, showRate] = React.useState(false);
  const mmr = user.mmRating && user.mmRating.score;
  const ratelink = (
    <a onClick={() => showRate(true)}>{mmr ? "Change" : "Add"} rating</a>
  );
  return (
    <div className="rating-widget">
      {user.userRating && (
        <>
          <p style={{ marginBottom: "0px", marginTop: "0px" }}>
            <span className="muted">Rating average:</span> {user.userRating}
          </p>
          <p style={{ marginBottom: "0px", marginTop: "0px" }}>
            {mmr && <span className="muted">Your rating:</span>} {mmr} -{" "}
            {ratelink}
          </p>
        </>
      )}
      {!user.userRating && (
        <>
          {" "}
          <p
            style={{ marginBottom: "0px", marginTop: "0px" }}
            className="muted"
          >
            No ratings
          </p>{" "}
          <p>{ratelink}</p>{" "}
        </>
      )}
      {rate && (
        <RateWidget
          title="Your rating"
          selected={mmr}
          onSelect={(i) => {
            rateUser(user, i);
            showRate(false);
          }}
        />
      )}
    </div>
  );
}

// TODO: update this, clunky and depends on old css
const RateWidget = ({ title, onSelect, selected }) => {
  return (
    <div className="rating-tooltip flex-column">
      {title && <span className="rating-tooltip__title"> {title} </span>}
      <div className="rating-tooltip__buttons flex-row flex-center">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => {
          return (
            <div
              key={i}
              className={`rating-tooltip__button flex-center ${
                selected === i ? "selected" : ""
              }`}
              onClick={() => onSelect(i)}
            >
              {i}
            </div>
          );
        })}
      </div>
    </div>
  );
};
