import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./Select.scss";

const Select = ({
  defaultValue,
  options = [],
  onChange = () => {},
  className = "",
  id = "select",
  fullWidth = false,
  onDelete,
  searchable = false,
  disabled = false,
  expandable = true,
  placeholder = "",
}) => {
  const [value, setValue] = useState(defaultValue);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => document.removeEventListener("click", handleOutsideClick);
  }, []);

  const handleOutsideClick = (e) => {
    if (e.target.closest(`#${id}`)) return;
    setOpen(false);
  };

  const handleChange = (option) => {
    setOpen(false);
    setValue(option);
    onChange(option);
  };

  const handleSelectClick = (e) => {
    if (!expandable || disabled) return;
    if (
      e.target.closest(".select__list") ||
      e.target.closest(".select__icons__delete")
    )
      return;
    setOpen(!open);
  };

  const handleSearch = (e) => {
    e.stopPropagation();
    const { value } = e.target;

    setSearch(value);
    if (!value) return setFilteredOptions(options);
    setFilteredOptions(
      options.filter((option) =>
        option.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  let selectClass = "select";
  if (expandable) selectClass += " expandable";
  if (fullWidth) selectClass += " full-width";
  if (disabled) selectClass += " disabled";
  if (className) selectClass += ` ${className}`;

  return (
    <div id={id} className={selectClass} onClick={handleSelectClick}>
      {value ? (
        <span className="select__value">
          { 
            !isNaN(value) ?
              (value === 1 ? "1st" : value === 2 ? "2nd" : value === 3 ? "3rd" : value + "th") 
              : value
          }
        </span>
      ) : (
        <span className="select__value select__placeholder">{placeholder}</span>
      )}
      <div className="select__icons">
        {expandable ? (
          <i
              style={{ color: "#ddd" }}
            className={`fas fa-chevron-down select__icons__chevron${
              onDelete ? " padding" : ""
            }${open ? " fa-rotate-180" : ""}`}
          />
        ) : null}
        {onDelete ? (
          <i
            className="fas fa-times select__icons__delete"
            onClick={() => onDelete(value)}
          />
        ) : null}
      </div>
      {open ? (
        <ul style={{maxHeight: "92px", overflowY: "scroll"}} className="select__list">
          {searchable ? (
            <div className="select__search">
              <input
                id="select-search-input"
                className="select__search__input"
                type="text"
                value={search}
                onChange={handleSearch}
              />
              {!search ? (
                <label
                  htmlFor="select-search-input"
                  className="select__search__label"
                >
                  <i class="fas fa-search" />
                  Search...
                </label>
              ) : null}
            </div>
          ) : null}
          {filteredOptions.map((option) => (
            <li
              key={`${option}_${Math.random().toString(16).slice(2)}`}
              className={`select__list__option hovereable${
                value === option ? " selected" : ""
              }`}
              onClick={() => handleChange(option)}
            >
              {
                !isNaN(option) ? 
                  (option === 1 ? "1st" : option === 2 ? "2nd" : option === 3 ? "3rd" : option + "th") 
                  : option 
              }
            </li>
          ))}
          {filteredOptions.length === 0 ? (
            <span className="select__list__option">No results</span>
          ) : null}
        </ul>
      ) : null}
    </div>
  );
};

Select.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  onDelete: PropTypes.func,
  searchable: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Select;
