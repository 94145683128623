@import "../../../../src/scss/variables.scss";

.input-text-area-wrapper {
  font-family: "Open Sans", sans-serif;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: .5rem;
  position: relative;

  &__length {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translateY(1.5rem);
    font-size: 0.875rem;
    font-weight: 600;
    color: $helperText;
    padding: 0.25rem;
  }

  .text-area-label {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 0 0 4px 0;
    text-align: left;

    &.show-label {
      transition: all 0.5s ease;
      color: rgba(84, 88, 90, 0.5);
    }
  }

  .input-text-area {
    background-color: #fff;
    border: 1px solid rgba(84, 88, 90, 0.2);
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.4);
    font-weight: 500;
    font-size: 16px;
    outline: none;
    padding: 12px 14px;
    resize: none;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

    &::placeholder {
      color: rgba(84, 88, 90, 0.5);
      font-family: Montserrat, sans-serif;
      font-size: 14px;
      font-weight: 600;
    }

    &--has-value {
      color: #54585A;
      font-size: 18px;
    }

    &.is-valid {
      border: 1px solid #7DC0BD;
    }

    &:focus, &:active {
      border: 1px solid #54585A;
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  .after-label {
    color: rgba(84, 88, 90, 0.5);
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 600;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px white inset;
    -webkit-text-fill-color: grey;
  }
}



