import React, { Component } from "react";
import PropTypes from "prop-types";
import InputText from "../input-text";
// import Validator from '../utils/validator/index';
import "./style.scss";
import Tooltip from "src/components/Tooltip";

class RadioButton extends Component {
  static propTypes = {
    checked: PropTypes.bool,
    clicked: PropTypes.func,
    className: PropTypes.string,
    hasInputText: PropTypes.bool,
    resetInputValue: PropTypes.bool,
    returnInputValue: PropTypes.func,
    selectedInput: PropTypes.string,
    listElement: PropTypes.object,
    disabled: PropTypes.bool,
  };

  state = {
    selectedInput: this.props.selectedInput || null,
    isTextComponent: this.props.hasInputText,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.resetInputValue && prevState.isTextComponent) {
      return { selectedInput: null, fieldError: null, fieldValid: null };
    }
    return null;
  }

  checkForValidationErrors = (value, stateError, validField, listElement) => {
    const valid = true;
    if (valid) {
      this.setState({ [validField]: true });
      this.props.returnInputValue({ ...listElement, value });
    } else {
      this.setState({ [stateError]: "Invalid" });
    }
  };

  render() {
    const { selectedInput, fieldError, fieldValid } = this.state;
    const {
      labelName,
      className,
      clicked,
      checked,
      hasInputText,
      listElement,
      disabled,
    } = this.props;

    return (
      <div className="flex-row">
        <div
          className={`custom-radio-button${disabled ? " disabled" : ""} ${
            className || ""
          } flex-row`}
          onClick={() => clicked(checked)}
        >
          {checked ? (
            <span className="icon-radio-full" />
          ) : (
            <span className="icon-radio-empty" />
          )}
          {hasInputText ? (
            <InputText
              disabled={disabled}
              wrapperClassName={"flex-column special-length"}
              placeholder="Other"
              error={fieldError}
              isValid={!fieldError && fieldValid}
              onChange={(e) =>
                this.setState({
                  selectedInput: e.target.value,
                  fieldError: null,
                  fieldValid: null,
                })
              }
              onBlur={(e) =>
                selectedInput &&
                this.checkForValidationErrors(
                  e.target.value,
                  "fieldError",
                  "fieldValid",
                  listElement
                )
              }
              value={selectedInput}
            />
          ) : (
            <span className="custom-radio-button__label">{labelName}</span>
          )}
        </div>
        {!!listElement.tooltip && <Tooltip text={listElement.tooltip} popOverClassName={listElement.tooltipClassName}/>}
        {!!listElement.tooltipElement && <Tooltip popOverClassName={listElement.tooltipClassName}>{listElement.tooltipElement}</Tooltip>}
        {!!listElement.icon && listElement.icon}
      </div>
    );
  }
}

export default RadioButton;
