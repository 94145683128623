// used for general stuff that we use on all parts
// import objectAssign from 'object-assign'
import cloneDeep from "lodash.clonedeep";
import {
  SET_SELECTED_HEADER_TAB,
  SET_ON_BOARDING_DATA,
  SET_SIGNED_UP_USER,
  SET_LOCAL_STORAGE_DATA,
  SET_LEFT_PANEL_FILTERS,
  RESET_LEFT_PANEL_FILTER,
  SAVE_SELECTED_FILTERS,
  LOAD_USERS,
  REQUEST_ACCESS_FOR_USER,
  LOAD_USER_INFO,
  CLOSE_USER_INFO_MODAL,
  OPEN_CLOSE_FILTER_MODAL_AND_SET_FILTER,
  OPEN_CLOSE_SAVE_FILTER_MODAL,
  SET_SAVED_FILTER,
  LOAD_SAVED_FILTERS,
  TOGGLE_LOADING_MODAL,
  REMOVE_ON_BOARDING_DATA,
  RATE_USER,
  LOCK_USER,
  UN_LOCK_USER,
  CANCEL_REQUEST_ACCESS_FOR_USER,
  ADD_CHANGE_USER_STATUS,
  SEND_CONTACT_MESSAGE,
  SENT_CONTACT_MESSAGE,
  FILTER_USERS,
  SAVE_USER_FIELD_DATA_FROM_ADMIN,
  USER_INACTIVATE,
  LOAD_USER_INFO_ADMIN,
  SET_DELETE_PHOTO,
  GET_USER_NOTES,
  CREATE_NOTE,
  UPDATE_NOTE
} from "../constants/actionTypes";

const initialState = {
  activeUser: null,
  selectedTab: null,
  loadingModal: {
    show: false,
    loading: false,
    error: null,
  },
  onBoardingData: {
    theBasics: {},
    moreAbout: {},
    yourPartners: {},
    photos: {},
    account: {},
  },
  leftPanelFilters: {},
  openSaveFiltersModal: false,
  savedSelectedFilters: {},
  openFiltersModal: false,
  selectedFiltersModal: null,
  usersList: [],
  filteredUsersList: [],
  showUserInfo: false,
  requestedUserData: {},
  contactMessageSent: false,
  resettingFilter: false,
};

const addInfoRequestOnUsers = (connectionRequest) => {
  return (item) =>
    item._id !== connectionRequest.userId
      ? item
      : {
        ...item,
        connectionsRequests: [...item.connectionsRequests, connectionRequest],
      };
};

export default function generalReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_USERS: {
      const { users } = action.response;

      return {
        ...state,
        usersList: users,
        filteredUsersList: users,
      };
    }

    case REQUEST_ACCESS_FOR_USER: {
      const { newConnectionRequest } = action.response;
      return {
        ...state,
        usersList: state.usersList.map(
          addInfoRequestOnUsers(newConnectionRequest)
        ),
        filteredUsersList: state.filteredUsersList.map(
          addInfoRequestOnUsers(newConnectionRequest)
        ),
      };
    }

    case SET_ON_BOARDING_DATA: {
      const { section, field, value } = action.response;
      return {
        ...state,
        onBoardingData: {
          ...state.onBoardingData,
          [section]: {
            ...state.onBoardingData[section],
            [field]: value,
          },
        },
      };
    }

    case REMOVE_ON_BOARDING_DATA: {
      return {
        ...state,
        onBoardingData: {
          theBasics: {},
          moreAbout: {},
          yourPartners: {},
          photos: {},
          account: {},
        },
        activeUser: null,
      };
    }

    case SET_SELECTED_HEADER_TAB: {
      return {
        ...state,
        selectedTab: action.tab,
      };
    }

    case SET_SIGNED_UP_USER: {
      return {
        ...state,
        activeUser: action.data,
      };
    }

    case SET_LOCAL_STORAGE_DATA: {
      return {
        ...state,
        onBoardingData: action.onBoardingData,
        activeUser: action.activeUser || null,
      };
    }

    case FILTER_USERS: {
      if (action.response) {
        return {
          ...state,
          filteredUsersList: state.usersList.filter((item) =>
            action.response.find((fu) => item._id === fu._id)
          ),
        };
      }
      return { ...state };
    }

    case SET_LEFT_PANEL_FILTERS: {
      return {
        ...state,
        leftPanelFilters: action.response,
        resettingFilter: false,
      };
    }

    case RESET_LEFT_PANEL_FILTER: {
      const { filter } = action;
      const leftFilters = { ...state.leftPanelFilters };
      delete leftFilters[filter];
      return {
        ...state,
        leftPanelFilters: leftFilters,
        resettingFilter: true,
      };
    }

    case SAVE_SELECTED_FILTERS: {
      const { savedSelectedFilters } = action.response.newSavedFilters;
      return {
        ...state,
        openSaveFiltersModal: false,
        savedSelectedFilters: savedSelectedFilters,
      };
    }

    case LOAD_USER_INFO: {
      if (action.response) {
        return {
          ...state,
          showUserInfo: true,
          requestedUserData: action.response.user,
        };
      }
      return { ...state };
    }

    case LOAD_USER_INFO_ADMIN: {
      if (action.response) {
        return {
          ...state,
          showUserInfo: true,
          requestedUserData: action.response.user,
        };
      }
      return { ...state };
    }

    case CLOSE_USER_INFO_MODAL: {
      return {
        ...state,
        showUserInfo: false,
        requestedUserData: {},
      };
    }

    case OPEN_CLOSE_FILTER_MODAL_AND_SET_FILTER: {
      const { mode, filter } = action.response;
      return {
        ...state,
        openFiltersModal: mode,
        selectedFiltersModal: filter || null,
      };
    }

    case OPEN_CLOSE_SAVE_FILTER_MODAL: {
      return {
        ...state,
        openSaveFiltersModal: action.response,
      };
    }

    case SET_SAVED_FILTER: {
      return {
        ...state,
        leftPanelFilters: action.response,
        resettingFilter: false,
      };
    }

    case LOAD_SAVED_FILTERS: {
      return {
        ...state,
        savedSelectedFilters: action.response.filters || {},
      };
    }

    case TOGGLE_LOADING_MODAL: {
      const loadingModal = { ...state.loadingModal, ...action.data };
      return {
        ...state,
        loadingModal,
      };
    }

    case RATE_USER: {
      if (action.response && action.response.data) {
        const { userId, score } = action.response.data;
        const filteredUsersList = cloneDeep(state.filteredUsersList);
        const idx = filteredUsersList.findIndex((user) => user._id === userId);
        filteredUsersList[idx].userRating = score;
        return { ...state, filteredUsersList };
      }
      return { ...state };
    }

    case LOCK_USER: {
      if (action.response && action.response.data) {
        const { userId, _id: connectionId } = action.response.data;
        const filteredList = cloneDeep(state.filteredUsersList);
        const idx = filteredList.findIndex((user) => user._id === userId);
        if (filteredList[idx].connectionsRequests.length === 0) {
          filteredList[idx].connectionsRequests = [action.response.data];
        } else {
          const existingConn = filteredList[idx].connectionsRequests.find(
            (conn) => conn._id === connectionId
          );
          if (existingConn) {
            const connIdx = filteredList[idx].connectionsRequests.findIndex(
              (conn) => conn._id === connectionId
            );
            filteredList[idx].connectionsRequests[connIdx] =
              action.response.data;
          } else {
            filteredList[idx].connectionsRequests = [
              ...filteredList[idx].connectionsRequests,
              { ...action.response.data },
            ];
          }
        }
        return { ...state, filteredUsersList: filteredList };
      }
      return { ...state };
    }

    case UN_LOCK_USER: {
      if (action.response && action.response.data) {
        const { userId, _id: connectionId } = action.response.data;
        const filteredUsersList = cloneDeep(state.filteredUsersList);
        const idx = filteredUsersList.findIndex((user) => user._id === userId);
        const connIdx = filteredUsersList[idx].connectionsRequests.findIndex(
          (conn) => conn._id === connectionId
        );
        filteredUsersList[idx].connectionsRequests.splice(connIdx, 1);

        return { ...state, filteredUsersList };
      }
      return { ...state };
    }

    case CANCEL_REQUEST_ACCESS_FOR_USER: {
      const { userId, _id: connectionId } = action.response;
      const filteredUsersList = cloneDeep(state.filteredUsersList);
      const idx = filteredUsersList.findIndex((item) => item._id === userId);
      filteredUsersList[idx].connectionsRequests = filteredUsersList[
        idx
        ].connectionsRequests.filter((conn) => conn._id !== connectionId);

      return { ...state, filteredUsersList };
    }

    case ADD_CHANGE_USER_STATUS: {
      if (action.response) {
        const { userId, status, matchMakerId } = action.response;
        const filteredUsersList = cloneDeep(state.filteredUsersList);
        const userIdx = filteredUsersList.findIndex(
          (item) => item._id === userId
        );
        if (filteredUsersList[userIdx].userRelationshipStatuses.length === 0) {
          filteredUsersList[userIdx].userRelationshipStatuses = [
            { matchMakerId, status },
          ];
        } else {
          const relIdx = filteredUsersList[
            userIdx
            ].userRelationshipStatuses.findIndex(
            (item) => item.matchMakerId === matchMakerId
          );
          filteredUsersList[userIdx].userRelationshipStatuses[relIdx].status =
            status;
        }

        return { ...state, filteredUsersList };
      }
      return { ...state };
    }

    case SEND_CONTACT_MESSAGE: {
      const { contactMessageSent } = action.response;
      return { ...state, contactMessageSent: contactMessageSent || false };
    }

    case SENT_CONTACT_MESSAGE: {
      return { ...state, contactMessageSent: false };
    }

    case SAVE_USER_FIELD_DATA_FROM_ADMIN: {
      if (action.response) {
        const { _id, willingToPay, allowsPhotos } = action.response.user;
        const updatedUserList = cloneDeep(state.usersList);
        const userIdx = updatedUserList.findIndex((item) => item._id === _id);
        updatedUserList[userIdx].willingToPay = willingToPay;
        updatedUserList[userIdx].allowsPhotos = allowsPhotos;
        return {
          ...state,
          filteredUsersList: updatedUserList,
          usersList: updatedUserList,
        };
      }
      return { ...state };
    }

    case USER_INACTIVATE: {
      if (action.response) {
        const { _id } = action.response.user;
        const filteredList = state.usersList.filter((item) => item._id !== _id);
        return {
          ...state,
          filteredUsersList: filteredList,
          usersList: filteredList,
        };
      }
      return { ...state };
    }

    case SET_DELETE_PHOTO: {
      if (action.response) {
        const { _id: userId, images } = action.response;
        const fUsers = cloneDeep(state.usersList);
        const clonedUser = cloneDeep(state.requestedUserData);
        clonedUser.images = images;
        const userIdx = fUsers.findIndex((item) => item._id === userId);
        fUsers[userIdx].images = images;
        return {
          ...state,
          filteredUsersList: fUsers,
          usersList: fUsers,
          requestedUserData: clonedUser,
        };
      }
      return { ...state };
    }

    case CREATE_NOTE:{
      if (action.response) {
        return {
          ...state,
          requestedUserData: {...state.requestedUserData, notes: [...state.requestedUserData.notes, action.response]},
        };
      }
      return { ...state };
    }

    case GET_USER_NOTES: {
      if (action.response) {
        return {
          ...state,
          requestedUserData: {...state.requestedUserData, notes: action.response},
        };
      }

      return { ...state };
    }

    case UPDATE_NOTE: {
      if (action.response) {
        const { _id: noteId, note } = action.response;
        const updatedNotes = state.requestedUserData.notes.map((item) =>
          item._id === noteId ? { ...item, note } : item
        );
        return {
          ...state,
          requestedUserData: { ...state.requestedUserData, notes: updatedNotes },
        };
      }
      return { ...state };

    }

    default: {
      return { ...state };
    }
  }
}
