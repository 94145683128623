import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as generalActions from "../../actions/generalActions";
import GoogleMapReact from "google-map-react";
import Button from "../../components/button";
import InputText from "../../components/input-text";
import Validator from "../../components/utils/validator";
import TextArea from "../../components/input-textarea";
import CheckBox from "../../components/check-box";
import "../home-pages/style.scss";
import ToolTip from "../../components/tooltip";
import pin from "../home-pages/assets/contact-page/pin.svg";

class ContactPage extends Component {
  state = {
    firstName: null,
    lastName: null,
    email: null,
    phoneNumber: null,
    message: null,
    interest: [],
    errors: {},
    validFields: {},
    showMap: false,
  };

  setData = (field, value, fieldError, fieldValid) => {
    const { errors, validFields } = this.state;
    this.setState({
      [field]: value,
      errors: { ...errors, [fieldError]: null },
      validFields: { ...validFields, [fieldValid]: null },
    });
  };

  sendData = () => {
    if (this.validate()) {
      const { firstName, lastName, email, phoneNumber, interest, message } =
        this.state;
      const { sendUserContactData } = this.props.generalActions;
      let interestMessage = "";
      interest.forEach((item, i) => {
        interestMessage += `${item.label}${
          i === interest.length - 1 ? "" : ","
        } `;
      });
      sendUserContactData({
        firstName,
        lastName,
        email,
        phoneNumber,
        interest: interestMessage,
        message,
      });
      this.setState({
        firstName: null,
        lastName: null,
        email: null,
        interest: [],
        message: null,
        phoneNumber: null,
      });
    }
  };

  validate = () => {
    let isValid = true;
    const { firstName, email, errors, interest } = this.state;
    if (!firstName) {
      errors.firstNameError = "Required";
      isValid = false;
    }
    if (!email) {
      errors.emailError = "Required";
      isValid = false;
    }
    if (interest.length < 1) {
      errors.interestError = "Required";
      isValid = false;
    }

    Object.values(errors).forEach((error) => {
      if (error) {
        isValid = false;
      }
    });
    this.setState({ errors });

    return isValid;
  };

  render() {
    const { showMap } = this.state;
    const { contactMessageSent } = this.props.generalReducer;
    const { removeContactMessageSentFlag } = this.props.generalActions;
    const renderInputs = this._renderInputs();
    const renderTextArea = this._renderTextArea();
    const renderCheckboxOptions = this._renderOptions();
    const MarkerComponent = ({ text }) => (
      <div>
        <img src={pin} />
        <span style={{ color: "#FF596F" }}>{text}</span>
      </div>
    );

    return (
      <div className="contact-page-container flex-row">
        <div className="left-section flex-column">
          <div className="contact-form flex-column">
            <div className="contact-form__title">Contact Us</div>
            {contactMessageSent && (
              <ToolTip
                text={"Your message was successfully sent!"}
                closeCallback={() => removeContactMessageSentFlag()}
              />
            )}
            {renderInputs}
            {renderCheckboxOptions}
            {renderTextArea}
            <Button
              value="send message"
              customClass="yellow send-message two-twenty-width"
              click={() => this.sendData()}
            />
          </div>
        </div>
        <div className="right-section flex-column">
          {showMap ? (
            <div className="map-container">
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyAW1x3Wl4GctKMhqXBsJppg8xKsK0J6jhI",
                }}
                defaultCenter={{ lat: 39.50716, lng: -99.260521 }}
                defaultZoom={4.5}
                city={[
                  {
                    id: 1,
                    name: "San Carlos",
                    latitude: "37.507160",
                    longitude: "-122.260521",
                  },
                ]}
              >
                <MarkerComponent
                  lat={37.50716}
                  lng={-122.260521}
                  text={"Silicon Valley"}
                />
              </GoogleMapReact>
            </div>
          ) : (
            <div className="contact-background flex-column">
              <div className="couple-info flex-column flex-center">
                <span>Layla & Steve</span>
                <span>San Francisco</span>
              </div>
            </div>
          )}
          {showMap ? (
            <Button
              value="Close"
              click={() => this.setState({ showMap: false })}
              customClass="yellow map-button"
            />
          ) : (
            <Button
              value="Map"
              click={() => this.setState({ showMap: true })}
              customClass="map-button"
            />
          )}
        </div>
      </div>
    );
  }

  _renderInputs = () => {
    const { firstName, lastName, email, phoneNumber, errors, validFields } =
      this.state;

    return (
      <div>
        <div className="flex-row names">
          <InputText
            wrapperClassName="flex-column float-left"
            placeholder="First Name"
            error={errors.firstNameError}
            isValid={firstName && validFields.firstNameValid}
            onBlur={(e) =>
              firstName &&
              this.checkForValidationErrors(
                "char",
                e.target.value,
                "firstNameError",
                "firstNameValid"
              )
            }
            onChange={(e) =>
              this.setData(
                "firstName",
                e.target.value,
                "firstNameError",
                "firstNameValid"
              )
            }
            value={firstName}
          />
          <InputText
            wrapperClassName="flex-column float-right"
            placeholder="Last Name (opt)"
            error={errors.lastNameError}
            isValid={lastName && validFields.lastNameValid}
            onBlur={(e) =>
              lastName &&
              lastName.length > 2 &&
              this.checkForValidationErrors(
                "char",
                e.target.value,
                "lastNameError",
                "lastNameValid"
              )
            }
            onChange={(e) =>
              this.setData(
                "lastName",
                e.target.value,
                "lastNameError",
                "lastNameValid"
              )
            }
            value={lastName}
          />
        </div>

        <InputText
          wrapperClassName="flex-column"
          placeholder="Email"
          error={errors.emailError}
          isValid={email && validFields.emailValid}
          onChange={(e) =>
            this.setData("email", e.target.value, "emailError", "emailValid")
          }
          onBlur={(e) =>
            this.checkForValidationErrors(
              "email",
              e.target.value,
              "emailError",
              "emailValid"
            )
          }
          value={email}
        />
        <InputText
          wrapperClassName="flex-column contact-phone"
          placeholder="Phone Number (optional)"
          error={errors.phoneError}
          isValid={phoneNumber && validFields.phoneValid}
          onBlur={(e) =>
            phoneNumber &&
            this.checkForValidationErrors(
              "phoneNumber",
              e.target.value,
              "phoneError",
              "phoneValid"
            )
          }
          onChange={(e) =>
            this.setData(
              "phoneNumber",
              e.target.value,
              "phoneError",
              "phoneValid"
            )
          }
          value={phoneNumber}
        />
      </div>
    );
  };

  _renderTextArea = () => {
    const { message, isValidMessage } = this.state;
    return (
      <TextArea
        className="contact-textarea"
        value={message}
        isValid={isValidMessage}
        placeholder="Message (optional)"
        classNameForLabel={message ? " show-label" : ""}
        onBlur={() => message && this.setState({ isValidMessage: true })}
        onChange={(e) =>
          this.setState({ message: e.target.value, isValidMessage: false })
        }
      />
    );
  };

  _renderOptions = () => {
    const { interest, errors } = this.state;
    const labelName1 = (
      <div className="flex-column special-label">
        <span>Get matched free</span>
        <span>Passive route</span>
      </div>
    );
    const labelName2 = (
      <div className="flex-column special-label">
        <span>Talk to a matchmaker about focusing on me</span>
        <span>Paid route through matchmaker (no obligation)</span>
      </div>
    );
    const labelName3 = <div className="flex-row other-option">Other</div>;
    const INTEREST = [
      { label: "Get matched free", id: 1, name: labelName1 },
      {
        label: "Talk to a matchmaker about focusing on me",
        id: 2,
        name: labelName2,
      },
      { label: "Other", id: 3, name: labelName3 },
    ];
    return (
      <div className="interested-in flex-column" style={{ marginTop: "20px" }}>
        <div className="flex-row" style={{ position: "relative" }}>
          <span className="cell-title">{`Are you looking to:`}</span>
          {errors.interestError && (
            <span className="custom-error-label">{errors.interestError}</span>
          )}
        </div>
        <div className="flex-column">
          {INTEREST.map((int, i) => {
            const isChecked = !!interest.find((label) => label.id === int.id);
            return (
              <div key={i}>
                <CheckBox
                  labelName={int.name}
                  customClass="sign-up"
                  clicked={() => {
                    !isChecked
                      ? this.setState({
                          interest: [...interest, int],
                          errors: { ...errors, interestError: false },
                        })
                      : this.setState({
                          interest: interest.filter((opt) => opt.id !== int.id),
                          errors: { ...errors, interestError: false },
                        });
                  }}
                  checked={isChecked}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  checkForValidationErrors = (type, value, stateError, validField) => {
    let valid;
    switch (type) {
      case "char":
        valid = Validator.validateCharacter(value);
        this.setErrorsOrValids(valid, validField, stateError, "Invalid");
        break;
      case "phoneNumber":
        valid = Validator.validatePhoneNumber(value);
        this.setErrorsOrValids(valid, validField, stateError, "Invalid");
        break;

      case "email":
        valid = Validator.validateEmail(value);
        this.setErrorsOrValids(valid, validField, stateError, "Invalid");
        break;
      default:
        break;
    }
  };

  setErrorsOrValids = (valid, validField, stateError, message) => {
    const { validFields, errors } = this.state;
    valid
      ? this.setState({ validFields: { ...validFields, [validField]: true } })
      : this.setState({ errors: { ...errors, [stateError]: message } });
  };
}

ContactPage.propTypes = {
  history: PropTypes.object,
  generalActions: PropTypes.object,
  generalReducer: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return {
    generalActions: bindActionCreators(generalActions, dispatch),
  };
};

const mapStateToProps = (state) => {
  return {
    generalReducer: state.generalReducer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactPage);
