import { post } from "../utils/client";

export const useFacebookLogin = (loginCallback) => {
  window.fbAsyncInit = function () {
    FB.init({
      appId: process.env.FACEBOOK_APP_ID,
      cookie: true,
      xfbml: true,
      version: "v15.0",
    });
  };

  // Load the SDK Asynchronously
  (function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  })(document, "script", "facebook-jssdk");

  const facebookLogin = (e) => {
    e.preventDefault();
    FB.login(facebookLoginCallback, { scope: "email" });
  };

  const facebookLoginCallback = (response) => {
    if (response.status === "connected") {
      FB.api(
        "/me",
        { fields: "first_name, last_name, email" },
        fetchFacebookUserInfoCallback(response.authResponse)
      );
    }
  };

  const fetchFacebookUserInfoCallback = (authResponse) => async (res) => {
    await post({
      url: "/facebook/callback",
      data: { ...authResponse, ...res },
      onSuccess: loginCallback,
      onError: (err) => {
        console.log("Callback err:", err);
      },
    });
  };

  return facebookLogin;
};
