.triangle-component {
    position: absolute;
    .arrow-up {
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 9px solid #fff;
        box-shadow: 2px 2px -1px 0 rgba(84,88,90,0.2);
    }
    &:before {
        content: '';
        position: absolute;
        width: 12px;
        height: 1px;
        background-color: rgba(84, 88, 90, 0.1);
        transform: rotate(-57deg);
        left: -2.6px;
        top: 3px;
    }
    &:after {
        content: '';
        position: absolute;
        width: 12px;
        height: 1px;
        background-color: rgba(84, 88, 90, 0.1);
        transform: rotate(52deg);
        left: 4.5px;
        top: 3px;
    }
}