.drop-down-wrapper {
    font-family: 'Montserrat', sans-serif;
    max-width: 390px;
    width: 100%;
    user-select: none;
    text-align: center;

    .drop-down-label {
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        padding: 0 0 4px 12px;
        text-align: left;

        &.show-label {
            transition: all 0.5s ease;
            color: rgba(84,88,90,0.5);
        }
        &.grey {
            color: #53585a;
        }
    }

    .flex-container {
        position: relative;
    }

    &__content {
        border-radius: 0 0 4px 4px;
        background-color: #fff;
        border: 1px solid rgba(0,0,0,0.4);
        display: none;
        left: 0;
        position: absolute;
        right: 0;
        top: 42px;
        z-index: 2;

        .drop-down-search {
            padding: 0 20px;
        }
    }

    &__list {
        list-style: none;
        max-height: 250px;
        margin: 0;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 0;

        &::-webkit-scrollbar {
            width: 5px;
        }
        &::-webkit-scrollbar-track {
            background-color: rgba(84,88,90,0.2);
        }
        &::-webkit-scrollbar-thumb {
            background-color: #54585A;
        }

        &__item {
            color: rgba(84,88,90,1);
            margin: 0;
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0.2px;
            font-family: Nunito, sans-serif;
            line-height: 22px;
            -webkit-appearance: none;
            -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
            padding: 9px 10px;
            cursor: pointer;

            &:hover {
                color: #54585A;
                background-color: #FFDA65;
            }
            &--active {
                font-weight: 900;
            }
            &:last-child {
                border-radius: 0 0 4px 4px;
            }
        }
    }

    &--open {
        z-index: 5;

        .drop-down-wrapper__content {
            display: block;
        }
    }

    .input-text {
        border: 1px solid rgba(84,88,90,0.2);
        border-radius: 4px;
        background-color: #fff;
        cursor: pointer;
        height: 38px;
        outline: none;
        padding: 0 12px;
        position: relative;
        text-transform: capitalize;
        -webkit-appearance: none;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

        &.placeholder {
            color: rgba(84,88,90,0.5);
            font-family: Montserrat, sans-serif;
            font-size: 16px;
            font-weight: 600;
        }

        .icon-arrow {
            position: absolute;
            right: 9px;
            transform: rotate(90deg);
            top: 10px;
        }
        .icon-arrow:hover {
            &:before { color: #54585A; }
        }

        &:disabled {
        }

        &--has-value {
            color: #54585A;
            font-size: 16px;
            border: 1px solid #7DC0BD;
        }

        &.hasError {
            border: 1px solid #FF596F;
        }

        &:focus, &:active {
        }
    }

    &--disabled .input-text {
        // background-color: #66B8D3;
        background-color: #ffffff;
        color: #cecbcb;
        cursor: default;
    }

    .error-label__icon {
        color: #FF596F;
        padding-right: 5px;
        display: inline-block;
        vertical-align: middle;
        margin-left: -20px;
    }

    &.special {
        margin-bottom: 15px;
        max-width: 655px;
        width: 100%;
        .input-text {
            .icon-arrow {
                right: 45px;
                &:after {
                    background: rgba(84,88,90,0.2);
                    content: '';
                    position: absolute;
                    height: 1px;
                    top: -8px;
                    left: 0;
                    width: 20px;
                }
            }

        }
        .icon-close-button-container {
            height: 40px;
            position: absolute;
            right: 0;
            top: 0;
            width: 40px;
        }
        .icon-close-button {
            font-size: 16px;

        }
    }
}



