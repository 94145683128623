import React, { Component } from "react";
import PropTypes from "prop-types";
import "./style.scss";

class TriangleComponent extends Component {
  state = {
    left: this.props.left || null,
    top: this.props.top || null,
    right: this.props.right || null,
    bottom: this.props.bottom || null,
  };

  render() {
    const { left, top, right, bottom } = this.state;
    const leftValue = `${left}px`;
    const topValue = `${top}px`;
    const rightValue = `${right}px`;
    const bottomValue = `${bottom}px`;

    return (
      <div
        className="triangle-component"
        style={{
          left: leftValue,
          right: rightValue,
          top: topValue,
          bottom: bottomValue,
        }}
      >
        <div className="arrow-up" />
      </div>
    );
  }
}

TriangleComponent.propTypes = {
  left: PropTypes.number,
  top: PropTypes.number,
  right: PropTypes.number,
  bottom: PropTypes.number,
};

export default TriangleComponent;
