import React, { useMemo, useState, useRef, useEffect } from "react";
import "./Autocomplete.scss";
import InputText from "../../../_old/src/components/input-text";
import { removeDiacritics } from "../../utils/functions";
import { Spinner } from "../../../launchpad";

/**
 * Autocomplete component.
 * @param props - Component props.
 * @param {string} props.value - The input value.
 * @param {() => {}} props.onChange - Callback function to handle input change.
 * @param {() => {}} props.onBlur - Callback function to handle input blur.
 * @param {string[]} props.options - Array of options to display.
 * @param {boolean} props.showPopover - A way to programatically trigger the popover.
 * @param {boolean} props.fullWidth - Whether the input should be full width or not.
 * @param {string} props.className - Additional class names to add to the component.
 * @param {Object} props.inputProps - Props to pass to the input component.
 * @param {Object} props.rest - Rest of the props.
 * @return {JSX.Element}
 * @constructor
 */
export const Autocomplete = ({
  value = "",
  onChange = () => {},
  options = [],
  fullWidth = false,
  className = "",
  inputProps,
  onOptionSelected = () => {},
  loading,
  primaryAccessor,
  secondaryAccessor,
  required,
  ...rest
}) => {
  const [focused, setFocused] = useState(false);
  const [activeOption, setActiveOption] = useState(0);
  const filteredOptions = useMemo(() => {
    if (!value) return options;
    return options.filter((option) => {
      let primary = option;
      if (typeof option === "object") {
        primary = option[primaryAccessor];
        if (typeof primary !== "string") return;
      }
      return removeDiacritics(value)
        .toLowerCase()
        .includes(removeDiacritics(primary).toLowerCase());
    });
  }, [options, value]);
  const popoverRef = useRef(null);

  const handleDocumentClick = (e) => {
    if (
      !e.target.closest(".autocomplete") &&
      !e.target.closest(".autocomplete__popover")
    ) {
      setFocused(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleInputFocus = () => setFocused(true);

  const handleInputChange = (newValue) => {
    setActiveOption(0);
    onChange(newValue);
  };

  const handleOptionClick = (option) => {
    setActiveOption(0);
    onOptionSelected(option);
    setFocused(false);
  };

  const handleInputKeyDown = (e) => {
    let newActiveOption;
    switch (e.key) {
      case "ArrowDown":
        newActiveOption = activeOption + 1;
        if (activeOption < filteredOptions.length - 1) {
          setActiveOption(newActiveOption);
          if (popoverRef.current) {
            const option = document.getElementById(
              `${filteredOptions[newActiveOption]}_${newActiveOption}`
            );
            const popoverHeight = popoverRef.current.clientHeight;
            const optionHeight = option.offsetHeight;
            const optionOffset = option.offsetTop;

            if (popoverHeight - optionOffset < optionHeight) {
              popoverRef.current.scrollTop =
                popoverRef.current.scrollTop + optionHeight;
            }
          }
        }
        break;
      case "ArrowUp":
        newActiveOption = activeOption - 1;
        if (activeOption > 0) {
          setActiveOption(newActiveOption);
          if (popoverRef.current) {
            const option = document.getElementById(
              `${filteredOptions[newActiveOption]}_${newActiveOption}`
            );
            const optionHeight = option.offsetHeight;

            if (popoverRef.current.scrollTop !== 0) {
              const newScrollTop = popoverRef.current.scrollTop - optionHeight;
              popoverRef.current.scrollTop =
                newScrollTop < 0 ? 0 : newScrollTop;
            }
          }
        }
        break;
      case "Enter":
        if (filteredOptions.length > 0) {
          handleOptionClick(filteredOptions[activeOption]);
          setFocused(false);
        }
        break;
      case "Escape":
        setActiveOption(0);
        setFocused(false);
        break;
      default:
        break;
    }
  };

  return (
    <div
      className={`autocomplete${fullWidth ? " full-width" : ""}${
        className ? ` ${className}` : ""
      }`}
      {...rest}
    >
      <InputText
        required={required}
        value={value}
        onFocus={handleInputFocus}
        onChange={(e) => handleInputChange(e.target.value)}
        onKeyDown={handleInputKeyDown}
        autocomplete="off"
        {...inputProps}
      />
      {value && focused && (
        <div className="autocomplete__popover" ref={popoverRef}>
          {loading ? (
            <div className="autocomplete__popover-loading">
              <Spinner className="autocomplete__popover-loading__spinner" />
              <p className="autocomplete__no-results">Loading...</p>
            </div>
          ) : filteredOptions.length ? (
            <ul role="list" className="autocomplete__list">
              {filteredOptions.map((option, idx) => (
                <li
                  id={`${option}_${idx}`}
                  key={`${option}_${idx}`}
                  className={`autocomplete__list__option${
                    activeOption === idx ? " active" : ""
                  }`}
                  onClick={() => handleOptionClick(option)}
                >
                  {typeof option === "object" ? (
                    <>
                      {option[primaryAccessor]}
                      <p className="autocomplete__list__option-secondary">
                        {option[secondaryAccessor]}
                      </p>
                    </>
                  ) : (
                    option
                  )}
                </li>
              ))}
            </ul>
          ) : (
            <p className="autocomplete__no-results">No results found</p>
          )}
        </div>
      )}
    </div>
  );
};
