/* launchpad overview: CMS
  Launchpad is set up so that all you need to do to create a dynamic, editable
  page is use a few basic components within your code, no additional piping or
  back end work required. The most basic components are:

  `<Page />`
  `<Snip />`
  `<Image />`

  All of these components can be imported from launchpad, e.g.:
  `import { Page, Snip, Image } from 'launchpad'`

  To create a new dynamic page, just create a new .jsx file in the `src/components/pages`
  directory and use the `<Page />` component anywhere in the file. The dev server
  will take care of the rest of the piping automatically. Then use the `Snip` and
  `Image` components within the `<Page />` and voila, you have an editable page
  that's automatically integrated with the CMS. For example:

  Create a file at `src/components/pages/Test.jsx`:

  ```js
  import React from 'react'
  import { Page, Snip, Image } from 'launchpad'

  export default function TestPage() {
    return <Page id='test-page'>
      <Snip name='main-text' />
      <Image name='image-1' />
    </Page>
  }

  \/\/ launchpad_url: /test/page
  ```

  You could optionally pass in a `page` prop to the Snip and Image, but by default
  they will pick up the id (`test-page`) from the Page component automatically
  via React's context API.

  Also notice the `\/\/ launchpad_url:` comment at the bottom, there are certain
  attributes which can be set for the CMS via these types of comments. The most
  useful ones are:

  - `\/\/ launchpad_url: <relative url>` - the url of the page in the CMS
  - `\/\/ launchpad_noindex: true` - if a value is set to this property the page will be hidden, e.g. hiding a WIP page to deploy other work
  - `\/\/ launchpad_title: <Title>` - the title of the page


  For admin dashboard pages, these can be useful as well
  - `\/\/ launchpad_type: admin` - hides the page from the "All Pages" list
  - `\/\/ launchpad_admin_panel: true` - adds a link to the page to the context pane
  - `\/\/ launchpad_icon: <icon name (font awesome)>` - sets the icon for the context pane link

*/

import React from "react";
import { setGlobal, getGlobal, applyMeta } from "launchpad";

export const PageContext = React.createContext({ pageId: "" });

/*
  launchpad component: Page
  categories: CMS, core
  Basis for CMS functionality. Essentially just a context provider for `<Snip />`
  and `<Image />` components, but it also ensures all pages have a similar root
  DOM structure which simplifies some of the core styling
  props:
    id - (String) a unique identifier for this page, used for namespacing text/image data

*/
export class Page extends React.Component {
  get pageId() {
    return this.props.id + "_" + window.location.pathname;
  }
  componentDidMount() {
    setGlobal({
      pageContext: this.pageId,
      pageID: this.props.id,
      pageLabel: (this.props.label || this.props.id || "").toUpperCase(),
    });
    applyMeta();
  }

  componentWillUnmount() {
    if (getGlobal("pageContext") === this.pageId) {
      setGlobal({ pageContext: "", pageLabel: "" });
    }
  }

  render() {
    const { className, id, children, padded } = this.props;
    return (
      <main id={id} className={`${className} ${padded ? "padded" : ""}`}>
        <PageContext.Provider value={{ pageId: id }}>
          {children}
        </PageContext.Provider>
      </main>
    );
  }
}
