import React from "react";
import "./Link.scss";

export const Link = ({ className, children, to, ...rest }) => (
  <a
    href={to}
    className={`link__root${className ? ` ${className}` : ""}`}
    {...rest}
  >
    {children}
  </a>
);
