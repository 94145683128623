@import "../../../../src/scss/utilities";

.modal-overlay {
  opacity: 0;
  z-index: -1;
  display: none;
  transition: opacity 1.5s ease-out;
  overflow: auto;
  margin-top: 2rem;
  
  &--isOpenModal {
    bottom: 0;
    left: 0;
    opacity: 1;
    display: flex;
    z-index: 101;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity 1.5s ease-out;
  }

  .modal {
    width: 80%;
    background: #ffffff;
    box-sizing: border-box;
    border: 1px solid #f5f5f5;
    border-radius: 8px;
    margin: 0 auto;
    position: absolute;
    top: calc(15 / 100 * 45vh);
    left: 50%;
    transform: translate(-50%, 0);
    min-height: 400px;
    z-index: 10;

    @include for-tablet-down {
      width: 100%;
    }

    .footer-section {
      border-top: 2px solid #eeedf1;
      margin-top: auto;
      padding: 20px 0 0;

      &__buttons {
        margin-left: auto;
        .grey {
          margin-left: 10px;
        }
      }
    }
  }

  &.event-modal {
    .modal {
      max-width: 834px;
      margin: 0 auto;
    }
  }
  &.view-modal {
    margin: 0 auto;
    border-top: 2px solid #6dbbaa;
  }
}

.modal-overlay:before {
  content: "";
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
