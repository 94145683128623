import React, { useMemo } from "react";
import "./Image.scss";

/**
 * Image component.
 * @param props
 * @param props.src
 * @param props.alt
 * @param props.className
 * @param props.rest
 * @return {JSX.Element}
 * @constructor
 */
export const Image = ({ src, alt, className, ...rest }) => {
  const classNames = useMemo(() => {
    let classes = "image-container";
    className && (classes += ` ${className}`);

    return classes;
  }, [className]);

  return (
    <figure className={classNames}>
      <img src={src} alt={alt} width="100%" height="100%" {...rest} />
    </figure>
  );
};
