@import "src/scss/variables";
@import "src/scss/utilities";

.lifestyle-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @include for-desktop {
    padding: 40px 60px;
  }

  &__field-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &__title {
      color: $teal;
      font-size: 1.125rem;
      font-family: Montserrat, sans-serif;
      margin: 0;
      font-weight: 500;
      &.error {
        color: $red;
      }
    }
  }

  .label {
    color: #ff6666;
    font-family: Montserrat, sans-serif;
    font-size: 18px;
    font-weight: 500;

    &.valid {
      color: $teal;
    }
  }

  .kids-section {
    border: 1px solid rgba(84, 88, 90, 0.2);
    border-radius: 4px;
    padding: 0 12px;
    width: 134px;

    .input-wrapper {
        width: 32px;

        .input-text {
            padding: 0 10px;
        }
    }

    .input-wrapper.small {
        width: 42px;

        .input-text {
            padding: 0 9px;
        }
    }

    .input-tag {
        color: rgba(84, 88, 90, 0.3);
        font-family: "Montserrat", sans-serif;
        font-size: 18px;
        font-style: italic;
        line-height: 24px;
        width: 33px;
        height: 40px;
      }

    &.kids {
        width: 88px;

        .input-tag {
        width: 50px;
        }
    }

    &.is-valid {
        border: 1px solid #7dc0bd;
        }
    }

    .kids-info {
        .kids-ages {
          height: 40px;
          margin-bottom: 10px;
        }

        .kid-label {
          color: #54585a;
          font-family: "Montserrat", sans-serif;
          font-size: 18px;
          margin-right: 12px;
          width: 112px;
          text-align: left;
        }
    }

    @include for-tablet-down {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

  &__continue-button-container {
    display: flex;
    justify-content: flex-start;
    margin-top: 1rem;
  }
}