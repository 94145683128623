import React from "react";
import PropTypes from "prop-types";
import "./style.scss";
import { apiPost } from "launchpad";
import UserSession from "../../../../components/user-session";

class MatchMakerReferrals extends React.Component {
  constructor() {
    super();
    this.state = {
      email: null,
      name: null,
      errors: {},
      validFields: {},
      showConfirmationTooltip: false,
      referrals: [],
      users: [],
      isAmbassador: UserSession.getToken().ambassadorAccount || false,
      referId: UserSession.getToken().referId || null,
    };
  }

  async componentDidMount() {
    const users = await apiPost("users", {
      filters: {},
      page: 1,
      per_page: 2000,
      query: "",
    });
    this.setState({ users: users.data });
  }

  renderReferralLink = () => {
    return (
      <p className="matchmaker-referrals__link">
        Your referral link:{" "}
        {this.state.referId ? (
          <a
            href={`https://datespot.love/?referrer=${this.state.referId}`}
          >{`https://datespot.love/?referrer=${this.state.referId}`}</a>
        ) : (
          <span>
            Not assigned yet - please contact{" "}
            <a href="mailto:support@datespot.love">support@datespot.love</a>
          </span>
        )}
      </p>
    );
  };

  render() {
    const renderReferralsTable = this._renderReferralsTable();
    const renderReferralLink = this.renderReferralLink();
    return (
      <div className="matchmaker-referrals flex-column">
        <div className="matchmaker-referrals__container">
          <div className="matchmaker-plans__text">
            {this.state.isAmbassador
              ? `Refer Someone and get paid`
              : `Refer to DateSpot and everyone wins!`}
          </div>
          {this.state.isAmbassador ? (
            <div className="matchmaker-referrals__text">
              <p>
                When a member you refer becomes a mutual match (turns into a
                first date), you receive $100, which is 20% of the DateSpot
                connection free. And of course, the more people you refer to
                DateSpot, the more likely we are to refer people back to you!
              </p>
              <p>
                For a referral to appear below, the new member needs to complete
                a minimum percentage of their profile, including photos. If
                someone you refer has signed up, and they do not appear below,
                follow up with them or DateSpot to bring the profile to
                completion for credit.
              </p>
              {/* {renderReferralLink} */}
            </div>
          ) : (
            <div className="matchmaker-referrals__text">
              <p>
                When a member you refer turns into a mutual match with a
                DateSpot client (where two people want to meet for a first
                date), you receive $100 of the intro fee that the DateSpot
                client pays.
              </p>
              <p>For a referral to count, the new member needs to: </p>
              <ul>
                <li>Complete a minimum of 75% of their profile</li>
                <li>Upload real photo(s)</li>
                <li>Not be flagged as suspicious</li>
              </ul>
              <p>
                If someone you referred has signed up, and they do not appear
                below, follow up with them or DateSpot to bring the profile to
                completion so you're eligible for the bonus with that person.
              </p>
              {/* {renderReferralLink} */}
            </div>
          )}
          {renderReferralsTable}
        </div>
      </div>
    );
  }

  returnProfileCompletion = (progress) => {
    const sections = Object.keys(progress);
    let sum = 0;
    for (const section of sections) {
      if (progress[section] > 100) {
        sum += 100;
      } else {
        sum += progress[section];
      }
    }
    return (sum / 400) * 100;
  };

  formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, day, year].join("-");
  };

  _renderReferralsTable = () => {
    const { matchMakerReferrals } = this.props;
    let referrals = [];
    if (this.state.users.length > 0) {
      referrals = this.state.users.filter((x) =>
        matchMakerReferrals?.find((y) => y.userId === x.user_id)
      );
      // referrals = referrals.filter((x) => x.suspicion.profile === false);
    }
    return (
      <div className="matchmaker-referrals__table flex-column">
        <div className="label">Referrals who signed up</div>
        <div className="table">
          <div className="table-headers flex-vertical-center">
            <span className="referral-name">Member #</span>
            <span className="referral-name">Name</span>
            <span className="referral-email">Email</span>
            <span className="referral-email">Signup Date</span>
            <span className="referral-email">Member Type</span>
            <span className="referral-email">Profile %</span>
            <span className="referral-email"># of Photos</span>
            <span className="referral-email">Suspicious</span>
          </div>
          <div className="table-content">
            {referrals.length > 0 &&
              referrals.map((ref, r) => {
                return (
                  <div
                    key={r}
                    className="table-content__item flex-vertical-center"
                  >
                    <span className="table-name">
                      {ref.user_id ? ref.user_id : "--"}
                    </span>
                    <span className="table-name">{ref.name}</span>
                    <span className="table-email">{ref.email}</span>
                    <span className="table-email">
                      {ref.createdAt ? this.formatDate(ref.createdAt) : "--"}
                    </span>
                    <span className="table-email">
                      {ref.clientStatus ? (ref.clientStatus === "current" ? "Premium Member" :
                        (ref.clientStatus === "free" ? "Free Member" :
                          (ref.clientStatus === "freemium" ? "Freemium Member" : "--")) )
                        : "--"}
                    </span>
                    <span className="table-email">
                      {ref.userProgress
                        ? `${this.returnProfileCompletion(ref.userProgress)} %`
                        : "--"}
                    </span>
                    <span className="table-name">{ref.images.length}</span>
                    <span className="table-name">
                      {ref.suspicion.profile ? "Yes" : "No"}
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  };
}

MatchMakerReferrals.propTypes = {
  history: PropTypes.object.isRequired,
  authActions: PropTypes.object,
  matchMakerActions: PropTypes.object,
  matchMakerReferrals: PropTypes.any,
};

export default MatchMakerReferrals;
