import { getGlobal, setGlobal, apiGet, apiPost } from "launchpad";
import { stateOptions } from "data/states";

export const reloadRegions = async () =>
  setGlobal({ regions: await apiGet("regions") });

export const getRegions = async () => {
  if (!getGlobal("regions")) {
    await reloadRegions();
    let labeledRegions = getGlobal("regions")
      .map((x) => {
        const label = x.label || (x.city ? `${x.city}, ${x.state}` : x);
        return {
          value: x._id || x.value || label,
          label: label,
        };
      })
      .concat(stateOptions)
      .concat([
        { label: "International", value: "International" },
        { label: "Other U.S.", value: "Other U.S." },
        { label: "United States", value: "United States" },
      ]);
    setGlobal({ labeledRegions });
  }
  return getGlobal("labeledRegions");
};
