import React, { Component } from "react";
import PropTypes from "prop-types";
import InputText from "../../../../components/input-text";
import Validator from "../../../../components/utils/validator/index";
import Checkbox from "../../../../components/check-box/index";
import "./style.scss";
import Cookies from "js-cookie";

const REFERRAL_VALUES = [
  { id: 1, label: "Carla", value: "Carla" },
  // { id: 2, label: 'Devon', value: 'Devon'},
  { id: 10, label: "Molly", value: "Molly" },
  { id: 3, label: "Other person", value: "" },
  { id: 4, label: "Google Search", value: "Google Search" },
  { id: 5, label: "Yelp", value: "Yelp" },
  { id: 6, label: "Facebook Ad", value: "Facebook Ad" },
  { id: 7, label: "Professional Matchmaker", value: "" },
  { id: 8, label: "Article/PR", value: "" },
  { id: 9, label: "Other", value: "" },
  { id: 11, label: "Instagram", value: "Instagram" },
  { id: 12, label: "Twitter", value: "Twitter" },
  { id: 13, label: "LinkedIn", value: "LinkedIn" },
  { id: 14, label: "Clubhouse", value: "Clubhouse" },
];
class ReferralSources extends Component {
  constructor(props) {
    super(props);
    const hasValue = (optionId) => {
      return props.user.referralSource.find((item) => item.id === optionId);
    };
    this.state = {
      referralSource: props.user.referralSource || [],
      otherPerson: hasValue(3) ? hasValue(3).value : null,
      otherMM: hasValue(7) ? hasValue(7).value : null,
      article: hasValue(8) ? hasValue(8).value : null,
      other: hasValue(9) ? hasValue(9).value : null,
      errors: {},
      validFields: {
        otherPersonValid: hasValue(3) && hasValue(3).value ? true : null,
        otherMMValid: hasValue(7) && hasValue(7).value ? true : null,
        articleValid: hasValue(8) && hasValue(8).value ? true : null,
        otherValid: hasValue(9) && hasValue(9).value ? true : null,
      },
      openView: false,
    };
  }

  componentDidMount() {
    const { validFields, errors, referralSource } = this.state;
    const referral = Cookies.get("referrer");
    if (referral) {
      this.addReferrals(false, REFERRAL_VALUES[2], 3);
      this.setState({
        otherPerson: referral,
        validFields: { ...validFields, otherPersonValid: null },
        errors: { ...errors, otherPersonError: null },
      });
      // this.checkForValidationErrors('name', referralSource, 'otherPersonError', 'otherPersonValid', 3);
      this.props.returnValues([
        ...referralSource,
        { id: 3, label: "Other person", value: referral },
      ]);
    }
  }

  render() {
    const { referralSource, otherPerson, errors, validFields, openView } =
      this.state;
    const isChecked = (id) => {
      return !!referralSource.find((item) => item.id === id);
    };
    const renderLastThreeOptions = this._renderLastThreeOptions(isChecked);

    return (
      <div className="referral-sources flex-column">
        <div className="flex-row">
          <span className="cell-title">{`How did you hear about us?`}</span>
          <span
            className="special-label"
            onClick={() => this.setState({ openView: !openView })}
            style={{ cursor: "pointer" }}
          >
            {"Expand"}
          </span>
          <span
            className={`icon-arrow-down ${openView ? "reverted" : ""}`}
            onClick={() => this.setState({ openView: !openView })}
          />
        </div>
        {openView && (
          <div className={`flex-column referral-sources__content`}>
            <div className="referral-sources__title green">Individual</div>
            <Checkbox
              labelName="Carla"
              checked={isChecked(1)}
              clicked={() =>
                this.addReferrals(isChecked(1), REFERRAL_VALUES[0], 1)
              }
            />
            <Checkbox
              labelName="Molly"
              checked={isChecked(10)}
              clicked={() =>
                this.addReferrals(isChecked(10), REFERRAL_VALUES[1], 10)
              }
            />
            <div className="flex-row">
              <Checkbox
                labelName="Other person"
                checked={isChecked(3)}
                clicked={() =>
                  this.addReferrals(isChecked(3), REFERRAL_VALUES[2], 3)
                }
              />
              <InputText
                wrapperClassName="flex-column two-pixel-margin"
                placeholder="Name"
                disabled={!isChecked(3)}
                isValid={otherPerson && validFields.otherPersonValid}
                onBlur={(e) =>
                  this.checkForValidationErrors(
                    "name",
                    e.target.value,
                    "otherPersonError",
                    "otherPersonValid",
                    3
                  )
                }
                onChange={(e) =>
                  this.setState({
                    otherPerson: e.target.value,
                    validFields: { ...validFields, otherPersonValid: null },
                    errors: { ...errors, otherPersonError: null },
                  })
                }
                value={otherPerson}
              />
            </div>

            <span className="referral-sources__title green">Website</span>
            <Checkbox
              labelName="Google"
              checked={isChecked(4)}
              clicked={() =>
                this.addReferrals(isChecked(4), REFERRAL_VALUES[3], 4)
              }
            />
            <Checkbox
              labelName="Yelp"
              checked={isChecked(5)}
              clicked={() =>
                this.addReferrals(isChecked(5), REFERRAL_VALUES[4], 5)
              }
            />
            <Checkbox
              labelName="Facebook"
              checked={isChecked(6)}
              clicked={() =>
                this.addReferrals(isChecked(6), REFERRAL_VALUES[5], 6)
              }
            />
            <Checkbox
              labelName="Instagram"
              checked={isChecked(11)}
              clicked={() =>
                this.addReferrals(isChecked(11), REFERRAL_VALUES[9], 11)
              }
            />
            <Checkbox
              labelName="Twitter"
              checked={isChecked(12)}
              clicked={() =>
                this.addReferrals(isChecked(12), REFERRAL_VALUES[10], 12)
              }
            />
            <Checkbox
              labelName="LinkedIn"
              checked={isChecked(13)}
              clicked={() =>
                this.addReferrals(isChecked(13), REFERRAL_VALUES[11], 13)
              }
            />
            <Checkbox
              labelName="Clubhouse"
              checked={isChecked(14)}
              clicked={() =>
                this.addReferrals(isChecked(14), REFERRAL_VALUES[12], 14)
              }
            />

            {renderLastThreeOptions}
          </div>
        )}
      </div>
    );
  }

  _renderLastThreeOptions = (isChecked) => {
    const { otherMM, article, other, errors, validFields } = this.state;
    return (
      <div>
        <div className="flex-row special-ckb">
          <Checkbox
            labelName="Professional Matchmaker"
            checked={isChecked(7)}
            clicked={() =>
              this.addReferrals(isChecked(7), REFERRAL_VALUES[6], 7)
            }
          />
          <InputText
            wrapperClassName="flex-column two-pixel-margin"
            placeholder="Name"
            disabled={!isChecked(7)}
            isValid={otherMM && validFields.otherMMValid}
            onBlur={(e) =>
              this.checkForValidationErrors(
                "name",
                e.target.value,
                "otherMMError",
                "otherMMValid",
                7
              )
            }
            onChange={(e) =>
              this.setState({
                otherMM: e.target.value,
                validFields: { ...validFields, otherMMValid: null },
                errors: { ...errors, otherMMError: null },
              })
            }
            value={otherMM}
          />
        </div>

        <div className="flex-row">
          <Checkbox
            labelName="Article/PR"
            checked={isChecked(8)}
            clicked={() =>
              this.addReferrals(isChecked(8), REFERRAL_VALUES[7], 8)
            }
          />
          <InputText
            wrapperClassName="flex-column two-pixel-margin"
            placeholder="Specifics"
            disabled={!isChecked(8)}
            isValid={article && validFields.articleValid}
            onBlur={(e) =>
              this.checkForValidationErrors(
                "name",
                e.target.value,
                "articleError",
                "articleValid",
                8
              )
            }
            onChange={(e) =>
              this.setState({
                article: e.target.value,
                validFields: { ...validFields, articleValid: null },
                errors: { ...errors, articleError: null },
              })
            }
            value={article}
          />
        </div>

        <div className="flex-row">
          <Checkbox
            labelName="Other"
            checked={isChecked(9)}
            clicked={() =>
              this.addReferrals(isChecked(9), REFERRAL_VALUES[8], 9)
            }
          />
          <InputText
            wrapperClassName="flex-column two-pixel-margin"
            placeholder="Specifics"
            disabled={!isChecked(9)}
            isValid={other && validFields.otherValid}
            onBlur={(e) =>
              this.checkForValidationErrors(
                "name",
                e.target.value,
                "otherError",
                "otherValid",
                8
              )
            }
            onChange={(e) =>
              this.setState({
                other: e.target.value,
                validFields: { ...validFields, otherValid: null },
                errors: { ...errors, otherError: null },
              })
            }
            value={other}
          />
        </div>
      </div>
    );
  };

  addReferrals = (checked, value, optionId) => {
    const { referralSource } = this.state;
    // const { _id: userId } = this.props.user;
    let dataToSave = referralSource;
    if (!checked) {
      this.setState({ referralSource: [...referralSource, value] });
      dataToSave = [...referralSource, value];
    } else {
      this.setState({
        referralSource: referralSource.filter((item) => item.id !== optionId),
      });
      dataToSave = referralSource.filter((item) => item.id !== optionId);
      if (optionId === 3) {
        this.setState({ otherPerson: null });
      }
      if (optionId === 7) {
        this.setState({ otherMM: null });
      }
      if (optionId === 8) {
        this.setState({ article: null });
      }
      if (optionId === 9) {
        this.setState({ other: null });
      }
    }
    this.props.returnValues(dataToSave);
    // this.props.saveUserFieldData(userId, 'referralSource', dataToSave);
  };

  checkForValidationErrors = (
    type,
    value,
    stateError,
    validField,
    optionId
  ) => {
    const { referralSource } = this.state;
    // const { _id: userId } = this.props.user;
    const valid = Validator.validateName(value);
    this.setErrorsOrValids(valid, validField, stateError, "Invalid");
    if (valid) {
      const newReferralSource = [...referralSource];
      const customVal = referralSource.find((item) => item.id === optionId);
      const customValIndex = referralSource.findIndex(
        (item) => item.id === optionId
      );
      customVal.value = value;
      newReferralSource[customValIndex] = customVal;
      this.setState({ referralSource: newReferralSource });
      // this.props.saveUserFieldData(userId, 'referralSource', newReferralSource);
      this.props.returnValues(newReferralSource);
    }
  };

  setErrorsOrValids = (valid, validField, stateError, message) => {
    const { validFields, errors } = this.state;
    valid
      ? this.setState({ validFields: { ...validFields, [validField]: true } })
      : this.setState({ errors: { ...errors, [stateError]: message } });
  };
}

ReferralSources.propTypes = {
  returnValues: PropTypes.func,
  user: PropTypes.object,
};

export default ReferralSources;
