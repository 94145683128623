export const HAS_KIDS = [
    { value: "Yes", label: "Yes", id: 1 },
    { value: "No", label: "No", id: 2 },
  ];

  export const FUTURE_CHILD_SCENARIOS_WITH_NO_KIDS = [
    {
        value: "Having my own kids",
        label: "Having my own kids",
        id: 1,
    },
    {
        value: "A partner's kids if they want/are open to more biologically",
        label: "A partner's kids if they want/are open to more biologically",
        id: 3,
    },
    {
        value: "A partner's kids if they don't want more biologically",
        label: "A partner's kids if they don't want more biologically",
        id: 5,
    },
    {
        value: "Adopting/fostering",
        label: "Adopting/fostering",
        id: 2,
    },
    {
        value: "No kids/no more kids",
        label: "No kids/no more kids",
        id: 4,
    },
  ];

  export const FUTURE_CHILD_SCENARIOS_WITH_KIDS = [
    {
        value: "Having more of my own kids",
        label: "Having more of my own kids",
        id: 1,
    },
    {
        value: "A partner's kids if they want/are open to more biologically",
        label: "A partner's kids if they want/are open to more biologically",
        id: 3,
    },
    {
        value: "A partner's kids if they don't want more biologically",
        label: "A partner's kids if they don't want more biologically",
        id: 5,
    },
    {
        value: "Adopting/fostering",
        label: "Adopting/fostering",
        id: 2,
    },
    { 
        value: "No kids/no more kids", 
        label: "No kids/no more kids", 
        id: 4
    },
  ];

  export const HAS_FROZEN_EGGS_SPERM = [
    { value: "Yes", label: "Yes", id: 1 },
    { value: "No", label: "No", id: 2 },
  ];

  export const FAMILY_CLOSENESS = [
    { value: "Very close with everyone", label: "Very close with everyone", id: 1 },
    { value: "Very close with most of them", label: "Very close with most of them", id: 2 },
    { value: "Pretty close with them", label: "Pretty close with them", id: 3 },
    { value: "Unfortunately not close", label: "Unfortunately not close", id: 4 },
  ];

  export const FAMILY_LOAN = [
    { 
        value: "Often, whenever they need it",
        label: "Often, whenever they need it",
        id: 1 
    },
    {
        value: "Sometimes, only as long as we don't go to debt",
        label: "Sometimes, only as long as we don't go to debt",
        id: 2,
    },
    {
        value: "Seldom, only when absolutely necessary",
        label: "Seldom, only when absolutely necessary",
        id: 3,
    },
    {
        value: "Never, people need to be self-sufficient",
        label: "Never, people need to be self-sufficient",
        id: 4,
    },
  ];