import React, {useState} from "react";
import {tableSchemas} from "src/constants/tables-schemas";
import {DropDown} from "src/components/widgets";
import {UserAction} from "src/components/Table/Row/UserAction";
import {Column} from "src/components/Table/Column/Column";
import {postReload} from "src/api/api";


const approveMM = async (mm) => {
    await postReload(
        `/approve-mm/${mm._id}`,
        `${mm.firstName} ${mm.lastName} approved`
    );
}
const approveAmb = async (mm) => {
    await postReload(
        `/approve-amb/${mm._id}`,
        `${mm.firstName} ${mm.lastName} approved`
    );
}
const cancelMMPlan = async (mm) => {
    await postReload(`/cancel-mm-plan/${mm._id}`, {
        message: {
            type: "error",
            text: `${mm.firstName} ${mm.lastName}'s plan has been cancelled`,
        },
    });
};
const restoreMMPlan = async (mm) => {
    await postReload(
        `/restore-mm-plan/${mm._id}`,
        `${mm.firstName} ${mm.lastName}'s plan has been restored`
    );
};
const rejectMM = async (mm) => {
    await postReload(`/reject-mm/${mm._id}`, {
        message: {
            type: "error",
            text: `${mm.firstName} ${mm.lastName} rejected`,
        },
        confirm: `Are you sure you want to reject ${mm.firstName} ${mm.lastName}?`,
    });
}

export const MMRow = ({ user, attributes, onSelectUser }) => {

    const [menu, setMenu] = useState(false);
    const schema = tableSchemas.matchmaker;

    const showMenu = (e) => {
        e.stopPropagation();
        setMenu(true);
    };

    return (
        <tr
            className="user-row"
            key={`mm-row-${user._id}`}
            onMouseLeave={() => setMenu(false)}
        >
            <Column {...{ schema, attributes, user }} />
            <td onClick={showMenu} className="actions">
                <span className="icon-more" />
                <div className="action-wrapper">
                    <DropDown className="action-menu" open={menu}>
                        <UserAction
                            icon="eye"
                            onClick={() => onSelectUser && onSelectUser(user)}
                        >
                            Referrals & Credits
                        </UserAction>
                        {user.approved && (
                            <UserAction
                                icon="edit"
                                onClick={() => history.push(`/maker-details?id=${user._id}`)}
                            >
                                Edit Matchmaker
                            </UserAction>
                        )}
                        {!user.approved && (
                            <UserAction icon="check" onClick={() => approveMM(user)}>
                                Approve Matchmaker
                            </UserAction>
                        )}
                        {!user.approved && (
                            <UserAction icon="check" onClick={() => approveAmb(user)}>
                                Approve Ambassador
                            </UserAction>
                        )}
                        {user.approved && !user.planCanceled && (
                            <UserAction icon="ban" onClick={() => cancelMMPlan(user)}>
                                Cancel Plan
                            </UserAction>
                        )}
                        {user.planCanceled && (
                            <UserAction icon="check" onClick={() => restoreMMPlan(user)}>
                                Restore Plan
                            </UserAction>
                        )}

                        {!user.approved && (
                            <UserAction icon="ban" onClick={() => rejectMM(user)}>
                                Reject Matchmaker
                            </UserAction>
                        )}
                        {user.approved && (
                            <UserAction icon="times" onClick={() => rejectMM(user)}>
                                Remove Matchmaker
                            </UserAction>
                        )}
                    </DropDown>
                </div>
            </td>
        </tr>
    );
}