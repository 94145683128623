import { setGlobal, pageIds, pageProps } from "launchpad";
import { refreshRoutes } from "launchpad/routes";

// ==================   "pre-flight" checks =======================
// Ensures page ids and urls aren't duplicated, may add more tests as we go

// TODO: may be better to create a logged warning than crash the system for
// production, and hide "checks passed" message

export const launchpadError = (msg) => {
  throw `
====================================
      ⚠️ Launchpad Error ⚠️
====================================

${msg}

====================================
      ⚠️ Launchpad Error ⚠️
====================================

`;
};

// ensure page ids and urls aren't duplicated
const check_unique = () => {
  let idMap = {};
  let urlMap = {};
  let titleMap = {};
  pageIds.forEach((page) => {
    if (!page.id) {
      launchpadError(
        `Page titled "${page.title}" is missing an id prop, please provide one \n(e.g. "<Page id='my-page'>" )`
      );
    }
    if (page.id) {
      if (idMap[page.id]) {
        launchpadError(
          `Pages at ${idMap[page.id]} and ${
            page.url
          } contain the duplicated page id '${page.id}'`
        );
      }
      idMap[page.id] = page.url;
    }
    if (page.url) {
      if (urlMap[page.url]) {
        launchpadError(
          `Pages with ids "${urlMap[page.url]}" and "${
            page.id
          }" are both attempting to be located at the url '${page.url}'`
        );
      }
      urlMap[page.url] = page.id;
    }
    if (page.title) {
      if (titleMap[page.title]) {
        launchpadError(
          `Pages with ids "${titleMap[page.title]}" and "${
            page.id
          }" are both attempting to use the title '${page.title}'`
        );
      }
      titleMap[page.title] = page.id;
    }
  });
};

// run pre-flight checks
export const run_preflight = () => {
  check_unique();
  console.log("pre-flight checks passed ✅");
};
