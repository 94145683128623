import React from "react";
import { hot } from "react-hot-loader";

import { Route, Router } from "react-router-dom";
import {
  setGlobal,
  getGlobal,
  applyMeta,
  ModalContainer,
  checkAuth,
  Loading,
  apiGet,
} from "launchpad";
import { OptionsContainer } from "sections/options";

import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

import {
  ConfirmModal,
  NotificationContainer,
  LoadIndicator,
  ImageUploader,
} from "launchpad/helpers";
import { FadeContainer, getFadeTarget } from "react-move-stuff";

import AutoRoutes from "launchpad/AutoRoutes";
import { refreshRoutes } from "launchpad/routes";
import { default as AdminAppHeader } from "_old/src/components/main-header";
import { default as AppHeader } from "./components/layouts/MainHeader";

import loadable from "@loadable/component";
import styled from "styled-components";

// TODO: eventually remove these dependencies, redux is overkill
import Root from "_old/src/Root";
import configureStore, { history } from "_old/src/store/configureStore";

export { history } from "_old/src/store/configureStore";
const store = configureStore;

import "_old/src/styles/styles.scss";
import "_old/src/containers/style.scss";
import "_old/src/components/main-header/style.scss";
import UserSession from "_old/src/components/user-session";

// TODO: remove dependencies on old api interaction, need to rebuild matchmaker routes
import Api from "_old/src/actions/api";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer/Footer";
import MediaQueryProvider from "./components/MediaQueryProvider";
import {
  FOR_TABLET_DOWN_QUERY,
  MARKETING_NAVBAR_ITEMS,
  NAVBAR_ITEMS,
} from "./data/constants";

// import{setConfig} from 'react-hot-loader'
// setConfig({logLevel: 'debug'})

// scroll to the top when navigating so page changes aren't disorienting
// export const history = createBrowserHistory();
history.listen(() => {
  window.scrollTo(0, 0);
  const ftgt = getFadeTarget();
  if (ftgt) ftgt.scrollTop = 0;
  setTimeout(() => {
    window.scrollTo(0, 0);
    const ftgt = getFadeTarget();
    if (ftgt) ftgt.scrollTop = 0;
  }, 300);
  if (getGlobal("mainHamburgerOpen")) setGlobal({ mainHamburgerOpen: false });
  applyMeta();
  if (window.registerPageView) {
    window.registerPageView();
  }
});

setGlobal({ editImages: true });

// a few keyboard shortcuts for common tasks and "super admin" functionality
function checkKeys(e) {
  if (getGlobal("is_admin") || getGlobal("doAdminToggle")) {
    var evtobj = window.event ? event : e;

    // use ctrl + e to activate global image editing
    if (evtobj.keyCode === 69 && evtobj.ctrlKey) {
      setGlobal({ editImages: !getGlobal("editImages") });
    }

    // use ctrl + e to activate global image editing
    if (evtobj.keyCode === 65 && evtobj.ctrlKey && evtobj.shiftKey) {
      if (getGlobal("is_admin") || getGlobal("doAdminToggle"))
        setGlobal({ is_admin: !getGlobal("is_admin"), doAdminToggle: true });
    }

    if (evtobj.keyCode === 71 && evtobj.ctrlKey) {
      const pane = getGlobal("app").contextPane;
      if (pane && pane.showPageSelector) pane.showPageSelector();
    }
  }
}

document.onkeydown = checkKeys;

const MUItheme = createTheme({
  palette: {
    primary: {
      main: "#65c4c0",
    },
    secondary: {
      main: "#65c4c0",
    },
  },
  typography: {
    useNextVariants: true,
  },
});

const GlobalStyles = styled.div`
  flex: 1;
  display: flex;

  .devMessage {
    position: fixed;
    bottom: 0;
    left: 0;
    background: rgba(150, 0, 0, 0.8);
    padding: 10px 30px;
    color: white;
  }

  .offline {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;

    h2 {
      margin-bottom: 30px;
    }
  }
`;

const MainContent = styled.main`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
`;

export const reloadUser = () => {
  return new Promise((resolve) => {
    // syncs old and new auth methods
    // TODO: refactor so this is no longer necessary
    Api.get({
      url: "/api/get-current-user",
      callback: async () => {
        const activeUser = await apiGet("check-user-status");
        setGlobal({ activeUser });
        resolve(activeUser);
      },
    });
  });
};

// Main app class
class App extends React.Component {
  state = {
    is_admin: false,
  };

  contextPane = (<span />);
  mediaLibrary = (<span />);
  adminBar = (<span />);

  UNSAFE_componentWillMount() {
    checkAuth();
    reloadUser().then(() => refreshRoutes());
  }

  componentDidMount() {
    setGlobal({ mainDomRoot: this.root, app: this }, false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
        (getGlobal("is_admin") || getGlobal("user_access") === "admin") &&
        !this.state.is_admin
    ) {
      this.setState({ is_admin: true });
      const MediaLibrary = loadable(() =>
          import("launchpad/admin/MediaLibrary")
      );
      const ContextPane = loadable(() => import("launchpad/admin/ContextPane"));
      const AdminBar = loadable(() => import("launchpad/admin/AdminBar"));
      this.mediaLibrary = <MediaLibrary fallback={<Loading active={true} />} />;
      this.contextPane = <ContextPane fallback={<Loading active={true} />} />;
      this.adminBar = <AdminBar fallback={<Loading active={true} />} />;
    }
  }

  render() {
    // set conditions for "dev mode" (displays warnings to avoid accidental deployment)
    const devMode = false;
    const is_admin =
        getGlobal("is_admin") || getGlobal("user_access") === "admin";
    if (getGlobal("noAPIConnection"))
      return (
          <GlobalStyles>
            <div ref={(el) => (this.root = el)} className="offline">
              <h2>Sorry, it looks like we're offline at the moment</h2>

              <p>
                We're either performing maintenance or experiencing technical
                difficulties. Either way, we'll be back shortly!
              </p>
            </div>
          </GlobalStyles>
      );

    const userIsLoggedIn = UserSession.hasValidToken();
    const userRole = UserSession.getToken().role;

    return (
        <MuiThemeProvider theme={MUItheme}>
          <MediaQueryProvider query={FOR_TABLET_DOWN_QUERY}>
            {(isTabletDown) => (
                <GlobalStyles>
                  {/* <Router history={history}> */}

                  {/* TODO: remove redux dependency */}
                  {/* Root includes redux connected router */}
                  <Root store={store} history={history}>
                    {/* maybe move to <LaunchpadWrapper /> or similar to provide classes */}
                    <Router history={history}>
                      <FadeContainer
                          className={
                              "root-wrapper" +
                              (getGlobal("editImages") ? " edit-images " : "") +
                              (getGlobal("openContext") && is_admin
                                  ? " context-open "
                                  : "") +
                              (getGlobal("pageContext") || "generic") +
                              "-wrapper" +
                              (is_admin ? " is-admin" : " test")
                          }
                          id="rootWrapper"
                          ref={(el) => (this.root = el && el.el)}
                          style={{}}
                      >

                        {userIsLoggedIn &&
                            (isTabletDown ? (
                                <NavBar
                                    history={history}
                                    options={NAVBAR_ITEMS[userRole]}
                                />
                            ) : (
                                <AppHeader />
                            ))}
                        <NotificationContainer />
                        <ConfirmModal />
                        <ImageUploader />
                        {/*launchpad stuff that we'll eventually remove*/}
                        {this.contextPane}
                        {this.adminBar}
                        {this.mediaLibrary}
                        <MainContent>
                          {!UserSession.hasValidToken() && (
                              <NavBar
                                  options={MARKETING_NAVBAR_ITEMS}
                                  history={history}
                              />
                          )}
                          <AutoRoutes />
                          {!UserSession.hasValidToken() && (
                              <Footer history={history} />
                          )}
                        </MainContent>
                        <OptionsContainer />
                        <ModalContainer />
                        <LoadIndicator />
                        {devMode && (
                            <div className="devMessage">
                              WARNING!! This app is in testing mode, please change
                              global_config before pushing to production
                            </div>
                        )}
                      </FadeContainer>
                    </Router>
                  </Root>
                  {/* </Router> */}
                </GlobalStyles>
            )}
          </MediaQueryProvider>
        </MuiThemeProvider>
    );
  }
}

export default hot(module)(App);
// export default hot(App);
// export default App
//// if(module.hot) module.hot.accept()
