.tool-tip-component {
    border: 1px solid rgba(84,88,90,0.1);
    border-radius: 2px;
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(84,88,90,0.2);
    max-width: 400px;
    position: absolute;
    padding: 21px;
    right: 0;
    top: 26px;
    z-index: 8;
    &__text {
        color: #54585A;
        font-family: Montserrat, sans-serif;
        font-size: 14px;
    }
    .icon-close-button {
        position: absolute;
        right: 5px;
        bottom: 5px;
        font-size: 14px;
    }
}