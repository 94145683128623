import Api from "./api";
import * as types from "../constants/actionTypes";

export const approveMatchMaker = (matchMakerId) => {
  return Api.post({
    url: "/api/approve-matchmaker",
    data: { matchMakerId },
    responseAction: types.APPROVED_MATCHMAKER,
  });
};

export const rejectMatchMaker = (matchMakerId) => {
  return Api.post({
    url: "/api/reject-matchmaker",
    data: { matchMakerId },
    responseAction: types.REJECT_MATCHMAKER,
  });
};

export const updateMatchMaker = (matchmaker) => {
  return Api.post({
    url: "/api/update-matchmaker",
    data: { matchmaker },
  });
};

export const loadPlans = () => {
  Api.get({
    url: "/api/plans",
    responseAction: types.LOAD_PLANS,
  });
};

export const updatePlan = (plan) => {
  return Api.post({
    url: "/api/plan",
    data: plan,
    requestAction: types.SAVING,
    responseAction: types.PLAN_SAVED,
  });
};

export const assignPlanToMatchmaker = (matchmakerId, planId) => {
  return Api.post({
    url: "/api/assign-plan",
    data: {
      matchmaker_id: matchmakerId,
      plan_id: planId,
    },
  });
};

export const saveUserFieldDataFromAdmin = (userId, field, value) => {
  return () => {
    Api.post({
      url: "/api/save-user-field-data",
      data: { userId, field, value },
      responseAction: types.SAVE_USER_FIELD_DATA_FROM_ADMIN,
    });
  };
};

export const inactivateUser = (id) => {
  return () => {
    Api.post({
      url: "/api/inactivate-user",
      data: { id },
      responseAction: types.USER_INACTIVATE,
    });
  };
};

export const getClickedUserInfoAdmin = (userId) => {
  return () => {
    Api.get({
      url: `/api/get-user/${userId}`,
      responseAction: types.LOAD_USER_INFO_ADMIN,
    });
  };
};

export const setDeletePhotos = (actionType, userId, photo) => {
  return () => {
    Api.post({
      url: "/api/set-delete-photo",
      data: { actionType, userId, photo },
      responseAction: types.SET_DELETE_PHOTO,
    });
  };
};

export const deletePhotos = (userId, photos) => {
  return () => {
    Api.post({
      url: "/api/delete-photos",
      data: { userId, photos },
      responseAction: types.SET_DELETE_PHOTO,
    });
  };
};

export const sendChangePlanEmail = (currentPlan, selectedPlan, user) => {
  return () => {
    Api.post({
      url: "/api/change-matchmaker-plan",
      data: { currentPlan, selectedPlan, user },
      responseAction: types.CHANGE_MM_PLAN,
    });
  };
};

export const AdminRateSelectedUser = (userId, score) => {
  return Api.post({
    url: "/api/rate-user",
    data: { userId, score },
    responseAction: types.RATE_USER,
  });
};

export const createNote = (userId, note) => {
  return () =>
    Api.post({
      url: `/api/note/${userId}`,
      data: { note },
      responseAction: types.CREATE_NOTE,
    });
};

export const getUserNotes = (userId) => {
  return () =>
    Api.get({
      url: `/api/note/${userId}`,
      responseAction: types.GET_USER_NOTES,
    });
};

export const updateNote = (noteId, note) => {
  return () =>
    Api.put({
      url: `/api/note/${noteId}`,
      data: { newNote: note },
      responseAction: types.UPDATE_NOTE,
    });
};

export const addMatchMakerFilter = (filter) => {
  return (dispatch) =>
    dispatch({
      type: types.ADD_MM_FILTER,
      filter,
    });
};

export const setShowMatchmakers = (showMatchmakers) => {
  return (dispatch) =>
    dispatch({
      type: types.SET_MM_SHOW,
      showMatchmakers,
    });
}
