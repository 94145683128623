import React, { Component } from "react";
import PropTypes from "prop-types";
import { PRIVACY_POLICY } from "../../constants/terms-and-policies";
import { REPEAT_SECTION_TWO } from "../home-pages/texts";
import { repeatingSectionTwo } from "../home-pages/components";

import "./style.scss";

class PrivacyPolicy extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { history } = this.props;
    const intro = this._renderIntro();
    const sections = this._renderSections();
    return (
      <div className="policy-page-content flex-column">
        <span
          className="icon-back-button flex-center"
          onClick={() => history.push("/")}
        />
        {repeatingSectionTwo(
          REPEAT_SECTION_TWO.privacyPageFirst.title,
          null,
          null,
          null,
          null,
          null
        )}
        {intro}
        {sections}

        <div className="flex-column" style={{ marginBottom: "50px" }}>
          <span className="text small">DateSpot, LLC</span>
          <span className="text small">
            130 Oakview Drive, San Carlos, CA 94070
          </span>
          <span className="text small">support@datespot.love</span>
        </div>
      </div>
    );
  }

  _renderIntro = () => {
    return (
      <div className="policy-page-content__section flex-column">
        <div className="text">Last Updated: as of March 9, 2021</div>
        {PRIVACY_POLICY.introduction.map((intro, i) => {
          return (
            <div className="text" key={i}>
              {intro.text}
            </div>
          );
        })}
      </div>
    );
  };
  _renderSections = () => {
    return (
      <div>
        {PRIVACY_POLICY.sections.map((sec, s) => {
          return (
            <div className="policy-page-content__section flex-column" key={s}>
              <div className="policy-page-content__subtitle">{sec.title}</div>
              <div className="text">{sec.text}</div>
              {sec.text1 && <div className="text">{sec.text1}</div>}
              {sec.text2 && <div className="text">{sec.text2}</div>}
              {sec.text3 && <div className="text">{sec.text3}</div>}
              {sec.text4 && <div className="text">{sec.text4}</div>}
              {sec.text5 && <div className="text">{sec.text5}</div>}
              {sec.text6 && <div className="text">{sec.text6}</div>}
              {sec.text7 && <div className="text">{sec.text7}</div>}
              {sec.text8 && <div className="text">{sec.text8}</div>}
              {sec.text9 && <div className="text">{sec.text9}</div>}
              {sec.text10 && <div className="text">{sec.text10}</div>}
            </div>
          );
        })}
      </div>
    );
  };
}

PrivacyPolicy.propTypes = {
  history: PropTypes.object,
};

export default PrivacyPolicy;
