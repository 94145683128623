import React, { Component } from "react";
import PropTypes from "prop-types";
import "./style.scss";

class Button extends Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    customClass: PropTypes.string,
    click: PropTypes.func,
    disabled: PropTypes.bool,
    onEnterKeyPressed: PropTypes.func,
  };

  enterPressed = (ev) => {
    const code = ev.keyCode || ev.which;
    if (code === 13) {
      this.props.onEnterKeyPressed();
    }
  };

  render() {
    const { value, click, disabled, customClass, onEnterKeyPressed } =
      this.props;
    let buttonClass = "button";
    if (customClass) {
      buttonClass += ` ${customClass}`;
    }

    return (
      <button
        onClick={!disabled ? click : () => {}}
        disabled={disabled ? true : undefined}
        onKeyUp={() => onEnterKeyPressed && this.enterPressed()}
        className={buttonClass}
      >
        <span className="value">{value}</span>
      </button>
    );
  }
}

export default Button;
