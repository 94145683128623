import kindness from "./assets/home-page-values/kindness.svg";
import authenticity from "./assets/home-page-values/authenticity.svg";
import transparency from "./assets/home-page-values/transparency.svg";
import inclusion from "./assets/home-page-values/inclusion.svg";
import moreMatches from "./assets/how-we-help-matchmakers/more-matches.svg";
import profiles from "./assets/how-we-help-matchmakers/comprenhensive-profiles.svg";
import filtering from "./assets/how-we-help-matchmakers/advanced-filtering.svg";
import relationships from "./assets/how-we-help-matchmakers/updated-status.svg";

export const list = [
  {
    value:
      " ● Access to a massive database of singles. " +
      "We'll only launch in a certain area when we have at least 3,000 \t   members in that region. ",
  },
  { value: " ● Proprietary data and analytics" },
  { value: " ● Tips for sales and client service" },
  {
    value:
      " ● Template for sharing profiles in a separate database with partner matchmaking companies",
  },
  { value: " ● New client leads" },
  { value: " ● Customer relationship management software" },
];

export const elements = [
  {
    id: 1,
    title: "Kindness",
    text1:
      "While matchmaking clients tend to be busy professionals, we’re here to make real, " +
      "lifelong connections with people who are looking for love.",
    text2:
      "We believe that any engagement with other people should as be considerate and respectful as possible.",
    background: "#fff",
    image: kindness,
  },
  {
    id: 2,
    title: "Transparency",
    text1:
      "We are committed to being transparent to our members — including who we work with and how we operate. " +
      "We also display exactly how many members we have in our database, both " +
      "total as well as verified single within the month.",
    text2:
      "We also expect honesty from our members about who they are, their relationship status, etc.. " +
      "There is zero tolerance for fake profiles and we have practices in place to " +
      "help ensure that all profiles are real individuals.",
    background: "#fff",
    image: transparency,
  },
  {
    id: 3,
    title: "Authenticity",
    text1:
      "We like people who are themselves - real and genuine. We hope you do too.",
    text2:
      "Instead of displaying stock photos of actors in love, we decided to do things differently — all of " +
      "the photos of couples on our website are of real couples who we actually know. " +
      "We think this demonstrates the right thing: true love.",
    background: "#fff",
    image: authenticity,
  },
  {
    id: 4,
    title: "Inclusion",
    text1:
      "Everyone deserves love. We're not elitist and we don't discriminate. Anyone can use our site to " +
      "get a recommendation for the dating solution for them, and can sign up to be" +
      " a potential match for matchmaking clients. " +
      "We enjoy helping everyone find their right partner.",
    text2: "",
    background: "#fff",
    image: inclusion,
  },
];

export const REPEAT_SECTION_TWO = {
  homePageFirst: {
    title: "Free Modern Matchmaking",
  },
  homePageSecond: {
    title:
      "Overwhelmed with dating options or \nunderwhelmed by the people you're meeting?\nLet us break it down for you.",
    text:
      "We help save you time in finding a truly compatible partner by allowing you to join " +
      "our private database of singles worldwide" +
      ", so you can be matched with relationship-minded matchmaking clients in your area. You'll be available to be matched" +
      " completely free across multiple companies without having to research companies and waste " +
      "time uploading your information to all of them.",
    secondText:
      "All the matchmakers we partner with are individually vetted and incredibly reputable. " +
      "We've done the work for you in researching!",
    thirdText:
      "It's comprehensive matchmaking that's easy and doesn't require writing a witty profile or spending " +
      "countless hours messaging back and forth with multiple people on an app. With DateSpot's passive matchmaking, " +
      "it's not member-to-member connections...you will receive matches when a matchmaker identifies you as someone " +
      "they want to contact for a current or future client now. It's dating with a purpose that you don't have to work for.",
  },
  homePageThird: {
    title: "For Existing and Prospective Matchmakers",
    text: "Are you a Matchmaker? Be able to do your job easier by accessing our database of singles.",
  },
  homePageFourth: {
    title: "OUR VALUES",
  },
  homePageFive: {
    title: "WHO ARE OUR MEMBERS",
    text: "You may be wondering who our DateSpot members are. They span all ages, geographies, interests and more, as we welcome everyone to our site. But they tend to be busy professionals age 25 - 65, about equal between men and women. When you join, your profile and photos will not be visible to other DateSpot members, only to matchmakers. But some of our awesome members were willing to display their photos here to welcome you and show our membership diversity. Thanks, guys!",
  },
  matchmakerFirst: {
    title: "HOW WE HELP MATCHMAKERS",
    text: "We understand the needs of Matchmakers today. You want more clients (who are going to be easy enough to work with). You want more great match options for your clients. And you want more information about match candidates to save you time.",
    // text: 'We understand the needs of Matchmakers today. ' +
    // 'You want prospective clients who are going to be fun, open-minded and easy to work with. ' +
    // 'You want more options for matches and dates. ' +
    // 'And you want the right match quickly, so you could use a lot of information about the candidates ahead of time.'
  },
  matchmakerSecond: {
    title: "PRICING",
    // text: 'We make quality matches affordable through a simple pay-per-lead model, with no monthly or annual commitment. '+ 'It\'s efficient and effective as you only pay for people who respond they are single at the time of your contact request.',
    // secondText: 'Connection with DateSpot Premium Member: Free\n'
    // + 'Free route member identified by you: $100\n'
    // + 'Free route member identified by DateSpot: $200\n'
    // + '\n'
    // + 'There is also the ability to send your client\'s profile before deciding to pay for a connection. If the member is interested in your client, you pay $50 more for the connection. If they\'re not interested, you pay nothing.'
    text: "We make quality matches affordable through a simple pay-per-lead or pay-per-date model, based on your preference. There is no monthly or annual commitment. It's efficient and effective as you only pay for people who respond that they are single at the time of your contact request. ",
    secondText:
      "There is also the ability to send your client's profile before deciding to pay for a connection. If they're not interested, you pay nothing.",
  },
  matchmakerThird: {
    title: "EXCLUSIVE CONTACT WITH INDIVIDUALS",
    text:
      "We also give you the ability to maintain exclusive contact with certain members so that" +
      " other Matchmakers cannot contact them and complicate or jeopardize a budding relationship.",
  },
  matchmakerSpreadLove: {
    title: "SPREAD THE LOVE",
    text:
      " We look forward to helping you be successful with one of the most fun and rewarding careers in " +
      "existence, help you make more money, and help to bring love to as many people as possible!",
  },
  singlesPageFirst: {
    title: "Why Should I Connect To Matchmakers?",
  },
  singlesPageSecond: {
    title: "But Is Matchmaking for Me?",
    text:
      "Paid matchmaking has a history of being only for the wealthy, and a stigma of being for people who are " +
      "desperate or can't get a date. But that has definitely changed. With the advent of modern matchmaking practices " +
      "and matchmaking being more widely accepted like online dating became, matchmaking has really " +
      "become accessible and appropriate for everyone. I've successfully matched people of all types, looks, " +
      "personalities, ages and financial situations. Most of us are highly selective and matchmaking can " +
      "be highly effective in narrowing down what you're looking for.",
  },
  singlesPageThird: {
    title: "How does it work?",
  },
  singlesWhatWeDo: {
    title: "What we do",
    text:
      "We make it easier for you to find love by connecting you to relationship-minded individuals who are " +
      "already working with matchmaking companies. We get it, you're highly selective and you don't " +
      "need help finding a date. But matchmaking isn't about getting dates. It's about getting to the right person. " +
      "And our matchmaking is completely free, so you have nothing to lose!",
  },
  singlesPageFourth: {
    title: "Want more matches?",
  },
  singlesPageFifth: {
    title: "Our partner matchmakers",
  },
  teamPageFirst: {
    title: "TEAM",
  },
  faqPageFirst: {
    title: "FAQ",
  },
  termsPageFirst: {
    title: "TERMS OF SERVICE",
  },
  privacyPageFirst: {
    title: "PRIVACY POLICY",
  },
  mmAgreementPageFirst: {
    title: "Partner Matchmaker Agreement",
  },
};

export const MATCHMAKER_SECTION_THREE = [
  {
    title: "More Matches",
    text:
      "Access to a massive nationwide database. DateSpot is your go-to database for " +
      "comprehensive profiles of male and female singles of all ages nationwide.",
    svg: moreMatches,
    extra: true,
    marginRight: true,
  },
  {
    title: "Comprehensive Profiles",
    text:
      "We don’t just stop at basic member info that people can find on the online dating apps." +
      " We ask all the questions you and your clients would be curious about, doing almost all of the interview for you.",
    svg: profiles,
    extra: false,
    marginLeft: true,
  },
  {
    title: "Advanced Filtering",
    text:
      "Use our comprehensive set of filters to hone in on a truly compatible person for each of your clients. " +
      "This allows you to do your job better and find the right matches faster!",
    svg: filtering,
    extra: false,
    marginRight: true,
  },
  {
    title: "Client Referrals",
    text:
      "DateSpot helps singles find the right dating solutions for them. " +
      "Receive referrals of potential matchmaking clients based on geography, client needs, and your areas of expertise. ",
    svg: relationships,
    extra: false,
    marginLeft: true,
  },
];

export const PARTNERS = {
  left: [
    "BAM Connections",
    "Berkeley Intl.",
    "Bloom Matchmaking",
    "Caliber Match",
    "Cinqe",
    "The Communication Studio",
    "Connectricity",
    "Courtney Kay Connections",
    "Corey Lynn Agency",
    "Elite Private Search",
    "Elite Connections",
    "Executive Matchmakers",
    "Fern Connections",
    "Find Love At Last",
    "Find The One",
    "Gayquation",
    "It’s Just Lunch",
    "Joy of Romance",
  ],
  right: [
    "Keeper Introduction Services",
    "Linx Dating",
    "Lisa Clampitt Matchmaking",
    "Luma Luxury Matchmaking",
    "Luxury Matchmaking Services",
    "Matchmakers in the City",
    "Matchmaker May",
    "Prestige Matchmaking Pros",
    "Rose Matchmaking",
    "Select Date Society",
    "Shannon's Circle",
    "Simantov International",
    "Simply Matchmaking",
    "Skilled Attraction",
    "Soul Mates Unlimited",
    "SoulSparx ",
    "SparkOffline.com",
    "Three Day Rule",
    "... and more!",
  ],
};

export const COMPANIES = {
  titles: ["THREE DAY RULE", "KELLEHER INTL.", "LINX DATING"],
  unique: {
    text: "Why they're \n" + "unique",
    values: [
      "This company is the only one backed by large online dating sites and apps like Match, OkCupid, J-Date and " +
        "The League, so their recruiting reach is further than almost any other service.",
      "Leveraging over 32 years of experience, they advertise themselves as the " +
        "largest matchmaking network. They offer a lot of personalization, and clients " +
        "have their own primary matchmaker backed by an entire search team.",
      "Founder Amy Andersen has 15 years" +
        " in the field. She is the epitome of class, and is energetic, " +
        "super responsive and high touch with every client.",
    ],
  },
  bios: {
    text: "Share Bios & \n" + "Photos Both Ways",
    values: [
      "Yes, always.",
      "No (client confidentiality is more important to them).",
      "Sometimes, at their discretion.",
    ],
  },
  pricing: {
    text: "Pricing",
    values: [
      "They typically work with clients for 6 months, which is $8K generally, although it varies slightly per region.",
      "Customized per client, but " +
        "typical fees run $25K for a local search to $75k for a nationwide search, or $150K for elite and intl." +
        " memberships with top company priority and oversight by the owners.",
      "Pricing " +
        "for dedicated service starts at around $20K for a 2 year period. Success fees if you find your mate are " +
        "encouraged and agreed to ahead of time.",
    ],
  },
  locations: {
    text: "Locations",
    values: [
      "Headquartered in L.A. They operate in 9 regions: L.A., Orange County, " +
        "Silicon Valley, San Francisco, New York City, Boston, " +
        "Philadelphia, and Chicago.",
      "Headquartered in the San Francisco Bay Area.\n" +
        "Key regions are L.A., Orange County, Silicon Valley, San Francisco, New York-Tristate, " +
        "Boston, D.C., Las Vegas, Scottsdale, Seattle, Chicago, Houston, Dallas, Atlanta, " +
        "West Palm Beach, Denver, Vancouver, Toronto and London.",
      "Headquartered in CA's Silicon Valley. " +
        "They focus on clients in the Bay Area, but take on some " +
        "clients in other regions.",
    ],
  },
  bestFor: {
    text: "Best for",
    values: [
      "Younger singles in their 20s & 30s who want a relatively affordable option while still having the " +
        "“meet everyone in person” model. They also celebrate working with female clients, unlike traditional " +
        "companies who have focused on women as just options for the men.",
      "Affluent individuals age 40+ who " +
        "are interested in cross-regional searches.",
      "They focus on men in tech in the San Francisco Bay Area, and to " +
        "a lesser degree, L.A. and the United Arab Emirates.",
    ],
  },
  contact: {
    text: "Contact",
    values: [
      "Contact \n" + "THREE DAY RULE",
      "CONTACT \n" + "KELLEHER INTL.",
      "Contact \n" + "Linx Dating",
    ],
  },
};

export const FIRST_SECTION_ELEMENTS = {
  "/": {
    text: "You're a Busy, Selective Professional.\n Now Date Like One.",
    buttonValue: "GET MATCHED FREE",
    pathname: "/welcome-singles",
    coupleInfo: {
      names: "Brooke and Eric",
      city: "San Francisco",
    },
  },
  "/for-matchmakers": {
    text: "Your go-to resource for \nmaking more happy matches.",
    buttonValue: "BECOME A PARTNER",
    pathname: "/welcome-partners",
    coupleInfo: {
      names: "Lissa and Ian",
      city: "Santiago, Chile",
    },
  },
  "/for-singles": {
    text: "Leverage tech and matchmakers to find the right connection.",
    buttonValue: "JOIN THE DATABASE",
    pathname: "/welcome-singles",
    coupleInfo: {
      names: "Becky and Arnel",
      city: "NYC",
    },
  },
  "/contact": {
    text: "",
    buttonValue: "",
    pathname: "",
    noShow: true,
  },
  "/faqs": {
    text: "",
    buttonValue: "",
    pathname: "",
    noShow: true,
  },
  "/terms-of-service": {
    text: "",
    buttonValue: "",
    pathname: "",
    noShow: true,
  },
  "/privacy-policy": {
    text: "",
    buttonValue: "",
    pathname: "",
    noShow: true,
  },
  "/team": {
    text: "",
    buttonValue: "",
    pathname: "",
    noShow: true,
  },
  "/matchmaker-agreement": {
    text: "",
    buttonValue: "",
    pathname: "",
    noShow: true,
  },
  "/talk-to-a-matchmaker": {
    text: "",
    buttonValue: "",
    pathname: "",
    noShow: true,
  },
};

export const HOW_DOES_IT_WORK = [
  {
    text:
      "Sign up to be a potential match by filling out\na fun questionnaire." +
      " You can always access\nand update your answers later.",
    background: "rgba(84,88,90,0.1)",
    before: true,
  },
  {
    text:
      "A partner matchmaker may reach out\nat any time " +
      "when they have an option\nfor you, " +
      "or to get you in their network \n" +
      "for an option in the future.",
    background: "rgba(84,88,90,0.1)",
    after: true,
  },
  {
    text:
      "They'll share a profile of the person they have in mind, " +
      "\ngenerally with photos, though it depends on the individual \nmatchmaker's process and their client's preference.",
    background: "rgba(84,88,90,0.1)",
    before: true,
  },
  {
    text:
      "The matchmaker will share your photos \nand info about you with their client. " +
      "\nIf there's mutual interest, the date is on!",
    background: "rgba(84,88,90,0.1)",
    after: true,
  },
  {
    text: "Provide feedback about the \ndate to the Matchmaker.",
    background: "rgba(84,88,90,0.1)",
    before: true,
  },
  {
    text:
      "Quickly rate the matchmaker and any \napplicable client " +
      "so we continue to incentivize \nexcellent matches and you " +
      "help prospective \nmatchmaking clients select the best company.",
    background: "rgba(84,88,90,0.1)",
    after: true,
  },
];

export const SINGLES_HOMEPAGE_SECTION_TWO = [
  {
    title: "It's private",
    text:
      "Your information remains visible only to the Matchmakers. " +
      "Many people appreciate the confidentiality that matchmaking provides.",
  },
  {
    title: "It's straightforward",
    text:
      "You're not going to be judged based on how witty your profile is. " +
      "You can be your normal self and not have it feel like it's an attention contest.",
  },
  {
    title: "It's serious",
    text:
      "Members of matchmaking companies are relationship-minded and don't want to play games. " +
      "Their financial investment proves it.",
  },
  {
    title: "It's long-lasting",
    text:
      "With matchmaking, the in-depth information collected about members allows for deeper compatibility " +
      "and longer standing relationships. Combined with a matchmaker's " +
      "intuition when they talk with you, it's a powerful method of getting beyond dates and making the right matches.",
  },
];

export const MATCHMAKER_PRICING = {
  plans: {
    title: "Plan",
    values: [
      { planName: "Pay-Per-Lead", description: "“One night stand”" },
      { planName: "Crush", description: "“Best behavior”" },
      { planName: "Flirting", description: "“Off the dating apps”" },
      { planName: "Exclusive", description: "“Meet the parents”" },
    ],
  },
  connections: {
    title: "Connections With Verified Singles",
    values: [
      "Unlimited",
      "Max of 2 connections per month",
      "Max of 5 connections per month",
      "Max of 10 connections per month",
    ],
  },
  otherBenefits: {
    title: "Other Benefits",
    values: [
      {
        text: "Eligible for potential client referrals",
        lock: " long long white text white long long",
      },
      {
        text: "Eligible for potential client referrals",
        lock: " long long white text white long long",
      },
      {
        text: "Eligible for potential client referrals",
        lock: "1 free lock per month",
      },
      { text: "Priority for client referrals", lock: "4 free locks per month" },
    ],
  },
  price: {
    title: "Price",
    values: [
      { text: "$100", continueText: "/person*", button: "Sign Up now" },
      { text: "$149", continueText: "/month", button: "Sign Up now" },
      { text: "$299", continueText: "/month", button: "Sign Up now" },
      { text: "$499", continueText: "/month", button: "Sign Up now" },
    ],
  },
};
