export const loadDataFromLocalStorage = (storageKey) => {
  try {
    const serializedData = localStorage.getItem(storageKey);
    if (serializedData === null) {
      return undefined;
    }
    return JSON.parse(serializedData);
  } catch (err) {
    return undefined;
  }
};

export const saveDataToLocalStorage = (storageKey, storageData) => {
  try {
    const serializedData = JSON.stringify(storageData);
    localStorage.setItem(storageKey, serializedData);
  } catch (err) {
    return err;
  }
};

export const removeDataFromLocalStorage = (storageKey) => {
  try {
    localStorage.removeItem(storageKey);
  } catch (err) {
    return err;
  }
};
