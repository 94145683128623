import { statesIndex } from "src/data/states";
import { REGIONS } from "src/constants/onboarding-data";

export function getRegion(user) {
    let closestRegion = {};
    for (let region of user.regionData) {
        if (!closestRegion.distance || region.distance < closestRegion.distance) {
            closestRegion = region;
        }
    }
    let label;
    if (closestRegion.distance || closestRegion.distance === 0) {
        label =
            closestRegion.label || `${closestRegion.city}, ${closestRegion.state}`;
    }
    if (!label)
        label =
            statesIndex[user.regions[0]] ||
            REGIONS.find((x) => x.value === user.regions[0])?.label ||
            label;

    return label;
}
export function calculateIndividualPercentages(images, userProgress) {
    let x = 0;
    const progress = {};
    if (images && images.length > 0) {
        progress.photos = 100;
    }
    if (userProgress) {
        Object.keys(userProgress).forEach((item) => {
            if (parseInt(userProgress[item], 10) > 100) {
                progress[item] = 100;
            } else {
                progress[item] = parseInt(userProgress[item], 10);
            }
        });
    }

    Object.values(progress).forEach((val) => {
        x += (val * 100) / 400;
    });
    return x;
}