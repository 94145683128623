import React from "react";

export const LogoutIcon = () => {

    return (
        <svg  width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M13.3333 10.1235L4.16665 10.1235M4.16665 10.1235L7.49998 13.3895M4.16665 10.1235L7.49998 6.85752M11.6666 4.40802H14.1666C14.6087 4.40802 15.0326 4.58006 15.3452 4.88631C15.6577 5.19256 15.8333 5.60792 15.8333 6.04102L15.8333 14.206C15.8333 14.6391 15.6577 15.0545 15.3452 15.3608C15.0326 15.667 14.6087 15.8391 14.1666 15.8391H11.6666" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )

}
