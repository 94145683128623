import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as generalActions from "../../actions/generalActions";
import Button from "../../components/button/index";
import logo from "../../components/main-header/assets/date-spot-logo.svg";
import "./style.scss";

class WelcomeOnBoarding extends Component {
  render() {
    const { history } = this.props;

    return (
      <div className="welcome-container flex-column">
        <div className="welcome-container__top">
          <img src={logo} />
        </div>
        <div className="welcome-container__content flex-column">
          <h1 className="welcome-container__content__title">
            Let’s find you a match
          </h1>
          <div className="welcome-container__content__text">
            {`By submitting this form, you consent to join Carla's database.
                        Your information will remain private to all other members.
                        You will only be searchable to partner matchmakers,
                        who can reach out with profiles of potential matches to gage your interest.
                         You will never be required to pay, ever. `}
          </div>
          <div className="welcome-container__content__text">
            The name and photo associated with your Google account will be
            recorded when you upload files and submit this form.
          </div>
        </div>
        <Button
          customClass="cyan lets-go"
          click={() =>
            history.push({ pathname: "/the-basics", section: "theBasics" })
          }
          value={"LET S GO!"}
        />
      </div>
    );
  }
}

WelcomeOnBoarding.propTypes = {
  history: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    generalReducer: state.generalReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    generalActions: bindActionCreators(generalActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeOnBoarding);
