import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  MATCH_MAKER_TABLE_HEADERS,
  USER_STATUS_VALUES,
} from "../../../../constants/index";
import { getInitials } from "../../../../components/util-functions";

import "../../user-list.scss";

class LocksList extends Component {
  state = {
    openedStatusMenu: false,
    hovered: null,
  };

  openStatusMenu = (userId, openMenuUpwards) => {
    return (
      <div
        className={`user-relationship-status user-menu flex-column ${
          openMenuUpwards ? "open-upwards" : ""
        }`}
      >
        {USER_STATUS_VALUES.map((rel) => (
          <div
            key={rel.id}
            onClick={() => {
              this.setState({ openedStatusMenu: false });
              this.props.matchMakerActions.addChangeUserRelationshipStatus(
                userId,
                rel.value
              );
            }}
          >
            <span>{rel.label}</span>
          </div>
        ))}
      </div>
    );
  };

  render() {
    const { filteredUsers, filtersHeight, matchMakerId, matchMakerActions } =
      this.props;

    const { openedStatusMenu, hovered } = this.state;
    // We only show users that are locked by us
    const locksList = filteredUsers.filter((user) =>
      user.connectionsRequests.find(
        (conn) => conn.matchMakerId === matchMakerId && conn.isLocked
      )
    );
    const readjustedHeight = `${248 + filtersHeight}px`;
    const listHeight = filtersHeight
      ? `calc(100vh - ${readjustedHeight})`
      : "calc(100vh - 234px)";
    const openMenuUpwards =
      this.selUserRef && this.selUserRef.getBoundingClientRect().top > 480;

    return (
      <div className="users-list-container locks flex-column">
        <div className="labels favorites flex-row">
          {MATCH_MAKER_TABLE_HEADERS.locks.map((tab, t) => {
            return (
              <span
                className={tab.class}
                key={t}
                style={{ width: `${tab.width}px` }}
              >
                {tab.name}
              </span>
            );
          })}
        </div>
        <div className="users-list" style={{ height: listHeight }}>
          {locksList.map((user, u) => {
            const { connectionsRequests } = user;
            const isUserLockedByMe = connectionsRequests.find(
              (conn) => conn.lockingMatchmaker === matchMakerId && conn.isLocked
            );
            const userRelStatus =
              user.userRelationshipStatuses &&
              user.userRelationshipStatuses.map((st) =>
                st.matchMakerId === matchMakerId ? st.status : null
              );
            return (
              isUserLockedByMe && (
                <div className="users-list__item" key={u}>
                  <div
                    className="users-list__item-container flex-row"
                    ref={
                      hovered === user._id
                        ? (ref) => {
                            this.selUserRef = ref;
                          }
                        : null
                    }
                    onMouseEnter={() => this.setState({ hovered: user._id })}
                    onMouseLeave={() =>
                      this.setState({ hovered: null, openedStatusMenu: false })
                    }
                  >
                    <div
                      className="flex-row user-info"
                      onClick={() =>
                        matchMakerActions.getClickedUserInfo(user._id)
                      }
                    >
                      {user.images && user.images[0] ? (
                        <img
                          src={user.images[0].url}
                          alt=""
                          className="avatar flex-center"
                        />
                      ) : (
                        <span className="avatar flex-center">
                          {getInitials(user)}
                        </span>
                      )}
                      <div className="member element flex-column">
                        <span>
                          {user.firstName} {user.lastName}
                        </span>
                      </div>
                      <span className="gender element">
                        {user.gender && user.gender.value}
                      </span>
                      <span className="rel-status element">
                        {userRelStatus}
                      </span>
                      <div className="show-more float-right">
                        <span
                          className="icon-more"
                          onClick={() =>
                            this.setState({
                              openedStatusMenu: !openedStatusMenu,
                            })
                          }
                        />
                        {hovered === user._id && openedStatusMenu
                          ? this.openStatusMenu(user._id, openMenuUpwards)
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
              )
            );
          })}
        </div>
      </div>
    );
  }
}

LocksList.propTypes = {
  history: PropTypes.object,
  matchmakersReducer: PropTypes.object.isRequired,
  matchMakerActions: PropTypes.object,
  filteredUsers: PropTypes.array.isRequired,
  filtersHeight: PropTypes.any,
  matchMakerId: PropTypes.string,
};

export default LocksList;
