import React, { Component } from "react";
import PropTypes from "prop-types";
import { MATCH_MAKER_TABLE_HEADERS } from "../../../../constants/index";
import { getInitials } from "../../../../components/util-functions";
import ToolTip from "../../../../components/tooltip/index";
import TriangleComponent from "../../../../components/triangle-component";
import "../../user-list.scss";

class UsersList extends Component {
  state = {
    hovered: null,
    openedMenu: false,
    infoRequestSent: false,
    contactedUserNumber: null,
  };

  doAction = (userId, action) => {
    const { matchMakerActions, generalActions } = this.props;

    generalActions.toggleLoadingModal({
      show: true,
      loading: true,
    });
    let funcAction = "";
    switch (action) {
      case "lock":
        funcAction = matchMakerActions.lockSelectedUser(userId);
        break;
      case "unlock":
        funcAction = matchMakerActions.unLockSelectedUser(userId);
        break;
      case "favorite":
        funcAction = matchMakerActions.addUserToFavorites(userId);
        break;
      case "removeFavorite":
        funcAction = matchMakerActions.removeUserFromFavorites(userId);
        break;
      default:
        break;
    }

    funcAction.then((res) => {
      const showModal = !!res.data.error;
      generalActions.toggleLoadingModal({
        show: showModal,
        loading: false,
        error: res.data.originalErrorMessage,
      });
      if (showModal) {
        setTimeout(() => {
          generalActions.toggleLoadingModal({
            show: false,
            loading: false,
            error: false,
          });
        }, 2000);
      }
    });
  };

  render() {
    const { filteredUsers, filtersHeight, matchMakerId } = this.props;
    const { hovered, openedMenu, infoRequestSent, contactedUserNumber } =
      this.state;
    const { favoriteUsersList } = this.props.matchmakersReducer;
    const readjustedHeight = `${248 + filtersHeight}px`;
    const listHeight = filtersHeight
      ? `calc(100vh - ${readjustedHeight})`
      : "calc(100vh - 234px)";
    const usersRenderedList = this._renderUsers(
      filteredUsers,
      hovered,
      openedMenu,
      matchMakerId,
      favoriteUsersList
    );

    return (
      <div
        className="users-list-container flex-column"
        style={{ position: "relative" }}
      >
        {infoRequestSent && (
          <ToolTip
            text={`We have just emailed Member ${contactedUserNumber} to find out their current status and interest. If we don’t hear back from them, we will follow up. Either way, we’ll let you know within one week whether they’d like to participate with you now!`}
            closeCallback={() =>
              this.setState({
                infoRequestSent: false,
                contactedUserNumber: null,
              })
            }
          />
        )}
        <div className="labels flex-row">
          {MATCH_MAKER_TABLE_HEADERS.userDb.map((tab, t) => {
            if (tab.class === "relationship-status") {
              return (
                <span
                  className={tab.class}
                  key={t}
                  style={{ width: `${tab.width}px` }}
                >
                  <span
                    style={{
                      maxWidth: "120px",
                      textAlign: "center",
                    }}
                  >
                    {tab.name}
                  </span>
                </span>
              );
            }
            return (
              <span
                className={tab.class}
                key={t}
                style={{ width: `${tab.width}px` }}
              >
                {tab.name}
              </span>
            );
          })}
        </div>
        <div className="users-list" style={{ height: listHeight }}>
          {usersRenderedList}
        </div>
      </div>
    );
  }

  _renderUsers = (
    filteredUsers,
    hovered,
    openedMenu,
    matchMakerId,
    favoriteUsersList
  ) => {
    return filteredUsers.map((user, u) => {
      const { connectionsRequests } = user;
      // console.log('user: ', user);
      // console.log('connectionsRequests at index: ', connectionsRequests);
      const connectionRequestSentByMyself =
        connectionsRequests.length > 0 &&
        connectionsRequests.find((conn) => conn.matchMakerId === matchMakerId);
      const isUserLocked = connectionsRequests.find((conn) => conn.isLocked);
      const isFavoriteUser = !!favoriteUsersList.find(
        (fu) => fu.favoriteUser && fu.favoriteUser._id === user._id
      );
      const isHoveredItem = hovered === user._id;
      const isUserLockedByMe = !!connectionsRequests.find(
        (conn) => conn.lockingMatchmaker === matchMakerId && conn.isLocked
      );
      const openMenuUpwards =
        this.selUserRef && this.selUserRef.getBoundingClientRect().top > 500;
      const renderUser = this._renderUser(
        user,
        isUserLocked,
        isUserLockedByMe,
        connectionsRequests,
        connectionRequestSentByMyself
      );
      const renderStatusIcons = this._renderStatusIcons(
        isFavoriteUser,
        isUserLocked,
        isUserLockedByMe,
        connectionRequestSentByMyself
      );
      return (
        <div
          className={`${
            isHoveredItem ? "users-list__item hovered" : "users-list__item"
          }`}
          key={u}
          ref={
            isHoveredItem
              ? (ref) => {
                  this.selUserRef = ref;
                }
              : null
          }
          onMouseEnter={() => this.setState({ hovered: user._id })}
          onMouseLeave={() =>
            this.setState({ hovered: null, openedMenu: false })
          }
        >
          <div
            className={`users-list__item-container flex-row
                ${isUserLocked && !isUserLockedByMe ? "faded-locked" : ""}`}
          >
            {renderUser}

            {renderStatusIcons}

            <div className="show-more">
              <span
                className="icon-more"
                onClick={() => this.setState({ openedMenu: !openedMenu })}
              />
              {isHoveredItem && openedMenu
                ? this._renderUserMenu(
                    user,
                    isUserLocked,
                    connectionRequestSentByMyself,
                    isFavoriteUser,
                    isUserLockedByMe,
                    openMenuUpwards
                  )
                : null}
            </div>
          </div>
        </div>
      );
    });
  };

  _renderUser = (
    user,
    isUserLocked,
    isUserLockedByMe,
    connectionsRequests,
    connectionRequestSentByMyself
  ) => {
    const requestStatus =
      connectionRequestSentByMyself && connectionRequestSentByMyself.status;
    const requestAccepted = requestStatus === "APPROVED";
    const showUserName = requestAccepted || isUserLockedByMe;
    const { matchMakerActions } = this.props;
    return (
      <div
        className="flex-row user-info"
        onClick={() => matchMakerActions.getClickedUserInfo(user._id)}
      >
        {user.images && user.images[0] ? (
          <img src={user.images[0].url} alt="" className="avatar flex-center" />
        ) : (
          <span className="avatar flex-center">{getInitials(user)}</span>
        )}
        <div className="member element flex-column">
          {/* here update member number in db -- script in root/ scripts*/}
          <span>#{user.number}</span>
          {showUserName ? (
            <span>
              {user.firstName} {user.lastName.charAt(0)}
            </span>
          ) : null}
        </div>
        <span className="gender element">
          {user.gender && user.gender.value.charAt(0)}
        </span>
        <span className="age element">{user.age}</span>
        <span
          className={`looks element ${user.userRating ? "big-blue" : "red"}`}
        >
          {Math.round(user.userRating) || "No rating"}
        </span>
        <span className="location element">{user.city}</span>
        <span className="relationship-status element">
          {isUserLocked ? (
            "Locked"
          ) : (
            <div className="flex-column">
              <span>
                {user.relationshipStatus && user.relationshipStatus.value}
              </span>
              <span>
                {user.clientStatus === "current"
                  ? "Free Connection"
                  : "Paid Connection"}
              </span>
            </div>
          )}
        </span>

        {connectionsRequests && connectionsRequests.length > 0 && (
          // && connectionsRequests.filter(c => c.status === 'APPROVED')
          <span className="conn-requests flex-center">
            {connectionsRequests.length}
          </span>
        )}
      </div>
    );
  };

  _renderStatusIcons = (
    isFavoriteUser,
    isUserLocked,
    isUserLockedByMe,
    connectionRequestSentByMyself
  ) => {
    return (
      <div className="status-icons flex-center">
        {isFavoriteUser ? (
          <span className="icon-heart red flex-center" />
        ) : (
          <span />
        )}
        {isUserLocked ? (
          <span
            className={`icon-lock flex-center ${
              isUserLockedByMe ? "cyan" : "red"
            }`}
          />
        ) : (
          <span />
        )}
        {!isUserLockedByMe && connectionRequestSentByMyself ? (
          <span className="icon-contacted flex-center" />
        ) : (
          <span />
        )}
      </div>
    );
  };

  _renderUserMenu = (
    user,
    isUserLocked,
    connectionRequestSentByMyself,
    isFavoriteUser,
    isUserLockedByMe,
    openMenuUpwards
  ) => {
    const { matchMakerActions } = this.props;
    const { favoriteUsersList, loggedMMplan } = this.props.matchmakersReducer;
    const favoriteUser = favoriteUsersList.find(
      (fu) => fu.favoriteUser && fu.favoriteUser._id === user._id
    );
    const mmHasLocksAvailable = loggedMMplan && loggedMMplan.locks > 0;
    const mmHasConnectionsAvailable =
      loggedMMplan && loggedMMplan.connections > 0;

    return (
      <div
        className={`user-menu flex-column ${
          openMenuUpwards ? "open-upwards" : ""
        }`}
      >
        <TriangleComponent top={-9} right={14} />
        <div
          className="view-profile flex-row"
          onClick={() => matchMakerActions.getClickedUserInfo(user._id)}
        >
          <span className="icon-eye" />
          <span>View profile</span>
        </div>
        <div
          className="flex-row"
          onClick={() => {
            isFavoriteUser
              ? this.doAction(favoriteUser._id, "removeFavorite")
              : this.doAction(user._id, "favorite");
            this.setState({ openedMenu: false });
          }}
        >
          <span className="icon-heart" />
          <span>
            {isFavoriteUser
              ? "Remove from my potentials"
              : "Add to my potentials"}
          </span>
        </div>
        {!isUserLocked ? (
          <div
            className="flex-row"
            style={{
              display: mmHasLocksAvailable ? "flex" : "none",
            }}
            onClick={() => {
              this.doAction(user._id, "lock");
              this.setState({ openedMenu: false });
            }}
          >
            <span className="icon-lock" />
            <span>Lock user</span>
          </div>
        ) : (
          <div
            className="flex-row"
            style={{ display: isUserLockedByMe ? "flex" : "none" }}
          >
            {isUserLockedByMe ? (
              <div
                className="flex-row"
                style={{ width: "100%", height: "100%" }}
                onClick={() => {
                  this.doAction(user._id, "unlock");
                  this.setState({ openedMenu: false });
                }}
              >
                <span className="icon-lock" />
                <span>Unlock user</span>
              </div>
            ) : null}
          </div>
        )}
        {!isUserLocked && mmHasConnectionsAvailable ? (
          <div
            className="flex-row"
            onClick={() => {
              if (!connectionRequestSentByMyself) {
                this.setState({
                  openedMenu: false,
                  infoRequestSent: true,
                  contactedUserNumber: user.number,
                });
                matchMakerActions.requestAccessForUser(user);
              } else {
                this.setState({ openedMenu: false });
                matchMakerActions.cancelRequestAccessForUser(user._id);
              }
            }}
          >
            <span className="icon-contacted" />
            <span>
              {connectionRequestSentByMyself
                ? "Cancel request"
                : "Contact member"}
            </span>
          </div>
        ) : null}
      </div>
    );
  };
}

UsersList.propTypes = {
  history: PropTypes.object,
  generalActions: PropTypes.object.isRequired,
  generalReducer: PropTypes.object,
  matchMakerActions: PropTypes.object.isRequired,
  matchmakersReducer: PropTypes.object.isRequired,
  filteredUsers: PropTypes.array.isRequired,
  filtersHeight: PropTypes.any,
  matchMakerId: PropTypes.string,
};

export default UsersList;
