import React from "react";
import Api from "../../actions/api";
import PropTypes from "prop-types";
import "@babel/polyfill/noConflict";
const appId =
  process.env.NODE_ENV === "development"
    ? "251728519092421"
    : process.env.FACEBOOK_ID;
class FacebookContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      isSignUp: props.page || false,
      formValid: props.formValid,
    };
  }

  async componentDidMount() {
    if (!isMyScriptLoaded()) {
      initializeFacebookSdk(document, "script", "facebook-jssdk");
      window.fbAsyncInit = () => {
        window.FB.init({
          appId,
          autoLogAppEvents: true,
          xfbml: true,
          status: true,
          version: "v3.2",
        });
        this.setState({ loading: false });
      };
    } else {
      this.setState({ loading: false });
    }
  }

  facebookLogin = () => {
    window.FB.login(
      (response) => {
        if (response.status === "connected") {
          window.FB.api(
            "/me",
            { fields: "first_name, last_name, email" },
            (res) => {
              const data = { ...response.authResponse, ...res };
              Api.post({
                url: "/api/facebook/callback",
                data: { ...data },
                callback: (err, res) => {
                  if (err || !res.data.success) {
                    this.setState({ error: true });
                  } else {
                    this.props.loginFbCallback(null, res);
                  }
                },
              });
            }
          );
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      { scope: "email" }
    );
  };

  render() {
    const { loading, error, isSignUp, formValid } = this.state;
    if (loading) {
      return <div className="ds-loader" />;
    }

    return (
      <div>
        {console.log("fb error: ", error)}
        {!error ? (
          <div
            onClick={() => {
              if (formValid()) {
                this.facebookLogin();
              } else {
                return false;
              }
            }}
            className={`sign-up-with-fb flex-row ${isSignUp ? "short" : ""}`}
          >
            <span className="icon-fb" />
            <span className="sign-up-with-fb__text">
              {isSignUp ? "Sign Up With Facebook" : "Log in with Facebook"}
            </span>
          </div>
        ) : (
          <div
            style={{
              padding: "0 60px",
              fontSize: "18px",
              color: "#FF6666",
              textAlign: "center",
            }}
          >
            Error connecting to Facebook
          </div>
        )}
      </div>
    );
  }
}

const initializeFacebookSdk = (d, s, id) => {
  const fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) {
    return;
  }
  const js = d.createElement(s);
  js.id = id;
  js.src = "//connect.facebook.net/en_US/all.js";
  fjs.parentNode.insertBefore(js, fjs);
};

// Check if the script is loaded or not
const isMyScriptLoaded = () => {
  const scripts = document.getElementsByTagName("script");
  for (let i = scripts.length; i--; i >= 0) {
    if (scripts[i].src.indexOf("facebook.net") > -1) {
      return true;
    }
  }
  return false;
};

FacebookContainer.propTypes = {
  loginFbCallback: PropTypes.func,
  page: PropTypes.bool,
};

// Wrapper to include an error boundary
export default FacebookContainer;
