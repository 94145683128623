import React, { Component } from "react";
import PropTypes from "prop-types";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import App from "./App";
import ReactGA from "react-ga";
const analyticsKey =
  process.env.NODE_ENV === "development"
    ? "UA-136077470-1"
    : process.env.G_ANALYTICS;
ReactGA.initialize(analyticsKey);

export default class Root extends Component {
  componentDidMount() {
    const { history } = this.props;
    history.listen((location) => {
      ReactGA.pageview(location.pathname);
    });
  }

  render() {
    const { store, history } = this.props;
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          {/* <App /> */}
          {this.props.children}
        </ConnectedRouter>
      </Provider>
    );
  }
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
