import React, { useState } from "react";
import "./ForgotPassword.scss";
import { Button, FormInput } from "../widgets";
import { Link } from "../widgets/Link";
import MainContent from "../layouts/MainContent";
import { validateEmail } from "../../utils/validation";
import { post } from "../../utils/client";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [pending, setPending] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [requestErrorMessage, setRequestErrorMessage] = useState("");

  const onSuccess = ({ data: { error } }) => {
    setPending(false);
    setShowSuccess(!error);
    error && setRequestErrorMessage(error);
  };

  const onError = (error) => {
    console.error(error);
    setPending(false);
  };

  const sendResetPasswordEmail = async () => {
    setRequestErrorMessage("");
    setPending(true);
    await post({
      url: "/v1/reset-password",
      data: { email },
      onSuccess,
      onError,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errorMessage = validateEmail(email);
    if (!errorMessage) {
      setEmailErrorMsg("");
      await sendResetPasswordEmail();
    } else setEmailErrorMsg(errorMessage);
  };

  return (
    <MainContent innerContainer="large" borderBottom>
      <form
        noValidate
        className="forgot-password__main-container"
        onSubmit={handleSubmit}
      >
        <h1 className="forgot-password__title">Forgot Password</h1>
        <div className="forgot-password__divider" />
        {showSuccess ? (
          <div className="forgot-password__success-message-container">
            <p className="forgot-password__success-message">
              Thanks! We sent you an email to reset your password.
            </p>
          </div>
        ) : (
          <>
            <FormInput
              className="forgot-password__email-input"
              value={email}
              onChange={(value) => setEmail(value)}
              placeholder="Email"
              error={emailErrorMsg}
              type="email"
            />
            <Button
              className="forgot-password__submit-button"
              type="submit"
              variant="primary"
              disabled={pending}
              error={requestErrorMessage}
            >
              {pending ? "Please Wait..." : "Reset Password"}
            </Button>
            {requestErrorMessage && (
              <p className="error-message">{requestErrorMessage}</p>
            )}
            <h2 className="forgot-password__subtitle">
              Know your password?&nbsp;
              <Link to={"/welcome-singles"} style={{ fontSize: 16 }}>
                Sign in free.
              </Link>
            </h2>
          </>
        )}
      </form>
    </MainContent>
  );
};

export default ForgotPassword;
