@import "../../scss/variables";
@import "../../scss/utilities";

.radio-form__field-container {
  display: flex;
  gap: .75rem;
  align-items: center;

  .radio-form__checkbox-label-grid {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: .75rem;
    margin-left: 1.25rem;

    label {
      color: $greyDarker;
    }
  }

  .radio-form__input-container {
    align-items: center;
    gap: 0.5rem; /* Adjust the gap between elements */
    display: inline-block;
    max-width: 100%;
    overflow-wrap: break-word;
  }

  .radio-form__input {
    width: 198px;
    padding: 0 12px;
    height: 24px !important;
    border: 1px solid #c1c7d0;
    font-size: .75rem;
    opacity: .6;
  }

  .radio-form__more-info {
    position: relative;
    cursor: pointer;

    .radio-form__more-info__popover-container {
      display: none;
      cursor: default;
      position: absolute;
      transition: opacity 0.2s ease-in-out;
      width: 500px;
      background-color: #f2f3f7;
      padding: 1rem .7rem;
      z-index: 100;
      border-radius: 1rem;
      left: 100%;
      top: 0;
      margin-left: 1rem;

      &.mount {
        display: block;
      }

      .radio-form__more-info__popover-content {
        font-size: .875rem;
        padding-left: 25px;
        margin: 0;
        animation: fadeIn 0.2s ease-in-out;
        display: flex;
        flex-direction: column;
        gap: .7rem;

        li {
          color: rgba(42,45,46,.8);
        }

        &.unmount {
          animation: fadeOut 0.2s ease-in-out;
        }
      }
    }
    @media only screen and (max-width: 600px) {
      .radio-form__more-info__popover-container {
        width: 280px;
        left: 100%; // Move the container to the center of the screen
        margin-left: 20px;
        transform: translateX(-10px); // Adjust the position by half of the container’s width
      }
    }
  }
}

.radio-form__custom-radio {
  display: flex;
  gap: .75rem;
  width: 18px;
  height: 18px;
  border: 1px solid #ccc;
  border-radius: 100%;

  &.error {
    border-color: $redError;
  }

  &.checkbox {
    border-radius: .25rem !important;
  }

  input:checked + .radio-form__checkmark {
    display: inline-block;
  }

  .radio-form__checkmark {
    display: none;
    width: 100%;
    border-radius: 100%;
    background-color: #ccc;
    border-style: none;

    &.checkbox {
      border-radius: .25rem !important;
    }
  }

  input {
    display: none;
  }

  label {
    font-size: 1rem;
    line-height: 1.5;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

//Mobile
@include for-mobile {
  .radio-form__more-info__popover-container {
    top: 100% !important;
    left: -400% !important;
    margin-left: 0 !important;
  }

  .radio-form__input {
    max-width: 170px;
  }
}