import React, { Component } from "react";
import PropTypes from "prop-types";
import SettingsSection from "../../../welcome-onboarding/settings-section";

class Settings extends Component {
  render() {
    const {
      user,
      saveUserFieldData,
      goToRelationshipStatus,
      isTabletDown,
      setOnboardingSectionsDisabledState,
    } = this.props;
    return (
      <div className="settings">
        <SettingsSection
          user={user}
          saveUserFieldData={saveUserFieldData}
          goToRelationshipStatus={goToRelationshipStatus}
          isTabletDown={isTabletDown}
          setOnboardingSectionsDisabledState={
            setOnboardingSectionsDisabledState
          }
        />
      </div>
    );
  }
}

Settings.propTypes = {
  saveUserFieldData: PropTypes.func,
  goToRelationshipStatus: PropTypes.func,
  user: PropTypes.object,
};

export default Settings;
