import React, { useEffect, useState } from "react";
import { approveAmb, approveMM, cancelMMPlan, rejectMM, restoreMMPlan } from "../../UserList";
import Modal from "../../../../Modal/Modal";
import moment from "moment";
import "./MMList.css";
import Api from "_old/src/actions/api";
import { ADMIN_TABLE_HEADERS } from "_old/src/constants";
import PaginationBar from "../../PaginationBar";
import { DropDown } from "src/components/widgets";
import { UserAction } from "src/components/Table/Row/UserAction";
import { apiPost } from "launchpad";
import SearchInput from "_old/src/components/search-input";
import { history } from "src/app";


const USERS_PER_PAGE = 10;

export default function MMList({ adminActions, filters, isTabletDown, adminReducer }) {
  const { showApprovedMM } = adminReducer;
  const [page, setPage] = useState(1);
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    const { query, ...restFilters } = filters;

    apiPost('matchmakers', {
      filters: restFilters,
      page: page,
      per_page: USERS_PER_PAGE,
      query: query ?? "",
    }).then((res) => {
      setUsers(res.data);
      setTotal(res.total);
    });

    return () => { }

  }, [page, JSON.stringify(filters)]);

  const handleTabChange = (value) => {
    adminActions.addMatchMakerFilter({ pending: !value })
    adminActions.setShowMatchmakers(value)
  }

  return (
    <div className="admin-mm-list">
      {isTabletDown
        ? <DashBoardSearchInput adminActions={adminActions} />
        : <div className="filters-header">
          <DashBoardSearchInput adminActions={adminActions} />
          <div className="tab-nav">
            <a className={showApprovedMM ? "active" : ""} onClick={() => handleTabChange(true)}>Matchmakers</a>
            <a className={showApprovedMM ? "" : "active"} onClick={() => handleTabChange(false)}>Requests</a>
          </div>
        </div>}
      <div className="table-container">
        <table className="admin-mm-table">
          {!isTabletDown && <thead>
            <tr className="labels">
              {ADMIN_TABLE_HEADERS.mm.map((tab, t) => {
                return (
                  <th
                    className={tab.class}
                    key={t}
                  >
                    {tab.name}
                  </th>
                );
              })}
            </tr>
          </thead>}
          <RenderMM matchmakers={users} isTabletDown={isTabletDown} adminActions={adminActions} />
        </table>
      </div>
      <PaginationBar
        total={total}
        per_page={USERS_PER_PAGE}
        page={page}
        onChange={(page) => setPage(page)} />
    </div>
  );
}

const datespotMemberTypes = ["", "Free", "Premium"];
const matchmakerMemberTypes = ["", "Client", "Non-Client", "Intro No-Show"];
const connectionTypes = [
  "",
  "Free to Free",
  "Free to Client",
  "Premium to Free",
  "Premium to Client",
];

const CreditRowEdit = ({
  credit,
  handleChange,
  confirmChange,
  confirmText,
}) => {
  return (
    <div className="credit-block">
      <div className="credit-row-container">
        {/* Acquired */}
        <div className="credit-row-1">
          <span className="credit-row-label"> Acquired </span>
          <input
            className="credit-row-input"
            type="text"
            value={credit.acquireDate}
            onChange={(e) =>
              handleChange({ ...credit, acquireDate: e.target.value })
            }
            placeholder="MM/DD/YYYY"
          />
          <input
            className="credit-row-input"
            type="text"
            value={credit.userId}
            onChange={(e) =>
              handleChange({ ...credit, userId: e.target.value })
            }
            placeholder="Member number"
          />
          <input
            className="credit-row-input"
            type="text"
            value={credit.name}
            onChange={(e) => handleChange({ ...credit, name: e.target.value })}
            placeholder="Name"
          />
          <select
            className="credit-row-input-select"
            value={credit.memberType}
            onChange={(e) =>
              handleChange({ ...credit, memberType: e.target.value })
            }
          >
            <option disabled>Matchmaker's Member Type</option>
            {matchmakerMemberTypes.map((type, index) => {
              return <option key={`${type}-${index}`}>{type}</option>;
            })}
          </select>
        </div>

        {/* Spent */}
        <div className="credit-row-n">
          <span className="credit-row-label"> Spent </span>
          <input
            className="credit-row-input"
            type="text"
            value={credit.spendDate}
            onChange={(e) =>
              handleChange({ ...credit, spendDate: e.target.value })
            }
            placeholder="MM/DD/YYYY"
          />
          <input
            className="credit-row-input"
            type="text"
            value={credit.matchUserId}
            onChange={(e) =>
              handleChange({ ...credit, matchUserId: e.target.value })
            }
            placeholder="Matched Member Number"
          />
          <select
            className="credit-row-input-select"
            value={credit.matchMemberType}
            onChange={(e) =>
              handleChange({ ...credit, matchMemberType: e.target.value })
            }
          >
            <option disabled>Matched Member Type</option>
            {datespotMemberTypes.map((type, index) => {
              return <option key={`${type}-${index}`}>{type}</option>;
            })}
          </select>

          <select
            className="credit-row-input-select"
            value={credit.matchType}
            onChange={(e) =>
              handleChange({ ...credit, matchType: e.target.value })
            }
          >
            <option disabled>Connection Type</option>
            {connectionTypes.map((type, index) => {
              return <option key={`${type}-${index}`}>{type}</option>;
            })}
          </select>
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <textarea
          className="credit-row-value-notes"
          placeholder="notes"
          value={credit.notes}
          onChange={(e) => handleChange({ ...credit, notes: e.target.value })}
        />
        <button
          style={{
            color: "white",
            backgroundColor: "#65c4c0",
            border: "none",
            zIndex: "9999",
            margin: "auto",
          }}
          onClick={() => confirmChange(credit)}
        >
          {confirmText}
        </button>
      </div>
    </div>
  );
};

const CreditRow = ({ credit, handleDelete, confirmText, handleEdit }) => {
  return (
    <div className="credit-block">
      <div className="credit-row-container" onClick={() => handleEdit(credit)}>
        {/* Acquired */}
        <div className="credit-row-1">
          <span className="credit-row-label"> Acquired </span>
          <div className="credit-row-value">{credit.acquireDate}</div>
          <div className="credit-row-value">{credit.userId}</div>
          <div className="credit-row-value">{credit.name}</div>
          <div className="credit-row-value">{credit.memberType}</div>
        </div>

        {/* Spent */}
        <div className="credit-row-n">
          <span className="credit-row-label"> Spent </span>
          <div className="credit-row-value">{credit.spendDate}</div>
          <div className="credit-row-value">{credit.matchUserId}</div>
          <div className="credit-row-value">{credit.matchMemberType}</div>
          <div className="credit-row-value">{credit.matchType}</div>
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <div className="credit-row-value-notes">{credit.notes}</div>
        <button
          style={{
            color: "white",
            backgroundColor: "#65c4c0",
            border: "none",
            zIndex: "9999",
            margin: "auto",
          }}
          onClick={() => handleDelete(credit)}
        >
          {confirmText}
        </button>
      </div>
    </div>
  );
};

const RenderMM = ({ matchmakers, isTabletDown, adminActions }) => {
  return (matchmakers && !matchmakers.length) ?
    (<tbody className="empty-table">
      <tr>
        <td colSpan={8}>No results found for those criteria</td>
      </tr>
    </tbody>)
    : <tbody>{matchmakers.map((matchmaker, index) => <MMRow matchmaker={matchmaker} key={index} isTabletDown={isTabletDown} adminActions={adminActions} />)}</tbody>;
}

const MMRow = ({ matchmaker, isTabletDown, adminActions }) => {

  return !isTabletDown ? (
    <tr className="user-row">
      <td className="">
        {matchmaker.firstName} {matchmaker.lastName}
      </td>
      <td className="">
        {matchmaker.companyName}
      </td>
      <td className="">
        {matchmaker.email}
      </td>
      <td className="">
        {matchmaker.phone}
      </td>
      <td className="actions-col">
        <MMDropdown user={matchmaker} adminActions={adminActions} />
      </td>
    </tr>
  ) : (
    <tr className="user-row-mobile">
      <td className="">
        <p>{matchmaker.firstName} {matchmaker.lastName}</p>
        <p><b>Company: </b>{matchmaker.companyName}</p>
        <p><b>Email: </b>{matchmaker.email}</p>
        <p><b>Phone: </b>{matchmaker.phone}</p>
      </td>
      <td className="actions-col">
        <MMDropdown user={matchmaker} adminActions={adminActions} />
      </td>
      {/* </div> */}
    </tr>
  );
}
const MMDropdown = ({ user, adminActions }) => {
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const selectUser = async (user) => {
    setOpen(false);
    const referrals = await Api.get({ url: `/api/get-mm-referrals/${user._id}` });
    setSelectedUser({ ...user, referrals: referrals.data });
  };


  return <>
    <ReferralsModal selectedUser={selectedUser} onClose={() => setSelectedUser(null)} adminActions={adminActions} />

    <div style={{ position: "relative", width: "fit-content" }}>
      <span className="icon-more" onClick={() => setOpen(!open)} />
      <div className="action-wrapper"> <DropDown className="action-menu" open={open}>
        <UserAction
          icon="eye"
          onClick={() => selectUser(user)}
        >
          Referrals & Credits
        </UserAction>
        {user.approved && (
          <UserAction
            icon="edit"
            onClick={() => history.push(`/maker-details?id=${user._id}`)}
          >
            Edit Matchmaker
          </UserAction>
        )}
        {(!user.approved || user.rejected) && (
          <UserAction icon="check" onClick={() => approveMM(user)}>
            Approve Matchmaker
          </UserAction>
        )}
        {(!user.approved || user.rejected) && (
          <UserAction icon="check" onClick={() => approveAmb(user)}>
            Approve Ambassador
          </UserAction>
        )}
        {user.approved && !user.planCanceled && (
          <UserAction icon="ban" onClick={() => cancelMMPlan(user)}>
            Cancel Plan
          </UserAction>
        )}
        {user.planCanceled && (
          <UserAction icon="check" onClick={() => restoreMMPlan(user)}>
            Restore Plan
          </UserAction>
        )}

        {!user.approved && (
          <UserAction icon="ban" onClick={() => rejectMM(user)}>
            Reject Matchmaker
          </UserAction>
        )}
        {user.approved && !user.rejected && (
          <UserAction icon="times" onClick={() => rejectMM(user)}>
            Remove Matchmaker
          </UserAction>
        )}
      </DropDown>
      </div>
    </div>
  </>
}

const DashBoardSearchInput = ({ adminActions }) => {

  const [search, setSearch] = useState("");

  useEffect(() => {
    const timeOut = setTimeout(() => {
      adminActions.addMatchMakerFilter({ query: search })
    }, 3000);

    return () => clearTimeout(timeOut);
  }, [search]);

  return (
    <div className="search-bar-container">
      <SearchInput
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder="Search by name"
      />
    </div>
  )
}

const ReferralsModal = ({ selectedUser, onClose, adminActions }) => {
  const referrals = selectedUser?.referrals ?? [];
  const [credits, setCredits] = useState(selectedUser?.referralCredits ?? []);
  const [newCredit, setNewCredit] = useState({
    acquireDate: "",
    userId: "",
    name: "",
    memberType: "Source Member Type",
    spendDate: "",
    matchUserId: "",
    matchMemberType: "",
    matchType: "",
    notes: "",
  });
  const [showEditCredit, setShowEditCredit] = useState(false);
  const [editingCredit, setEditingCredit] = useState({
    acquireDate: "",
    userId: "",
    name: "",
    memberType: "",
    spendDate: "",
    matchUserId: "",
    matchMemberType: "",
    matchType: "",
    notes: "",
  });

  const addCredit = async (credit) => {
    if (!validateCredit(credit, "add")) {
      return false;
    }

    const updatedCredits = [...credits, newCredit];
    if (selectedUser) {
      adminActions
        .updateMatchMaker({ ...selectedUser, referralCredits: updatedCredits })
        .then((res) => {
          if (!res.data.error) {
            setCredits(updatedCredits);
          }
        });
    }
  };

  const validateCredit = (credit, action) => {
    if (action === "add") {
      // uniqueness
      if (credits.find((x) => x.userId === credit.userId)) {
        alert("Could not save credit, member number already exists");
        return false;
      }
    }

    // valid date
    if (!moment(credit.acquireDate, "MM/DD/YYYY", true).isValid()) {
      alert("Could not save credit, check date format");
      return false;
    }

    // valid member number
    if (!validateMemberNumber(credit.userId)) {
      alert("Could not save credit, check member number");
      return false;
    }

    return true;
  };

  const deleteCredit = async (credit) => {
    const updatedCredits = credits.filter((c) => c !== credit);
    adminActions
      .updateMatchMaker({ ...selectedUser, referralCredits: updatedCredits })
      .then((res) => {
        if (!res.data.error) {
          setCredits(updatedCredits);
        }
      });
  };

  const updateCredit = async (credit) => {
    if (!validateCredit(credit, "update")) {
      return false;
    }

    let updatedCredits = credits.filter(
      (x) => x.userId !== editingCredit.userId
    );
    updatedCredits = [...updatedCredits, credit];
    if (selectedUser) {
      adminActions
        .updateMatchMaker({ ...selectedUser, referralCredits: updatedCredits })
        .then((res) => {
          if (!res.data.error) {
            setCredits(updatedCredits);
          }
        });
    }
    setShowEditCredit(false);
  };

  const userSelectedEditCredit = (credit) => {
    setEditingCredit(credit);
    setShowEditCredit(true);
  };

  return <>
    <Modal
      title={
        selectedUser
          ? `${selectedUser.firstName} of ${selectedUser.companyName}'s referrals & credits`
          : ""
      }
      open={selectedUser !== null}
      onClose={onClose}
      customClass="referrals"
    >
      <div
        style={{
          borderBottom: "5px solid #65c4c0",
          marginBottom: "5em",
          paddingBottom: "5em",
        }}
      >
        {referrals.length > 0 ? (
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <th style={{ padding: "0 1em 0 1em" }}>Member Number</th>
                <th style={{ padding: "0 1em 0 1em" }}>Name</th>
                <th style={{ padding: "0 1em 0 1em" }}>Email</th>
                <th style={{ padding: "0 1em 0 1em" }}>Signup Date</th>
                <th style={{ padding: "0 1em 0 1em" }}>Member Type</th>
                <th style={{ padding: "0 1em 0 1em" }}>Suspicious</th>
              </tr>
            </thead>
            <tbody>
              {referrals.map((x) => {
                return (
                  <tr style={{ padding: "2em 0 2em 0" }}>
                    <td
                      style={{
                        padding: "1em 1em 0 1em",
                        textAlign: "center",
                        border: "solid 1px black",
                      }}
                    >
                      {x?.user_id}
                    </td>
                    <td
                      style={{
                        padding: "1em 1em 0 1em",
                        textAlign: "center",
                        border: "solid 1px black",
                      }}
                    >
                      {x?.name}
                    </td>
                    <td
                      style={{
                        padding: "1em 1em 0 1em",
                        maxWidth: "200px",
                        wordWrap: "break-word",
                        textAlign: "center",
                        border: "solid 1px black",
                      }}
                    >
                      {x?.email}
                    </td>
                    <td
                      style={{
                        padding: "1em 1em 0 1em",
                        textAlign: "center",
                        border: "solid 1px black",
                      }}
                    >
                      {formatDate(x?.createdAt)}
                    </td>
                    <td
                      style={{
                        padding: "1em 1em 0 1em",
                        textAlign: "center",
                        border: "solid 1px black",
                      }}
                    >
                      {x?.clientStatus ? (x.clientStatus === "current" ? "Premium Member" :
                        (x.clientStatus === "free" ? "Free Member" :
                          (x.clientStatus === "freemium" ? "Freemium Member" : "--")))
                        : "--"}
                    </td>
                    <td
                      style={{
                        padding: "1em 1em 0 1em",
                        textAlign: "center",
                        border: "solid 1px black",
                      }}
                    >
                      {x?.suspicion?.profile ? "Yes" : "No"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p style={{ textAlign: "center" }}>No referrals to show</p>
        )}
      </div>

      <div>
        <CreditRowEdit
          credit={newCredit}
          confirmText="Add"
          handleChange={(c) => setNewCredit(c)}
          confirmChange={(c) => addCredit(c)}
        />

        {credits.map((credit) => {
          return (
            <CreditRow
              credit={credit}
              confirmText="Delete"
              handleDelete={(c) => deleteCredit(c)}
              handleEdit={(c) => userSelectedEditCredit(c)}
            />
          );
        })}
      </div>
    </Modal>
    <Modal
      title={"crEdit"}
      open={showEditCredit}
      onClose={() => {
        setShowEditCredit(false);
      }}
      customClass="edit-credit"
    >
      <div>
        <CreditRowEdit
          credit={editingCredit}
          confirmText="Update"
          handleChange={(c) => setEditingCredit(c)}
          confirmChange={(c) => updateCredit(c)}
        />
      </div>
    </Modal>
  </>
}

const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [month, day, year].join("-");
};

const validateMemberNumber = (str) => {
  var n = Math.floor(Number(str));
  return n !== Infinity && String(n) === str && n > 0;
};