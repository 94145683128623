import React from "react";
import {
  toggleMedia,
  setGlobal,
  getGlobal,
  setSetting,
  getSetting,
} from "launchpad";
import TextField from "@material-ui/core/TextField";

export function Input(props) {
  return (
    <div className="Input">
      <TextField
        {...props}
        onChange={(e) => props.onChange && props.onChange(e, e.target.value)}
      />
    </div>
  );
}
