import React, { useMemo } from "react";
import "./Button.scss";

export const Button = ({
                         children,
                         className = "",
                         variant = "primary",
                         icon,
                         error,
                         text = "",
                         fullWidth = false,
                         ...rest
                       }) => {
  const classes = useMemo(() => {
    let classes = `button__root${icon ? " button__w-icon" : ""}`;
    classes += error ? " --error-state" : "";
    switch (variant) {
      case "primary":
        classes += " --primary";
        break;
      case "facebook":
        classes += " --facebook";
        break;
      case "teal":
        classes += " --teal";
        break;
      default:
        classes += " --primary";
        break;
    }
    className && (classes += ` ${className}`);
    fullWidth && (classes += " --full-width");
    return classes;
  }, [className, variant]);

  return (
      <button className={classes} {...rest}>
        {icon && <span className="button__icon">{icon}</span>}
        {text || children}
      </button>
  );
};
