import React from "react";
import PropTypes from "prop-types";
import "./Avatar.scss";
import { Image } from "./widgets";

const Avatar = ({
  src,
  alt,
  size = "medium",
  className = "",
  initials = "",
}) => {
  return src ? (
    <Image
      src={src}
      alt={alt}
      className={`avatar avatar--${size} ${className}`}
    />
  ) : (
    <div className={`avatar avatar--${size} ${className}`}>
      <span className="avatar__initials">{initials}</span>
    </div>
  );
};

Avatar.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  className: PropTypes.string,
  initials: PropTypes.string,
};

export default Avatar;
