import React from "react";
import "./MainContent.scss";

/**
 * Main content layout
 * @param props
 * @param props.children
 * @param {string} props.innerContainer
 * @param {boolean} props.borderBottom
 * @return {JSX.Element}
 * @constructor
 */
const MainContent = ({ children, innerContainer = "", borderBottom }) => {
  return (
    <div
      className={`main-content__layout${
        borderBottom ? " main-content__w-border" : ""
      }`}
    >
      {innerContainer ? (
        <div className={`main-content__${innerContainer}`}>{children}</div>
      ) : (
        children
      )}
    </div>
  );
};

export default MainContent;
