import React from "react";
import { getSetting } from "launchpad";

export const Address = () => (
  <div className="flex-column" style={{ marginBottom: "50px" }}>
    <span className="text small">{getSetting("company_name")}</span>
    <span className="text small">
      {getSetting("street_address")}, {getSetting("city_&_zip")}
    </span>
    <span className="text small">{getSetting("email")}</span>
  </div>
);
