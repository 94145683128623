import React, { Component } from "react";
import PropTypes from "prop-types";
import CheckBox from "../check-box";
import "./style.scss";

class CheckBoxList extends Component {
  state = {
    selectedOptions: this.props.selectedValue?.filter((el) => el) || [],
  };

  render() {
    const {
      list,
      title,
      subTitle,
      stateCallback,
      error,
      extraClass,
      returnInputValue,
      placeholder,
      required,
      columns,
      labelExceptions = {},
    } = this.props;
    const { selectedOptions } = this.state;
    const textInputValue = selectedOptions.find((s) => s.label === "Other");

    const wrapAt = Math.ceil(list.length / columns);

    const renderCheckBoxList = (list) =>
      list.map((li, l) => {
        const isChecked = !!selectedOptions.find((so) => so.label === li.label);
        const isOtherLabel = li.label === "Other";
        return (
          <CheckBox
            labelName={labelExceptions[li.label] || li.label}
            className={l === list.length - 1 ? "last-element" : ""}
            key={l}
            listElement={li}
            placeholder={placeholder}
            hasInputText={isOtherLabel}
            returnInputValue={(val) =>
              val.value !== "Other"
                ? returnInputValue([
                    ...selectedOptions.filter((o) => o.value !== "Other"),
                    val,
                  ])
                : returnInputValue(
                    [...selectedOptions].filter((o) => o.value !== "Other")
                  )
            }
            resetInputValue={isChecked.label !== "Other"}
            selectedInput={textInputValue ? textInputValue.value : null}
            clicked={
              isChecked
                ? () => {
                    this.setState({
                      selectedOptions: selectedOptions.filter(
                        (so) => so.label !== li.label
                      ),
                    });
                    stateCallback(
                      selectedOptions.filter((so) => so.label !== li.label)
                    );
                  }
                : () => {
                    this.setState({
                      selectedOptions: [...selectedOptions, li],
                    });
                    stateCallback([...selectedOptions, li]);
                  }
            }
            checked={isChecked}
          />
        );
      });

    return (
      <div className={"check-box-list flex-column"}>
        {/* check-box-container*/}
        <div className={"check-box-list__header"}>
          <div
            className={`check-box-list__header__title${
              selectedOptions.length > 0
                ? " is-completed-green"
                : ""
            }`}
          >
            {title}
            {required && !selectedOptions.length ? (
              <span className="text-alert-red">*</span>
            ) : (
              ""
            )}
          </div>
          <span className="helper-text">{subTitle}</span>
        </div>
        {error && <div className="right-block-title__error-field">{error}</div>}
        <div
          className={`${
            wrapAt
              ? "check-box-list__wrapped-list"
              : "flex-column checkbox-list-items"
          }${` ${extraClass}` || ""}`}
          style={{
            gridTemplateRows: columns ? `repeat(${wrapAt}, auto)` : "unset",
            gridTemplateColumns: columns ? `repeat(${columns}, 1fr)` : "unset",
          }}
        >
          {renderCheckBoxList(list)}
        </div>
      </div>
    );
  }
}

CheckBoxList.propTypes = {
  list: PropTypes.array,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  stateCallback: PropTypes.func,
  error: PropTypes.any,
  extraClass: PropTypes.string,
  returnInputValue: PropTypes.func,
  palceholder: PropTypes.string,
  required: PropTypes.bool,
};

export default CheckBoxList;
