import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import Button from "../../../../components/button";
import InputText from "../../../../components/input-text";
import InputTextarea from "../../../../components/input-textarea";
import Checkbox from "../../../../components/check-box";
import LinkState from "../../../../components/utils/link-state";
import { isEmpty } from "../../../../components/util-functions";
import ERRORS from "../../../../components/utils/validator/errors";
import * as adminActions from "../../../../actions/adminActions";
import * as generalActions from "../../../../actions/generalActions";

class PlanDetails extends Component {
  constructor() {
    super();
    this.state = {
      plan: null,
      errors: {
        plan: {},
      },
    };
  }

  componentDidMount() {
    document.body.classList.remove("no-scroll");
    if (!this.props.adminReducer.plans) {
      adminActions.loadPlans();
    } else {
      this.loadCurrentPlan();
    }
  }

  componentWillUnmount() {
    document.body.classList.add("no-scroll");
  }

  componentDidUpdate() {
    const { plans } = this.props.adminReducer;
    if (!this.state.plan && plans) {
      this.loadCurrentPlan();
    }

    let { lastUpdatedField } = this.state;
    const { errors } = this.state;
    if (lastUpdatedField) {
      const parsed = lastUpdatedField.split("plan.")[1];
      // Remove any errors
      errors.plan[parsed] = null;
      lastUpdatedField = null;
      this.setState({
        lastUpdatedField,
        errors,
      });
    }
  }

  /** Loads the current plan based on the ID from the URL */
  loadCurrentPlan = () => {
    const { plans } = this.props.adminReducer;
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    let plan;
    if (!id) {
      plan = {};
    } else {
      plan = plans.find((item) => item._id === id);
    }
    this.setState({ plan: { ...plan } });
  };

  /** Triggered when unlimited checkbox is clicked */
  unlimitedClicked = () => {
    const plan = { ...this.state.plan };
    if (plan.connections !== Number.MAX_SAFE_INTEGER) {
      plan.prevConnections = plan.connections;
      plan.connections = Math.abs(Number.MAX_SAFE_INTEGER);
    } else {
      plan.connections = plan.prevConnections || "";
    }
    this.setState({ plan });
  };

  /** Triggered when free trial checkbox is clicked */
  freeTrialClicked = () => {
    const plan = { ...this.state.plan };
    plan.freeTrial = !plan.freeTrial;
    plan.connections = 1;
    plan.locks = 0;
    plan.price = 0;
    plan.freeLocks = 0;
    this.setState({ plan, unlimited: false });
  };

  /** Triggered when matchmaker checkbox is clicked */
  matchmakerClicked = () => {
    const plan = { ...this.state.plan };
    plan.matchmakerEngine = !plan.matchmakerEngine;
    this.setState({ plan });
  };

  // Validation
  validate = () => {
    let isValid = true;
    const errors = { plan: {} };
    const { plan } = this.state;
    if (isEmpty(plan.name)) {
      errors.plan.name = ERRORS.REQUIRED;
      isValid = false;
    }
    if (!plan.freeTrial && isEmpty(plan.price)) {
      errors.plan.price = ERRORS.REQUIRED;
      isValid = false;
    }
    if (!plan.freeTrial && parseInt(plan.price, 10) < 0) {
      errors.plan.price = "Must be a positive value";
      isValid = false;
    }
    if (isEmpty(plan.connections)) {
      errors.plan.connections = ERRORS.REQUIRED;
      isValid = false;
    }
    if (parseInt(plan.connections, 10) <= 0) {
      errors.plan.connections = "Must be a positive value";
      isValid = false;
    }
    if (isEmpty(plan.freeLocks)) {
      errors.plan.freeLocks = ERRORS.REQUIRED;
      isValid = false;
    }
    if (parseInt(plan.freeLocks, 10) < 0) {
      errors.plan.freeLocks = "Must be greater or equal to 0";
      isValid = false;
    }

    this.setState({ errors });

    return isValid;
  };

  /** Save the plan */
  save = () => {
    const { plan } = this.state;
    if (this.validate()) {
      plan.connections = parseInt(plan.connections, 10);
      if (plan.freeTrial) {
        plan.price = 0;
      }
      adminActions.updatePlan(plan).then(() => {
        this.props.history.push("/plans");
      });
    }
  };

  render() {
    const { plans, saving } = this.props.adminReducer;
    const { plan, errors } = this.state;
    if (!plans || !this.state.plan) {
      return <div className="ds-loader" />;
    }
    const unlimited = plan.connections === Number.MAX_SAFE_INTEGER;
    const renderDetailsForm = this._renderDetailsForm(
      plan,
      unlimited,
      errors,
      saving
    );

    return (
      <div className="ds-details ds-form">
        <div className="ds-details__header">
          <span
            className="icon-back-button"
            onClick={() => {
              this.props.history.push("/plans");
            }}
          />
          <div className="ds-details__header-title">
            {plan.id ? "Edit" : "Add"} {plan.name} Plan
          </div>
        </div>
        <hr />

        {renderDetailsForm}
      </div>
    );
  }

  _renderDetailsForm = (plan, unlimited, errors, saving) => {
    return (
      <div className="ds-details__form">
        <div className="flex-column">
          <div className={"right-block-title is-completed-blue"}>
            Plan Name <span className="is-required-field">*</span>
          </div>
          <InputText
            error={errors.plan.name}
            onChange={LinkState.bind(this, "plan.name")}
            value={plan.name}
          />
        </div>
        <div className="flex-column">
          <div className={"right-block-title is-completed-blue"}>
            Short description
          </div>
          <InputText
            onChange={LinkState.bind(this, "plan.shortDesc")}
            value={plan.shortDesc}
          />
        </div>
        <div className="flex-column">
          <div className={"right-block-title is-completed-blue"}>
            Long description
          </div>
          <InputTextarea
            onChange={LinkState.bind(this, "plan.longDesc")}
            value={plan.longDesc}
          />
        </div>
        <div className="flex-column">
          <div className={"right-block-title is-completed-blue"}>
            Price / month<span className="is-required-field">*</span>
          </div>
          <InputText
            onChange={LinkState.bind(this, "plan.price")}
            wrapperClassName={"input-price"}
            type={"number"}
            disabled={plan.freeTrial}
            error={errors.plan.price}
            value={plan.price}
          />
          <div style={{ marginTop: "10px", marginLeft: "auto" }}>
            <Checkbox
              labelName={"Free Trial"}
              checked={plan.freeTrial}
              clicked={() => this.freeTrialClicked()}
            />
          </div>
        </div>
        <div className="flex-column">
          <div
            className={"right-block-title is-completed-blue"}
            style={{ flex: "1 0 50%" }}
          >
            Connections <span className="is-required-field">*</span>
          </div>
          <InputText
            onChange={LinkState.bind(this, "plan.connections")}
            error={errors.plan.connections}
            type={"number"}
            disabled={unlimited}
            value={!unlimited ? plan.connections : plan.prevConnections}
          />
          <div style={{ marginTop: "10px", marginLeft: "auto" }}>
            <Checkbox
              labelName={"Unlimited connections"}
              checked={unlimited}
              disabled={plan.freeTrial}
              clicked={() => this.unlimitedClicked()}
            />
          </div>
        </div>
        <div className="flex-column">
          <div className={"right-block-title is-completed-blue"}>
            Free Locks / month <span className="is-required-field">*</span>
          </div>
          <InputText
            onChange={LinkState.bind(this, "plan.freeLocks")}
            type={"number"}
            disabled={plan.freeTrial}
            error={errors.plan.freeLocks}
            value={plan.freeLocks}
          />
        </div>
        <div className="flex-column">
          <div className={"right-block-title is-completed-blue"}>
            Matchmaker Engine
          </div>
          <Checkbox
            checked={plan.matchmakerEngine}
            disabled={plan.freeTrial}
            clicked={() => this.matchmakerClicked()}
          />
        </div>
        <div className="flex-column">
          <Button value={"Save"} click={() => this.save()} disabled={saving} />
          {saving && <div className="ds-loader ds-loader--small" />}
        </div>
      </div>
    );
  };
}

PlanDetails.propTypes = {
  history: PropTypes.object.isRequired,
  adminReducer: PropTypes.object.isRequired,
  generalActions: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    generalActions: bindActionCreators(generalActions, dispatch),
  };
};

const mapStateToProps = (state) => {
  return {
    adminReducer: state.adminReducer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanDetails);
