const path = require("path");

exports.toTitleCase = (str) => {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

exports.getTitle = (str) => {
  str = (str || "").replace(/([A-Z])/g, "-$1");
  return exports.toTitleCase(str.replace(/['"\_\-]/g, " "));
};

exports.fuzzyMatch = (a, b) => {
  let isMatch = true;
  a.split(/[- \.]/g).forEach((x) => {
    const reg = new RegExp(x, "ig");
    if (!reg.test(b)) isMatch = false;
  });
  return isMatch;
};

exports.parseFraction = (f) => {
  if (!f || typeof f != "string") return 0;
  const values = f
    .replace(/[^0-9|\/]/g, " ")
    .split(" ")
    .map((x) => x.trim())
    .filter((x) => !!x);
  let value = 0;
  for (let x of values) {
    if (x.includes("/")) {
      const [a, b] = x.split("/");
      value += parseFloat(a) / parseFloat(b);
    } else {
      value += parseFloat(x);
    }
  }
  return value;
};

exports.loadScript = (scriptSrc, scriptId) => {
  if (typeof document != "undefined") {
    if (scriptId) {
      const existingScript = document.getElementById(scriptId);
      if (existingScript) {
        existingScript.remove();
      }
    }
    scriptSrc = `${scriptSrc}?v=${Date.now()}`;
    const script = document.createElement("script");
    script.id = scriptId;
    script.src = scriptSrc;
    //script.async = true;
    script.defer = true;
    script.type = "text/javascript";
    document.body.appendChild(script);
  }
};

// const checkCache = () => {
//   if(typeof window != 'undefined') return window._lpcache || {};
//   return require('data/megaCache.json') || {}
// }

exports.getCache = (segment, id, href) => {
  const cache = checkCache();
  id = id && id.replace(/-/g, "_");
  let results = cache[segment] && cache[segment][id] && cache[segment][id].data;

  if (!results) {
    href = href ? href.replace(/-/g, "_") : "";
    results =
      cache.pages &&
      cache.pages.urlCache &&
      cache.pages.urlCache[href] &&
      cache.pages.urlCache[href].data[segment];
  }
  return results;
};

exports.getPageCache = (pageId) => {
  // try {
  //   const cache = require('data/megaCache.json')
  //   return cache[pageId]
  // } catch(e) {
  //   // couldn't load catch, may not exist
  //   return {}
  // }
};

const isBrowser = () => {
  return typeof window != "undefined";
};

const browserRun = (fn) => {
  if (typeof window != "undefined") fn();
};

exports.listen = (event, fn) => {
  browserRun(() => document.addEventListener(event, fn));
};

exports.unlisten = (event, fn) => {
  browserRun(() => document.removeEventListener(event, fn));
};

exports.getUrlHash = () => {
  if (isBrowser()) return window.location.hash.replace("#", "");
};

exports.setUrlHash = (hash) => {
  if (isBrowser()) window.location.hash = hash;
};

exports.setCaretAtEnd = (elem) => {
  let elemLen = elem.value.length;
  // For IE Only
  if (document.selection) {
    elem.focus();
    // Use IE Ranges
    var oSel = document.selection.createRange();
    // Reset position to 0 & then set at end
    oSel.moveStart("character", -elemLen);
    oSel.moveStart("character", elemLen);
    oSel.moveEnd("character", 0);
    oSel.select();
  } else if (elem.selectionStart || elem.selectionStart === "0") {
    // Firefox/Chrome
    elem.selectionStart = elemLen;
    elem.selectionEnd = elemLen;
    elem.focus();
  } // if
};

// exports.getSettings = () => {
//   return checkCache().settings
// }
//
// exports.getSetting = (name) => {
//   return getSettings()[name]
// }
//
// exports.setSettings = (obj) => {
//   const api = require('scripts/api')
//
//   // inefficient if setting multiple settings, may revisit, but it works for now
//   Object.keys(obj).forEach(key => {
//     api.post(`snips/${key}`, {value: obj[key]}).then(()=> {
//       exports.loadScript(`api/v1/static/cache/settings.js`, 'lp_settings')
//     })
//   })
// }
