import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

class TextArea extends React.Component {
  state = {
    focused: null,
  };

  focus = () => {
    return this.input.focus();
  };

  value = () => {
    return this.input.value;
  };

  onFocus = () => {
    this.setState({ focused: true });
  };

  onBlur = () => {
    this.setState({ focused: false });
  };

  render() {
    const { focused } = this.state;
    const {
      className,
      value,
      label,
      maxLength,
      placeholder,
      onChange,
      onKeyUp,
      onKeyDown,
      disabled,
      rows,
      error,
      resize,
      name,
      onBlur,
      onFocus,
      afterLabel,
      showLabel,
      classNameForLabel,
      isValid,
    } = this.props;
    const textValue = value || "";

    let wrapperClassName = `input-text-area-wrapper flex-column ${
      error ? "has-error" : ""
    }`;
    let inputClassName = `input-text-area ${className || ""}`;
    if (focused) {
      wrapperClassName += " focused";
    }
    if (textValue) {
      inputClassName += " input-text-area--has-value";
    }

    if (isValid) {
      inputClassName += " is-valid";
    }
    return (
      <div className={wrapperClassName}>
        {showLabel && (
          <span className={`text-area-label ${classNameForLabel}`}>
            {label || "label"}{" "}
          </span>
        )}
        {afterLabel && <span className="helper-text">{afterLabel}</span>}
        <textarea
          ref={(c) => {
            this.textarea = c;
          }}
          rows={rows || 3}
          maxLength={maxLength}
          data-resize={resize || "none"}
          placeholder={placeholder || ""}
          className={inputClassName}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          onFocus={onFocus || this.onFocus.bind(this)}
          onBlur={onBlur || this.onBlur.bind(this)}
          disabled={disabled}
          name={name}
          value={textValue || ""}
        />
        {maxLength && (
          <span className="input-text-area-wrapper__length">
            {textValue.length}/{maxLength}
          </span>
        )}
      </div>
    );
  }
}

TextArea.propTypes = {
  resize: PropTypes.string,
  rows: PropTypes.number,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  afterLabel: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyUp: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.any,
  name: PropTypes.string,
  hasCheckMark: PropTypes.bool,
  showLabel: PropTypes.bool,
  classNameForLabel: PropTypes.string,
  isValid: PropTypes.any,
};

export default TextArea;
