import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import cloneDeep from "lodash.clonedeep";
import InputRange from "react-input-range";
import * as generalActions from "../../actions/generalActions";
import DropDown from "../../components/drop-down";
import Button from "../../components/button";
import Modal from "../../components/modal";
import { LEFT_PANEL_FILTERS } from "../../constants";
import SearchInput from "../../components/search-input";
import CheckBoxList from "../../components/check-box-list";
import RadioList from "../../components/radio-list";
import InputText from "../../components/input-text";
import ToolTip from "../../components/tooltip";
import {
  GENDERS,
  INTERESTS,
  BODY_TYPE,
  ETHNICITY,
  RELIGION,
  HAS_KIDS,
  FUTURE_CHILD_SCENARIOS,
  MARRIAGE_STATUSES,
  LEVEL_OF_EDUCATION,
  LAST_RELATIONSHIP,
  DRINKING_TYPE,
  SMOKING_TYPE,
  DRUGS_TYPE,
  EATING_HABITS,
  POLITICAL_ORIENTATION,
  HOBBIES,
  WORKOUT,
  FINANCIAL_STABILITY,
  RELATIONSHIP_INTEREST,
  RELIGION_PREFERENCE,
  POLITICAL_PREFERENCE,
  ETHNICITY_REQUIREMENTS,
} from "../../constants/onboarding-data-elements";

import "react-input-range/lib/css/index.css";
import "./style.scss";

class LeftFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ageValue: { min: 18, max: 100 },
      search: "",
      savedFiltersValue: "",
      showFilterToolTip: false,
      selectedSavedFilter: null,
    };
  }

  static getDerivedStateFromProps(props) {
    if (props.generalReducer.resettingFilter) {
      return {
        selectedSavedFilter: null,
      };
    }
    return null;
  }

  render() {
    const {
      savedSelectedFilters,
      openFiltersModal,
      selectedFiltersModal,
      leftPanelFilters,
      openSaveFiltersModal,
    } = this.props.generalReducer;
    const {
      openCloseFilterModalAndSetFilter,
      openCloseSaveFiltersModal,
      saveSelectedFilters,
      setSavedFilterUserFilter,
      searchDb,
    } = this.props.generalActions;
    const { search, savedFiltersValue } = this.state;
    // const { history } = this.props;
    const showSaveFiltersButton = !(
      Object.entries(leftPanelFilters).length === 0 &&
      leftPanelFilters.constructor === Object
    );
    const filterModalContent =
      selectedFiltersModal &&
      this._renderModalContent(
        selectedFiltersModal,
        search,
        openCloseFilterModalAndSetFilter
      );
    const saveFiltersModalContent =
      openSaveFiltersModal &&
      this._renderSaveFiltersModalContent(
        savedFiltersValue,
        openCloseSaveFiltersModal,
        saveSelectedFilters,
        leftPanelFilters,
        savedSelectedFilters
      );
    const savedFilters = Object.entries(savedSelectedFilters).map((ft, f) => {
      return { value: ft[0], label: ft[0], id: f };
    });
    const renderMyFilters = this._renderMyFilters(
      savedFilters,
      savedSelectedFilters,
      setSavedFilterUserFilter,
      searchDb
    );
    const renderBasicsFilters = this._renderSectionFilters(
      "Basics",
      LEFT_PANEL_FILTERS.slice(0, 9),
      openCloseFilterModalAndSetFilter
    );
    const renderBackgroundFilters = this._renderSectionFilters(
      "Background",
      LEFT_PANEL_FILTERS.slice(9, 11),
      openCloseFilterModalAndSetFilter
    );
    const renderLifestyleFilters = this._renderSectionFilters(
      "Lifestyle",
      LEFT_PANEL_FILTERS.slice(11, 19),
      openCloseFilterModalAndSetFilter
    );
    const renderPartnerCriteriaFilters = this._renderSectionFilters(
      "Partner criteria",
      LEFT_PANEL_FILTERS.slice(19, 24),
      openCloseFilterModalAndSetFilter
    );
    return (
      <div className="left-filters-container flex-column">
        {/* <div className='left-filters-container__add-user-matchmaker flex-column'>*/}
        {/* <Button value='+ADD MEMBER' customClass='yellow'*/}
        {/* click={() => history.push({ pathname: '/the-basics', section: 'theBasics' })}/>*/}
        {/* </div>*/}
        {renderMyFilters}

        <div
          className={`left-filters-container__filters flex-column ${
            showSaveFiltersButton ? "more-padding" : ""
          }`}
        >
          {renderBasicsFilters}
          {renderBackgroundFilters}
          {renderLifestyleFilters}
          {renderPartnerCriteriaFilters}
        </div>
        {showSaveFiltersButton && (
          <div className="left-filters-container__save-current-filters">
            <Button
              value="save current filters"
              click={() => openCloseSaveFiltersModal(true)}
            />
          </div>
        )}
        {openFiltersModal && (
          <Modal
            isOpen={openFiltersModal}
            closeOnClickOutside={true}
            className={`filters-modal ${selectedFiltersModal.filter}`}
            modalContent={filterModalContent}
            hideModal={() => openCloseFilterModalAndSetFilter(false, null)}
          />
        )}
        {openSaveFiltersModal && (
          <Modal
            isOpen={openSaveFiltersModal}
            closeOnClickOutside={true}
            className="save-filters-modal"
            modalContent={saveFiltersModalContent}
            hideModal={() => openCloseSaveFiltersModal(false)}
          />
        )}
      </div>
    );
  }

  _renderSectionFilters = (
    title,
    filters,
    openCloseFilterModalAndSetFilter
  ) => {
    return (
      <div style={{ marginBottom: "20px" }}>
        <span className="filters-section">{title}</span>
        <div className="left-filters-container__filter-list">
          {filters.map((filter, f) => {
            return (
              <div
                className="filter flex-row"
                key={f}
                onClick={() => openCloseFilterModalAndSetFilter(true, filter)}
              >
                <span className="name">{filter.filterName}</span>
                <span className="plus float-right flex-center">+</span>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  _renderMyFilters = (
    savedFilters,
    savedSelectedFilters,
    setSavedFilterUserFilter,
    searchDb
  ) => {
    const { showFilterToolTip, selectedSavedFilter } = this.state;
    return (
      <div className="left-filters-container__my-selected-filters">
        <div className="flex-row">
          <span className="title">My Filters</span>
          <span
            className="icon-question-mark"
            onMouseEnter={() => this.setState({ showFilterToolTip: true })}
            onMouseLeave={() => this.setState({ showFilterToolTip: false })}
          />
          {showFilterToolTip && (
            <ToolTip text="Save filters for specific clients or searches you want to perform repeatedly." />
          )}
        </div>
        <DropDown
          listOfValues={savedFilters || []}
          selectedValue={selectedSavedFilter || null}
          name="savedFilters"
          placeholder="Select..."
          onSelectValue={(savedFilter) => {
            setSavedFilterUserFilter(savedSelectedFilters[savedFilter.value]);
            searchDb(savedSelectedFilters[savedFilter.value]);
            this.setState({ selectedSavedFilter: savedFilter });
          }}
        />
      </div>
    );
  };

  _renderModalContent = (
    selectedModal,
    search,
    openCloseFilterModalAndSetFilter
  ) => {
    const content = this._renderFilterContent(selectedModal.filter);
    let modalTitle = selectedModal.filterName;
    if (selectedModal.filter === "futureChildScenarios") {
      modalTitle = "Future Child Scenarios Open To";
    }
    return (
      <div className={`${selectedModal.filter}`}>
        <div className="modal-header flex-row">
          <span>{modalTitle}</span>
          {selectedModal.search && (
            <SearchInput
              value={search}
              customClass="filter-modal-search"
              onChange={(e) => this.setState({ search: e.target.value })}
              placeholder="Search ..."
            />
          )}
          <span
            className="icon-close-button grey float-right"
            onClick={() => openCloseFilterModalAndSetFilter(false, null)}
          />
        </div>
        <div className="modal-content">{content}</div>
      </div>
    );
  };

  _renderAge = (ageValue) => {
    const { openCloseFilterModalAndSetFilter } = this.props.generalActions;
    return (
      <div>
        <InputRange
          step={1}
          maxValue={100}
          minValue={18}
          value={ageValue}
          // onChangeComplete={val => this.changeFilters([val], 'ageValue')}
          onChange={(val) => this.setState({ ageValue: val })}
        />
        <div className="flex-row">
          <InputText
            wrapperClassName="flex-column"
            type={"number"}
            max={ageValue.max}
            onChange={(e) => {
              const val = e.target.value;
              if (val.length < 3) {
                this.setState({
                  ageValue: { ...ageValue, min: parseInt(val, 10) },
                });
              }
            }}
            value={ageValue.min}
          />
          <InputText
            wrapperClassName="flex-column"
            type={"number"}
            min={ageValue.min}
            onChange={(e) => {
              const val = e.target.value;
              if (val.length < 3) {
                this.setState({
                  ageValue: { ...ageValue, max: parseInt(val, 10) },
                });
              }
            }}
            value={ageValue.max}
          />
        </div>
        <div className="text-right">
          <Button
            className="btn btn-primary accept-btn"
            click={() => {
              this.changeFilters([ageValue], "ageValue");
              openCloseFilterModalAndSetFilter(false, null);
            }}
            value="Accept"
          />
        </div>
      </div>
    );
  };

  _renderFilterContent = (filter) => {
    const {
      gender,
      interestedIn,
      bodyType,
      ethnicity,
      religionPracticed,
      hasKids,
      futureChildScenarios,
      relationshipStatus,
      education,
      longestRelationship,
      drinking,
      smoking,
      drugs,
      eatingHabit,
      politicalOrientation,
      hobbies,
      workout,
      financialStability,
      relationshipInterest,
      religionPreference,
      politicalPreference,
      ethnicityRequired,
    } = this.props.generalReducer.leftPanelFilters;
    const { ageValue } = this.state;
    const renderAge = this._renderAge(ageValue);
    let content = null;
    switch (filter) {
      case "gender":
        content = (
          <CheckBoxList
            list={GENDERS}
            selectedValue={gender}
            stateCallback={(val) => this.changeFilters(val, "gender")}
          />
        );
        break;
      case "interestedIn":
        content = (
          <CheckBoxList
            list={INTERESTS}
            selectedValue={interestedIn}
            stateCallback={(val) => this.changeFilters(val, "interestedIn")}
          />
        );
        break;
      case "age":
        content = renderAge;
        break;
      case "bodyType":
        content = (
          <CheckBoxList
            list={BODY_TYPE}
            selectedValue={bodyType}
            stateCallback={(val) => this.changeFilters(val, "bodyType")}
          />
        );
        break;
      case "ethnicity":
        content = (
          <CheckBoxList
            list={ETHNICITY}
            selectedValue={ethnicity}
            stateCallback={(val) => this.changeFilters(val, "ethnicity")}
          />
        );
        break;
      case "religionPracticed":
        content = (
          <CheckBoxList
            list={RELIGION}
            selectedValue={religionPracticed}
            stateCallback={(val) =>
              this.changeFilters(val, "religionPracticed")
            }
          />
        );
        break;
      case "hasKids":
        content = (
          <RadioList
            list={HAS_KIDS}
            selectedValue={hasKids}
            stateCallback={(val) => this.changeFilters([val], "hasKids")}
          />
        );
        break;
      case "futureChildScenarios":
        content = (
          <CheckBoxList
            list={FUTURE_CHILD_SCENARIOS}
            selectedValue={futureChildScenarios}
            stateCallback={(val) =>
              this.changeFilters(val, "futureChildScenarios")
            }
          />
        );
        break;
      case "relationshipStatus":
        content = (
          <CheckBoxList
            list={MARRIAGE_STATUSES}
            selectedValue={relationshipStatus}
            stateCallback={(val) =>
              this.changeFilters(val, "relationshipStatus")
            }
          />
        );
        break;
      case "education":
        content = (
          <CheckBoxList
            list={LEVEL_OF_EDUCATION}
            selectedValue={education}
            stateCallback={(val) => this.changeFilters(val, "education")}
          />
        );
        break;
      case "longestRelationship":
        content = (
          <CheckBoxList
            list={LAST_RELATIONSHIP}
            selectedValue={longestRelationship}
            stateCallback={(val) =>
              this.changeFilters(val, "longestRelationship")
            }
          />
        );
        break;
      case "drinking":
        content = (
          <CheckBoxList
            list={DRINKING_TYPE}
            selectedValue={drinking}
            stateCallback={(val) => this.changeFilters(val, "drinking")}
          />
        );
        break;
      case "smoking":
        content = (
          <CheckBoxList
            list={SMOKING_TYPE}
            selectedValue={smoking}
            stateCallback={(val) => this.changeFilters(val, "smoking")}
          />
        );
        break;
      case "drugs":
        content = (
          <CheckBoxList
            list={DRUGS_TYPE}
            selectedValue={drugs}
            stateCallback={(val) => this.changeFilters(val, "drugs")}
          />
        );
        break;
      case "eatingHabit":
        content = (
          <CheckBoxList
            list={EATING_HABITS}
            selectedValue={eatingHabit}
            stateCallback={(val) => this.changeFilters(val, "eatingHabit")}
          />
        );
        break;
      case "politicalOrientation":
        content = (
          <CheckBoxList
            list={POLITICAL_ORIENTATION}
            selectedValue={politicalOrientation}
            stateCallback={(val) =>
              this.changeFilters(val, "politicalOrientation")
            }
          />
        );
        break;
      case "hobbies":
        content = (
          <CheckBoxList
            list={HOBBIES}
            selectedValue={hobbies}
            extraClass="half-size-list"
            stateCallback={(val) => this.changeFilters(val, "hobbies")}
          />
        );
        break;
      case "workout":
        content = (
          <CheckBoxList
            list={WORKOUT}
            selectedValue={workout}
            stateCallback={(val) => this.changeFilters(val, "workout")}
          />
        );
        break;
      case "financialStability":
        content = (
          <div>
            <span>Not at all stable</span>
            <CheckBoxList
              list={FINANCIAL_STABILITY}
              selectedValue={financialStability}
              stateCallback={(val) =>
                this.changeFilters(val, "financialStability")
              }
            />
            <span>Extremely stable</span>
          </div>
        );
        break;
      case "relationshipInterest":
        content = (
          <CheckBoxList
            list={RELATIONSHIP_INTEREST}
            selectedValue={relationshipInterest}
            stateCallback={(val) =>
              this.changeFilters(val, "relationshipInterest")
            }
          />
        );
        break;
      case "religionPreference":
        content = (
          <div>
            <span>Not important</span>
            <CheckBoxList
              list={RELIGION_PREFERENCE}
              selectedValue={religionPreference}
              stateCallback={(val) =>
                this.changeFilters(val, "religionPreference")
              }
            />
            <span>Very important</span>
          </div>
        );
        break;
      case "politicalPreference":
        content = (
          <div>
            <span>Not important</span>
            <CheckBoxList
              list={POLITICAL_PREFERENCE}
              selectedValue={politicalPreference}
              stateCallback={(val) =>
                this.changeFilters(val, "politicalPreference")
              }
            />
            <span>Very important</span>
          </div>
        );
        break;
      case "ethnicityRequired":
        content = (
          <CheckBoxList
            list={ETHNICITY_REQUIREMENTS}
            selectedValue={ethnicityRequired}
            stateCallback={(val) =>
              this.changeFilters(val, "ethnicityRequired")
            }
          />
        );
        break;
      default:
        break;
    }
    return content;
  };

  changeFilters = (val, field) => {
    const { setLeftPanelFilters, searchDb, resetLeftPanelFilter } =
      this.props.generalActions;
    const { leftPanelFilters } = this.props.generalReducer;
    const appliedFilters = cloneDeep(leftPanelFilters);
    if (val.length > 0) {
      setLeftPanelFilters({ ...leftPanelFilters, [field]: val });
      searchDb({ ...leftPanelFilters, [field]: val });
    } else {
      delete appliedFilters[field];
      resetLeftPanelFilter(field);
      searchDb(appliedFilters);
    }
  };

  _renderSaveFiltersModalContent = (
    savedFiltersValue,
    openCloseSaveFiltersModal,
    saveSelectedFilters,
    leftPanelFilters,
    savedSelectedFilters
  ) => {
    return (
      <div className="save-filters">
        <span className="save-filters__title">Save filters as</span>
        <InputText
          wrapperClassName="flex-column"
          placeholder="Filter Name"
          label="Last Name"
          onChange={(e) => this.setState({ savedFiltersValue: e.target.value })}
          value={savedFiltersValue}
          afterLabel="Use the same name to overwrite existing filter"
        />
        <div className="save-filters__buttons flex-row">
          <Button
            value="save"
            customClass="yellow float-right"
            disabled={!savedFiltersValue}
            click={() => {
              saveSelectedFilters(
                savedFiltersValue,
                leftPanelFilters,
                savedSelectedFilters
              );
              this.setState({ savedFiltersValue: "" });
            }}
          />
          <Button
            value="cancel"
            click={() => {
              openCloseSaveFiltersModal(false);
              this.setState({ savedFiltersValue: "" });
            }}
          />
        </div>
      </div>
    );
  };
}

LeftFilters.propTypes = {
  history: PropTypes.object,
  generalActions: PropTypes.object,
  generalReducer: PropTypes.object,
  matchmakersReducer: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return {
    generalActions: bindActionCreators(generalActions, dispatch),
  };
};

const mapStateToProps = (state) => {
  return {
    generalReducer: state.generalReducer,
    matchmakersReducer: state.matchmakersReducer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftFilters);
