import React, { useEffect } from "react";
import Button from "_old/src/components/button";

function AdminSideBar({ history, adminReducer, generalActions}) {
    return (
        <div className="admin-dashboard-container__left-panel ">
            <Button
                value="+ ADD MEMBER"
                customClass="yellow"
                click={() =>
                    history.push({
                        pathname: "/the-basics",
                        section: "theBasics",
                    })
                }
            />
            <Button
                value="+ ADD MATCHMAKER"
                click={() =>
                    history.push({
                        pathname: "/welcome-partners",
                        section: "admin",
                    })
                }
            />
            <div className="admin-dashboard-container__left-panel__content flex-column">
                <GeneralInfo adminReducer={adminReducer} generalActions={generalActions} />
            </div>
        </div>
    );
}


const GeneralInfo = ({ adminReducer, generalActions }) => {
    const {
        approvedMatchMakers,
        pendingMatchMakers,
        totalMembersCount,
        premiumMembersCount,
        freeRouteMembersCount,
        freemiumMembersCount,
        historicalMembersCount,
    } = adminReducer;

    const totalMM =
        approvedMatchMakers &&
        pendingMatchMakers &&
        approvedMatchMakers.length + pendingMatchMakers.length;

    useEffect(() => {
        if (!totalMembersCount) {
            generalActions.getTotalMembersCount();
        }
    }, [])

    return (
        <div className="general-info">
            <div className="flex-row">
                <span className="column-title">Total Members</span>
                <span className="flex-center float-right numbers">
                    {totalMembersCount}
                </span>
                <span className="flex-center percentage empty" />
            </div>
            <div className="flex-row">
                <span className="column-title">Free Route Members</span>
                <span className="flex-center float-right numbers">
                    {freeRouteMembersCount}
                </span>
                <span className="flex-center percentage">
                    {" "}
                    {totalMembersCount > 0
                        ? percentage(freeRouteMembersCount, totalMembersCount)
                        : " - "}
                </span>
            </div>
            <div className="flex-row">
                <span className="column-title">Freemium Members</span>
                <span className="flex-center float-right numbers">
                    {freemiumMembersCount}
                </span>
                <span className="flex-center percentage">
                    {totalMembersCount > 0
                        ? percentage(freemiumMembersCount, totalMembersCount)
                        : " - "}
                </span>
            </div>
            <div className="flex-row">
                <span className="column-title">Premium Members</span>
                <span className="flex-center float-right numbers">
                    {premiumMembersCount}
                </span>
                <span className="flex-center percentage">
                    {totalMembersCount > 0
                        ? percentage(premiumMembersCount, totalMembersCount)
                        : " - "}
                </span>
            </div>
            <div className="flex-row">
                <span className="column-title">Partner Matchmakers</span>
                <span className="flex-center float-right numbers">
                    {approvedMatchMakers && approvedMatchMakers.length}
                </span>
                <span className="flex-center percentage">
                    {percentage(approvedMatchMakers.length, totalMM)}
                </span>
            </div>
            <div className="flex-row">
                <span className="column-title">Pending Matchmakers</span>
                <span className="flex-center float-right numbers">
                    {pendingMatchMakers && pendingMatchMakers.length}
                </span>
                <span className="flex-center percentage">
                    {percentage(pendingMatchMakers.length, totalMM)}
                </span>
            </div>
            <div className="flex-row">
                <span className="column-title">Historical Signups</span>
                <span className="flex-center float-right numbers">
                    {historicalMembersCount}
                </span>
                <span className="flex-center percentage empty" />
            </div>
            <div className="flex-row">
                <span className="column-title">Deleted Members</span>
                <span className="flex-center float-right numbers">
                    {historicalMembersCount - totalMembersCount}
                </span>
                <span className="flex-center percentage empty" />
            </div>
        </div>
    );
}

const percentage = (partialValue, totalValue) => {
    const percentage = (100 * partialValue) / totalValue;
    return `${Math.round(percentage)}%`;
};

export default AdminSideBar;
