@import "../../../../../src/scss/utilities";
@import "../../../../../src/scss/variables";

.settings-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__title-container {
    border-bottom: 1px solid #bfbfbf;

    h1 {
      color: $textPrimary;
      font-size: 20px;
      font-weight: 600;
      margin: 0 0 0.5rem 0;
    }
  }

  &__text {
    color: $textPrimary;
    font-size: 1rem;
    margin-left: 1rem;
    margin-top: 0.5rem;
  }

  .phone-number {
    input {
      width: 100%;

      @include for-tablet-down {
        width: unset;
      }
    }
  }

  .no-underline {
    text-decoration: none;
  }

  .agree-terms {
    font-size: 14px;
    margin: 1em 0 2em !important;
    letter-spacing: -0.1px;

    @media (min-device-width: 771px) {
      margin: 1em 0 0.5em !important;
    }

    .underlined {
      color: #7dc0bd;
      cursor: pointer;
      padding: 0 3px;
      text-decoration: underline;
    }

    span {
      // margin-top: -18px;
      // font-size: 12px;
      display: inline-flex;
    }

    .error-custom {
      font-family: "Montserrat", sans-serif;
      color: #ff596f;
      font-size: 12px;
      font-weight: 400;
      padding-left: 1em;

      &.agree-terms {
        margin: -15px 0 10px 32px;
      }
    }
  }

  .icon-question-mark {
    // font-size: .75em;
    padding-left: 0.5em;
    cursor: pointer;
  }

  .premium-modal {
    margin: 5% 10% 5% 10%;
    text-align: center;
    display: flex;
    flex-direction: column;

    h1 {
      color: $teal;
      text-align: center;
    }

    .icon-close-button {
      position: absolute;
      right: 2%;
      top: 2%;
    }

    .premium-modal-terms {
      padding-top: 0.5em;
    }
  }

  .update-rel-status {
    padding: 0;
    width: 287px;
  }

  .accept-terms-button {
    padding: 0;
    width: 25% !important;
    margin: auto;

    @include for-tablet-down {
      width: 100% !important;
    }
  }

  .drop-down-wrapper {
    width: 287px;
  }

  .change-to-paid {
    color: rgba(84, 88, 90, 0.5);
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    max-width: 288px;
  }

  .subscription-label {
    color: $teal;
    font-family: Montserrat, sans-serif;
    font-size: 18px;
  }

  .email-subscription {
    border: 1px solid rgba(84, 88, 90, 0.2);
    border-radius: 4px;
    height: 40px;
    padding: 0 12px;
    gap: 1rem;

    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    @include for-tablet-down {
      width: unset;
    }
  }

  .active-status {
    color: $teal;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 600;
  }

  .change-status {
    color: #ff596f;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 600;
    text-decoration: underline;
  }
}
