import React, {useEffect} from "react"
export default function Metrics({adminReducer, generalActions}) {
    const {
        pendingMatchmakers,
        partnerMatchmakers,
        totalMM,
        totalMembersCount,
        premiumMembersCount,
        freeRouteMembersCount,
        historicalMembersCount,
    } = adminReducer;

    useEffect(() => {
        if (!totalMembersCount) {
            generalActions.getTotalMembersCount();
        }
    }, [])
    console.log("totalMM", totalMM);

    return (
        <div className="general-info">
            <div className="flex-row">
                <span className="column-title">Total Members</span>
                <span className="flex-center float-right numbers">
                    {totalMembersCount}
                </span>
                <span className="flex-center percentage empty" />
            </div>
            <div className="flex-row">
                <span className="column-title">Candidates</span>
                <span className="flex-center float-right numbers">
                    {freeRouteMembersCount}
                </span>
                <span className="flex-center percentage">
                    {" "}
                    {totalMembersCount > 0
                        ? percentage(freeRouteMembersCount, totalMembersCount)
                        : " - "}
                </span>
            </div>
            <div className="flex-row">
                <span className="column-title">Clients</span>
                <span className="flex-center float-right numbers">
                    {premiumMembersCount}
                </span>
                <span className="flex-center percentage">
                    {totalMembersCount > 0
                        ? percentage(premiumMembersCount, totalMembersCount)
                        : " - "}
                </span>
            </div>
            <div className="flex-row">
                <span className="column-title">Partner Matchmakers</span>
                <span className="flex-center float-right numbers">
                    {partnerMatchmakers}
                </span>
                <span className="flex-center percentage">
                    {totalMM >0
                        ? percentage(partnerMatchmakers, totalMM)
                        : " - "}
                </span>
            </div>
            <div className="flex-row">
                <span className="column-title">Pending Matchmakers</span>
                <span className="flex-center float-right numbers">
                    {pendingMatchmakers}
                </span>
                <span className="flex-center percentage">
                    {totalMM > 0
                        ? percentage(pendingMatchmakers, totalMM)
                        : " - "}
                </span>
            </div>
            <div className="flex-row">
                <span className="column-title">Historical Signups</span>
                <span className="flex-center float-right numbers">
                    {historicalMembersCount}
                </span>
                <span className="flex-center percentage empty" />
            </div>
            <div className="flex-row">
                <span className="column-title">Deleted Members</span>
                <span className="flex-center float-right numbers">
                    {historicalMembersCount - totalMembersCount}
                </span>
                <span className="flex-center percentage empty" />
            </div>
        </div>
    );
}


const percentage = (partialValue, totalValue) => {
    console.log(partialValue + "------------" + totalValue)
    const percentage = (100 * partialValue) / totalValue;
    return `${Math.round(percentage)}%`;
};