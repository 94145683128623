import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as generalActions from "../../../actions/generalActions";
import * as adminActions from "../../../actions/adminActions";
import * as matchMakerActions from "../../../actions/matchmakersActions";
import MainHeader from "../../../components/main-header";
import UsersList from "./users-list";
import { ADMIN_URLS_TO_TABS } from "../../../constants";
import PlanList from "./plan-list";
import Profile from "../../../components/profile-component";
import Button from "../../../components/button";
import LoadingModal from "../../../components/loading-modal";
import Modal from "../../../components/modal";
import UserInfoModalContent from "../modals/user-info-modal-content";

import "../style.scss";
import AdminSingles from "src/components/pages/dashboards/admin/adminSingles/AdminSingles";
import AdminSideBar from "src/components/SideBar/AdminSideBar";
import MMList from "src/components/pages/dashboards/admin/mmList/MMList";
import { ToastContainer } from "react-toastify";

class AdminDashboard extends Component {
  componentDidMount() {
    document.body.classList.add("no-scroll");
    this.switchSectionFromUrl();
  }

  switchSectionFromUrl = () => {
    // Check if the section wasn't changed through URL change (push state, browser back button, refresh, etc)
    const { section } = this.props.history.location;
    const urlSection = ADMIN_URLS_TO_TABS[window.location.pathname];
    if (urlSection && urlSection !== section) {
      this.props.generalActions.setSelectedHeaderTab(urlSection);
    }
  };

  render() {
    const { history } = this.props;
    const { selectedTab, loadingModal, requestedUserData, showUserInfo } =
      this.props.generalReducer;
    const { closeUserInfoModal } = this.props.generalActions;

    return (
      <>
        <ToastContainer position="top-right" />
        <div className={loadingModal.show ? "loading-modal-opened" : ""}>
          <div className="admin-dashboard-container flex-column">
            <div className="flex-row admin-dashboard-container__sections">
              <AdminSideBar
                history={history}
                adminReducer={this.props.adminReducer}
                generalActions={this.props.generalActions}
              />
              <div className="admin-dashboard-container__right-panel">
                {this._renderLists(history, selectedTab)}
              </div>
            </div>
          </div>

          {loadingModal.show && (
            <LoadingModal
              error={loadingModal.error}
              loading={loadingModal.loading}
            />
          )}

          {showUserInfo && (
            <Modal
              isOpen={showUserInfo}
              className="user-info-modal"
              modalContent={
                <UserInfoModalContent
                  requestedUserData={requestedUserData}
                  isAdminUser={true}
                  adminActions={this.props.adminActions}
                  closeUserInfoModal={closeUserInfoModal}
                  matchMakerActions={matchMakerActions}
                />
              }
            />
          )}
        </div>
      </>
    );
  }

  _renderLists = (history, selectedTab) => {
    let sectionContent = "";
    switch (selectedTab) {
      case "requests":
        sectionContent = <MMList />;
        break;
      case "singles":
        sectionContent = (
          <AdminSingles adminActions={this.props.adminActions} />
        );
        break;
      case "matchmakers":
        sectionContent = <MMList />;
        break;
      case "plans":
        sectionContent = (
          <PlanList
            adminActions={adminActions}
            adminReducer={this.props.adminReducer}
            history={history}
          />
        );
        break;
      default:
        break;
    }

    return sectionContent;
  };
}

AdminDashboard.propTypes = {
  history: PropTypes.object,
  generalActions: PropTypes.object,
  matchMakerActions: PropTypes.object,
  adminActions: PropTypes.object,
  generalReducer: PropTypes.object,
  adminReducer: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return {
    generalActions: bindActionCreators(generalActions, dispatch),
    matchMakerActions: bindActionCreators(matchMakerActions, dispatch),
    adminActions: bindActionCreators(adminActions, dispatch),
  };
};

const mapStateToProps = (state) => {
  return {
    generalReducer: state.generalReducer,
    adminReducer: state.adminReducer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);
