// Deps
import React from "react";
import "./Footer.scss";
// Constants
import datespotLogoSvg from "../../images/datespot-logo-w-label.svg";
import {
  SOCIAL_LINKS,
  SERVICES_LINKS,
  RESOURCES_LINKS,
  COMPANY_LINKS,
} from "./constants";
import { redirectToHome } from "../../utils/functions";

const FooterLink = ({ linkObj }) => (
  <a
    href={`${process.env.MARKETING_SITE_URL}${linkObj.url}`}
    className="footer__link"
    target="_blank"
  >
    {linkObj.label}
  </a>
);

const Footer = ({ history }) => {
  const redirectToSocialSite = (url) => window.open(url, "_blank");

  return (
    <footer className="footer__container">
      <img
        src={datespotLogoSvg}
        alt="DateSpot Logo"
        className="footer__logo"
        onClick={redirectToHome}
      />
      <div className="footer__social">
        {SOCIAL_LINKS.map((link) => (
          <img
            key={link.id}
            src={link.icon}
            alt={link.alt}
            className="footer__social-icon"
            onClick={() => redirectToSocialSite(link.url)}
          />
        ))}
      </div>
      <div className="footer__links-container">
        <div className="footer__links-column">
          <span className="footer__links-title">Services</span>
          {SERVICES_LINKS.map((linkObj) => (
            <FooterLink key={linkObj.id} linkObj={linkObj} />
          ))}
        </div>
        <div className="footer__links-column">
          <span className="footer__links-title">Resources</span>
          {RESOURCES_LINKS.map((linkObj) => (
            <FooterLink key={linkObj.id} linkObj={linkObj} />
          ))}
        </div>
        <div className="footer__links-column">
          <span className="footer__links-title">Company</span>
          {COMPANY_LINKS.map((linkObj) => (
            <FooterLink key={linkObj.id} linkObj={linkObj} />
          ))}
        </div>
      </div>
      <span className="footer__copyright">
        &#169;2022 DateSpot&#8482; All rights reserved.
      </span>
    </footer>
  );
};

export default Footer;
