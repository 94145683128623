import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "../../../../components/button";

class PlanList extends Component {
  componentDidMount() {
    if (!this.props.adminReducer.plans) {
      this.props.adminActions.loadPlans();
    }
  }

  render() {
    const { plans } = this.props.adminReducer;
    const { history } = this.props;

    if (!plans) {
      return <div className="ds-loader" />;
    }
    return (
      <div className="users-list-container flex-column flex-column">
        <Button
          value={"Add new"}
          customClass={"float-right"}
          click={() => history.push("/plan-details")}
        />
        <div className="users-list plan">
          {plans.map((plan, key) => {
            return (
              <div className="users-list__item flex-row" key={key}>
                <div className="flex-row  user-info" style={{ width: "100%" }}>
                  <div style={{ width: "40%" }}>
                    <span className="name">{plan.name}&nbsp;</span>
                    <span>{plan.shortDesc ? `(${plan.shortDesc})` : ""}</span>
                  </div>
                  <div style={{ fontWeight: "bold", width: "20%" }}>
                    {plan.freeTrial ? "Free Trial" : `$${plan.price} / mo`}
                  </div>
                  <div>
                    {plan.connections === Number.MAX_SAFE_INTEGER
                      ? "Unlimited"
                      : plan.connections}{" "}
                    connections
                  </div>
                </div>
                <Button
                  value="Edit"
                  customClass="yellow float-right"
                  click={() => history.push(`/plan-details?id=${plan.id}`)}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

PlanList.propTypes = {
  history: PropTypes.object.isRequired,
  adminActions: PropTypes.object.isRequired,
  adminReducer: PropTypes.object.isRequired,
};

export default PlanList;
