import {apiPost, confirm, notify} from "../../launchpad";

export const postReload = async (route, options) => {
    options = options || {};
    if (typeof options == "string") {
        options = { message: options };
    }
    const { message } = options;

    const go = async () => {
        const { error } = await apiPost(route);
        if (error) {
            notify(error, { type: "error" });
        } else if (message) {
            notify(message.text || message, {
                type: message.type || "success",
            });
        }
        reloadUserTables();
    };

    if (options.confirm) {
        confirm(options.confirm, go);
    } else {
        await go();
    }
};