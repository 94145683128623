import { combineReducers } from "redux";
import generalReducer from "./generalReducer";
import authenticationReducer from "./authenticationReducer";
import matchmakersReducer from "./matchmakersReducer";
import adminReducer from "./adminReducer";
import singlesReducer from "./singlesReducer";

const rootReducer = combineReducers({
  authenticationReducer,
  generalReducer,
  matchmakersReducer,
  adminReducer,
  singlesReducer,
});

export default rootReducer;
