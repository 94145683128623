import React from "react";
import {
  getGlobal,
  setGlobal,
  loadGlobal,
  createDoc,
  Spinner,
  notify,
  updateDoc,
  deleteDoc,
  Modal,
  Collapsible,
  apiPost,
  helpers,
} from "launchpad";
import _ from "lodash";
import styled from "styled-components";

/***************************************************
  VALIDATION
****************************************************/

const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validate = (value, options) => {
  if (value.checkbox) return { error: false };
  options = options || {};
  let ret = { error: false };

  if (typeof value != "object") {
    value = { text: value };
  }

  for (let entry of Object.entries(value)) {
    const v = entry[1];
    if (options.min && v.length < min)
      ret.error = `must be at least ${min} characters`;
    if (options.max && v.length > max)
      ret.error = `must be less than ${max} characters`;
    if (!v) ret.error = `must not be empty`;
  }
  if (value.password && value.password.length < 6)
    ret.error = `must be at least 6 characters`;
  if (value.email && !emailRegex.test(value.email))
    ret.error = `does not appear to be a valid email address`;
  if (value.number && isNaN(parseFloat(value.number)))
    ret.error = `must be numeric`;

  return ret;
};

const ValidatorContainer = styled.form`
  .validator-error {
    border-color: red !important;
    > div {
      border-color: red !important;
    }
  }
`;

let blurListeners = {};

export class Validator extends React.Component {
  state = { validating: false, checked: [], errored: [], blockUpdates: [] };

  validate = async () => {
    return new Promise((resolve, reject) => {
      this.setState({ validating: true, checked: [], errored: [] }, () => {
        resolve(this.checkChildren());
      });
    });
  };

  componentDidUpdate = () => {
    this.checkChildren();
  };

  messages = [];
  notTimer = null;

  notify = (n) => {
    clearTimeout(this.notTimer);
    const showNotifications = () => {
      this.messages.forEach((x) => {
        notify(x);
      });
      this.messages = [];
    };
    let emptyChecked = this.messages.find((x) =>
      x.includes("all required fields")
    );
    const isEmpty = n.includes("must not be empty");
    if (!emptyChecked) {
      for (let msg of this.messages) {
        if (isEmpty && msg.includes("must not be empty")) {
          this.messages = this.messages.filter(
            (x) => !x.includes("must not be empty")
          );
          this.messages.push("Please fill out all required fields");
          emptyChecked = true;
        }
      }
    }

    if (!(emptyChecked && isEmpty)) this.messages.push(n);
    this.notTimer = setTimeout(showNotifications, 100);
  };

  checkChildren = (options) => {
    options = Object.assign({ notify: true }, options || {});
    let errors = false;
    let { checked, errored, blockUpdates, editing } = this.state;

    if (this.state.validating) {
      let newChecks = [];
      let newErrors = [];
      let newValids = [];

      Object.keys(this.inputs).forEach((key) => {
        const input = this.inputs[key];
        const i = input.component;
        const cn = (i.props && i.props.className) || "";
        if (i && !checked.includes(key) && !cn.includes("optional")) {
          newChecks.push(key);
          let v = (i.props && i.props.value) || this.values[i];
          v = v && v.value !== undefined ? v.value : v;
          const type = (i.props && i.props.type) || i.type || "text";
          const valid = validate({ [type]: v });
          if (valid.error !== false) {
            errors = true;
            if (editing !== key) this.notify(`${input.label} ${valid.error}`);
            if (!errored.includes(key)) {
              newErrors.push(key);
            }
          } else {
            if (errored.includes(key)) {
              newValids.push(key);
            }
          }
        }
      });

      const newErrored = errored
        .concat(newErrors)
        .filter((x) => !newValids.includes(x));
      if (
        newChecks.length > 0 ||
        newErrors.length > 0 ||
        newValids.length > 0
      ) {
        this.setState({
          checked: checked.concat(newChecks),
          errored: newErrored,
        });
      }

      return newErrored.length === 0;
    }

    return errored.length === 0;
  };

  updateValue = (el, key, e) => {
    let v = e.target ? e.target.value : e;
    v = v.value || v;
    this.values[key] = v;
  };

  inputs = {};
  values = {};

  render = () => {
    const { checked, errored, editing } = this.state;
    return (
      <ValidatorContainer
        {...this.props}
        innerRef={(c) => (this.container = c)}
      >
        {React.Children.map(this.props.children, (child, index) => {
          if (!child) return;
          const p = child.props || {};
          let errored = false;
          let onChange = p.onChange;
          let onBlur = p.onBlur;
          let onKeyUp = p.onKeyUp;
          let onFocus = p.onFocus;
          if (p.onChange) {
            const label = p.placeholder || p.name || "Field";
            const key = index + label;
            errored = this.state.errored.includes(key);
            this.inputs[key] = {
              component: child,
              key,
              label,
            };
            onChange = (...args) => {
              this.updateValue(child, key, ...args);
              p.onChange(...args);
            };
            onBlur = (...args) => {
              if ((this.state.editing = key)) this.setState({ editing: null });
              this.setState({ checked: checked.filter((x) => x !== key) });
              if (p.onBlur) p.onBlur(...args);
            };
            onFocus = (...args) => {
              this.setState({ editing: key });
            };
            onKeyUp = (...args) => {
              this.setState({ checked: checked.filter((x) => x !== key) }, () =>
                this.checkChildren()
              );
              if (p.onKeyUp) p.onKeyUp(...args);
            };
          }
          return React.cloneElement(child, {
            index,
            onChange,
            className:
              (p.className || "") + (errored ? " validator-error" : ""),
            onBlur,
            onFocus,
            onKeyUp,
          });
        })}
      </ValidatorContainer>
    );
  };
}

/***************************************************
  END VALIDATION
****************************************************/
