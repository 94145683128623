import {
  LOAD_MATCHMAKER_MATCHES,
  LOAD_FAVORITE_USERS,
  REMOVE_USER_FROM_FAVORITES,
  ADD_USER_TO_FAVORITES,
  OPEN_CLOSE_RATING_MODAL,
  LOAD_MM_PLAN_INFO,
  GET_MM_AGREED_TERMS,
  MM_AGREED_TERMS,
  CHANGE_MM_PLAN,
  ADD_MM_REFERRAL,
  GET_MM_REFERRALS,
} from "../constants/actionTypes";

const initialState = {
  matchmakerMatches: [],
  favoriteUsersList: [],
  isOpenRatingModal: false,
  ratedUser: null,
  loggedMMplan: null,
  hasAgreedWithTermsAndConditions: false,
  loadedAllActions: false,
  changePlanEmailSend: false,
  matchMakerReferrals: null,
};

export default function matchmakersReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_MATCHMAKER_MATCHES: {
      return {
        ...state,
        matchmakerMatches: action.response,
      };
    }

    case LOAD_FAVORITE_USERS: {
      return {
        ...state,
        favoriteUsersList: action.response || [],
      };
    }

    case ADD_USER_TO_FAVORITES: {
      return {
        ...state,
        favoriteUsersList: [...state.favoriteUsersList, { ...action.response }],
      };
    }

    case REMOVE_USER_FROM_FAVORITES: {
      return {
        ...state,
        favoriteUsersList: state.favoriteUsersList.filter(
          (item) => item._id !== action.response.requestId
        ),
      };
    }

    case OPEN_CLOSE_RATING_MODAL: {
      const { userId, mode } = action.response;
      return {
        ...state,
        isOpenRatingModal: !mode,
        ratedUser: mode ? null : userId,
      };
    }

    case LOAD_MM_PLAN_INFO: {
      if (action.response) {
        const { error } = action.response;
        if (error) {
          return { ...state, loggedMMplan: error };
        }
        return { ...state, loggedMMplan: action.response };
      }
      return { ...state };
    }

    case GET_MM_AGREED_TERMS: {
      return {
        ...state,
        hasAgreedWithTermsAndConditions: action.response,
        loadedAllActions: true,
      };
    }
    case MM_AGREED_TERMS: {
      return { ...state, hasAgreedWithTermsAndConditions: action.response };
    }

    case CHANGE_MM_PLAN: {
      if (action.response) {
        return { ...state, changePlanEmailSend: action.response };
      }
      return { ...state };
    }

    case GET_MM_REFERRALS: {
      if (action.response) {
        return { ...state, matchMakerReferrals: action.response };
      }
      return { ...state };
    }

    case ADD_MM_REFERRAL: {
      if (action.response) {
        console.log(action.response);
        return {
          ...state,
          matchMakerReferrals: [...state.matchMakerReferrals, action.response],
        };
      }
      return { ...state };
    }

    default:
      return state;
  }
}
