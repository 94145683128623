import React from "react";

export const UserAction = ({ icon, children, onClick, className }) => {
    const moons = ["heart", "contacted", "lock", "eye"];
    const cn = moons.includes(icon) ? `icon icon-${icon}` : `icon fa fa-${icon}`;
    return (
        <div style={{ display: "flex" , alignItems: "center", gap: "12px", marginBottom: "6px" }}
          className={`user-profile-action ${className || ""}`} onClick={onClick}>
            <span style={{ width: "36px" }} className={cn} />
            <span className="content">{children}</span>
        </div>
    );
};