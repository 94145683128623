// Deps
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
// Components
import datespotLogoSvg from "../images/datespot-logo-w-label.svg";
import { Button } from "./widgets";
import MainContent from "./layouts/MainContent";
// Utils
import { redirectToHome } from "../utils/functions";
import UserSession from "datespot/src/components/user-session";
import { useEventListener } from "../hooks";
import { Thumbnail } from "src/components/Thumbnail/Thumbnail";
import theme from "src/styles/theme";
import { LogoutIcon } from "src/components/icons/LogoutIcon";
import { getGlobal } from "../../launchpad";
import { logoutUser } from "src/utils/client";

const DropdownToggleContext = createContext({});

const getHref = (item) =>
    item.isApp ? item.url : item.url;

const NavBarItem = ({ item, history, nestedItem }) => {
  const chevronRef = useRef(null);
  const popoverRef = useRef(null);

  const toggleExpand = () => {
    chevronRef.current &&
    chevronRef.current.classList.toggle("nav-bar__expanded");
    popoverRef.current &&
    popoverRef.current.classList.toggle("nav-bar__show-popover");
  };

  const triggerCollapse = (e) => {
    e.preventDefault();
    chevronRef.current &&
    chevronRef.current.classList.remove("nav-bar__expanded");
    popoverRef.current &&
    popoverRef.current.classList.remove("nav-bar__show-popover");
  };

  return item.children ? (
      <div
          className="nav-bar__dropdown"
          onClick={toggleExpand}
          onMouseEnter={toggleExpand}
          onMouseLeave={triggerCollapse}
      >
        <div className="nav-bar__nested-item">
          <span className="nav-bar__item">{item.label}</span>
          <span
              ref={chevronRef}
              className="fa fa-chevron-down nav-bar__chevron"
          />
        </div>
        <nav ref={popoverRef} className="nav-bar__popover-menu">
          {item.children.map((nestedItem) => (
              <NavBarItem
                  history={history}
                  key={nestedItem.id}
                  item={nestedItem}
                  nestedItem
              />
          ))}
        </nav>
      </div>
  ) : (
      <Item
          history={history}
          item={item}
          className={`nav-bar__item${nestedItem ? " nested" : ""}`}
      />
  );
};

const HamburgerMenuIcon = ({ open, onClick }) => (
    <i
        onClick={onClick}
        className={`fa fa-bars nav-bar__hamburger-menu__icon${open ? ` open` : ""}`}
    />
);

const HamburgerDropdownMenu = ({
                                 isOpen,
                                 shouldUnmount,
                                 setShouldUnmount,
                                 redirectToSignUp,
                                 options,
                                 history,
                               }) => {
  const handleAnimationEnd = (e) => {
    e.persist();
    setShouldUnmount(false);
  };

  const { activeUser } = getGlobal();

  return (
      <div
          className={`nav-bar__hamburger-menu__content${isOpen || shouldUnmount ? " open" : ""
          }`}
      >
        <nav
            style={{ paddingLeft: "24px" }}
            role="navigation"
            onAnimationEnd={handleAnimationEnd}
            className={`nav-bar__hamburger-menu__links-container${shouldUnmount ? " unmount" : ""
            }`}
        >
          {activeUser && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ color: theme.colors.white, fontWeight: 500, fontSize: "18px" }} >{activeUser?.firstName} {activeUser?.lastName}</p>
              </div>
          )}
          {options.map((item, index) =>
              item.children ? (
                  <ExpandableItem history={history} key={index} item={item} />
              ) : (
                  <Item
                      history={history}
                      key={index}
                      item={item}
                      className="nav-bar__hamburger-menu__item"
                  />
              )
          )}
        </nav>
        <div onClick={logoutUser} style={{
          backgroundColor: "#3FC6C0", padding: "12px", display: "flex", justifyContent: "center",
          alignItems: "center", margin: "0 24px", marginBottom: "32px", borderRadius: "4px", gap: "8px", fontWeight: 600
        }}>
          <LogoutIcon />
          <span style={{ color: theme.colors.white }}>Logout</span>
        </div>
      </div>
  );
};

const Item = ({ item, history, className }) => {
  const { toggleDropdownMenuDisplay, isOpen } = useContext(
      DropdownToggleContext
  );

  const handleClick = (e) => {
    if (isOpen) toggleDropdownMenuDisplay(e);
    history.push(item.url);
  };


  return item.isApp ? (
      <span onClick={item.action || handleClick} className={className}>
      {item.label}
    </span>
  ) : (
      <a onClick={(e) => handleClick(e)} href={item.url} className={className}>
        {item.label}
      </a>
  );
};

const ExpandableItem = ({ item, history }) => {
  const expandableChevronRef = useRef(null);
  const dropdownNavMenuRef = useRef(null);

  const handleExpandItem = (e) => {
    e.preventDefault();
    expandableChevronRef.current &&
    expandableChevronRef.current.classList.toggle("open");
    dropdownNavMenuRef.current &&
    dropdownNavMenuRef.current.classList.toggle("show");
  };

  return (
      <div className="nav-bar__hamburger-menu__nested-item-container">
        <div
            className="nav-bar__hamburger-menu__nested-item"
            onClick={handleExpandItem}
        >
        <span className="nav-bar__hamburger-menu__item borderless">
          {item.label}
        </span>
          <span
              ref={expandableChevronRef}
              className="fa fa-chevron-down nav-bar__hamburger-menu__chevron"
          />
        </div>
        <nav
            ref={dropdownNavMenuRef}
            className="nav-bar__hamburger-menu__nested-links-container"
        >
          {item.children.map((nestedItem) => (
              <Item
                  history={history}
                  item={nestedItem}
                  key={nestedItem.id}
                  className="nav-bar__hamburger-menu__item nested"
              />
          ))}
        </nav>
      </div>
  );
};

const NavBar = ({ history, options }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [shouldUnmount, setShouldUnmount] = useState(false);

  const toggleDropdownMenuDisplay = useCallback(
      (e) => {
        e.preventDefault();
        if (isOpen) setShouldUnmount(true);
        setIsOpen(!isOpen);
      },
      [isOpen]
  );

  useEventListener(
      "click",
      (e) => {
        if (!e.target.closest(".nav-bar__sticky-container") && isOpen) {
          toggleDropdownMenuDisplay(e);
        }
      },
      document
  );

  const redirectToSignUp = (e) => {
    if (isOpen) toggleDropdownMenuDisplay(e);
    history.push(`/welcome-singles`);
  };

  return (
      <DropdownToggleContext.Provider
          value={{ toggleDropdownMenuDisplay, isOpen }}
      >
        <div className="nav-bar__sticky-container">
          <MainContent innerContainer={"large"} borderBottom>
            <div className="nav-bar__container">
              <img
                  src={datespotLogoSvg}
                  alt="DateSpot Logo"
                  className="nav-bar__logo"
                  onClick={redirectToHome}
              />
              <nav role="navigation" className="nav-bar__items-container">
                {options.map((item, index) => (
                    <NavBarItem history={history} key={index} item={item} />
                ))}
              </nav>
              <div className="nav-bar__right-side">
                {!UserSession.hasValidToken() && (
                    <Button
                        onClick={redirectToSignUp}
                        className="nav-bar__join-button"
                        variant="primary"
                    >
                      Join Now
                    </Button>
                )}
                <HamburgerMenuIcon
                    isOpen={isOpen}
                    onClick={toggleDropdownMenuDisplay}
                />
              </div>
            </div>
          </MainContent>
          <HamburgerDropdownMenu
              options={options}
              isOpen={isOpen}
              shouldUnmount={shouldUnmount}
              setShouldUnmount={setShouldUnmount}
              redirectToSignUp={redirectToSignUp}
              history={history}
          />
        </div>
      </DropdownToggleContext.Provider>
  );
};

export default NavBar;
