@import '../../../../src/scss/utilities';
@import "../../../../src/scss/variables";

.pets-dropdown {
  position: relative;
  gap: .5rem;

  &__label {
    color: rgba(84, 88, 90, 0.5);
    font-family: Montserrat, sans-serif;
    font-size: 1.125rem;
    text-align: center;

    > div {
      width: 50%;

      span {
        width: 50%;
      }
    }

    .p-label {
      color: #ff6666;
      font-weight: 500;

      &.green {
        color: $teal;
      }
    }
  }

  &__options {
    max-width: 680px;
    width: 100%;
    gap: 1rem;
  }

  &__option {
    color: #54585A;
    // cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    width: 100%;
    gap: 1rem;

    span {
      width: 47px;
    }

    @include for-tablet-down {
      display: flex;
      flex-direction: column;
      width: unset;
      align-items: flex-start !important;
      gap: 4px;
    }

    &:hover {
      // background-color: rgba(84,88,90,0.1);
      border-radius: 4px;
    }
  }

  &__buttons {
    position: absolute;
    top: 35px;
    right: 0;
    width: calc(50% - 6px);

    .dropdown-list {
      width: 50%;

      .custom-dropdown-button {
        justify-content: center;
        width: 90%;
      }
    }

    .label {
      display: none;
    }
  }
}
