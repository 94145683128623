import React from "react";
import "./index.scss";
export const AccountStatus = ({ isAccountStatusActive, changeStatus }) => {

    const handleOnPress = () => {
      changeStatus(!isAccountStatusActive);
    }

    return (
        <div className="input-wrapper flex-column">
            <span className="subscription-label">Account status</span>
            <div className="email-subscription flex-row">
            <span className="active-status">
              {isAccountStatusActive
                  ? "Active: eligible for matches"
                  : "Inactive: not eligible for matches"}
            </span>
            <span
                className="change-status float-right"
                onClick={handleOnPress}
            >
            Change
            </span>
            </div>
        </div>
    )

}