import React, { Component } from "react";
import PropTypes from "prop-types";
import "./style.scss";

class RatingToolTip extends Component {
  state = {
    selected: this.props.selectedValue || null,
  };

  render() {
    const { list, title, stateCallback } = this.props;
    const { selected } = this.state;

    return (
      <div className="rating-tooltip flex-column">
        {title && <span className="rating-tooltip__title"> {title} </span>}
        <div className="rating-tooltip__buttons flex-row flex-center">
          {list.map((li, l) => {
            const isSelected =
              parseInt(selected, 10) === parseInt(li.value, 10);
            return (
              <div
                key={l}
                className={`rating-tooltip__button flex-center ${
                  isSelected ? "selected" : ""
                }`}
                onClick={() => {
                  this.setState({ selected: li.value });
                  stateCallback(li.value);
                }}
              >
                {li.value}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

RatingToolTip.propTypes = {
  list: PropTypes.array,
  title: PropTypes.string,
  stateCallback: PropTypes.func,
  selectedValue: PropTypes.any,
};

export default RatingToolTip;
