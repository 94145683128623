import React from "react";
import { getHeight } from "/../global_data.js";
import {
  REGIONS,
  matchAreas,
} from "../../../../_old/src/constants/onboarding-data-elements";
import { getRegions } from "data/regions";
import { statesIndex } from "data/states";
import UserRating from "./UserRating";
import { isString } from "lodash";
import { QUALITIES } from "_old/src/constants/onboarding-data-elements";

/*
 This data defines the information and UI displayed on the UserProfile modal component
 and the Filter component (for objects with the filterType field).
 */
const memberSchema = {
  basic: {
    label: "Basics",
    attributes: {
      user_id: {
        label: "Member ID",
        type: "input-text",
        filterType: "input-text",
        writeIn: true,
      },
      gender: {
        label: "Gender",
        type: "radio",
        filterType: "checkboxes",
        options: ["Male", "Female"],
        writeIn: true,
      },
      interestedIn: {
        label: "Interested in",
        type: "checkboxes",
        filterType: "checkboxes",
        options: ["Men", "Women"],
        writeIn: true,
      },
      images: {
        label: "Photos",
        type: "filter",
        filterType: "checkboxes",
        options: ["Has at least one photo"],
      },
      userRating: {
        label: "Attractiveness",
        type: "attractiveness",
        filterType: "range",
        min: 1,
        max: 10,
        options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        display: (user) => <UserRating user={user} />,
      },
      age: {
        label: "Age",
        type: "input",
        filterType: "range",
        min: 18,
        max: 99,
      },
      height: {
        label: "Height",
        type: "height",
        filterType: "range",
        min: 48,
        max: 84,
        translateValue: getHeight,
      },

      bodyTypes: {
        label: "Body types",
        type: "checkboxes",
        filterType: "checkboxes",
        options: [
          "Thin",
          "Muscular/Athletic",
          "Average",
          "Curvy/Well-endowed",
          "A few extra pounds",
          "More than a few extra pounds",
        ],
      },
      hasKids: {
        label: "Has children",
        type: "radio",
        filterType: "radio",
        options: ["Yes", "No"],
      },
      futureChildScenarios: {
        label: "Future child scenarios open to",
        filterType: "checkboxes",
        options: [
          `Having my own kids`,
          `A partner's kids if they want/are open to more biologically`,
          `A partner's kids if they don't want more biologically`,
          `Adopting/fostering`,
          `No kids/no more kids`,
          // `I don't want kids/more kids`,
        ],
      },
      "kids.kidsAges": {
        label: "Children's ages (+/- 1 year)",
        type: "kidsAges",
        display: (user) => {
          const kids = user.kids;
          if (
              !kids ||
              !kids.kidsAges ||
              (user.hasKids && user.hasKids.value === "No")
          ) {
            return <span className="the-basics-section__value">--</span>;
          }

          const kidsAgesJoined = Object.values(kids.kidsAges).join(" & ");

          return (
              <span className="the-basics-section__value">
              {!!kidsAgesJoined ? `${kidsAgesJoined} years old` : "--"}
            </span>
          );
        },
      },

      regions: {
        label: "Location: Metro Area",
        type: "",
        display: (user) => {
          let closestRegion = {};
          for (let region of user.regionData) {
            if (
                !closestRegion.distance ||
                region.distance < closestRegion.distance
            ) {
              closestRegion = region;
            }
          }
          let label;
          if (closestRegion.distance || closestRegion.distance === 0) {
            label =
                closestRegion.label ||
                `${closestRegion.city}, ${closestRegion.state}`;
          }
          if (!label)
            label =
                statesIndex[user.regions[0]] ||
                REGIONS.find((x) => x.value === user.regions[0])?.label ||
                label;

          const locationString = [user.city, user.state, user.country]
              .filter((elem) => elem)
              .join(", ");

          return (
              <div>
                <div>{locationString}</div>
                {label && <div>Region: {label}</div>}
              </div>
          );
        },
        filterType: "select-multi",
        filterDisplay: (v) => {
          const { getGlobal } = require("launchpad");
          const test = getRegions();
          const region = (getGlobal("labeledRegions") || []).find(
              (x) => x.value === v
          );
          return (region || { label: v }).label;
        },
        options: matchAreas,
      },

      state: {
        label: "Location: State",
        filterType: "select-multi",
        options: [
          { id: 2, label: "AL", value: "AL", text: "Alabama" },
          { id: 1, label: "AK", value: "AK", text: "Alaska" },
          { id: 4, label: "AS", value: "AS", text: "American Samoa" },
          { id: 5, label: "AZ", value: "AZ", text: "Arizona" },
          { id: 3, label: "AR", value: "AR", text: "Arkansas" },
          { id: 6, label: "CA", value: "CA", text: "California" },
          { id: 7, label: "CO", value: "CO", text: "Colorado" },
          { id: 8, label: "CT", value: "CT", text: "Connecticut" },
          { id: 10, label: "DE", value: "DE", text: "Delaware" },
          {
            id: 9,
            label: "DC",
            value: "DC",
            text: "District of Columbia",
          },
          { id: 11, label: "FL", value: "FL", text: "Florida" },
          { id: 12, label: "GA", value: "GA", text: "Georgia" },
          { id: 13, label: "GU", value: "GU", text: "Guam" },
          { id: 14, label: "HI", value: "HI", text: "Hawaii" },
          { id: 16, label: "ID", value: "ID", text: "Idaho" },
          { id: 17, label: "IL", value: "IL", text: "Illinois" },
          { id: 18, label: "IN", value: "IN", text: "Indiana" },
          { id: 15, label: "IA", value: "IA", text: "Iowa" },
          { id: 19, label: "KS", value: "KS", text: "Kansas" },
          { id: 20, label: "KY", value: "KY", text: "Kentucky" },
          { id: 21, label: "LA", value: "LA", text: "Louisiana" },
          { id: 24, label: "ME", value: "ME", text: "Maine" },
          { id: 23, label: "MD", value: "MD", text: "Maryland" },
          { id: 22, label: "MA", value: "MA", text: "Massachusetts" },
          { id: 25, label: "MI", value: "MI", text: "Michigan" },
          { id: 26, label: "MN", value: "MN", text: "Minnesota" },
          { id: 28, label: "MS", value: "MS", text: "Mississippi" },
          { id: 27, label: "MO", value: "MO", text: "Missouri" },
          { id: 29, label: "MT", value: "MT", text: "Montana" },
          { id: 32, label: "NE", value: "NE", text: "Nebraska" },
          { id: 36, label: "NV", value: "NV", text: "Nevada" },
          { id: 33, label: "NH", value: "NH", text: "New Hampshire" },
          { id: 34, label: "NJ", value: "NJ", text: "New Jersey" },
          { id: 35, label: "NM", value: "NM", text: "New Mexico" },
          { id: 37, label: "NY", value: "NY", text: "New York" },
          {
            id: 30,
            label: "NC",
            value: "NC",
            text: "North Carolina",
          },
          { id: 31, label: "ND", value: "ND", text: "North Dakota" },
          { id: 38, label: "OH", value: "OH", text: "Ohio" },
          { id: 39, label: "OK", value: "OK", text: "Oklahoma" },
          { id: 40, label: "OR", value: "OR", text: "Oregon" },
          { id: 41, label: "PA", value: "PA", text: "Pennsylvania" },
          { id: 42, label: "PR", value: "PR", text: "Puerto Rico" },
          { id: 43, label: "RI", value: "RI", text: "Rhode Island" },
          {
            id: 44,
            label: "SC",
            value: "SC",
            text: "South Carolina",
          },
          { id: 45, label: "SD", value: "SD", text: "South Dakota" },
          { id: 46, label: "TN", value: "TN", text: "Tennessee" },
          { id: 47, label: "TX", value: "TX", text: "Texas" },
          { id: 48, label: "UT", value: "UT", text: "Utah" },
          { id: 51, label: "VT", value: "VT", text: "Vermont" },
          {
            id: 50,
            label: "VI",
            value: "VI",
            text: "Virgin Islands",
          },
          { id: 49, label: "VA", value: "VA", text: "Virginia" },
          { id: 52, label: "WA", value: "WA", text: "Washington" },
          { id: 54, label: "WV", value: "WV", text: "West Virginia" },
          { id: 53, label: "WI", value: "WI", text: "Wisconsin" },
          { id: 55, label: "WY", value: "WY", text: "Wyoming" },
        ],
      },

      ethnicity: {
        label: "Ethnicity",
        type: "checkboxes",
        filterType: "checkboxes",
        options: [
          "Asian/Pacific Islander",
          "Black or African-American",
          "Caucasian",
          "Hispanic/Latin",
          "Indian",
          "Middle Eastern",
          "Native American",
          "Prefer not to say",
        ],
      },
      religionPracticed: {
        label: "Religion",
        type: "checkboxes",
        filterType: "checkboxes",
        options: [
          "Agnostic",
          "Atheist",
          "Buddhist",
          "Catholic",
          "Christian",
          "Jewish",
          "Hindu",
          "Latter-day Saints",
          "Muslim",
          "Sikh",
          "Spiritual but not religious",
        ],
      },
      clientStatus: {
        label: "DateSpot member type",
        filterType: "checkboxes",
        options: ["Candidate", "Client"],
      },
      "other.blindDates": {
        label: "Type of date open to",
        filterType: "checkboxes",
        hidden: true,
        options: ["Photos shared with them", "Blind dates", "Unknown"],
      },
    },
  },

  background: {
    label: "Background",
    type: "checkboxes",
    attributes: {
      education: {
        label: "Education",
        type: "radio",
        filterType: "checkboxes",
        options: [
          "Less than high school",
          "High school diploma",
          "Some college, no degree",
          "Associates degree",
          "Bachelors degree",
          "Masters degree",
          "Doctoral degree",
        ],
      },
      schoolAttended: {
        label: "School attended",
        type: "input",
        //filterType: 'checkboxes',
        options: [
          /* school list */
        ],
      },
      employmentStatus: {
        label: "Employment status",
        type: "radio",
        filterType: "checkboxes",
        options: [
          "Employee/contractor/temp",
          "Self-employed",
          "Unemployed (in between jobs)",
          "Retired",
        ],
        helpText:
            "Question added Feb 2023 - Many members have not answered this yet but did answer financial stability",
        display: (user) => {
          if (!user.employmentStatus) return "";
          return (
              <>
                <div>{user.employmentStatus.label}</div>
              </>
          );
        },
      },
      occupation: {
        label: "Occupation",
        type: "input",
      },
      company: {
        label: "Company",
        type: "input",
      },
      marriageStatus: {
        label: "Marriage status",
        type: "radio",
        filterType: "checkboxes",
        options: ["Never married", "Divorced", "Separated", "Widowed", "Other"],
      },
      longestRelationship: {
        label: "Longest relationship",
        hidden: true,
        filterType: "checkboxes",
        options: [
          "Less than 3 months",
          "3-6 months",
          "7-11 months",
          "1-2 years",
          "2-5 years",
          "Over 5 years",
        ],
      },
      relationshipStatus: {
        label: "Last known relationship status",
        filterType: "checkboxes",
        options: [
          "Single within past 3 months",
          "Not single within past 3 months",
          "Unknown 3 month status",
        ],
        hidden: true,
      },
      upbringing: {
        label: "Where are you from and how has your upbringing shaped you?",
        type: "textarea",
      },
    },
  },

  lifestyle: {
    label: "Lifestyle",
    attributes: {
      financialStability: {
        label: "Financial stability",
        type: "range",
        filterType: "checkboxes",
        minLabel: "Not at all stable",
        maxLabel: "Extremely stable",
        options: [1, 2, 3, 4, 5],
      },
      yearlyIncome: {
        label: "Average yearly income",
        type: "radio",
        filterType: "checkboxes",
        options: [
          "$0-$59K",
          "$60K-$99K",
          "$100K-$199K",
          "$200K-$499K",
          "$500K-$999K",
          "$1M+",
        ],
        helpText:
            "Question added Feb 2023 - Many members have not answered this yet but did answer financial stability",
        display: (user) => {
          if (!user.yearlyIncome) return "";
          return (
              <>
                <div>{user.yearlyIncome.label}</div>
              </>
          );
        },
      },
      netWorthEstimate: {
        label: "Net worth estimate",
        type: "radio",
        filterType: "checkboxes",
        options: [
          "$0-$99K",
          "$100K-$499K",
          "$500K-$999K",
          "$1M-$4.9M",
          "$5M+",
          "Don't know",
        ],
        helpText:
            "Question added Feb 2023 - Many members have not answered this yet but did answer financial stability",
        display: (user) => {
          if (!user.netWorthEstimate) return "";
          return (
              <>
                <div>{user.netWorthEstimate.label}</div>
              </>
          );
        },
      },
      drinking: {
        label: "Drinking",
        type: "radio",
        filterType: "checkboxes",
        options: [
          "Never",
          "A few times a year",
          "2-3 times a month",
          "1-3 times a week",
          "4-6 times a week",
          "Every day",
        ],
      },
      "does_drugs.drugs": {
        label: "Drugs",
        type: "filter",
        filterType: "checkboxes",
        options: [
          "Never",
          "Special Occasions",
          "Once a month to once a week",
          "Multiple times a week",
        ],
      },
      "does_drugs.type": {
        label: "Drug Types They DO",
        type: "radio",
        filterType: "checkboxes",
        options: [
          "Marijuana",
          "Psychedelics",
          "Cocaine",
          "MDMA",
          "Ketamine",
          "Other",
        ],
      },
      "does_drugs.not_type": {
        label: "Drug Types They Do NOT Do",
        type: "radio",
        filterType: "checkboxes",
        options: [
          "Marijuana",
          "Psychedelics",
          "Cocaine",
          "MDMA",
          "Ketamine",
          "Other",
        ],
      },
      eatingHabits: {
        label: "Eating habits",
        type: "checkboxes",
        filterType: "checkboxes",
        writeIn: true,
        options: [
          "I eat pretty much everything",
          "I'm kind of a picky eater",
          "Very health-conscious",
          "Vegetarian",
          "Vegan",
          "Gluten free",
        ],
      },
      pets: {
        label: "Pets",
        type: "pets",
        display: (user) => {
          if (!user.pets) return "";
          return (
              <>
                <div>
                  {Object.keys(user.pets).map((pet, p) => {
                    if (user.pets[pet]) {
                      return (
                          <span key={p}>
                        <div>
                          <span
                              style={{
                                textTransform: "capitalize",
                              }}
                          >
                            {pet}
                          </span>
                          :
                          <span>
                            {" "}
                            {pet === "other"
                                ? user.pets[pet]
                                : user.pets[pet].value}
                          </span>
                        </div>
                      </span>
                      );
                    }
                  })}
                </div>
              </>
          );
        },
      },
      smoking: {
        label: "Smoking",
        type: "radio",
        filterType: "checkboxes",
        options: ["Never", "Socially", "Often"],
      },
      politicalOrientation: {
        label: "Politics",
        type: "radio",
        filterType: "checkboxes",
        options: [
          "Democrat",
          "Republican",
          "Moderate",
          "Independent",
          "Apolitical",
        ],
      },
      workout: {
        label: "Fitness level",
        type: "radio",
        filterType: "checkboxes",
        options: [
          "Daily",
          "Most days per week",
          "2-3 days per week",
          "Once a week",
          "Less than once a week but I want to do it more",
          "Less than once a week and I don't want to do more",
        ],
      },
      does_drugs: {
        label: "Drugs",
        display: (user) => {
          function getLabel(type) {
            switch (type) {
              case "marijuana":
                return "Marijuana";
              case "psychedelics":
                return "Psychedelics";
              case "cocaine":
                return "Cocaine";
              case "mdma":
                return "MDMA";
              case "ketamine":
                return "Ketamine";
              default:
                return type;
            }
          }

          if (user.does_drugs) {
            return (
                <div>
                  {user.does_drugs.drugs ? (
                      user.does_drugs.drugs.value ? (
                          <div>
                            How often do you do any drugs?{" "}
                            {user.does_drugs.drugs.value}
                          </div>
                      ) : (
                          <div />
                      )
                  ) : (
                      <div />
                  )}

                  {user.does_drugs.drugs ? (
                      user.does_drugs.drugs.value &&
                      user.does_drugs.drugs.value !== "Never" ? (
                          user.does_drugs.types ? (
                              <div>
                                {Object.keys(user.does_drugs.types).map((type) => {
                                  if (
                                      user.does_drugs.types[type] &&
                                      user.does_drugs.types[type].value &&
                                      type !== "other"
                                  ) {
                                    return (
                                        <div>
                                          {getLabel(type)}:{" "}
                                          {user.does_drugs.types[type].value}
                                        </div>
                                    );
                                  } else if (
                                      type === "other" &&
                                      user.does_drugs.types[type].value
                                  ) {
                                    const freq =
                                        user.does_drugs.types[type].frequency &&
                                        isString(user.does_drugs.types[type].frequency) &&
                                        user.does_drugs.types[type].frequency;
                                    const value =
                                        user.does_drugs.types[type].value &&
                                        isString(user.does_drugs.types[type].value) &&
                                        user.does_drugs.types[type].value;
                                    return (
                                        <div>
                                          {value ? value : ""}{" "}
                                          {value ? (freq ? `: ${freq}` : "") : ""}
                                        </div>
                                    );
                                  } else {
                                    return "";
                                  }
                                })}
                              </div>
                          ) : (
                              <div />
                          )
                      ) : (
                          <div />
                      )
                  ) : (
                      <div />
                  )}
                </div>
            );
          } else {
            return <div>--</div>;
          }
        },
      },
      tattoos: {
        label: "Do you have any tattoos?",
        type: "checkboxes",
      },
      hobbies: {
        label: "Hobbies",
        type: "checkboxes",
        filterType: "checkboxes",
        writeIn: true,
        options: [
          "Art/art projects",
          "Aviation",
          "Biking/cycling",
          "Board games",
          "Boating/sailing",
          "Burning Man",
          "Camping",
          "Concerts",
          "Cooking",
          "Dancing",
          "Fishing",
          "Gardening",
          "Golf",
          "Gym/lifting weights",
          "Hiking",
          "Hosting dinner parties",
          "Hunting",
          "Kayaking",
          "Meditation",
          "Movies",
          "Museums",
          "Music festivals",
          "Paddleboarding",
          "Photography",
          "Reading",
          "Religious services/activities",
          "Running",
          "Scuba diving",
          "Shopping",
          "Skiing/snowboarding",
          "Surfing",
          "Swimming",
          "Team Sports",
          "Tennis",
          "Theatre/plays",
          "Traveling",
          "Video games",
          "Volunteering",
          "Walking",
          "Watching sports",
          "Watching TV",
          "Wineries",
          "Yoga",
        ],
        display: (user) => {
          const hobbies_music =
              user.music && user.music.activities
                  ? user.hobbies.concat(user.music.activities)
                  : user.hobbies;
          return <div>{hobbies_music.map((val) => val.value).join(", ")}</div>;
        },
      },
      passionateAbout: {
        label: "Passionate about",
        type: "text",
      },
      music: {
        label: "Music genres",
        type: "checkbox",
        display: (user) => {
          if (!user.music) return "";
          return (
              <>
                <div>
                  {user.music.genres
                      ? user.music.genres.map((val) => val.value).join(", ")
                      : ""}
                </div>
              </>
          );
        },
      },
      "travel.personalImportance": {
        label: "Travel importance",
        type: "radio",
      },
      "travel.personalExperience": {
        label: "International travel experience",
        type: "radio",
      },
      "travel.personalComments": {
        label: "Feel free to elaborate on any travel or living abroad",
        type: "textarea",
      },
    },
  },

  family: {
    label: "Family",
    attributes: {
      personalFrozenEggsOrSperm: {
        label: "Do you have frozen eggs/sperm?",
        type: "radio",
      },
      familyCloseness: {
        label: "Are you (or were you) close with the family you grew up with?",
        type: "radio",
      },
      familyLoan: {
        label: "Should you loan money to family?",
        type: "radio",
      },
      kidsAnythingElse: {
        label: "Any other details about kids?",
        type: "textarea",
      },
      familyAndUpbringing: {
        label: "Describe your family & upbringing",
        type: "textarea",
      },
    },
  },

  partnerCriteria: {
    label: "Partner criteria",
    attributes: {
      relocationOptions: {
        label: "Open To Relocating",
        type: "checkboxes",
        filterType: "checkboxes",
        options: [
          "Maybe",
          "Yes, nearly anywhere",
          "Yes, major cities",
          "Yes, but not big cities",
        ],
      },
      matchArea: {
        label: "Metro areas open to",
        type: "",
        filterType: "select-multi",
        options: matchAreas,
        filterDisplay: (v) => {
          const { getGlobal } = require("launchpad");
          const test = getRegions();
          const region = (getGlobal("labeledRegions") || []).find(
              (x) => x.value === v
          );
          return (region || { label: v }).label;
        },
      },
      relationshipType: {
        label: "Relationship types",
        type: "checkboxes",
        filterType: "checkboxes",
        options: ["Long term Monogamous", "Casual for now", "Ethically non-monogamous", "Other"],
      },
      heightPreference: {
        label: "Height",
        type: "",
        display: (user) => {
          if (!user.heightPreference) return "";
          if (!user.heightPreference.value.height)
            return user.heightPreference.value;
          return `${user.heightPreference.value.height} or ${
              user.heightPreference.value.attribute &&
              user.heightPreference.value.attribute.label
          }`;
        },
      },
      partnerPreferences: {
        label: "Looking for in a partner",
        type: "textarea",
      },
      ageRange: {
        label: "Age range",
      },
      ethnicityRequired: {
        label: "Ethnicities open to",
        type: "checkboxes",
        filterType: "checkboxes",
        options: [
          "All",
          "Asian/Pacific Islander",
          "Black or African-American",
          "Caucasian",
          "Hispanic/Latin",
          "Indian",
          "Middle Eastern",
          "Native American",
        ],
      },
      religionPreference: {
        label: "Religious alignment",
        type: "range",
        filterType: "checkboxes",
        minLabel: "Not important",
        maxLabel: "Very important",
        options: [1, 2, 3, 4, 5],
      },
      politicalPreference: {
        label: "Political alignment",
        type: "range",
        filterType: "checkboxes",
        minLabel: "Not important",
        maxLabel: "Very important",
        options: [1, 2, 3, 4, 5],
      },
      does_drugs: {
        label: "Drugs",
        display: (user) => {
          function getLabel(type) {
            switch (type) {
              case "marijuana":
                return "Marijuana";
              case "psychedelics":
                return "Psychedelics";
              case "cocaine":
                return "Cocaine";
              case "mdma":
                return "MDMA";
              default:
                return type;
            }
          }

          if (user.does_drugs) {
            return (
                <div>
                  {user.does_drugs.partner_drugs ? (
                      user.does_drugs.partner_drugs.value ? (
                          <div>
                            How often are you open to your partner doing drugs?{" "}
                            {user.does_drugs.partner_drugs.value}
                          </div>
                      ) : (
                          <div />
                      )
                  ) : (
                      <div />
                  )}

                  {user.does_drugs.partner_drugs ? (
                      user.does_drugs.partner_drugs.value &&
                      user.does_drugs.partner_drugs.value !== "Never" ? (
                          user.does_drugs.partner ? (
                              <div>
                                {Object.keys(user.does_drugs.partner).map((type) => {
                                  if (
                                      user.does_drugs.partner[type] &&
                                      user.does_drugs.partner[type].value &&
                                      type !== "other"
                                  ) {
                                    return (
                                        <div>
                                          {getLabel(type)}:{" "}
                                          {user.does_drugs.partner[type].value}
                                        </div>
                                    );
                                  } else if (
                                      type === "other" &&
                                      user.does_drugs.partner[type].value
                                  ) {
                                    const freq =
                                        user.does_drugs.partner[type].frequency &&
                                        isString(
                                            user.does_drugs.partner[type].frequency
                                        ) &&
                                        user.does_drugs.partner[type].frequency;
                                    const value =
                                        user.does_drugs.partner[type].value &&
                                        isString(user.does_drugs.partner[type].value) &&
                                        user.does_drugs.partner[type].value;
                                    return (
                                        <div>
                                          {value ? value : ""}{" "}
                                          {value ? (freq ? `: ${freq}` : "") : ""}
                                        </div>
                                    );
                                  } else {
                                    return "";
                                  }
                                })}
                              </div>
                          ) : (
                              <div />
                          )
                      ) : (
                          <div />
                      )
                  ) : (
                      <div />
                  )}
                </div>
            );
          } else {
            return <div>--</div>;
          }
        },
      },
      partnerTattoos: {
        label: "Could you be with someone who has a tattoo?",
        type: "checkboxes",
      },
    },
  },

  other: {
    label: "Other",
    attributes: {
      "other.giveAppreciation": {
        label: "Love Languages They Give Most",
        filterType: "checkboxes",
        options: [
          "Quality Time",
          "Words of Affirmation",
          "Physical Touch",
          "Acts of Service",
          "Gifts",
        ],
      },
      "other.receiveAppreciation": {
        label: "Love Languages They Want To Receive Most",
        filterType: "checkboxes",
        options: [
          "Quality Time",
          "Words of Affirmation",
          "Physical Touch",
          "Acts of Service",
          "Receiving Gifts",
        ],
      },
      "other.longestRelationship": {
        label: "Longest relationship",
        type: "radio",
      },
      "other.languages": {
        label: "Languages spoken",
        type: "text",
      },
      "other.qualities": {
        label: "Personal Traits",
        type: "checkboxes",
        filterType: "checkboxes",
        options: [
          "Adaptable",
          "Adventurous",
          "Athletic",
          "Attractive",
          "Brave",
          "Confident",
          "Driven",
          "Easygoing",
          "Fun",
          "Funny",
          "Generous",
          "Humble",
          "Intellectually curious",
          "Intelligent",
          "Interesting",
          "Kind/considerate",
          "Organized",
          "Passionate about what you do",
          "Quirky",
          "Reliable/stable",
          "Social",
          "Spontaneous",
          "Successful",
          "Well-traveled/worldly",
          "Other",
          "Outdoorsy",
          "Family-oriented",
          "Growth-oriented",
          "An excellent communicator",
          "Empathetic",
          "Sophisticated",
          "Happy"
        ],
      },
      "other.habitsToWorkOn": {
        label: "Habits to work on",
        type: "checkboxes",
      },
      "other.dealbreakers": {
        label: "Dealbreaker traits (from list)",
        type: "checkboxes",
      },
      "other.anythingElse": {
        label: "Anything else?",
        type: "textarea",
      },
    },
  },

  datespotHistory: {
    label: "DateSpot History",
    attributes: {
      createdAt: {
        label: "Signup date",
        display: (user) =>
            new Date(user.createdAt).toLocaleString().split(",")[0],
      },
      verfication: {
        label: "Verified member",
        display: (user) => (user.verification.verified ? "Yes" : "Not yet"),
      },
      relationshipStatus: {
        label: "Last known relationship status",
        type: "radio",
        options: [
          "Single within past 3 months",
          "Not single within past 3 months",
          "Unknown 3 month status",
        ],
      },
      "other.blindDates": {
        label: "Open to blind dates",
        type: "radio",
      },
    },
  },

};

export default memberSchema;
