function compare(a, b) {
  let unlistedLocation = [384, 385, 386];

  const found = unlistedLocation.find(
    (element) => element === a.id || element === b.id
  );

  if (found) {
    return 0;
  }

  if (a.value < b.value) {
    return -1;
  }
  if (a.value > b.value) {
    return 1;
  }
  return 0;
}
let areas = [
  { id: 384, value: "All U.S. ", label: "All U.S. " },
  {
    id: 1,
    value: "New York-Newark-Jersey City, NY-NJ-PA ",
    label: "New York-Newark-Jersey City, NY-NJ-PA ",
  },
  {
    id: 2,
    value: "Los Angeles-Long Beach-Anaheim, CA ",
    label: "Los Angeles-Long Beach-Anaheim, CA ",
  },
  {
    id: 3,
    value: "Chicago-Naperville-Elgin, IL-IN-WI ",
    label: "Chicago-Naperville-Elgin, IL-IN-WI ",
  },
  {
    id: 4,
    value: "Dallas-Fort Worth-Arlington, TX ",
    label: "Dallas-Fort Worth-Arlington, TX ",
  },
  {
    id: 5,
    value: "Houston-The Woodlands-Sugar Land, TX ",
    label: "Houston-The Woodlands-Sugar Land, TX ",
  },
  {
    id: 6,
    value: "Washington-Arlington-Alexandria, DC-VA-MD-WV ",
    label: "Washington-Arlington-Alexandria, DC-VA-MD-WV ",
  },
  {
    id: 7,
    value: "Miami-Fort Lauderdale-West Palm Beach, FL ",
    label: "Miami-Fort Lauderdale-West Palm Beach, FL ",
  },
  {
    id: 8,
    value: "Philadelphia-Camden-Wilmington, PA-NJ-DE-MD ",
    label: "Philadelphia-Camden-Wilmington, PA-NJ-DE-MD ",
  },
  {
    id: 9,
    value: "Atlanta-Sandy Springs-Roswell, GA ",
    label: "Atlanta-Sandy Springs-Roswell, GA ",
  },
  {
    id: 10,
    value: "Boston-Cambridge-Newton, MA-NH ",
    label: "Boston-Cambridge-Newton, MA-NH ",
  },
  {
    id: 11,
    value: "Phoenix-Mesa-Scottsdale, AZ ",
    label: "Phoenix-Mesa-Scottsdale, AZ ",
  },
  {
    id: 12,
    value: "San Francisco-San Jose, CA ",
    label: "San Francisco-San Jose, CA ",
  },
  {
    id: 13,
    value: "Riverside-San Bernardino-Ontario, CA ",
    label: "Riverside-San Bernardino-Ontario, CA ",
  },
  {
    id: 14,
    value: "Detroit-Warren-Dearborn, MI ",
    label: "Detroit-Warren-Dearborn, MI ",
  },
  {
    id: 15,
    value: "Seattle-Tacoma-Bellevue, WA ",
    label: "Seattle-Tacoma-Bellevue, WA ",
  },
  {
    id: 16,
    value: "Minneapolis-St. Paul-Bloomington, MN-WI ",
    label: "Minneapolis-St. Paul-Bloomington, MN-WI ",
  },
  {
    id: 17,
    value: "San Diego-Carlsbad, CA ",
    label: "San Diego-Carlsbad, CA ",
  },
  {
    id: 18,
    value: "Tampa-St. Petersburg-Clearwater, FL ",
    label: "Tampa-St. Petersburg-Clearwater, FL ",
  },
  {
    id: 19,
    value: "Denver-Aurora-Lakewood, CO ",
    label: "Denver-Aurora-Lakewood, CO ",
  },
  { id: 20, value: "St. Louis, MO-IL ", label: "St. Louis, MO-IL " },
  {
    id: 21,
    value: "Baltimore-Columbia-Towson, MD ",
    label: "Baltimore-Columbia-Towson, MD ",
  },
  {
    id: 22,
    value: "Orlando-Kissimmee-Sanford, FL ",
    label: "Orlando-Kissimmee-Sanford, FL ",
  },
  {
    id: 23,
    value: "Charlotte-Concord-Gastonia, NC-SC ",
    label: "Charlotte-Concord-Gastonia, NC-SC ",
  },
  {
    id: 24,
    value: "San Antonio-New Braunfels, TX ",
    label: "San Antonio-New Braunfels, TX ",
  },
  {
    id: 25,
    value: "Portland-Vancouver-Hillsboro, OR-WA ",
    label: "Portland-Vancouver-Hillsboro, OR-WA ",
  },
  {
    id: 26,
    value: "Sacramento–Roseville–Arden-Arcade, CA ",
    label: "Sacramento–Roseville–Arden-Arcade, CA ",
  },
  { id: 27, value: "Pittsburgh, PA ", label: "Pittsburgh, PA " },
  {
    id: 28,
    value: "Las Vegas-Henderson-Paradise, NV ",
    label: "Las Vegas-Henderson-Paradise, NV ",
  },
  { id: 29, value: "Cincinnati, OH-KY-IN ", label: "Cincinnati, OH-KY-IN " },
  { id: 30, value: "Austin-Round Rock, TX ", label: "Austin-Round Rock, TX " },
  { id: 31, value: "Kansas City, MO-KS ", label: "Kansas City, MO-KS " },
  { id: 32, value: "Columbus, OH ", label: "Columbus, OH " },
  { id: 33, value: "Cleveland-Elyria, OH ", label: "Cleveland-Elyria, OH " },
  {
    id: 34,
    value: "Indianapolis-Carmel-Anderson, IN ",
    label: "Indianapolis-Carmel-Anderson, IN ",
  },
  // { id: 35, value: 'San Jose-Sunnyvale-Santa Clara, CA ', label: 'San Jose-Sunnyvale-Santa Clara, CA ' },
  {
    id: 36,
    value: "Nashville-Davidson–Murfreesboro–Franklin, TN ",
    label: "Nashville-Davidson–Murfreesboro–Franklin, TN ",
  },
  {
    id: 37,
    value: "Virginia Beach-Norfolk-Newport News, VA-NC ",
    label: "Virginia Beach-Norfolk-Newport News, VA-NC ",
  },
  {
    id: 38,
    value: "Providence-Warwick, RI-MA ",
    label: "Providence-Warwick, RI-MA ",
  },
  {
    id: 39,
    value: "Milwaukee-Waukesha-West Allis, WI ",
    label: "Milwaukee-Waukesha-West Allis, WI ",
  },
  { id: 40, value: "Jacksonville, FL ", label: "Jacksonville, FL " },
  { id: 41, value: "Oklahoma City, OK ", label: "Oklahoma City, OK " },
  { id: 42, value: "Raleigh, NC ", label: "Raleigh, NC " },
  { id: 43, value: "Memphis, TN-MS-AR ", label: "Memphis, TN-MS-AR " },
  { id: 44, value: "Richmond, VA ", label: "Richmond, VA " },
  {
    id: 45,
    value: "Louisville–Jefferson County, KY-IN ",
    label: "Louisville–Jefferson County, KY-IN ",
  },
  {
    id: 46,
    value: "New Orleans-Metairie, LA ",
    label: "New Orleans-Metairie, LA ",
  },
  { id: 47, value: "Salt Lake City, UT ", label: "Salt Lake City, UT " },
  {
    id: 48,
    value: "Hartford-West Hartford-East Hartford, CT ",
    label: "Hartford-West Hartford-East Hartford, CT ",
  },
  { id: 49, value: "Birmingham-Hoover, AL ", label: "Birmingham-Hoover, AL " },
  {
    id: 50,
    value: "Buffalo-Cheektowaga-Niagara Falls, NY ",
    label: "Buffalo-Cheektowaga-Niagara Falls, NY ",
  },
  { id: 51, value: "Rochester, NY ", label: "Rochester, NY " },
  {
    id: 52,
    value: "Grand Rapids-Wyoming, MI ",
    label: "Grand Rapids-Wyoming, MI ",
  },
  { id: 53, value: "Tucson, AZ ", label: "Tucson, AZ " },
  { id: 54, value: "Fresno, CA ", label: "Fresno, CA " },
  { id: 55, value: "Tulsa, OK ", label: "Tulsa, OK " },
  { id: 56, value: "Urban Honolulu, HI ", label: "Urban Honolulu, HI " },
  { id: 57, value: "Worcester, MA-CT ", label: "Worcester, MA-CT " },
  {
    id: 58,
    value: "Bridgeport-Stamford-Norwalk, CT ",
    label: "Bridgeport-Stamford-Norwalk, CT ",
  },
  {
    id: 59,
    value: "Omaha-Council Bluffs, NE-IA ",
    label: "Omaha-Council Bluffs, NE-IA ",
  },
  { id: 60, value: "Albuquerque, NM ", label: "Albuquerque, NM " },
  {
    id: 61,
    value: "Greenville-Anderson-Mauldin, SC ",
    label: "Greenville-Anderson-Mauldin, SC ",
  },
  { id: 62, value: "Bakersfield, CA ", label: "Bakersfield, CA " },
  { id: 63, value: "Knoxville, TN ", label: "Knoxville, TN " },
  {
    id: 64,
    value: "Albany-Schenectady-Troy, NY ",
    label: "Albany-Schenectady-Troy, NY ",
  },
  {
    id: 65,
    value: "McAllen-Edinburg-Mission, TX ",
    label: "McAllen-Edinburg-Mission, TX ",
  },
  { id: 66, value: "New Haven-Milford, CT ", label: "New Haven-Milford, CT " },
  {
    id: 67,
    value: "Oxnard-Thousand Oaks-Ventura, CA ",
    label: "Oxnard-Thousand Oaks-Ventura, CA ",
  },
  { id: 68, value: "El Paso, TX ", label: "El Paso, TX " },
  {
    id: 69,
    value: "Allentown-Bethlehem-Easton, PA-NJ ",
    label: "Allentown-Bethlehem-Easton, PA-NJ ",
  },
  { id: 70, value: "Columbia, SC ", label: "Columbia, SC " },
  { id: 71, value: "Baton Rouge, LA ", label: "Baton Rouge, LA " },
  {
    id: 72,
    value: "North Port-Sarasota-Bradenton, FL ",
    label: "North Port-Sarasota-Bradenton, FL ",
  },
  { id: 73, value: "Dayton, OH ", label: "Dayton, OH " },
  {
    id: 74,
    value: "Charleston-North Charleston, SC ",
    label: "Charleston-North Charleston, SC ",
  },
  {
    id: 75,
    value: "Greensboro-High Point, NC ",
    label: "Greensboro-High Point, NC ",
  },
  {
    id: 76,
    value: "Cape Coral-Fort Myers, FL ",
    label: "Cape Coral-Fort Myers, FL ",
  },
  { id: 77, value: "Stockton-Lodi, CA ", label: "Stockton-Lodi, CA " },
  {
    id: 78,
    value: "Little Rock-North Little Rock-Conway, AR ",
    label: "Little Rock-North Little Rock-Conway, AR ",
  },
  { id: 79, value: "Colorado Springs, CO ", label: "Colorado Springs, CO " },
  { id: 80, value: "Boise City, ID ", label: "Boise City, ID " },
  {
    id: 81,
    value: "Lakeland-Winter Haven, FL ",
    label: "Lakeland-Winter Haven, FL ",
  },
  { id: 82, value: "Akron, OH ", label: "Akron, OH " },
  { id: 83, value: "Ogden-Clearfield, UT ", label: "Ogden-Clearfield, UT " },
  { id: 84, value: "Winston-Salem, NC ", label: "Winston-Salem, NC " },
  { id: 85, value: "Madison, WI ", label: "Madison, WI " },
  {
    id: 86,
    value: "Deltona-Daytona Beach-Ormond Beach, FL ",
    label: "Deltona-Daytona Beach-Ormond Beach, FL ",
  },
  {
    id: 87,
    value: "Des Moines-West Des Moines, IA ",
    label: "Des Moines-West Des Moines, IA ",
  },
  { id: 88, value: "Syracuse, NY ", label: "Syracuse, NY " },
  { id: 89, value: "Wichita, KS ", label: "Wichita, KS " },
  { id: 90, value: "Provo-Orem, UT ", label: "Provo-Orem, UT " },
  { id: 91, value: "Springfield, MA ", label: "Springfield, MA " },
  {
    id: 92,
    value: "Augusta-Richmond County, GA-SC ",
    label: "Augusta-Richmond County, GA-SC ",
  },
  { id: 93, value: "Toledo, OH ", label: "Toledo, OH " },
  {
    id: 94,
    value: "Palm Bay-Melbourne-Titusville, FL ",
    label: "Palm Bay-Melbourne-Titusville, FL ",
  },
  { id: 95, value: "Jackson, MS ", label: "Jackson, MS " },
  {
    id: 96,
    value: "Durham-Chapel Hill, NC ",
    label: "Durham-Chapel Hill, NC ",
  },
  {
    id: 97,
    value: "Harrisburg-Carlisle, PA ",
    label: "Harrisburg-Carlisle, PA ",
  },
  {
    id: 98,
    value: "Spokane-Spokane Valley, WA ",
    label: "Spokane-Spokane Valley, WA ",
  },
  { id: 99, value: "Chattanooga, TN-GA ", label: "Chattanooga, TN-GA " },
  {
    id: 100,
    value: "Scranton–Wilkes-Barre–Hazleton, PA ",
    label: "Scranton–Wilkes-Barre–Hazleton, PA ",
  },
  { id: 101, value: "Modesto, CA ", label: "Modesto, CA " },
  {
    id: 102,
    value: "Fayetteville-Springdale-Rogers, AR-MO ",
    label: "Fayetteville-Springdale-Rogers, AR-MO ",
  },
  { id: 103, value: "Lancaster, PA ", label: "Lancaster, PA " },
  {
    id: 104,
    value: "Youngstown-Warren-Boardman, OH-PA ",
    label: "Youngstown-Warren-Boardman, OH-PA ",
  },
  {
    id: 105,
    value: "Portland-South Portland, ME ",
    label: "Portland-South Portland, ME ",
  },
  { id: 106, value: "Lexington-Fayette, KY ", label: "Lexington-Fayette, KY " },
  { id: 107, value: "Santa Rosa, CA ", label: "Santa Rosa, CA " },
  {
    id: 108,
    value: "Pensacola-Ferry Pass-Brent, FL ",
    label: "Pensacola-Ferry Pass-Brent, FL ",
  },
  { id: 109, value: "Lafayette, LA ", label: "Lafayette, LA " },
  { id: 110, value: "Port St. Lucie, FL ", label: "Port St. Lucie, FL " },
  {
    id: 111,
    value: "Lansing-East Lansing, MI ",
    label: "Lansing-East Lansing, MI ",
  },
  {
    id: 112,
    value: "Myrtle Beach-Conway-North Myrtle Beach, SC-NC ",
    label: "Myrtle Beach-Conway-North Myrtle Beach, SC-NC ",
  },
  { id: 113, value: "Reno, NV ", label: "Reno, NV " },
  { id: 114, value: "Springfield, MO ", label: "Springfield, MO " },
  {
    id: 115,
    value: "Visalia-Porterville, CA ",
    label: "Visalia-Porterville, CA ",
  },
  { id: 116, value: "Huntsville, AL ", label: "Huntsville, AL " },
  { id: 117, value: "Asheville, NC ", label: "Asheville, NC " },
  { id: 118, value: "Corpus Christi, TX ", label: "Corpus Christi, TX " },
  { id: 119, value: "Killeen-Temple, TX ", label: "Killeen-Temple, TX " },
  { id: 120, value: "York-Hanover, PA ", label: "York-Hanover, PA " },
  { id: 121, value: "Vallejo-Fairfield, CA ", label: "Vallejo-Fairfield, CA " },
  {
    id: 122,
    value: "Santa Maria-Santa Barbara, CA ",
    label: "Santa Maria-Santa Barbara, CA ",
  },
  { id: 123, value: "Fort Wayne, IN ", label: "Fort Wayne, IN " },
  {
    id: 124,
    value: "Shreveport-Bossier City, LA ",
    label: "Shreveport-Bossier City, LA ",
  },
  { id: 125, value: "Salinas, CA ", label: "Salinas, CA " },
  { id: 126, value: "Salem, OR ", label: "Salem, OR " },
  {
    id: 127,
    value: "Brownsville-Harlingen, TX ",
    label: "Brownsville-Harlingen, TX ",
  },
  { id: 128, value: "Reading, PA ", label: "Reading, PA " },
  { id: 129, value: "Manchester-Nashua, NH ", label: "Manchester-Nashua, NH " },
  { id: 130, value: "Mobile, AL ", label: "Mobile, AL " },
  { id: 131, value: "Salisbury, MD-DE ", label: "Salisbury, MD-DE " },
  {
    id: 132,
    value: "Beaumont-Port Arthur, TX ",
    label: "Beaumont-Port Arthur, TX ",
  },
  { id: 133, value: "Flint, MI ", label: "Flint, MI " },
  { id: 134, value: "Anchorage, AK ", label: "Anchorage, AK " },
  { id: 135, value: "Canton-Massillon, OH ", label: "Canton-Massillon, OH " },
  {
    id: 136,
    value: "Gulfport-Biloxi-Pascagoula, MS ",
    label: "Gulfport-Biloxi-Pascagoula, MS ",
  },
  { id: 137, value: "Savannah, GA ", label: "Savannah, GA " },
  { id: 138, value: "Fayetteville, NC ", label: "Fayetteville, NC " },
  { id: 139, value: "Tallahassee, FL ", label: "Tallahassee, FL " },
  {
    id: 140,
    value: "Davenport-Moline-Rock Island, IA-IL ",
    label: "Davenport-Moline-Rock Island, IA-IL ",
  },
  { id: 141, value: "Eugene, OR ", label: "Eugene, OR " },
  {
    id: 142,
    value: "Naples-Immokalee-Marco Island, FL ",
    label: "Naples-Immokalee-Marco Island, FL ",
  },
  { id: 143, value: "Montgomery, AL ", label: "Montgomery, AL " },
  { id: 144, value: "Ann Arbor, MI ", label: "Ann Arbor, MI " },
  { id: 145, value: "Trenton, NJ ", label: "Trenton, NJ " },
  {
    id: 146,
    value: "Hickory-Lenoir-Morganton, NC ",
    label: "Hickory-Lenoir-Morganton, NC ",
  },
  { id: 147, value: "Peoria, IL ", label: "Peoria, IL " },
  { id: 148, value: "Ocala, FL ", label: "Ocala, FL " },
  {
    id: 149,
    value: "Huntington-Ashland, WV-KY-OH ",
    label: "Huntington-Ashland, WV-KY-OH ",
  },
  { id: 150, value: "Fort Collins, CO ", label: "Fort Collins, CO " },
  { id: 151, value: "Spartanburg, SC ", label: "Spartanburg, SC " },
  { id: 152, value: "Kalamazoo-Portage, MI ", label: "Kalamazoo-Portage, MI " },
  { id: 153, value: "Rockford, IL ", label: "Rockford, IL " },
  { id: 154, value: "Lincoln, NE ", label: "Lincoln, NE " },
  { id: 155, value: "Boulder, CO ", label: "Boulder, CO " },
  {
    id: 156,
    value: "South Bend-Mishawaka, IN-MI ",
    label: "South Bend-Mishawaka, IN-MI ",
  },
  { id: 157, value: "Green Bay, WI ", label: "Green Bay, WI " },
  { id: 158, value: "Lubbock, TX ", label: "Lubbock, TX " },
  { id: 159, value: "Evansville, IN-KY ", label: "Evansville, IN-KY " },
  { id: 160, value: "Greeley, CO ", label: "Greeley, CO " },
  { id: 161, value: "Roanoke, VA ", label: "Roanoke, VA " },
  {
    id: 162,
    value: "Kingsport-Bristol-Bristol, TN-VA ",
    label: "Kingsport-Bristol-Bristol, TN-VA ",
  },
  { id: 163, value: "Columbus, GA-AL ", label: "Columbus, GA-AL " },
  {
    id: 164,
    value: "Kennewick-Richland, WA ",
    label: "Kennewick-Richland, WA ",
  },
  { id: 165, value: "Wilmington, NC ", label: "Wilmington, NC " },
  { id: 166, value: "Clarksville, TN-KY ", label: "Clarksville, TN-KY " },
  { id: 167, value: "Utica-Rome, NY ", label: "Utica-Rome, NY " },
  { id: 168, value: "Gainesville, FL ", label: "Gainesville, FL " },
  { id: 169, value: "Olympia-Tumwater, WA ", label: "Olympia-Tumwater, WA " },
  {
    id: 170,
    value: "San Luis Obispo-Paso Robles-Arroyo Grande, CA ",
    label: "San Luis Obispo-Paso Robles-Arroyo Grande, CA ",
  },
  { id: 171, value: "Fort Smith, AR-OK ", label: "Fort Smith, AR-OK " },
  { id: 172, value: "Duluth, MN-WI ", label: "Duluth, MN-WI " },
  {
    id: 173,
    value: "Crestview-Fort Walton Beach-Destin, FL ",
    label: "Crestview-Fort Walton Beach-Destin, FL ",
  },
  { id: 174, value: "Laredo, TX ", label: "Laredo, TX " },
  { id: 175, value: "Merced, CA ", label: "Merced, CA " },
  {
    id: 176,
    value: "Santa Cruz-Watsonville, CA ",
    label: "Santa Cruz-Watsonville, CA ",
  },
  { id: 177, value: "Cedar Rapids, IA ", label: "Cedar Rapids, IA " },
  { id: 178, value: "Erie, PA ", label: "Erie, PA " },
  { id: 179, value: "Waco, TX ", label: "Waco, TX " },
  {
    id: 180,
    value: "Bremerton-Silverdale, WA ",
    label: "Bremerton-Silverdale, WA ",
  },
  {
    id: 181,
    value: "Hagerstown-Martinsburg, MD-WV ",
    label: "Hagerstown-Martinsburg, MD-WV ",
  },
  {
    id: 182,
    value: "Norwich-New London, CT ",
    label: "Norwich-New London, CT ",
  },
  { id: 183, value: "Amarillo, TX ", label: "Amarillo, TX " },
  { id: 184, value: "Sioux Falls, SD ", label: "Sioux Falls, SD " },
  {
    id: 185,
    value: "Atlantic City-Hammonton, NJ ",
    label: "Atlantic City-Hammonton, NJ ",
  },
  { id: 186, value: "Lynchburg, VA ", label: "Lynchburg, VA " },
  {
    id: 187,
    value: "College Station-Bryan, TX ",
    label: "College Station-Bryan, TX ",
  },
  { id: 188, value: "Yakima, WA ", label: "Yakima, WA " },
  { id: 189, value: "Fargo, ND-MN ", label: "Fargo, ND-MN " },
  { id: 190, value: "Tuscaloosa, AL ", label: "Tuscaloosa, AL " },
  { id: 191, value: "Binghamton, NY ", label: "Binghamton, NY " },
  { id: 192, value: "Champaign-Urbana, IL ", label: "Champaign-Urbana, IL " },
  { id: 193, value: "Appleton, WI ", label: "Appleton, WI " },
  { id: 194, value: "Charlottesville, VA ", label: "Charlottesville, VA " },
  { id: 195, value: "Topeka, KS ", label: "Topeka, KS " },
  { id: 196, value: "Prescott, AZ ", label: "Prescott, AZ " },
  { id: 197, value: "Chico, CA ", label: "Chico, CA " },
  { id: 198, value: "Tyler, TX ", label: "Tyler, TX " },
  { id: 199, value: "Macon-Bibb County, GA ", label: "Macon-Bibb County, GA " },
  { id: 200, value: "Bellingham, WA ", label: "Bellingham, WA " },
  {
    id: 201,
    value: "Lafayette-West Lafayette, IN ",
    label: "Lafayette-West Lafayette, IN ",
  },
  {
    id: 202,
    value: "Burlington-South Burlington, VT ",
    label: "Burlington-South Burlington, VT ",
  },
  { id: 203, value: "Rochester, MN ", label: "Rochester, MN " },
  { id: 204, value: "Medford, OR ", label: "Medford, OR " },
  { id: 205, value: "Longview, TX ", label: "Longview, TX " },
  {
    id: 206,
    value: "Daphne-Fairhope-Foley, AL ",
    label: "Daphne-Fairhope-Foley, AL ",
  },
  {
    id: 207,
    value: "Hilton Head Island-Bluffton-Beaufort, SC ",
    label: "Hilton Head Island-Bluffton-Beaufort, SC ",
  },
  { id: 208, value: "Las Cruces, NM ", label: "Las Cruces, NM " },
  { id: 209, value: "Barnstable Town, MA ", label: "Barnstable Town, MA " },
  { id: 210, value: "Yuma, AZ ", label: "Yuma, AZ " },
  {
    id: 211,
    value: "Athens-Clarke County, GA ",
    label: "Athens-Clarke County, GA ",
  },
  { id: 212, value: "Charleston, WV ", label: "Charleston, WV " },
  { id: 213, value: "Lake Charles, LA ", label: "Lake Charles, LA " },
  {
    id: 214,
    value: "Lake Havasu City-Kingman, AZ ",
    label: "Lake Havasu City-Kingman, AZ ",
  },
  { id: 215, value: "Houma-Thibodaux, LA ", label: "Houma-Thibodaux, LA " },
  { id: 216, value: "Springfield, IL ", label: "Springfield, IL " },
  { id: 217, value: "Elkhart-Goshen, IN ", label: "Elkhart-Goshen, IN " },
  { id: 218, value: "Florence, SC ", label: "Florence, SC " },
  { id: 219, value: "Johnson City, TN ", label: "Johnson City, TN " },
  { id: 220, value: "Gainesville, GA ", label: "Gainesville, GA " },
  { id: 221, value: "Panama City, FL ", label: "Panama City, FL " },
  { id: 222, value: "St. Cloud, MN ", label: "St. Cloud, MN " },
  { id: 223, value: "Jacksonville, NC ", label: "Jacksonville, NC " },
  { id: 224, value: "Racine, WI ", label: "Racine, WI " },
  { id: 225, value: "Warner Robins, GA ", label: "Warner Robins, GA " },
  { id: 226, value: "Bend-Redmond, OR ", label: "Bend-Redmond, OR " },
  { id: 227, value: "Saginaw, MI ", label: "Saginaw, MI " },
  { id: 228, value: "Bloomington, IL ", label: "Bloomington, IL " },
  { id: 229, value: "Punta Gorda, FL ", label: "Punta Gorda, FL " },
  {
    id: 230,
    value: "Blacksburg-Christiansburg-Radford, VA ",
    label: "Blacksburg-Christiansburg-Radford, VA ",
  },
  { id: 231, value: "El Centro, CA ", label: "El Centro, CA " },
  { id: 232, value: "Redding, CA ", label: "Redding, CA " },
  { id: 233, value: "Columbia, MO ", label: "Columbia, MO " },
  { id: 234, value: "Greenville, NC ", label: "Greenville, NC " },
  { id: 235, value: "Joplin, MO ", label: "Joplin, MO " },
  { id: 236, value: "Kingston, NY ", label: "Kingston, NY " },
  { id: 237, value: "Dover, DE ", label: "Dover, DE " },
  { id: 238, value: "Midland, TX ", label: "Midland, TX " },
  { id: 239, value: "Bowling Green, KY ", label: "Bowling Green, KY " },
  { id: 240, value: "Monroe, LA ", label: "Monroe, LA " },
  { id: 241, value: "Yuba City, CA ", label: "Yuba City, CA " },
  { id: 242, value: "Muskegon, MI ", label: "Muskegon, MI " },
  { id: 243, value: "Iowa City, IA ", label: "Iowa City, IA " },
  { id: 244, value: "St. George, UT ", label: "St. George, UT " },
  { id: 245, value: "Billings, MT ", label: "Billings, MT " },
  { id: 246, value: "Abilene, TX ", label: "Abilene, TX " },
  { id: 247, value: "Oshkosh-Neenah, WI ", label: "Oshkosh-Neenah, WI " },
  { id: 248, value: "Terre Haute, IN ", label: "Terre Haute, IN " },
  {
    id: 249,
    value: "Waterloo-Cedar Falls, IA ",
    label: "Waterloo-Cedar Falls, IA ",
  },
  { id: 250, value: "East Stroudsburg, PA ", label: "East Stroudsburg, PA " },
  { id: 251, value: "Sioux City, IA-NE-SD ", label: "Sioux City, IA-NE-SD " },
  { id: 252, value: "Eau Claire, WI ", label: "Eau Claire, WI " },
  { id: 253, value: "Bloomington, IN ", label: "Bloomington, IN " },
  { id: 254, value: "Pueblo, CO ", label: "Pueblo, CO " },
  {
    id: 255,
    value: "Kahului-Wailuku-Lahaina, HI ",
    label: "Kahului-Wailuku-Lahaina, HI ",
  },
  { id: 256, value: "Burlington, NC ", label: "Burlington, NC " },
  { id: 257, value: "Auburn-Opelika, AL ", label: "Auburn-Opelika, AL " },
  { id: 258, value: "Janesville-Beloit, WI ", label: "Janesville-Beloit, WI " },
  { id: 259, value: "State College, PA ", label: "State College, PA " },
  { id: 260, value: "Odessa, TX ", label: "Odessa, TX " },
  { id: 261, value: "Coeur d'Alene, ID ", label: "Coeur d'Alene, ID " },
  { id: 262, value: "Jackson, MI ", label: "Jackson, MI " },
  { id: 263, value: "Madera, CA ", label: "Madera, CA " },
  {
    id: 264,
    value: "Sebastian-Vero Beach, FL ",
    label: "Sebastian-Vero Beach, FL ",
  },
  {
    id: 265,
    value: "Chambersburg-Waynesboro, PA ",
    label: "Chambersburg-Waynesboro, PA ",
  },
  {
    id: 266,
    value: "Niles-Benton Harbor, MI ",
    label: "Niles-Benton Harbor, MI ",
  },
  {
    id: 267,
    value: "Elizabethtown-Fort Knox, KY ",
    label: "Elizabethtown-Fort Knox, KY ",
  },
  { id: 268, value: "Grand Junction, CO ", label: "Grand Junction, CO " },
  { id: 269, value: "Alexandria, LA ", label: "Alexandria, LA " },
  { id: 270, value: "Albany, GA ", label: "Albany, GA " },
  { id: 271, value: "Decatur, AL ", label: "Decatur, AL " },
  { id: 272, value: "Jefferson City, MO ", label: "Jefferson City, MO " },
  { id: 273, value: "Hanford-Corcoran, CA ", label: "Hanford-Corcoran, CA " },
  { id: 274, value: "Wichita Falls, TX ", label: "Wichita Falls, TX " },
  { id: 275, value: "Bangor, ME ", label: "Bangor, ME " },
  {
    id: 276,
    value: "Vineland-Bridgeton, NJ ",
    label: "Vineland-Bridgeton, NJ ",
  },
  { id: 277, value: "Monroe, MI ", label: "Monroe, MI " },
  { id: 278, value: "Texarkana, TX-AR ", label: "Texarkana, TX-AR " },
  { id: 279, value: "Santa Fe, NM ", label: "Santa Fe, NM " },
  { id: 280, value: "Hattiesburg, MS ", label: "Hattiesburg, MS " },
  { id: 281, value: "Idaho Falls, ID ", label: "Idaho Falls, ID " },
  { id: 282, value: "Rapid City, SD ", label: "Rapid City, SD " },
  { id: 283, value: "Dothan, AL ", label: "Dothan, AL " },
  { id: 284, value: "Homosassa Springs, FL ", label: "Homosassa Springs, FL " },
  {
    id: 285,
    value: "Florence-Muscle Shoals, AL ",
    label: "Florence-Muscle Shoals, AL ",
  },
  { id: 286, value: "Valdosta, GA ", label: "Valdosta, GA " },
  { id: 287, value: "Rocky Mount, NC ", label: "Rocky Mount, NC " },
  { id: 288, value: "Dalton, GA ", label: "Dalton, GA " },
  { id: 289, value: "Flagstaff, AZ ", label: "Flagstaff, AZ " },
  { id: 290, value: "Lebanon, PA ", label: "Lebanon, PA " },
  { id: 291, value: "Logan, UT-ID ", label: "Logan, UT-ID " },
  { id: 292, value: "Morgantown, WV ", label: "Morgantown, WV " },
  { id: 293, value: "Wheeling, WV-OH ", label: "Wheeling, WV-OH " },
  { id: 294, value: "Winchester, VA-WV ", label: "Winchester, VA-WV " },
  { id: 295, value: "Napa, CA ", label: "Napa, CA " },
  {
    id: 296,
    value: "La Crosse-Onalaska, WI-MN ",
    label: "La Crosse-Onalaska, WI-MN ",
  },
  { id: 297, value: "Wausau, WI ", label: "Wausau, WI " },
  { id: 298, value: "Harrisonburg, VA ", label: "Harrisonburg, VA " },
  { id: 299, value: "Springfield, OH ", label: "Springfield, OH " },
  { id: 300, value: "Battle Creek, MI ", label: "Battle Creek, MI " },
  { id: 301, value: "Sherman-Denison, TX ", label: "Sherman-Denison, TX " },
  { id: 302, value: "Hammond, LA ", label: "Hammond, LA " },
  { id: 303, value: "Bismarck, ND ", label: "Bismarck, ND " },
  { id: 304, value: "Jonesboro, AR ", label: "Jonesboro, AR " },
  { id: 305, value: "Johnstown, PA ", label: "Johnstown, PA " },
  { id: 306, value: "Jackson, TN ", label: "Jackson, TN " },
  { id: 307, value: "The Villages, FL ", label: "The Villages, FL " },
  {
    id: 308,
    value: "Mount Vernon-Anacortes, WA ",
    label: "Mount Vernon-Anacortes, WA ",
  },
  { id: 309, value: "Albany, OR ", label: "Albany, OR " },
  {
    id: 310,
    value: "Sierra Vista-Douglas, AZ ",
    label: "Sierra Vista-Douglas, AZ ",
  },
  { id: 311, value: "St. Joseph, MO-KS ", label: "St. Joseph, MO-KS " },
  { id: 312, value: "Pittsfield, MA ", label: "Pittsfield, MA " },
  { id: 313, value: "Lawton, OK ", label: "Lawton, OK " },
  { id: 314, value: "Glens Falls, NY ", label: "Glens Falls, NY " },
  { id: 315, value: "New Bern, NC ", label: "New Bern, NC " },
  { id: 316, value: "Farmington, NM ", label: "Farmington, NM " },
  { id: 317, value: "Carbondale-Marion, IL ", label: "Carbondale-Marion, IL " },
  { id: 318, value: "Cleveland, TN ", label: "Cleveland, TN " },
  { id: 319, value: "Goldsboro, NC ", label: "Goldsboro, NC " },
  {
    id: 320,
    value: "Staunton-Waynesboro, VA ",
    label: "Staunton-Waynesboro, VA ",
  },
  { id: 321, value: "Altoona, PA ", label: "Altoona, PA " },
  { id: 322, value: "Lawrence, KS ", label: "Lawrence, KS " },
  { id: 323, value: "Mansfield, OH ", label: "Mansfield, OH " },
  { id: 324, value: "Wenatchee, WA ", label: "Wenatchee, WA " },
  { id: 325, value: "San Angelo, TX ", label: "San Angelo, TX " },
  { id: 326, value: "Owensboro, KY ", label: "Owensboro, KY " },
  { id: 327, value: "Missoula, MT ", label: "Missoula, MT " },
  { id: 328, value: "Morristown, TN ", label: "Morristown, TN " },
  { id: 329, value: "Brunswick, GA ", label: "Brunswick, GA " },
  { id: 330, value: "Beckley, WV ", label: "Beckley, WV " },
  {
    id: 331,
    value: "Weirton-Steubenville, WV-OH ",
    label: "Weirton-Steubenville, WV-OH ",
  },
  { id: 332, value: "Sheboygan, WI ", label: "Sheboygan, WI " },
  { id: 333, value: "Muncie, IN ", label: "Muncie, IN " },
  {
    id: 334,
    value: "Anniston-Oxford-Jacksonville, AL ",
    label: "Anniston-Oxford-Jacksonville, AL ",
  },
  { id: 335, value: "Williamsport, PA ", label: "Williamsport, PA " },
  {
    id: 336,
    value: "California-Lexington Park, MD ",
    label: "California-Lexington Park, MD ",
  },
  {
    id: 337,
    value: "Watertown-Fort Drum, NY ",
    label: "Watertown-Fort Drum, NY ",
  },
  { id: 338, value: "Twin Falls, ID ", label: "Twin Falls, ID " },
  { id: 339, value: "Kankakee, IL ", label: "Kankakee, IL " },
  {
    id: 340,
    value: "Michigan City-La Porte, IN ",
    label: "Michigan City-La Porte, IN ",
  },
  { id: 341, value: "Longview, WA ", label: "Longview, WA " },
  { id: 342, value: "Lewiston-Auburn, ME ", label: "Lewiston-Auburn, ME " },
  { id: 343, value: "Sumter, SC ", label: "Sumter, SC " },
  { id: 344, value: "Sebring, FL ", label: "Sebring, FL " },
  { id: 345, value: "Decatur, IL ", label: "Decatur, IL " },
  { id: 346, value: "Bay City, MI ", label: "Bay City, MI " },
  { id: 347, value: "Fond du Lac, WI ", label: "Fond du Lac, WI " },
  { id: 348, value: "Gettysburg, PA ", label: "Gettysburg, PA " },
  { id: 349, value: "Ithaca, NY ", label: "Ithaca, NY " },
  { id: 350, value: "Lima, OH ", label: "Lima, OH " },
  { id: 351, value: "Gadsden, AL ", label: "Gadsden, AL " },
  { id: 352, value: "Grand Forks, ND-MN ", label: "Grand Forks, ND-MN " },
  {
    id: 353,
    value: "Mankato-North Mankato, MN ",
    label: "Mankato-North Mankato, MN ",
  },
  { id: 354, value: "Victoria, TX ", label: "Victoria, TX " },
  { id: 355, value: "Hot Springs, AR ", label: "Hot Springs, AR " },
  { id: 356, value: "Cheyenne, WY ", label: "Cheyenne, WY " },
  { id: 357, value: "Fairbanks, AK ", label: "Fairbanks, AK " },
  { id: 358, value: "Ames, IA ", label: "Ames, IA " },
  { id: 359, value: "Manhattan, KS ", label: "Manhattan, KS " },
  { id: 360, value: "Rome, GA ", label: "Rome, GA " },
  { id: 361, value: "Cumberland, MD-WV ", label: "Cumberland, MD-WV " },
  { id: 362, value: "Cape Girardeau, MO-IL ", label: "Cape Girardeau, MO-IL " },
  { id: 363, value: "Dubuque, IA ", label: "Dubuque, IA " },
  { id: 364, value: "Ocean City, NJ ", label: "Ocean City, NJ " },
  { id: 365, value: "Corvallis, OR ", label: "Corvallis, OR " },
  {
    id: 366,
    value: "Parkersburg-Vienna, WV ",
    label: "Parkersburg-Vienna, WV ",
  },
  { id: 367, value: "Pine Bluff, AR ", label: "Pine Bluff, AR " },
  { id: 368, value: "Grants Pass, OR ", label: "Grants Pass, OR " },
  { id: 369, value: "Pocatello, ID ", label: "Pocatello, ID " },
  { id: 370, value: "Grand Island, NE ", label: "Grand Island, NE " },
  { id: 371, value: "Elmira, NY ", label: "Elmira, NY " },
  {
    id: 372,
    value: "Bloomsburg-Berwick, PA ",
    label: "Bloomsburg-Berwick, PA ",
  },
  { id: 373, value: "Midland, MI ", label: "Midland, MI " },
  { id: 374, value: "Columbus, IN ", label: "Columbus, IN " },
  { id: 375, value: "Kokomo, IN ", label: "Kokomo, IN " },
  { id: 376, value: "Great Falls, MT ", label: "Great Falls, MT " },
  { id: 377, value: "Hinesville, GA ", label: "Hinesville, GA " },
  { id: 378, value: "Casper, WY ", label: "Casper, WY " },
  { id: 379, value: "Danville, IL ", label: "Danville, IL " },
  { id: 380, value: "Walla Walla, WA ", label: "Walla Walla, WA " },
  { id: 381, value: "Lewiston, ID-WA ", label: "Lewiston, ID-WA " },
  { id: 382, value: "Enid, OK ", label: "Enid, OK " },
  { id: 383, value: "Carson City, NV ", label: "Carson City, NV " },
  { id: 385, value: "Other U.S.", label: "Other U.S." },
  { id: 386, value: "International", label: "International" },
];

export const STATISTICAL_AREAS = areas.sort(compare);

export const RANGE_OPTIONS = [
  { id: 1, label: "Within 10 miles", value: "Within 10 miles" },
  { id: 2, label: "Within 25 miles", value: "Within 25 miles" },
  { id: 3, label: "Within 50 miles", value: "Within 50 miles" },
  { id: 4, label: "Within 100 miles", value: "Within 100 miles" },
];
