const memberAttributes = [
  "Fun",
  "Funny",
  "Generous",
  "Intelligent",
  "Kind",
  "Reliable",
  "Successful",
  "Well-traveled/worldly",
];

const getAttributeRatings = (user) => {
  const descriptions = (user.matchmakerDescriptions || []).map(
    (x) => x.descriptions
  );
  let stats = {};
  for (let d of descriptions) {
    for (let a of memberAttributes) {
      // set up empty stat block for each attribute
      if (!stats[a]) stats[a] = { yes: 0, no: 0, total: 0 };

      if (typeof d[a] != "undefined") {
        if (d[a]) {
          stats[a].yes += 1;
        } else {
          stats[a].no += 1;
        }
        stats[a].total += 1;
      }
    }
  }
  for (let key of Object.keys(stats)) {
    stats[key].yesRate =
      stats[key].yes === 0 ? 0 : (stats[key].yes / stats[key].total) * 100;
    stats[key].noRate =
      stats[key].no === 0 ? 0 : (stats[key].no / stats[key].total) * 100;
  }
  return stats;
};

const getHeight = (inches) => {
  const inch = inches % 12;
  const feet = Math.floor(inches / 12);
  return `${feet} ft ${inch} in`;
};

const allUS = [
  "All U.S.",
  "Other U.S.",
  "5deaebc4770cec6bfb53fbcc",
  "5deaf1ac770cec6bfb53fbe1",
  "5deaec39770cec6bfb53fbcd",
  "5deaee92770cec6bfb53fbd6",
  "5deae6c6770cec6bfb53fbc6",
  "5deaf108770cec6bfb53fbdc",
  "5deaf16b770cec6bfb53fbdf",
  "5deaf193770cec6bfb53fbe0",
  "5deaea8c770cec6bfb53fbc9",
  "5deae6e2770cec6bfb53fbc7",
  "5deaee0b770cec6bfb53fbd4",
  "5deaed4d770cec6bfb53fbcf",
  "5deaea29770cec6bfb53fbc8",
  "5deaf1c4770cec6bfb53fbe2",
  "5deaf14d770cec6bfb53fbde",
  "5deae6ad770cec6bfb53fbc5",
  "5deaf129770cec6bfb53fbdd",
  "5deaebac770cec6bfb53fbcb",
  "5deaed92770cec6bfb53fbd1",
  "5deae540770cec6bfb53fbc4",
  "5deaeedf770cec6bfb53fbd8",
  "5deaeb71770cec6bfb53fbca",
  "5deaec65770cec6bfb53fbce",
  "5deaef4f770cec6bfb53fbda",
  "5deaeebb770cec6bfb53fbd7",
  "5deabb673d331e646bd5d04a",
  "5deaf0e3770cec6bfb53fbdb",
  "5deaeef9770cec6bfb53fbd9",
  "5deaedcb770cec6bfb53fbd2",
  "5deaed71770cec6bfb53fbd0",
  "5deaee25770cec6bfb53fbd5",
  "5deaede3770cec6bfb53fbd3",
];

module.exports = { memberAttributes, getAttributeRatings, getHeight, allUS };
