.rating-tooltip {
    border-radius: 2px;
    border: 1px solid rgba(84,88,90,0.1);
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(84,88,90,0.2);
    font-family: Montserrat, sans-serif;
    height: 60px;
    padding: 22px 20px;
    position: absolute;
    top: 33px;
    right: 0;
    width: 394px;
    &__title {
        color: rgba(84,88,90,0.5);
        font-size: 14px;
        margin-bottom: 9px;
    }
    &__buttons {
        border: 1px solid #FF476B;
        border-radius: 19px;
        background-color: rgba(84,88,90,0.1);
        height: 30px;
    }
    &__button {
        cursor: pointer;
        color: rgba(84,88,90,0.5);
        font-size: 16px;
        height: 30px;
        width: 37px;
        &:hover {
            font-size: 19px;
        }
        &.selected {
            background: #FF476B;
            color: #fff;
            font-size: 19px;
        }
    }
}