import React, { useState, useRef, useEffect } from "react";

export const DropDown = ({ children, open, className }) => {
  const menuRef = useRef(null);
  const [menuUp, setMenuUp] = useState(false);
  const checkMenu = () => {
    const rowTop = menuRef.current.getBoundingClientRect().top;
    if (rowTop > window.innerHeight / 2) {
      setMenuUp(true);
    } else {
      setMenuUp(false);
    }
  };
  useEffect(checkMenu, [open]);
  // useEffect(checkMenu, [])

  return (
    <div ref={menuRef} className={`drop-down-anchor`}>
      {/* commenting this out to fix a bug in the matchmaker dashboard. No time to debug at the moment */}
      <div style={{ padding: "16px" }}
        className={`drop-down-menu ${className || ""} ${
          menuUp ? "up" : "down"
        } ${open ? "active" : ""}`}
      >
        {children}
      </div>
    </div>
  );
};
