import React from "react";
import PropTypes from "prop-types";
import RadioList from "../radio-list";
import { PETS } from "../../constants/onboarding-data-elements";
import "./style.scss";

class PetsRadio extends React.Component {
  state = {
    dogs: this.props.pets ? this.props.pets.dogs : null,
    cats: this.props.pets ? this.props.pets.cats : null,
    other: this.props.pets ? this.props.pets.other : null,
  };
  render() {
    const { dogs, cats, other } = this.state;
    const { saveUserFieldData } = this.props;
    return (
      <div className="pets-radio flex-row">
        <div className="pets-radio__options flex-column">
          <div className="pets-radio__label flex-row flex-vertical-center">
            <div
              className={`${
                dogs || cats || other ? "p-label green" : "p-label"
              }`}
              style={{ textAlign: "left" }}
            >
              Pets
            </div>
            <div className="float-right label-options flex-row">
              <span>Dogs</span>
              <span>Cats</span>
              {/* <span>Other</span> */}
            </div>
          </div>
          {PETS.dogs.map((p, i) => (
            <div key={i} className="pets-radio__option flex-vertical-center">
              {p.label}
            </div>
          ))}
        </div>
        <div className="pets-radio__buttons flex-row">
          <RadioList
            list={PETS.dogs}
            selectedValue={dogs}
            stateCallback={(val) => {
              this.setState({ dogs: val });
              saveUserFieldData("pets", { dogs: val, cats, other });
            }}
          />
          <RadioList
            list={PETS.cats}
            selectedValue={cats}
            stateCallback={(val) => {
              this.setState({ cats: val });
              saveUserFieldData("pets", { dogs, cats: val, other });
            }}
          />
          {/* <RadioList list={PETS.other} selectedValue={other}
                               stateCallback={val => {
                                   this.setState({ 'other': val });
                                   saveUserFieldData('pets', { dogs, cats, other: val });
                               }}/> */}
        </div>
      </div>
    );
  }
}

PetsRadio.propTypes = {
  saveUserFieldData: PropTypes.func,
  pets: PropTypes.object,
};
export default PetsRadio;
