/*
  launchpad overview: widgets

  Widgets are components that serve a simple function, e.g. accepting a certain
  kind of user input, or showing/hiding content based on state.

  Widgets can typically be imported via:

  ```
    import {Widget} from 'widgets'
  ```

*/
import React from "react";
import { default as CB } from "@material-ui/core/Checkbox";
import styled from "styled-components";
import { history } from "app";
export { default as Slideshow } from "./Slideshow";
export { AccordionPanel, Accordion } from "./Accordion";
export { Repeater } from "./Repeater";
export { PayPalButton } from "./Paypal";
export { ItemList } from "./ItemList";
export { Collapsible } from "./Collapsible";
export { Address } from "./Address";
export { ShareIcons } from "./ShareIcons";
export { ImageCarousel } from "./ImageCarousel";
export { DropDown } from "./DropDown";
export { FormInput } from "./FormInput";
export { Button } from "./Button";
export { Image } from "./Image";
export { Autocomplete } from "./Autocomplete";

// https://reactdatepicker.com/
export { default as DatePicker } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ReactSelect from "react-select";

// consolidating widgets originally exported from 'launchpad'
export { Input } from "launchpad";
export {
  default as CheckMark,
  WarningIcon,
} from "launchpad/admin/widgets/CheckMark";

// Material UI Checkbox with style fixes

const CBContainer = styled.div`
  > span,
  > label > span {
    display: inline-block;
    margin: 5px;
    padding: 0;
  }
`;

export const Title = (props) => (
  <div className="title-component">
    <span className="blue-line" />
    <span className="title-text">{props.children}</span>
    <span className="blue-line" />
  </div>
);

export const CheckBox = (props) => (
  <CBContainer className={`${props.className} check-box`}>
    {props.label ? (
      <label>
        {props.label}
        <CB {...props} />
      </label>
    ) : (
      <CB {...props} />
    )}
  </CBContainer>
);

// Easy to remember alias, may expand later

export const Link = (props) => {
  const p = Object.assign({}, props);
  if (props.to) {
    p.href = props.to;
  }
  if (props.href) {
    p.to = props.href;
  }
  if (p.href) {
    var internal = p.href.startsWith("/") || !p.href.includes(".");
  }
  if (p.href && internal) {
    p.onClick = (e) => {
      e.preventDefault();
      history.push(p.href);
      if (props.onClick) props.onClick(e);
    };
  }
  if (internal) return <a {...p} />;
  return <a {...p} />;
};

const PBContainer = styled.div`
  border: 1px solid #ddd;
  background: #eee;
  border-radius: 4px;
  overflow: hidden;
  padding: 0;
  height: 17px;
  display: block;
  .lp_progress-bar {
    background: #0062cc;
    height: 15px;
    margin: 0;
    border-radius: 2px;
    display: block;
    transition: width 0.3s;
  }
`;
export const ProgressBar = (props) => (
  <PBContainer className="lp_progress">
    <div className="lp_progress-bar" style={{ width: `${props.progress}%` }} />
  </PBContainer>
);

// Qty selector widget

const QContainer = styled.div`
  display: inline-flex;
  margin: 0;
  > div {
    padding: 7px;
    border: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .value {
    padding: 7px 0;
    width: 40px;
    border: 0;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    text-align: center;
  }
  .increment,
  .decrement {
    padding: 7px 10px;
    cursor: pointer;
  }
  .decrement {
    border-radius: 4px 0 0 4px;
  }
  .increment {
    border-radius: 0 4px 4px 0;
  }

  &.disabled {
    pointer-events: none;
    .fa {
      color: #bbb;
    }
  }
`;

export class Qty extends React.Component {
  add = (amt) => {
    const { max, onMin, onMax, onChange } = this.props;
    let min = this.props.min || 0;
    let value = this.props.value + amt;
    if (max) {
      if (value > max && onMax) onMax(value);
      value = Math.min(max, value);
    }
    if (min || min === 0) {
      if (value < min && onMin) onMin(value);
      value = Math.max(0, min, value);
    }
    onChange(value);
  };

  change = (e) => {
    const { onError, value, onChange } = this.props;
    const num = e.target.value;
    const v = parseInt(num);
    if (num && isNaN(v)) {
      if (onError) onError();
    } else {
      if (!num) {
        onChange("");
      } else {
        this.add(v - value);
      }
    }
  };

  render() {
    let { value, disabled } = this.props;
    value = parseInt(value);
    return (
      <QContainer className={`qty-selector ${disabled ? "disabled" : ""}`}>
        <div className="decrement fa fa-minus" onClick={() => this.add(-1)} />
        <input
          className="value"
          onChange={this.change}
          value={isNaN(value) ? "" : value}
        />
        <div className="increment fa fa-plus" onClick={() => this.add(1)} />
      </QContainer>
    );
  }
}

// Select that uses react-select, but lets you just use <option>s like you normally would
export const Select = (props) => {
  let ops = [];
  let { children } = props;
  if (!Array.isArray(props.children)) children = [children];
  children
    .filter((x) => !!x)
    .forEach((x) => {
      ops.push({
        value: x.props.value || x.props.children,
        label: x.props.children,
      });
    });
  const isMulti = props.multi || props.isMulti;
  const onChange = (v) => {
    if (props.onChange) {
      if (isMulti) {
        props.onChange(v.map((v) => v.value));
      } else {
        props.onChange(v.value);
      }
    }
  };
  const value =
    props.multi || props.isMulti
      ? ops.filter((x) =>
          (props.value || []).map((x) => x.value || x).includes(x.value)
        )
      : ops.find((x) => x.value === props.value);
  return (
    <ReactSelect
      {...props}
      isMulti={isMulti}
      options={ops}
      value={value}
      onChange={onChange}
      className={`react-select ${props.className || ""}`}
    />
  );
};

// if(module.hot) module.hot.accept()
