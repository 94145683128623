import UserSession from "../../_old/src/components/user-session";

const HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const logoutUser = async () => {
  await post({ url: "/v1/user/logout" });
  UserSession.logout();
};

export const get = ({ url, onSuccess, onError }) => {
  const request = { method: "GET", url, onSuccess, onError };
  return makeRequest(request);
};

export const put = ({ url, data = {}, onSuccess, onError }) => {
  const request = {
    method: "PUT",
    url,
    body: JSON.stringify(data),
    headers: HEADERS,
    onSuccess,
    onError,
  };
  return makeRequest(request);
};

export const post = ({ url, data = {}, onSuccess, onError }) => {
  const request = {
    method: "POST",
    url,
    body: JSON.stringify(data),
    headers: HEADERS,
    onSuccess,
    onError,
  };
  return makeRequest(request);
};

export const deleteRequest = ({ url, onSuccess, onError }) => {
  const request = { method: "DELETE", url, onSuccess, onError };
  return makeRequest(request);
};

const makeRequest = (request) => {
  const headers = request.headers || {};
  // If user is authenticated, send the auth. token on each request
  const { token } = UserSession.getToken();
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  // Don't set the body if it's a GET request as it will crash on Microsoft Edge
  const params = {
    headers,
    method: request.method || "GET",
  };
  if (params.method !== "GET") {
    params.body = request.body || null;
  }
  //FIXME: environment variables on deploy.
  // Do the API Request
  //return fetch(`${process.env.API_BASE_URL}${request.url}`, params)
  return fetch(`/api${request.url}`, params)
      .then((res) => {
        return Promise.all([res.status, res.json()]);
      })
      .then((results) => {
        return { status: results[0], data: results[1] };
      })
      .then((response) => {
        // Not authorized
        if (response.status === 401 && window.location.pathname !== "/login") {
          if (response.data.invalidToken && response.data.invalidToken) {
            UserSession.clearToken();
            window.location.href = "/";
          }
        }
        if (
            response.status === 400 ||
            (response.status >= 402 && response.status <= 500)
        ) {
          throw Error(response.data.message);
        }
        request.onSuccess && request.onSuccess(response);
        return response;
      })
      .catch((error) => {
        request.onError && request.onError(error);
      });
};

export const exportUserData = async (id) => {
  fetch(`/api/upload-doc/${id}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${UserSession.getToken().token}`,
    },
  })
      .then((response) => response.json())
      .then((resp) =>
          window.open(`https://docs.google.com/document/d/${resp.id}`, "_blank")
      );
};
