import React from "react";
import PropTypes from "prop-types";
import RadioList from "../radio-list-horizontal";
import RadioButton from "../radio-button";
import DropDown from "../drop-down";
import CheckBoxList from "../check-box-list";
import InputText from "../input-text";
import {
  DRUGS_FREQUENCY,
  DRUGS_FREQUENCY_OTHER,
} from "../../constants/onboarding-data-elements";
import "./style.scss";

class DrugForm extends React.Component {
  state = {
    marijuana: this.props.drugs ? this.props.drugs.marijuana : null,
    psychedelics: this.props.drugs ? this.props.drugs.psychedelics : null,
    cocaine: this.props.drugs ? this.props.drugs.cocaine : null,
    mdma: this.props.drugs ? this.props.drugs.mdma : null,
    ketamine: this.props.drugs ? this.props.drugs.ketamine : null,
    prescription: this.props.drugs ? this.props.drugs.prescription : null,
    other: this.props.drugs ? this.props.drugs.other : null,
    otherFreq: this.props.drugs
      ? this.props.drugs.other
        ? this.props.drugs.other.frequency
        : null
      : null,
    otherVal: this.props.drugs
      ? this.props.drugs.other
        ? this.props.drugs.other.value
        : null
      : null,
  };

  getState = (name) => {
    switch (name) {
      case "marijuana": {
        return this.state.marijuana;
      }
      case "psychedelics": {
        return this.state.psychedelics;
      }
      case "cocaine": {
        return this.state.cocaine;
      }
      case "mdma": {
        return this.state.mdma;
      }
      case "ketamine": {
        return this.state.ketamine;
      }
    }
  };

  render() {
    const { saveUserFieldData } = this.props;
    const other = DRUGS_FREQUENCY_OTHER[0];
    const { otherFreq, otherVal } = this.state;
    const disable_other_dropdown = this.state.otherVal === "" ? true : false;

    return (
      <div className="drug-form flex-box">
        <div className="drug-form__label flex-row flex-vertical-center">
          <div className="p-label green" style={{ textAlign: "left" }}>
            {this.props.title}
          </div>
        </div>

        {DRUGS_FREQUENCY.map((a, index) => (
          <div key={index} className="drug-form__options flex-row">
            <div className="drug-form__options flex-vertical-center">
              {a.label}
            </div>
            <div className="drug-form__dropdown flex-column">
              <DropDown
                listOfValues={a.options}
                selectedValue={this.getState(a.name)}
                placeholder="Choose one..."
                onSelectValue={(val) => {
                  this.setState({ [a.name]: val });
                  saveUserFieldData(
                    "does_drugs." + this.props.obj_name + "." + a.name,
                    val
                  );
                }}
              />
            </div>
          </div>
        ))}

        <div className="drug-form__options flex-row">
          <div className="drug-form__options flex-vertical-center">
            {other.label}
          </div>
          <div className="drug-form__dropdown flex-column">
            <InputText
              placeholder="Specify if desired"
              onChange={(e) => {
                this.setState({ otherVal: e.target.value });
                saveUserFieldData(
                  "does_drugs." + this.props.obj_name + ".other.value",
                  e.target.value
                );
              }}
              value={otherVal ? otherVal : " "}
            />
            <DropDown
              listOfValues={other.options}
              selectedValue={otherFreq}
              disabled={disable_other_dropdown}
              placeholder="Choose one..."
              onSelectValue={(val) => {
                this.setState({ otherFreq: val });
                saveUserFieldData(
                  "does_drugs." + this.props.obj_name + ".other.frequency",
                  val
                );
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

DrugForm.propTypes = {
  saveUserFieldData: PropTypes.func,
  drugs_: PropTypes.object,
};

export default DrugForm;
