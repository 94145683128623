import Api from "./api";
import * as types from "../constants/actionTypes";

export const saveUserFieldData = (userId, field, value, callback) => {
  return () => {
    Api.post({
      url: "/api/save-user-field-data",
      data: { userId, field, value },
      responseAction: types.SAVE_USER_FIELD_DATA,
      callback,
    });
  };
};

export const setPercentage = (completed, total, section) => {
  Api.post({
    url: "/api/save-user-percentages",
    data: { completed, total, section },
    responseAction: types.SET_PERCENTAGE,
  });
};

export const closeTCModal = () => {
  return {
    type: types.CLOSE_TC_MODAL,
  };
};

export const sendNewUserEmails = (type) => {
  return () => {
    Api.post({
      url: "/api/new-user-email",
      data: { type },
      responseAction:
        type === "userAddedPhotos"
          ? types.USER_EMAILS_PHOTOS_ADDED
          : types.USER_NO_DATA_OR_PHOTOS_FILLED,
    });
  };
};

export const updateUserRegion = (userId) => {
  return () => {
    Api.post({
      url: "/api/update-user-region",
      data: { userId },
      responseAction: types.UPDATE_USER_REGION,
    });
  };
};
