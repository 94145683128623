import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  STATISTICAL_AREAS,
  RANGE_OPTIONS,
} from "../../constants/statistical-areas";
import { matchAreas } from "../../../src/constants/onboarding-data-elements.js";
import SpecialDropDown from "../drop-down-special";
import TextArea from "../input-textarea";
import RadioList from "../radio-list";
import "./style.scss";

class StatisticalAreas extends Component {
  static propTypes = {
    statisticalAreas: PropTypes.array,
    userId: PropTypes.string,
    saveUserFieldData: PropTypes.func,
    matchAreaDetails: PropTypes.string,
    isOnBoarding: PropTypes.any,
    matchAreaRange: PropTypes.object,
    required: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      firstStatisticalArea: props.statisticalAreas[0] || null,
      statisticalAreas:
        (props.statisticalAreas.length > 1 &&
          props.statisticalAreas.slice(1, props.statisticalAreas.length)) ||
        [],
      matchAreaDetailsValid: false,
      matchAreaDetails: props.matchAreaDetails || null,
      isOnBoarding: props.isOnBoarding || false,
      matchAreaRange: props.matchAreaRange || null,
      required: props.required || false,
    };
  }

  render() {
    const { firstStatisticalArea, statisticalAreas } = this.state;
    let filteredStatisticalAreas = [];
    let all_us = false;
    filteredStatisticalAreas =
      firstStatisticalArea &&
      matchAreas
        .filter((item) => !statisticalAreas.find((sta) => sta.id === item.id))
        .filter((item) => item.id !== firstStatisticalArea.id);
    const renderTextArea = this._renderTextArea();
    // const renderRange = this._renderRange();

    if (firstStatisticalArea) {
      if (firstStatisticalArea.id === 384) {
        all_us = true;
        for (var i in statisticalAreas.length) {
          this.findAndDeleteNewRow(statisticalAreas[d].id);
        }
      }
    }

    return (
      <div className="flex-column statistical-area">
        <div className="statistical-area__top-container">
          <div className={`${firstStatisticalArea ? "label valid" : "label"}`}>
            What geographic areas are you open to in terms of matches?
            {this.state.required ? (
              <span className="text-alert-red">*</span>
            ) : (
              ""
            )}
          </div>
          <SpecialDropDown
            listOfValues={matchAreas}
            selectedValue={firstStatisticalArea}
            name="statisticalAreas"
            placeholder="Select..."
            deleteRow={(val) =>
              statisticalAreas.length > 0
                ? () => {}
                : this.setState({ firstStatisticalArea: null })
            }
            onSelectValue={(val) => {
              this.setState({ firstStatisticalArea: val });
              this.saveStatisticalAreas(val, []);
            }}
          />
        </div>

        {statisticalAreas.length > 0 &&
          statisticalAreas.map((drop, d) => {
            if (!all_us) {
              return (
                <div key={d}>
                  <SpecialDropDown
                    listOfValues={filteredStatisticalAreas}
                    selectedValue={statisticalAreas[d]}
                    name="statisticalAreas"
                    placeholder="Select..."
                    deleteRow={() =>
                      this.findAndDeleteNewRow(statisticalAreas[d].id)
                    }
                    onSelectValue={(val) => this.findAndReplaceNewRow(val, d)}
                  />
                </div>
              );
            }
          })}

        <div
          className="flex-row add-area"
          onClick={() => this.shouldAddMoreDropDowns()}
        >
          <span>+</span>
          <span>Add area</span>
        </div>
        {/* { renderRange } */}
        {renderTextArea}
      </div>
    );
  }

  _renderRange = () => {
    const { matchAreaRange } = this.state;
    const { userId, saveUserFieldData } = this.props;
    return (
      <div>
        <RadioList
          list={RANGE_OPTIONS}
          title="Range (if preferred)"
          selectedValue={matchAreaRange}
          stateCallback={(val) =>
            saveUserFieldData(userId, "matchAreaRange", val)
          }
        />
      </div>
    );
  };

  _renderTextArea = () => {
    const { matchAreaDetails, matchAreaDetailsValid, isOnBoarding } =
      this.state;
    const { userId, saveUserFieldData } = this.props;
    return (
      <TextArea
        value={matchAreaDetails}
        placeholder={"More geographic details (optional)"}
        onChange={(e) =>
          this.setState({
            matchAreaDetails: e.target.value,
            matchAreaDetailsValid: false,
          })
        }
        isValid={matchAreaDetails && matchAreaDetailsValid}
        onBlur={() => {
          this.setState({ matchAreaDetailsValid: true });
          isOnBoarding
            ? saveUserFieldData(
                matchAreaDetails,
                "matchAreaDetails",
                "moreAbout"
              )
            : saveUserFieldData(userId, "matchAreaDetails", matchAreaDetails);
        }}
      />
    );
  };

  shouldAddMoreDropDowns = () => {
    const { firstStatisticalArea, statisticalAreas } = this.state;
    if (statisticalAreas.length > 0) {
      const existingTemporaryDropDown = !!statisticalAreas.find(
        (item) => item.id === "temp"
      );
      !existingTemporaryDropDown &&
        this.setState({
          statisticalAreas: [
            ...statisticalAreas,
            {
              id: "temp",
              label: "",
              value: "",
            },
          ],
        });
    } else {
      firstStatisticalArea
        ? this.setState({
            statisticalAreas: [
              ...statisticalAreas,
              {
                id: "temp",
                label: "",
                value: "",
              },
            ],
          })
        : () => {};
    }
  };

  findAndReplaceNewRow = (val, index) => {
    const { firstStatisticalArea, statisticalAreas } = this.state;
    const newStatisticalAreas = [...statisticalAreas];
    const temporaryObjectIndex = newStatisticalAreas.findIndex(
      (item) => item.id === "temp"
    );
    if (temporaryObjectIndex === 1) {
      newStatisticalAreas[temporaryObjectIndex] = val;
    } else {
      newStatisticalAreas[index] = val;
    }
    this.setState({ statisticalAreas: newStatisticalAreas });
    this.saveStatisticalAreas(firstStatisticalArea, newStatisticalAreas);
  };

  findAndDeleteNewRow = (rowId) => {
    const { firstStatisticalArea, statisticalAreas } = this.state;
    this.setState({
      statisticalAreas: statisticalAreas.filter((item) => item.id !== rowId),
    });
    this.saveStatisticalAreas(
      firstStatisticalArea,
      statisticalAreas.filter((item) => item.id !== rowId)
    );
  };

  saveStatisticalAreas = (firstStatisticalArea, statisticalAreas) => {
    const { userId, saveUserFieldData } = this.props;
    const { isOnBoarding } = this.state;
    const statisticalAreasFinal = [firstStatisticalArea, ...statisticalAreas];
    isOnBoarding
      ? saveUserFieldData(statisticalAreasFinal, "matchArea", "moreAbout")
      : saveUserFieldData(userId, "matchArea", statisticalAreasFinal);
  };
}

export default StatisticalAreas;
