import React, { Component } from "react";
import PropTypes from "prop-types";
import cloneDeep from "lodash.clonedeep";
import { getInitials } from "../../../../components/util-functions";
import { ADMIN_TABLE_HEADERS } from "../../../../constants/index";
import CheckBox from "../../../../components/check-box";
import DropDownComponent from "../../../../components/drop-down";
import {
  USER_WILLING_TO_PAY,
  CLIENT_STATUS,
  REGIONS,
} from "../../../../constants/onboarding-data-elements";
import { statesIndex } from "data/states";
import trashCan from "./trash.svg";
import SearchInput from "../../../../components/search-input";
import TriangleComponent from "../../../../components/triangle-component";
import "../../user-list.scss";
import prettyName from "pretty-name";

class UsersList extends Component {
  state = {
    openedMenu: false,
    hovered: null,
    search: "",
  };

  render() {
    const { usersList } = this.props;
    const { search } = this.state;
    var filteredUsers = [...usersList];
    if (search) {
      if (Number.isFinite(+search)) {
        const userIDMatches = (user) => +search === user.user_id;
        filteredUsers = usersList.filter((user) => userIDMatches(user));
      } else {
        const contains = (string, subStr) =>
          string.toLowerCase().indexOf(subStr.toLowerCase()) > -1;
        const userMatches = (user) =>
          contains(`${user.firstName} ${user.lastName}`, search);
        filteredUsers = usersList.filter((user) => userMatches(user));
      }
    }
    const renderSearchBar = this._renderSearchBar(search);
    const renderTableHeaders = this._renderTableHeaders();
    const renderUsers = this._renderUsers(filteredUsers);

    return (
      <div className="users-list-container flex-column flex-column">
        {renderSearchBar}
        {renderTableHeaders}
        {renderUsers}
      </div>
    );
  }

  _renderSearchBar = (search) => {
    return (
      <div className="search-bar-container">
        <SearchInput
          value={search}
          onChange={(e) => this.setState({ search: e.target.value })}
          placeholder="Search by name or member #"
        />
      </div>
    );
  };

  _renderTableHeaders = () => {
    return (
      <div className="labels flex-row" style={{ paddingLeft: "-1vh" }}>
        {ADMIN_TABLE_HEADERS.users.map((tab, t) => {
          return (
            <span
              className={tab.class}
              key={t}
              style={{ width: tab.width || "100%" }}
            >
              {tab.name}
            </span>
          );
        })}
      </div>
    );
  };
  Thumbnail = (user) => {
    const thumbUrl = (url) => url;
    const initials = user.firstName[0] + user.lastName[0];
    const image = thumbUrl(user.images && user.images[0] && user.images[0].url);
    return (
      <div className="avatar" style={{ backgroundImage: `url(${image})` }}>
        {!image && initials}
      </div>
    );
  };

  getRegion = (user) => {
    let closestRegion = {};
    // FIXME: workaround for users with that doesn't have regionsData (the model is initialized with an empty array but some users doesn't have it for some reason)
    const regionsData = user.regionsData || [];
    for (let region of regionsData) {
      if (!closestRegion.distance || region.distance < closestRegion.distance) {
        closestRegion = region;
      }
    }
    let label;
    if (closestRegion.distance || closestRegion.distance === 0) {
      label =
        closestRegion.label || `${closestRegion.city}, ${closestRegion.state}`;
    }
    if (!label && user.regions?.length)
      label =
        statesIndex[user.regions[0]] ||
        REGIONS.find((x) => x.value === user.regions[0])?.label ||
        label;
    return label;
  };

  _renderUsers = (filteredUsers) => {
    const { openedMenu, hovered } = this.state;
    const { adminActions } = this.props;
    const hasClassOrIsDescendant = (element, classname) => {
      if (
        element !== "undefined" &&
        element.classList &&
        element.classList.contains("show-more")
      )
        return true;
      return (
        element.parentNode &&
        hasClassOrIsDescendant(element.parentNode, classname)
      );
    };
    return (
      <div className="users-list">
        {filteredUsers.map((user, u) => {
          const renderCheckboxesForWillingToPay =
            this._renderCheckboxesWP(user);
          // const renderAllowPhotos = this._renderAllowPhotos(user);
          const renderUserMenu = this._renderUserMenu(user._id);
          const isHoveredItem = hovered === user._id;
          const isUserLocked = user.connectionsRequests.find(
            (conn) => conn.isLocked
          );
          const avatar = this.Thumbnail(user);

          return (
            <div
              className="users-list__item flex-row"
              key={u}
              style={{ position: "relative" }}
              ref={
                isHoveredItem
                  ? (ref) => {
                      this.selUserRef = ref;
                    }
                  : null
              }
              onMouseEnter={() => this.setState({ hovered: user._id })}
              onMouseLeave={() =>
                this.setState({
                  hovered: null,
                  openedMenu: false,
                })
              }
            >
              <div
                className="flex-row  user-info"
                onClick={(e) => {
                  if (!hasClassOrIsDescendant(e.target, "show-more")) {
                    adminActions.getClickedUserInfoAdmin(user._id);
                  }
                }}
              >
                {/* {user.images && user.images[0]
                                ? <img src={decodeURI(user.images[0].url.replace('datespot-bucket', 'datespot-rotated-thumbnails'))} alt="" className='avatar flex-center'/>
                                : <span className='avatar flex-center'>{getInitials(user)}</span>
                            } */}
                <div className="flex-center">{avatar}</div>
                <span className="name element">
                  {user.firstName} {user.lastName}
                </span>
                <span className="id element">{user.user_id}</span>
                <div className="numbers">
                  <p>{prettyName(user.city)}</p>
                  <p>{this.getRegion(user)}</p>
                  <p>{user.country != "United States of America" && prettyName(user.country) }</p>
                </div>
                <span
                  className={`rating element ${
                    user.userRating ? "big-blue" : "red"
                  }`}
                >
                  {user.userRating ? Math.round(user.userRating) : "No rating"}
                </span>
                {/* <span className='numbers element connections-length'>{user.connectionsRequests
                            && user.connectionsRequests.length}</span> */}
                {/* <span className='numbers element statuses'>
                                    {isUserLocked ? 'Locked'
                                        : <div className='flex-column'>
                                            <span>{user.relationshipStatus && user.relationshipStatus.value}</span>
                                            <span>{user.clientStatus === 'current' ? 'Free Connection' : 'Paid Connection'}</span>
                                        </div>
                                    }
                            </span> */}
                <span className="numbers element">{user.age}</span>
                <span className="flex-column element willing-to-pay">
                  {renderCheckboxesForWillingToPay}
                </span>
                <span className="rating element">
                  {this._calculateIndividualPercentages(
                    user.images,
                    user.userProgress
                  )}
                  %
                </span>
                {/* <span className='flex-row element allows-photos'>{renderAllowPhotos}</span>*/}
                {/* <div className='show-more'>
                                    <span className='icon-more float-right'
                                          onClick={() => this.setState({ openedMenu: !openedMenu })}/>
                                { isHoveredItem && openedMenu ? renderUserMenu : null }
                            </div> */}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  _renderCheckboxesWP = (user) => {
    const { saveUserFieldDataFromAdmin } = this.props.adminActions;

    function Status({ value, label, color, _id, user_id, handleClick }) {
      return (
        <div
          className="status"
          onClick={() => handleClick(_id, user_id, value)}
        >
          <span style={{ color: color }}>{label}</span>
        </div>
      );
    }

    const handleClick = (_id, user_id, value) => {
      alert(
        `Client status updated to ${value} for member ${user_id}, refresh page for to see changes.`
      );
      let res = saveUserFieldDataFromAdmin(_id, "clientStatus", value);
      // console.log(res);
    };

    if (user) {
      const { _id, clientStatus, user_id } = user;

      // let status = CLIENT_STATUS.find(x => x.value === clientStatus);
      return (
        <div className="client-status">
          {CLIENT_STATUS.map((status, i) => {
            let color =
              status.value === clientStatus
                ? CLIENT_STATUS.find((x) => x.value === clientStatus)?.color
                : "gray";
            return (
              <Status
                key={`client-status-${i}`}
                id={i}
                value={status.value}
                label={status.label}
                color={color}
                _id={_id}
                user_id={user_id}
                handleClick={handleClick}
              />
            );
          })}
        </div>
      );
    }
    return null;
  };

  _renderAllowPhotos = (user) => {
    const { saveUserFieldDataFromAdmin } = this.props.adminActions;
    const { allowsPhotos, _id } = user;
    return (
      <CheckBox
        labelName=""
        clicked={() =>
          saveUserFieldDataFromAdmin(_id, "allowsPhotos", !allowsPhotos)
        }
        checked={allowsPhotos}
      />
    );
  };

  _renderUserMenu = (userId) => {
    const { adminActions } = this.props;
    const openMenuUpwards =
      this.selUserRef && this.selUserRef.getBoundingClientRect().top > 500;
    return (
      <div
        className={`user-menu admin-menu flex-column ${
          openMenuUpwards ? "open-upwards" : ""
        }`}
      >
        <TriangleComponent top={-9} right={23} />
        <div
          className="view-profile flex-row"
          onClick={() => adminActions.getClickedUserInfoAdmin(userId)}
        >
          <span className="icon-eye" />
          <span>View profile</span>
        </div>

        <div
          className="view-profile flex-row"
          onClick={() => {
            adminActions.inactivateUser(userId);
            this.setState({ openedMenu: false });
          }}
        >
          <span className="trash-can">
            <img src={trashCan} alt="trash-can" />
          </span>
          <span>Delete user</span>
        </div>
      </div>
    );
  };

  _calculateTotalPercentage = (data) => {
    let x = 0;
    Object.values(data).forEach((val) => {
      x += (val * 100) / 400;
    });
    return x;
  };

  _calculateIndividualPercentages = (images, userProgress) => {
    const progress = {};
    if (images && images.length > 0) {
      progress.photos = 100;
    }

    if (userProgress) {
      Object.keys(userProgress).forEach((item) => {
        if (parseInt(userProgress[item], 10) > 100) {
          progress[item] = 100;
        } else {
          progress[item] = parseInt(userProgress[item], 10);
        }
      });
    }

    let x = 0;
    Object.values(progress).forEach((val) => {
      x += (val * 100) / 400;
    });
    return x;
  };
}

UsersList.propTypes = {
  adminActions: PropTypes.object,
  usersList: PropTypes.array,
};

export default UsersList;
