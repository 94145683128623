import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as generalActions from "../../actions/generalActions";
import Button from "../../components/button";
import { repeatingSectionTwo } from "./components";
import {
  REPEAT_SECTION_TWO,
  PARTNERS,
  COMPANIES,
  HOW_DOES_IT_WORK,
  SINGLES_HOMEPAGE_SECTION_TWO,
} from "./texts";
import partnersPhoto from "./assets/homepage-singles/partners.png";
import check from "./assets/homepage-singles/checkmark.svg";

import "./style.scss";

class SinglesHomePage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const { history } = this.props;
    const renderPartners = this._renderPartner();
    const renderCompanies = this._renderCompanies();
    const renderSection = this._renderSection(history);

    return (
      <div className="flex-column singles-homepage-content">
        <div className="section-two-what flex-column flex-vertical-center">
          {repeatingSectionTwo(
            REPEAT_SECTION_TWO.singlesWhatWeDo.title,
            REPEAT_SECTION_TWO.singlesWhatWeDo.text,
            null,
            null,
            null,
            null
          )}
        </div>
        <div className="singles-why-section flex-column">
          <div className="section-two flex-column flex-vertical-center">
            {repeatingSectionTwo(
              REPEAT_SECTION_TWO.singlesPageFirst.title,
              null,
              null,
              null,
              null,
              null
            )}
          </div>
          {SINGLES_HOMEPAGE_SECTION_TWO.map((sect, s) => {
            return (
              <div className={`singles-why-section__item flex-column`} key={s}>
                <div className="flex-row" style={{ marginBottom: "20px" }}>
                  <img src={check} />
                  <span className="title">{sect.title}</span>
                </div>
                <span className="text">{sect.text}</span>
              </div>
            );
          })}
        </div>
        <div className="section-two flex-column flex-vertical-center">
          {repeatingSectionTwo(
            REPEAT_SECTION_TWO.singlesPageSecond.title,
            REPEAT_SECTION_TWO.singlesPageSecond.text,
            null,
            null,
            null,
            null
          )}
          <div className="carla-swiryn flex-column">
            <span>— Carla Swiryn</span>
            <span>DateSpot Founder</span>
          </div>
        </div>
        <div className="singles-first-section flex-column flex-center">
          {repeatingSectionTwo(
            REPEAT_SECTION_TWO.singlesPageThird.title,
            null,
            null,
            null,
            null
          )}
          <div className="flex-column" style={{ width: "100%" }}>
            {HOW_DOES_IT_WORK.map((section, s) => {
              return (
                <div key={s} className={`flex-row item`}>
                  {section.before && (
                    <span className="before-circle flex-center">{s + 1}</span>
                  )}
                  {section.after && (
                    <span className="after-circle flex-center">{s + 1}</span>
                  )}
                  <div className="item-text">{section.text}</div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="singles-third-section flex-column flex-center">
          <span>The entire process is totally free!</span>
          <div className="custom-text">
            Our Partner Matchmakers always want more and better matches, so they
            pay for access to our database.
          </div>
          <Button
            click={() => history.push({ pathname: "/welcome-singles" })}
            value="JOIN THE DATABASE"
            customClass="large yellow extra-padding"
          />
        </div>
        {renderPartners}
        {renderSection}
        {renderCompanies}
      </div>
    );
  }

  _renderSection = (history) => {
    return (
      <div className="singles-section-seven flex-column flex-center">
        <div className="singles-section-seven__content flex-column flex-center">
          {repeatingSectionTwo(
            REPEAT_SECTION_TWO.singlesPageFourth.title,
            null,
            null,
            null
          )}
        </div>
        <div className="section-six">
          <div className="section-six__content flex-row">
            <div className="background-image flex-column">
              <div className="couple-info flex-column flex-center">
                <span>Meghan and Andrew</span>
                <span>NYC</span>
              </div>
            </div>
            <div className="dark-grey-section ">
              <div className="flex-column" style={{ paddingRight: "80px" }}>
                <span
                  className="dark-grey-section__text"
                  style={{ marginBottom: "30px" }}
                >
                  {" "}
                  {"Don’t want to wait to be notified and want " +
                    "a Matchmaker to focus on you? " +
                    "Talk with a reputable Matchmaker in your area about becoming a client."}{" "}
                </span>
                <span
                  className="dark-grey-section__text"
                  style={{ marginBottom: "60px" }}
                >
                  {`There’s no cost to talk or commitment to become a client. We'll suggest a highly reviewed
                                company based on your preferences, needs and budget.`}
                </span>
              </div>
              <Button
                click={() =>
                  history.push({ pathname: "/talk-to-a-matchmaker" })
                }
                value="TALK TO A MATCHMAKER"
                customClass="yellow large"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  _renderPartner = () => {
    return (
      <div className="our-partners flex-center">
        <div className="our-partners__content flex-column">
          {repeatingSectionTwo(
            REPEAT_SECTION_TWO.singlesPageFifth.title,
            null,
            null,
            null,
            null,
            null
          )}
          <div className="partners flex-row">
            <div className="left flex-column">
              {PARTNERS.left.map((par, p) => (
                <span key={p}>{par}</span>
              ))}
            </div>
            <div className="partners-photo flex-center">
              <img src={partnersPhoto} />
            </div>
            <div className="right  flex-column">
              {PARTNERS.right.map((par, p) => (
                <span key={p}>{par}</span>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  _renderCompanies = () => {
    return (
      <div>
        <div className="companies flex-column flex-center">
          <div className="companies__title">
            {`There are tons of matchmaking companies out there...`}
          </div>
          <div className="companies__intro">
            {`We've honed the list down to our favorites that operate nationwide.`}
          </div>
          <div className="flex-column companies__content">
            <div className="flex-row  companies__titles">
              {COMPANIES.titles.map((title, t) => (
                <div className="item__title" key={t}>
                  {title}
                </div>
              ))}
            </div>
            <div className="flex-row  companies__unique">
              <div className="companies__tab">{COMPANIES.unique.text}</div>
              {COMPANIES.unique.values.map((unq, u) => (
                <div className="item__text" key={u}>
                  {unq}
                </div>
              ))}
            </div>
            <div className="flex-row  companies__bios">
              <div className="companies__tab">{COMPANIES.bios.text}</div>
              {COMPANIES.bios.values.map((bi, b) => (
                <div className="item__text" key={b}>
                  {bi}
                </div>
              ))}
            </div>
            <div className="flex-row  companies__pricing">
              <div className="companies__tab">{COMPANIES.pricing.text}</div>
              {COMPANIES.pricing.values.map((pr, p) => (
                <div className="item__text" key={p}>
                  {pr}
                </div>
              ))}
            </div>
            <div className="flex-row  companies__locations">
              <div className="companies__tab">{COMPANIES.locations.text}</div>
              {COMPANIES.locations.values.map((loc, l) => (
                <div className="item__text" key={l}>
                  {loc}
                </div>
              ))}
            </div>
            <div className="flex-row  companies__bestFor">
              <div className="companies__tab">{COMPANIES.bestFor.text}</div>
              {COMPANIES.bestFor.values.map((loc, l) => (
                <div className="item__text" key={l}>
                  {loc}
                </div>
              ))}
            </div>
            <div className="flex-row  companies__contact">
              <div className="companies__tab">{COMPANIES.contact.text}</div>
              {COMPANIES.contact.values.map((con, c) => (
                <div className="item__text flex-center" key={c}>
                  <Button
                    value={con}
                    customClass="yellow"
                    click={() =>
                      this.props.history.push({
                        pathname: "/talk-to-a-matchmaker",
                        state: c,
                      })
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };
}

SinglesHomePage.propTypes = {
  history: PropTypes.object,
  generalActions: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return {
    generalActions: bindActionCreators(generalActions, dispatch),
  };
};

const mapStateToProps = (state) => {
  return {
    generalReducer: state.generalReducer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SinglesHomePage);
