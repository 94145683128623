import "./Badges.scss";
import React from "react";
import datespotImg from "../../../../images/Official DateSpot Partner Seal.png";
import datespotApprovedImg from "../../../../images/Official-DateSpot-Approved-Partner.png";
import { Button } from "src/components/widgets";


const badgeText = [
  "Here are two partner matchmaker badges you can choose from to add to your " +
  "site and ideally link to DateSpot. It's not required, but it creates further " +
  "legitimacy and trust for your own business. We also appreciate the " +
  "cross-promotion, and generally refer more potential clients to you if you " +
  "display it. Please let us know if your site has a badge. Thanks!"
]



const Badges = () => {

  return (
      <div className="badges">
        <div className="badges-container">
          <div className="badges-title">
            <h2>Pick a Badge</h2>
          </div>
          <div className="badges-images">
            <div>
              <img src={datespotImg} />
              <a href={datespotImg} target="_blank" download={datespotImg}>
                <Button>Download</Button>
              </a>
            </div>
            <div>
              <img src={datespotApprovedImg} />
              <a href={datespotApprovedImg} target="_blank" download={datespotApprovedImg}>
                <Button>Download</Button>
              </a>
            </div>
          </div>
          <div className="badges-text">
            <p>{badgeText}</p>
          </div>
        </div>
      </div>
  );

}

export default Badges;