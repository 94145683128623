import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as generalActions from "../../actions/generalActions";
import * as authenticationActions from "../../actions/authentication";
import Button from "../../components/button";
import InputText from "../../components/input-text";
import Validator from "../../components/utils/validator";
import CheckBox from "../../components/check-box";
import "../home-pages/style.scss";
import ToolTip from "../../components/tooltip";
import DropDown from "../../components/drop-down";
import MatchmakerOptions from "./matchmaker-options";
import UserSession from "../../components/user-session";
import ReactGA from "react-ga";
import Modal from "../../components/modal/index";
import TextArea from "../../components/input-textarea/index";
const BUDGET_VALUES = [
  { id: 1, label: "$1 - $999", value: "$1 - $999" },
  { id: 2, label: "$1,000 - $4,999", value: "$1,000 - $4,999" },
  { id: 3, label: "$5,000 - $9,999", value: "$5,000 - $9,999" },
  { id: 4, label: "$10,000 - $24,999", value: "$10,000 - $24,999" },
  { id: 5, label: "$25,000+", value: "$25,000+" },
];

class TalkToAMMPage extends Component {
  constructor(props) {
    super(props);
    const historyCompanyParam =
      props.history.location && props.history.location.state;
    this.state = {
      firstName: null,
      lastName: null,
      email: null,
      phoneNumber: null,
      basicRequirements: null,
      radioValue: true,
      mmOptionsValues: [],
      budgetValue: null,
      agreeWithTermsAndPolicies: false,
      errors: {},
      validFields: {},
      showSignUpModal: false,
      threeDayRule: historyCompanyParam === 0 || false,
      kelleher: historyCompanyParam === 1 || false,
      lynx: historyCompanyParam === 2 || false,
      otherCompany: null,
    };
  }

  setData = (field, value, fieldError, fieldValid) => {
    const { errors, validFields } = this.state;
    this.setState({
      [field]: value,
      errors: { ...errors, [fieldError]: null },
      validFields: { ...validFields, [fieldValid]: null },
    });
  };

  sendData = (withSignUp) => {
    if (this.validate(withSignUp)) {
      const {
        firstName,
        lastName,
        email,
        phoneNumber,
        radioValue,
        basicRequirements,
        mmOptionsValues,
        budgetValue,
        password,
        agreeWithTermsAndPolicies,
        threeDayRule,
        kelleher,
        lynx,
        otherCompany,
      } = this.state;
      const { talkToAMmContactData } = this.props.generalActions;
      let choices = "";
      mmOptionsValues &&
        mmOptionsValues.forEach((item) => {
          choices += `${Object.keys(item)[0]}${
            Object.values(item)[0] ? ":" : ""
          } ${Object.values(item)[0] || ""} , `;
        });
      const companiesToConsider = `${threeDayRule ? "THREE DAY RULE, " : ""}${
        kelleher ? "KELLEHER INTL., " : ""
      }
            ${lynx ? "LINX DATING, " : ""}${
        otherCompany ? "Other you recommend" : ""
      }`;
      const dataToSend = {
        firstName,
        lastName,
        email,
        phoneNumber,
        radioValue,
        basicRequirements,
        mmOptionsValues: choices,
        budgetValue,
        companiesToConsider,
      };
      talkToAMmContactData(dataToSend);
      this.setState({
        firstName: null,
        lastName: null,
        email: null,
        radioValue: true,
        phoneNumber: null,
        basicRequirements: null,
        budgetValue: null,
        mmOptionsValues: [],
        threeDayRule: null,
        kelleher: null,
        lynx,
        otherCompany: null,
      });
      if (withSignUp) {
        const data = {
          firstName,
          lastName,
          email,
          phone: phoneNumber,
          password,
          acceptedTC: agreeWithTermsAndPolicies,
          role: "user",
        };
        this.props.authenticationActions.signUpUser(data, this.signUpCallback);

        ReactGA.event({
          category: "member signing in",
          action: "Created an user account",
        });
      }
    }
  };

  validate = (withSignUp) => {
    let isValid = true;
    const {
      firstName,
      lastName,
      email,
      errors,
      agreeWithTermsAndPolicies,
      password,
      retypePassword,
      mmOptionsValues,
    } = this.state;
    if (!firstName) {
      errors.firstNameError = "Required";
      isValid = false;
    }
    if (!lastName) {
      errors.lastName = "Required";
      isValid = false;
    }
    if (!email) {
      errors.emailError = "Required";
      isValid = false;
    }
    if (mmOptionsValues.length < 1) {
      errors.mmOptionsValuesError = "Required";
      isValid = false;
    }
    if (!agreeWithTermsAndPolicies) {
      errors.agreeWithTermsAndPoliciesError =
        "Please check this box if you want to proceed";
      isValid = false;
    }

    if (withSignUp) {
      if (!password) {
        errors.passwordError = "Required";
        isValid = false;
      }
      if (!retypePassword) {
        errors.retypePasswordError = "Required";
        isValid = false;
      }
    }

    Object.values(errors).forEach((error) => {
      if (error) {
        isValid = false;
      }
    });
    this.setState({ errors });

    return isValid;
  };

  signUpCallback = (mess, response) => {
    const { error, user, token } = response.data;
    if (!error) {
      this.setState({ showSignUpModal: true, token, user });
    }
  };

  render() {
    const { radioValue, showSignUpModal } = this.state;
    const { contactMessageSent } = this.props.generalReducer;
    const { removeContactMessageSentFlag } = this.props.generalActions;
    const renderInputs = this._renderInputs();
    const renderBudgetDropdown = this._renderBudgetDropDown();
    const renderCheckboxOptions = this._renderOptions();
    const renderPasswords = this._renderPasswords();
    const renderTermsAndPrivacy = this._renderTermsAndPrivacy();
    const renderMMOptions = this._renderMMOptions();
    const renderLastTwoOptions = this._renderLastTwoOptions();
    const renderCompanies = this._renderCompanies();

    if (showSignUpModal) {
      return this.showSignUpModal(showSignUpModal);
    }

    return (
      <div className="contact-page-container talk-to-a-matchmaker flex-row">
        <div
          className="left-section flex-column"
          style={{ maxHeight: "calc(100vh - 81px)", marginTop: "81px" }}
        >
          <div
            className="contact-form talk-to-a-mm-form flex-column"
            style={{ padding: "30px" }}
          >
            <div className="contact-form__title">
              Talk With a Matchmaker About Becoming a Client
            </div>
            <div className="text">
              Be proactive and work with a reputable Matchmaker who will focus
              on matching you. Our Founder was a matchmaker herself for over 5
              years, and knows matchmaking companies inside and out. We’ll
              introduce you to a matchmaker based on your location, budget and
              individual needs. There’s no obligation to become a client.
            </div>
            {renderInputs}
            {this.props.history.location.state !== undefined
              ? renderCompanies
              : null}
            {renderBudgetDropdown}
            {renderMMOptions}
            {renderLastTwoOptions}
            {renderCheckboxOptions}
            {renderPasswords}
            {renderTermsAndPrivacy}
            {contactMessageSent && (
              <ToolTip
                text={"Your message was successfully sent!"}
                closeCallback={() => removeContactMessageSentFlag()}
              />
            )}
            <div className="flex-row talk-button">
              <Button
                value="SUBMIT"
                customClass="yellow send-message"
                click={() => this.sendData(radioValue)}
              />
            </div>
          </div>
        </div>
        <div className="right-section flex-column">
          <div className="talk-to-a-matchmaker-background flex-column">
            <div className="couple-info flex-column flex-center">
              <span>Meghan & Andrew</span>
              <span>NYC</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  showSignUpModal = (showSignUpModal) => {
    const content = (
      <div className="flex-column">
        <div className="message">{`Your message has been sent. We’ll get back to you soon with a couple
            recommendations of matchmaking companies. In the meantime, please fill out our important
             questionnaire so matchmakers can identify you as a potential match and reach out with any match options.`}</div>
        <Button value="Got it" click={() => this.goToUserDashboard()} />
      </div>
    );
    return (
      <Modal
        isOpen={showSignUpModal}
        className="talk-to-a-mm-signup"
        modalContent={content}
      />
    );
  };

  goToUserDashboard = () => {
    const { user, token } = this.state;
    UserSession.setToken(token, user, null);
    this.props.history.push({ pathname: "/" });
  };

  _renderInputs = () => {
    const { firstName, lastName, email, phoneNumber, errors, validFields } =
      this.state;
    return (
      <div>
        <div className="flex-row names" style={{ marginTop: "20px" }}>
          <InputText
            wrapperClassName="flex-column float-left"
            placeholder="First Name"
            error={errors.firstNameError}
            isValid={firstName && validFields.firstNameValid}
            onBlur={(e) =>
              firstName &&
              this.checkForValidationErrors(
                "char",
                e.target.value,
                "firstNameError",
                "firstNameValid"
              )
            }
            onChange={(e) =>
              this.setData(
                "firstName",
                e.target.value,
                "firstNameError",
                "firstNameValid"
              )
            }
            value={firstName}
          />
          <InputText
            wrapperClassName="flex-column float-right"
            placeholder="Last Name"
            error={errors.lastNameError}
            isValid={lastName && validFields.lastNameValid}
            onBlur={(e) =>
              lastName &&
              lastName.length > 2 &&
              this.checkForValidationErrors(
                "char",
                e.target.value,
                "lastNameError",
                "lastNameValid"
              )
            }
            onChange={(e) =>
              this.setData(
                "lastName",
                e.target.value,
                "lastNameError",
                "lastNameValid"
              )
            }
            value={lastName}
          />
        </div>

        <InputText
          wrapperClassName="flex-column"
          placeholder="Email"
          error={errors.emailError}
          isValid={email && validFields.emailValid}
          onChange={(e) =>
            this.setData("email", e.target.value, "emailError", "emailValid")
          }
          onBlur={(e) =>
            this.checkForValidationErrors(
              "email",
              e.target.value,
              "emailError",
              "emailValid"
            )
          }
          value={email}
        />
        <InputText
          wrapperClassName="flex-column contact-phone"
          placeholder="Phone Number (optional)"
          error={errors.phoneError}
          isValid={phoneNumber && validFields.phoneValid}
          onBlur={(e) =>
            phoneNumber &&
            this.checkForValidationErrors(
              "phoneNumber",
              e.target.value,
              "phoneError",
              "phoneValid"
            )
          }
          onChange={(e) =>
            this.setData(
              "phoneNumber",
              e.target.value,
              "phoneError",
              "phoneValid"
            )
          }
          value={phoneNumber}
        />
      </div>
    );
  };

  _renderPasswords = () => {
    const { radioValue, password, retypePassword, errors, validFields } =
      this.state;
    if (radioValue) {
      return (
        <div className="flex-column passwords">
          <InputText
            wrapperClassName="flex-column password"
            placeholder="Password"
            value={password}
            type="password"
            label="Password"
            error={errors.passwordError}
            isValid={password && validFields.passwordValid}
            onChange={(e) =>
              this.setData(
                "password",
                e.target.value,
                "passwordError",
                "passwordValid"
              )
            }
            onBlur={(e) =>
              password.length > 0 &&
              this.checkForValidationErrors(
                "password",
                e.target.value,
                "passwordError",
                "passwordValid"
              )
            }
          />
          <InputText
            wrapperClassName="flex-column"
            placeholder="Retype password"
            type="password"
            error={errors.retypePasswordError}
            isValid={retypePassword && validFields.retypePasswordValid}
            onChange={(e) => {
              this.setData(
                "retypePassword",
                e.target.value,
                "retypePasswordError",
                "retypePasswordValid"
              );
              this.setState({
                errors: {
                  ...errors,
                  passwordError: false,
                  retypePasswordError: false,
                },
              });
            }}
            onBlur={() => retypePassword && this.checkPasswords()}
            value={retypePassword}
          />
        </div>
      );
    }
    return null;
  };

  checkPasswords = () => {
    const { password, retypePassword, errors, validFields } = this.state;
    if (Validator.validatePassword(password)) {
      if (password !== retypePassword) {
        this.setState({
          errors: {
            ...errors,
            passwordError: "Doesn't match password",
            retypePasswordError: "Doesn't match password",
          },
        });
      } else {
        this.setState({
          validFields: {
            ...validFields,
            passwordValid: true,
            retypePasswordValid: true,
          },
          errors: { ...errors, passwordError: null, retypePasswordError: null },
        });
      }
    } else {
      this.setState({
        errors: { ...errors, passwordError: "At least 6 characters" },
      });
    }
  };

  _renderBudgetDropDown = () => {
    const { budgetValue } = this.state;
    return (
      <div className="budget-options">
        <div className="matchmaker-options__title">Budget</div>
        <DropDown
          listOfValues={BUDGET_VALUES || null}
          selectedValue={budgetValue}
          placeholder="Select..."
          onSelectValue={(val) => this.setState({ budgetValue: val })}
        />
      </div>
    );
  };

  _renderCompanies = () => {
    const { threeDayRule, kelleher, lynx, otherCompany } = this.state;
    return (
      <div className="flex-column companies">
        <span className="matchmaker-options__title">{`Companies To Consider`}</span>
        <CheckBox
          labelName="Three Day Rule"
          clicked={() => this.setState({ threeDayRule: !threeDayRule })}
          checked={threeDayRule}
        />
        <CheckBox
          labelName="Kelleher Intl"
          clicked={() => this.setState({ kelleher: !kelleher })}
          checked={kelleher}
        />
        <CheckBox
          labelName="Linx Dating"
          clicked={() => this.setState({ lynx: !lynx })}
          checked={lynx}
        />
        <div className="flex-row other-comp-ckb">
          <CheckBox
            labelName="Any others you recommend"
            clicked={() => this.setState({ otherCompany: !otherCompany })}
            checked={otherCompany}
          />
        </div>
      </div>
    );
  };

  _renderMMOptions = () => {
    const { contactMessageSent } = this.props.generalReducer;
    const { mmOptionsValuesError } = this.state.errors;
    return (
      <MatchmakerOptions
        returnOptionsValues={(v) => this.processMmOptions(v)}
        error={mmOptionsValuesError}
        resetValues={contactMessageSent}
      />
    );
  };

  processMmOptions = (values) => {
    const { errors } = this.state;
    this.setState({
      mmOptionsValues: values,
      errors: { ...errors, mmOptionsValuesError: false },
    });
  };

  _renderOptions = () => {
    const { radioValue, errors, email } = this.state;
    return (
      <div className="interested-in flex-column">
        <div className="flex-column talk-to-a-matchmaker-checkbox">
          <CheckBox
            labelName={`Sign up to also join DateSpot’s database as a free potential match for clients.`}
            clicked={() => {
              !radioValue && this.checkIfMailExists(email);
              this.setState({
                radioValue: !radioValue,
                errors: {
                  ...errors,
                  passwordError: false,
                  retypePasswordError: false,
                  emailError: false,
                },
              });
            }}
            checked={radioValue}
          />
        </div>
      </div>
    );
  };

  _renderLastTwoOptions = () => {
    const { basicRequirements } = this.state;
    return (
      <div className="flex-column anything-else">
        <div className="matchmaker-options__title small">
          Partner Requirements/Preferences and Anything Else
        </div>
        <TextArea
          wrapperClassName="flex-column"
          isValid={basicRequirements}
          rows={3}
          onChange={(e) => this.setState({ basicRequirements: e.target.value })}
          value={basicRequirements}
        />
      </div>
    );
  };

  _renderTermsAndPrivacy = () => {
    const { agreeWithTermsAndPolicies, errors } = this.state;
    const { history } = this.props;
    return (
      <div>
        <div className="agree-terms flex-row flex-vertical-center">
          <CheckBox
            checked={agreeWithTermsAndPolicies}
            clicked={() =>
              this.setState({
                agreeWithTermsAndPolicies: !agreeWithTermsAndPolicies,
                errors: { ...errors, agreeWithTermsAndPoliciesError: null },
              })
            }
          />
          <span>I agree with the</span>
          <span
            onClick={() => history.push({ pathname: "/terms-of-service" })}
            className="underlined"
          >
            Terms of Service
          </span>
          <span>
            and
            <span
              onClick={() => history.push({ pathname: "/privacy-policy" })}
              className="underlined"
            >
              Privacy Policy.
            </span>
          </span>
        </div>
        {errors.agreeWithTermsAndPoliciesError && (
          <div className="custom-error-label normal">
            {errors.agreeWithTermsAndPoliciesError}
          </div>
        )}
      </div>
    );
  };

  checkForValidationErrors = (type, value, stateError, validField) => {
    const { radioValue } = this.state;
    let valid;
    switch (type) {
      case "char":
        valid = Validator.validateCharacter(value);
        this.setErrorsOrValids(valid, validField, stateError, "Invalid");
        break;
      case "phoneNumber":
        valid = Validator.validatePhoneNumber(value);
        this.setErrorsOrValids(valid, validField, stateError, "Invalid");
        break;
      case "email":
        valid = Validator.validateEmail(value);
        radioValue && this.checkIfMailExists(value);
        this.setErrorsOrValids(valid, validField, stateError, "Invalid");
        break;
      case "password":
        valid = Validator.validatePassword(value);
        this.setErrorsOrValids(
          valid,
          validField,
          stateError,
          "At least 6 characters"
        );
        break;
      default:
        break;
    }
  };

  setErrorsOrValids = (valid, validField, stateError, message) => {
    const { validFields, errors } = this.state;
    valid
      ? this.setState({ validFields: { ...validFields, [validField]: true } })
      : this.setState({ errors: { ...errors, [stateError]: message } });
  };

  checkIfMailExists = (email) => {
    const { errors, validFields } = this.state;
    const callback = (err, res) => {
      if (res && res.data.emailAlreadyExists) {
        this.setState({
          errors: { ...errors, emailError: "Email already exists" },
          validFields: { ...validFields, emailValid: false },
        });
      }
    };
    this.props.authenticationActions.verifyIfEmailExists(email, callback);
  };
}

TalkToAMMPage.propTypes = {
  history: PropTypes.object,
  generalActions: PropTypes.object,
  generalReducer: PropTypes.object,
  authenticationActions: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return {
    generalActions: bindActionCreators(generalActions, dispatch),
    authenticationActions: bindActionCreators(authenticationActions, dispatch),
  };
};

const mapStateToProps = (state) => {
  return {
    generalReducer: state.generalReducer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TalkToAMMPage);
