import SearchInput from "_old/src/components/search-input";
import { ADMIN_TABLE_HEADERS } from "_old/src/constants";
import { CLIENT_STATUS, REGIONS } from "_old/src/constants/onboarding-data-elements";
import React, { useEffect, useState } from "react";
import { statesIndex } from "data/states";
// import "_old/src/containers/dashboards/user-list.scss";
import "./AdminSingles.scss"
import PropTypes from "prop-types";
import { apiPost } from "launchpad";
import prettyName from "pretty-name";
import PaginationBar from "../../PaginationBar";
// import * as adminActions from "_old/src/actions/adminActions";
import { CheckBox } from "src/components/widgets";
import { getGlobal } from "launchpad";
import { toast } from "react-toastify";
import { setFilters } from "src/components/filters/Filters";

const USERS_PER_PAGE = 10;

const AdminSingles = ({ adminActions, isTabletDown }) => {
    const [page, setPage] = useState(1);
    const [users, setUsers] = useState([]);
    const [total, setTotal] = useState(0);
    const filters = getGlobal("userFilters");
    useEffect(() => {
        const { query, ...restFilters } = filters;

        apiPost('users', {
            filters: restFilters,
            page: page,
            per_page: USERS_PER_PAGE,
            query: query ?? "",
        }).then((res) => {
            setUsers(res.data);
            setTotal(res.total);
        });

    }, [page, JSON.stringify(filters)]);

    return (
        <div className="admin-users-list">
            {isTabletDown && <DashBoardSearchInput />}
            <div className="table-container">
                <table className="admin-user-table">

                    {!isTabletDown && <thead>
                    <tr className="labels">
                        {ADMIN_TABLE_HEADERS.users.map((tab, t) => {
                            return (
                                <th
                                    className={tab.class}
                                    key={t}
                                >
                                    {tab.name}
                                </th>
                            );
                        })}
                    </tr>
                    </thead>}

                    <RenderUsers adminActions={adminActions} filteredUsers={users} isTabletDown={isTabletDown} />

                </table>
            </div>
            <PaginationBar
                total={total}
                per_page={USERS_PER_PAGE}
                page={page}
                onChange={(page) => setPage(page)} />
        </div>
    );

}

const DashBoardSearchInput = () => {

    const [search, setSearch] = useState("");
    const filters = getGlobal("userFilters");
    useEffect(() => {
        const timeOut = setTimeout(() => {
            if (filters.user_id && search == "") {
                const { user_id, ...rest } = filters
                setFilters(rest, { overwrite: true })
            }
            if (+search) setFilters({ user_id: search })
            else setFilters({ query: search })
        }, 3000);

        return () => clearTimeout(timeOut);
    }, [search]);

    return (
        <div className="search-bar-container">
            <SearchInput
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search by name or #"
            />
        </div>
    )
}

const RenderUsers = ({ filteredUsers, adminActions, isTabletDown }) => {
    return (filteredUsers && !filteredUsers.length) ?
        (<tbody className="empty-table">
        <tr>
            <td colSpan={8}>No results found for those criteria</td>
        </tr>
        </tbody>)
        : <tbody>{filteredUsers.map((user) => <UserRow user={user} adminActions={adminActions} key={user._id} isTabletDown={isTabletDown} />)}</tbody>;
}

const UserRow = ({ user, adminActions, isTabletDown }) => {
    const avatar = getThumbnail(user);

    const handleRowClick = (e) => {
        if (!hasClassOrIsDescendant(e.target, "show-more")) {
            adminActions.getClickedUserInfoAdmin(user._id);
        }
    }

    const stopPropagation = (e) => {
        e.stopPropagation();
    }

    return !isTabletDown ? (
        <tr className="user-row" onClick={handleRowClick}>
            <td className="">
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    {avatar}
                </div>

            </td>
            <td className="">
                {user.firstName} {user.lastName}
            </td>
            <td className="">{user.user_id}</td>
            <td className="">
                <p>{prettyName(user.city)}</p>
                <p>{getRegion(user)}</p>
                <p>{user.country != "United States of America" && prettyName(user.country)}</p>
            </td>
            <td className="">{user.age}</td>
            <td onClick={stopPropagation}>
                <div className="flex-column element willing-to-pay">
                    <RenderCheckboxesWP user={user} adminActions={adminActions} />
                </div>
            </td>
            <td className="">
                {calculateIndividualPercentages(
                    user.images,
                    user.userProgress
                )}
                %
            </td>
            <td>
                {user.userRating ?? "--"}
            </td>
            <td onClick={stopPropagation}>
                <ReviewCheckBox user={user} adminActions={adminActions} />
            </td>
        </tr>
    ) : (
        <tr className="user-row-mobile" onClick={handleRowClick}>
            <td className="">
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    {avatar}
                </div>

            </td>
            <td className="">
                <p>{user.firstName} {user.lastName}</p>
                <p><b>ID: </b>{user.user_id}</p>
                <p><b>Location: </b>{prettyName(user.city)},{getRegion(user)}</p>
                <p><b>Age: </b>{user.age}</p>
                <p><b>Completion: </b>{calculateIndividualPercentages(
                    user.images,
                    user.userProgress
                )}
                    %</p>
                <p><b>Atractiveness: </b>{user.userRating ?? "--"}</p>
            </td>
            <td onClick={stopPropagation}>
                <ReviewCheckBox user={user} adminActions={adminActions} />
            </td>
            {/* </div> */}
        </tr>
    );
}


const Status = ({ value, label, color, _id, user_id, handleClick }) => {
    return (
        <div
            className="status"
            onClick={() => handleClick(_id, user_id, value)}
        >
            <span style={{ color: color }}>{label}</span>
        </div>
    );
}

const RenderCheckboxesWP = ({ user, adminActions }) => {
    const { saveUserFieldDataFromAdmin } = adminActions;
    const { _id, clientStatus, user_id } = user;
    const [currentClientStatus, setCurrentClientStatus] = useState(clientStatus);

    const handleClick = (_id, user_id, value) => {
        toast.info(
            `Client status updated to ${value} for member ${user_id}`,
            {
                icon: false,
                pauseOnHover: false,
            }
        );
        setCurrentClientStatus(value);
        saveUserFieldDataFromAdmin(_id, "clientStatus", value);
        if (value === "client") {
            saveUserFieldDataFromAdmin(_id, "clientLead", "Current Client");
        }
        // console.log(res);
    };


    // let status = CLIENT_STATUS.find(x => x.value === clientStatus);
    return user &&
        (<div className="client-status">
                {CLIENT_STATUS.map((status, i) => {
                    let color =
                        status.value === currentClientStatus
                            ? CLIENT_STATUS.find((x) => x.value === currentClientStatus)?.color
                            : "gray";
                    return (
                        <Status
                            key={`client-status-${i}`}
                            id={i}
                            value={status.value}
                            label={status.label}
                            color={color}
                            _id={_id}
                            user_id={user_id}
                            handleClick={handleClick}
                        />
                    );
                })}
            </div>
        );
}

const ReviewCheckBox = ({ user, adminActions }) => {
    const { saveUserFieldDataFromAdmin } = adminActions;
    const [checked, setChecked] = useState(user.verification.verified);
    const handleReviewCheckBoxClick = () => {
        setChecked(!checked);
        saveUserFieldDataFromAdmin(user._id, "verification.verified", !checked);
    }
    return (
        <div className="element" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <CheckBox value={user._id} checked={checked} onChange={handleReviewCheckBoxClick} />
        </div>)
}

const getThumbnail = (user) => {
    const image = user.images && user.images[0] && user.images[0].url;
    const initials = user.firstName[0] + user.lastName[0];
    return (
        <div className="avatar" style={{ backgroundImage: `url(${image})` }}>
            {!image && initials}
        </div>
    );
}

const getRegion = (user) => {
    let closestRegion = {};
    // FIXME: workaround for users with that doesn't have regionsData (the model is initialized with an empty array but some users doesn't have it for some reason)
    const regionsData = user.regionsData || [];
    for (let region of regionsData) {
        if (!closestRegion.distance || region.distance < closestRegion.distance) {
            closestRegion = region;
        }
    }
    let label;
    if (closestRegion.distance || closestRegion.distance === 0) {
        label =
            closestRegion.label || `${closestRegion.city}, ${closestRegion.state}`;
    }
    if (!label && user.regions?.length)
        label =
            statesIndex[user.regions[0]] ||
            REGIONS.find((x) => x.value === user.regions[0])?.label ||
            label;
    return label;
};

const calculateIndividualPercentages = (images, userProgress) => {
    const progress = {};
    if (images && images.length > 0) {
        progress.photos = 100;
    }

    if (userProgress) {
        Object.keys(userProgress).forEach((item) => {
            if (parseInt(userProgress[item], 10) > 100) {
                progress[item] = 100;
            } else {
                progress[item] = parseInt(userProgress[item], 10);
            }
        });
    }

    let x = 0;
    Object.values(progress).forEach((val) => {
        x += (val * 100) / 400;
    });
    return x;
};

const calculateTotalPercentage = (data) => {
    let x = 0;
    Object.values(data).forEach((val) => {
        x += (val * 100) / 400;
    });
    return x;
};

const hasClassOrIsDescendant = (element, classname) => {
    if (
        element !== "undefined" &&
        element.classList &&
        element.classList.contains("show-more")
    )
        return true;
    return (
        element.parentNode &&
        hasClassOrIsDescendant(element.parentNode, classname)
    );
};

AdminSingles.propTypes = {
    adminActions: PropTypes.object,
};
export default AdminSingles;