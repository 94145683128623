export const SECTIONS_ORDER = {
  1: { pathname: "/the-basics", section: "theBasics" },
  2: { pathname: "/more-about", section: "moreAbout" },
  3: { pathname: "/your-partners", section: "yourPartners" },
  4: { pathname: "/photos", section: "photos" },
  5: { pathname: "/account", section: "account" },
};

export const ORDERED_SECTION = {
  theBasics: 1,
  moreAbout: 2,
  yourPartners: 3,
  photos: 4,
  account: 5,
};

export const ON_BOARDING_URLS_TO_TABS = {
  "/the-basics": "theBasics",
  "/more-about": "moreAbout",
  "/your-partners": "yourPartners",
  "/photos": "photos",
  "/account": "account",
};

export const SERVER_URI = "/";
//process.env.NODE_ENV === 'development' ? 'http://localhost:8096' : '';

export const TITLE_TABS = {
  theBasics: "The Basics",
  moreAbout: "More About You",
  yourPartners: "Your Partner(s)",
  photos: "Photos",
  account: "Account",
};
