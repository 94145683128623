import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as generalActions from "../../actions/generalActions";
import UserSession from "../user-session";
import logo from "./assets/date-spot-logo.svg";
import { HEADER_TABS_ALLOWED, HEADER_TABS_DATA } from "../../constants";
import "./style.scss";
import Profile from "../profile-component";

class MainHeader extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    section: PropTypes.string,
  };

  render() {
    const { section, history } = this.props;
    const { selectedTab } = this.props.generalReducer;

    return (
      <div className={`main-header flex-row ${section}`}>
        {HEADER_TABS_DATA.map((tab, t) =>
          tab.isLogo
            ? this._renderLogo(t, history, section)
            : this._renderHeaderTab(
                selectedTab,
                tab.class,
                tab.pathname,
                tab.section,
                tab.title,
                tab.tab,
                tab.icon,
                t,
                history
              )
        )}
        <Profile history={history} />
      </div>
    );
  }

  _renderLogo = (key, history, section) => {
    const { setSelectedHeaderTab } = this.props.generalActions;
    const isMatchmakerDashBoard = section === "matchmakerDashboard";
    const isAdminDashBoard = section === "adminDashboard";
    const isUserDashBoard = section === "userDashboard";
    return (
      <div className="flex-center  main-header__tab date-spot-logo" key={key}>
        <div className="flex-column">
          <img src={logo} onClick={() => history.push("/dashboard")} />
        </div>
      </div>
    );
  };

  _renderHeaderTab = (
    selectedTab,
    selectedTabClass,
    pathname,
    section,
    title,
    tab,
    icon,
    key
  ) => {
    const { setSelectedHeaderTab } = this.props.generalActions;
    const { history } = this.props;
    const userRole = UserSession.getToken().role || "default";
    const showTab = HEADER_TABS_ALLOWED[userRole][tab];

    return (
      showTab && (
        <div
          className={`flex-center  main-header__tab ${selectedTabClass}`}
          key={key}
        >
          <div
            className={`${
              selectedTab === selectedTabClass
                ? "main-header__item flex-center selected-tab"
                : "main-header__item flex-center"
            }`}
            onClick={() => {
              setSelectedHeaderTab(selectedTabClass);
              if (pathname) {
                pathname.startsWith(process.env.MARKETING_SITE_URL)
                  ? window.open(pathname)
                  : history.push({ pathname, section, selectedTab });
              }
            }}
          >
            {icon && <span className={`tab-icon ${icon}`} />}
            {title}
          </div>
        </div>
      )
    );
  };
}

MainHeader.propTypes = {
  history: PropTypes.object,
  generalActions: PropTypes.object,
  generalReducer: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    generalReducer: state.generalReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    generalActions: bindActionCreators(generalActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainHeader);
