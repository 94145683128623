/*
----------------
index.jsx
----------------
*/

import "babel-polyfill";
// import './image-hack.js'
import React from "react";
import App, { history } from "./app";
import { launchpadInit } from "launchpad";
import { run_preflight } from "launchpad/devops/pre-flight";

/*
    There seems to be a problem with parcel and the way it handles images.
    For an image import to work on a component you will need to import it down here.
    * Don't look at me, this was already implemented this way by previous devs u.u *
    //TODO: Fix this.
 */
require("_old/src/containers/home-pages/assets/team/carla.png");
require("_old/src/containers/home-pages/assets/team/mike.png");
require("_old/src/containers/home-pages/assets/team/navin.png");
require("_old/src/components/main-header/assets/date-spot-logo.svg");
require("_old/src/containers/home-pages/assets/home-page-values/kindness.svg");
require("_old/src/containers/home-pages/assets/home-page-values/authenticity.svg");
require("_old/src/containers/home-pages/assets/home-page-values/transparency.svg");
require("_old/src/containers/home-pages/assets/home-page-values/inclusion.svg");
require("_old/src/containers/home-pages/assets/how-we-help-matchmakers/more-matches.svg");
require("_old/src/containers/home-pages/assets/how-we-help-matchmakers/comprenhensive-profiles.svg");
require("_old/src/containers/home-pages/assets/how-we-help-matchmakers/advanced-filtering.svg");
require("_old/src/containers/home-pages/assets/how-we-help-matchmakers/updated-status.svg");
require("_old/src/containers/home-pages/assets/homepage-singles/partners.png");
require("_old/src/containers/home-pages/assets/homepage-singles/checkmark.svg");
require("_old/src/containers/home-pages/assets/contact-page/pin.svg");
require("_old/src/containers/welcome-onboarding/photo-section/star.svg");
require("_old/src/containers/dashboards/admin/users-list/trash.svg");
require("_old/src/containers/home-pages/assets/member-photos/j.png");
require("_old/src/containers/home-pages/assets/member-photos/k.png");
require("_old/src/containers/home-pages/assets/member-photos/member10.png");
require("_old/src/containers/home-pages/assets/member-photos/member12.png");
require("_old/src/containers/home-pages/assets/member-photos/member5.png");
require("_old/src/containers/home-pages/assets/member-photos/member6.png");
require("_old/src/containers/home-pages/assets/member-photos/member7.png");
require("_old/src/containers/home-pages/assets/member-photos/u111 Cropped.jpg");
require("_old/src/containers/home-pages/assets/member-photos/u12.png");
require("_old/src/containers/home-pages/assets/member-photos/u126 Cropped.jpg");
require("_old/src/containers/home-pages/assets/member-photos/u13.png");
require("_old/src/containers/home-pages/assets/member-photos/u16.jpg");
require("_old/src/containers/home-pages/assets/member-photos/u18.png");
require("_old/src/containers/home-pages/assets/member-photos/u19.png");
require("_old/src/containers/home-pages/assets/member-photos/u199 Cropped.jpg");
require("_old/src/containers/home-pages/assets/member-photos/u20.png");
require("_old/src/containers/home-pages/assets/member-photos/u22.png");
require("_old/src/containers/home-pages/assets/member-photos/u223 Cropped.png");
require("_old/src/containers/home-pages/assets/member-photos/u232 Cropped.jpg");
require("_old/src/containers/home-pages/assets/member-photos/u24.png");
require("_old/src/containers/home-pages/assets/member-photos/u243 Cropped.jpg");
require("_old/src/containers/home-pages/assets/member-photos/u252 Cropped.jpg");
require("_old/src/containers/home-pages/assets/member-photos/u274 Cropped.jpg");
require("_old/src/containers/home-pages/assets/member-photos/u279 Cropped.jpg");
require("_old/src/containers/home-pages/assets/member-photos/u290 Cropped.jpg");
require("_old/src/containers/home-pages/assets/member-photos/u298 Cropped.jpg");
require("_old/src/containers/home-pages/assets/member-photos/u309 Cropped.jpg");
require("_old/src/containers/home-pages/assets/member-photos/u314 Cropped.png");
require("_old/src/containers/home-pages/assets/member-photos/u316_Cropped.jpg");
require("_old/src/containers/home-pages/assets/member-photos/u319 Cropped.jpg");
require("_old/src/containers/home-pages/assets/member-photos/u320 Cropped.jpg");
require("_old/src/containers/home-pages/assets/member-photos/u328 Cropped.jpg");
require("_old/src/containers/home-pages/assets/member-photos/u34.png");
require("_old/src/containers/home-pages/assets/member-photos/u340 Cropped.jpg");
require("_old/src/containers/home-pages/assets/member-photos/u346 Cropped.jpg");
require("_old/src/containers/home-pages/assets/member-photos/u348 Cropped.png");
require("_old/src/containers/home-pages/assets/member-photos/u35.png");
require("_old/src/containers/home-pages/assets/member-photos/u352 Cropped.jpg");
require("_old/src/containers/home-pages/assets/member-photos/u359 Cropped.jpg");
require("_old/src/containers/home-pages/assets/member-photos/u360 Cropped.png");
require("_old/src/containers/home-pages/assets/member-photos/u378 Cropped.jpg");
require("_old/src/containers/home-pages/assets/member-photos/u382 Cropped.png");
require("_old/src/containers/home-pages/assets/member-photos/u385 Cropped.jpg");
require("_old/src/containers/home-pages/assets/member-photos/u386 Cropped.png");
require("_old/src/containers/home-pages/assets/member-photos/u39 Cropped.jpg");
require("_old/src/containers/home-pages/assets/member-photos/u392 Cropped.png");
require("_old/src/containers/home-pages/assets/member-photos/u407 Cropped.jpg");
require("_old/src/containers/home-pages/assets/member-photos/u408 Cropped.jpg");
require("_old/src/containers/home-pages/assets/member-photos/u41 Cropped.jpg");
require("_old/src/containers/home-pages/assets/member-photos/u415 Cropped.png");
require("_old/src/containers/home-pages/assets/member-photos/u426 Cropped.jpg");
require("_old/src/containers/home-pages/assets/member-photos/u432 Cropped.jpg");
require("_old/src/containers/home-pages/assets/member-photos/u45 Cropped.jpg");
require("_old/src/containers/home-pages/assets/member-photos/u608 Cropped.jpg");
require("_old/src/containers/home-pages/assets/member-photos/u614 Cropped.jpg");
require("_old/src/containers/home-pages/assets/member-photos/u643 Cropped.jpg");
require("_old/src/containers/home-pages/assets/member-photos/u651 Cropped.jpg");
require("_old/src/containers/home-pages/assets/member-photos/u68.png");
require("_old/src/containers/home-pages/assets/member-photos/u69.png");
require("_old/src/containers/home-pages/assets/member-photos/u70.jpg");
require("_old/src/containers/home-pages/assets/member-photos/u73.png");
require("_old/src/containers/home-pages/assets/member-photos/u76.png");
require("_old/src/containers/home-pages/assets/member-photos/u82 Cropped.jpg");
require("_old/src/containers/home-pages/assets/member-photos/u89 Cropped.jpg");
require("_old/src/containers/home-pages/assets/member-photos/u610_Cropped.jpg");
require("_old/src/containers/home-pages/assets/member-photos/u823_Cropped.jpg");
require("src/images/facebook.svg");
require("src/images/Layla&Steve.jpg");
require("src/images/datespot-logo-w-label.svg");
require("src/images/facebook-footer.svg");
require("src/images/instagram-footer.svg");
require("src/images/linkedin-footer.svg");
require("src/images/twitter-footer.svg");
require("src/images/Jacky&Tim.jpg");
require("src/images/question.svg");
require("src/images/circle-chevron-down.svg");
require("src/images/check.svg");
require("src/images/Priscilla&Anthony.jpg");
require("src/images/lgbtq-plus.png");
require("src/images/poly-other.png");
require("src/images/trophy.png");
require("src/images/Datespot_Paused.png");
require("src/images/Official DateSpot Partner Seal.png");
require("src/images/Official-DateSpot-Approved-Partner.png");
require("src/images/help-image.png");
require("src/images/zelle.svg");
require("src/images/paypal.svg");
require("src/images/venmo.svg");
require("src/images/stripe.svg");
require("src/images/plus-icon.svg");
require("src/images/minus-icon.svg");
require("src/images/carla&greg.png");

// do a sanity check before proceeding
run_preflight();
function renderApp() {
  launchpadInit(<App />, "root", { history: history });
}

renderApp();

if (module.hot) module.hot.accept();
// if(module.hot) {
//   module.hot.accept(() => require('react-dom').render(<App />, document.getElementById('root')))
// }
