import React from "react";
import "./ProgressBar.scss";

const ProgressBar = ({ progress }) => {
  return (
    <div className="new-progress-bar">
      <div
        className="new-progress-bar__filler"
        style={{ width: `${progress}%` }}
      />
    </div>
  );
};
export default ProgressBar;
