import React, { useState } from "react";
import PropTypes from "prop-types";
import "./LifestyleSection.scss";
import TextArea from "src/components/TextArea";
import { useDebounce } from "src/hooks/useDebounce";
import RadioList from "_old/src/components/radio-list";
import CheckBoxList from "_old/src/components/check-box-list/index";
import { 
    RELIGION,
    RELIGION_PREFERENCE,
    POLITICAL_ORIENTATION,
    POLITICAL_PREFERENCE,
    HOBBIES,
    MUSIC,
    TRAVEL_IMPORTANCE,
    TRAVEL_EXPERIENCE,
    DRINKING_TYPE,
    SMOKING_TYPE,
    DO_DRUGS,
    EATING_HABITS,
    WORKOUT,
 } from "src/data/lifestyle-section";
import OldButton from "_old/src/components/button";
import { Button } from "src/components/widgets";
import { sortArrayByStringField } from "src/utils/functions";
import debounce from "lodash.debounce";
import HobbiesMusicCheckBoxList from "_old/src/components/hobbies-music-check-box-list/hobbies-music";
import RadioHorizontalList from "_old/src/components/radio-list-horizontal";
import DrugFrom from "_old/src/components/drugs-form";
import PetsDropdown from "_old/src/components/pets-dropdown";

/**
 * Family section of the singles onboarding form.
 *
 * @typedef {(key: string, value: any) => void} TSaveUserFieldDataFunction
 * @typedef {Object} TUserData - The user's data for the Lifestyle section.
 * @property {Object} religionPracticed - The user's religion.
 * @property {Object} religionPreference - The user's religion preference.
 * @property {Object} politicalOrientation - The user's political orientation.
 * @property {Object} politicalPreference - The user's political preference.
 * @property {String} upbringing - The user's upbringing.
 * @property {Object} matchAreaRange - The user's match area range.
 * @property {Object} matchArea - The user's match area.
 * @property {Object} matchAreaDetails - The user's match area details.
 * @property {Object} relocationOptions - The user's relocation options.
 * @property {Object[]} hobbies - The user's hobbies.
 * @property {Object[]} musicActivities - The user's music activities.
 * @property {String} passionateAbout - The user's passions.
 * @property {Object[]} musicGenres - The user's music genres.
 * @property {Object} travelImportance - The user's travel importance.
 * @property {Object} travelExperience - The user's travel experience.
 * @property {String} travelComments - The user's travel comments.
 * 
 *
 * @typedef {Object} TLifestyleSectionProps
 * @property {TUserData} userData - The user's data for the Lifestyle section.
 * @property {TSaveUserFieldDataFunction} saveUserFieldData - The function to save the user's data.
 * @property {Boolean} isTabletDown - Whether the screen is tablet size or smaller.
 *
 * @param {TLifestyleSectionProps} props
 * @returns {JSX.Element}
 */
const LifestyleSection = ({
  userData = {},
  saveUserFieldData = () => {},
  isTabletDown = false,
  setSectionFromNextButtons = () => {},
}) => {

    const handleChange = useDebounce((key, value) => {
        if (!key || !value) return;
        //TODO: Ideally, we should separate each section request to its own endpoint.
        saveUserFieldData(key, value);
    }, 500);

    const [userId, setUserId] = useState(userData.id);
    const [hobbies, setHobbies] = useState(userData.hobbies);
    const [musicActivities, setMusicActivities] = useState(userData.music.activities);
    const [passionateAbout, setPassionateAbout] = useState(userData.passionateAbout);
    const [musicGenres, setMusicGenres] = useState(userData.music.genres);
    const [travelImportance, setTravelImportance] = useState(userData.travel ? userData.travel.personalImportance : null);
    const [travelExperience, setTravelExperience] = useState(userData.travel ? userData.travel.personalExperience : null);
    const [travelComments, setTravelComments] = useState(userData.travel ? userData.travel.personalComments : null);
    const [eatingHabits, setEatingHabits] = useState(userData.eatingHabits);
    const [workout, setWorkout] = useState(userData.workout);
    const [drinking, setDrinking] = useState(userData.drinking);
    const [smoking, setSmoking] = useState(userData.smoking);
    const [does_drugs_bool, setDoes_drugs_bool] = useState(userData.does_drugs
        ? userData.does_drugs.drugs
        : null);
    const [does_drugs_types, setDoes_drugs_types] = useState(userData.does_drugs
        ? userData.does_drugs.types
        : null);
    const [does_drugs_partner_bool, setDoes_drugs_partner_bool] = useState(userData.does_drugs
        ? userData.does_drugs.partner_drugs
        : null);
    const [does_drugs_partner, setDoes_drugs_partner] = useState(userData.does_drugs
        ? userData.does_drugs.partner
        : null);
    const [pets, setPets] = useState(userData.pets);

    return (
        <section className="lifestyle-section">

            <div className="lifestyle-section__field-container">
                <CheckBoxList
                    extraClass="input-checkbox-full"
                    list={EATING_HABITS}
                    title="What are your eating habits?"
                    selectedValue={eatingHabits}
                    returnInputValue={(val) => {
                        handleChange("eatingHabits", val);
                        setEatingHabits(val);
                    }}
                    stateCallback={(val) => {
                        handleChange("eatingHabits", val);
                        setEatingHabits(val);
                    }}
                />
            </div>

            <div className="lifestyle-section__field-container">
                <RadioList
                    list={WORKOUT}
                    title="How often do you work out?"
                    selectedValue={workout}
                    stateCallback={(val) => handleChange("workout", val)}
                />
            </div>

            <div className="lifestyle-section__field-container">
                <RadioList
                    list={DRINKING_TYPE}
                    title="How often do you drink?"
                    selectedValue={drinking}
                    stateCallback={(val) => handleChange("drinking", val)}
                />
            </div>


            <div className="lifestyle-section__field-container">
                <RadioList
                    list={SMOKING_TYPE}
                    title="How often do you smoke cigarettes?"
                    selectedValue={smoking}
                    stateCallback={(val) => handleChange("smoking", val)}
                />
            </div>

            <div className="lifestyle-section__field-container">
                <RadioList
                    list={DO_DRUGS}
                    title="How often do you do any drugs?"
                    selectedValue={does_drugs_bool}
                    stateCallback={(val) => {
                        handleChange("does_drugs.drugs", val);
                        setDoes_drugs_bool(val);
                    }}
                />
            </div>

            {does_drugs_bool && does_drugs_bool.label !== "Never" && (
                <div className="lifestyle-section__field-container">
                    <DrugFrom
                        title="Specifying type and frequency can be helpful:"
                        saveUserFieldData={(field, value) => {
                            handleChange(field, value);
                        }}
                        drugs={does_drugs_types}
                        obj_name="types"
                    />
                </div>
            )}

            <div className="lifestyle-section__field-container">
                <RadioList
                    list={DO_DRUGS}
                    title="How often are you open to your partner doing drugs?"
                    selectedValue={does_drugs_partner_bool}
                    stateCallback={(val) => {
                        handleChange("does_drugs.partner_drugs", val);
                        setDoes_drugs_partner_bool(val);
                    }}
                />
            </div>

            {does_drugs_partner_bool && does_drugs_partner_bool.label !== "Never" && (
                <div className="lifestyle-section__field-container">
                    <DrugFrom
                        title="Specifying type and frequency can be helpful:"
                        saveUserFieldData={(field, value) =>
                            handleChange(field, value)
                        }
                        drugs={does_drugs_partner}
                        obj_name="partner"
                    />
                </div>
            )}

            <div className="lifestyle-section__field-container">
                <PetsDropdown
                    saveUserFieldData={(field, value) =>
                        handleChange(field, value)
                    }
                    pets={pets}
                />
            </div>

            <div className="lifestyle-section__field-container">
                <HobbiesMusicCheckBoxList
                    list={sortArrayByStringField(HOBBIES, "label", {
                        lastElement: "Other",
                    })}
                    title="What do you like to do for fun?"
                    hobbies_selectedValue={hobbies}
                    music_selectedValue={musicActivities}
                    extraClass="half-size-list"
                    hobbies_returnInputValue={(val) => {
                        handleChange("hobbies", val);
                        setHobbies(val);
                    }}
                    hobbies_stateCallback={(val) => {
                        handleChange("hobbies", val);
                        setHobbies(val);
                    }}
                    music_returnInputValue={(val) => {
                        handleChange("music.activities", val);
                        setMusicActivities(val);
                    }}
                    music_stateCallback={(val) => {
                        handleChange("music.activities", val);
                        setMusicActivities(val);
                    }}
                />
            </div>

            <div className="lifestyle-section__field-container">
                <div className="flex-column title-gap">
                    <div className="labels-container">
                        <div className="label valid">
                            What are you passionate about?
                        </div>
                    </div>
                    <TextArea
                        defaultValue={passionateAbout}
                        maxLength={240}
                        onChange={(e) => {
                            handleChange("passionateAbout", e.target.value);
                        }}
                    />
                </div>
            </div>

            <div className="lifestyle-section__field-container">
                <CheckBoxList
                    list={MUSIC.genres}
                    columns={2}
                    title="What music genres do you like?"
                    selectedValue={musicGenres}
                    extraClass="half-size-list"
                    returnInputValue={(val) => {
                        handleChange("music.genres", val);
                        setMusicGenres(val);
                    }}
                    stateCallback={(val) => {
                        handleChange("music.genres", val);
                        setMusicGenres(val);
                    }}
                    labelExceptions={{
                        "International/world": isTabletDown ? "Intl/world" : undefined,
                    }}
                />
            </div>

            <div className="lifestyle-section__field-container">
                <RadioList
                    list={TRAVEL_IMPORTANCE}
                    title="How important is travel to you?"
                    selectedValue={travelImportance}
                    stateCallback={(val) => {
                        handleChange("travel.personalImportance", val);
                        setTravelImportance(val);
                    }}
                />
            </div>

            <div className="lifestyle-section__field-container">
                <RadioList
                    list={TRAVEL_EXPERIENCE}
                    title="What's your international travel experience?"
                    selectedValue={travelExperience}
                    stateCallback={(val) => {
                        handleChange("travel.personalExperience", val)
                        setTravelExperience(val);
                    }}
                />
            </div>

            <div className="lifestyle-section__field-container">
                <div className="flex-column">
                    <div className="labels-container">
                        <div className="label valid">
                            Feel free to elaborate on any travel or living abroad.
                        </div>
                    </div>
                    <TextArea
                        defaultValue={travelComments}
                        onChange={(e) => {
                            handleChange("travel.personalComments", e.target.value);
                        }}
                    />
                </div>
            </div>

            <div className="lifestyle-section__continue-button-container">
                {isTabletDown ? (
                    <Button
                        fullWidth
                        variant="primary"
                        onClick={() => setSectionFromNextButtons("family")}
                    >
                        CONTINUE
                    </Button>
                ) : (
                    <OldButton
                        value="CONTINUE"
                        customClass="yellow"
                        click={() => setSectionFromNextButtons("family")}
                    />
                )}
            </div>
        </section>
    );
};

LifestyleSection.propTypes = {
    userData: PropTypes.shape({}),
    setSectionFromNextButtons: PropTypes.func,
    saveUserFieldData: PropTypes.func.isRequired,
    isTabletDown: PropTypes.bool,
};

export default LifestyleSection;
