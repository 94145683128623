/* eslint-disable object-curly-newline */
import React from "react";
import PropTypes from "prop-types";
import ReactDropzone from "react-dropzone";
import Api from "../../../actions/api";
import { PHOTOS_TEXT } from "../../../constants/onboarding-data-elements";
import CheckBox from "../../../components/check-box";
import starSvg from "./star.svg";
import TriangleComponent from "../../../components/triangle-component";
import "./style.scss";
import OldButton from "../../../components/button";
import { Button } from "src/components/widgets";
import BulletCheckList from "src/components/BulletCheckList";
import { RotateLoader } from "react-spinners";

class PhotoSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: props.files || [],
      profileHover: false,
      loading: false,
      photoTimeUpdate: new Date().getTime(),
    };
  }

  static getDerivedStateFromProps(props) {
    if (props.changedPhotos) {
      return {
        files: props.files,
      };
    }
    return null;
  }

  componentWillUnmount() {
    // Make sure to revoke the data uris to avoid memory leaks
    this.state.files.forEach((file) => URL.revokeObjectURL(file.url));
  }

  onDrop = (files) => {
    let new_files = files.map((file, index) => {
      return {
        position: this.state.files.length + index,
        url: URL.createObjectURL(file),
      };
    });
    this.setState(
      { files: [...this.state.files, ...new_files] },
      this.props.reloadUserData()
    );
    this.uploadFiles(files);
  };

  uploadFiles = (files) => {
    const formData = new FormData();
    const { generalActions, setData } = this.props;
    const { toggleLoadingModal } = generalActions;
    files &&
      files.forEach((file) => {
        formData.append(file.name, file);
      });
    // Add this if you want also to store the files on a user
    if (this.props.userId) {
      formData.append("userId", this.props.userId);
    }

    toggleLoadingModal({
      show: true,
      loading: true,
    });
    Api.postForm({
      url: "/api/upload",
      data: formData,
    }).then((res) => {
      if (res.data.images) {
        this.props.reloadUserData();
      }
    });
  };

  rotateImage = async (userId, photoNr, actionType) => {
    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("photoNr", photoNr);
    formData.append("actionType", actionType);

    this.setState({ loading: true });

    Api.postForm({
      url: "/api/rotatePhoto",
      data: formData,
    }).then((res) => {
      if (res.data.images) {
        setTimeout(() => {
          this.setState({ loading: false, photoTimeUpdate: new Date().getTime() });
        }, 3000);
      }
    });
  };

  render() {
    const { files, hoveredPic, openPhotoMenu } = this.state;
    const { allowsPhotos, userId, isTabletDown } = this.props;
    const isCheckedAllowPhotos = allowsPhotos || false;
    const thumbs = files.map((file, idx) => {
      const isHoveredPic = hoveredPic && hoveredPic.position === idx;
      const renderPhotosMenu = this.state.loading ? null : this._renderPhotosMenu(userId, idx);
      return (
        <div
          className={`upload-thumb ${
            isHoveredPic && openPhotoMenu ? "blurred" : ""
          }`}
          key={idx}
        >
          <div
            className={"upload-thumb-inner"}
            style={{ position: "relative " }}
            onMouseEnter={() => {
              this.setState({ hoveredPic: file });
            }}
            onMouseLeave={() => {
              this.setState({
                hoveredPic: null,
                openPhotoMenu: false,
              });
            }}
          >
            {idx === 0 && (
              <figure className="profile-icon">
                <img src={starSvg} alt="Profile Icon" />
              </figure>
            )}
            {this.state.loading && (
              //center the loader
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <RotateLoader
                  sizeUnit={"px"}
                  size={20}
                  color={"#f2f2f2"}
                  loading={this.state.loading}
                />
              </div>
            )}
              <img
              id={`img-${idx}`}
              src={decodeURI(file.url) + "?t=" + this.state.photoTimeUpdate }
              className={"upload-thumb-img"}
            />
            {
              <span
                className="icon-more"
                onClick={() =>
                  this.setState({
                    openPhotoMenu: !openPhotoMenu,
                  })
                }
              />
            }
            {isHoveredPic && openPhotoMenu && renderPhotosMenu}
          </div>
        </div>
      );
    });
    const renderDropZone = this._renderDropZone(thumbs);
    return (
      <div className="photo-section">
        {this.props.user.images && this.props.user.images.length === 0 && (
        <div className="flex-column gap-05">
          <div className="photo-section__message">
            Upload photos of yourself.
          </div>
            <BulletCheckList items={PHOTOS_TEXT} />
        </div>
        )}

        {this.state.profileHover ? (
          <div className="photo-section__message">
            To delete your main profile photo, first choose a different photo as
            a replacement.
          </div>
        ) : (
          <></>
        )}

        {renderDropZone}

        <div className="continue-button-container">
          {isTabletDown ? (
            <Button
              fullWidth
              variant="primary"
              onClick={() => this.props.setSectionFromNextButtons("background")}
            >
              CONTINUE
            </Button>
          ) : (
            <OldButton
              value="CONTINUE"
              click={() => this.props.setSectionFromNextButtons("background")}
              customClass="yellow"
            />
          )}
        </div>
      </div>
    );
  }

  _renderPhotosMenu = (userId, photoNr) => {
    const { setDeletePhotosUser } = this.props.generalActions;
    return photoNr !== 0 ? (
      <div className="flex-column photo-menu">
        <TriangleComponent top={-9} right={22} />
        <span
          className="flex-center"
          onClick={() => {
            setDeletePhotosUser("set", userId, photoNr);
            this.setState({ openPhotoMenu: false });
          }}
        >
          Set as profile
        </span>
        <span
          className="flex-center"
          onClick={async () => {
            this.rotateImage(userId, photoNr, "rotate");
            this.setState({ openPhotoMenu: false });
          }}
        >
        Rotate 90°
        </span>
        <span
          className="flex-center"
          onClick={() => {
            setDeletePhotosUser("delete", userId, photoNr);
            this.setState({ openPhotoMenu: false });
          }}
        >
          Delete
        </span>
      </div>
    ) : (
      <div className="flex-column photo-menu">
        <TriangleComponent top={-9} right={22} />
        <span
          className="flex-center"
          onClick={async () => {
            this.rotateImage(userId, photoNr, "rotate");
            this.setState({ openPhotoMenu: false });
          }}
        >
          Rotate 90°
        </span>
        <span
          className="flex-center"
          onClick={() => this.setState({ profileHover: true })}
        >
          Delete
        </span>
      </div>
    );
  };

  _renderDropZone = (thumbs) => {
    return (
      <div className="upload-photos">
        <div className="upload-photos__template">
          <ReactDropzone
            accept="image/*"
            // accept="image/png, image/jpg"
            onDrop={this.onDrop.bind(this)}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className="upload-photos__zone">
                <input {...getInputProps()} />
                <div className="upload-photos__plus" />
                <div className="upload-photos__placeholder">Add Photo(s)</div>
              </div>
            )}
          </ReactDropzone>
        </div>
        {thumbs}
      </div>
    );
  };
}

PhotoSection.propTypes = {
  history: PropTypes.object,
  setData: PropTypes.func,
  generalActions: PropTypes.object.isRequired,
  saveUserFieldData: PropTypes.func,
  files: PropTypes.array,
  userId: PropTypes.any,
  setSectionFromNextButtons: PropTypes.func,
  reloadUserData: PropTypes.func,
  user: PropTypes.object,
};

export default PhotoSection;
