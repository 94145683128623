import React, { useEffect, useRef, useState } from "react";
import {
  apiPost,
  apiGet,
  getGlobal,
  setGlobal,
  notify,
  confirm, apiRequest,
} from "launchpad";
import { useSetState, titleCase } from "launchpad/helpers";
import PaginationBar from "./PaginationBar";
import { DropDown } from "widgets";
import { reloadUser, history } from "app";
import moment from "moment";
import { statesIndex } from "../../../data/states";
import { REGIONS } from "../../../../_old/src/constants/onboarding-data-elements";
import prettyName from "pretty-name";

// TODO: remove dependencies on old api interaction, need to rebuild matchmaker routes
import Api from "_old/src/actions/api";
import SearchInput from "_old/src/components/search-input";

export const rateUser = (user, rating) => {
  Api.post({
    url: "/api/rate-user",
    data: { score: rating, userId: user._id },
    callback: reloadUserTables,
  });
};

export const saveFilters = (filters) => {
  Api.post({
    url: "/api/save-selected-filters",
    data: { savedSelectedFilters: filters },
    callback: async () => {
      await reloadUser();
      reloadUserTables();
    },
  });
};

const reloadUserTables = async () => {
  const user = await reloadUser();
  setGlobal({ usersTableNeedsRefresh: true, activeUser: user });
};

const postReload = async (route, options) => {
  options = options || {};
  if (typeof options == "string") {
    options = { message: options };
  }
  const { message } = options;

  const go = async () => {
    const { error } = await apiPost(route);
    if (error) {
      notify(error, { type: "error" });
    } else if (message) {
      notify(message.text || message, {
        type: message.type || "success",
      });
    }
    reloadUserTables();
  };

  if (options.confirm) {
    confirm(options.confirm, go);
  } else {
    await go();
  }
};

export const contactUser = async (user) => {
  console.log("hey in here", user);
  await postReload(`/contact-user/${user._id}`, {
    message: `We just emailed Carla about Member #${user.user_id} to explore this potential match!`,
  });
};

export const cancelContact = async (user) =>
  await postReload(`/cancel-contact/${user._id}`);
export const favoriteUser = async (user) =>
  await postReload(`/favorite-user/${user._id}`);
export const unFavoriteUser = async (user) =>
  await postReload(`/unfavorite-user/${user._id}`);
export const lockUser = async (user) =>
  await postReload(`/lock-user/${user._id}`);
export const unlockUser = async (user) =>
  await postReload(`/unlock-user/${user._id}`);

export const rejectMM = async (mm) =>
  await postReload(`/reject-mm/${mm._id}`, {
    message: {
      type: "error",
      text: `${mm.firstName} ${mm.lastName} rejected`,
    },
    confirm: `Are you sure you want to reject ${mm.firstName} ${mm.lastName}?`,
  });
export const approveMM = async (mm) =>
  await postReload(
    `/approve-mm/${mm._id}`,
    `${mm.firstName} ${mm.lastName} approved`
  );
export const approveAmb = async (mm) =>
  await postReload(
    `/approve-amb/${mm._id}`,
    `${mm.firstName} ${mm.lastName} approved`
  );
export const cancelMMPlan = async (mm) => {
  await postReload(`/cancel-mm-plan/${mm._id}`, {
    message: {
      type: "error",
      text: `${mm.firstName} ${mm.lastName}'s plan has been cancelled`,
    },
  });
};
export const restoreMMPlan = async (mm) => {
  await postReload(
    `/restore-mm-plan/${mm._id}`,
    `${mm.firstName} ${mm.lastName}'s plan has been restored`
  );
};

// for testing
export const cancelMM = async (mm) =>
  await postReload(
    `/cancel-mm/${mm._id}`,
    `${mm.firstName} ${mm.lastName} canceled`
  );

// possibly resize images automatically?
//const thumbUrl = url => (url || '').split('amazonaws.com').join('amazonaws.com/250x250')
const thumbUrl = (url) => url;

export const Thumbnail = ({ user }) => {
  const initials = user.firstName[0] + user.lastName[0];
  const image = thumbUrl(user.images && user.images[0] && user.images[0].url);
  return (
    <div
      className="user-thumbnail user-image"
      style={{ backgroundImage: `url(${image})` }}
    >
      {!image && initials}
    </div>
  );
};

function Columns({ schema, attributes, user }) {
  return (
    <>
      {Object.keys(schema)
        .filter((x) => !attributes || attributes.includes(x))
        .map((attribute, i) => {
          const data =
            typeof schema[attribute].data == "function"
              ? schema[attribute].data(user)
              : user[schema[attribute].data || attribute];
          return (
            <td key={attribute} className={attribute}>
              {data}
            </td>
          );
        })}
    </>
  );
}

export function MMRow({ user, attributes, onSelectUser }) {
  const [menu, setMenu] = useState(false);
  const schema = tableSchemas.matchmaker;

  const showMenu = (e) => {
    e.stopPropagation();
    setMenu(true);
  };
  const redBackground = user?.rejected && user?.approved;

  return (
    <tr
      className="user-row"
      key={`mm-row-${user._id}`}
      onMouseLeave={() => setMenu(false)}
      style={redBackground ? {backgroundColor:"#f78488"} : {}}
    >
      <Columns {...{ schema, attributes, user }} />
      <td onClick={showMenu} className="actions">
        <span className="icon-more" />
        <div className="action-wrapper">
          <DropDown className="action-menu" open={menu}>
            <UserAction
              icon="eye"
              onClick={() => onSelectUser && onSelectUser(user)}
            >
              Referrals & Credits
            </UserAction>
            {user.approved && (
              <UserAction
                icon="edit"
                onClick={() => history.push(`/maker-details?id=${user._id}`)}
              >
                Edit Matchmaker
              </UserAction>
            )}
            {(!user.approved || user.rejected) && (
              <UserAction icon="check" onClick={() => approveMM(user)}>
                Approve Matchmaker
              </UserAction>
            )}
            {(!user.approved || user.rejected) && (
              <UserAction icon="check" onClick={() => approveAmb(user)}>
                Approve Ambassador
              </UserAction>
            )}
            {user.approved && !user.planCanceled && (
              <UserAction icon="ban" onClick={() => cancelMMPlan(user)}>
                Cancel Plan
              </UserAction>
            )}
            {user.planCanceled && (
              <UserAction icon="check" onClick={() => restoreMMPlan(user)}>
                Restore Plan
              </UserAction>
            )}

            {!user.approved && (
              <UserAction icon="ban" onClick={() => rejectMM(user)}>
                Reject Matchmaker
              </UserAction>
            )}
            {user.approved && !user.rejected && (
              <UserAction icon="times" onClick={() => rejectMM(user)}>
                Remove Matchmaker
              </UserAction>
            )}

            {/* {user.approved && <UserAction icon='times' onClick={() => cancelMM(user)}>
            Cancel Approval
          </UserAction>} */}
          </DropDown>
        </div>
      </td>
    </tr>
  );
}

// render a user's row, optionally pass a list of attributes
export function UserRow({ user, attributes, onSelectUser, loadUsers }) {
  const [menu, setMenu] = useState(false);
  const schema = tableSchemas.user;

  const showMenu = (e) => {
    e.stopPropagation();
    setMenu(true);
  };

  if (user.getContactTiming) {
    const { hoursSinceContact, contactHours, contactDays } =
      user.getContactTiming();
  }
  return (
    <tr
      className="user-row"
      key={`user-row-${user._id}`}
      onMouseLeave={() => setMenu(false)}
      onClick={() => onSelectUser && onSelectUser(user)}
    >
      <Columns {...{ schema, attributes, user }} />
      <td className="indicators" onClick={(e) => e.stopPropagation()}>
        {user.potential && <span className="icon-heart red" />}
        {user.contacted && <span className="icon-contacted" />}
        {user.locked && <span className="icon-lock cyan" />}
        {user.connected && <span className="fa fa-link" />}
      </td>
      <td onClick={showMenu} className="actions">
        <span className="icon-more" />
        <div className="action-wrapper">
          <DropDown className="action-menu" open={menu}>
            <UserAction
              icon="eye"
              onClick={() => onSelectUser && onSelectUser(user)}
            >
              View Profile
            </UserAction>
            {!user.potential && (
              <UserAction icon="heart" onClick={() => favoriteUser(user)}>
                Add to My Potentials
              </UserAction>
            )}
            {user.favorited && (
              <UserAction icon="heart" onClick={() => unFavoriteUser(user)}>
                Remove from My Potentials
              </UserAction>
            )}
            {!user.locked && !user.otherLocked && (
              <UserAction icon="lock" onClick={() => lockUser(user)}>
                Lock User
              </UserAction>
            )}
            {user.locked && (
              <UserAction icon="lock" onClick={() => unlockUser(user)}>
                Unlock User
              </UserAction>
            )}
            {!user.contacted && !user.otherLocked && !user.connected && (
              <UserAction icon="contacted" onClick={() => contactUser(user)}>
                Contact Member
              </UserAction>
            )}
            {/*FIXME*/}
            {user.contacted &&
              typeof hoursSinceContact === "number" &&
              hoursSinceContact > 72 && (
                <UserAction
                  icon="contacted"
                  onClick={() => cancelContact(user)}
                >
                  Cancel Request
                </UserAction>
              )}
            {user.contacted &&
              typeof hoursSinceContact === "number" &&
              hoursSinceContact > 72 && (
                <UserAction icon="contacted" className="inactive">
                  Request Cancelable in{" "}
                  {contactDays > 0
                    ? `${contactDays} Days`
                    : `${contactHours} Hours`}
                </UserAction>
              )}
          </DropDown>
        </div>
      </td>
    </tr>
  );
}

const UserAction = ({ icon, children, onClick, className }) => {
  const moons = ["heart", "contacted", "lock", "eye"];
  const cn = moons.includes(icon) ? `icon icon-${icon}` : `icon fa fa-${icon}`;
  return (
    <div className={`user-profile-action ${className || ""}`} onClick={onClick}>
      <span className={cn} />
      <span className="content">{children}</span>
    </div>
  );
};

export default function List({
  filters,
  per_page,
  role,
  className,
  query,
  onSelectUser,
  onSelectUserCredits,
  onLoadUsers,
  headers,
}) {
  const containerRef = useRef(null);

  const [search, setSearch] = useState('');
  const [state, setState] = useSetState({
    users: {
      data: [],
      per_page: 10,
      page: 1,
      total: 0,
      all: 0,
    },
    page: 1,
    query: "",
  });
  const { users, page } = state;
  const [filteredUsers, setFilteredUsers] = useState(users.data)

  useEffect(() => {
    setFilteredUsers(users.data);
  }, [users.data]);

  let base = (users.page - 1) * users.per_page;
  base += users.total > 0 ? 1 : 0;
  const max = Math.min(users.total, base + (users.per_page - 1));
  // ${users.all} ${role == 'user' ? 'Current Members' : 'Approved Matchmakers'} |
  const showing = `${
    Object.keys(getGlobal("userFilters") || {}).length > 0 ? "Filters " : ""
  } Showing ${base}-${max} of ${users.total} ${
    Object.keys(getGlobal("userFilters") || {}).length > 0
      ? "filtered members"
      : "total members"
  }`;

  // TODO: we should either add this logic server side or create a globally
  // accessible "loadUsers" function
  const setupUser = (user, type = "user") => {
    let newUser = { ...user };
    const viewer = getGlobal("activeUser");
    if (viewer.role === "matchmaker" && type === "user") {
      for (const x of [
        "connected",
        "contacted_by",
        "favorited_by",
        "locked_by",
        "rejected",
      ]) {
        if (!newUser[x]) newUser[x] = [];
      }

      newUser.mmRating = newUser.ratings.find(
        (x) => x.matchMakerId === viewer._id
      );

      newUser.connections = newUser.connected;
      newUser.connected = !!newUser.connected.find((x) => x.mm === viewer._id);
      newUser.contactRequest = newUser.contacted_by.find(
        (x) => x.mm === viewer._id
      );
      newUser.contacted = !!newUser.contactRequest;
      newUser.favorited = !!newUser.favorited_by.find(
        (x) => x.mm === viewer._id
      );
      newUser.locked = !!newUser.locked_by.find((x) => x.mm === viewer._id);
      newUser.otherLocked = !!newUser.locked_by.find(
        (x) => x.mm !== viewer._id
      );
      newUser.potential =
        newUser.favorited || newUser.contacted || newUser.locked;

      newUser.getContactTiming = () => {
        const today = moment();
        const hoursSinceContact =
          newUser.contactRequest && newUser.contactRequest.since
            ? today.diff(newUser.contactRequest.since, "hours")
            : 0;
        const contactDays = Math.floor((72 - hoursSinceContact) / 24);
        const contactHours = (72 - hoursSinceContact) % 24;
        return { hoursSinceContact, contactDays, contactHours };
      };
    }
    return newUser;
  };

  const loadUsers = async () => {
    let users = [];
    users = await apiPost(role + "s", {
      filters: filters,
      page,
      per_page,
      query,
    });
    users.data = users.data.map((user) => setupUser(user, role));
    if (role === "user") {
      users.data = users.data.filter((x) => x.suspicion?.profile === false);
    }
    if (onLoadUsers) onLoadUsers(users);
    setState({ users });
  };

  useEffect(() => {
    loadUsers();
  }, [JSON.stringify(filters), page, query]);

  useEffect(() => {
    // I really dislike this pattern, unnecessary render
    if (getGlobal("usersTableNeedsRefresh")) {
      loadUsers();
      setGlobal({ usersTableNeedsRefresh: false });
    }
  });

  const handleTextChange = async (text) => {
    setSearch(text)
    if(text==="") return loadUsers();
    const matchmakers = await apiGet(`get-filtered-matchmakers/${text}`)
    if (matchmakers && Array.isArray(matchmakers)) {
      setFilteredUsers(matchmakers);
    }
  }

  const setPage = (page) => {
    setState({ page });
    if (containerRef.current) containerRef.current.scrollTop = 0;
  };

  // modify these lists to dynamically set which columns are shown
  let headerTemplate = {
    user: [
      "thumbnail",
      "user_id",
      "gender",
      "age",
      "attractiveness",
      "location",
      "status",
      "connections",
    ],
    matchmaker: [
      // 'Matchmaker', 'Company', 'Email', 'Phone', 'Actions'
    ],
  };

  // e.g.
  // headers[role] = ['thumbnail', 'gender', 'age']

  const columns = headers || headerTemplate[role];

  const Row = role === "matchmaker" ? MMRow : UserRow;
  return (
    <>
      <div className={`${className || ""} ${role} users-list`}>
        <div className="table-container" ref={containerRef}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', flexDirection: 'row' }}>
            <div className="search-bar-container">
              { role === "matchmaker" &&
                  <SearchInput
                      value={search}
                      onChange={(e) => handleTextChange(e.target.value)}
                      placeholder="Search by name..."
                  />
              }
            </div>
            <div className="member-count">{showing}</div>
          </div>
          <table>
            <thead style={{ position: "relative", zIndex: 2 }}>
              <tr>
                {columns.map((x, i) =>
                  tableSchemas[role][x] ? (
                    <th key={i}>
                      {tableSchemas[role][x].heading || titleCase(x)}
                    </th>
                  ) : (
                    <></>
                  )
                )}
                <th colSpan={2}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers &&
                  filteredUsers.map((user) => (
                  <Row
                    onSelectUser={onSelectUser}
                    onSelectUserCredits={onSelectUserCredits}
                    key={user._id}
                    user={user}
                    attributes={columns}
                    loadUsers={loadUsers}
                  />
                ))}
            </tbody>
          </table>
          {filteredUsers && !filteredUsers.length && (
            <p className="empty-table">No results found for those criteria</p>
          )}
        </div>
      </div>
      <PaginationBar
        total={users.total}
        per_page={users.per_page}
        page={page}
        onChange={(page) => setPage(page)}
      />
    </>
  );
}

function getRegion(user) {
  let closestRegion = {};
  for (let region of user.regionData) {
    if (!closestRegion.distance || region.distance < closestRegion.distance) {
      closestRegion = region;
    }
  }
  let label;
  if (closestRegion.distance || closestRegion.distance === 0) {
    label =
      closestRegion.label || `${closestRegion.city}, ${closestRegion.state}`;
  }
  if (!label)
    label =
      statesIndex[user.regions[0]] ||
      REGIONS.find((x) => x.value === user.regions[0])?.label ||
      label;

  return label;
}

function calculateIndividualPercentages(images, userProgress) {
  let x = 0;
  const progress = {};
  if (images && images.length > 0) {
    progress.photos = 100;
  }
  if (userProgress) {
    Object.keys(userProgress).forEach((item) => {
      if (parseInt(userProgress[item], 10) > 100) {
        progress[item] = 100;
      } else {
        progress[item] = parseInt(userProgress[item], 10);
      }
    });
  }

  Object.values(progress).forEach((val) => {
    x += (val * 100) / 400;
  });
  return x;
}

// may move to another file, map of available columns and how to calculate row value
const tableSchemas = {
  user: {
    thumbnail: {
      heading: "Main Photo",
      data: (user) => <Thumbnail user={user} />,
    },
    user_id: {
      heading: "Member",
      data: (user) => (
        <span>
          {user.user_id}{" "}
          {user.connected && (
            <>
              <br /> {user.firstName} {user.lastName.substring(0, 1)}
            </>
          )}
        </span>
      ),
    },
    gender: {
      heading: "Gender",
      data: (user) => user.gender && user.gender.label.substring(0, 1),
    },
    age: { heading: "Age", data: "age" },
    attractiveness: { heading: "Attractiveness", data: "userRating" },
    location: {
      heading: "City/Region",
      data: (user) => (
        <div>
          <p>{prettyName(user.city)}</p>
          <p>{getRegion(user)}</p>
          <p>{user.country != "United States of America" && prettyName(user.country) }</p>
        </div>
      ),
    },
    status: {
      heading: "Member Type",
      // right now all users are always "available" (from legacy code), should be dynamic?
      data: (user) => (
        <span>
          {user.clientStatus === "current"
            ? "Free Connection"
            : "Paid Connection"}
        </span>
      ),
    },
    completePercent: {
      heading: "Completed Profile Percent",
      data: (user) =>
        calculateIndividualPercentages(user.images, user.userProgress) + "%",
    },
    // connections: {
    //   heading: '# Connections',
    //   data: user => user.connections && user.connections.length
    //     ? user.connections.length
    //     : ''
    // }
  },

  matchmaker: {
    name: {
      heading: "Matchmaker",
      data: (u) => `${u.firstName} ${u.lastName}`,
    },
    company: {
      data: "companyName",
    },
    email: {},
    phone: {},
    // plan_type: {
    //   data: u => {
    //     return <>
    //       {u.limit.plan} {u.planCanceled && <span className='fa fa-ban'/>}
    //     </>
    //   }
    // },
    credits: {
      data: (u) => {
        if (u.referralCredits) {
          const availableCredits = u.referralCredits?.filter(
            (x) => x.spendDate === ""
          );
          return `${availableCredits.length}`;
        }
        return "0";
      },
    },
    // available: {
    //   data: u => {
    //     const { connections, locks, current, max } = u.limit
    //     return <div className='available-container'>
    //       <span><span className='icon icon-contacted' /> {connections} / {max.connected}</span>
    //       <span><span className='fa fa-link' /> {max.connected - current.connected} / {max.connected}</span>
    //       <span><span className='icon icon-lock' /> {u.planCanceled ? 0 : locks} / {max.locked}</span>
    //     </div>
    //   }
    // }
  },
};
