import {
  SET_PERCENTAGE,
  GET_LOGGED_IN_USER,
  CLOSE_TC_MODAL,
  USER_EMAILS_PHOTOS_ADDED,
} from "../constants/actionTypes";

const initialState = {
  loggedUser: null,
  profileCompletion: {
    theBasics: null,
    moreAbout: null,
    partners: null,
    photos: null,
  },
  profileCompletionPercentage: 0,
  isOpenTCModal: false,
};

export default function singlesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LOGGED_IN_USER: {
      if (action.response && action.response.user) {
        const { userProgress, images, acceptedTC } = action.response.user;
        if (userProgress) {
          const progress = calculateIndividualPercentages(
            { ...state.profileCompletion },
            images,
            userProgress
          );
          return {
            ...state,
            user: action.response.user,
            profileCompletion: progress,
            isOpenTCModal: !acceptedTC,
            profileCompletionPercentage: calculateTotalPercentage(progress),
          };
        }
        return {
          ...state,
          user: action.response.user,
          isOpenTCModal: !acceptedTC,
        };
      }
      return state;
    }

    case SET_PERCENTAGE: {
      const { userProgress, images } = action.response.user;
      const progress = calculateIndividualPercentages(
        { ...state.profileCompletion },
        images,
        userProgress
      );

      if (userProgress) {
        return {
          ...state,
          profileCompletion: progress,
          profileCompletionPercentage: calculateTotalPercentage(progress),
        };
      }
      return { ...state };
    }

    case CLOSE_TC_MODAL: {
      return { ...state, isOpenTCModal: false };
    }

    case USER_EMAILS_PHOTOS_ADDED: {
      return { ...state };
    }

    default:
      return { ...state };
  }
}

const calculateTotalPercentage = (data) => {
  let x = 0;
  Object.values(data).forEach((val) => {
    x += (val * 100) / 400;
  });
  return Math.round(x);
};

const calculateIndividualPercentages = (
  stateProgress,
  images,
  userProgress
) => {
  const progress = stateProgress;
  if (images && images.length > 0) {
    progress.photos = 100;
  }
  Object.keys(stateProgress).forEach((item) => {
    if (userProgress[item]) {
      if (parseInt(userProgress[item], 10) > 100) {
        progress[item] = 100;
      } else {
        progress[item] = parseInt(userProgress[item], 10);
      }
    }
  });
  return progress;
};
