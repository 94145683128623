@import "../../../../../../src/scss/variables";

.matchmaker-plans {
  background-color: rgba(84, 88, 90, 0.1);
  height: 100%;
  overflow: auto;

  &__container {
    padding: 80px 0;
  }

  &__text {
    color: $teal;
    font-family: Montserrat, sans-serif;
    font-size: 35px;
    font-weight: 300;
    line-height: 49px;
    max-width: 880px;
    margin: 0 auto;
    padding: 0 100px;
    text-transform: uppercase;
    text-align: center;
  }

  &__plans {
    cursor: pointer;
    height: 570px;
    max-width: 300px;
    margin: 0 25px 50px 25px;

    &__title {
      background-color: $teal;
      color: #fff;
      font-family: "Montserrat", sans-serif;
      font-size: 32px;
      font-weight: bold;
      line-height: 43px;
      padding: 24px 0 5px 0;
    }

    &__description {
      background-color: $teal;
      color: #fff;
      font-family: Montserrat, sans-serif;
      font-size: 18px;
      line-height: 22px;
      padding: 0 0 30px 0;
      text-align: center;
    }

    &__price {
      height: 80px;
      background-color: #f6f6f7;

      span:first-child {
        color: #ffc134;
        font-family: Montserrat, sans-serif;
        font-size: 38px;
        font-weight: 900;
        line-height: 44px;
      }

      span:nth-child(2) {
        color: #54585a;
        font-family: "Montserrat", sans-serif;
        font-size: 18px;
        line-height: 24px;
        padding: 12px 0 0 4px;
      }
    }

    &__fields {
      background: #fff;
      color: #54585a;
      font-family: "Montserrat", sans-serif;
      font-size: 18px;
      min-height: 294px;
      line-height: 24px;
      padding: 32px 24px 24px 24px;

      .connections {
        height: 24px;
      }

      .text {
        padding: 24px 0;
        min-height: 80px;
      }

      .button {
        margin: auto auto 24px auto;
        padding: 0;
        width: 252px;
      }
    }

    &.selected {
      box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.3);
    }

    &.disabled-current-plan {
      pointer-events: none;
    }
  }
}

.confirmation-change-plan-modal .modal {
  min-height: 200px;
  max-width: 400px;
  padding: 30px;

  span {
    color: #53585a;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    margin-bottom: 20px;

    .button {
      margin-left: auto;
    }
  }
}

.subscription-modal .modal {
  min-height: 400px;
  max-width: 500px;
  padding: 30px;

  span:first-child {
    color: #54585a;
    font-family: Montserrat, sans-serif;
    font-size: 18px;
    font-weight: 900;
    text-align: center;
  }

  span:nth-child(2) {
    color: #54585a;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    line-height: 27px;
    margin: 40px 0 30px 0;
    text-align: center;
  }

  .button {
    padding: 0;
    width: 138px;

    .value {
      font-size: 14px;
    }

    &.yellow {
      margin-right: 10px;
    }
  }
}

.matchmaker-referrals {
  width: 100%;
  overflow: auto;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 80px $main-header-padding;
  }

  &__email-template {
    text-align: left;
    font-size: smaller;
  }

  &__text {
    color: #54585a;
    font-family: Montserrat, sans-serif;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    width: 100%;

    & > p, ul {
      text-align: left;
      width: 100%;
    }
  }

  &__link {
    font-weight: 600;
  }

  &__inputs {
    max-width: 1080px;
    margin: 0 auto 20px;
  }

  &__inputs-half {
    width: 50%;

    .blue-label {
      color: $teal;
      font-family: Montserrat, sans-serif;
      font-size: 18px;
      margin-bottom: 4px;
    }

    .input-wrapper {
      max-width: 520px;
      width: 100%;

      .input-text::placeholder {
        color: rgba(84, 88, 90, 0.3);
        font-family: "Montserrat", sans-serif;
        font-size: 16px;
        font-style: italic;
        font-weight: normal;
      }
    }
  }

  &__table {
    max-width: 1500px;
    width: 100%;

    .label {
      border-bottom: 1px solid rgba(84, 88, 90, 0.3);
      color: #54585a;
      font-family: Montserrat, sans-serif;
      font-size: 18px;
      font-weight: 900;
      padding-bottom: 20px;
      margin-bottom: 15px;
      width: 100%;
    }

    .table {
      border: 1px solid rgba(84, 88, 90, 0.1);
      border-radius: 2px;
      background-color: #fff;
      box-shadow: 0 0 10px 0 rgba(84, 88, 90, 0.2);
    }

    .table-headers {
      background-color: $teal;
      height: 36px;
      color: #ffffff;
      font-family: Montserrat, sans-serif;
      font-size: 14px;
      font-weight: 500;
      padding: 0 20px;

      .referral-name,
      .referral-email {
        max-width: 400px;
        width: 100%;
      }
    }

    .table-content {
      background-color: #fff;
      color: #54585a;
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      font-weight: 600;
      padding: 0 20px;

      &__item {
        border-bottom: 1px solid rgba(84, 88, 90, 0.3);
        height: 60px;

        .red-status {
          color: #ff596f;
          text-transform: capitalize;
        }

        .green-status {
          color: $teal;
          text-transform: capitalize;
        }
      }

      &__item:last-child {
        border-bottom: none;
      }

      .table-name,
      .table-email {
        max-width: 400px;
        width: 100%;
        padding-right: 1em;
      }
    }
  }

  .add-referral-button {
    position: relative;

    .tool-tip-component {
      top: 60px;
      right: 32%;
    }
  }
}
