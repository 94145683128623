import React from "react";
import { Redirect, Route } from "react-router-dom";
import { loadPages } from "pages/admin/PageManager";
import CustomPage from "templates/CustomPage";

// OLD DATESPOT ROUTES
import WelcomeOnBoardingSingles from "_old/src/containers/welcome-onboarding";
import SinglesBasics from "_old/src/containers/welcome-onboarding/basic-single-info";
import UserDashboard from "_old/src/containers/dashboards/user";
/* matchmaker containers */
import MatchMakerDashboard from "_old/src/containers/dashboards/matchmaker";
import NewMatchMakerDashboard from "../components/pages/dashboards/matchmaker/MatchmakerDashboard";
import MatchMakerDetails from "_old/src/containers/dashboards/matchmaker/maker-details";
import Referrals from "src/components/matchmakers/referrals_section/Referrals";
import HelpSection from "src/components/pages/dashboards/help/Help";
import Badges from "src/components/pages/dashboards/badges/Badges";
/* admin containers */
import PlanDetail from "_old/src/containers/dashboards/admin/plan-detail";
import UserEmailResponse from "_old/src/components/user-email-response/index";
import UserSession from "_old/src/components/user-session";
import USER_ROLES from "_old/src/constants/user-roles";
import UserLoginContainer from "../containers/UserLoginContainer";
import SignUpContainer from "../containers/SignUpContainer";
import ForgotPassword from "../components/ForgotPassword/ForgotPassword";
import ResetPassword from "../components/ResetPassword/ResetPassword";
import AdminDashboard from "src/components/pages/dashboards/admin/AdminDashboard";

/**
 * This route will render the given component if the user is logged in. Otherwise it will redirect to the log in page.
 * @param {React.ReactNode} Component - The component to render when the user is logged in.
 * @param {Object} rest - Rest props.
 * @return {JSX.Element}
 * @constructor
 */
const ProtectedRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                UserSession.hasValidToken() ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/log-in" />
                )
            }
        />
    );
};

/**
 * This route will render the given component if the user's role is _admin_. Otherwise it will redirect to the log in page.
 * @param {React.ReactNode} Component - The component to render in case the user's role is _admin_.
 * @param {Object} rest - Rest props.
 * @return {JSX.Element}
 * @constructor
 */
const AdminRoute = ({ component: Component, ...rest }) => {
    const user = UserSession.getToken();
    const valid = UserSession.hasValidToken() && user.role === USER_ROLES.ADMIN;
    return (
        <Route
            {...rest}
            render={(props) =>
                valid ? <Component {...props} /> : <Redirect to="/log-in" />
            }
        />
    );
};

/**
 * This route will render the given component if the user's role is _matchmaker_. Otherwise it will redirect to the log in page.
 * @param {React.ReactNode} Component - The component to render in case the user's role is _matchmaker_.
 * @param {Object} rest - Rest props.
 * @return {JSX.Element}
 * @constructor
 */
const MatchmakerRoute = ({ component: Component, ...rest }) => {
    const user = UserSession.getToken();
    const valid =
        UserSession.hasValidToken() &&
        (user.role === USER_ROLES.MATCHMAKER || user.role === USER_ROLES.ADMIN);
    return (
        <Route
            {...rest}
            render={(props) =>
                valid ? <Component {...props} /> : <Redirect to="/log-in" />
            }
        />
    );
};

/**
 * This route will render the corresponding dashboard based on the user's role.
 * Currently the _/dashboard_ route is polymorphic, we might want to change this behaviour in the future.
 * @param {Object} rest - Rest props.
 * @return {JSX.Element}
 * @constructor
 */
const MainDashboardRoute = ({ ...rest }) => {
    let Component = null;
    const user = UserSession.getToken();
    switch (user.role) {
        case USER_ROLES.MATCHMAKER:
            Component = NewMatchMakerDashboard;
            break;
        case USER_ROLES.ADMIN:
            Component = AdminDashboard;
            break;
        case USER_ROLES.USER:
            Component = UserDashboard;
            break;
    }

    return (
        <Route
            {...rest}
            render={(props) => <Component {...props} />
            }
        />
    );
};

/**
 * This route will render the given component if the user is not logged in. Otherwise it will redirect to the main dashboard.
 * @param {React.ReactNode} Component - The component to render in case the user is not logged in.
 * @param {Object} rest - Rest props.
 * @return {JSX.Element}
 * @constructor
 */
const LoggedOutRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                !UserSession.hasValidToken() ? (
                    Component ? (
                        <Component {...props} />
                    ) : (
                        <Route {...rest} />
                    )
                ) : (
                    <Redirect to="/dashboard" />
                )
            }
        />
    );
};

/* ==========================================
   CUSTOM ROUTING
  ========================================== */

// NOTE: not sure if this is the best pattern (hook/register approach) but it's
// clean-ish and easy for now. If we keep it we should document it.

/*
  launchpad variable: customRoutes
  categories: routing
  A variable containing any custom or ambiguous routes. When
  adding new routes, you can just add to this array. Routes will be rendered
  inside a `react-router` `<Switch>`, all the standard `react-router` props
  apply. these routes will be automatically combined with CMS routes. If
  creating a single route which doesn't need ambiguous matching, consider just
  creating a file in the `components/pages` directory (see CMS section), which
  will automatically create a page, add it to the CMS's page index, and
  automatically implement code splitting.
*/

// manually add static routes
// NOTE: these routes will override automatic (page index) routes, use with caution
// NOTE: make sure that all the route components have an unique key (use path) so react doesn't complain
export const customRoutes = [
    <LoggedOutRoute
        key="/log-in"
        exact
        path="/log-in"
        component={UserLoginContainer}
    />,
    <LoggedOutRoute
        key="/welcome-singles"
        exact
        path="/welcome-singles"
        render={(props) => (
            <SignUpContainer userRole={USER_ROLES.USER} {...props} />
        )}
    />,
    <LoggedOutRoute
        key="/forgot-password"
        exact
        path="/forgot-password"
        component={ForgotPassword}
    />,
    <Route
        key="/welcome-partners"
        exact
        path="/welcome-partners"
        render={(props) => (
            <SignUpContainer userRole={USER_ROLES.MATCHMAKER} {...props} />
        )}
    />,

    <LoggedOutRoute
        key="/reset-password"
        path="/reset-password"
        component={ResetPassword}
    />,

    // Logged user routes
    <ProtectedRoute
        key="/"
        path="/"
        component={({ match: { url } }) => [
            /* singles routes */
            <MainDashboardRoute key={`${url}dashboard`} path={`${url}dashboard`} />,
            <Route
                key={`${url}welcome`}
                exact
                path={`${url}welcome`}
                component={WelcomeOnBoardingSingles}
            />,
            <Route
                key={`${url}the-basics`}
                exact
                path={`${url}the-basics`}
                component={SinglesBasics}
            />,
            <Route
                key={`${url}more-about`}
                exact
                path={`${url}more-about`}
                component={SinglesBasics}
            />,
            <Route
                key={`${url}your-partners`}
                exact
                path={`${url}your-partners`}
                component={SinglesBasics}
            />,
            <Route
                key={`${url}photos`}
                exact
                path={`${url}photos`}
                component={SinglesBasics}
            />,
            <Route
                key={`${url}account`}
                exact
                path={`${url}account`}
                component={SinglesBasics}
            />,

            /* matchmakers routes */
            <MatchmakerRoute
                key={`${url}my-favorites`}
                exact
                path={`${url}my-favorites`}
                component={MatchMakerDashboard}
            />,
            <MatchmakerRoute
                key={`${url}my-contacted`}
                exact
                path={`${url}my-contacted`}
                component={MatchMakerDashboard}
            />,
            <MatchmakerRoute
                key={`${url}my-locks`}
                exact
                path={`${url}my-locks`}
                component={MatchMakerDashboard}
            />,
            <MatchmakerRoute
                key={`${url}matchmaker-plans`}
                exact
                path={`${url}matchmaker-plans`}
                component={MatchMakerDashboard}
            />,
            <MatchmakerRoute
                key={`${url}matchmaker-referrals`}
                exact
                path={`${url}matchmaker-referrals`}
                component={Referrals}
            />,
            <MatchmakerRoute
                key={`${url}help`}
                exact
                path={`${url}help`}
                component={HelpSection}
            />,
            <MatchmakerRoute
                key={`${url}badges`}
                exact
                path={`${url}badges`}
                component={Badges}
            />,

            /* admins routes */
            <AdminRoute
                key={`${url}locks-section`}
                exact
                path={`${url}locks-section`}
                component={AdminDashboard}
            />,
            <AdminRoute
                key={`${url}completed-section`}
                exact
                path={`${url}completed-section`}
                component={AdminDashboard}
            />,
            <AdminRoute
                key={`${url}matchmakers-list`}
                exact
                path={`${url}matchmakers-list`}
                component={AdminDashboard}
            />,
            <AdminRoute
                key={`${url}maker-details`}
                exact
                path={`${url}maker-details`}
                component={MatchMakerDetails}
            />,
            <AdminRoute
                key={`${url}user-response/:id`}
                exact
                path={`${url}user-response/:id`}
                component={UserEmailResponse}
            />,
            <AdminRoute
                key={`${url}plans`}
                exact
                path={`${url}plans`}
                component={AdminDashboard}
            />,
            <AdminRoute
                key={`${url}metrics`}
                exact
                path={`${url}metrics`}
                component={AdminDashboard}
            />,
            <AdminRoute
                key={`${url}plan-details`}
                exact
                path={`${url}plan-details`}
                component={PlanDetail}
            />,
            // Fallback when logged-in
            <Redirect to={`${url}dashboard`} />,
        ]}
    />,
];

/*
  launchpad variable: refreshFunctions
  categories: routing, CMS
  This function is used to register an async function that should be called when calculating
  which routes are available to the CMS. Typically it will involve making an API
  call, and then generating a list of routes from the data that's returned.
  This approach is much less performant than statically adding routes, since these
  async functions must complete before the first render. However, when adding
  routes that depend upon dynamic data, such as blog posts or admin configuration,
  calls to this function allow dynamic routes to be added and integrated with
  other CMS functionality (e.g. editable menus)
*/

// add dynamic routes based on database or other asynchronously loaded data
export const refreshFunctions = [
    // NOTE: DO NOT REMOVE THIS, it loads the custom pages, left here as an example
    async () => {
        const pages = await loadPages(true);
        let custom_routes = {};
        for (let p of pages) {
            if (!p.url.startsWith("/")) p.url = "/" + p.url;
            custom_routes[p.url] = {
                type: "custom",
                title: p.title,
                component: () => <CustomPage page={p} />,
            };
        }
        return custom_routes;
    },
];
