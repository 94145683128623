.ds-loading {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300px;
    height: 100px;
    transform: translate(-50%, -50%);
    z-index: 3;
    border: 1px solid rgba(84, 88, 90, 0.1);
    box-shadow: 0 0 10px 0 rgba(84, 88, 90, 0.2);
    border-radius: 2px;
    background: white;

    .ds-loader {
        margin-top: 18px;
    }

    &__error {
        color: #ff596f;
        font-size: 18px;
        font-weight: bold;
        padding: 20px;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        box-sizing: border-box;
    }
}
