@import "../../../../src/scss/variables";

.button {
  background-color: #3ec6c0;
  box-shadow: 0 0 10px 0 rgba(84, 88, 90, 0.2);
  cursor: pointer;
  height: 50px;
  outline: none;
  padding: 0 40px;
  text-decoration: none;
  transition: all 0.5s;
  user-select: none;
  -webkit-appearance: none;
  white-space: nowrap;
  width: max-content;
  border: none;

  .value {
    color: #fff;
    font-size: 14px;
    font-weight: 800;
    font-family: Montserrat, sans-serif;
    letter-spacing: 0.93px;
    text-transform: uppercase;
  }

  &:hover {
    background-color: #00a19b;
  }

  &.large {
    height: 70px;
    min-width: 306px;
    .value {
      font-size: 20px;
      letter-spacing: 1.33px;
    }
  }

  &.yellow {
    background-color: #ffd758;
    .value {
      color: #54585a;
    }
    &:hover {
      background-color: #ffbd0c;
    }

    &:disabled {
      opacity: 0.5 !important;
      background-color: $greyLightest !important;
    }
  }

  &.disabled-plan {
    background-color: rgba(84, 88, 90, 0.1);
    box-shadow: none;
    .value {
      opacity: 0.6;
      color: #cc4758;
    }
  }

  &.redish {
    background-color: #ff596f;
  }

  &.two-twenty-width {
    height: 44px;
    min-height: 44px;
    width: 220px;
  }

  &.extra-padding {
    padding: 0 75px;
  }

  &:focus,
  &:active,
  &:visited,
  &:hover,
  &.input-button--active {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    outline: none;
  }

  &[disabled] {
    opacity: 0.5 !important;
    background-color: $greyLightest !important;
    cursor: default;
    pointer-events: none;
  }
}
