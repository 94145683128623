import React from "react";

export const repeatingSectionTwo = (
  title,
  text,
  text2,
  text3,
  specialTitle,
  specialPadding
) => {
  const textClass = text2 ? "text margin-30-bottom" : "text";
  const titleClass = specialTitle
    ? "section-two__title special"
    : "section-two__title flex-row flex-center";
  return (
    <div
      className={`section-two__content flex-column ${
        specialPadding ? " special-padding" : ""
      }`}
    >
      <div className={titleClass}>
        {!specialTitle && <span className="blue-line" />}
        <span className="title-text">{title}</span>
        {!specialTitle && <span className="blue-line" />}
      </div>
      <div className={textClass}>{text}</div>
      {text2 && (
        <div className={`${text3 ? "text margin-30-bottom" : "text"}`}>
          {text2}
        </div>
      )}

      {text3 && <div className="text">{text3}</div>}
    </div>
  );
};

export const repeatingMatchmakerThree = (
  title,
  text,
  svg,
  extra,
  marginRight,
  key
) => {
  return (
    <div
      className={`item flex-column flex-center ${
        marginRight ? "margin-right" : "margin-left"
      }`}
      key={key}
    >
      <img className="item__svg" src={svg} />
      <span className="item__subtitle">{title}</span>
      {/* {extra && <div className='flex-row extra'>*/}
      {/* <span className='circle'/>*/}
      {/* <span>Total Members</span>*/}
      {/* <span className='circle blue'/>*/}
      {/* <span>Verified Singles</span>*/}
      {/* </div>}*/}
      <span className="text">{text}</span>
    </div>
  );
};
