import React from "react";
import PropTypes from "prop-types";
import UserSession from "../user-session";
import "./style.scss";

// modifications by codepilot
import { apiPost } from "launchpad";

class Profile extends React.Component {
  state = {
    show: false,
  };

  render() {
    const user = UserSession.getToken();
    const { history, showImage } = this.props;
    const { show } = this.state;
    return (
      <div className="profile-component flex-column">
        <div
          className="profile-component__profile flex-center"
          onClick={() => this.setState({ show: !show })}
        >
          {showImage && (
            <span className="logged-user-image">
              {user.images && user.images[0] ? (
                <img src={user.images[0].url} alt="" />
              ) : null}
            </span>
          )}

          <span className="logged-user-name">
            {user.firstName} {user.lastName}
          </span>
        </div>
        {show && (
          <div className="profile-component__menu flex-column">
            <span
              className="flex-center"
              onClick={() => {
                this.setState({ show: !show });
                this.logout();
              }}
            >
              Sign Out
            </span>
          </div>
        )}
      </div>
    );
  }

  // modified by CodePilot
  logout = () => {
    this.props.returnLogoutTime && this.props.returnLogoutTime();
    setTimeout(async () => {
      await apiPost("/user/logout");
      UserSession.logout();
    }, 1000);
  };
}

Profile.propTypes = {
  history: PropTypes.object,
  generalActions: PropTypes.object,
  generalReducer: PropTypes.object,
  showImage: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isMatchMaker: PropTypes.bool,
  returnLogoutTime: PropTypes.func,
};
export default Profile;
