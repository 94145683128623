import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

class LoadingModal extends React.Component {
  render() {
    const { loading, error } = this.props;
    let content = "";

    if (loading) {
      content = <div className="ds-loader"></div>;
    } else if (error) {
      content = <div className="ds-loading__error">{error}</div>;
    }

    return <div className="ds-loading">{content}</div>;
  }
}

LoadingModal.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
};

export default LoadingModal;
