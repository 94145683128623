// Deps
import React from "react";
// Layouts && Sections
import MainContent from "../components/layouts/MainContent";
import { HeroSection } from "../components/sections";
// Components
import UserLogin from "user/UserLogin";

const UserLoginContainer = (props) => (
  <MainContent innerContainer={"large"} borderBottom>
    <HeroSection>
      <UserLogin {...props} />
    </HeroSection>
  </MainContent>
);

export default UserLoginContainer;
