import React, { Component } from "react";
import PropTypes from "prop-types";
import "./style.scss";

class RadioList extends Component {
  state = {
    selected: this.props.selectedValue || null,
  };

  render() {
    const { list, title, stateCallback, startLabel, endLabel, required } =
      this.props;
    const { selected } = this.state;

    return (
      <div className="radio-list-horizontal flex-column">
        {title && (
          <span
            className={`${
              selected
                ? "radio-list-horizontal__label green"
                : "radio-list-horizontal__label"
            }`}
          >
            {" "}
            {title}
            {required ? <span className="text-alert-red">*</span> : ""}{" "}
          </span>
        )}
        <div className="radio-list-horizontal__wrapper flex-row">
          <span className="start-label">{startLabel}</span>
          <div className="radio-list-horizontal__buttons flex-row flex-center">
            {list.map((li, l) => {
              const isSelected =
                selected &&
                parseInt(selected.value, 10) === parseInt(li.value, 10);
              return (
                <div
                  key={l}
                  className={`radio-list-horizontal__button flex-center ${
                    isSelected ? "selected" : ""
                  }`}
                  onClick={() => {
                    this.setState({ selected: li });
                    stateCallback(li);
                  }}
                >
                  {li.value}
                </div>
              );
            })}
          </div>
          <span className="end-label">{endLabel}</span>
        </div>
      </div>
    );
  }
}

RadioList.propTypes = {
  list: PropTypes.array,
  title: PropTypes.string,
  startLabel: PropTypes.string,
  endLabel: PropTypes.string,
  stateValue: PropTypes.string,
  stateCallback: PropTypes.func,
  selectedValue: PropTypes.any,
};

export default RadioList;
