/* eslint-disable object-curly-newline */
import React from "react";
import PropTypes from "prop-types";
import {
  SETTINGS_TEXT,
  CLIENT_STATUS,
  RELATIONSHIP_STATUSES,
  MARRIAGE_STATUSES,
  SINGLES_ROUTES,
} from "_old/src/constants/onboarding-data-elements";
import { PREMIUM_SERVICE_TERMS } from "_old/src/constants";
import Modal from "_old/src/components/modal";
import Button from "_old/src/components/button/index";
import InputText from "_old/src/components/input-text";
import CheckBox from "_old/src/components/check-box";
import Validator from "_old/src/components/utils/validator";
import "./style.scss";
import BulletCheckList from "src/components/BulletCheckList";
import RadioList from "_old/src/components/radio-list";
import { insertAt } from "src/utils/functions";
import { AccountStatus } from "src/components/AccountStatus/AccountStatus";
import { SINGLE_ROUTES_MORE_INFO } from "src/data/constants";

class SettingsSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      premium_modal_accept: false,
      clientStatus: props.user.clientStatus || null,
      agreed_terms_modal: props.user.clientStatus === false,
      premium_agreed_terms:
        props.user.clientStatus === "current" ? true : false,
      willingToPay: props.user.willingToPay || [],
      emailSubscription: props.user.emailSubscription || "active",
      phone: props.user.phone ? parseInt(props.user.phone) : null,
      possibleRoutes: props.user.possibleRoutes || [],
      errors: {},
      validFields: {
        phoneValid: !!props.user.phone,
      },
      firstSignup: props.user.firstSignup || false,
    };
  }

  agree_terms = () => {
    this.props.saveUserFieldData(
      this.props.user._id,
      "clientStatus",
      this.state.clientStatus
    );
    this.props.saveUserFieldData(
      this.props.user._id,
      "possibleRoutes",
      this.state.possibleRoutes
    );
    this.setState({
      premium_agreed_terms: true,
      premium_modal_accept: false,
      agreed_terms_modal: true,
    });
  };

  changeData = (field, value) => {
    this.setState({
      [field]: value,
    });

    this.props.saveUserFieldData(this.props.user._id, field, value);
  };

  handleRelationshipStatusChange = (status) => {
    let newStatus = {
      ...status,
      date: new Date(),
    };

    this.props.setOnboardingSectionsDisabledState(
      newStatus.value === "Not Single"
    );

    this.changeData("relationshipStatus", newStatus);
  };

  handleMarriageStatusChange = (status) => {
    this.changeData("marriageStatus", status);

    if (status.value === "Engaged/married") {
      this.handleRelationshipStatusChange({
        value: "Not Single",
        label: "Not Single",
      });
      this.changeData("accountStatus", "inactive");
    } else if (this.state.accountStatus === "inactive") {
      this.changeData("accountStatus", "active");
    }
  };

  getMarriageStatuses = () => {
    const { createdAt } = this.props.user;
    // This way we ensure that the Engaged/married option only gets added if a user logs back in after some time
    let createdAtOneDayAfter = new Date(createdAt);
    createdAtOneDayAfter.setDate(createdAtOneDayAfter.getDate() + 1);

    if (createdAtOneDayAfter.getTime() < new Date().getTime()) {
      return insertAt(MARRIAGE_STATUSES, -1, {
        value: "Engaged/married",
        label: "Engaged/married",
      });
    }
    return MARRIAGE_STATUSES;
  };

  render() {
    const { saveUserFieldData, isTabletDown } = this.props;
    const {
      clientStatus,
      possibleRoutes,
      agreed_terms_modal,
      premium_modal_accept,
      emailSubscription,
      phone,
      errors,
      validFields,
      firstSignup,
    } = this.state;

    const { marriageStatus, relationshipStatus, accountStatus, _id } =
      this.props.user;
    const isEmailSubscriptionActive = emailSubscription === "active";
    const isAccountStatusActive = accountStatus === "active";
    const renderInterestedIn = this._renderInterestedIn(
      clientStatus,
      possibleRoutes,
      isTabletDown
    );
    const renderAgreeTerms = this._renderAgreeTerms();
    const renderPhone = this._renderPhone(phone, errors, validFields);

    return (
      <div className="settings-section">
        {isTabletDown ? (
          <div className="settings-section__title-container">
            <h1>Account Settings</h1>
          </div>
        ) : null}
        {premium_modal_accept && (
          <Modal
            isOpen={premium_modal_accept}
            closeOnClickOutside={true}
            modalContent={
              <div className="premium-modal">
                <span
                  onClick={() =>
                    this.setState({
                      premium_modal_accept: false,
                    })
                  }
                  className="icon-close-button"
                />
                <h1>Client Service Terms</h1>
                <span style={{ fontSize: "1.2em", margin: "20px 0" }}>
                  To become a Client, please read and accept the{" "}
                  <a
                    href="https://docs.google.com/document/d/1vtxjuqvIFXCHyl41d3L_atIu9Ql20QpebDqcKd_DriQ/edit"
                    target="_blank"
                    style={{ color: "#3FC6C0", fontWeight: 600 }}
                  >
                    Terms of Service
                  </a>
                  .
                </span>
                {renderAgreeTerms}
              </div>
            }
          />
        )}
        {agreed_terms_modal && (
          <Modal
            isOpen={agreed_terms_modal}
            closeOnClickOutside={true}
            modalContent={
              <div className="premium-modal">
                <span
                  onClick={() =>
                    this.setState({
                      agreed_terms_modal: false,
                    })
                  }
                  className="icon-close-button"
                ></span>
                <h1>Client Next Steps</h1>
                <div className="premium-modal-terms">
                  <p>We're happy that you're now a DateSpot Client!</p>
                  <p>
                    We will email you now to send the Client Handbook with next
                    steps.
                  </p>
                  <p>
                    If you have not already done a deep-dive video call so we
                    can personally get to know you and advise on your photos,
                    feel free to book one for $195{" "}
                    <a
                      href="https://calendar.app.google/Ha937HuX5tN6MQ5m7"
                      target="_blank"
                      style={{ color: "#3FC6C0", fontWeight: 600 }}
                    >
                      here
                    </a>
                    .
                  </p>
                  <p>
                    We look forward to working with you and hope to be matching
                    you up!
                  </p>
                </div>
              </div>
            }
          />
        )}
        {!firstSignup && (
          <div className="flex-column">
            <div className="photo-section__message">
              Has your relationship status changed? Please update it today!
            </div>
            <BulletCheckList items={SETTINGS_TEXT} />
          </div>
        )}
        {/* TODO */}
        {/* {relationshipStatus?.value === "Not Single" && (
          <span className="error-message">
            According to our records, you are engaged or married. That means
            your account is inactive and we are not showing your account to our
            matchmaker network. If your relationship status has changed, please
            update it here. You'll then be able to access your account and make
            any other updates.
          </span>
        )} */}

        {!firstSignup && (
          <RadioList
            required
            list={RELATIONSHIP_STATUSES}
            title="Relationship status"
            selectedValue={relationshipStatus}
            stateCallback={this.handleRelationshipStatusChange}
            returnInputValue={this.handleRelationshipStatusChange}
            radioWrapperClassName="full-width"
            disabled={marriageStatus.value === "Engaged/married"}
          />
        )}

        <RadioList
          required
          list={this.getMarriageStatuses()}
          title="Marriage status"
          selectedValue={marriageStatus}
          stateCallback={this.handleMarriageStatusChange}
          returnInputValue={(val) => this.changeData("marriageStatus", val)}
          radioWrapperClassName="full-width"
        />
        <AccountStatus
          changeStatus={() => {
            this.setState({
              accountStatus: isAccountStatusActive ? "inactive" : "active",
            });
            saveUserFieldData(
              _id,
              "accountStatus",
              isAccountStatusActive ? "inactive" : "active"
            );
          }}
          isAccountStatusActive={isAccountStatusActive}
        />
        {renderInterestedIn}
        {renderPhone}
        <div className="input-wrapper flex-column">
          <span className="subscription-label">Email subscription</span>
          <div className="email-subscription flex-row">
            <span className="active-status">
              {isEmailSubscriptionActive ? "Active" : "Inactive"}
            </span>
            <span
              className="change-status float-right"
              onClick={() => {
                this.setState({
                  emailSubscription: isEmailSubscriptionActive
                    ? "inactive"
                    : "active",
                });
                saveUserFieldData(
                  _id,
                  "emailSubscription",
                  isEmailSubscriptionActive ? "inactive" : "active"
                );
              }}
            >
              {isEmailSubscriptionActive ? "Unsubscribe" : "Subscribe"}
            </span>
          </div>
        </div>
      </div>
    );
  }

  _renderInterestedIn = (clientStatus, possibleRoutes, isTabletDown) => {
    const labels = {
      Client: "Client",
      Candidate: "Candidate",
    };
    const icon = {
      Client: SINGLE_ROUTES_MORE_INFO.CLIENT,
      Candidate: SINGLE_ROUTES_MORE_INFO.CANDIDATE,
    };
    const inputs = SINGLES_ROUTES.map(({ value, label }) => {
      return {
        value,
        label: labels[label],
        tooltipElement: (
          <ul style={{ paddingLeft: "20px" }}>
            {icon[label].map((text) => (
              <li style={{ margin: "10px 0" }}>{text}</li>
            ))}
          </ul>
        ),
        tooltipClassName: isTabletDown ? "tooltip__popover-left" : "",
      };
    });

    return (
      <RadioList
        required
        list={inputs}
        title="Member Type"
        selectedValue={{ value: clientStatus }}
        stateCallback={(option) =>
          this.handleClientStatusChange(option, possibleRoutes)
        }
      />
    );
  };

  handleClientStatusChange = (option, possibleRoutes) => {
    const { _id } = this.props.user;
    const { clientStatus } = this.state;
    const { value } = option;
    if (clientStatus === value) return;
    if (value === "candidate") {
      possibleRoutes = [
        { value: "candidate", label: "Candidate" },
        {
          value: "client",
          label: "Client",
          status: "Previously yes",
        },
      ];
      this.props.saveUserFieldData(_id, "clientStatus", "candidate");
      this.props.saveUserFieldData(_id, "possibleRoutes", possibleRoutes);
      this.setState({
        possibleRoutes: possibleRoutes,
        clientStatus: "candidate",
      });
    } else if (value === "client") {
      possibleRoutes = [
        {
          value: "client",
          label: "Client",
          status: "Yes",
        },
      ];
      this.setState({
        premium_modal_accept: true,
        possibleRoutes: possibleRoutes,
        clientStatus: "client",
      });
    }
  };

  _renderAgreeTerms = () => {
    return (
      <div className="agree-terms flex-row">
        <Button
          value="Accept Terms"
          customClass="accept-terms-button yellow"
          click={() => this.agree_terms()}
        />
      </div>
    );
  };
  _renderPhone = (phone, errors, validFields) => {
    return (
      <InputText
        wrapperClassName="flex-column phone-number"
        placeholder="Phone number"
        label="Phone number"
        showLabel={true}
        classNameForLabel="subscription-label"
        error={errors.phoneError}
        isValid={phone && validFields.phoneValid}
        onBlur={(e) => this.saveUserFieldData("phone", phone)}
        onChange={(e) =>
          this.setState({
            phone: e.target.value,
            errors: { ...errors, phoneError: false },
            validFields: { ...validFields, validPhone: false },
          })
        }
        value={phone}
      />
    );
  };

  saveUserFieldData = (field, value) => {
    const { validFields, errors } = this.state;
    const valid = Validator.validatePhoneNumber(value);
    if (field === "phone" && valid) {
      this.setState({
        validFields: {
          ...validFields,
          phoneValid: true,
        },
      });
      const { _id: userId } = this.props.user;
      this.props.saveUserFieldData(userId, field, value);
    } else {
      this.setState({ errors: { ...errors, phoneError: "invalid" } });
    }
  };
}

SettingsSection.propTypes = {
  user: PropTypes.object,
  saveUserFieldData: PropTypes.func,
  goToRelationshipStatus: PropTypes.func,
};

export default SettingsSection;
