@import "src/scss/variables";
@import "src/scss/utilities";

.member-header {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
  .button {
    text-transform: uppercase;
    font-size: 0.7em;
    padding: 10px 20px;
    font-weight: 800;
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
  .button.blue {
    color: white;
  }
  .inactive {
    background: #999;
    color: white;
  }
  .actions-menu {
    display: none;
  }
  .actions-menu,
  .admin-actions-menu {
    position: relative;
    font-size: 15px;
    .menu-dropdown {
      top: 150% !important;
      right: -30%;
      .requested {
        background-color: $tcolor3;
        color: gray;
      }
    }
  }
  &__left-container {
    width: 50%;
    color: $textPrimary;
    font-weight: 600;
  }
}

.menu-dropdown {
  width: max-content;
  height: fit-content;
}

.user-profile-action {
  text-align: center;
  padding: 10px;
  cursor: pointer;
  &:hover,
  &:focus-within {
    background-color: $lightYellow;
  }
}

.suspicion {
  color: $teal;
  display: flex;
  align-items: center;
  flex-direction: row;
  font-weight: normal;
  margin-top: 10px;
}

.range-tooltip {
  position: absolute;
  background: white;
  box-shadow: 1px 1px 15px #a7a7a7;
  border-radius: 30px;
  left: 0;
  bottom: -60px;
}

@include for-mobile {
  .member-header {
    .button {
      display: none;
    }
    .actions-menu {
      display: block !important;
    }
    .delete-photo-button {
      display: block !important;
      font-size: 8px;
      padding: 5px 10px;
    }
  }

  .range-tooltip {
    right: 0;
    left: unset;
  }
}
