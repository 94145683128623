import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as authActions from "../../actions/authentication";
import * as generalActions from "../../actions/generalActions";
import LoginForm from "../../components/login-form";
import UserSession from "../../components/user-session";
import logo from "../../components/main-header/assets/date-spot-logo.svg";
import LoginWithFb from "../facebook/index";

// modified by codepilot
import { apiGet, setGlobal, getGlobal } from "launchpad";

class LoginPage extends Component {
  constructor(props) {
    super(props);
  }

  checkLogin = () => {
    const user = getGlobal("activeUser");
    if (!user) {
      if (UserSession.hasValidToken()) {
        apiGet("check-user-status").then((user) => {
          setGlobal({ activeUser: user });
          const token = UserSession.getToken();
          if (token.ambassadorAccount === true) {
            this.props.history.push("/matchmaker-referrals");
          } else if (token.role === "matchmaker") {
            this.props.history.push("/matchmaker/dashboard");
          } else {
            this.props.history.push("/");
          }
        });
      }
    } else {
      setGlobal({ activeUser: user });
      if (user?.ambassadorAccount) {
        this.props.history.push("/matchmaker-referrals");
      } else if (user.role === "matchmaker") {
        this.props.history.push("/matchmaker/dashboard");
      } else {
        this.props.history.push("/");
      }
    }
  };

  componentDidMount() {
    this.checkLogin();
  }
  componentDidUpdate() {
    this.checkLogin();
  }

  loginCallback = (mess, response) => {
    const { error, user, token } = response.data;
    if (!error) {
      UserSession.setToken(token, user, null);
      // const user = UserSession.getToken()
      if (user.role === "matchmaker") {
        apiGet("check-user-status").then((user) => {
          setGlobal({ activeUser: user });
          return this.props.history.push({ pathname: "/matchmaker/dashboard" });
        });
      }
      return this.props.history.push({ pathname: "/" });
    }
  };

  fbValidate = () => {
    let isValid = true;
    return isValid;
  };

  render() {
    const { history } = this.props;
    const { loginUser, removeError } = this.props.authActions;
    const { errors } = this.props.authenticationReducer;

    const params = getUrlVars();

    return (
      <div className="login-container flex-row">
        <div className="left-section flex-column">
          <div className="logo-and-arrow flex-row">
            <span
              className="icon-back-button"
              onClick={() => {
                this.props.generalActions.setSelectedHeaderTab(
                  history.location.selectedTab
                );
                history.goBack();
              }}
            />
            <div className="date-spot-logo large flex-center">
              <img src={logo} />
            </div>
          </div>

          {params && params.requestID && params.interested && (
            <div className="flex-row connection-response">
              <div className="flex-column">
                <h2>Thank you for your response</h2>
                {params.interested.toLowerCase() === "yes" && (
                  <p>
                    We are letting the matchmaker know. They should be in
                    contact soon!
                  </p>
                )}
                {params.interested.toLowerCase() !== "yes" && (
                  <p>
                    Thank you for your response, we will not make this
                    connection. Should you wish to not be contacted by us in the
                    future, please login to update your relationship status.
                  </p>
                )}
              </div>
            </div>
          )}

          <LoginWithFb
            formValid={() => this.fbValidate()}
            loginFbCallback={(mess, response) =>
              this.loginCallback(mess, response)
            }
          />

          <div className="separator">or sign in with your email</div>
          <LoginForm
            loginUser={loginUser}
            removeError={removeError}
            errors={errors}
            history={history}
            loginCallback={(mess, response) =>
              this.loginCallback(mess, response)
            }
          />
        </div>
        <div className="right-section flex-column">
          <div className="login-background flex-column">
            <div className="couple-info flex-column flex-center">
              <span>Dani and Kevin</span>
              <span>Seattle</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  authActions: PropTypes.object,
  generalActions: PropTypes.object,
  authenticationReducer: PropTypes.object,
};

const getUrlVars = () => {
  var vars = {};
  var parts = window.location.href.replace(
    /[?&]+([^=&]+)=([^&]*)/gi,
    function (m, key, value) {
      vars[key] = value;
    }
  );
  return vars;
};

const mapStateToProps = (state) => {
  return {
    generalReducer: state.generalReducer,
    authenticationReducer: state.authenticationReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
