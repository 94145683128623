import { useCallback, useEffect, useRef } from "react";

/**
 * Given a callback function, returns a debounced version of the function with a specified delay time.
 * @param {Function} callback - callback function
 * @param {number} delay - delay time in ms
 * @param {boolean} immediately - trigger callback immediately on first call
 * @returns {Function}
 */
export const useDebounce = (
  callback = () => {},
  delay = 300,
  immediately = false
) => {
  const timeoutRef = useRef(null);
  const firstTriggerRef = useRef(true);

  useEffect(() => {
    if (immediately) {
      debounceCallback();
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const debounceCallback = useCallback(
    (...args) => {
      if (firstTriggerRef.current) {
        firstTriggerRef.current = false;
        callback.apply(null, args);
        return;
      }

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        callback.apply(null, args);
      }, delay);
    },
    [callback, delay]
  );

  debounceCallback.cancel = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  return debounceCallback;
};
