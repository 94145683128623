@import "../scss/variables.scss";
@import "../scss/utilities.scss";

.bullet-check-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 0;
  padding: 0;
  list-style: none;

  &__item {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  &__text {
    color: $textPrimary;
    font-size: 1rem;

    @include for-tablet-down {
      font-size: 0.875rem;
    }
  }

  &__icon-container {
    background-color: $teal;
    aspect-ratio: 1;
    border-radius: 50%;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    i {
      color: white;
      font-size: 12px;
    }
  }
}
