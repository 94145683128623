export const TERMS_OF_SERVICE = {
  introduction: [
    {
      text:
        "Welcome to DateSpot! DateSpot begins each new relationship with a contract. " +
        "These Terms of Service spell out what you can expect from us with regard to Services we deliver through " +
        "our website, www.datespot.love (the Site), and what we expect from you. We do not know each user of our " +
        "Services personally, so it is important to set out the ground rules clearly in advance.",
    },
    {
      text:
        "The Site is owned and operated by DateSpot Inc, a Delaware corporation" +
        "(DateSpot, we, us, or our.) " +
        " In these Terms of Service, we refer to you, any user of this Site, as you or the User, and, when and as applicable," +
        " a Member or Partner Matchmaker.  We refer to the DateSpot services, " +
        "which includes your use of this Site, as the Service or Services.",
    },
    {
      text:
        "To become one of our Members, you must affirmatively click and acknowledge that you have read and " +
        "accept these Terms of Service. " +
        "If you are request to be one of our Partner Matchmakers, you will be directed to and required " +
        "to accept our Partner Matchmaker Subscription Agreement, which, in addition to other provisions, " +
        "incorporates these Terms of Service by reference.",
    },
    {
      text:
        "If you have questions about any part of these Terms of Service, " +
        "please contact us at support@datespot.love. We will do our best to explain why these contract provisions are " +
        "important for our Services. Please remember that, until there is an agreement in place, you may access" +
        " only the public portion of this Site, and will not receive any benefits as a Member or as a Partner Matchmaker.",
    },
  ],
  sectionOne: {
    title: "II. Relationship Between DateSpot And User: Providing Only a Venue",
    text:
      "DateSpot is an Internet forum that facilitates communication between certain persons or " +
      "companies actively engaged in in the business of matchmaking, finding possible " +
      "date matches for their paying clientele, and single adults interested in creating " +
      "a DateSpot Profile and passively being involved in matchmaking. A Matchmaker may request " +
      "contact with a User in these cases:",
    list: [
      "• Matchmaker identifies the User to be a potential match for one or more of their current or former clients.",
      "• Matchmaker thinks the User would be a helpful addition to their potential match network in the future," +
        " and wants to have them in their own database.",
      "• The User has expressed interest in talking with a Matchmaker " +
        "about becoming a client by checking the appropriate box on the contact form or otherwise expressing " +
        "interest in written communications. There is no obligation for a User to become a client. " +
        "Most of our Partner Matchmakers offer free consultations, but the User should discuss " +
        "with their chosen Matchmaker whether there are any fees associated with the initial consultation. ",
    ],
    text1:
      "In the case that a Matchmaker identifies the User to be a potential match for one or more " +
      "current or former clients, the Matchmaker is expected to share information and at least " +
      "one photo about at least one of these clients before a date to ask the User" +
      " if they want to meet this particular client. If this does not occur, " +
      "a User should contact DateSpot at support@datespot.love so we can make sure " +
      "the Matchmaker understands our policies and complies in the future, or suspension from the Site may occur.",
    text2:
      "DateSpot is not intended for persons under the age of 18," +
      " married persons, or those already in a committed relationship, or for persons " +
      "otherwise uninterested in the possibility of engaging in a meaningful personal relationship. " +
      "A User may join DateSpot’s database, but will likely not receive any potential matches " +
      "if they are in a relationship, with the exception being if they are in an open or " +
      "polyamorous relationship and a Matchmaker is looking for an open/polyamorous client. " +
      "Accordingly, if you are married, under the age of 18, or not presently open to the " +
      "idea of the possibility of a meaningful relationship, please do not register as a Member on our Site.",
    text3:
      "By registering with our Site as a Member, you are representing and affirming to " +
      "us that you are 18 years of age or older, neither married nor in an " +
      "exclusive relationship, but open to the idea of considering a meaningful" +
      " relationship were you to meet the right person. If all of these things are true, " +
      "by all means please register.",
    text4:
      "DateSpot does not vet or vouch for any of the Partner Matchmakers." +
      " Providing a service where subscribing matchmakers can screen, and if the Member authorizes " +
      "releasing his or her email address, contact potential matches for their clients, " +
      "does not imply an endorsement by us of any subscribing Partner Matchmaker, or of any of the Partner " +
      "Matchmaker’s clients.  DateSpot is not in a legal partnership or joint venture with any Partner Matchmaker, " +
      "or otherwise in business with any of them.  For a subscription fee, DateSpot provides an arms-length " +
      "service as an independent contractor to the Site’s Partner Matchmakers. " +
      "While DateSpot seeks to satisfy itself and its Users that a company or person" +
      " in fact appears to be in the matchmaking business when it contacts us to subscribe as a " +
      "Partner Matchmaker by requiring at the time of signup that they have a professional" +
      " related website and a professional related public profile on social media and/or Yelp," +
      " and that their ratings or reviews are of average or better standing at that time, our assessment " +
      "is limited and their ratings may change after that point, not necessarily affecting their status " +
      "as a DateSpot Partner Matchmaker.  DateSpot conducts no material investigation or vetting of the" +
      " persons and companies seeking to subscribe to our site as Partner Matchmakers.",
    text5:
      "DateSpot, accordingly, makes no representation and provides no assurance to any " +
      "Member concerning the qualifications, legitimacy, or ethics of any of subscribing " +
      "Partner Matchmaker, or that they are in fact whom they hold themselves out to be – " +
      "a matchmaker with paying clients.  DateSpot conducts no criminal background checks " +
      "of an anyone who uses or subscribes to Site Services.",
    text6:
      "If and when DateSpot contacts you to advise that an identified Partner Matchmaker" +
      " would like to connect with you, before you give us permission to provide your contact" +
      " information to the Partner Matchmaker, each Members agrees to make his or her own independent " +
      "investigation and evaluation of that Partner Matchmaker based on the company name provided. " +
      "If you are not completely comfortable, you agree you will tell us not to release your contact information.",
    text7:
      "DateSpot does not vet or vouch for any of its Members. Similarly, because DateSpot does not meet, " +
      "interview, conduct background checks, or seek to verify information a Member posts to his or " +
      "DateSpot Profile, DateSpot makes not representation and provides no assurance to any " +
      "Partner Matchmaker concerning the accuracy, ethics, or trustworthiness of any of its" +
      " Members’ DateSpot profiles, or that they are in fact whom they hold themselves out to be.",
    text8:
      "Each Partner Matchmaker acknowledges and agrees the he, she, or it, and not DateSpot," +
      " is solely responsible for assessing the integrity, honesty, identity, and trustworthiness " +
      "of all Members and his or her DateSpot Profile.",
    text9:
      "Interactions with Other Users You, as a Site User, are fully and solely responsible for your " +
      "communications and interactions with other Users. You agree and understand that DateSpot is not obligated " +
      "to review the backgrounds of its Users, or to screen Users. DateSpot makes no guarantees, " +
      "warranties, or representations as to the actions or conduct of its Users. DateSpot makes no " +
      "guarantees as to number or frequency of matches made through your use of the Services. Also, we " +
      "do not guarantee, warrant, endorse, or imply compatibility with any current or future Users, or any of " +
      "their clients. In absolutely no event shall DateSpot be liable for any damages resulting from messages, " +
      "communications, meetings, or actions with other Users of the Services, or with any clients of any Partner " +
      "Matchmaker. You agree that it is your responsibility to take reasonable precautions in all actions and " +
      "interactions with other Users of the Services, or with clients of any Partner Matchmaker; this is " +
      "especially important if You decide to meet in person. In absolutely no event shall DateSpot be held " +
      "liable for any damages, in any form, whatsoever arising out of or relating to the conduct of You or" +
      "anyone else in connection with the use of the Services. This includes, without limitation," +
      " that we will not be held liable for any mental instability, emotional distress, or bodily " +
      "injury whether indirect, direct, incidental, accidental arising out of or relating to " +
      "conduct of You or anyone else in connection with the Services.  You are solely responsible " +
      "for your communications and interactions with other Users. We reserve the right to, but " +
      "have no obligation to, monitor disputes between you and other Users of the Services.",
    text10:
      "Partner Matchmaker Recommendations. Ratings for Partner Matchmakers included on our " +
      "Site and in our recommendation module derive from a rules-based system heavily weighted towards " +
      "reviews from our Members interactions with the Partner Matchmakers, and, to a lesser extent," +
      " may integrate reviews from outside review-based websites. Ratings may be displayed even " +
      "if there is only one rating, and, as explained above, DateSpot does not vet or vouch for " +
      "any of the Partner Matchmakers, including those included in the recommendation module.",
    text11:
      'Locks. "Locks" permit a Partner Matchmaker to maintain exclusive contact with a Member where that' +
      " Member is seeing a Partner Matchmaker’s client. The lock disqualifies the Member from being " +
      "contacted by other Partner Matchmakers for as long as the lock is place, with the exception " +
      "of any previous Partner Matchmaker that Member was already corresponding with. " +
      "This means a Member's profile may be visible to other Partner Matchmakers, but the Member won’t be able " +
      "to be contacted by them. However, “locking” does not guarantee that a Partner Matchmaker" +
      " will not make contact with a Member who they have added to their own database or network. " +
      "A lock is held indefinitely until either the Matchmaker or the Member provides an update that " +
      "the Member is not seeing the Matchmaker's client anymore. It is the responsibility of" +
      " the Partner Matchmaker AND the Member to update a Member's status when prompted " +
      "by email or by logging into one’s account.  DateSpot reserves the right to suspend any account " +
      "including if it believes in its sole discretion that a Member or Partner Matchmaker is incorrectly " +
      "assigning statuses to Members.",
  },
  sectionTwo: {
    title: "III. User Rules and Guidelines",
    text:
      "In order to keep this Site useful, pleasant, and lawful for all Users, it is important that" +
      " Users follow our Site rules and guidelines (Rules and Guidelines). DateSpot " +
      "reserves the right to deny further access to its Site and Services to any " +
      "User who violates any of the Rules and Guidelines, is the subject of complaints " +
      "by other DateSpot Users, or for any other reason or for no reason at all.",
    text1:
      "The following is a list of rules that govern your conduct in connection " +
      "with your use of our Services and the Site. You agree to abide by these rules, which include, but are not limited to:\n",
    list: [
      "• You agree that You will not pretend to be any person or entity other than yourself.",
      "• You agree not to display material containing nudity or pornographic material of any kind.",
      '• Any content, words, photographs, messages, or other information (collectively, "Content") ' +
        "provided to us or other Users through the Site shall remain your sole and exclusive property, " +
        "and You shall be solely responsible for your Content and the consequences of providing it.",
      "• You agree all information you provide to us, and all your Content, will be accurate, current, " +
        "and complete. You absolutely will not use the Services to provide, display, or transmit any defamatory," +
        " inaccurate, abusive, obscene, profane, offensive, sexually oriented, threatening, harassing," +
        " racially offensive material, or that which reflects illegal material or solicits illegal conduct." +
        " Also, you will not provide, publish, display, or transmit any material that infringes or " +
        "violates another party's rights (not limited to, but including intellectual property rights, " +
        "rights of privacy, and copyright rights).",
      "• You agree if any of your Content becomes inaccurate with the passage of time you will " +
        "promptly correct such Content to make it accurate. " +
        "This includes one’s status as being an unmarried person not otherwise already in a committed relationship.",
      "• You agree that you will not post, publish, display, or transmit in any way proprietary information," +
        " copyrighted material, and trademarks that do not belong to you without first obtaining " +
        "the prior consent of the owner of such rights.",
      "• You agree that you will not modify the Site and/or the Services for your own personal or " +
        "commercial purposes. This includes, but is not limited to, altering, adapting, " +
        "licensing, sublicensing, or translating the Site and/or the Services. " +
        "You also agree that You will not aid others in such activities.",
      "• You agree that you will not hack or interfere with the Site, its servers, or any connected networks.",
      "• You agree that you will not use a robot, spider, manual/automatic processes " +
        "or devices to data-mine, data-crawl, scrape, or index the Site in any manner.",
      "• You agree that you will not stalk or harass another User of our Services, " +
        "and will not use our Services or Site in any manner which might be offensive, " +
        "illegal, or that might violate the rights, harm, or threaten the safety of yourself or other Users.",
      "• You agree that you will not use the Services to promote, " +
        "condone, endorse, distribute, publish, or post any material that solicits money, funds, or people.",
      "• You agree that you will not use the Site to transmit, upload, " +
        "or distribute any electronic/computer viruses, trojan horses, or anything " +
        "else that might cause harm to other Users, our Services or DateSpot.",
      "• You agree not to use our Services for displaying harassing, abusive, threatening, " +
        "harmful, vulgar, obscene, or tortuous material or invading other's privacy.",
      "• You agree to keep all personal login information, such as passwords, private and confidential, " +
        "and you agree not to give such information to anyone.",
      "• You agree that you will not imply or state that any statements you make are" +
        " endorsed by DateSpot without our prior written consent.",
      "• You agree you will not engage in any effort to circumvent the efforts we take to " +
        "anonymize and keep concealed the name, addresses, and contact information of each Member " +
        "and his or her DateSpot Profile unless and until he or she expressly authorizes the specific " +
        "release of any of it.  This includes, without limitation, any Partner Matchmaker or other " +
        "User using software tools or search engines to search a Member’s photo " +
        "to seek to uncover his or her name, identity, email address, home or work address, " +
        "social media account, phone number or the like",
      "• You agree you may not authorize others to use your Site account or subscription, " +
        "and you may not assign or otherwise transfer your account or subscription to any other person or entity.",
    ],
  },
  nextSections: [
    {
      title: "IV. Privacy Policy",
      text:
        "Use of the Services and Site are also governed by our Privacy Policy, " +
        "which is incorporated herein by reference. Your privacy is important to us, and our " +
        "Privacy Policy makes important disclosures about how DateSpot collects, uses, and shares " +
        "the information you provide, or we receive, variously when you access the Site, or " +
        "when you join the Site as a Member or become a subscribing Partner Matchmaker. " +
        "It also describes your choices regarding control over the information you provide to us," +
        " to whom it is released, and how you may correct or update your personal information. " +
        "We encourage you to read our Privacy Policy and use it to help make informed decisions.",
    },
    {
      title: "V. DateSpot’s Proprietary and Intellectual Property Rights",
      text:
        "Our Site contains trademarks, copyrighted material, and reflects other proprietary rights " +
        "of DateSpot. Other than your Content, we own and retain absolutely all intellectual and " +
        "proprietary rights of the Site and in and to the Services. You agree that you cannot " +
        "copy, alter, duplicate, transmit, license, post, publish, display, sell, or any of our " +
        "proprietary information and copyrighted material, unless, we have given you explicit written permission.",
    },
    {
      title: "VI. Indemnification",
      text:
        "You agree to hold harmless, defend, and indemnify DateSpot, its officers," +
        " members, managers, advertisers, agents, and employees against and from all, " +
        "including but limited to, loss, claim, demand, costs, liability, lawsuits, attorney's " +
        "fees, incurred by such persons or groups in conjunction with any dispute or claim arising" +
        " out of your breach of these Terms of Use, the Privacy Policy, and/or your Partner " +
        "Matchmaker Subscription Agreement.  DateSpot reserves the right, at its own expense," +
        " to assume the exclusive defense and control of any matter otherwise subject to " +
        "indemnification by the User and the User shall not in any event settle any matter " +
        "without the written consent of DateSpot.",
    },
    {
      title:
        "VII. Governing Law, Arbitration of Disputes and Waiver of Class Actions",
      text:
        "These Terms of Service, the Privacy Policy, and all Partner Matchmaker " +
        "Subscription Agreements, shall be governed and construed in accordance with " +
        "the laws of the state of California conflict of laws excluding provisions.",
      text1:
        "If DateSpot fails to exercise or enforce any right or provision of these Terms of Service," +
        " the Privacy Policy, and any Partner Matchmaker Subscription Agreement (each, an Agreement) " +
        "that failure shall not be used to prove that DateSpot has waived the right or provision" +
        " under any of these agreements. If any provision of any Agreement is found by a court of competent " +
        "jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor " +
        "to give effect to the parties' intentions as reflected in the provision, and that the other " +
        "provisions of the Agreement remain in full force and effect. The User agrees that regardless " +
        "of any statute or law to the contrary, any claim or cause of action arising out of or" +
        " related to our Services or an Agreement must be filed within one (1) year after such " +
        "claim or cause of action arose or be forever barred. The section titles in each such Agreement " +
        "are for convenience only and have no legal effect.",
      text2:
        "ARBITRATION of DISPUTES.  You agree with DateSpot that, where allowed by" +
        " applicable laws, any dispute or claim in law or equity arising between " +
        "them regarding the use or breach of this Site, these Terms of Use, and Partner " +
        "Matchmaker Subscription Agreements, including any dispute regarding the enforceability" +
        " or applicability of this arbitration provision, shall be decided by neutral, binding " +
        "arbitration conducted in San Jose, California. The arbitrator shall be a retired judge," +
        " justice, or an attorney with at least ten (10) years of legal experience relating to " +
        "the subject matter of this Agreement, unless the parties mutually agree otherwise, " +
        "who shall render an award in accordance with the substantive laws of California. " +
        "In all other respects, the arbitration shall be conducted in accordance with " +
        "the rules and procedures of the American Arbitration Association, " +
        "subject to the parties being allowed limited discovery. Judgment upon " +
        "the award of the arbitrator(s) may be entered in any court having jurisdiction.",
      bold:
        "NOTICE: BY USING THIS WEBSITE YOU, ACCEPTING THESE TERMS OF USE, AND/OR ANY" +
        " PARTNER MATCHMAKER SUBSCRIPTION AGREEMENT, YOU ARE AGREEING TO HAVE " +
        'ANY DISPUTE REGARDING THE MATTERS INCLUDED IN THIS "ARBITRATION OF DISPUTES"' +
        " PROVISION DECIDED BY NEUTRAL ARBITRATION AND YOU ARE GIVING UP ANY RIGHTS " +
        "YOU MIGHT POSSESS TO HAVE THE DISPUTE LITIGATED IN A COURT OR JURY TRIAL. " +
        "YOU ARE GIVING UP YOUR JUDICIAL RIGHTS TO DISCOVERY AND APPEAL, " +
        'UNLESS THOSE RIGHTS ARE SPECIFICALLY INCLUDED IN THE "ARBITRATION ' +
        'OF DISPUTES" PROVISION. IF YOU REFUSE TO SUBMIT TO ARBITRATION AFTER AGREEING TO THIS PROVISION ' +
        "YOU MAY BE COMPELLED TO ARBITRATE ANYHOW PURSUANT TO A COURT ORDER. " +
        "YOUR AGREEMENT TO THIS ARBITRATION PROVISION IS VOLUNTARY. IF YOU DO NOT WISH TO AGREE TO ARBITRATION, " +
        "THEN YOU MAY NOT USE THIS WEBSITE.",
      bold1:
        "Class Action Waiver.  ARBITRATION OR ANY OTHER LEGAL ACTION ARISING IN CONNECTION WITH THE USE OF " +
        "THIS SITE, THESE TERMS OF USE, OR ANY PARTNER MATCHMAKER SUBSCRIPTION AGREEMENT MUST, TO THE MAXIMUM EXTENT" +
        " PERMITED BY  APPLICABLE LAW, BE ON AN INDIVIDUAL BASIS, WHERE ALLOWED BY APPLICABLE LAWS. THIS MEANS " +
        "NEITHER YOU NOR WE MAY JOIN OR CONSOLIDATE CLAIMS BY OR AGAINST OTHER USERS OR LITIGATE IN COURT OR" +
        " ARBITRATE ANY CLAIMS AS A REPRESENTATIVE OR MEMBER OF A CLASS OR IN A PRIVATE ATTORNEY GENERAL CAPACITY.",
    },
    {
      title: "VIII.  Disclaimer of Warranties",
      text:
        "ALL MATERIALS, INFORMATION, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE (OTHER THAN USER " +
        '"CONTENT") ARE PROVIDED "AS IS" AND "AS AVAILABLE" FOR YOUR USE. THE SITE AND SERVICES ARE PROVIDED WITHOUT ' +
        "WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF" +
        " MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. DATESPOT AND ITS AGENTS DO NOT " +
        "WARRANT THAT THE SITE AND SERVICES ARE ACCURATE, RELIABLE OR CORRECT; THAT THE SITE WILL BE AVAILABLE AT " +
        "ANY PARTICULAR TIME OR LOCATION; THAT ANY DEFECTS OR ERRORS WILL BE CORRECTED; OR THAT THE STIE AND SERVICES " +
        "ARE FREE OF VIRUSES, ERRORS, OR OTHER HARMFUL COMPONENTS. YOUR USE OF THE SITE AND SERVICES IS SOLELY AT YOUR " +
        "RISK. BECAUSE SOME JURISDICTIONS DO NOT PERMIT THE EXCLUSION OF CERTAIN WARRANTIES, THESE EXCLUSIONS " +
        "MAY NOT APPLY TO YOU.",
    },
    {
      title: "IX. Limitation of Liability",
      text:
        "UNDER NO CIRCUMSTANCES SHALL DATESPOT OR ITS AGENTS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, " +
        "INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES THAT RESULT FROM THE USE OF, OR INABILITY TO USE, THE SITE " +
        "OR OUR SERVICES. THIS LIMITATION APPLIES WHETHER THE ALLEGED LIABILITY IS BASED ON CONTRACT, TORT, " +
        "NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER BASIS, EVEN IF THE DATESPOT HAS BEEN ADVISED OF THE " +
        "POSSIBILITY OF SUCH DAMAGE. YOU AGREE THAT THE MAXIMUM AGGREGATE LIABILITY OF THE DATESPOT SHALL " +
        "BE LIMITED TO THE AMOUNT YOU PAID TO DATESPOT FOR OUR SERVICES. BECAUSE SOME JURISDICTIONS DO NOT " +
        "ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, THE COMPANY'S LIABILITY " +
        "IN SUCH JURISDICTIONS SHALL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW.",
    },
    {
      title: "X. Modifications to Terms of Service",
      text:
        "DateSpot may change these Terms of Service at any time. If the Terms of Service are modified," +
        " we will post the restated Terms of Service in full on this page and update the ‘Last Updated’ date above. " +
        " And, if we make any material changes to our Terms of Service and you are a Member or Partner Matchmaker, " +
        "we will notify you by email prior to the change becoming effective.",
    },
  ],
};
export const PRIVACY_POLICY = {
  introduction: [
    {
      text:
        "This Privacy Policy applies to visitors to our website, www.datespot.love (the Site), " +
        "and to those who are users of services delivered through our Site." +
        " The Site is owned and operated by DateSpot Inc, a Delaware corporation " +
        "(DateSpot, we, us, or our). This page describes how DateSpot collects, uses, and shares the " +
        "information you provide, or we receive, variously when you access the Site, or when you join the" +
        " Site by registering as a Member or as a subscribing Partner Matchmaker.  It also describes your " +
        "choices regarding control over the information you provide to us, to whom it is released, " +
        "and how you may correct or update your personal information.    ",
    },
    {
      text:
        "If we modify these policies, we will post the restated Privacy Policy in full on this page and " +
        "update the ‘Last Updated’ date above.  And, if you are a Registered User or Partner Matchmaker, " +
        "we will notify you as described at the end of this Policy.",
    },
    {
      text:
        "If you have any unresolved privacy or data use concerns not addressed to your satisfaction " +
        "by this Privacy Policy statement, please contact us at support@datespot.love. A real human being will respond.",
    },
  ],
  sections: [
    {
      title: "I. Security Measures Concerning Your Personal Information",
      text:
        "While transmission of information over the Internet cannot be guaranteed 100% secure, our Site employs " +
        " HTTPS technology -- the HTTP protocol embedded within the TLS (transport layer security) protocol. " +
        "HTTP addresses the security of web surfing mechanics, while TLS encrypts the data sent over the network " +
        "and verifies the identity of the server host using a certificate.  This helps protect you and " +
        "the information in your account.",
      text1:
        "In addition, when you register with DateSpot as a Member, your personal information is password-protected." +
        " Your password and password reminder answers are kept encrypted at all times.  However, to avoid unauthorized use " +
        "of your account by others, you should take steps to maintain protection of your password, including changing your" +
        " password each year, including a mix of capital and lower case letters, and including both letters, numbers and " +
        "symbols. Further, this site may contain, and, in the course of services delivered through this Site we may provide" +
        " you, with various links to other Web sites.  DateSpot is not responsible for the privacy practices of " +
        "those linked sites. In particular, DateSpot is not responsible for the privacy practices of Partner Matchmakers" +
        " or non-matchmaking companies we recommend.  You should not assume that other sites and Partner Matchmakers take " +
        "similar steps in securing your information and should evaluate their privacy and security practices before " +
        "using their systems or services.",
    },
    {
      title: "II. Collection of Information",
      text:
        "MEMBERS. When you initially register with DateSpot as a Member, we require that you provide your name and " +
        "email address, and your phone number is optional. By registering, you consent to our contacting you by email, " +
        "initially to invite you to complete your DateSpot Profile if applicable.  Your DateSpot Profile consists of" +
        " select personal information which matchmakers, including our Partner Matchmakers, find useful to screen persons for " +
        "those who may be a compatible with one their paying clients.  While our Site template suggests certain standard " +
        "information, it is up to you what information you provide in your DateSpot Profile.",
      text1:
        "While your DateSpot Profile will be accessible to our Partner Matchmakers (see Use of Collected Information)" +
        " it is not accessible to others outside of DateSpot, and is not accessible to other Members.  Your DateSpot Profile" +
        " also does not include your home address. Partner Matchmakers can not view your last name, email address or phone " +
        "number without asking to connect with you as one of their “connections” in their plan.  As discussed below at " +
        "Use of Collected Information, you determine if and when your contact information may be released to a particular " +
        "Partner Matchmaker by responding to our contact request emails.",
      text2:
        "At any time, you may access and add to, remove, or change the personal information in your DateSpot Profile.",
      text3:
        "If you do not want to be contacted in the future, you may either deactivate or cancel your account. " +
        "Deactivating a DateSpot Profile removes its visibility to Partner Matchmakers so they may not contact you, " +
        "but we retain the information.  You may ask that it be re-activated or cancelled at any time.  Deactivating " +
        "your account is a helpful choice if you enter into a non-married relationship or otherwise may want to re-join " +
        "the Site or reference your Profile.  You may completely cancel and thus delete your DateSpot Profile through " +
        "your Account Settings page.  Once deleted, we will not retain a copy, and it is not retrievable. " +
        "If you cancel your account, we may alert the Matchmakers you connected with through our Site, but we cannot" +
        " control their response and the information which you agree to provide to a Partner Matchmaker. Accordingly," +
        " you may wish to retain a list of those Partner Matchmakers whom, in response to a request, you have authorized" +
        " a connection with to request that they delete your information as well (see Use of Collected Information).",
      text4:
        "PARTNER MATCHMAKERS.  When you, as a professional matchmaker, apply to join our Site as" +
        " a Partner Matchmaker, you will be asked to provide your full name, firm name, firm address, telephone " +
        "number, business email address, and business website URL.  You will not be able to subscribe to the Site " +
        "until we reply by email with a confirmation.  We will only reply with a confirmation email if, in our" +
        " sole discretion, we are satisfied that you are in fact in business as a reputable matchmaker. " +
        "In the course of your use of our Site, we may request additional information, for example, whether your " +
        "services focus on a particular demographic, your experience, and relevant background.  We do not presently " +
        "sell Partner Matchmaker information with third parties, and only share it with one or more Members when you" +
        " ask that we reach out to a particular Member on your behalf (see Use of Collected Information.)",
      text5:
        "We rely on the services of a third party billing processor to process credit and debit card payments " +
        "from subscribing Partner Matchmakers.  We do not receive or retain your card information. " +
        "To process your payment, you will need to accept the third party card processor’s payment and processing " +
        "terms and conditions.  We do not have control over, nor are we responsible for, those terms and conditions " +
        "or the payment processor’s privacy practices.",
      text6:
        "ALL SITE USERS AND VISTORS. As is true of most web sites, we gather certain information automatically " +
        "and store it in log files when you access our Site. This information may include internet protocol " +
        "(IP) addresses, browser type, internet service provider (ISP), referring/exit pages, operating system, " +
        "date/time stamp, and/or clickstream data.  We may combine this automatically collected log information " +
        "with other information we collect about you.  We do this to improve services we offer you, to improve " +
        "marketing, analytics, and/or Site functionality.",
      text7:
        "DateSpot uses cookies or similar technologies to analyze trends, administer the Site, track users' " +
        "movements around the Site, and to gather demographic information about our user base as a whole. " +
        "You can control the use of cookies at the individual browser level, but if you choose to disable cookies," +
        " it may limit your use of certain features or functions of our Site or service.",
      text8:
        "SOCIAL MEDIA AND WIDGETS. Our Site may include Social Media Features, such as the Facebook " +
        "Like button and Widgets, such as the Share this button or interactive mini-programs that run on our site." +
        " These Features may collect your IP address, which page you are visiting on our site, and may set a cookie " +
        "to enable the Feature to function properly. Social Media Features and Widgets are either hosted by a " +
        "third party or hosted directly on our Site. Your interactions with these Features are governed " +
        "by the privacy policy of the company providing it.",
    },
    {
      title: "III. Use of Collected Information",
      text:
        "In addition to any uses described above, we collect information through our Site primarily to " +
        "permit Partner Matchmakers to screen our database of DateSpot Profiles for possible compatibility with " +
        "any of their clients.  For privacy and security, one’s DateSpot Profile initially does not display " +
        "contact information or the Member’s last name, until the Partner Matchmaker requests a connection with" +
        " that Member.  In the event a Partner Matchmaker is interested in learning more about you, he or she " +
        "contacts us, and we email to you the name of the matchmaker, the name and website URL of his or her " +
        "company, and ask that you reply whether or not you authorize our releasing your contact information to " +
        "the interested matchmaker.  Only if you reply affirmatively, will we forward your contact information " +
        "to the inquiring matchmaker.",
      text1:
        "The interested Partner Matchmaker may ask to speak with you directly over the phone, over video " +
        "call or in person, as their services are personal and often in person matchmaking.  How you connect" +
        "and whatever information you wish to provide is entirely up to you.  We do not necessarily receive" +
        " that information, nor do we have any control over it once you provide it to a Partner Matchmaker." +
        " We do proactively request impressions about you from the matchmaker and their client(s) and what" +
        " happened with a particular match for our records and to assist with matching you appropriately in the future.",
      text2:
        "Partner Matchmakers pay us a monthly subscription fee to access and search our database of DateSpot Profiles. " +
        "We would also share your information to any recommended business mentioned on our Site only when you express " +
        "interest in that particular business or use our referral code when signing up for the service. We do not otherwise," +
        " however, share or sell any of your personal information to any third parties.",
      text3:
        "If one is a subscribed Partner Matchmaker, we do not presently sell, disclose, or share your" +
        " business or personal information with third parties, unless, and until you ask that we share your name " +
        "and business information with a specific Member identified by their DateSpot Profile.",
      text4:
        "Except as expressly set forth above, DateSpot does not sell, trade, or rent your personal information " +
        "to others without your permission.  We share your personal information with third parties only in the ways " +
        "that are described in this Privacy Policy.  We may, however, internally use your information to improve our " +
        "marketing and promotional efforts, statistically analyze site usage, and improve our Site's content, " +
        "offering, and layout. We may use your IP address may to help diagnose problems with our cloud hosted server," +
        " to administer our Site, and for security needs.",
      text5:
        "We may use the information we collect to notify you about the status of your account, " +
        "important functionality changes to our Site, new DateSpot services, and special offers " +
        "we think you'll find valuable. We may also contact you to verify your information or ask " +
        "you about your experiences with our Site and services.",
      text6:
        "Except for required communications that relate directly to the service being provided, you may " +
        '"opt out" from receiving communications from us.  If you are a Member, required communications include' +
        " (1) confirmation of your registration or of the status or content of your DateSpot Profile, including," +
        " but not limited to, in response to requests to deactivate, delete, or reactivate your DateSpot Profile, " +
        "(2) notification that a Partner Matchmaker is seeking permission to contact you, and (3) notice of changes" +
        " to our Privacy Policy or Terms & Conditions.  If you are a Partner Matchmaker, required communications i" +
        "nclude (1) an invitation to subscribe to our Site, (2) confirmation of your subscription, (3) communications" +
        " concerning billing and your subscription, and (4) relaying Members’ responses to your request for " +
        "permission to contact him or her.",
      text7:
        "To opt out of non-required communications, check the appropriate boxes when you register or at any time " +
        "by logging into the Site and modifying your account, or as applicable, DateSpot Profile page. Users may" +
        " also opt out of receiving non-required communications by replying to unsubscribe in the subject line " +
        "in the email or by e-mailing us at support@datespot.love. Upon request, DateSpot will provide you with " +
        "information about whether we hold any of your personal information.",
      text8:
        "When it is necessary to contact you, we use your email address and, in some situations, " +
        "your mailing address or phone number. Note: DateSpot will not ask you for your password " +
        "if we contact you. Do not disclose your password if asked by a caller claiming to represent DateSpot.\n" +
        "DateSpot may develop business partnerships with other companies, as part of our service to users." +
        " These special business partnerships may include acquisitions by or mergers with other companies or " +
        "special joint ventures that integrate our Site and services. " +
        "In this event, you will be notified via email and a prominent notice on our Web " +
        "site of any change in ownership or uses of your personal information, as well as any " +
        "choices you may have regarding your personal information.",
      text9:
        "In certain situations, DateSpot may be required to disclose personal data in response to lawful " +
        "requests by public authorities, including law enforcement. DateSpot may release account information when" +
        " we believe, in good faith, that such release is reasonably necessary to comply with law; enforce or apply " +
        "the terms of any of our user agreements including the Terms of Use; protect the rights, property, or " +
        "safety of DateSpot, our users, or others, or investigate fraud.",
    },
    {
      title: "IV. Consent and Changes to This Policy",
      text:
        "By using our Site, you consent to the collection and use of this information by DateSpot as described above." +
        " If we change our privacy practices, we will update our Privacy Policy at this page to include such changes " +
        "so that you are aware of what information we collect, how we use it, and under what circumstances we disclose it." +
        " If we make any material changes to our Privacy Policy and you are a Member or Partner Matchmaker, we " +
        "will notify you by email prior to the change becoming effective",
    },
  ],
};
export const MATCHMAKER_AGREEMENT = {
  title:
    "This Partner Matchmaker Agreement (Agreement) is entered into by you, the subscribing matchmaker" +
    "(you or Partner Matchmaker)," +
    " and DateSpot LLC, a California limited liability (we, us, or DateSpot), " +
    "with reference to accessing our Site, www.datespot.love, as a Partner Matchmaker. " +
    "You subscribe and enter into this Agreement by providing the requisite " +
    "Partner Matchmaker information below, checking and selecting an applicable subscription box (Free Trial, " +
    "Paid level 1 “Crush” Partner, Paid level 2 “Flirting” Partner, or Paid Level 3 “Exclusive” Partner), and, " +
    "checking the box at the end of this Agreement acknowledging your acceptance of the same including the incorporated " +
    "Terms of Service and Privacy Policy, and, except for Trial Partners," +
    " proceeding to Check-Out and completing required payment information.",
  sections: [
    {
      title: "Required Subscribing Matchmaker Information:",
      text: "First name, last name, email address, phone number, company name and company URL",
      list: null,
      bulletList: null,
    },
    {
      title: "Partner Subscription Levels & Fees:",
      text:
        "Each of the following refers to connections with Members who confirm that they are in a" +
        " non-exclusive relationship at the time of request and are interested in connecting with you specifically. " +
        "We do not guarantee that their status or interest will not change at any point, even immediately afterwards.",
      list: [
        {
          title: "• Free Trial:",
          elements: [
            "⁃ No charge",
            "⁃ 1 connection total, with no time limit",
            "⁃ This option is non-renewable",
            "⁃ Should the Site cease to exist or cease to provide services prior to a connection being made,",
            "DateSpot does not owe the Partner Matchmaker anything, either monetary or otherwise.",
          ],
        },
        {
          title: "• Paid Level 1 “Crush”",
          elements: ["⁃ $100 per month", "⁃ Up to 5 connections per month"],
        },
        {
          title: "• Paid Level 2 “Flirting”",
          elements: [
            "⁃ $200 per month",
            "⁃ Up to 15 connections per month",
            "⁃ 1 free Lock per month",
            "⁃ Eligible as inclusion as a recommended matchmaker, " +
              "subject to our discretion and the ratings on the site by Members and any outside parties.",
          ],
        },
        {
          title: "• Paid Level 3 “Exclusive”",
          elements: [
            "⁃ $500 per month",
            "⁃ Up to 50 connections per month",
            "⁃ 3 free Locks per month",
            "⁃ Eligible as inclusion as a recommended matchmaker, subject to our discretion and the ratings " +
              "on the site by Members and any outside parties.",
          ],
        },
      ],
      bulletList: null,
    },
    {
      title: "Payments & Billing:",
      text: "",
      list: null,
      bulletList: [
        {
          text:
            "• As a Paid Level Partner, your monthly subscription begins on the date you accept this Partner" +
            " Matchmaker Agreement and make your initial payment (the Effective Date). " +
            "Each subsequent subscription payment is due every 30 days (the Billing Date).",
        },
        {
          text:
            "• We may change the DateSpot price but will give you prior notice of these changes. " +
            "These changes will apply after your current payment period is completed, when the next payment is due " +
            "from you after the notice. We will give you at least 30 days prior notice of a price increase " +
            "before you are charged; " +
            "if you are given less than 30 days' prior notice, the change will not apply until " +
            "the payment after the next payment is due." +
            " If you do not wish to continue with DateSpot or the new price, you may cancel or downgrade at any time " +
            "from within your DateSpot account settings. Your cancellation or downgrade will apply to the " +
            "next billing period after the current service term.",
        },
        {
          text:
            "• AUTO-RENEWAL:  Your subscription will automatically renew on a month-to-month basis until notice of " +
            "non-renewal is provided by either party.  Regardless of when notice is provided, it becomes effective the " +
            "day immediately before your next Billing Date.  By subscribing as a paid level Partner, you consent to your " +
            "credit or debit card being automatically charged as of the Billing Date for the duration of your" +
            " subscription until canceled.",
        },
      ],
    },
    {
      title: "Canceling Account:",
      text:
        "You may cancel your account at any time by emailing support@datespot.love or through the link on your" +
        " Account Settings page when you’re logged into your account. " +
        "Due to your monthly subscription, your account is active until the end of the current billing cycle in " +
        "which you requested the cancellation. Your account information and " +
        "matching information will " +
        "be archived should you choose to reactivate in the future.",
      list: null,
      bulletList: null,
    },
    {
      title: "Reviews: ",
      text:
        "Our Partner Matchmakers agree to be reviewed on the DateSpot Site. " +
        "These reviews are heavily weighed towards member ratings after a connection is made, " +
        "and may also include ratings by third party review sites.  You may not alter or request a change to your rating. " +
        "You also are not permitted to instruct or ask a member to give you a positive or certain rating." +
        " However, if you are unsatisfied with your rating, and do not want to wait to organically influence " +
        "it through future connections, you can request to be temporarily or permanently removed " +
        "from the section containing the rating altogether.",
      list: null,
      bulletList: null,
    },

    {
      title: "Locks: ",
      text: '"Locks" are a way to maintain exclusive contact with a Site Member.',
      list: null,
      bulletList: [
        {
          text:
            "• A Partner Matchmaker can secure a lock by referring someone new to the DateSpot database " +
            "(identified as new by their email address), after that person joins the database.",
        },
        {
          text:
            "• The lock allows them to not be contacted by any other Partner Matchmaker for as long as" +
            " the lock is place, with the exception of any previous Matchmaker they were already corresponding with.",
        },
        {
          text:
            "• A lock is held indefinitely until either the Partner Matchmaker provides and update that the " +
            "Member is not dating the Partner Matchmaker’s client anymore, or they otherwise do not require the " +
            "exclusive contact, or the member provides an update that they are not dating the Partner Matchmaker's" +
            " client anymore to the degree that they don’t want to be available for other potential matches.",
        },
        {
          text:
            "• It is the responsibility of the Partner Matchmaker to update a Member's status when" +
            " prompted by email or by logging into their account, including when they unlock a member.",
        },
        {
          text:
            "• It is up to our discretion to determine whether a Matchmaker is not updating their members. " +
            "DateSpot may suspend a Matchmaker's account if DateSpot determines that the Matchmaker " +
            "is consistently not updating locks or client status, which would suspend future payments.",
        },
        {
          text:
            "• If a member referred by a Matchmaker deactivates or cancels their DateSpot account after 30 " +
            "days of creating an account, the lock acquired by the Partner Matchmaker will still apply. " +
            "Before that timeframe, the lock may be revoked.",
        },
      ],
    },
    {
      title: "Free to Members: ",
      text: "DateSpot provides a platform that is free for single members, which is part of the appeal to singles.",
      list: null,
      bulletList: [
        {
          text:
            "• You agree not to charge a fee to any Member whom you contact through DateSpot who you are connecting" +
            " with one of your clients, even if charging both people is your standard business practice. " +
            "If you do not comply with this procedure, you risk immediate cancellation of your account.",
        },
        {
          text:
            "• Partner Matchmakers agree not to solicit a member to become a paying client within " +
            "60 days of a connection," +
            " unless the member has expressed interest in talking with a Matchmaker about becoming a " +
            "client through the DateSpot website. After 60 days, a Partner Matchmaker may use their" +
            " discretion in pitching and discussing the client route. ",
        },
        {
          text:
            "• If a member referred to you by DateSpot ends up becoming a client of yours," +
            " you agree to alert us and identify that Member.  This assists with our success monitoring.",
        },
      ],
    },
    {
      title: "Refunds and Credits:",
      text:
        "Refunds will not be given to a Partner Matchmaker who has connected with any member in any given month, " +
        "regardless of what ensued with that member or those members. We do our best to ensure that our members are" +
        " available and interested by sending them an email when you request to contact a member, and only passing" +
        ' them along to you when they say "yes".  We also take it on faith that their provided ' +
        "information and status is factual." +
        " However, we cannot guarantee that a Member's information is accurate, up-to-date," +
        " or that they follow through with meeting or speaking with you or a client, or that they " +
        "follow any other procedures.  If you did not connect with any member in a given month, or you believe" +
        " you are deserved a refund or connection credit for any other scenario, please email us at support@datespot.love" +
        " with details and we will review your case. We cannot guarantee any refund or credit, " +
        "but we strive to be fair and keep our Partner Matchmakers happy.",
      list: null,
      bulletList: null,
    },
    {
      title: "Data Breach: ",
      text:
        "In the event that your database has a data breach, you are required to notify DateSpot," +
        " as it may affect our members.  We have authority to alert affected and potentially affected members.",
      list: null,
      bulletList: null,
    },
    {
      title: "Other Important Terms:",
      text: "",
      list: null,
      bulletList: [
        {
          text:
            "A. BREACH:  Upon breach of any term or provision of this Agreement," +
            " we may immediately terminate your subscription and with it your access to the Site." +
            " You shall not be entitled to a pro-rata refund for prepaid fees.",
        },
        {
          text:
            "B.  No PRIOR AGREEMENTS: This Agreement supersedes all prior agreements, representations, " +
            "and discussions, and constitutes a final integrated agreement between the parties and may only be " +
            "modified by signed writing. This Agreement shall be interpreted as though all provisions" +
            " were drafted equally by each party.",
        },
        {
          text:
            "C.  NO WARRANTIES: You acknowledge that DateSpot makes no guarantees of your success, " +
            "and provides access to the Site and its services “as-is” without any implied or express warranties" +
            " including, but not limited to the implied warranties of merchantability, fitness for a particular " +
            "purpose, and non-infringement. DateSpot makes no warranty that the Site and its services will meet " +
            "your requirements, that the Site will be uninterrupted, timely, secure, or error free.",
        },
        {
          text:
            "D.  LAW AND FORUM:  This Agreement shall be interpreted and enforced under the laws of the State " +
            "of California. Any disputes related to this agreement shall be resolved between the parties under the " +
            "binding arbitration and class action waiver provisions set forth in the incorporate Terms of Service. " +
            "Additionally, every cause of action between the parties must be filed within one (1) year after " +
            "it arises, or be barred.",
        },
        {
          text:
            "E. PARTY RELATIONSHIP:  In entering into and performing this Agreement, DateSpot " +
            "is acting as an independent contractor, and neither party is acting as a partner or agent of the other. " +
            "Nothing in this Agreement is intended to create a partnership, joint venture, " +
            "or employer-employee relationship between the parties.",
        },
        {
          text:
            "F.  DAMAGES LIMITATIONS:  You agree that DateSpot shall not be liable for any direct, indirect, " +
            "incidental, special or consequential damages, resulting from the use or the inability to use the Site, " +
            "or from any services obtained through or from the Site or as a subscriber, or resulting from " +
            "unauthorized access to or alteration of your transmissions or data, including but not limited to, " +
            "damages for loss of profits, use, data or other intangible, even if DateSpot has been advised of " +
            "the possibility of such damages. You further agrees that DateSpot shall not be liable for any damages " +
            "arising from interruption, suspension or termination of the program, for any loss of data resulting " +
            "from accidental or deliberate deletion, network or system outages, file corruption or any other reasons," +
            " including but not limited to direct, indirect, incidental, special consequential or exemplary damages, " +
            "whether such interruption, suspension or termination was justified or not, negligent or intentional. " +
            "You agree that under all circumstances, DateSpot’s aggregate liability to you for damages related " +
            "to this Agreement, the Site and/or services shall not exceed the dollar amount you paid in " +
            "subscription fees under this Agreement.",
        },
        {
          text:
            "G.  INDEMNITY: You agree to defend, indemnify, and hold harmless DateSpot, " +
            "its members, managers, and employees from any third party claims brought by any of your " +
            "clients or any other person related to your use of the Site or connected with your subscription to the Site.",
        },
        {
          text:
            "H. Incorporation by Reference: This Agreement incorporates the Terms of Service appearing " +
            "at [insert link] and Privacy Policy [insert link].",
        },
        {
          text:
            "I. Changes to Subscription Terms:  We reserve the right to change Partner Matchmaker subscription " +
            "classification level names, pricing, and Singles access activity upon at least seven days’ notice by email." +
            " Any such noticed changes will automatically take effect starting the following month.   ",
        },
      ],
    },
  ],
};
