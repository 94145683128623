@import "src/scss/variables.scss";

// Classes

.helper-text {
  color: $helperText;
  font-weight: 500;
  font-size: 12px;
  text-align: left;
  font-style: italic;
  font-family: "Montserrat", sans-serif;
}

.image-cover {
  background-position: center;
  background-size: cover;
}

.error-message {
  font-size: 14px;
  color: $greyDarker;
  background-color: $greyLightest;
  padding: 10px;
  margin: 0 auto;
}

.success-message {
  font-size: 0.75rem;
  color: $tcolor1;
  margin-top: 0.25rem;
}

.btn,
a.btn {
  display: inline-block;
  padding: 7px 12px;
  box-shadow: 0 0 10px 0 rgba(84, 88, 90, 0.2);
  cursor: pointer;
  outline: none;
  text-decoration: none;
  transition: all 0.5s;
  user-select: none;
  -webkit-appearance: none;
  white-space: nowrap;
  width: max-content;

  background: $yellow;
  border: 2px solid $yellow;

  .value {
    color: #54585a;
  }

  &:hover {
    background-color: $darkYellow;
    border: 2px solid $darkYellow;
  }
}

.date-ago {
  color: $helperText;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
}

// Old Media Queries

@mixin for-mobile {
  @media only screen and (min-width: 0) and (max-width: 480px) {
    @content;
  }
}

@mixin for-tablet {
  @media only screen and (min-width: 481px) and (max-width: 768px) {
    @content;
  }
}

@mixin for-desktop {
  @media only screen and (min-width: 769px) {
    @content;
  }
}

@mixin for-tablet-down {
  @media only screen and (max-width: 768px) {
    @content;
  }
}

// New Media Queries

@mixin for-sm {
  @media only screen and (min-width: 0) and (max-width: 480px) {
    @content;
  }
}

@mixin for-md {
  @media only screen and (min-width: 481px) and (max-width: 768px) {
    @content;
  }
}

@mixin for-lg {
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    @content;
  }
}

@mixin for-xl {
  @media only screen and (min-width: 1025px) {
    @content;
  }
}



