.credit-block:hover {
    background-color: #f5f5f5;
    cursor: pointer;
}

.credit-block {
    display: grid;
    grid-template-columns: 4fr 1fr;
    grid-template-rows: auto;
    margin-bottom: 2em;
    width: 100%;
    padding-bottom: 2em;
    border-bottom: 2px solid #ffd758;
}

.credit-row-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.credit-row-1 {
    display: flex;
    flex-direction: row;
    /* width: 75%; */
}

.credit-row-n {
    display: flex;
    flex-direction: row;
    padding-top: 1em;
    /* width: 75%; */
}

.credit-row-input {
    font-weight: 500;
    font-size: 1em;
    margin: auto auto auto 0;
    max-width: 220px;
}

.credit-row-input::placeholder {
    font-size: 1em;
}

.credit-row-input-select {
    margin: auto auto auto 0;
    max-width: 220px;
    background-color: white;
    border: 1px solid black;
}

.credit-row-label {
    min-width: 100px;
    font-weight: 800;
}

.credit-row-value {
    min-width: 200px;
    margin: auto auto auto 0;
    /* text-align: left; */
}

.credit-row-value-notes {
    min-width: 15em;
    min-height: 4em;
    max-width: 220px;
}

input::-webkit-input-placeholder {
    font-size: 2px;
    line-height: 3;
}