@import "../../../../../scss/variables.scss";
@import "../../../../../scss/utilities.scss";

.admin-users-list,
.admin-mm-list {
  display: flex;
  flex-direction: column;
  height: 100%;

  .table-container {
    overflow: auto;
    box-shadow: inset 0 -3px 3px 0 rgba(0, 0, 0, 0.05);
    flex: 1;
  }
}

.admin-mm-list {
  .filters-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  }
  .tab-nav {
    margin: 10px;
    width: 30%;
  }
  .user-row > td {
    padding: 20px;
  }
  .drop-down-menu.down.active {
    top: 30px;
    right: 0;
    width: max-content;
  }

  .actions-col {
    display: grid;
    place-content: center;
  }
}

.admin-user-table,
.admin-mm-table {
  width: 100%;
  & > tbody > tr,
  & > thead > tr {
    text-align: center;
    vertical-align: middle;
  }

  & > tbody > tr > td {
    cursor: default;
    padding: 10px;
    .avatar {
      background-size: cover;
      height: 90px;
      width: 90px;
      border-radius: 50%;
      background-color: #fafafa;
      border: 1px solid #aaa;
      color: #aaa;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .user-row {
    &:hover {
      background: rgba(101, 196, 192, 0.2);
    }
  }
}

.labels {
  background-color: $teal;
  font-size: 16px;
  & > th {
    padding: 14px;
    color: white;
  }
}

@include for-mobile {
  .admin-users-list,
  .admin-mm-list {
    height: 78svh;
  }

  .admin-user-table,
  .admin-mm-table {
    font-size: 12px;
    tbody > .user-row-mobile {
      text-align: left;
      td > p {
        margin: 0;
      }
      td > p:first-child {
        color: #65c4c0;
        font-size: 14px;
        font-weight: bold;
        text-transform: capitalize;
      }
    }
  }

  .admin-mm-table {
    tbody > .user-row-mobile > td {
      padding: 10px 25px;
    }
  }

  .search-bar-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    .search-bar {
      width: 90%;
      padding: 0 10px;
      gap: 10px;
      border: 1px solid lightgrey;
      border-radius: 10px;
      input {
        background-color: transparent;
        height: 40px;
      }
    }
  }
}
