import React, { useEffect, useState } from "react";
import { exportUserData } from "src/utils/client";
import { Button, CheckBox, DropDown, FormInput, Image, ImageCarousel } from 'src/components/widgets';
import { capitalize, consecutiveArray, downloadImage, formatHeight, formatLocation, getRegion, shouldRenderLGBTLabel, shouldRenderPausedLabel, shouldRenderPolyLabel } from "src/utils/functions";
import LGBTQ from "src/images/lgbtq-plus.png";
import Paused from "src/images/Datespot_Paused.png"
import PolyOther from "src/images/poly-other.png";
import Select from "src/components/Select";
import { CLIENT_LEAD_STATUS } from "src/data/constants";
import { MARRIAGE_STATUSES, RELATIONSHIP_STATUSES } from "src/constants/onboarding-data";
import { APPRECIATION_OPTIONS } from "src/data/other-section";
import InputText from "_old/src/components/input-text";
import { DeletePhotosModal } from "../../DeletePhotosModal";
import { toast } from "react-toastify";
import EditIcon from "src/components/icons/EditIcon";
import { PlusIcon } from "src/components/icons/Plusicon";
import { MinusIcon } from "src/components/icons/MinusIcon";

export const AdminModalHeader = ({ activeUser, adminActions, generalActions }) => {
    const [openMenu, setOpenMenu] = useState(false);
    return (
        activeUser ? (
            <div className="member-header">
                <div className="member-header__left-container">
                    <div className="member-header__left-container__member-label">
                        <span>
                            {activeUser.name} | {`Member ${activeUser.user_id}`}
                        </span>
                    </div>
                </div>
                <div className='admin-actions-menu'>
                    <span className="icon-more" onClick={() => setOpenMenu(!openMenu)} />
                    <DropDown className="menu-dropdown" open={openMenu}>
                        <div className={`user-profile-action`}>
                            <span
                                className="content"
                                onClick={() => {
                                    exportUserData(activeUser._id);
                                }}
                            >
                                Export Member Data
                            </span>
                        </div>
                        <div className={`user-profile-action`}>
                            <span
                                className="content"
                                style={{ color: "rgba(84,88,90,0.3)" }}
                                onClick={() => {
                                    generalActions.closeUserInfoModal();
                                }}
                            >
                                Contact Member
                            </span>
                        </div>
                        <div className={`user-profile-action`}>
                            <span
                                className="content"
                                onClick={() => { }}
                                style={{ color: "rgba(84,88,90,0.3)" }}
                            >
                                Suggest to a Matchmaker
                            </span>
                        </div>
                        <div className={`user-profile-action`}>
                            <span
                                className="content"
                                onClick={() => { }}
                                style={{ color: "rgba(84,88,90,0.3)" }}
                            >
                                Deactivate Account
                            </span>
                        </div>
                        <div className={`user-profile-action`}>
                            <span
                                className="content"
                                onClick={() => {
                                    adminActions.inactivateUser(activeUser._id);
                                    generalActions.closeUserInfoModal();
                                }}
                            >
                                {" "}
                                Delete Member
                            </span>
                        </div>
                    </DropDown>
                </div>
            </div>
        ) : (
            <></>
        )
    )
}

export const AdminUserProfile = ({ user, adminActions }) => {
    return <>
        <div className="dashboard-user-profile">
            <div className="tags-container">
                {shouldRenderLGBTLabel(user) && (
                    <Image
                        src={LGBTQ}
                        alt="LGBTQ+ label"
                        className="tag"
                    />
                )}
                {shouldRenderPolyLabel(user) && (
                    <Image
                        src={PolyOther}
                        alt="PolyOther label"
                        className="tag"
                    />
                )}
                {shouldRenderPausedLabel(user) && (
                    <Image
                        src={Paused}
                        alt="Paused label"
                        className="tag"
                    />
                )}
            </div>
            <div className="profile-category">
                <div className="info-category">
                    <ProfilePictures user={user} adminActions={adminActions} />
                    <div className="flex-column">
                        <ContactInfo user={user} />
                        <TheBasics user={user} adminActions={adminActions} />
                    </div>
                </div>
            </div>
            <Notes user={user} adminActions={adminActions} />
            <History user={user} adminActions={adminActions} />
            <Background user={user} adminActions={adminActions} />
            <LifeStyle user={user} />
            <Family user={user} />
            <PartnerCriteria user={user} />
            <Other user={user} />
        </div>
    </>
}

const ProfilePictures = ({ user, adminActions }) => {
    const [openDeleteMenu, setOpenDeleteMenu] = useState(false)
    const images = user?.images?.filter((x) => x)
        .map((x) => x.url
            ? x.url
            : null
        ) ?? []

    const menuOptions = [
        {
            label: "Set as Profile",
            action: (imageId) => adminActions.setDeletePhotos(
                "set",
                user._id,
                imageId
            )
        },
        {
            label: "Rotate 90°",
            action: (imageId) => adminActions.setDeletePhotos(
                "rotate",
                user._id,
                imageId
            )
        },
        {
            label: "Delete",
            action: (imageId) => setOpenDeleteMenu(true)
        },
        {
            label: "Download",
            action: (imageId) => downloadImage(images[imageId], `${user.name}_${imageId}`)
        }
    ]

    const deletePhotos = (photos) => {
        try {
            adminActions.deletePhotos(
                user._id,
                photos
            );
            setOpenDeleteMenu(false);
            toast.success(photos.length + " Photo(s) Deleted, reload to see changes");
        } catch (e) {
            toast.error(e);
        }
    };
    return images.length ? (
        <>
            <DeletePhotosModal
                showModal={openDeleteMenu}
                user={user}
                onClose={() => setOpenDeleteMenu(false)}
                onDelete={deletePhotos}
            />
            <ImageCarousel
                imageClass="user-image"
                images={images}
                optionsMenu={menuOptions}
            />
        </>
    ) : <></>
}

const ContactInfo = ({ user }) => {
    return <div className="info-container">
        <h3>Contact Info</h3>
        <div className="profile-field">
            <h4>Phone Number</h4>{user.phone ?? "--"}
        </div>
        <div className="profile-field">
            <h4>Email Address</h4>{user.email ?? "--"}
        </div>
    </div>
}

const TheBasics = ({ user, adminActions }) => {
    const [memberType, setMemberType] = useState(user.clientStatus)
    const [rating, setRating] = useState({
        openMenu: false,
        rating: user.userRatingByMe
    })

    const handleRatingChange = (rating) => {
        setRating({ openMenu: false, rating })
        adminActions.AdminRateSelectedUser(user._id, rating)
    }

    const handleClientLeadStatusChange = (status) => {
        if (status === "No (Candidate Only)" || status === "Former Client") {
            adminActions.saveUserFieldDataFromAdmin(user._id, "clientStatus", "candidate");
            setMemberType("candidate");
        } else if (status === "Current Client" || status === "Yes") {
            adminActions.saveUserFieldDataFromAdmin(user._id, "clientStatus", "client");
            setMemberType("client");
        }
        adminActions.saveUserFieldDataFromAdmin(user._id, "clientLead", status);
    };

    useEffect(() => {
        const listener = document.addEventListener("click", (e) => {

            if (!e.target.closest(".range-tooltip") && !e.target.closest(".user-rating")) {

                setRating(prev => ({ ...prev, openMenu: false }))
            }
        })

        return () => {
            document.removeEventListener("click", listener)
        }
    }, [])

    return <div className="info-container">
        <h3>The Basics</h3>
        <div className="profile-field" >
            <h4>Gender</h4>
            {user["gender"]?.value}
        </div>
        <div className="profile-field" >
            <h4>Interested in</h4>
            {user["interestedIn"]?.map(field => field.value).join(",")}
        </div>
        <div className="profile-field" style={{ position: "relative" }}>
            <h4>Attractiveness</h4>
            <div>Rating Average: {user["userRating"] ?? "--"}</div>
            <div>Your Rating: {rating.rating ?? "--"}</div>
            <div
                className="user-rating"
                onClick={() => {
                    setRating(prev => ({ ...prev, openMenu: !prev.openMenu }))
                }}
            >
                {rating.rating ? "Change rating" : "Add rating"}
            </div>
            {
                rating.openMenu && (
                    <div className="range range-tooltip">
                        <Range
                            data={consecutiveArray(10, 1)}
                            value={rating.rating ?? 0}
                            onClick={(rate) => handleRatingChange(rate)} />
                    </div>
                )
            }
        </div>
        <div className="profile-field" >
            <h4>Has Children</h4>
            {user["hasKids"]?.value ?? "--"}
        </div>
        <div className="profile-field" >
            <h4>Age</h4>
            {user["age"]}
        </div>
        <div className="profile-field" >
            <h4>Children's Ages (+/- 1 year)</h4>
            {Object.values(user["kids"].kidsAges).length ? `${Object.values(user["kids"].kidsAges).join(" & ")} years old` : "--"}
        </div>
        <div className="profile-field" >
            <h4>Height</h4>
            {formatHeight(user["height"])}
        </div>
        <div className="profile-field" >
            <h4>Future Child Scenarios Open To</h4>
            {user["futureChildScenarios"]?.map(field => field.value).join(",")}
        </div>
        <div className="profile-field" >
            <h4>Body Type</h4>
            {user["bodyTypes"]?.map(field => field.value).join(",")}
        </div>
        <div className="profile-field" >
            <h4>Location</h4>
            <div>
                <p>
                    {formatLocation(user)}
                </p>
                {getRegion(user) && (
                    <p>Region: {getRegion(user)}</p>
                )}
            </div>
        </div>
        <div className="profile-field" >
            <h4>Ethnicity</h4>
            {user["ethnicity"]?.map(field => field.value).join(",")}
        </div>
        <div className="profile-field" >
            <h4>Religion</h4>
            {user["religionPracticed"]?.map(field => field.value).join(",")}
        </div>
        <div className="profile-field" >
            <h4>Client Lead?</h4>
            <Select
                id="clientLead-status-select"
                fullWidth={false}
                defaultValue={user.clientLead}
                options={CLIENT_LEAD_STATUS}
                onChange={handleClientLeadStatusChange}
                expandable={!!CLIENT_LEAD_STATUS.length}
                placeholder="Select Status"
            />
        </div>
        <div className="profile-field" >
            <h4>Member Type</h4>
            {capitalize(memberType)}
        </div>
    </div>
}

const Background = ({ user, adminActions }) => {
    const handleMarriageStatusChange = (status) => {
        const chosenOption = MARRIAGE_STATUSES.find((item) => item.value === status)
        console.log(chosenOption)
        adminActions.saveUserFieldDataFromAdmin(user._id, "marriageStatus", chosenOption);

        if (status === "Engaged/married") {
            adminActions.saveUserFieldDataFromAdmin(user._id, "relationshipStatus", {
                value: "Not Single",
                label: "Not Single",
            });
            adminActions.saveUserFieldDataFromAdmin(user._id, "accountStatus", "inactive");
        } else if (user.accountStatus === "inactive") {
            adminActions.saveUserFieldDataFromAdmin(user._id, "accountStatus", "active");
        }
    };

    const marriageOptions = MARRIAGE_STATUSES.map((item) => item.value)
    return <div className="profile-category">
        <div className="info-category">
            <div className="info-container">
                <h3>Background</h3>
                <div className="profile-field">
                    <h4>Marriage Status</h4>
                    <Select
                        id="relationship-status-select"
                        fullWidth={false}
                        defaultValue={user.marriageStatus?.value ?? MARRIAGE_STATUSES[0].value}
                        options={marriageOptions}
                        onChange={handleMarriageStatusChange}
                        expandable={!!marriageOptions.length}
                        placeholder="Select Status"
                    />
                </div>
                <div className="profile-field">
                    <h4>Education</h4>
                    {user["education"]?.label ?? "--"}
                </div>
                <div className="profile-field">
                    <h4>Employment Status</h4>
                    {user["employmentStatus"]?.label ?? "--"}
                </div>
                <div className="profile-field">
                    <h4>Occupation</h4>
                    {user["occupation"] ?? "--"}
                </div>
                <div className="profile-field">
                    <h4>School Attended</h4>
                    {user["schoolAttended"] ?? "--"}
                </div>
                <div className="profile-field">
                    <h4>Company/ Org</h4>
                    {user["company"] ?? "--"}
                </div>
                <div className="profile-field">
                    <h4>Where are you from?</h4>
                    {user["upbringing"] ?? "--"}
                </div>
            </div>
        </div>
    </div>
}
const LifeStyle = ({ user }) => {
    return <div className="profile-category">
        <div className="info-category">
            <div className="info-container">
                <h3>Lifestyle</h3>
                <div className="profile-field" >
                    <h4>Drinking</h4>
                    {user["drinking"]?.value ?? "--"}
                </div>
                <div className="profile-field" >
                    <h4>Eating Habits</h4>
                    {user["eatingHabits"]?.map(habit => habit?.value).join(", ")}
                </div>
                <div className="profile-field" >
                    <h4>Pets</h4>
                    {user["pets"] ? Object.keys(user["pets"]).map(pet => <div key={pet}>{capitalize(pet)}: {user["pets"][pet]?.value}</div>) : <div>--</div>}
                </div>
                <div className="profile-field" >
                    <h4>Smoking</h4>
                    {user["smoking"]?.value ?? "--"}
                </div>
                <div className="profile-field" >
                    <h4>Politics</h4>
                    {user["politicalOrientation"]?.value ?? "--"}
                </div>
                <div className="profile-field" >
                    <h4>Fitness Level</h4>
                    {user["workout"]?.value ?? "--"}
                </div>
                <div className="profile-field" >
                    <h4>Drugs</h4>
                    <RenderDrugs does_drugs={user["does_drugs"]} isPartner={false} />
                </div>
                <div className="profile-field" >
                    <h4>Do you have any tattoos?</h4>
                    {user["tattoos"]?.value ?? "--"}
                </div>
                <div className="profile-field" >
                    <h4>Music Genres</h4>
                    {user["music"]["genres"]?.map(genre => genre?.value).join(", ") ?? "--"}
                </div>
                <div className="profile-field" >
                    <h4>Hobbies</h4>
                    {user["hobbies"]?.map(hobbie => hobbie?.value).join(", ") ?? "--"}
                </div>
                <div className="profile-field" >
                    <h4>Passionate About</h4>
                    {user["passionateAbout"] ?? "--"}
                </div>
                <div className="profile-field range" >
                    <h4>Financial Stability</h4>
                    <Range data={consecutiveArray(5, 1)} value={user["financialStability"]?.value ?? 0} maxLabel={"High"} minLabel={"Low"} />
                </div>
                <div className="profile-field" >
                    <h4>Average yearly income</h4>
                    {user["yearlyIncome"]?.label ?? "--"}
                </div>
                <div className="profile-field" >
                    <h4>Net worth estimate</h4>
                    {user["netWorthEstimate"]?.label ?? "--"}
                </div>
                <div className="profile-field" >
                    <h4>How important is travel to you?</h4>
                    {user["travel"]["personalImportance"]?.value ?? "--"}
                </div>
                <div className="profile-field" >
                    <h4>What's your international travel experience?</h4>
                    {user["travel"]["personalExperience"]?.value ?? "--"}
                </div>
                <div className="profile-field" >
                    <h4>Feel free to elaborate on any travel or living abroad</h4>
                    {user["travel"]["personalComments"] ?? "--"}
                </div>
            </div>
        </div>
    </div>
}
const Family = ({ user }) => {
    return <div className="profile-category">
        <div className="info-category">
            <div className="info-container">
                <h3>Family</h3>
                <div className="profile-field" >
                    <h4>Have you frozen any eggs/sperm?</h4>
                    {user["personalFrozenEggsOrSperm"]?.value ?? "--"}
                </div>
                <div className="profile-field" >
                    <h4>Any other details about kids?</h4>
                    {user["kidsAnythingElse"] ?? "--"}
                </div>
                <div className="profile-field" >
                    <h4>Describe your family & upbringing</h4>
                    {user["familyAndUpbringing"] ?? "--"}
                </div>
                <div className="profile-field" >
                    <h4>Are you (or were you) close with the family you grew up with?</h4>
                    {user["familyCloseness"]?.value ?? "--"}
                </div>
                <div className="profile-field" >
                    <h4>Should you loan money to family?</h4>
                    {user["familyLoan"]?.value ?? "--"}
                </div>
            </div>
        </div>
    </div>
}
const PartnerCriteria = ({ user }) => {
    return <div className="profile-category">
        <div className="info-category">
            <div className="info-container">
                <h3>Partner Criteria</h3>
                <div className="profile-field" >
                    <h4>Relationship Type(s)</h4>
                    {user["relationshipType"] ? user["relationshipType"].map(rel => rel.label).join(", ") : "--"}
                </div>
                <div className="profile-field" >
                    <h4>Height</h4>
                    {user["heightPreference"]?.value && user["heightPreference"]?.value.height
                        ? `${user["heightPreference"].value.height} or ${user["heightPreference"].value.attribute.label}`
                        : "No preference"}
                </div>
                <div className="profile-field" >
                    <h4>Age range</h4>
                    {user["ageRange"] ?? "--"}
                </div>
                <div className="profile-field" >
                    <h4>Ethnicities</h4>
                    {user["ethnicityRequired"] ? user["ethnicityRequired"].map(rel => rel.label).join(", ") : "--"}
                </div>
                <div className="profile-field range" >
                    <h4>Religious alignment</h4>
                    <Range data={consecutiveArray(5, 1)} value={user["religionPreference"]?.value ?? 0} maxLabel={"Not important"} minLabel={"Very important"} />
                </div>
                <div className="profile-field range" >
                    <h4>Political alignment</h4>
                    <Range data={consecutiveArray(5, 1)} value={user["politicalPreference"]?.value ?? 0} maxLabel={"Not important"} minLabel={"Very important"} />
                </div>
                <div className="profile-field" >
                    <h4>Drugs</h4>
                    <RenderDrugs does_drugs={user["does_drugs"]} isPartner={true} />
                </div>
                <div className="profile-field" >
                    <h4>Could you be with someone who has a tattoo?</h4>
                    {user["partnerTattoos"]?.value ?? "--"}
                </div>
                <div className="profile-field" >
                    <h4>Open to relocation</h4>
                    {user["relocationOptions"]?.value ?? "--"}
                </div>
                <div className="profile-field" >
                    <h4>Match areas</h4>
                    {user["matchArea"] ? user["matchArea"].map(area => area.label).join(", ") : "--"}
                </div>
                <div className="profile-field" >
                    <h4>Match area details</h4>
                    {user["matchAreaDetails"] ?? "--"}
                </div>
                <div className="profile-field" >
                    <h4>Looking for in a partner</h4>
                    {user["partnerPreferences"] ?? "--"}
                </div>
            </div>
        </div>
    </div>
}
const Other = ({ user }) => {
    return <div className="profile-category">
        <div className="info-category">
            <div className="info-container">
                <h3>Other</h3>
                <div className="profile-field" >
                    <h4>Of the 5 Love Languages, how do you naturally GIVE appreciation?</h4>
                    {user["other"]["giveAppreciation"] ? Object.keys(user["other"]["giveAppreciation"])
                        .map(
                            (dataKey) =>
                                `${APPRECIATION_OPTIONS.find((option) => option.key === dataKey)
                                    .label
                                } (${user["other"]["receiveAppreciation"][dataKey]})`
                        )
                        .join(", ") : "--"}
                </div>
                <div className="profile-field" >
                    <h4>Of the 5 Love Languages, how do you like to RECEIVE appreciation?</h4>
                    {user["other"]["receiveAppreciation"] ? Object.keys(user["other"]["receiveAppreciation"])
                        .map(
                            (dataKey) =>
                                `${APPRECIATION_OPTIONS.find((option) => option.key === dataKey)
                                    .label
                                } (${user["other"]["receiveAppreciation"][dataKey] ?? ""})`
                        )
                        .join(", ") : "--"}
                </div>
                <div className="profile-field" >
                    <h4>Longest relationship duration</h4>
                    {user["longestRelationship"]?.label ?? "--"}
                </div>
                <div className="profile-field" >
                    <h4>Languages spoken</h4>
                    {user["other"]["languages"] ? user["other"]["languages"].map(lang => lang.language).join(", ") : "--"}
                </div>
                <div className="profile-field" >
                    <h4>Descriptors (from list)</h4>
                    {user["other"]["qualities"] ? user["other"]["qualities"].map(q => q.label).join(", ") : "--"}
                </div>
                <div className="profile-field" >
                    <h4>Habits to work on</h4>
                    {user["other"]["habitsToWorkOn"] ? user["other"]["habitsToWorkOn"].map(habit => habit.label).join(", ") : "--"}
                </div>
                <div className="profile-field" >
                    <h4>Dealbreaker traits (from list)</h4>
                    {user["other"]["dealbreakers"] ? user["other"]["dealbreakers"].map(dealbr => dealbr.label) : "--"}
                </div>
                <div className="profile-field" >
                    <h4>Open to blind dates</h4>
                    {user["other"]["blindDates"]?.label ?? "--"}
                </div>
                <div className="profile-field" >
                    <h4>Anything else?</h4>
                    {user["other"]["anythingElse"] ?? "--"}
                </div>
            </div>
        </div>
    </div>
}
const History = ({ user, adminActions }) => {
    const [state, setState] = useState({
        suspicionProfile: user.suspicion?.profile ?? false,
        suspicionEmail: user.suspicion?.email ?? false,
        verifiedProfile: user.verification?.verified ?? false,
        unresponsive: user.unresponsive ?? false,
        verifiedBy: user.verification?.verified_by ?? "",
        dateMet: user.verification?.date_verified ?? "",
    })

    const { suspicionProfile, suspicionEmail, verifiedProfile, unresponsive, verifiedBy, dateMet } = state

    const handleFieldChange = (field, value) => {
        setState(prev => ({ ...prev, [field]: value }));
    };

    const relationStatuses = RELATIONSHIP_STATUSES.map((item) => item.value)

    const handleRelationshipStatusChange = (status) => {
        const userStatus = RELATIONSHIP_STATUSES.find(
            (item) => item.value === status
        );
        let newStatus = {
            ...userStatus,
            date: new Date(),
        };

        adminActions.saveUserFieldDataFromAdmin(user._id, "relationshipStatus", newStatus);
    };

    return <div className="profile-category">
        <div className="info-category">
            <div className="info-container">
                <h3>Datespot History</h3>
                <div className="profile-field" >
                    <h4>Signup Date</h4>
                    {new Date(user["createdAt"]).toLocaleDateString()}
                </div>
                <div className="profile-field" >
                    <h4>Referral Source</h4>
                    {user["referralSource"] ? user["referralSource"].map(source => source.label).join(", ") : "--"}
                </div>
                <div className="profile-field" >
                    <h4>Last Known Relationship Status</h4>
                    <Select
                        id="relationship-status-select"
                        fullWidth={false}
                        defaultValue={user["relationshipStatus"]?.value ?? relationStatuses[0]}
                        options={relationStatuses}
                        onChange={handleRelationshipStatusChange}
                        expandable={!!relationStatuses.length}
                        placeholder="Select Status"
                    />
                    <div>
                        {!!user.relationshipStatus?.date && (
                            <span className="date-ago">
                                as of{" "}
                                {user.relationshipStatus.date &&
                                    new Intl.DateTimeFormat("en-US", {
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric",
                                    }).format(
                                        new Date(user.relationshipStatus.date)
                                    )}
                            </span>
                        )}
                        {/* if no date */}
                        {!user.relationshipStatus?.date && (
                            <span className="date-ago">as of Today</span>
                        )}
                    </div>
                </div>
                <div className="profile-field" >
                    <h4>Contact Requests</h4>
                    {user.connectionsRequests
                        ? user.connectionsRequests.length
                        : "--"}
                </div>
                <div className="profile-field" >
                    <h4>Contact Requests Responded to</h4>
                    {user["requestsResponded"] ?? "--"}
                </div>
                <div className="profile-field" >
                    <h4>Number of dates through DateSpot</h4>
                    {user["requestsResponded"] ?? "--"}
                </div>
                <div className="profile-field" >
                    <label className="suspicion">
                        <CheckBox
                            checked={suspicionProfile}
                            onChange={() => {
                                adminActions.saveUserFieldDataFromAdmin(
                                    user._id,
                                    "suspicion.profile",
                                    !suspicionProfile
                                );
                                handleFieldChange("suspicionProfile", !suspicionProfile);
                            }}
                        />
                        Suspicious Account
                    </label>
                    <label className="suspicion">
                        <CheckBox
                            checked={verifiedProfile}
                            onChange={() => {
                                adminActions.saveUserFieldDataFromAdmin(
                                    user._id,
                                    "verification.verified",
                                    !verifiedProfile
                                );
                                handleFieldChange("verifiedProfile", !verifiedProfile);
                            }}
                        />
                        Verified Profile
                    </label>
                </div>
                <div className="profile-field" >
                    <label className="suspicion">
                        <CheckBox
                            checked={unresponsive}
                            onChange={() => {
                                adminActions.saveUserFieldDataFromAdmin(
                                    user._id,
                                    "unresponsive",
                                    !unresponsive
                                );
                                handleFieldChange("unresponsive", !unresponsive);
                            }}
                        />
                        Unresponsive
                    </label>
                    <InputText
                        wrapperClassName="met-with-wrapper suspicion"
                        classNameForLabel="suspicion-label"
                        label="Met with: "
                        disabled={!verifiedProfile}
                        showLabel={true}
                        value={verifiedBy}
                        onChange={(e) => {
                            handleFieldChange("verifiedBy", e.target.value);
                        }}
                        onBlur={() => {
                            adminActions.saveUserFieldDataFromAdmin(
                                user._id,
                                "verification.verified_by",
                                verifiedBy
                            );
                        }}
                    />
                </div>
                <div className="profile-field" >
                    <label className="suspicion">
                        <CheckBox
                            checked={suspicionEmail}
                            onChange={() => {
                                adminActions.saveUserFieldDataFromAdmin(
                                    user._id,
                                    "suspicion.email",
                                    !suspicionEmail
                                );
                                handleFieldChange("suspicionEmail", !suspicionEmail);
                            }}
                        />
                        Bounced Email
                    </label>
                    <InputText
                        wrapperClassName="met-with-wrapper suspicion"
                        classNameForLabel="suspicion-label"
                        placeholder="mm/dd/yyyy"
                        label="Date Met: "
                        disabled={!verifiedProfile}
                        showLabel={true}
                        value={verifiedBy}
                        onChange={(e) => {
                            handleFieldChange("dateMet", e.target.value);
                        }}
                        onBlur={() => {
                            adminActions.saveUserFieldDataFromAdmin(
                                user._id,
                                "verification.date_verified",
                                dateMet
                            );
                        }}
                    />
                </div>
            </div>
        </div>
    </div>
}

const Notes = ({ user, adminActions }) => {
    const [createMessage, setCreateMessage] = useState(false)
    const [message, setMessage] = useState("");

    const handleMessage = (e) => {
        setMessage(e.target.value);
    }

    useEffect(() => {
        adminActions.getUserNotes(user._id);
    }, [])

    const handleSendNote = () => {
        adminActions.createNote(user._id, message)
        setMessage("")
    }
    return <div className="profile-category">
        <div className="info-category">
            <div className="info-container">
                <h3 style={{ display: "flex", gap: 5, alignItems: "center" }}>
                    Notes
                    <button className="button yellow edit-trigger" onClick={() => setCreateMessage(!createMessage)}>{
                        createMessage
                            ? <MinusIcon width={10} height={10} />
                            : <PlusIcon width={10} height={10} />
                    }</button>
                </h3>
                {createMessage && <div style={{ gridColumn: "span 3", display: "flex", justifyContent: "flex-end", gap: 15, flexWrap: "wrap" }}>
                    <div className="form-input__container">
                        <textarea className="form-input__root note-input" value={message} onChange={handleMessage} />
                    </div>
                    <Button
                        variant="teal"
                        style={{ margin: 0 }}
                        disabled={message === ""}
                        onClick={handleSendNote}
                    >
                        Add Note
                    </Button>

                </div>}
                <div className="notes" >
                    {user.notes && user.notes.length
                        ? user.notes.map((note, index) => {
                            return <Note key={index} note={note} adminActions={adminActions} />

                        })
                        : <div className="note">No notes</div>
                    }
                </div>
            </div>
        </div>
    </div>

}

const Range = ({ data, value, minLabel, maxLabel, onClick = () => { } }) => {
    return (
        <div className="range">
            <label>{minLabel}</label>
            <div className="range-values">
                {data.map((x) => {
                    return (
                        <div
                            className={`range-value ${value === x ? "active" : ""
                            }`}
                            key={`range-value-${x}`}
                            onClick={() => onClick(x)}
                        >
                            {x}
                        </div>
                    );
                })}
            </div>
            <label>{maxLabel}</label>
        </div>
    );
};
const RenderDrugs = ({ does_drugs, isPartner }) => {
    function getLabel(type) {
        switch (type) {
            case "marijuana":
                return "Marijuana";
            case "psychedelics":
                return "Psychedelics";
            case "cocaine":
                return "Cocaine";
            case "mdma":
                return "MDMA";
            case "ketamine":
                return "Ketamine";
            default:
                return type;
        }
    }

    if (!does_drugs) return <div>--</div>

    const type = isPartner ? "partner_drugs" : "drugs"
    const drugTypes = isPartner ? "partner" : "types"
    const question = isPartner ? "How often are you open to your partner doing drugs?" : "How often do you do any drugs?"

    return (
        <div>
            {does_drugs[type]?.value ? <div>{question} {does_drugs[type].value}</div> : "--"}
            {
                does_drugs[drugTypes] && (
                    <div>
                        {Object.keys(does_drugs[drugTypes]).map((type, idx) => {
                            if (
                                does_drugs[drugTypes][type]?.value &&
                                type !== "other"
                            ) {
                                return (
                                    <div key={`drugs-${type}-${idx}`}>
                                        {getLabel(type)}: {does_drugs[drugTypes][type].value}
                                    </div>
                                );
                            }
                            else if (
                                type === "other" &&
                                does_drugs[drugTypes][type].value
                            ) {
                                const freq = does_drugs[drugTypes][type].frequency;
                                const value = does_drugs[drugTypes][type].value;
                                if(!freq || !value) return ""
                                if (!Object.keys(freq).length || !Object.keys(value).length) return "";

                                return (
                                    <div key={`drugs-${type}-${idx}`}>
                                        {value ? value : ""}{" "}
                                        {value ? (freq ? `: ${freq.value}` : "") : ""}
                                    </div>
                                );
                            }
                            else {
                                return "";
                            }
                        })}
                    </div>)
            }
        </div>)
}

const Note = ({ note, adminActions }) => {
    const [update, setUpdate] = useState(false)
    const [message, setMessage] = useState(note.note)

    const handleMessage = (e) => {
        setMessage(e.target.value);
    }

    const handleUpdateNote = () => {
        adminActions.updateNote(note._id, message)
        setUpdate(false)
    }

    const cancelOperation = () => {
        setUpdate(false)
        setMessage(note.note)
    }

    return <div className="note">
        {!update ? <>
                <p>{note.note}</p>
                <div style={{ display: "flex", alignItems: "end", justifyContent: "flex-end", gap: 5 }}>
                    Last edit: {new Date(note.updatedAt).toLocaleDateString()}
                    <EditIcon size={22} style={{ cursor: "pointer" }} onClick={() => setUpdate(true)} />
                </div>
            </>
            : <>
                <div className="form-input__container">
                    <textarea className="form-input__root note-input edit-input" value={message} onChange={handleMessage} />
                </div>
                <div style={{ display: "flex", alignItems: "end", justifyContent: "flex-end", gap: 5, marginTop: 10 }}>
                    <Button

                        style={{ width: "160px", height: "35px" }}
                        onClick={cancelOperation}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="teal"
                        disabled={message === note.note}
                        style={{ width: "160px", height: "35px" }}
                        onClick={handleUpdateNote}
                    >
                        Update Note
                    </Button>
                </div>
            </>
        }
    </div >
}

