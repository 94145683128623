@import "../../../../scss/utilities";
@import "../../../../scss/variables";

.sidebar {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  background: white;
  box-shadow: 0 0 10px 0 rgba(84, 88, 90, 0.2);
  height: 100%;
  .active {
    background: #013b4d;
    * {
      color: white !important;
    }
  }
  .filter-button {
    padding: 16px;
    display: flex;
    justify-content: center;
  }
  button {
    background: #eee;
    border: 0;
    padding: 20px 10px;
    margin: 3px 0;
    display: flex;
    font-size: 14px;
    .fa {
      width: 25px;
      font-size: 20px;
    }
    .text {
      text-align: left;
    }
    &:focus {
      outline: none;
    }

    .text {
      flex: 1;
      justify-content: center;
      margin: 0 10px;
    }
  }
  .saved-filters {
    h4 {
      text-align: center;
      margin-top: 40px;
    }
  }
  .save-filters {
    margin-top: auto;
    button {
      width: 100%;
      background: $teal;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      text-transform: uppercase;
      font-weight: 800;
      font-size: 0.85em;
    }
  }

  .search-bar-container {
    .search-bar {
      width: 90%;
      padding: 0 10px;
      border-bottom: 1px solid #eee;
      gap: 10px;

    }
  }
}

.tools {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  .search-box {
    input {
      padding: 10px;
    }
  }
  .filters {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .filter-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 10px;
  }
}
.filter-btn {
  background-color: #ffd758;
  color: #54585a;
  box-shadow: none;
  margin-top: 0em;
  padding: 0 40px;
  width: 220px;
  height: 2.5em;
  min-width: 145px;
  outline: none;
  border: 2px solid $yellow;
  z-index: 3;
  font-size: 14px;
  font-weight: 800;
  min-height: 44px;
}
.clear-filters {
  background-color: #eee;
  color: $textPrimary;
  box-shadow: none;
  margin-top: 10px;
  margin-left: -7px;
  padding: 0 30px;
  width: 220px;
  height: 2.5em;
  min-width: 145px;
  outline: none;
  border: 2px solid #eee;
  z-index: 3;
  font-size: 14px;
  font-weight: 800;
  min-height: 44px;
}