import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as generalActions from "../../actions/generalActions";
import Button from "../../components/button";
import { elements, REPEAT_SECTION_TWO } from "./texts.js";
import { repeatingSectionTwo } from "./components";
import Slider from "../../containers/slider";
import { MEMBER_PHOTOS_SLIDER } from "../../constants/members-photos";

import "./style.scss";

class HomePage extends Component {
  state = {
    loadSlider: false,
  };
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    window.scrollTo(0, 0);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll = (event) => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollTop > 1000) {
      this.setState({ loadSlider: true });
    }
  };
  render() {
    const renderSlider = this._renderSlider();
    const renderValues = this._renderValues();
    const { history } = this.props;
    return (
      <div className="home-page-content">
        <div className="section-two flex-column flex-vertical-center">
          {repeatingSectionTwo(
            REPEAT_SECTION_TWO.homePageFirst.title,
            REPEAT_SECTION_TWO.homePageFirst.text,
            null,
            null,
            null,
            null
          )}
        </div>
        <div className="section-three flex-column flex-vertical-center grey-background">
          {repeatingSectionTwo(
            REPEAT_SECTION_TWO.homePageSecond.title,
            REPEAT_SECTION_TWO.homePageSecond.text,
            REPEAT_SECTION_TWO.homePageSecond.secondText,
            REPEAT_SECTION_TWO.homePageSecond.thirdText,
            true,
            true
          )}
        </div>

        {renderSlider}

        {renderValues}

        <div className="section-six homepage-section-six">
          <div style={{ padding: "200px 0 0 0" }}>
            <div className="section-title">
              <span> Ready to get started? </span>
            </div>
            <Button
              click={() => history.push({ pathname: "/welcome-singles" })}
              value="GET MATCHED FREE"
              customClass="yellow large"
            />
          </div>
        </div>
        <div className="section-seven flex-center">
          <div className="section-seven__content flex-column">
            {repeatingSectionTwo(
              REPEAT_SECTION_TWO.homePageThird.title,
              REPEAT_SECTION_TWO.homePageThird.text,
              REPEAT_SECTION_TWO.homePageThird.secondText,
              true,
              true
            )}
            <Button
              click={() => history.push({ pathname: "/for-matchmakers" })}
              value="LEARN MORE..."
              customClass="large extra-padding"
            />
          </div>
        </div>
      </div>
    );
  }

  _renderSlider = () => {
    const { loadSlider } = this.state;
    return (
      <div className="section-four">
        <div className="section-two flex-column flex-vertical-center">
          {repeatingSectionTwo(
            REPEAT_SECTION_TWO.homePageFive.title,
            REPEAT_SECTION_TWO.homePageFive.text,
            null,
            null,
            null,
            null
          )}
        </div>
        <div className="section-four__content flex-column">
          <span className="example-members" />
          {loadSlider && (
            <Slider
              photos={MEMBER_PHOTOS_SLIDER}
              padding={{ paddingLeft: 100, paddingRight: 100 }}
            />
          )}
        </div>
      </div>
    );
  };

  _renderValues = () => {
    return (
      <div className="section-five flex-center">
        <div className="section-five__content flex-column flex-center">
          {repeatingSectionTwo(
            REPEAT_SECTION_TWO.homePageFourth.title,
            null,
            null,
            null,
            null
          )}
          {elements.map((el, e) => {
            return (
              <div
                key={e}
                className={`section-five__item flex-row ${el.title.toLowerCase()}`}
                style={{ background: el.background }}
              >
                <div className="section-five__item__image">
                  <img src={el.image} />
                </div>
                <div className="section-five__item__right flex-column">
                  <span className="title">{el.title}</span>
                  <span className="text one">{el.text1}</span>
                  <span className="text">{el.text2}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
}

HomePage.propTypes = {
  history: PropTypes.object,
  generalActions: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return {
    generalActions: bindActionCreators(generalActions, dispatch),
  };
};

const mapStateToProps = (state) => {
  return {
    generalReducer: state.generalReducer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
