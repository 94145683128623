@import ".././../../scss/variables.scss";
@import ".././../../scss/utilities.scss";
.saved-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    text-transform: capitalize;
    flex: 2;
  }
}

.toggle-container {
  padding: 4px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: rgb(238, 238, 238);
  flex: 1;

  button.toggle-btn {
    border-radius: 14px;
    margin: 0 !important;
    font-size: 16px !important;
    font-weight: 600;
    justify-content: center;
    padding: 10px 0 !important;
    flex: 1;
    background-color: transparent;
    width: 100%;
    border: none;
  }

  .active-toggle {
    background-color: $lightYellow !important;
  }
}

.saved-filters-modal {
  @include for-desktop {
    max-width: unset !important;
    min-width: unset !important;
    width: 50vw;
  }
  .dashboard-modal-header {
    background-color: $blue;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
  }
}
