import React from "react";
import PropTypes from "prop-types";
import "./BulletCheckList.scss";

const BulletCheckList = ({ items }) => (
  <ul className="bullet-check-list">
    {items.map((item, index) => (
      <li className="bullet-check-list__item" key={`${item}_${index}`}>
        <div className="bullet-check-list__icon-container">
          <i className="fas fa-check" />
        </div>
        <span className="bullet-check-list__text">{item}</span>
      </li>
    ))}
  </ul>
);

BulletCheckList.propTypes = {
  items: PropTypes.array,
};

export default BulletCheckList;
