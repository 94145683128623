import React, { useEffect } from "react";
import { apiPost, getGlobal, setGlobal } from "launchpad";
import memberSchema from "./member-data";
import { memberAttributes, getAttributeRatings } from "/../global_data";
import { useSetState } from "launchpad/helpers";
import Checkbox from "@material-ui/core/Checkbox";
import { ImageCarousel } from "widgets";
import Modal from "../../Modal/Modal";
import { APPRECIATION_OPTIONS } from "src/data/other-section";
import { downloadImage, fromNow, shouldRenderLGBTLabel, shouldRenderPausedLabel, shouldRenderPolyLabel } from "src/utils/functions";
import LGBTQ from "src/images/lgbtq-plus.png";
import Paused from "src/images/Datespot_Paused.png"
import PolyOther from "src/images/poly-other.png";
import { Image } from "src/components/widgets";

export const UserDescriptionForm = ({ user, onSubmit }) => {
  const [state, setState] = useSetState({
    descriptions: {},
    comment: "",
  });
  const { descriptions, comment } = state;

  const mm = getGlobal("activeUser");

  useEffect(() => {
    const userDescriptions = user.matchmakerDescriptions || [];
    const existing = userDescriptions.find((x) => x.mmID === mm._id);
    if (existing) {
      setState({
        descriptions: existing.descriptions,
        comment: existing.comment,
      });
    }
  }, []);

  const submit = async () => {
    const d = { mmID: mm._id, descriptions, comment };
    await apiPost(`/update-descriptions/${mm._id}/${user._id}`, d);
    setGlobal({ usersTableNeedsRefresh: true });
    if (onSubmit) onSubmit();
  };

  return (
      <div className="member-description-form">
        <div className="wrapper">
          <div className="attributes">
            {memberAttributes.map((x) => (
                <div key={x} className="attribute-box">
                  <h4>{x}</h4>

                  <label>
                    <Checkbox
                        checked={descriptions[x] === false}
                        onChange={(e) =>
                            setState({
                              descriptions: {
                                ...descriptions,
                                ...{ [x]: e.target.checked ? false : undefined },
                              },
                            })
                        }
                    />{" "}
                    No
                  </label>

                  <label>
                    <Checkbox
                        checked={descriptions[x] === true}
                        onChange={(e) =>
                            setState({
                              descriptions: {
                                ...descriptions,
                                ...{ [x]: e.target.checked ? true : undefined },
                              },
                            })
                        }
                    />{" "}
                    Yes
                  </label>
                </div>
            ))}
          </div>
          <div className="comment">
            <h4>Comments</h4>
            <textarea
                value={comment}
                onChange={(e) => setState({ comment: e.target.value })}
            />
          </div>
        </div>
        <button className="btn" onClick={submit}>
          Submit
        </button>
      </div>
  );
};

export default function UserProfile({ user }) {
  const mm =
      getGlobal("activeUser") &&
      getGlobal("activeUser").role === "matchmaker" &&
      getGlobal("activeUser");

  const [showRatings, setShowRatings] = React.useState(false);

  const ratings = getAttributeRatings(user);

  const descriptions = user.matchmakerDescriptions || [];
  const reviewed =
      mm && descriptions && descriptions.find((x) => x.mmID === mm._id);

  const getHeight = (inches) => {
    inches = parseInt(inches);
    const inch = inches % 12;
    const feet = Math.floor(inches / 12);
    return `${feet}'${inch}"`;
  };

  const validHeight = (inches) => {
    if (typeof inches === "string") {
      return !inches.includes("ft");
    } else {
      return Number.isFinite(parseInt(inches));
    }
  };

  const getUserFieldValue = (field, user) => {
    const splittedField = field.split(".");
    let value;

    splittedField.forEach((field, idx) => {
      if (idx === 0) {
        value = user[field];
      } else {
        if (!value) return;
        value = value[field];
      }
    });

    if (!value) return null;
    if (typeof value === "object" && Object.keys(value).length === 0)
      return null;
    if (typeof value.value === "number") return value.value;
    if (value.label === "Other") return value.value;
    return value.label || value.value || value;
  };

  const menuOptions = [
    {
      label: "Download",
      action: (imageId) => downloadImage(user.images[imageId], `${user.name}_${imageId}`)
    }
  ]

  return (
      <div className="dashboard-user-profile">
        <div className="tags-container">
          {shouldRenderLGBTLabel(user) && (
              <Image
                  src={LGBTQ}
                  alt="LGBTQ+ label"
                  className="tag"
              />
          )}
          {shouldRenderPolyLabel(user) && (
              <Image
                  src={PolyOther}
                  alt="PolyOther label"
                  className="tag"
              />
          )}
          {shouldRenderPausedLabel(user) && (
              <Image
                  src={Paused}
                  alt="Paused label"
                  className="tag"
              />
          )}
        </div>


        {Object.keys(memberSchema).map((category, idx) => {
          const cat = memberSchema[category];
          return (
              <div
                  key={`user-profile-${category}-${idx}`}
                  className="profile-category"
              >
                <div className="info-category">
                  {category === "basic" && user.images && user.images[0] && (
                      <ImageCarousel
                          imageClass="user-image"
                          images={
                              (user.images &&
                                  user.images
                                      .filter((x) => x)
                                      .map((x) =>
                                          x.url
                                              ? x.url
                                              : null
                                      )) ||
                              []
                          }
                          optionsMenu={menuOptions}
                      />
                  )}
                  <div className={`info-container ${category}`}>
                    <h3>{cat.label}</h3>

                {Object.keys(cat.attributes).map((field) => {
                  const fieldData = cat.attributes[field];
                  // * If the field is hidden, don't display it on the user modal (it will still be displayed as filter if it has a filterType defined)
                  if (fieldData.hidden) return;
                  if (field === "does_drugs.type") return;
                  let value = getUserFieldValue(field, user);
                  if (field === "clientStatus") {
                    value = value === "candidate"
                        ? "Candidate (paid connection)"
                        : "Client (free connection)";
                  }
                  if (
                    field === "height" &&
                    user[field] &&
                    validHeight(user[field])
                  ) {
                    value = getHeight(user[field]);
                  }
                  if (fieldData.display) value = fieldData.display(user);
                  if (Array.isArray(value)) {
                    if (!!value.length) {
                      if (field === "matchArea") {
                        value = value.map((x) => x.label || x).join(", ");
                      } else if (field === "interestedIn") {
                        value = value
                          .map((x) =>
                            x.label === "Other" ? x.value || x.label : x.label
                          )
                          .join(", ");
                      } else if (field === "other.languages") {
                        value = value
                          .map(
                            (x) =>
                              `${x.language}${x.proficiency ? ` (${x.proficiency})` : ""
                              }`
                          )
                          .join(", ");
                      } else {
                        value = value
                          .map((x) =>
                            x.label === "Other" ? x.value || x.label : x.label
                          )
                          .join(", ");
                      }
                    } else {
                      value = null;
                    }
                  }

                      if (
                          value &&
                          [
                            "other.giveAppreciation",
                            "other.receiveAppreciation",
                          ].includes(field)
                      ) {
                        value = Object.keys(value)
                            .map(
                                (key) =>
                                    `${APPRECIATION_OPTIONS.find(
                                        (option) => option.key === key
                                    ).label
                                    } (${value[key]})`
                            )
                            .join(", ");
                      }

                      if (field === "relationshipStatus") {
                        value = (
                            <>
                              {value}{" "}
                              {!!user.relationshipStatus?.date && (
                                  <span className="date-ago">
                            as of {user.relationshipStatus.date &&
                                      (new Intl.DateTimeFormat("en-US", { year: "numeric", month: "long", day: "numeric" })
                                          .format(new Date(user.relationshipStatus.date)))}
                                    {/* {fromNow(user.relationshipStatus.date)} */}
                          </span>
                              )}
                              {!user.relationshipStatus?.date && (
                                  <span className="date-ago">
                            as of Today
                          </span>
                              )}
                            </>
                        );
                      }

                      if (category !== "basic" && field === "clientStatus") return;
                      if (field === "state") return;

                      return (
                          <div
                              className={`profile-field${fieldData.type ? ` ${fieldData.type}` : ""
                              }`}
                              key={`user-profile-${field}`}
                          >
                            {field === "regions" ? (
                                <h4>{"Location"}</h4>
                            ) : (
                                <h4>{fieldData.label}</h4>
                            )}
                            {fieldData.minLabel && (
                                <div className="range">
                                  <label>{fieldData.minLabel}</label>
                                  <div className="range-values">
                                    {fieldData.options.map((x) => {
                                      return (
                                          <div
                                              className={`range-value ${value === x ? "active" : ""
                                              }`}
                                              key={`range-value-${x}`}
                                          >
                                            {x}
                                          </div>
                                      );
                                    })}
                                  </div>
                                  <label>{fieldData.maxLabel}</label>
                                </div>
                            )}
                            {!fieldData.minLabel && (value || "--")}
                          </div>
                      );
                    })}

                    {category === "attributes" && (
                        <div className="profile-field attributes">
                          <div className="mm-reviews">
                            <h4>Matchmaker Reviews</h4>

                            {user.connected && (
                                <button
                                    className="btn"
                                    onClick={() => setShowRatings(true)}
                                >
                          <span
                              className={`fa ${reviewed ? "fa-edit" : "fa-plus"}`}
                          />
                                  &nbsp; {reviewed ? "Edit" : "Add"} Review
                                </button>
                            )}

                            <Modal
                                open={showRatings}
                                title={`Review Member`}
                                onClose={() => setShowRatings(false)}
                            >
                              <UserDescriptionForm
                                  user={user}
                                  onSubmit={() => setShowRatings(false)}
                              />
                            </Modal>

                            <div className="matchmaker-provided-info">
                              {descriptions.length > 0 && (
                                  <>
                                    <div className="matchmaker-ratings">
                                      {memberAttributes.map((x) =>
                                              ratings[x] && (
                                                  <>
                                                    <div className="rating-label">{x}</div>
                                                    <div className="rating-display">
                                                      <div
                                                          className="no"
                                                          style={{
                                                            width: `${ratings[x].noRate / 2}%`,
                                                          }}
                                                      />
                                                      <div
                                                          className="yes"
                                                          style={{
                                                            width: `${ratings[x].yesRate / 2}%`,
                                                          }}
                                                      />
                                                    </div>
                                                  </>
                                              )
                                      )}
                                    </div>

                                    <div className="matchmaker-notes">
                                      {descriptions
                                          .filter((x) => x.comment)
                                          .map((x) => (
                                              <p>
                                    <span className="date">
                                      {x.updated &&
                                          new Date(x.updated).toLocaleString(
                                              "en-US",
                                              {
                                                day: "numeric",
                                                month: "numeric",
                                                year: "numeric",
                                              }
                                          )}
                                    </span>
                                                {x.comment}
                                              </p>
                                          ))}

                                      {descriptions.filter((x) => x.comment).length ===
                                          0 && (
                                              <p className="muted">
                                                No matchmaker comments yet
                                              </p>
                                          )}
                                    </div>
                                  </>
                              )}
                              {descriptions.length === 0 && (
                                  <p className="muted">No matchmaker reviews yet</p>
                              )}
                            </div>
                          </div>
                        </div>
                    )}
                  </div>
                </div>
              </div>
          );
        })}
      </div>
  );
}
