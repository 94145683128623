import React, { useEffect, useState } from "react";
import { getGlobal, setGlobal } from "../../../launchpad";
import { getFilter, setFilters } from "src/components/filters/Filters";
import Select from "../Select";
import { getHeight } from "../../../global_data";
import theme from "src/styles/theme";
import { SavedFilters } from "../filters/savedFilters/SavedFilters";
import { FilterIcon } from "../icons";

export const MMSideBar = ({ filters, toggleFilter, saveFilters, filterButton, isTabletDown }) => {

    const [filter, _setFilter] = useState("");
    const mm = getGlobal("activeUser");
    const saved = mm.savedSelectedFilters || {};

    const filterUsed = () => {
        const current = JSON.stringify(filters);
        const mmFilters = mm.savedSelectedFilters || {};
        for (let x of Object.keys(mmFilters)) {
            if (JSON.stringify(mmFilters[x]) === current) return x;
        }
    };

    const setFilter = (v) => {
        _setFilter(v);
        setFilters(flatten(saved[v]), { overwrite: true });
    };

    const currentFilter = filterUsed();

    useEffect(() => {
        if (currentFilter !== filter) {
            _setFilter(currentFilter);
        }
    }, [currentFilter]);


    return !isTabletDown ? (
        <div className="sidebar dashboard-panel">
            <button
                style={{ marginTop: '8px', backgroundColor: theme.colors.yellow }}
                onClick={() => filterButton(true)}
                className={"filter-button"}
            >
                <div>
                    <span style={{ fontWeight: 500, color: "#2E383A", fontSize: "16px" }} className="text">FILTER MEMBERS</span>
                </div>
            </button>
            {hasFiltersApplied() ? (
                <button
                    className={"active filter-button"}
                    style={{ backgroundColor: '#3FC6C0', marginBottom: '8px' }}
                    onClick={() => removeAllFilters()}
                >
                    CLEAR ALL FILTERS
                </button>
            ) : (
                <div style={{ marginBottom: '36px' }} />
            )}
            <CurrentFilters />
            <button
                onClick={() => toggleFilter("all")}
                className={
                    !filters.favorited &&
                        !filters.contacted &&
                        !filters.locked &&
                        !filters.connected &&
                        !filters.associated &&
                        !filters.potentials
                        ? "active"
                        : ""
                }
            >
                <span className="fa fa-user" />{" "}
                <span style={{ fontWeight: 500, color: theme.colors.black, fontSize: '16px' }} className="text">All Members</span>
            </button>
            <button
                onClick={() => toggleFilter("potentials")}
                className={filters.potentials ? "active" : ""}
            >
                <span className={`icon-heart${filters.potentials === true ? "-white" : ""}`} />
                <span style={{ fontWeight: 500, color: theme.colors.black, fontSize: '16px' }} className="text">My Potentials</span>
            </button>
            <button
                onClick={() => toggleFilter("connected")}
                className={filters.connected ? "active" : ""}
            >
                <span className="fa fa-link" />{" "}
                <span style={{ fontWeight: 500, color: theme.colors.black, fontSize: '16px' }} className="text">My Connections</span>
            </button>
            <SavedFilters filters={saved} setFilter={setFilter} selectedFilter={filter} setAllFilters={setFilters} />
            {Object.keys(filters).length > 0 &&
                !(filters.connected || filters.potentials) && (
                    <div className="save-filters">
                        <button className="button" onClick={saveFilters}>
                            Save Filter
                        </button>
                    </div>
                )}
        </div>
    ) : (
        <div style={{ display: "flex", justifyContent: "space-around", padding: "2vh", height: "8svh" }}>
            <FilterMobileButton text={"Filter Members"} isPressed={hasFiltersApplied()} handleFilterButtonClick={filterButton} selectedColor={theme.colors.yellow} />
            <SavedFilters filters={saved} setFilter={setFilter} selectedFilter={filter} setAllFilters={setFilters} isMobile={true} />
        </div>
    );
};

const flatten = (obj) => {
    if (typeof obj !== "object") return obj;
    if (obj.value) return obj.value;
    if (Array.isArray(obj)) {
        return obj.map((x) => flatten(x));
    }
    let flat = {};
    for (let key of Object.keys(obj)) {
        flat[key] = flatten(obj[key]);
    }
    return flat;
};

const CurrentFilters = () => {
    const filters = getGlobal("userFilters") || {};
    return (
        <div className="current-filters">
            {Object.keys(filters)
                .filter((x) => getFilter(x))
                .map((filter, index) => {
                    const v = filters[filter];
                    const filterData = getFilter(filter);
                    let valueLabel;
                    const getLabel = (v) => {
                        if (filterData.filterDisplay) return filterData.filterDisplay(v);
                        return v;
                    };
                    if (Array.isArray(v)) {
                        valueLabel =
                            v.length === 1 ? getLabel(v[0]) : `${v.length} Selected`;
                    } else if (filter === "height") {
                        valueLabel = `${getHeight(v.min)} - ${getHeight(v.max)}`;
                    } else if (typeof v.min != "undefined") {
                        valueLabel = `${v.min} - ${v.max}`;
                    } else {
                        valueLabel = v;
                    }
                    return (
                        <div className="current-filter" key={index}>
                            <label>{filterData.filterLabel || filterData.label}</label>
                            <div
                                className="filter-box"
                                onClick={() => setGlobal({ selectedUserFilter: filter })}
                            >
                                <span className="filter-value">{valueLabel}</span>
                                <span className="icon-arrow" />
                                <span
                                    className="icon-close-button"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setFilters({ [filter]: null });
                                    }}
                                />
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};

const removeAllFilters = () => {
    const filters = getGlobal("userFilters") || {};
    Object.keys(filters).forEach((x) => setFilters({ [x]: null }));
};

const hasFiltersApplied = () => {
    const filters = getGlobal("userFilters") || {};
    return Object.keys(filters).some((x) => getFilter(x));
};

const FilterMobileButton = ({ text, isPressed, selectedColor, handleFilterButtonClick }) => {

    return (
        <div onClick={() => handleFilterButtonClick(true)}
            style={{
                display: "flex", backgroundColor: `${isPressed ? selectedColor : ""}`,
                border: "1px solid rgba(0, 0, 0, 0.15)", borderRadius: "5px",
                padding: "4px 8px", alignItems: "center", width: "170px", justifyContent: "space-between"
            }}>
            <span style={{ color: theme.colors.black, fontSize: "14px", fontWeight: 400 }}>
                {text}
            </span>
            <FilterIcon />
        </div>
    )
}
