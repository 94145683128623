// Deps
import React from "react";
// Layouts && Sections
import MainContent from "../components/layouts/MainContent";
// Components
import SignUp from "../components/SignUp/SignUp";

const SignUpContainer = ({ userRole, ...rest }) => (
  <MainContent innerContainer="large" borderBottom>
    <SignUp userRole={userRole} {...rest} />
  </MainContent>
);

export default SignUpContainer;
