@import '../../../../src/scss/variables';

.welcome-container, .basic-single-info-container, .ds-form {
    background: #fff;
    font-family: "Open Sans", sans-serif;
    height: 100%;
    width: 100%;

    &__top {
        background-color: #FFFFFF;
        box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.2);
        height: 72px;
        padding: 20px 60px;

        img {
            height: 70px;
        }

        .basic-info-title {
            color: rgba(0, 0, 0, 0.7);
            font-size: 34px;
            font-weight: bold;
            letter-spacing: -1.98px;
            line-height: 46px;
            margin-left: 10%;
        }

        button {
            margin-left: 20px;
            width: 144px;
        }

    }

    &__content {
        max-width: 500px;
        margin: 95px auto 40px;

        &__title {
            color: rgba(0, 0, 0, 0.7);
            font-size: 34px;
            font-weight: bold;
            letter-spacing: -1.98px;
            line-height: 46px;
            text-align: center;
        }

        &__text {
            color: rgba(0, 0, 0, 0.4);
            font-size: 15px;
            font-weight: 600;
            line-height: 28px;
            margin-bottom: 30px;
        }
    }

    .lets-go {
        letter-spacing: 2.17px;
        height: 62px;
        margin: 0 auto;
        width: 224px;

    }

    &__sections {
        height: calc(100vh - 112px);
        width: 100%;
    }

    .left-section {
        background-color: #FAFAFA;
        box-shadow: 1px 0 0 0 rgba(0, 0, 0, 0.2);
        color: rgba(0, 0, 0, 0.4);
        height: 100%;
        margin-top: 1px;
        overflow: auto;
        width: 270px;

        &__block {
            height: 60px;
            .number {
                border: 2px solid rgba(0, 0, 0, 0.2);
                border-radius: 20px;
                color: rgba(0, 0, 0, 0.2);
                font-size: 12px;
                font-weight: 800;
                line-height: 16px;
                height: 20px;
                position: relative;
                width: 20px;
                &:after {
                    background: rgba(0, 0, 0, 0.2);
                    bottom: -38px;
                    content: '';
                    height: 36px;
                    left: 9px;
                    position: absolute;
                    width: 2px;
                }
                &.first-child:after, &.last-child:after {
                    display: none;
                }
            }

            .title {
                font-size: 18px;
                font-weight: bold;
                line-height: 24px;
                margin-left: 20px;
            }

            &.is-completed-section {
                .number {
                    border: 2px solid #81C4C1;
                    background: #81C4C1;
                    color: #fff;
                }
                .number:before {
                    background: #81C4C1;
                    bottom: -38px;
                    content: '';
                    height: 36px;
                    left: 9px;
                    position: absolute;
                    width: 2px;
                }
                .number:after {
                    display: none;
                }
            }

            &.is-active-section {
                .number:after {
                    display: none;
                }

            }
        }

        &__block-small {
            height: 30px;
            .number {
                border: 2px solid rgba(0, 0, 0, 0.2);
                border-radius: 10px;
                height: 10px;
                margin-left: 5px;
                position: relative;
                width: 10px;
                &:before {
                    background: rgba(0, 0, 0, 0.2);
                    bottom: 12px;
                    content: '';
                    height: 16px;
                    left: 4px;
                    position: absolute;
                    width: 2px;
                }
                &.first-child:before {
                    height: 26px;
                }
                &.last-child:after {
                    background: rgba(0, 0, 0, 0.2);
                    bottom: -28px;
                    content: '';
                    height: 26px;
                    left: 4px;
                    position: absolute;
                    width: 2px;
                }
            }
            .title {
                color: rgba(0, 0, 0, 0.4);
                font-size: 15px;
                font-weight: 600;
                line-height: 20px;
                margin-left: 25px;
            }
        }

        &__block, &__block-small {
            &.is-completed-blue {
                .number {
                    border: 2px solid #81C4C1;
                    color: #81C4C1;
                    &:before, &:after {
                        background: #81C4C1;
                    }
                }
                .title {
                    color: #81C4C1;
                }

            }
        }

    }

    .right-block-title {
        &__error-field {
            font-size: 12px;
            font-weight: 400;
            color: #FF596F;
            margin-bottom: 10px;
        }
    }

    .right-section {
        background-color: #fff;
        color: rgba(0, 0, 0, 0.4);
        height: 100%;
        margin: 1px 0 0 1px;
        width: calc(100% - 271px);
        overflow: auto;

        .general-error {
            font-size: 18px;
            color: #FF6666;
            position: relative;
            top: -20px;
        }

        .custom-radio-button.last-element {
            margin-bottom: 0;
        }

        .height-input {
            border: 1px solid rgba(84, 88, 90, 0.2);
            border-radius: 4px;
            margin-bottom: 20px;
            padding: 0 12px;
            width: 134px;
            .input-wrapper {
                width: 32px;
                .input-text {
                    padding: 0 10px;

                }
            }
            .input-wrapper.small {
                width: 42px;
                .input-text {
                    padding: 0 9px;
                }
            }
            .input-tag {
                color: rgba(84,88,90,0.3);
                font-family: 'Montserrat', sans-serif;
                font-size: 18px;
                font-style: italic;
                width: 33px;
                height: 40px;
            }
            &.is-valid {
                border: 1px solid #7DC0BD;
            }
            &.has-error {
                border: 1px solid #FF6666;
            }
            &.age-range {
                width: 110px;
                .input-wrapper {
                    width: 44px;
                }
            }
        }

        .label {
            color: #FF6666;
            font-family: Montserrat, sans-serif;
            font-size: 16px;
            margin: 0 0 4px 12px;
            &.valid {
                color: $teal;
            }
        }

        .date-input {
            margin: 0 0 20px 0;
            .month-drop-down {
                width: 140px;
            }
            .day-drop-down {
                width: 90px;
            }
            .year-drop-down {
                width: 100px;
            }
            .error-label {
                color: #FF6666;
            }
        }

        .state-drop-down {
            width: 100px;
            margin-right: 30px;
        }

        .drop-down-wrapper {
            margin-right: 30px;
        }

        .input-text-area-wrapper .input-text-area {
            width: 100%;
            max-width: 632px;
            min-height: 63px;
            max-height: 200px;
        }

        .pets-radio__options {
            max-width: none;
        }

        .kids-info {
            margin-bottom: 20px;
            .input-tag {
                width: 50px;
            }
            .kids-ages {
                height: 40px;
                margin-bottom: 10px;
            }
            .kid-label {
                color: #54585A;
                font-family: 'Montserrat', sans-serif;
                font-size: 18px;
                margin-right: 12px;
                width: 112px;
                text-align: left;
            }
        }

    }
}
