@import "../../../scss/variables.scss";

.referrals-section {
  background-color: $pale;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  min-height: 100%;

  .referrals-card {
    background-color: white;
    width: 95%;

    table {
      width: 100%;
      border-collapse: collapse;
      margin: 20px 0;
      box-shadow: 0 0 10px 0 rgba(84, 88, 90, 0.2);

      th {
        background-color: $teal;
        color: white;
        padding: 10px;
        border: 1px solid $teal;
      }

      td {
        padding: 10px;
        text-align: center;
      }
    }

    .title {
      font-size: 30px;
      color: white;
      background-color: $blue;
      text-align: center;
      padding: 25px 0;
      margin: 0;
    }

    .content {
      padding: 40px 40px 0px 40px;
      font-size: 20px;
      display: flex;
      align-items: center;

      div > * {
        margin: 10px 0;
      }
    }

    .referrals-table {
      padding: 0 40px 40px 40px;

      h3 {
        border-bottom: 1px solid $blue;
        padding-bottom: 20px;
      }
    }

    .referrals-list {
      display: none;
    }
  }
}

@media (max-width: $xl_min) {
  .referrals-card table {
    font-size: 14px;
  }
}

@media (max-width: $l_min) {
  .content {
    flex-direction: column;
  }

  .referrals-section {
    padding: 10px 0;
    height: unset;
    min-height: unset;

    .referrals-card {
      .title {
        font-size: 26px;
      }

      .content {
        font-size: 18px;
      }
    }
  }
}

@media (max-width: 950px) {
  .referrals-table {
    padding: 0 !important;
    h3 {
      background-color: $teal;
      text-align: center;
      padding: 7px !important;
      color: white;
      border: none !important;
      font-size: 20px;
      margin-bottom: 0;
    }

    table {
      display: none;
    }
  }

  .referrals-section .referrals-card .referrals-list {
    display: flex;
    padding: 20px;
    flex-direction: column;
    gap: 20px;

    .user {
      display: flex;
      align-items: center;
      gap: 20px;

      img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
      }

      p {
        margin: 0;
      }

      h4 {
        color: $teal;
        margin: 0;
        font-size: 18px;
      }
    }
  }
}
