import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./style.scss";

class Modal extends Component {
  static propTypes = {
    isOpen: PropTypes.any,
    className: PropTypes.any,
    modalContent: PropTypes.any,
    hideModal: PropTypes.any,
    closeOnClickOutside: PropTypes.any,
  };

  componentDidMount() {
    document.body.classList.add("no-scroll");
  }

  componentWillUnmount() {
    document.body.classList.remove("no-scroll");
  }

  render() {
    const { isOpen, className, modalContent } = this.props;
    const classes = classNames(
      {
        "modal-overlay": true,
        "modal-overlay--isOpenModal": isOpen,
      },
      className
    );
    return isOpen ? (
      <div className={classes} onClick={this.onClickHandler.bind(this)}>
        <div className="modal">
          <div className="modal__content">{modalContent}</div>
        </div>
      </div>
    ) : null;
  }

  onClickHandler = (ev) => {
    return this.props.closeOnClickOutside &&
      ev.target.classList.contains("modal-overlay")
      ? this.props.hideModal()
      : "";
  };
}

export default Modal;
