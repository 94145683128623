import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as authActions from "../../actions/authentication";
import * as generalActions from "../../actions/generalActions";
import SignUpSingles from "../../components/sign-up-form";
import logo from "../../components/main-header/assets/date-spot-logo.svg";
import UserSession from "../../components/user-session";
import LoginWithFb from "../facebook/index";

import "../style.scss";

class SignUpPage extends Component {
  signUpCallback = (mess, response) => {
    const { error, user, token } = response.data;
    if (!error) {
      UserSession.setToken(token, user, null);
      this.props.history.push({ pathname: "/" });
    }
  };

  render() {
    const { history } = this.props;
    const { signUpUser } = this.props.authActions;

    return (
      <div className="matchmaker-sign-up-container flex-row">
        <div className="left-section flex-column">
          <div className="logo-and-arrow flex-row">
            <span
              className="icon-back-button"
              onClick={() => history.push("/for-singles")}
            />
            <div className="date-spot-logo flex-center">
              <img src={logo} />
            </div>
          </div>
          <div className="find-your-partner">Find Your Partner</div>
          <SignUpSingles
            signUpUser={signUpUser}
            authActions={this.props.authActions}
            history={history}
            signUpCallback={(mess, response) =>
              this.signUpCallback(mess, response)
            }
            isMatchMakerForm={true}
          />
        </div>
        <div className="right-section flex-column">
          <div className="sign-up-background flex-column">
            <div className="couple-info flex-column flex-center">
              <span>Jacky & Tim</span>
              <span className="float-right">NYC</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SignUpPage.propTypes = {
  history: PropTypes.object,
  authActions: PropTypes.object,
  generalActions: PropTypes.object,
  errors: PropTypes.object,
  authenticationReducer: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    generalReducer: state.generalReducer,
    authenticationReducer: state.authenticationReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpPage);
