@import "src/scss/variables";

.avatar {
  border-radius: 50%;
  display: inline-block;
  margin: 0 !important;

  img {
    border-radius: 50%;
    object-fit: cover;
  }

  &--small {
    height: 40px !important;
    width: 40px !important;
  }

  &--medium {
    height: 60px !important;
    width: 60px !important;
  }

  &--large {
    height: 80px !important;
    width: 80px !important;
  }

  &__initials {
    background-color: $teal;
    border-radius: 50%;
    color: $lightYellow;
    display: flex;
    font-size: 1.5rem;
    font-weight: 500;
    height: 100%;
    justify-content: center;
    align-items: center;
    line-height: 1;
    width: 100%;
  }
}
