import React from "react";
import PropTypes from "prop-types";
import { hasParent } from "../util-functions";
import SearchInput from "../search-input";

import "../drop-down/style.scss";

class DropDownComponentSpecial extends React.Component {
  constructor() {
    super();
    this.id = uid();
    this.state = { showItemsList: false, search: "" };
  }

  componentDidMount() {
    this.touchOccurred = (ev) => {
      if (!hasParent(ev.target, this.id)) {
        this.setState({ showItemsList: false });
      }
    };

    // Detect click outside
    document.body.addEventListener("touchstart", this.touchOccurred);
    document.body.addEventListener("click", this.touchOccurred);
  }

  /* Unbind the touchOcurred function on component unmount */
  componentWillUnmount() {
    document.body.removeEventListener("touchstart", this.touchOccurred);
    document.body.removeEventListener("click", this.touchOccurred);
  }

  componentDidUpdate() {
    if (this.state.showItemsList && this.selectedValue) {
      this.dropDown.scrollTop = this.selectedValue.offsetTop - 10;
    }
  }

  toggleItemList = () => {
    this.setState({ showItemsList: !this.state.showItemsList });
  };

  render() {
    const {
      className,
      wrapperClassName,
      disabled,
      listOfValues,
      label,
      selectedValue,
      onSelectValue,
      name,
      placeholder,
      error,
      showLabel,
      classNameForLabel,
      hasError,
    } = this.props;
    const selected = selectedValue || {};
    const dropdownName = name || null;
    const dropdownPlaceholder = placeholder || "";
    let classes = `drop-down-wrapper special flex-column ${this.id}`;
    if (wrapperClassName) {
      classes += ` ${wrapperClassName}`;
    }
    let inputClass = className || "";
    if (selectedValue) {
      inputClass += " input-text--has-value";
    }
    if (this.state.showItemsList) {
      classes += " drop-down-wrapper--open";
    }
    if (this.props.placeholder && !selectedValue) {
      inputClass += " placeholder";
    }
    if (disabled) {
      classes += " drop-down-wrapper--disabled";
    }
    if (hasError) {
      inputClass += " hasError";
    }
    const renderContent = this._renderContent(
      listOfValues,
      onSelectValue,
      dropdownName,
      selected
    );
    return (
      <div className={classes}>
        {showLabel && (
          <span className={`drop-down-label ${classNameForLabel}`}>
            {label || "label"}{" "}
          </span>
        )}
        <div className="flex-container flex-column">
          <div
            className={`input-text flex-vertical-center ${inputClass}`}
            onClick={!disabled ? this.toggleItemList.bind(this) : noop}
          >
            {selectedValue
              ? selectedValue.label || selected.value
              : dropdownPlaceholder}
            <span className="icon-arrow" />
          </div>
          <div className="icon-close-button-container flex-center">
            <span
              className="icon-close-button"
              onClick={() => this.props.deleteRow()}
            />
          </div>
          {renderContent}
        </div>
        <label className="error-label">
          {error && typeof error === "string" ? error : ""}
        </label>
      </div>
    );
  }

  _renderContent = (listOfValues, onSelectValue, dropdownName, selected) => {
    const { search } = this.state;
    let values = listOfValues;
    if (search) {
      const contains = (string, subStr) =>
        string.toLowerCase().indexOf(subStr.toLowerCase()) > -1;
      const areaMatches = (area) => contains(`${area.label}`, search);
      values = values.filter((area) => areaMatches(area));
    }
    return (
      <div className="drop-down-wrapper__content">
        <SearchInput
          value={search}
          customClass="drop-down-search"
          onChange={(e) => this.setState({ search: e.target.value })}
          placeholder="Select or Search"
        />
        <ul
          className="drop-down-wrapper__list"
          ref={(c) => {
            this.dropDown = c;
          }}
        >
          {values &&
            values.map((item, idx) => {
              if (item.value === selected.value) {
                return (
                  <li
                    key={idx}
                    ref={(c) => {
                      this.selectedValue = c;
                    }}
                    className="drop-down-wrapper__list__item drop-down-wrapper__list__item--active"
                  >
                    {item.label || item.value}
                  </li>
                );
              }
              return (
                <li
                  key={idx}
                  className="drop-down-wrapper__list__item"
                  onClick={() => {
                    onSelectValue(item, dropdownName);
                    this.toggleItemList();
                  }}
                >
                  {item.label || item.value}
                </li>
              );
            })}
        </ul>
      </div>
    );
  };
}

const uid = () => {
  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};

const noop = () => {
  // Do nothing
};

DropDownComponentSpecial.propTypes = {
  selectedValue: PropTypes.any,
  listOfValues: PropTypes.array,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  wrapperClassName: PropTypes.string,
  label: PropTypes.string,
  onSelectValue: PropTypes.func,
  error: PropTypes.any,
  showLabel: PropTypes.bool,
  hasError: PropTypes.string,
  classNameForLabel: PropTypes.string,
  deleteRow: PropTypes.func,
};

export default DropDownComponentSpecial;
