import React, { useCallback, useState } from "react";
import "./FormInput.scss";
import ToolTip from "../Tooltip";
// Icons
import { QuestionIcon } from "../icons";

export const FormInput = ({
  style = {},
  maxLength,
  className = "",
  defaultValue,
  onChange,
  error,
  helpText, // this is the new prop for the tooltip content
  ...rest
}) => {
  const [value, setValue] = useState(defaultValue || "");

  const handleChange = useCallback(
    (e) => {
      const inputValue = e.target.value;
      setValue(inputValue);
      onChange && onChange(inputValue);
    },
    [onChange]
  );

  return (
    <div className="form-input__container">
      <input
        style={{ ...style }}
        maxLength={maxLength ?? 256}
        value={value}
        onChange={handleChange}
        className={`form-input__root${
          error ? " form-input__error-state" : ""
        } ${className}`}
        data-tooltip-id="input-tooltip" // this is the id for the tooltip component
        data-tooltip-content={helpText} // this is the content for the tooltip component
        {...rest}
      />
      {helpText && ( // this is the conditional rendering of the icon
        <ToolTip
        className="form-input__container-question-icon"
          text={helpText}
          popOverClassName="form-input__container-popOver-tooltip"
        />
      )}
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};
