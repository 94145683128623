export const REGIONS = [
    { label: "United States", value: "All U.S." },
    { value: "5deabb673d331e646bd5d04a", label: "SF Bay Area" },
    { label: "NYC", value: "5deae540770cec6bfb53fbc4" },
    { label: "L.A.", value: "5deae6ad770cec6bfb53fbc5" },
    { label: "Chicago", value: "5deae6c6770cec6bfb53fbc6" },
    { label: "Dallas / Ft. Worth", value: "5deae6e2770cec6bfb53fbc7" },
    { label: "Houston", value: "5deaea29770cec6bfb53fbc8" },
    { label: "D.C.", value: "5deaea8c770cec6bfb53fbc9" },
    { label: "Philadelphia", value: "5deaeb71770cec6bfb53fbca" },
    { label: "Miami", value: "5deaebac770cec6bfb53fbcb" },
    { label: "Atlanta", value: "5deaebc4770cec6bfb53fbcc" },
    { label: "Boston", value: "5deaec39770cec6bfb53fbcd" },
    { label: "Phoenix", value: "5deaec65770cec6bfb53fbce" },
    { label: "Detroit", value: "5deaed4d770cec6bfb53fbcf" },
    { label: "Seattle", value: "5deaed71770cec6bfb53fbd0" },
    { label: "Minneapolis", value: "5deaed92770cec6bfb53fbd1" },
    { label: "San Diego", value: "5deaedcb770cec6bfb53fbd2" },
    { label: "Tampa", value: "5deaede3770cec6bfb53fbd3" },
    { label: "Denver", value: "5deaee0b770cec6bfb53fbd4" },
    { label: "St. Louis", value: "5deaee25770cec6bfb53fbd5" },
    { label: "Charlotte", value: "5deaee92770cec6bfb53fbd6" },
    { label: "Portland", value: "5deaeebb770cec6bfb53fbd7" },
    { label: "Orlando", value: "5deaeedf770cec6bfb53fbd8" },
    { label: "San Antonio", value: "5deaeef9770cec6bfb53fbd9" },
    { label: "Pittsburgh", value: "5deaef4f770cec6bfb53fbda" },
    { label: "Sacramento", value: "5deaf0e3770cec6bfb53fbdb" },
    { label: "Cincinnati", value: "5deaf108770cec6bfb53fbdc" },
    { label: "Las Vegas", value: "5deaf129770cec6bfb53fbdd" },
    { label: "Kansas City", value: "5deaf14d770cec6bfb53fbde" },
    { label: "Cleveland", value: "5deaf16b770cec6bfb53fbdf" },
    { label: "Columbus", value: "5deaf193770cec6bfb53fbe0" },
    { label: "Austin", value: "5deaf1ac770cec6bfb53fbe1" },
    { label: "Indianapolis", value: "5deaf1c4770cec6bfb53fbe2" },
    { label: "Alabama", value: "AL" },
    { label: "Alaska", value: "AK" },
    { label: "American Samoa", value: "AS" },
    { label: "Arizona", value: "AZ" },
    { label: "Arkansas", value: "AR" },
    { label: "California", value: "CA" },
    { label: "Colorado", value: "CO" },
    { label: "Connecticut", value: "CT" },
    { label: "Delaware", value: "DE" },
    { label: "District Of Columbia", value: "DC" },
    { label: "Federated States Of Micronesia", value: "FM" },
    { label: "Florida", value: "FL" },
    { label: "Georgia", value: "GA" },
    { label: "Guam", value: "GU" },
    { label: "Hawaii", value: "HI" },
    { label: "Idaho", value: "ID" },
    { label: "Illinois", value: "IL" },
    { label: "Indiana", value: "IN" },
    { label: "Iowa", value: "IA" },
    { label: "Kansas", value: "KS" },
    { label: "Kentucky", value: "KY" },
    { label: "Louisiana", value: "LA" },
    { label: "Maine", value: "ME" },
    { label: "Marshall Islands", value: "MH" },
    { label: "Maryland", value: "MD" },
    { label: "Massachusetts", value: "MA" },
    { label: "Michigan", value: "MI" },
    { label: "Minnesota", value: "MN" },
    { label: "Mississippi", value: "MS" },
    { label: "Missouri", value: "MO" },
    { label: "Montana", value: "MT" },
    { label: "Nebraska", value: "NE" },
    { label: "Nevada", value: "NV" },
    { label: "New Hampshire", value: "NH" },
    { label: "New Jersey", value: "NJ" },
    { label: "New Mexico", value: "NM" },
    { label: "New York", value: "NY" },
    { label: "North Carolina", value: "NC" },
    { label: "North Dakota", value: "ND" },
    { label: "Northern Mariana Islands", value: "MP" },
    { label: "Ohio", value: "OH" },
    { label: "Oklahoma", value: "OK" },
    { label: "Oregon", value: "OR" },
    { label: "Palau", value: "PW" },
    { label: "Pennsylvania", value: "PA" },
    { label: "Puerto Rico", value: "PR" },
    { label: "Rhode Island", value: "RI" },
    { label: "South Carolina", value: "SC" },
    { label: "South Dakota", value: "SD" },
    { label: "Tennessee", value: "TN" },
    { label: "Texas", value: "TX" },
    { label: "Utah", value: "UT" },
    { label: "Vermont", value: "VT" },
    { label: "Virgin Islands", value: "VI" },
    { label: "Virginia", value: "VA" },
    { label: "Washington", value: "WA" },
    { label: "West Virginia", value: "WV" },
    { label: "Wisconsin", value: "WI" },
    { label: "Wyoming", value: "WY" },
    { label: "International", value: "International" },
    { label: "Other U.S.", value: "Other U.S." },
];

export const MARRIAGE_STATUSES = [
    { value: "Engaged/married", label: "Engaged/married", id: 0 },
    { value: "Never married", label: "Never married", id: 1 },
    { value: "Divorced", label: "Divorced", id: 2 },
    { value: "Separated", label: "Separated", id: 5 },
    { value: "Widowed", label: "Widowed", id: 7 },
    { value: "Other", label: "Other", id: 8 },
];

export const RELATIONSHIP_STATUSES = [
    { value: "Single", label: "Single" },
    {
        value: "Not Single",
        label: "Not Single",
        tooltip:
            "Choose your Account Status below so we know whether to check in with you later.",
    },
    { value: "Other", label: "Other" },
];