import React, { useState } from "react";
import PropTypes from "prop-types";
import "./OtherSection.scss";
import TextArea from "src/components/TextArea";
import SelectWithLabel from "src/components/SelectWithLabel";
import { useDebounce } from "src/hooks/useDebounce";
import RadioList from "_old/src/components/radio-list";
import { QUALITIES } from "_old/src/constants/onboarding-data-elements";
import CheckBoxList from "_old/src/components/check-box-list/index";
import {
    APPRECIATION_OPTIONS,
    LONGEST_RELATIONSHIP_OPTIONS,
    HABITS,
    BLIND_DATES_OPTIONS,
    LANGUAGE_PROFICIENCY_OPTIONS,
} from "src/data/other-section";
import { LANGUAGES } from "src/data/languages";
import Select from "../Select";
import OldButton from "_old/src/components/button";
import { Button } from "src/components/widgets";
import { sortArrayByStringField } from "src/utils/functions";

/**
 * Other section of the singles onboarding form.
 *
 * @typedef {(key: string, value: any) => void} TSaveUserFieldDataFunction
 * @typedef {Object} TOtherUserData - The user's data for the other section.
 * @property {Object} giveAppreciation - The user's data for the give appreciation section.
 * @property {Object} receiveAppreciation - The user's data for the receive appreciation section.
 * @property {Object} longestRelationship - The user's longest relationship.
 * @property {Object} languages - The user's languages.
 * @property {Object[]} qualities - The user's qualities.
 * @property {Object[]} habitsToWorkOn - The user's habits to work on.
 * @property {Object[]} dealbreakers - The user's dealbreakers.
 * @property {Object} blindDates - The user's blind dates preference.
 * @property {string} anythingElse - The user's anything else comment
 *
 * @typedef {Object} TOtherSectionProps
 * @property {TOtherUserData} otherUserData - The user's data for the other section.
 * @property {TSaveUserFieldDataFunction} saveUserFieldData - The function to save the user's data.
 * @property {Boolean} isTabletDown - Whether the screen is tablet size or smaller.
 *
 * @param {TOtherSectionProps} props
 * @returns {JSX.Element}
 */
const OtherSection = ({
                          otherUserData = {},
                          saveUserFieldData = () => { },
                          isTabletDown = false,
                          setSectionFromNextButtons = () => { },
                      }) => {
    const handleChange = useDebounce((key, value) => {
        if (!key || !value) return;
        //TODO: Ideally, we should separate each section request to its own endpoint.
        saveUserFieldData(`other.${key}`, value);
    }, 500);
    const [languages, setLanguages] = useState(otherUserData.languages);

    const handleAddLanguage = () => {
        if (languages.some((item) => !item.language || !item.proficiency)) return;
        setLanguages([...languages, { language: "", proficiency: "" }]);
    };

    const handleChangeLanguage = (accessor, oldValue, newValue) => {
        const newLanguages = languages.map((item) =>
            item[accessor] === oldValue ? { ...item, [accessor]: newValue } : item
        );
        setLanguages(newLanguages);
        handleChange("languages", newLanguages);
    };

    const handleDeleteLanguage = (language) => {
        const newLanguages = languages.filter((item) => item.language !== language);
        setLanguages(newLanguages);
        handleChange("languages", newLanguages);
    };

    const getLanguages = () => {
        const userLanguages = languages
            .map((item) => item.language)
            .filter((lang) => lang);

        return LANGUAGES.filter((lang) => !userLanguages.includes(lang));
    };

    const goToSinglesSignupConfirmation = (e) => {
        e.preventDefault();
        window.location.replace(
            `${process.env.MARKETING_SITE_URL}/singles-account-update-confirmation`
        );
    };

    const handleFinish = () => {
        if (otherUserData.firstSignup) {
            window.open("https://www.datespot.love/singles-signup-confirmation", "_blank")
        }
        setSectionFromNextButtons("settings")
    }

    return (
        <section className="other-section">
            <div className="other-section__field-container">
                <h3 className="other-section__field-container__title">
                    Of the{" "}
                    <a
                        className="other-section__field-container__title"
                        href="https://5lovelanguages.com/quizzes/love-language"
                        target="_blank"
                    >
                        5 Love Languages
                    </a>
                    , how do you naturally GIVE appreciation?
                </h3>
                <span className="helper-text">(1st = Highest, 5th = Lowest)</span>
                {APPRECIATION_OPTIONS.map((fieldData) => (
                    fieldData.key !== "receivingGifts" && (
                        <SelectWithLabel
                            key={`give_appreciation_${fieldData.key}`}
                            id={`give_appreciation_${fieldData.key}`}
                            label={fieldData.label}
                            options={fieldData.options}
                            defaultValue={
                                otherUserData.giveAppreciation
                                    ? otherUserData.giveAppreciation[fieldData.key]
                                    : null
                            }
                            onChange={(value) =>
                                handleChange(`giveAppreciation.${fieldData.key}`, value)
                            }
                        />
                    )))}
            </div>
            <div className="other-section__field-container">
                <h3 className="other-section__field-container__title">
                    Of the{" "}
                    <a
                        className="other-section__field-container__title"
                        href="https://5lovelanguages.com/quizzes/love-language"
                        target="_blank"
                    >
                        5 Love Languages
                    </a>
                    , how do you like to RECEIVE appreciation?
                </h3>
                <span className="helper-text">(1st = Highest, 5th = Lowest)</span>
                {APPRECIATION_OPTIONS.map((fieldData) => (
                    fieldData.key !== "gifts" && (
                        <SelectWithLabel
                            key={`receive_appreciation_${fieldData.key}`}
                            id={`receive_appreciation_${fieldData.key}`}
                            label={fieldData.label}
                            options={fieldData.options}
                            defaultValue={
                                otherUserData.receiveAppreciation
                                    ? otherUserData.receiveAppreciation[fieldData.key]
                                    : null
                            }
                            onChange={(val) =>
                                handleChange(`receiveAppreciation.${fieldData.key}`, val)
                            }
                        />
                    )
                ))}
            </div>
            <div className="other-section__field-container">
                <RadioList
                    title={"How long was your longest relationship?"}
                    list={LONGEST_RELATIONSHIP_OPTIONS}
                    selectedValue={otherUserData.longestRelationship}
                    stateCallback={(val) => handleChange("longestRelationship", val)}
                />
            </div>
            <div className="other-section__field-container">
                <h3 className="other-section__field-container__title">
                    What language(s) do you speak?
                </h3>
                <div className="other-section__field-container__language">
                    <div className="other-section__language-fields-container">
                        {languages.map((item) => (
                            <LanguageField
                                key={item.language}
                                options={getLanguages()}
                                language={item.language}
                                proficiency={item.proficiency}
                                onChange={handleChangeLanguage}
                                onDelete={
                                    item.language !== "English" ? handleDeleteLanguage : undefined
                                }
                                proficiencyOptions={LANGUAGE_PROFICIENCY_OPTIONS}
                            />
                        ))}
                    </div>
                    <span
                        className="other-section__field-container__language__add"
                        onClick={handleAddLanguage}
                    >
            +&nbsp;
                        <span className="underline">Add language</span>
          </span>
                </div>
            </div>
            <div className="other-section__field-container">
                <CheckBoxList
                    columns={2}
                    list={sortArrayByStringField(QUALITIES, "value", {
                        lastElement: "Other",
                    })}
                    title="How would people describe you?"
                    selectedValue={otherUserData.qualities}
                    stateCallback={(val) => handleChange("qualities", val)}
                    returnInputValue={(val) => handleChange("qualities", val)}
                />
            </div>
            <div className="other-section__field-container">
                <CheckBoxList
                    columns={2}
                    list={HABITS}
                    title="What are qualities you could work on?"
                    extraClass="half-size-list"
                    selectedValue={otherUserData.habitsToWorkOn}
                    stateCallback={(val) => handleChange("habitsToWorkOn", val)}
                    returnInputValue={(val) => handleChange("habitsToWorkOn", val)}
                />
            </div>
            <div className="other-section__field-container">
                <CheckBoxList
                    columns={2}
                    list={HABITS}
                    title="Which of these traits is a dealbreaker?"
                    extraClass="half-size-list"
                    selectedValue={otherUserData.dealbreakers}
                    stateCallback={(val) => handleChange("dealbreakers", val)}
                    returnInputValue={(val) => handleChange("dealbreakers", val)}
                />
            </div>
            <div className="other-section__field-container">
                <RadioList
                    title="Are you open to blind dates with partner matchmaker clients?"
                    helperText="Some clients prefer more confidentiality, and some matchmakers want to remove preconceived notions."
                    list={BLIND_DATES_OPTIONS}
                    selectedValue={otherUserData.blindDates}
                    stateCallback={(val) => handleChange("blindDates", val)}
                />
            </div>
            <div className="other-section__field-container">
                <h3 className="other-section__field-container__title">
                    Anything else that's helpful to share?
                </h3>
                <TextArea
                    defaultValue={otherUserData.anythingElse}
                    onChange={(e) => handleChange("anythingElse", e.target.value)}
                />
            </div>
            <div className="other-section__continue-button-container">
                {isTabletDown ? (
                    <Button
                        fullWidth
                        variant="primary"
                        onClick={handleFinish}
                    >
                        FINISH
                    </Button>
                ) : (
                    <OldButton
                        value="FINISH"
                        customClass="yellow"
                        click={handleFinish}
                    />
                )}
            </div>
        </section>
    );
};

// * We'll leave this component here for now as it's the only place where it's ever used
const LanguageField = ({
                           language,
                           proficiency,
                           onChange,
                           onDelete,
                           options,
                           proficiencyOptions,
                       }) => (
    <div className="other-section__language-field">
        <Select
            id="language"
            fullWidth
            searchable
            onDelete={onDelete}
            defaultValue={language}
            options={options}
            onChange={(val) => onChange("language", language, val)}
            expandable={language !== "English"}
            placeholder="Select Language"
        />
        <Select
            id="proficiency"
            fullWidth
            defaultValue={proficiency}
            options={proficiencyOptions}
            onChange={(val) => onChange("proficiency", proficiency, val)}
            placeholder="Select Proficiency"
            className="other-section__language-field__proficiency"
        />
    </div>
);

OtherSection.propTypes = {
    otherUserData: PropTypes.shape({
        giveAppreciation: PropTypes.object,
        receiveAppreciation: PropTypes.object,
        longestRelationship: PropTypes.object,
        languages: PropTypes.array,
        qualities: PropTypes.array,
        habitsToWorkOn: PropTypes.array,
        dealbreakers: PropTypes.array,
        blindDates: PropTypes.object,
        anythingElse: PropTypes.string,
    }),
    saveUserFieldData: PropTypes.func.isRequired,
    isTabletDown: PropTypes.bool,
};

export default OtherSection;
