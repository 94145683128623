import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { ReactHeight } from "react-height";
import * as generalActions from "../../../actions/generalActions";
import * as matchMakerActions from "../../../actions/matchmakersActions";
import * as authActions from "../../../actions/authentication";
import cloneDeep from "lodash.clonedeep";
import LeftFilters from "../../left-filters";
import UsersList from "./users-list";
import FavoritesList from "./favorites-list";
import LocksList from "./locks-list";
import ContactedList from "./contacted-list";
import MatchmakerPlans from "./mmaker-plans";
import MatchMakerReferrals from "./mmaker-plans/matchmaker-referrals";

// modified by codepilot
//import MainHeader from '../../../components/main-header';
import MainHeader from "layouts/MainHeader";

import Profile from "../../../components/profile-component";
import Modal from "../../../components/modal";
import UserInfoModalContent from "../modals/user-info-modal-content";
import {
  MATCHMAKER_URLS_TO_TABS,
  LEFT_PANEL_FILTERS,
} from "../../../constants";
import ToolTip from "../../../components/tooltip";
import SearchInput from "../../../components/search-input";
import LoadingModal from "../../../components/loading-modal";
import DropDownComponent from "../../../components/drop-down";
import UserSession from "../../../components/user-session";
import Button from "../../../components/button";
import "../style.scss";
import Referrals from "src/components/matchmakers/referrals_section/Referrals";

class MatchMakerDashboard extends Component {
  state = {
    search: "",
    showAreaTooltip: false,
    showSubscriptionTooltip: false,
    loading: true,
  };

  componentDidMount() {
    const { usersList, savedSelectedFilters } = this.props.generalReducer;
    const { favoriteUsersList, loggedMMplan, matchMakerReferrals } =
      this.props.matchmakersReducer;
    const { loadUsers, loadSavedFilters } = this.props.generalActions;
    const {
      loadFavoritesList,
      loadLoggedMMPlan,
      getMatchmakerAgreedTerms,
      getMatchmakerReferrals,
    } = this.props.matchMakerActions;

    // document.body.classList.add('no-scroll');

    // see if matchmaker has agreed with terms and conditions

    getMatchmakerAgreedTerms();

    // Get users
    if (usersList.length === 0) {
      loadUsers();
    }
    // Get matchmaker filters
    if (Object.keys(savedSelectedFilters).length === 0) {
      loadSavedFilters();
    }

    // Get matchmaker favorites
    if (favoriteUsersList.length === 0) {
      loadFavoritesList();
    }

    // Get logged MM plan data
    if (!loggedMMplan) {
      loadLoggedMMPlan();
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.matchmakersReducer.loadedAllActions) {
      return {
        loading: !props.matchmakersReducer.loadedAllActions,
      };
    }
    return null;
  }

  render() {
    const { history } = this.props;
    const { search, showAreaTooltip, loading } = this.state;
    const {
      resetLeftPanelFilter,
      closeUserInfoModal,
      openCloseFilterModalAndSetFilter,
      searchDb,
      setSelectedHeaderTab,
    } = this.props.generalActions;
    const {
      leftPanelFilters,
      selectedTab,
      requestedUserData,
      showUserInfo,
      filteredUsersList,
      loadingModal,
    } = this.props.generalReducer;
    const {
      hasAgreedWithTermsAndConditions,
      favoriteUsersList,
      loggedMMplan,
      matchMakerReferrals,
    } = this.props.matchmakersReducer;
    let filteredUsers = [...filteredUsersList];
    if (search) {
      const contains = (string, subStr) =>
        string.toLowerCase().indexOf(subStr.toLowerCase()) > -1;
      const userMatches = (user) =>
        contains(`${user.firstName} ${user.lastName}`, search);
      filteredUsers = filteredUsers.filter((user) => userMatches(user));
    }
    const appliedFilters = this._renderFilters(
      leftPanelFilters,
      openCloseFilterModalAndSetFilter,
      resetLeftPanelFilter,
      searchDb
    );
    const renderMMAgreementModal = this._renderMMAgreementModal();
    const renderUserInfoModal = this._renderUserInfoModal(
      showUserInfo,
      requestedUserData,
      favoriteUsersList,
      closeUserInfoModal,
      matchMakerActions,
      filteredUsersList,
      loggedMMplan
    );
    const renderRightPanel = this._renderRightPanel(
      selectedTab,
      search,
      showAreaTooltip,
      appliedFilters,
      filteredUsers,
      history
    );
    if (loading) {
      return <div className="ds-loader" />;
    }
    if (!loading && !hasAgreedWithTermsAndConditions) {
      return (
        <Modal
          isOpen={true}
          className="terms-and-conditions-for-mm-modal"
          modalContent={renderMMAgreementModal}
        />
      );
    }
    const isSafari =
      navigator.userAgent.search("Safari") >= 0 &&
      navigator.userAgent.search("Chrome") < 0;
    const isMatchmakerPlansSection = selectedTab === "matchmaker-plans";
    return (
      <div
        className={`matchmaker-dashboard${
          loadingModal.show ? " loading-modal-opened" : ""
        }`}
      >
        <div
          className="matchmaker-dashboard-container flex-column"
          style={{ height: "100%" }}
        >
          {isMatchmakerPlansSection && <MatchmakerPlans history={history} />}

          {!isMatchmakerPlansSection && (
            <div
              className={`flex-row matchmaker-dashboard-container__sections ${
                isSafari ? "safari-custom" : ""
              }`}
            >
              <div className="matchmaker-dashboard-container__left-panel">
                <LeftFilters history={history} />
              </div>
              {renderRightPanel}
            </div>
          )}

          {renderUserInfoModal}
        </div>
        {loadingModal.show && (
          <LoadingModal
            error={loadingModal.error}
            loading={loadingModal.loading}
          />
        )}
      </div>
    );
  }

  _renderRightPanel = (
    selectedTab,
    search,
    showAreaTooltip,
    appliedFilters,
    filteredUsers,
    history
  ) => {
    return (
      <div className="matchmaker-dashboard-container__right-panel">
        <div className="flex-row">
          <div className="search-bar-container">
            <SearchInput
              value={search}
              onChange={(e) => this.setState({ search: e.target.value })}
              placeholder="Search by name..."
            />
          </div>
          {selectedTab === "mySingles" && (
            <div className="areas-selection flex-row">
              <span className="float-right select">Select default areas</span>
              <span
                className="icon-question-mark"
                onMouseEnter={() => this.setState({ showAreaTooltip: true })}
                onMouseLeave={() => this.setState({ showAreaTooltip: false })}
              />
              {showAreaTooltip && <ToolTip text="Area tooltip here." />}
              <DropDownComponent
                wrapperClassName="flex-column"
                listOfValues={[]}
              />
            </div>
          )}
        </div>
        <ReactHeight
          onHeightReady={(height) => this.setState({ filtersHeight: height })}
        >
          <div className="selected-filters flex-column">
            <div className="flex-row wrap">{appliedFilters}</div>
          </div>
        </ReactHeight>
        {this._renderLists(history, filteredUsers, selectedTab)}
      </div>
    );
  };

  _renderUserInfoModal = (
    showUserInfo,
    requestedUserData,
    favoriteUsersList,
    closeUserInfoModal,
    matchMakerActions,
    filteredUsersList,
    loggedMMplan
  ) => {
    const { id: matchMakerId } = UserSession.getToken();
    const isFavoriteUser =
      favoriteUsersList.length > 0
        ? !!favoriteUsersList.find(
            (fav) =>
              fav.favoriteUser && fav.favoriteUser._id === requestedUserData._id
          )
        : false;
    const requestedUser =
      filteredUsersList &&
      filteredUsersList.find((user) => user._id === requestedUserData._id);

    const isUserContacted =
      requestedUser &&
      requestedUser.connectionsRequests &&
      !!requestedUser.connectionsRequests.find(
        (conn) => conn.matchMakerId === matchMakerId
      );

    const isUserLockedByMe =
      requestedUser &&
      requestedUser.connectionsRequests &&
      !!requestedUser.connectionsRequests.find(
        (conn) => conn.lockingMatchmaker === matchMakerId && conn.isLocked
      );
    const userNumber = filteredUsersList.find(
      (us) => us._id === requestedUserData._id
    );

    const modalContent = (
      <UserInfoModalContent
        requestedUserData={requestedUserData}
        isFavoriteUser={isFavoriteUser}
        isUserContacted={isUserContacted}
        isUserLockedByMe={isUserLockedByMe}
        favoriteUsersList={favoriteUsersList}
        closeUserInfoModal={closeUserInfoModal}
        loggedMMplan={loggedMMplan}
        userNumber={userNumber && userNumber.number}
        matchMakerActions={matchMakerActions}
      />
    );
    return (
      <div>
        {showUserInfo && (
          <Modal
            isOpen={showUserInfo}
            className="user-info-modal"
            modalContent={modalContent}
          />
        )}
      </div>
    );
  };

  _renderMMAgreementModal = () => {
    return (
      <div className="terms-and-conditions-for-mm-modal__content">
        <div className="terms-and-conditions-for-mm-modal__title">
          In order to proceed you must agree with the terms and conditions of
          the Matchmaker Agreement
        </div>
        <div className="flex-row terms-and-conditions-for-mm-modal__text">
          <span>Please read the </span>
          <span
            onClick={() => this.props.history.push("/matchmaker-agreement")}
          >
            {" "}
            Matchmaker Agreement{" "}
          </span>
        </div>

        <div className="terms-and-conditions-for-mm-modal__buttons flex-row flex-center">
          <Button
            value="ACCEPT"
            click={() =>
              this.props.matchMakerActions.agreedWithTermsAndConditions()
            }
          />
          <Button
            value="DECLINE"
            customClass="redish"
            click={() => UserSession.logout()}
          />
        </div>
      </div>
    );
  };

  _renderFilters = (
    leftPanelFilters,
    openCloseFilterModalAndSetFilter,
    resetLeftPanelFilter,
    searchDb
  ) => {
    return Object.entries(leftPanelFilters).map((filter, f) => {
      let modalFilter = "";
      if (filter[0] === "ageValue") {
        modalFilter = LEFT_PANEL_FILTERS.find((item) => item.filter === "age");
      } else {
        modalFilter = LEFT_PANEL_FILTERS.find(
          (item) => item.filter === filter[0]
        );
      }
      const filterLength = filter[1].length;
      let filterValue =
        filterLength > 1 ? `${filterLength} filters` : filter[1][0].value;
      if (filter[0] === "ageValue") {
        filterValue = `${filter[1][0].min} to ${filter[1][0].max}`;
      }
      return (
        filterLength > 0 && (
          <div className="selected-filter flex-column" key={f}>
            <span className="selected-filter__label">
              {modalFilter.filterName}
            </span>
            <div className="selected-filter__content flex-row">
              <span className="filter-value">{filterValue}</span>
              <span
                className="icon-arrow"
                onClick={() =>
                  openCloseFilterModalAndSetFilter(true, modalFilter)
                }
              />
              <span className="vertical-line" />
              <span
                className="icon-close-button"
                onClick={() => {
                  const leftFilters = cloneDeep(leftPanelFilters);
                  delete leftFilters[filter[0]];
                  searchDb(leftFilters);
                  resetLeftPanelFilter(filter[0]);
                }}
              />
            </div>
          </div>
        )
      );
    });
  };

  _renderLists = (history, filteredUsers, selectedTab) => {
    const { filtersHeight } = this.state;
    const { id: matchMakerId } = UserSession.getToken();
    let sectionContent = "";
    switch (selectedTab) {
      case "mySingles":
        sectionContent = (
          <UsersList
            filteredUsers={filteredUsers}
            filtersHeight={filtersHeight}
            history={history}
            matchMakerActions={matchMakerActions}
            matchmakersReducer={this.props.matchmakersReducer}
            generalActions={generalActions}
            matchMakerId={matchMakerId}
            generalReducer={this.props.generalReducer}
          />
        );
        break;
      case "myFavorites":
        sectionContent = (
          <FavoritesList
            history={history}
            filteredUsers={filteredUsers}
            filtersHeight={filtersHeight}
            matchMakerActions={matchMakerActions}
            matchMakerId={matchMakerId}
            matchmakersReducer={this.props.matchmakersReducer}
          />
        );
        break;
      case "myContacted":
        sectionContent = (
          <ContactedList
            filteredUsers={filteredUsers}
            filtersHeight={filtersHeight}
            history={history}
            matchMakerActions={matchMakerActions}
            matchmakersReducer={this.props.matchmakersReducer}
            generalActions={generalActions}
            matchMakerId={matchMakerId}
            generalReducer={this.props.generalReducer}
          />
        );
        break;
      case "myLocks":
        sectionContent = (
          <LocksList
            filteredUsers={filteredUsers}
            history={history}
            filtersHeight={filtersHeight}
            matchMakerActions={matchMakerActions}
            matchmakersReducer={this.props.matchmakersReducer}
            generalActions={generalActions}
            matchMakerId={matchMakerId}
            generalReducer={this.props.generalReducer}
          />
        );
        break;

      default:
        break;
    }

    return sectionContent;
  };
}

MatchMakerDashboard.propTypes = {
  history: PropTypes.object,
  generalActions: PropTypes.object,
  authActions: PropTypes.object,
  matchMakerActions: PropTypes.object,
  generalReducer: PropTypes.object,
  matchmakersReducer: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return {
    generalActions: bindActionCreators(generalActions, dispatch),
    matchMakerActions: bindActionCreators(matchMakerActions, dispatch),
    authActions: bindActionCreators(authActions, dispatch),
  };
};

const mapStateToProps = (state) => {
  return {
    generalReducer: state.generalReducer,
    matchmakersReducer: state.matchmakersReducer,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MatchMakerDashboard);
