.profile-component {
    cursor: pointer;
    font-family: Montserrat, sans-serif;
    min-width: 170px;
    position: relative;
    // right: 0;
    // top: 0;
    // z-index: 6;

    &__profile {
        height: 80px;
        .logged-user-image {
            border-radius: 50%;
            border: 1px solid #53585A;
            height: 40px;
            margin-right: 10px;
            width: 40px;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .logged-user-name {
            color: #53585A;
            font-size: 18px;
        }
    }

    &__menu {
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 9999;
        background: #fff;
        border: 1px solid rgba(84,88,90,0.1);
        border-radius: 2px;
        box-shadow: 0 0 10px 0 rgba(84,88,90,0.2);
        max-width: 220px;
        user-select: none;

        span {
            // width: 100%;
            height: 40px;
            color: #54585A;
            font-size: 14px;
            padding: 0 20px;
            &:hover {
                background: #FFDA65;
            }
        }
        span:nth-child(2) {
            border-top: 1px solid rgba(84,88,90,0.2);
        }
    }
}
