@import "../scss/variables";

$dropdown-menu-row-height: 41px;

.dropdown-menu {
  display: flex;
  flex-direction: column;
  position: relative;

  &--disabled {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
  }

  &__options-container {
    position: absolute;
    width: 100%;
    transition: all 0.2s ease-in-out;
    opacity: 0;
    pointer-events: none;
    background-color: $pale;
    z-index: 2;

    &--open {
      transform: translateY(calc(#{$dropdown-menu-row-height} + 10px));
      opacity: 1;
      pointer-events: all;
    }
  }
}

.dropdown-menu-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  font-size: 0.75rem;
  color: $textPrimary;
  cursor: pointer;
  height: $dropdown-menu-row-height;
  box-sizing: border-box;

  // Chevron
  i {
    transition: transform 0.2s ease-in-out;
    transform-origin: center;
  }

  &--header {
    z-index: 3;
    background-color: $aqua;
    color: white;
  }

  &__label-container {
    display: flex;
    flex-direction: row;
    gap: 0.5em;

    &__check {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      border-radius: 50%;
      width: 17px;
      height: 17px;

      // Checkmark
      i {
        color: $aqua;
        font-size: 0.5rem;
      }
    }
  }
}
