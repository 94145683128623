import React from "react";
import PropTypes from "prop-types";
import "./SelectWithLabel.scss";
import Select from "./Select";

const SelectWithLabel = ({
  label = "",
  options = [],
  defaultValue = null,
  onChange = () => {},
  className = "",
  orientation = "horizontal",
  id,
}) => (
  <div
    className={`select-with-label${className ? ` ${className}` : ""}${
      orientation ? ` ${orientation}` : ""
    }`}
  >
    <span className="select-with-label__label">{label}</span>
    <Select
      id={id}
      options={options}
      defaultValue={defaultValue}
      onChange={onChange}
    />
  </div>
);

SelectWithLabel.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  orientation: PropTypes.oneOf(["horizontal", "vertical"]),
  id: PropTypes.string,
};

export default SelectWithLabel;
