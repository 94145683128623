import React, { Component } from "react";
import PropTypes from "prop-types";
import InputText from "../../../../components/input-text";
import TextArea from "../../../../components/input-textarea";
import CheckBox from "../../../../components/check-box";
import CheckBoxList from "../../../../components/check-box-list";
import RadioHorizontalList from "../../../../components/radio-list-horizontal";
import HeightPreference from "../../../../components/height-requirements";
import {
  ETHNICITY_REQUIREMENTS,
  HEIGHT_REQUIREMENTS,
  RELOCATION_OPTIONS,
} from "../../../../constants/onboarding-data-elements";
import Validator from "../../../../components/utils/validator/index";
import debounce from "lodash.debounce";
import OldButton from "../../../../components/button";
import { Button } from "src/components/widgets";
import StatisticalAreas from "../../../../components/statistical-areas/index";
import RadioList from "../../../../components/radio-list";

class YourPartner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      relationshipInterest: props.user.relationshipInterest || [],
      partnerPreferences: props.user.partnerPreferences || null,
      ethnicityRequired: props.user.ethnicityRequired || [],
      ethnicityOptions: props.user.ethnicityOptions || null,
      heightPreference: props.user.heightPreference || null,
      relocationOptions: props.user.relocationOptions || null,
      matchArea: props.user.matchArea || [],
      matchAreaDetails: props.user.matchAreaDetails || null,
      matchAreaRange: props.user.matchAreaRange || null,
      ageFrom: props.user.ageRange ? props.user.ageRange.slice(0, 2) : null,
      ageTo: props.user.ageRange ? props.user.ageRange.slice(6, 8) : null,
      clientStatus: props.user.clientStatus || null,
      errors: {},
    };
  }

  render() {
    const { isTabletDown } = this.props;
    const renderPartnerPreference = this._renderPartnerPreference();
    const renderEthnicityPreference = this._renderEthnicityPreference();
    const renderHeightRequirements = this._renderHeightRequirements();
    const renderAgeRange = this._renderAgeRange();
    const renderMatchAreas = this._renderMatchAreas();
    const renderRelocationOptions = this._renderRelocationOptions();
    const renderClientStatus = this._renderClientStatus();

    return (
      <div className="your-partner">
        {renderPartnerPreference}
        {renderEthnicityPreference}
        {renderHeightRequirements}
        {renderAgeRange}
        {renderMatchAreas}
        {renderRelocationOptions}
        <div className="continue-button-container">
          {isTabletDown ? (
            <Button
              fullWidth
              variant="primary"
              onClick={() => this.props.setSectionFromNextButtons("other")}
            >
              CONTINUE
            </Button>
          ) : (
            <OldButton
              value="CONTINUE"
              click={() => this.props.setSectionFromNextButtons("other")}
              customClass="yellow"
            />
          )}
        </div>
      </div>
    );
  }

  _renderPartnerPreference = () => {
    const { partnerPreferences } = this.state;
    return (
      <div className="flex-column">
        <div className={`${partnerPreferences ? "label valid" : "label"}`}>
          What are you looking for in a partner?
        </div>
        <TextArea
          value={partnerPreferences}
          onChange={(e) => {
            this.changeData("partnerPreferences", e.target.value);
          }}
          afterLabel={"Please specify between any preferences or criteria."}
        />
      </div>
    );
  };

  _renderEthnicityPreference = () => {
    const { ethnicityRequired, ethnicityOptions } = this.state;
    return (
      <div className="flex-column" style={{ gap: "0.5rem" }}>
        <CheckBoxList
          list={ETHNICITY_REQUIREMENTS}
          extraClass="no-padding-bottom"
          title="What ethnicities are you open to dating?"
          selectedValue={ethnicityRequired}
          returnInputValue={(val) => this.changeData("ethnicityRequired", val)}
          stateCallback={(val) => this.changeData("ethnicityRequired", val)}
          columns={1}
        />
        <div className="flex-column">
          <InputText
            wrapperClassName="flex-column"
            placeholder="More details (optional)"
            onBlur={(e) =>
              this.saveUserFieldData("ethnicityOptions", ethnicityOptions)
            }
            onChange={(e) =>
              this.setState({
                ethnicityOptions: e.target.value,
              })
            }
            value={ethnicityOptions}
          />
        </div>
      </div>
    );
  };

  _renderHeightRequirements = () => {
    const { heightPreference } = this.state;
    return (
      <HeightPreference
        list={HEIGHT_REQUIREMENTS}
        title="Any height requirements?"
        selectedValue={heightPreference}
        stateCallback={(val) => this.saveUserFieldData("heightPreference", val)}
      />
    );
  };

  _renderAgeRange = () => {
    const { ageFrom, ageTo, errors } = this.state;
    return (
      <div className="range-age-container">
        <div className={`${!ageFrom || !ageTo ? "label" : "label valid"}`}>
          What age range would you consider?
        </div>
        <div
          className={`flex-row height-input age-range
                            ${
                              ageFrom && ageTo && !errors.ageRangeError
                                ? " is-valid"
                                : ""
                            }`}
        >
          <InputText
            wrapperClassName="flex-column no-borders"
            placeholder="18"
            value={ageFrom}
            maxLength="2"
            onChange={(e) => {
              this.setState({
                ageFrom: e.target.value,
                errors: {
                  ...errors,
                  ageRangeError: null,
                },
              });
              this.processAgeRange(e.target.value, ageTo);
            }}
          />
          <span className="input-tag flex-center">to</span>
          <InputText
            wrapperClassName="flex-column small no-borders"
            placeholder="99"
            value={ageTo}
            maxLength="2"
            onChange={(e) => {
              this.setState({
                ageTo: e.target.value,
                errors: {
                  ...errors,
                  ageRangeError: null,
                },
              });
              this.processAgeRange(ageFrom, e.target.value);
            }}
          />
        </div>
        {errors.ageRangeError && (
          <span className="error-label">{errors.ageRangeError}</span>
        )}
      </div>
    );
  };


  _renderMatchAreas = () => {
    const { _id: userId } = this.props.user;
    const { saveUserFieldData } = this.props;
    const { matchArea, matchAreaDetails, matchAreaRange } = this.state;
    return (
      <div>
        <StatisticalAreas
          userId={userId}
          saveUserFieldData={saveUserFieldData}
          matchAreaRange={matchAreaRange}
          statisticalAreas={matchArea}
          matchAreaDetails={matchAreaDetails}
        />
      </div>
    );
  };

  _renderRelocationOptions = () => {
    const { relocationOptions } = this.state;
    return (
      <RadioList
        list={RELOCATION_OPTIONS}
        title="Are you open to moving outside of your metro area for a partner?"
        selectedValue={relocationOptions}
        stateCallback={(val) => this.changeData("relocationOptions", val)}
      />
    );
  };

  _renderClientStatus = () => {
    const { clientStatus } = this.state;
    const checked = clientStatus === "current";
    return (
      <div style={{ paddingBottom: 40, paddingTop: 20 }}>
        <CheckBox
          checked={checked}
          labelName={
            "Opt-in to premium matchmaking - $600/match, pay nothing up front"
          }
          clicked={
            checked
              ? () => this.changeData("clientStatus", "free")
              : () => this.changeData("clientStatus", "current")
          }
        />
      </div>
    );
  };

  processAgeRange = (ageFrom, ageTo) => {
    const { errors } = this.state;
    if (ageFrom && ageTo) {
      if (
        Validator.validateNumber(ageFrom) &&
        Validator.validateNumber(ageTo)
      ) {
        if (parseInt(ageFrom, 10) < 18 || parseInt(ageTo, 10) < 18) {
          this.setState({
            errors: {
              ...errors,
              ageRangeError: "Greater than 18",
            },
          });
          this.saveUserFieldData("ageRange", "");
        } else {
          if (parseInt(ageFrom, 10) > parseInt(ageTo, 10)) {
            const ageRange = `${ageTo} to ${ageFrom}`;
            this.saveUserFieldData("ageRange", ageRange);
          } else {
            const ageRange = `${ageFrom} to ${ageTo}`;
            this.saveUserFieldData("ageRange", ageRange);
          }
        }
      } else {
        this.setState({
          errors: {
            ...errors,
            ageRangeError: "Numbers only",
          },
        });
        this.saveUserFieldData("ageRange", "");
      }
    } else {
      this.saveUserFieldData("ageRange", "");
    }
  };

  changeData = (stateVal, val) => {
    this.saveUserFieldData(stateVal, val);
    this.setState({ [stateVal]: val });
  };

  saveUserFieldData = debounce((field, value) => {
    const { _id: userId } = this.props.user;
    this.props.saveUserFieldData(userId, field, value);
    this.props.calculatePercentage(this.state, 7, "partners");
  }, 500);
}

YourPartner.propTypes = {
  saveUserFieldData: PropTypes.func,
  calculatePercentage: PropTypes.func,
  setSectionFromNextButtons: PropTypes.func,
  user: PropTypes.object,
};

export default YourPartner;
