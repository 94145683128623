import UserSession from "../components/user-session";
import configureStore from "../store/configureStore";

const store = configureStore;

const SERVER_URI = "/";
//process.env.NODE_ENV === 'development' ? 'http://localhost:8096' : process.env.SERVER_URI;
class Actions {
  /**
   * Make a GET request
   *
   * @param {String} url API url to make request to
   * @param {String} [query] Query string
   * @param {Object} [requestAction] Action to be triggered before the request is made
   * @param {Object} [responseAction] Action to be triggered when the server responds
   * @param {Function} [callback] Function to be run after the server responds
   * @returns {Promise<void>}
   */
  static get({ url, query, requestAction, responseAction, callback }) {
    const request = { method: "GET", url, query };
    return this.makeRequest({
      request,
      requestAction,
      responseAction,
      callback,
    });
  }

  /**
   * Make a PUT request
   *
   * @param {String} url API url to make request to
   * @param {Object} data The data to be updated
   * @param {Object} [requestAction] Action to be triggered before the request is made
   * @param {Object} [responseAction] Action to be triggered when the server responds
   * @param {Function} [callback] Function to be run after the server responds
   * @returns {Promise<void>}
   */
  static put({ url, data, requestAction, responseAction, callback }) {
    const request = {
      method: "PUT",
      url,
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return this.makeRequest({
      request,
      requestAction,
      responseAction,
      callback,
    });
  }

  /**
   * Make a POST JSON request
   *
   * @param {String} url API url to make request to
   * @param {Object} data The data to be inserted
   * @param {Object} [requestAction] Action to be triggered before the request is made
   * @param {Object} [responseAction] Action to be triggered when the server responds
   * @param {Function} [callback] Function to be run after the server responds
   * @returns {Promise<void>}
   */
  static post({ url, data, requestAction, responseAction, callback }) {
    const request = {
      method: "POST",
      url,
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return this.makeRequest({
      request,
      requestAction,
      responseAction,
      callback,
    });
  }

  /**
   * Make a POST form data request
   *
   * @param {String} url API url to make request to
   * @param {Object} data The data to be inserted
   * @param {Object} [requestAction] Action to be triggered before the request is made
   * @param {Object} [responseAction] Action to be triggered when the server responds
   * @param {Function} [callback] Function to be run after the server responds
   * @returns {Promise<void>}
   */
  static postForm({ url, data, requestAction, responseAction, callback }) {
    const request = { method: "POST", url, body: data };
    return this.makeRequest({
      request,
      requestAction,
      responseAction,
      callback,
    });
  }

  /**
   * Make a DELETE request
   *
   * @param {String} url API url to make request to
   * @param {String} [query] Query string
   * @param {Object} [requestAction] Action to be triggered before the request is made
   * @param {Object} [responseAction] Action to be triggered when the server responds
   * @param {Function} [callback] Function to be run after the server responds
   * @returns {Promise<void>}
   */
  static delete({ url, query, requestAction, responseAction, callback }) {
    const request = { method: "DELETE", url, query };
    return this.makeRequest({
      request,
      requestAction,
      responseAction,
      callback,
    });
  }

  /**
   * Make a generic request
   *
   * @param {Object} request Request to be made. Must be of the form: {method, url, query [optional]}
   * @param {Object} [requestAction] Action to be triggered before the request is made
   * @param {Object} [responseAction] Action to be triggered when the server responds
   * @param {Function} [callback] Function to be run after the server responds
   * @returns {Promise<void>}
   */
  static makeRequest({ request, requestAction, responseAction, callback }) {
    const headers = request.headers || {};
    // If user is authenticated, send the auth. token on each request
    const { token } = UserSession.getToken();
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    // Dispatch the pre-request action
    store && requestAction && store.dispatch({ type: requestAction, request });
    // Don't set the body if it's a GET request as it will crash on Microsoft Edge
    const params = {
      headers,
      method: request.method || "GET",
    };
    if (params.method !== "GET") {
      params.body = request.body || null;
    }
    // Do the API Request
    return fetch(request.url, params)
      .then((res) => {
        return Promise.all([res.status, res.json()]);
      })
      .then((results) => {
        return { status: results[0], data: results[1] };
      })
      .then((response) => {
        // Not authorized
        if (response.status === 401 && window.location.pathname !== "/log-in") {
          if (response.data.invalidToken && response.data.invalidToken) {
            UserSession.clearToken();
            window.location.href = "/";
          } else {
            // Call the callback if provided
            callback && callback(response.message, response);

            // Dispatch the post-request action w/ data from the API
            store &&
              responseAction &&
              store.dispatch({ type: responseAction, response });
          }
        } else {
          // Dispatch the post-request action w/ data from the API
          store &&
            responseAction &&
            store.dispatch({ type: responseAction, response: response.data });

          // Call the callback if provided
          callback && callback(null, response);
        }

        return response;
      })
      .catch((error) => {
        // Dispatch the post-request action w/ error
        store &&
          responseAction &&
          store.dispatch({ type: responseAction, error });

        // Call the callback if provided
        callback && callback(error, null);
      });
  }
}

export default Actions;
