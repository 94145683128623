@import "../../../../src/scss/variables";

.radio-list {
  font-family: Montserrat, sans-serif;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &__header {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    .title {
      color: #ff6666;
      font-size: 18px;
      font-weight: 500;
      &.is-completed-green {
        color: $teal;
      }
    }
  }

  .uncheck-all {
    color: #ff596f;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 600;
    text-decoration: underline;
    padding: 10px 0 0 40px;
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
