import React, { useState } from "react";
import PropTypes from "prop-types";
import "./FamilySection.scss";
import TextArea from "src/components/TextArea";
import InputText from "_old/src/components/input-text";
import SelectWithLabel from "src/components/SelectWithLabel";
import { useDebounce } from "src/hooks/useDebounce";
import RadioList from "_old/src/components/radio-list";
import { QUALITIES } from "_old/src/constants/onboarding-data-elements";
import CheckBoxList from "_old/src/components/check-box-list/index";
import { 
    HAS_KIDS,
    FUTURE_CHILD_SCENARIOS_WITH_NO_KIDS,
    FUTURE_CHILD_SCENARIOS_WITH_KIDS,
    HAS_FROZEN_EGGS_SPERM,
    FAMILY_CLOSENESS,
    FAMILY_LOAN,
 } from "src/data/family-section";
import { LANGUAGES } from "src/data/languages";
import Select from "../Select";
import OldButton from "_old/src/components/button";
import { Button } from "src/components/widgets";
import { sortArrayByStringField } from "src/utils/functions";
import { set } from "lodash";
import debounce from "lodash.debounce";

/**
 * Family section of the singles onboarding form.
 *
 * @typedef {(key: string, value: any) => void} TSaveUserFieldDataFunction
 * @typedef {Object} TUserData - The user's data for the family section.
 * 
 * @property {Object[]} futureChildScenarios - The user's future child scenarios.
 * @property {Object} hasKids - The user's has kids preference.
 * @property {Object} kids - The user's kids data.
 * @property {Number} kids.nrOfKids - The number of kids the user has.
 * @property {Number[]} kids.kidsAges - The ages of the user's kids.
 * @property {Object} personalFrozenEggsOrSperm - The user's personal frozen eggs or sperm preference.
 * @property {String} kidsAnythingElse - The user's kids other information.
 * @property {String} familyAndUpbringing - The user's family and upbringing information.
 * @property {Object} familyCloseness - The user's family closeness answer.
 * @property {Object} familyLoan - The user's family loan answer.
 *
 * @typedef {Object} TFamilySectionProps
 * @property {TUserData} userData - The user's data for the family section.
 * @property {TSaveUserFieldDataFunction} saveUserFieldData - The function to save the user's data.
 * @property {Boolean} isTabletDown - Whether the screen is tablet size or smaller.
 *
 * @param {TFamilySectionProps} props
 * @returns {JSX.Element}
 */
const FamilySection = ({
  userData = {},
  saveUserFieldData = () => {},
  isTabletDown = false,
  setSectionFromNextButtons = () => {},
}) => {

  const handleChange = useDebounce((key, value) => {
      if (!key || !value) return;
      //TODO: Ideally, we should separate each section request to its own endpoint.
      saveUserFieldData(key, value);
  }, 500);

  // const [userData, setUserData] = useState(userData);
  const [futureChildScenarios, setFutureChildScenarios] = useState(
      userData.futureChildScenarios
  );
  const [hasKids, setHasKids] = useState(userData.hasKids);
  const [kids, setKids] = useState(userData.kids);
  const [nrOfKids, setNrOfKids] = useState(userData.kids.nrOfKids);
  const [kidsAges, setKidsAges] = useState(userData.kids.kidsAges);
  const [personalFrozenEggsOrSperm, setPersonalFrozenEggsOrSperm] = useState(userData.personalFrozenEggsOrSperm);
  const [kidsAnythingElse, setKidsAnythingElse] = useState(userData.kidsAnythingElse);
  const [familyAndUpbringing, setFamilyAndUpbringing] = useState(userData.familyAndUpbringing);
  const [familyCloseness, setFamilyCloseness] = useState(userData.familyCloseness);
  const [familyLoan, setFamilyLoan] = useState(userData.familyLoan);

  const hasKidsValue = hasKids && hasKids.id === 1;

  let debouncedSaveUserFieldData = debounce(saveUserFieldData, 285);

  const replaceFutureChildScenariosOnHasKidsChange = (val) => {
    const futureChildScenariosValues = futureChildScenarios.map(
      (scenario) => scenario.value
    );
    if (val.label === "No") {
      if (futureChildScenariosValues.includes("Having more of my own kids")) {
        // Swap "Having more of my own kids" for "Having my own kids"
        let newFutureChildScenarios = futureChildScenarios.filter(
          (scenario) => scenario.value !== "Having more of my own kids"
        );
        newFutureChildScenarios = [
          ...newFutureChildScenarios,
          FUTURE_CHILD_SCENARIOS_WITH_NO_KIDS[0],
        ];
        handleChange("futureChildScenarios", newFutureChildScenarios);
      }
    }
    if (val.label === "Yes") {
      if (futureChildScenariosValues.includes("Having my own kids")) {
        // Swap "Having my own kids" for "Having more of my own kids"
        let newFutureChildScenarios = futureChildScenarios.filter(
          (scenario) => scenario.value !== "Having my own kids"
        );
        newFutureChildScenarios = [
          ...newFutureChildScenarios,
          FUTURE_CHILD_SCENARIOS_WITH_KIDS[0],
        ];
        handleChange("futureChildScenarios", newFutureChildScenarios);
      }
    }
  };

  const handleNrOfKidsChange = (e) => {
    const newKidsAges =
      e.target.value < kidsAges.length
        ? kidsAges.slice(0, e.target.value)
        : kidsAges;

    debouncedSaveUserFieldData(
      "kids.nrOfKids",
      e.target.value,
      e.target.value < kidsAges.length
        ? () => handleChange("kids.kidsAges", newKidsAges)
        : undefined
    );
    setNrOfKids(parseInt(e.target.value) || null);
    setKidsAges(newKidsAges);
  };

  const nrOfInputsGivenByNumberOfKids =
    nrOfKids && Array.from(new Array(parseInt(nrOfKids, 10)), (_, x) => x);

  const handleChangeKidsAges = (value, index) => {
    const newKidsAges = [
      ...kidsAges.slice(0, index),
      value,
      ...kidsAges.slice(index + 1),
    ];
    setKidsAges(newKidsAges);
  };

  return (
    <section className="family-section">
      <div className="family-section__field-container">
        <RadioList
            list={HAS_KIDS}
            title="Do you have children?"
            selectedValue={hasKids}
            stateCallback={(val) => {
                replaceFutureChildScenariosOnHasKidsChange(val);
                setHasKids(val);
                handleChange("hasKids", val);
                if (val.label === "No") {
                    setNrOfKids(null);
                    setKidsAges([]);
                    handleChange("kids.nrOfKids", 0);
                    handleChange("kids.kidsAges", []);
                }
            }}
        />
      </div>
      {hasKids && hasKids.label === "Yes" && (
      <div className="family-section__field-container">
        <div className="kids-info">
        <div className={`${!nrOfKids ? "label" : "label valid"}`}>
            How many children do you have and what are their ages?
        </div>
          <div
            className={`flex-row kids-section kids ${
              nrOfKids ? " is-valid" : ""
            }`}
          >
            <InputText
              wrapperClassName="flex-column small no-borders"
              placeholder="0"
              value={nrOfKids}
              maxLength="2"
              type="number"
              onChange={handleNrOfKidsChange}
            />
            <span className="input-tag flex-center">kid(s)</span>
          </div>
          {!!nrOfKids &&
            nrOfInputsGivenByNumberOfKids.map((kid, k) => {
              let numberLabel = "th";
              if (k === 0) {
                numberLabel = "st";
              }
              if (k === 1) {
                numberLabel = "nd";
              }
              if (k === 2) {
                numberLabel = "rd";
              }
              return (
                <div className="flex-row kids-ages" key={k}>
                  <span className="kid-label">
                    {" "}
                    {kid + 1}
                    {`${numberLabel} kid's age`}
                  </span>
                  <InputText
                    wrapperClassName="flex-column kids-age"
                    placeholder="00"
                    value={ kidsAges && kidsAges.length > 0
                        ? kidsAges[k]
                        : null
                    }
                    maxLength="2"
                    isValid={
                      kidsAges && kidsAges.length > 0 ? kidsAges[k] : null
                    }
                    type="number"
                    onBlur={() =>
                      handleChange("kids.kidsAges", kidsAges)
                    }
                    onChange={(e) => handleChangeKidsAges(e.target.value, k)}
                  />
                </div>
              );
            })}
        </div>
      </div>
      )}

      <div className="family-section__field-container">
        <CheckBoxList
          required
          list={hasKidsValue ? FUTURE_CHILD_SCENARIOS_WITH_KIDS : FUTURE_CHILD_SCENARIOS_WITH_NO_KIDS}
          title={"Future child scenarios you're open to"}
          selectedValue={futureChildScenarios}
          stateCallback={(val) => debouncedSaveUserFieldData("futureChildScenarios", val)}
          columns={1}
        />
      </div>

      <div className="family-section__field-container">
        <RadioList
            list={HAS_FROZEN_EGGS_SPERM}
            title="Have you frozen any eggs/sperm?"
            selectedValue={personalFrozenEggsOrSperm}
            helperText="Helpful when talking about you to candidates who might otherwise say no based on age."
            stateCallback={(val) => {
              setPersonalFrozenEggsOrSperm(val);
              handleChange("personalFrozenEggsOrSperm", val);
            }}
        />
      </div>

      <div className="family-section__field-container">
        <h3 className="family-section__field-container__title">
          Any other details about kids?
        </h3>
        <TextArea
          defaultValue={kidsAnythingElse}
          onChange={(e) => handleChange("kidsAnythingElse", e.target.value)}
          afterLabel="Custody split, living arrangements, expected timeline, parenting style, etc."
        />
      </div>

      <div className="family-section__field-container">
        <h3 className="family-section__field-container__title">
          Describe your family & upbringing
        </h3>
        <TextArea
          defaultValue={familyAndUpbringing}
          onChange={(e) => handleChange("familyAndUpbringing", e.target.value)}
          afterLabel="How did it shape you?"
        />
      </div>

      <div className="family-section__field-container">
        <RadioList
            list={FAMILY_CLOSENESS}
            title="Are you (or were you) close with the family you grew up with?"
            selectedValue={familyCloseness}
            stateCallback={(val) => {
              setFamilyCloseness(val);
              handleChange("familyCloseness", val);
            }}
        />
      </div>

      <div className="family-section__field-container">
        <RadioList
            list={FAMILY_LOAN}
            title="Should you loan money to family?"
            selectedValue={familyLoan}
            stateCallback={(val) => {
              setFamilyLoan(val);
              handleChange("familyLoan", val);
            }}
        />
      </div>

      <div className="family-section__continue-button-container">
        {isTabletDown ? (
          <Button
            fullWidth
            variant="primary"
            onClick={() => setSectionFromNextButtons("partners")}
          >
            CONTINUE
          </Button>
        ) : (
          <OldButton
            value="CONTINUE"
            customClass="yellow"
            click={() => setSectionFromNextButtons("partners")}
          />
        )}
      </div>
    </section>
  );
};

FamilySection.propTypes = {
  userData: PropTypes.shape({
    hasKids: PropTypes.object,
    kids: PropTypes.object,
    futureChildScenarios: PropTypes.array,
  }),
  setSectionFromNextButtons: PropTypes.func,
  saveUserFieldData: PropTypes.func.isRequired,
  isTabletDown: PropTypes.bool,
};

export default FamilySection;
