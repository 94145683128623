import React from "react";
export const FilterIcon = () => {

    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
            <path d="M2.75004 6.41666H4.05537C4.2458 7.01504 4.62164 7.53732 5.12854 7.90795C5.63543 8.27859 6.2471 8.47835 6.87504 8.47835C7.50298 8.47835 8.11465 8.27859 8.62154 7.90795C9.12844 7.53732 9.50429 7.01504 9.69471 6.41666H19.25C19.4932 6.41666 19.7263 6.32009 19.8982 6.14818C20.0701 5.97627 20.1667 5.74311 20.1667 5.5C20.1667 5.25688 20.0701 5.02372 19.8982 4.85181C19.7263 4.67991 19.4932 4.58333 19.25 4.58333H9.69471C9.50429 3.98495 9.12844 3.46267 8.62154 3.09204C8.11465 2.7214 7.50298 2.52164 6.87504 2.52164C6.2471 2.52164 5.63543 2.7214 5.12854 3.09204C4.62164 3.46267 4.2458 3.98495 4.05537 4.58333H2.75004C2.50693 4.58333 2.27377 4.67991 2.10186 4.85181C1.92995 5.02372 1.83337 5.25688 1.83337 5.5C1.83337 5.74311 1.92995 5.97627 2.10186 6.14818C2.27377 6.32009 2.50693 6.41666 2.75004 6.41666Z" fill="#013B4D"/>
            <path d="M19.25 10.0833H17.9447C17.7543 9.48495 17.3784 8.96267 16.8715 8.59204C16.3646 8.2214 15.753 8.02164 15.125 8.02164C14.4971 8.02164 13.8854 8.2214 13.3785 8.59204C12.8716 8.96267 12.4958 9.48495 12.3054 10.0833H2.75004C2.50693 10.0833 2.27377 10.1799 2.10186 10.3518C1.92995 10.5237 1.83337 10.7569 1.83337 11C1.83337 11.2431 1.92995 11.4763 2.10186 11.6482C2.27377 11.8201 2.50693 11.9167 2.75004 11.9167H12.3054C12.4958 12.515 12.8716 13.0373 13.3785 13.408C13.8854 13.7786 14.4971 13.9784 15.125 13.9784C15.753 13.9784 16.3646 13.7786 16.8715 13.408C17.3784 13.0373 17.7543 12.515 17.9447 11.9167H19.25C19.4932 11.9167 19.7263 11.8201 19.8982 11.6482C20.0701 11.4763 20.1667 11.2431 20.1667 11C20.1667 10.7569 20.0701 10.5237 19.8982 10.3518C19.7263 10.1799 19.4932 10.0833 19.25 10.0833Z" fill="#013B4D"/>
            <path d="M19.25 15.5833H9.69471C9.50429 14.985 9.12844 14.4627 8.62154 14.092C8.11465 13.7214 7.50298 13.5216 6.87504 13.5216C6.2471 13.5216 5.63543 13.7214 5.12854 14.092C4.62164 14.4627 4.2458 14.985 4.05537 15.5833H2.75004C2.50693 15.5833 2.27377 15.6799 2.10186 15.8518C1.92995 16.0237 1.83337 16.2569 1.83337 16.5C1.83337 16.7431 1.92995 16.9763 2.10186 17.1482C2.27377 17.3201 2.50693 17.4167 2.75004 17.4167H4.05537C4.2458 18.015 4.62164 18.5373 5.12854 18.908C5.63543 19.2786 6.2471 19.4784 6.87504 19.4784C7.50298 19.4784 8.11465 19.2786 8.62154 18.908C9.12844 18.5373 9.50429 18.015 9.69471 17.4167H19.25C19.4932 17.4167 19.7263 17.3201 19.8982 17.1482C20.0701 16.9763 20.1667 16.7431 20.1667 16.5C20.1667 16.2569 20.0701 16.0237 19.8982 15.8518C19.7263 15.6799 19.4932 15.5833 19.25 15.5833Z" fill="#013B4D"/>
        </svg>
    )

}
