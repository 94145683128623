@import "../../../../src/scss/variables";

.check-box-list {
  font-family: Montserrat, sans-serif;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .checkbox-list-items {
    flex-wrap: wrap;
    gap: 0.5rem;
    height: 100%;

    &.half-size-list {
      max-height: 300px;
    }

    &.qualities-class {
      max-height: 500px;
    }

    .input-checkbox {
      width: 50%;
    }
  }

  &__wrapped-list {
    display: grid;
    grid-auto-flow: column;
    gap: 0.5rem;
  }

  &.no-padding-bottom {
    padding-bottom: 0;
  }

  &__header {
    font-size: 1.125rem;
    font-weight: 500;
    display: flex;
    flex-direction: column;

    &__title {
      color: #ff6666;
      font-size: 1.125rem;
      font-weight: 500;
      display: flex;

      &.is-completed-green {
        color: $teal;
      }
    }
  }
}
