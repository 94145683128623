/* eslint-disable import/no-named-as-default */
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { hot } from "react-hot-loader";
import LoginPage from "./containers/login";
import MatchMakerSignup from "./containers/sign-up/match-makers-signup";
import SinglesSignup from "./containers/sign-up";
import HomePage from "./containers/home-pages";
import AboutPage from "./containers/about";
import NotFoundPage from "./components/NotFoundPage";
import WelcomeOnBoardingSingles from "./containers/welcome-onboarding";
import SinglesBasics from "./containers/welcome-onboarding/basic-single-info";
import UserDashboard from "./containers/dashboards/user";
/* matchmaker containers */
import MatchMakerDashboard from "./containers/dashboards/matchmaker";
import MatchMakerDetails from "./containers/dashboards/matchmaker/maker-details";
/* admin containers */
import AdminDashboard from "./containers/dashboards/admin";
import PlanDetail from "./containers/dashboards/admin/plan-detail";
import UserEmailResponse from "./components/user-email-response/index";
import UserSession from "./components/user-session";
import USER_ROLES from "./constants/user-roles";
import Cookies from "js-cookie";

// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.
const ProtectedRoute = ({ ...args }) => {
  const user = UserSession.getToken();
  const token = user && user.token;
  return token ? <Route {...args} /> : <Redirect to="/login" />;
};

const setUTM = () => {
  let referQuery = decodeURIComponent(window.location.search.substring(1));
  let referVariables = referQuery.split("&");

  let i, ParameterName;
  const getUTMValue = (inputParameter) => {
    for (i = 0; i < referVariables.length; i++) {
      ParameterName = referVariables[i].split("=");
      if (ParameterName[0] === inputParameter) {
        return ParameterName[1] === null ? null : ParameterName[1];
      }
    }
  };

  const referParams = ["referrer"];

  referParams.forEach((param) => {
    var pValue = getUTMValue(param);

    if (pValue) {
      Cookies.set(param, pValue, { expires: 7 });
    }
  });
};

setUTM();

const RootRoute = ({ ...args }) => {
  const user = UserSession.getToken();
  const token = user && user.token;
  let component = null;
  switch (user.role) {
    case USER_ROLES.MATCHMAKER:
      component = MatchMakerDashboard;
      break;
    case USER_ROLES.ADMIN:
      component = AdminDashboard;
      break;
    case USER_ROLES.USER:
      component = UserDashboard;
      break;
  }
  return token ? (
    <Route {...args} component={component} />
  ) : (
    <Route {...args} component={HomePage} />
  );
};

class App extends React.Component {
  render() {
    return (
      <Switch>
        <RootRoute exact path="/" />

        {/* homepages */}
        <Route exact path="/for-singles" component={HomePage} />
        <Route exact path="/for-matchmakers" component={HomePage} />

        {/* misc */}
        <Route path="/team" component={HomePage} />
        <Route path="/contact" component={HomePage} />
        <Route path="/talk-to-a-matchmaker" component={HomePage} />
        <Route path="/about" component={AboutPage} />
        <Route path="/faqs" component={HomePage} />
        <Route path="/terms-of-service" component={HomePage} />
        <Route path="/privacy-policy" component={HomePage} />
        <ProtectedRoute path="/matchmaker-agreement" component={HomePage} />

        {/* authentication */}
        <Route exact path="/login" component={LoginPage} />

        {/* onboardings */}
        <ProtectedRoute path="/welcome" component={WelcomeOnBoardingSingles} />
        <ProtectedRoute path="/the-basics" component={SinglesBasics} />
        <ProtectedRoute path="/more-about" component={SinglesBasics} />
        <Route path="/welcome-partners" component={MatchMakerSignup} />
        <Route path="/welcome-singles" component={SinglesSignup} />
        <ProtectedRoute path="/your-partners" component={SinglesBasics} />
        <Route path="/photos" component={SinglesBasics} />
        <ProtectedRoute path="/account" component={SinglesBasics} />

        {/* matchmakers routes */}
        <ProtectedRoute path="/my-favorites" component={MatchMakerDashboard} />
        <ProtectedRoute path="/my-contacted" component={MatchMakerDashboard} />
        <ProtectedRoute path="/my-locks" component={MatchMakerDashboard} />
        <ProtectedRoute
          path="/matchmaker-plans"
          component={MatchMakerDashboard}
        />
        <ProtectedRoute
          path="/matchmaker-referrals"
          component={MatchMakerDashboard}
        />

        {/* admin routes */}
        <ProtectedRoute
          path="/matchmakers-requests"
          component={AdminDashboard}
        />
        <ProtectedRoute path="/locks-section" component={AdminDashboard} />
        <ProtectedRoute path="/completed-section" component={AdminDashboard} />
        <ProtectedRoute path="/matchmakers-list" component={AdminDashboard} />
        <ProtectedRoute path="/maker-details" component={MatchMakerDetails} />
        <ProtectedRoute
          path="/user-response/:id"
          component={UserEmailResponse}
        />
        <ProtectedRoute path="/plans" component={AdminDashboard} />
        <ProtectedRoute path="/plan-details" component={PlanDetail} />

        {/* not found routes */}
        <Route component={NotFoundPage} />
      </Switch>
    );
  }
}

App.propTypes = {
  children: PropTypes.element,
};

export default hot(module)(App);
