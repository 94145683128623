import React from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  PinterestShareButton,
  PinterestIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";

import shareIcon from "../../images/share.svg";

const showMessage = (message) => {
  const copyMessage = document.getElementById("copy-url-to-clipboard-success");
  copyMessage.innerHTML = message;
  copyMessage.classList.add("show");
  setTimeout(() => {
    copyMessage.classList.remove("show");
  }, 3000);
};

const copyToClipboard = () => {
  navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
    if (result.state === "granted" || result.state === "prompt") {
      const copyText = document.getElementById("copy-url-to-clipboard");
      navigator.clipboard.writeText(copyText.value).then(
        function () {
          /* clipboard successfully set */
          showMessage(`Copied: ${copyText.value}`);
        },
        function () {
          /* clipboard write failed */
          showMessage(`Copy failed.`);
        }
      );
    } else {
      showMessage(`Can't copy to your clipboard.`);
    }
  });
};

class CopyLink extends React.Component {
  render() {
    return (
      <div
        className="SocialMediaShareButton SocialMediaShareButton--copy"
        onClick={copyToClipboard}
      >
        <input
          type="text"
          id="copy-url-to-clipboard"
          value={window.location.href}
          tabindex="0"
        />
        <img src={shareIcon} alt="Share link" width="30" height="30" />
        <p id="copy-url-to-clipboard-success">{}</p>
      </div>
    );
  }
}

/*
  launchpad component: ShareIcons
  categories: widgets
  Buttons fixed to top right corner of the screen that will allow sharing of whatever
  page they're loaded on
*/
export const ShareIcons = () => {
  const shareProps = { url: window.location.href };
  const buttonProps = { size: 36 };
  return (
    <div className="share-icons">
      <FacebookShareButton {...shareProps}>
        <FacebookIcon {...buttonProps} />
      </FacebookShareButton>
      <TwitterShareButton {...shareProps}>
        <TwitterIcon {...buttonProps} />
      </TwitterShareButton>
      <LinkedinShareButton {...shareProps}>
        <LinkedinIcon {...buttonProps} />
      </LinkedinShareButton>
      <CopyLink />
      {/* <EmailShareButton {...shareProps} subject={`Check out this article from the DateSpot blog - thought you'd enjoy!`} body={`Article: `}><EmailIcon {...buttonProps} /></EmailShareButton> */}
      {/* needs image */}
      {/* <PinterestShareButton {...shareProps} /> */}
    </div>
  );
};
