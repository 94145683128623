import React, { useState } from "react";
import PropTypes from "prop-types";
import "./BackgroundSection.scss";
import InputText from "_old/src/components/input-text";
import { useDebounce } from "src/hooks/useDebounce";
import RadioList from "_old/src/components/radio-list";
import CheckBoxList from "_old/src/components/check-box-list/index";
import { 
    LEVEL_OF_EDUCATION,
    YEARLY_INCOME,
    NET_WORTH,
    EMPLOYMENT_STATUSES,
    TATTOOS,
    PARTNER_TATTOOS,
    POLITICAL_ORIENTATION,
    POLITICAL_PREFERENCE,
    RELIGION,
    RELIGION_PREFERENCE
 } from "src/data/background-section";
import OldButton from "_old/src/components/button";
import { Button } from "src/components/widgets";
import debounce from "lodash.debounce";
import RadioHorizontalList from "_old/src/components/radio-list-horizontal";
import TextArea from "src/components/TextArea";

/**
 * Family section of the singles onboarding form.
 *
 * @typedef {(key: string, value: any) => void} TSaveUserFieldDataFunction
 * @typedef {Object} TUserData - The user's data for the Lifestyle section.
 * @property {Object} education - The user's education level.
 * @property {String} schoolAttended - The user's school attended.
 * @property {Object} employmentStatus - The user's employment status.
 * @property {String} occupation - The user's occupation.
 * @property {String} company - The user's company.
 * @property {Object[]} bodyTypes - The user's body types.
 * @property {Object[]} eatingHabits - The user's eating habits.
 * @property {Object} workout - The user's workout frequency.
 * @property {Object} drinking - The user's drinking frequency.
 * @property {Object} smoking - The user's smoking frequency.
 * @property {Boolean} does_drugs_bool - The user's drug usage.
 * @property {Object[]} does_drugs_types - The user's drug types.
 * @property {Boolean} does_drugs_partner_bool - The user's partner's drug usage.
 * @property {Object[]} does_drugs_partner - The user's partner's drug types.
 * @property {Object} tattoos - The user's tattoo status.
 * @property {Object} partnerTattoos - The user's partner's tattoo status.
 * @property {Object} yearlyIncome - The user's yearly income.
 * @property {Object} netWorthEstimate - The user's net worth estimate.
 * @property {Object} financialStability - The user's financial stability.
 * @property {Object} pets - The user's pets.
 * 
 *
 * @typedef {Object} TLifestyleSectionProps
 * @property {TUserData} userData - The user's data for the Lifestyle section.
 * @property {TSaveUserFieldDataFunction} saveUserFieldData - The function to save the user's data.
 * @property {Boolean} isTabletDown - Whether the screen is tablet size or smaller.
 *
 * @param {TLifestyleSectionProps} props
 * @returns {JSX.Element}
 */
const BackgroundSection = ({
  userData = {},
  saveUserFieldData = () => {},
  isTabletDown = false,
  setSectionFromNextButtons = () => {},
}) => {

    const handleChange = useDebounce((key, value) => {
        if (!key || !value) return;
        //TODO: Ideally, we should separate each section request to its own endpoint.
        saveUserFieldData(key, value);
    }, 500);

    const [userId, setUserId] = useState(userData.id);
    const [religionPracticed, setReligionPracticed] = useState(userData.religionPracticed);
    const [religionPreference, setReligionPreference] = useState(userData.religionPreference);
    const [politicalOrientation, setPoliticalOrientation] = useState(userData.politicalOrientation);
    const [politicalPreference, setPoliticalPreference] = useState(userData.politicalPreference);
    const [upbringing, setUpbringing] = useState(userData.upbringing);
    const [education, setEducation] = useState(userData.education);
    const [schoolAttended, setSchoolAttended] = useState(userData.schoolAttended);
    const [employmentStatus, setEmploymentStatus] = useState(userData.employmentStatus);
    const [occupation, setOccupation] = useState(userData.occupation);
    const [company, setCompany] = useState(userData.company);
    const [tattoos, setTattoos] = useState(userData.tattoos);
    const [partnerTattoos, setPartnerTattoos] = useState(userData.partnerTattoos);
    const [yearlyIncome, setYearlyIncome] = useState(userData.yearlyIncome);
    const [netWorthEstimate, setNetWorthEstimate] = useState(userData.netWorthEstimate);


    const workingStatusValues = [
        EMPLOYMENT_STATUSES[0].value,
        EMPLOYMENT_STATUSES[1].value,
      ];

    const currentlyWorks = workingStatusValues.includes(employmentStatus);

    return (
        <section className="lifestyle-section">

            <div className="lifestyle-section__field-container">
                <CheckBoxList
                    required
                    list={RELIGION}
                    title="What do you identify with religiously?"
                    selectedValue={religionPracticed}
                    stateCallback={(val) => {
                        handleChange("religionPracticed", val);
                        setReligionPracticed(val);
                    }}
                    returnInputValue={(val) => {
                        handleChange("religionPracticed", val);
                        setReligionPracticed(val);
                    }}
                    columns={1}
                />
            </div>

            <div className="lifestyle-section__field-container">
                <RadioHorizontalList
                    list={RELIGION_PREFERENCE}
                    title="Importance of religious alignment"
                    selectedValue={religionPreference}
                    stateCallback={(val) => {
                        handleChange("religionPreference", val);
                        setReligionPreference(val);
                    }}
                    startLabel="Not important"
                    endLabel="Very important"
                />
            </div>

            <div className="lifestyle-section__field-container">
                <RadioList
                    list={POLITICAL_ORIENTATION}
                    title="What do you identify as politically?"
                    selectedValue={politicalOrientation}
                    returnInputValue={(val) => {
                        handleChange("politicalOrientation", val);
                        setPoliticalOrientation(val);
                    }}
                    stateCallback={(val) => {
                        handleChange("politicalOrientation", val);
                        setPoliticalOrientation(val);
                    }}
                />
            </div>

            <div className="lifestyle-section__field-container">
                <RadioHorizontalList
                    list={POLITICAL_PREFERENCE}
                    title="Importance of political alignment"
                    selectedValue={politicalPreference}
                    stateCallback={(val) => {
                        handleChange("politicalPreference", val);
                        setPoliticalPreference(val);
                    }}
                    startLabel="Not important"
                    endLabel="Very important"
                />
            </div>

            <div className="lifestyle-section__field-container">
                <div className="flex-column">
                    <div className="labels-container">
                        <div className="label valid">
                            Where are you from?
                        </div>
                    </div>
                    <TextArea
                        defaultValue={upbringing}
                        afterLabel="You can describe your upbringing more in the Family section."
                        onChange={(e) => {
                            handleChange("upbringing", e.target.value);
                        }}
                        rows={"1"}
                    />
                </div>
            </div>

            <div className="lifestyle-section__field-container">
                <RadioList
                    list={LEVEL_OF_EDUCATION}
                    title="Highest level of education"
                    selectedValue={education}
                    stateCallback={(val) => {
                        handleChange("education", val);
                        setEducation(val);
                        if (val.id < 4) setSchoolAttended(null);
                    }}
                />
            </div>

            {education?.id >= 4 ? (
                <div className="lifestyle-section__field-container">
                    <InputText
                        wrapperClassName="flex-column"
                        label="Higher education school(s) attended"
                        showLabel={true}
                        classNameForLabel="show-label greenish"
                        onBlur={(e) => handleChange("schoolAttended", schoolAttended)}
                        onChange={(e) => {
                            handleChange("schoolAttended", e.target.value);
                            setSchoolAttended(e.target.value);
                        }}
                        value={schoolAttended}
                    />
                </div>
            ) : (<></>)}

            <div className="lifestyle-section__field-container">
                <RadioList
                    list={EMPLOYMENT_STATUSES}
                    title="What's your employment status?"
                    selectedValue={employmentStatus}
                    stateCallback={(val) => {
                        handleChange("employmentStatus", val);
                        setEmploymentStatus(val);
                    }}
                />
                <InputText
                    wrapperClassName="flex-column"
                    label="Occupation"
                    showLabel
                    classNameForLabel={`${
                        currentlyWorks && !occupation
                            ? "show-label redish"
                            : "show-label greenish"
                    }`}
                    onBlur={(e) => handleChange("occupation", occupation)}
                    onChange={(e) => {
                        handleChange("occupation", e.target.value);
                        setOccupation(e.target.value);
                    }}
                    value={occupation}
                />
                <InputText
                    wrapperClassName="flex-column"
                    label="Company/Organization"
                    showLabel
                    afterLabel="Not visible to our partner matchmakers"
                    classNameForLabel={`${
                        currentlyWorks && !company
                            ? "show-label redish"
                            : "show-label greenish"
                    }`}
                    onBlur={(e) => handleChange("company", company)}
                    onChange={(e) => setCompany(e.target.value)}
                    value={company}
                />
            </div>

            <div className="lifestyle-section__field-container">
                <RadioList
                    list={YEARLY_INCOME}
                    title="What is your average yearly income?"
                    selectedValue={yearlyIncome}
                    stateCallback={(val) => {
                        handleChange("yearlyIncome", val);
                        setYearlyIncome(val);
                    }}
                />
                <RadioList
                    list={NET_WORTH}
                    title="What is your net worth estimate?"
                    selectedValue={netWorthEstimate}
                    stateCallback={(val) => {
                        handleChange("netWorthEstimate", val);
                        setNetWorthEstimate(val);
                    }}
                />
            </div>

            <div className="lifestyle-section__field-container">
                <RadioList
                    list={TATTOOS}
                    title="Do you have any tattoos?"
                    selectedValue={tattoos}
                    stateCallback={(val) => {
                        handleChange("tattoos", val);
                        setTattoos(val);
                    }}
                />
            </div>

            <div className="lifestyle-section__field-container">
                <RadioList
                    list={PARTNER_TATTOOS}
                    title="Could you be with someone who has a tattoo?"
                    selectedValue={partnerTattoos}
                    stateCallback={(val) => {
                        handleChange("partnerTattoos", val);
                        setPartnerTattoos(val);
                    }}
                />
            </div>

            <div className="lifestyle-section__continue-button-container">
                {isTabletDown ? (
                    <Button
                        fullWidth
                        variant="primary"
                        onClick={() => setSectionFromNextButtons("lifestyle")}
                    >
                        CONTINUE
                    </Button>
                ) : (
                    <OldButton
                        value="CONTINUE"
                        customClass="yellow"
                        click={() => setSectionFromNextButtons("lifestyle")}
                    />
                )}
            </div>
        </section>
    );
};

BackgroundSection.propTypes = {
    userData: PropTypes.shape({}),
    setSectionFromNextButtons: PropTypes.func,
    saveUserFieldData: PropTypes.func.isRequired,
    isTabletDown: PropTypes.bool,
};

export default BackgroundSection;
