export const LEVEL_OF_EDUCATION = [
    { value: "Less than high school", label: "Less than high school", id: 1 },
    { value: "High school diploma", label: "High school diploma", id: 2 },
    {
      value: "Some college, no degree",
      label: "Some college, no degree",
      id: 3,
    },
    { value: "Associates degree", label: "Associates degree", id: 4 },
    { value: "Bachelors degree", label: "Bachelors degree", id: 5 },
    { value: "Masters degree", label: "Masters degree", id: 6 },
    { value: "Doctoral degree", label: "Doctoral degree", id: 7 },
  ];
  
  export const EMPLOYMENT_STATUSES = [
    { value: "employee_contractor_temp", label: "Employee/contractor/temp" },
    { value: "self_employed", label: "Self-employed" },
    {
      value: "unemployed_in_between_jobs",
      label: "Unemployed (in between jobs)",
    },
    { value: "retired", label: "Retired" },
  ];
  
  export const BODY_TYPE = [
    { value: "Thin", label: "Thin", id: 1 },
    { value: "Muscular/Athletic", label: "Muscular/Athletic", id: 2 },
    { value: "Average", label: "Average", id: 3 },
    { value: "Curvy/Well-endowed", label: "Curvy/Well-endowed", id: 4 },
    { value: "A few extra pounds", label: "A few extra pounds", id: 5 },
    {
      value: "More than a few extra pounds",
      label: "More than a few extra pounds",
      id: 6,
    },
  ];

  export const YEARLY_INCOME = [
    { value: "0_59k", label: "$0-$59K" },
    { value: "60k_99k", label: "$60K-$99K" },
    { value: "100k_199k", label: "$100K-$199K" },
    { value: "200k_499k", label: "$200K-$499K" },
    { value: "500k_999k", label: "$500K-$999K" },
    { value: "1m+", label: "$1M+" },
  ];

  export const NET_WORTH = [
    { value: "0_99k", label: "$0-$99K" },
    { value: "100k_499k", label: "$100K-$499K" },
    { value: "500k_999k", label: "$500K-$999K" },
    { value: "1m_4.9m", label: "$1M-$4.9M" },
    { value: "5m+", label: "$5M+" },
    { value: "dont_know", label: "Don't know" },
  ];

  export const TATTOOS = [
    { 
        value: "No, and never would", 
        label: "No, and never would" 
    },
    { 
        value: "No, but would consider one", 
        label: "No, but would consider one" 
    },
    {
        value: "Yes, one discreet (generally hidden)",
        label: "Yes, one discreet (generally hidden)",
    },
    {
        value: "Yes, more than one, or it's easily seen",
        label: "Yes, more than one, or it's easily seen",
    },
  ];

  export const PARTNER_TATTOOS = [
    {
        value: "No, that type of person is not for me",
        label: "No, that type of person is not for me",
    },
    {
        value: "Maybe, if it's one that's discreet",
        label: "Maybe, if it's one that's discreet",
    },
    { 
        value: "Sure, multiple can be fine", 
        label: "Sure, multiple can be fine" 
    },
    {
        value: "Definitely, they're cool and sexy",
        label: "Definitely, they're cool and sexy",
    },
  ];

  export const FINANCIAL_STABILITY = [
    { value: 1, label: "1", id: 1 },
    { value: 2, label: "2", id: 2 },
    { value: 3, label: "3", id: 3 },
    { value: 4, label: "4", id: 4 },
    { value: 5, label: "5", id: 5 },
  ];

export const RELIGION_PREFERENCE = [
    { value: 1, label: "1", id: 1 },
    { value: 2, label: "2", id: 2 },
    { value: 3, label: "3", id: 3 },
    { value: 4, label: "4", id: 4 },
    { value: 5, label: "5", id: 5 },
];

export const RELIGION = [
    { value: "Agnostic", label: "Agnostic", id: 1 },
    { value: "Atheist", label: "Atheist", id: 2 },
    { value: "Buddhist", label: "Buddhist", id: 3 },
    { value: "Catholic", label: "Catholic", id: 4 },
    { value: "Christian", label: "Christian", id: 5 },
    { value: "Jewish", label: "Jewish", id: 6 },
    { value: "Hindu", label: "Hindu", id: 7 },
    { value: "Latter-day Saints", label: "Latter-day Saints", id: 8 },
    { value: "Muslim", label: "Muslim", id: 9 },
    { value: "Sikh", label: "Sikh", id: 10 },
    { value: "Spiritual but not religious", label: "Spiritual but not religious", id: 11 },
    { value: "Other", label: "Other", id: 12 },
];

export const POLITICAL_PREFERENCE = [
    { value: 1, label: "1", id: 1 },
    { value: 2, label: "2", id: 2 },
    { value: 3, label: "3", id: 3 },
    { value: 4, label: "4", id: 4 },
    { value: 5, label: "5", id: 5 },
];

export const POLITICAL_ORIENTATION = [
    {
        value: "Democrat",
        label: "Democrat",
        id: 1
    },
    {
        value: "Republican",
        label: "Republican",
        id: 2
    },
    {
        value: "Moderate",
        label: "Moderate",
        id: 3,
    },
    {
        value: "Independent",
        label: "Independent",
        id: 4
    },
    {
        value: "Apolitical",
        label: "Apolitical",
        id: 5
    },
];