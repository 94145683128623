@import "../../../../src/scss/variables";

.radio-list-horizontal {
  gap: 0.5rem;
  
  &__label {
    color: #ff6666;
    font-family: Montserrat, sans-serif;
    font-size: 18px;
    margin-bottom: 4px;
    font-weight: 500;
    &.green {
      color: $teal;
    }
  }
  .start-label,
  .end-label {
    color: rgba(84, 88, 90, 0.5);
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 600;
  }
  &__buttons {
    border: 1px solid rgba(84, 88, 90, 0.2);
    border-radius: 19px;
    background-color: #fff;
    height: 34px;
    margin: 0 12px;
    width: 185px;
  }
  &__button {
    cursor: pointer;
    color: rgba(84, 88, 90, 0.5);
    font-size: 16px;
    height: 36px;
    width: 37px;
    &:first-child {
      border-top-left-radius: 19px;
      border-bottom-left-radius: 19px;
    }
    &:last-child {
      border-top-right-radius: 19px;
      border-bottom-right-radius: 19px;
    }
    &:hover {
      font-size: 19px;
    }
    &.selected {
      background: #ff476b;
      color: #fff;
      font-size: 19px;
    }
  }
}
