import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  MATCH_MAKER_TABLE_HEADERS,
  USER_STATUS_VALUES,
} from "../../../../constants/index";
import { getInitials } from "../../../../components/util-functions";
import "../../user-list.scss";

class ContactedList extends Component {
  state = {
    openedStatusMenu: false,
    hovered: null,
  };

  openStatusMenu = (userId, openMenuUpwards) => {
    return (
      <div
        className={`user-relationship-status user-menu flex-column ${
          openMenuUpwards ? "open-upwards" : ""
        }`}
      >
        {USER_STATUS_VALUES.map((rel) => (
          <div
            key={rel.id}
            onClick={() => {
              this.setState({ openedStatusMenu: false });
              this.props.matchMakerActions.addChangeUserRelationshipStatus(
                userId,
                rel.value
              );
            }}
          >
            <span>{rel.label}</span>
          </div>
        ))}
      </div>
    );
  };

  render() {
    const { filteredUsers, filtersHeight, matchMakerId } = this.props;
    const { hovered, openedStatusMenu } = this.state;
    const connectionsList = filteredUsers.filter(
      (
        user // We only show users we tried to contact
      ) =>
        user.connectionsRequests.find(
          (conn) => conn.matchMakerId === matchMakerId && !conn.isLocked
        )
    );
    const readjustedHeight = `${248 + filtersHeight}px`;
    const listHeight = filtersHeight
      ? `calc(100vh - ${readjustedHeight})`
      : "calc(100vh - 234px)";
    const openMenuUpwards =
      this.selUserRef && this.selUserRef.getBoundingClientRect().top > 480;
    return (
      <div className="users-list-container contacted flex-column">
        <div className="labels contacted flex-row">
          {MATCH_MAKER_TABLE_HEADERS.contacted.map((tab, t) => {
            return (
              <span
                className={tab.class}
                key={t}
                style={{ width: `${tab.width}px` }}
              >
                {tab.name}
              </span>
            );
          })}
        </div>
        <div className="users-list" style={{ height: listHeight }}>
          {connectionsList.map((user, u) => {
            const { connectionsRequests } = user;
            const connectionRequestSentByMyself = connectionsRequests.find(
              (conn) => conn.matchMakerId === matchMakerId
            );
            const requestStatus =
              connectionRequestSentByMyself &&
              connectionRequestSentByMyself.status;
            const requestAccepted = requestStatus === "APPROVED";
            const isHoveredItem = hovered === user._id;
            const userRelStatus = user.userRelationshipStatuses.map((st) =>
              st.matchMakerId === matchMakerId ? st.status : null
            );
            const renderContactedUser = this._renderContactedUser(
              user,
              requestAccepted,
              userRelStatus,
              openedStatusMenu,
              openMenuUpwards,
              requestStatus,
              isHoveredItem
            );
            return (
              <div
                className={`${
                  isHoveredItem
                    ? "users-list__item hovered"
                    : "users-list__item"
                }`}
                ref={
                  isHoveredItem
                    ? (ref) => {
                        this.selUserRef = ref;
                      }
                    : null
                }
                key={u}
                onMouseEnter={() => this.setState({ hovered: user._id })}
                onMouseLeave={() =>
                  this.setState({
                    hovered: null,
                    openedStatusMenu: false,
                  })
                }
              >
                {renderContactedUser}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  _renderContactedUser = (
    user,
    requestAccepted,
    userRelStatus,
    openedStatusMenu,
    openMenuUpwards,
    requestStatus,
    isHoveredItem
  ) => {
    const { matchMakerActions } = this.props;
    return (
      <div className="users-list__item-container flex-row">
        <div
          className="flex-row user-info"
          onClick={() => matchMakerActions.getClickedUserInfo(user._id)}
        >
          {user.images && user.images[0] ? (
            <img
              src={user.images[0].url}
              alt=""
              className="avatar flex-center"
            />
          ) : (
            <span className="avatar flex-center">{getInitials(user)}</span>
          )}
          <div className="member element flex-column">
            <span>#{user.number}</span>
            {requestAccepted && (
              <span>
                {user.firstName} {user.lastName.charAt(0)}
              </span>
            )}
          </div>
          <span className="gender element">
            {user.gender && user.gender.value}
          </span>
          <div className={`connection-status element ${requestStatus}`}>
            {requestStatus}
          </div>
          <span className="rel-status element">{userRelStatus}</span>
        </div>
        {requestAccepted ? (
          <div className="show-more">
            <span
              className="icon-more"
              onClick={() =>
                this.setState({ openedStatusMenu: !openedStatusMenu })
              }
            />
            {isHoveredItem && openedStatusMenu
              ? this.openStatusMenu(user._id, openMenuUpwards)
              : null}
          </div>
        ) : (
          <span style={{ display: `${requestAccepted ? "block" : "none"}` }}>
            Add status
          </span>
        )}
      </div>
    );
  };
}

ContactedList.propTypes = {
  history: PropTypes.object,
  generalActions: PropTypes.object.isRequired,
  generalReducer: PropTypes.object,
  matchMakerActions: PropTypes.object.isRequired,
  matchmakersReducer: PropTypes.object.isRequired,
  filteredUsers: PropTypes.array.isRequired,
  filtersHeight: PropTypes.any,
  matchMakerId: PropTypes.string,
};

export default ContactedList;
