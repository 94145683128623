import React, { Component } from "react";
import PropTypes from "prop-types";
import InputText from "../input-text";
import Button from "../button";
import Validator from "../utils/validator";
import LinkState from "../utils/link-state";
import { isEmpty } from "../util-functions";
import { Link } from "widgets";

import "./style.scss";

class LoginForm extends Component {
  state = {
    email: null,
    password: null,
    errors: Validator.createErrorMap(VALIDATION_MAP),
    generalError: null,
  };

  login = () => {
    const { email, password } = this.state;

    // Validate on submit
    const { isValid, errors } = Validator.validateState(
      this.state,
      VALIDATION_MAP
    );
    if (!isValid) {
      this.setState({ errors });
      return;
    }
    // Dispatch the login action
    this.props.loginUser(email, password, this.props.loginCallback);
  };

  componentDidUpdate() {
    let { lastUpdatedField } = this.state;
    const { errors } = this.state;
    if (lastUpdatedField) {
      // Check for trimming
      const nonTrimmedField = this.state[lastUpdatedField];
      if (nonTrimmedField !== nonTrimmedField.trim()) {
        this.setState({ [lastUpdatedField]: nonTrimmedField.trim() });
      }

      // Remove any errors
      errors[lastUpdatedField] = null;
      lastUpdatedField = null;
      this.props.removeError();
      this.setState({ lastUpdatedField, errors });
    }
  }

  static getDerivedStateFromProps = (props) => {
    if (props.errors) {
      return {
        generalError: props.errors,
      };
    }
    return {
      generalError: null,
    };
  };

  render() {
    const { loading, generalError } = this.state;
    const { email, password } = this.state.errors;
    const isDisabledButton = !email && !password && loading;
    const { history } = this.props;
    let generalErrorMessage = null;

    if (generalError) {
      generalErrorMessage = (
        <span className="general-error-message">{generalError}</span>
      );
    }

    const hasGeneralError = !isEmpty(generalError) && !email && !password;

    return (
      <div className="login-container__form">
        <InputText
          wrapperClassName="flex-column"
          placeholder="Email"
          type="email"
          onEnterKeyPressed={this.login.bind(this)}
          error={email || hasGeneralError}
          onChange={LinkState.bind(this, "email")}
          value={this.state.email}
        />
        <InputText
          wrapperClassName="flex-column"
          placeholder="Password"
          type="password"
          error={password || hasGeneralError}
          onChange={LinkState.bind(this, "password")}
          onEnterKeyPressed={() => this.login()}
          value={this.state.password}
        />

        {generalErrorMessage}

        <div className="flex-column flex-center" style={{ marginTop: "40px" }}>
          <Button
            click={() => this.login()}
            value="LOG IN"
            customClass="two-twenty-width yellow"
            disabled={isDisabledButton}
          />
          <Link href="/forgot-password" className="forgot-password">
            Forgot password
          </Link>
        </div>

        <div className="want-to-be-matchmaker">Don’t have an account?</div>
        <div className="flex-center">
          <Button
            click={() => {
              history.push({ pathname: "/welcome-singles" });
            }}
            value="SIGN UP FREE"
            customClass="two-twenty-width"
          />
        </div>
      </div>
    );
  }
}

const VALIDATION_MAP = {
  email: { email: true },
  password: { required: true, password: true },
};

LoginForm.propTypes = {
  history: PropTypes.object,
  loginUser: PropTypes.func,
  loginCallback: PropTypes.func,
  removeError: PropTypes.func,
};

export default LoginForm;
