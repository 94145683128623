import React, { forwardRef } from "react";

const Modal = forwardRef(
    ({ open, onClose, title, children, footer, header, customClass }, ref) => {
        return (
            <div className={`dashboard-modal-overlay ${open ? "open" : ""}`}>
                <div
                    className={`dashboard-modal-panel ${customClass ? customClass : ""}`}
                >
                    <div className="dashboard-modal-header">
                        <span>{title}</span> {header || <span />}{" "}
                        <span
                            onClick={onClose}
                            className="dashboard-modal-close fa fa-times"
                        />
                    </div>
                    <div className="dashboard-modal-body" ref={ref}>{children}</div>
                    {footer ? (
                        <div className="dashboard-modal-footer">{footer}</div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        );
    }
);

Modal.displayName = "Modal";

export default Modal;