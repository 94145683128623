import Api from "./api";
import * as types from "../constants/actionTypes";
import configureStore from "../store/configureStore";

const store = configureStore;

export function requestAccessForUser(user) {
  Api.post({
    url: "/api/request-access-for-user",
    data: { user },
    responseAction: types.REQUEST_ACCESS_FOR_USER,
  });
}

export function cancelRequestAccessForUser(userId) {
  Api.post({
    url: "/api/cancel-request-for-user",
    data: { userId },
    responseAction: types.CANCEL_REQUEST_ACCESS_FOR_USER,
  });
}

export function loadMyMatches() {
  return () => {
    Api.get({
      url: "/api/matchmaker/load-matchmaker-matches",
      responseAction: types.LOAD_MATCHMAKER_MATCHES,
    });
  };
}

export function saveUserResponse(answer, requestId) {
  Api.post({
    url: "/api/get-selected-single-response",
    data: {
      answer,
      requestId,
    },
  });
}

export const lockSelectedUser = (userId) => {
  return Api.post({
    url: "/api/lock-selected-user",
    data: { userId },
    responseAction: types.LOCK_USER,
  }).then((res) => {
    setTimeout(loadLoggedMMPlan(), 2000);
    return res;
  });
};

export const unLockSelectedUser = (userId) => {
  return Api.post({
    url: "/api/unlock-selected-user",
    data: { userId },
    responseAction: types.UN_LOCK_USER,
  });
};

export function loadFavoritesList() {
  return () => {
    Api.get({
      url: "/api/get-favorite-users",
      responseAction: types.LOAD_FAVORITE_USERS,
    });
  };
}

export function addUserToFavorites(userId) {
  return Api.post({
    url: "/api/add-user-to-favorites",
    data: { userId },
    responseAction: types.ADD_USER_TO_FAVORITES,
  });
}

export function removeUserFromFavorites(requestId) {
  return Api.post({
    url: "/api/remove-user-from-favorites",
    data: { requestId },
    responseAction: types.REMOVE_USER_FROM_FAVORITES,
  });
}

export function openCloseRatingModal(userId, mode) {
  return store.dispatch({
    type: types.OPEN_CLOSE_RATING_MODAL,
    response: {
      userId,
      mode,
    },
  });
}

export const rateSelectedUser = (userId, score) => {
  return Api.post({
    url: "/api/rate-user",
    data: { userId, score },
    responseAction: types.RATE_USER,
  }).then(() => getClickedUserInfo(userId));
};

export const getClickedUserInfo = (userId) => {
  Api.get({
    url: `/api/get-user/${userId}`,
    responseAction: types.LOAD_USER_INFO,
  });
};

export const loadLoggedMMPlan = () => {
  return () => {
    Api.get({
      url: "/api/get-mm-plan",
      responseAction: types.LOAD_MM_PLAN_INFO,
    });
  };
};

export function addChangeUserRelationshipStatus(userId, status) {
  Api.post({
    url: "/api/add-change-user-relationship-status",
    data: { userId, status },
    responseAction: types.ADD_CHANGE_USER_STATUS,
  });
}

export const getMatchmakerAgreedTerms = () => {
  return () => {
    Api.post({
      url: "/api/get-mm-agreed-terms",
      responseAction: types.GET_MM_AGREED_TERMS,
    });
  };
};

export const agreedWithTermsAndConditions = () => {
  return () => {
    Api.post({
      url: "/api/mm-agreed-terms",
      responseAction: types.MM_AGREED_TERMS,
    });
  };
};

export const getMatchmakerReferrals = () => {
  return () => {
    Api.get({
      url: "/api/get-mm-referrals",
      responseAction: types.GET_MM_REFERRALS,
    });
  };
};

export const addMatchmakerReferral = (data) => {
  return () => {
    Api.post({
      url: "/api/add-mm-referral",
      data,
      responseAction: types.ADD_MM_REFERRAL,
    });
  };
};
