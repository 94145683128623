@import "src/scss/variables";

.badges {
  padding: 16px;
  background-color: $pale;
  height: 100%;
  @media (max-width: $mobile) {
    padding: 0;
  }
  .badges-container {
    background-color: #ffffff;

    .badges-title {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $blue;

      h2 {
        color: #fff;
        font-feature-settings: "clig" off, "liga" off;
        font-family: Montserrat;
        font-size: 35px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
      }
    }

    a {
      text-decoration: none;
    }

    .badges-images {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      padding: 16px;
      gap: 102px;

      @media (max-width: $mobile) {
        gap: 16px;
        img {
          width: 150px;
          height: 150px;
        }

        div{
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 6px;
        }
      }
    }

    .badges-text {
      display: flex;
      align-items: center;
      flex-direction: column;

      p {
        width: 60%;
        color: #54585a;
        text-align: center;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        @media (max-width: $mobile) {
          font-size: 18px;
          width: 90%;
        }
      }
    }

    .badges-buttons {
      display: flex;
      justify-content: center;
      padding-bottom: 32px;
      @media (max-width: $mobile) {
        flex-wrap: wrap;
        gap: 16px;
      }

      button {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: capitalize;
        padding: 8px 16px;
        margin: 0 16px;

        @media (max-width: $mobile) {
          width: auto;
          padding: 8px 56px;
          margin: 0;
        }
      }

      .badges-buttons__no {
        background-color: $red;
        color: white;

        &:hover {
          background-color: #e6475d;
        }
      }

      .badges-buttons__planning {
        background-color: $blue;
        color: white;
        width: auto;
        @media (max-width: $mobile) {
          width: auto;
          padding: 8px 16px;
          margin: 0;
        }

        &:hover {
          background-color: #002936;
        }
      }
    }
  }
}
