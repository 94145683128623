import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as generalActions from "../../actions/generalActions";
import Button from "../../components/button";
import { repeatingSectionTwo, repeatingMatchmakerThree } from "./components";
import {
  REPEAT_SECTION_TWO,
  MATCHMAKER_SECTION_THREE,
  MATCHMAKER_PRICING,
} from "./texts";

import "./style.scss";

class MatchMakersHomePage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { history } = this.props;
    const renderWeGiveYouSection = this._renderWeGiveYouSection();
    const renderPlansSection = this._renderPlansSection();
    return (
      <div className="match-makers-page-content flex-column">
        <div className="match-makers-page-container__content flex-column">
          <div className="section-two flex-column flex-vertical-center">
            {repeatingSectionTwo(
              REPEAT_SECTION_TWO.matchmakerFirst.title,
              REPEAT_SECTION_TWO.matchmakerFirst.text,
              null
            )}
          </div>
          {renderWeGiveYouSection}
          {renderPlansSection}
          <div className="section-two flex-column flex-vertical-center exclusive-contact">
            {repeatingSectionTwo(
              REPEAT_SECTION_TWO.matchmakerThird.title,
              REPEAT_SECTION_TWO.matchmakerThird.text,
              REPEAT_SECTION_TWO.matchmakerThird.secondText
            )}
            <div className="lock-acquire">You can acquire a lock by:</div>
            <div className="lock-image">
              <div
                className="flex-row"
                style={{ padding: "221px 25px 0px 25px", position: "relative" }}
              >
                <div className="lock-text">
                  Referring a single person who completes a profile
                </div>
                <div className="lock-text or">or</div>
                <div className="lock-text right float-right">
                  Choosing either the Flirting or Exclusive Plan
                </div>
              </div>
            </div>
            <div className="text last">
              When you lock a member, you will be required to “unlock” them if
              the relationship doesn’t work out. You can expect to hear from us
              about the status of pairings so we can keep our database current.
              Our Partner Matchmakers are expected to respond by quickly
              clicking a button. We make it as easy for you as possible as we
              know you’re as busy as your clients, if not more!
            </div>
          </div>
          <div className="section-two flex-column flex-vertical-center spread-love">
            {repeatingSectionTwo(
              REPEAT_SECTION_TWO.matchmakerSpreadLove.title,
              REPEAT_SECTION_TWO.matchmakerSpreadLove.text,
              null
            )}
          </div>
          <div className="section-six">
            <div className="section-six__content flex-column">
              <div className="section-title flex-center">
                <span> Ready to get started? </span>
              </div>
              <Button
                click={() => history.push({ pathname: "/welcome-partners" })}
                value="BECOME A PARTNER"
                customClass="yellow large"
              />
            </div>
          </div>
          <div className="white-spacing" />
        </div>
      </div>
    );
  }

  _renderWeGiveYouSection = () => {
    return (
      <div className="matchmaker-section-three flex-center">
        <div className="matchmaker-section-three__content flex-row wrap flex-center">
          <div className="title"> We give you all of that, and more… </div>
          {MATCHMAKER_SECTION_THREE.map((item, i) =>
            repeatingMatchmakerThree(
              item.title,
              item.text,
              item.svg,
              item.extra,
              item.marginRight,
              i
            )
          )}
        </div>
      </div>
    );
  };

  _renderPlansSection = () => {
    return (
      <div className="section-two flex-column flex-vertical-center pricing">
        {repeatingSectionTwo(
          REPEAT_SECTION_TWO.matchmakerSecond.title,
          REPEAT_SECTION_TWO.matchmakerSecond.text,
          REPEAT_SECTION_TWO.matchmakerSecond.secondText,
          null,
          null
        )}
        <div className="flex-column plans">
          <div className="plans__margin">
            <div className="flex-row  plans__titles">
              <div className="plans__tab plan">
                {MATCHMAKER_PRICING.plans.title}
              </div>
              {MATCHMAKER_PRICING.plans.values.map((plan, p) => (
                <div className="item__title flex-column" key={p}>
                  <div className="plan-name">{plan.planName}</div>
                  <div className="plan-title">{plan.description}</div>
                </div>
              ))}
            </div>
            <div className="flex-row  plans__connections">
              <div className="plans__tab connections">
                {MATCHMAKER_PRICING.connections.title}
              </div>
              {MATCHMAKER_PRICING.connections.values.map((conn, u) => (
                <div className="item__text" key={u}>
                  {conn}
                </div>
              ))}
            </div>
            <div className="flex-row  plans__benefits">
              <div className="plans__tab benefits">
                {MATCHMAKER_PRICING.otherBenefits.title}
              </div>
              {MATCHMAKER_PRICING.otherBenefits.values.map((bi, b) => (
                <div className="flex-column benefits-content" key={b}>
                  <div
                    className={`${
                      b === 0 || b === 1 ? "item__text custom " : "item__text"
                    }`}
                  >
                    {bi.text}
                  </div>
                  <div
                    className={`${
                      b === 0 || b === 1
                        ? "item__text custom-bottom "
                        : "item__text"
                    }`}
                  >
                    {bi.lock}
                  </div>
                </div>
              ))}
            </div>
            <div className="flex-row  plans__prices">
              <div className="plans__tab price">
                {MATCHMAKER_PRICING.price.title}
              </div>
              {MATCHMAKER_PRICING.price.values.map((bi, b) => (
                <div className="flex-column price-content" key={b}>
                  <div
                    className={`${
                      b === 0
                        ? "flex-row flex-center first"
                        : "flex-row flex-center"
                    }`}
                  >
                    <span className="item__text plan-price">{bi.text}</span>
                    <span className="item__text continue">
                      {bi.continueText}
                    </span>
                  </div>
                  <Button
                    value={bi.button}
                    customClass="yellow"
                    click={() => this.props.history.push("/welcome-partners")}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };
}

MatchMakersHomePage.propTypes = {
  history: PropTypes.object,
  generalActions: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return {
    generalActions: bindActionCreators(generalActions, dispatch),
  };
};

const mapStateToProps = (state) => {
  return {
    generalReducer: state.generalReducer,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MatchMakersHomePage);
