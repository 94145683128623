import React, { Component } from "react";
import PropTypes from "prop-types";
import { TERMS_OF_SERVICE } from "../../constants/terms-and-policies";
import { REPEAT_SECTION_TWO } from "../home-pages/texts";
import { repeatingSectionTwo } from "../home-pages/components";

import "./style.scss";

class TermsOfService extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const { history } = this.props;
    const sectionOne = this._renderSectionOne();
    const sectionTwo = this._renderSectionTwo();
    const sectionThree = this._renderSectionThree();
    const lastSections = this._renderLastSections();
    return (
      <div className="terms-page-content flex-column">
        <span
          className="icon-back-button flex-center"
          onClick={() => history.push("/")}
        />
        {repeatingSectionTwo(
          REPEAT_SECTION_TWO.termsPageFirst.title,
          null,
          null,
          null,
          null,
          null
        )}
        {sectionOne}
        {sectionTwo}
        {sectionThree}
        {lastSections}

        <div className="flex-column" style={{ marginBottom: "50px" }}>
          <span className="text small">DateSpot, LLC</span>
          <span className="text small">
            130 Oakview Drive, San Carlos, CA 94070
          </span>
          <span className="text small">support@datespot.love</span>
        </div>
      </div>
    );
  }

  _renderSectionOne = () => {
    return (
      <div className="terms-page-content__section flex-column">
        <div className="text">Last Updated: as of March 9, 2021</div>

        {TERMS_OF_SERVICE.introduction.map((intro, i) => {
          return (
            <div className="text" key={i}>
              {intro.text}
            </div>
          );
        })}

        <div className="text">
          These Terms of Service in contain agreement to
          <span className="terms-and-privacy" onClick={() => {}}>
            Arbitration of Disputes
          </span>
          and
          <span className="terms-and-privacy" onClick={() => {}}>
            Class Action Waivers
          </span>
          with regard to disputes. Please read these provisions carefully.
        </div>
      </div>
    );
  };

  _renderSectionTwo = () => {
    return (
      <div className="terms-page-content__section flex-column">
        <div className="terms-page-content__subtitle">
          {TERMS_OF_SERVICE.sectionOne.title}
        </div>
        <div className="text">{TERMS_OF_SERVICE.sectionOne.text}</div>
        <div className="list">
          {TERMS_OF_SERVICE.sectionOne.list.map((item, i) => {
            return (
              <div className="text" key={i}>
                {item}
              </div>
            );
          })}
        </div>
        <div className="text">{TERMS_OF_SERVICE.sectionOne.text1}</div>
        <div className="text">{TERMS_OF_SERVICE.sectionOne.text2}</div>
        <div className="text">{TERMS_OF_SERVICE.sectionOne.text3}</div>
        <div className="text">{TERMS_OF_SERVICE.sectionOne.text4}</div>
        <div className="text">{TERMS_OF_SERVICE.sectionOne.text5}</div>
        <div className="text">{TERMS_OF_SERVICE.sectionOne.text6}</div>
        <div className="text">{TERMS_OF_SERVICE.sectionOne.text7}</div>
        <div className="text">{TERMS_OF_SERVICE.sectionOne.text8}</div>
        <div className="text">{TERMS_OF_SERVICE.sectionOne.text9}</div>
        <div className="text">{TERMS_OF_SERVICE.sectionOne.text10}</div>
        <div className="text">{TERMS_OF_SERVICE.sectionOne.text11}</div>
      </div>
    );
  };

  _renderSectionThree = () => {
    return (
      <div className="terms-page-content__section flex-column">
        <div className="terms-page-content__subtitle">
          {TERMS_OF_SERVICE.sectionTwo.title}
        </div>
        <div className="text">{TERMS_OF_SERVICE.sectionTwo.text}</div>
        <div className="text">{TERMS_OF_SERVICE.sectionTwo.text1}</div>
        <div className="list">
          {TERMS_OF_SERVICE.sectionTwo.list.map((item, i) => {
            return (
              <div className="text" key={i}>
                {item}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  _renderLastSections = () => {
    return (
      <div>
        {TERMS_OF_SERVICE.nextSections.map((sec, s) => {
          return (
            <div className="terms-page-content__section flex-column" key={s}>
              <div className="terms-page-content__subtitle">{sec.title}</div>
              <div className="text">{sec.text}</div>
              {sec.text1 && <div className="text">{sec.text1}</div>}
              {sec.text2 && <div className="text">{sec.text2}</div>}
              {sec.bold && <div className="text">{sec.bold}</div>}
              {sec.bold1 && <div className="text">{sec.bold1}</div>}
            </div>
          );
        })}
      </div>
    );
  };
}

TermsOfService.propTypes = {
  history: PropTypes.object,
};

export default TermsOfService;
