const carla = require("../containers/home-pages/assets/team/carla.png");
const mike = require("../containers/home-pages/assets/team/mike.png");
const navin = require("../containers/home-pages/assets/team/navin.png");
export const STORAGE_KEY_SESSION = "date-spot-cookie-string";
export const HEADER_TABS_DATA = [
  { isLogo: true },
  {
    class: "add-person",
    pathname: "/the-basics",
    section: "theBasics",
    title: "ADD PERSON",
    tab: "addPerson",
  },
  {
    class: "singles",
    pathname: "/dashboard",
    section: "singles",
    title: "SINGLES",
    tab: "singles",
  },
  {
    class: "requests",
    pathname: "/matchmakers-requests",
    section: "requests",
    title: "REQUESTS",
    tab: "requests",
  },
  {
    class: "mySingles",
    pathname: "/dashboard",
    section: "singles",
    title: "USER DATABASE",
    tab: "mySingles",
    icon: "icon-list",
  },
  {
    class: "myFavorites",
    pathname: "/my-favorites",
    section: null,
    title: "MY POTENTIALS",
    tab: "myFavorites",
    icon: "icon-heart",
  },
  {
    class: "myContacted",
    pathname: "/my-contacted",
    section: "contacted",
    title: "CONTACTED BY ME",
    tab: "myContacted",
    icon: "icon-contacted",
  },
  {
    class: "matchmakers",
    pathname: "/matchmakers-list",
    section: "matchmakers",
    title: "MATCHMAKERS",
    tab: "matchmakers",
  },
  {
    class: "myLocks",
    pathname: "/my-locks",
    section: "myLocks",
    title: "LOCKED BY ME",
    tab: "myLocks",
    icon: "icon-lock",
  },
  {
    class: "plans",
    pathname: "/plans",
    section: "plans",
    title: "PLANS",
    tab: "plans",
  },
  {
    class: "about",
    pathname: `${process.env.MARKETING_SITE_URL}/about-us`,
    section: null,
    title: "ABOUT US",
    tab: "about",
  },
  {
    class: "contact",
    pathname: `${process.env.MARKETING_SITE_URL}/help`,
    section: null,
    title: "CONTACT",
    tab: "contact",
  },
  {
    class: "my-profile",
    pathname: "/my-profile",
    section: null,
    title: "MY PROFILE",
    tab: "myProfile",
  },
  {
    class: "login",
    pathname: "/log-in",
    section: null,
    title: "LOG IN",
    tab: "login",
  },
];
export const ADMIN_URLS_TO_TABS = {
  "/dashboard": "singles",
  "/matchmaker-requests": "requests",
  "/locks-section": "locks",
  "/completed-section": "completed",
  "/matchmakers-list": "matchmakers",
  "/plans": "plans",
  "/metrics": "metrics",
};
export const MATCHMAKER_URLS_TO_TABS = {
  "/dashboard": "mySingles",
  "/my-favorites": "myFavorites",
  "/my-contacted": "myContacted",
  "/my-locks": "myLocks",
  "/matchmaker-plans": "matchmaker-plans",
  "/matchmaker-referrals": "matchmaker-referrals",
};
export const HOME_PAGES_URL_TO_TABS = {
  "/web/": "",
  "/web/for-singles": "singles-tab",
  "/web/for-matchmakers": "matchmakers-tab",
  "/web/team": "team",
  "/web/blog": "blog",
  "/web/contact": "contact",
};
export const HEADER_TABS_ALLOWED = {
  admin: {
    singlesTab: false,
    matchMakersTab: false,
    addPerson: false,
    requests: true,
    singles: true,
    mySingles: false,
    locks: true,
    myContacted: false,
    matchmakers: true,
    myFavorites: false,
    myLocks: false,
    plans: true,
    about: false,
    team: false,
    contact: false,
    myProfile: false,
    login: false,
  },
  matchmaker: {
    singlesTab: false,
    matchMakersTab: false,
    addPerson: false,
    requests: false,
    singles: false,
    mySingles: true,
    locks: true,
    myContacted: true,
    matchmakers: false,
    myFavorites: true,
    myLocks: true,
    about: false,
    team: false,
    contact: false,
    myProfile: false,
    login: false,
  },
  user: {
    singlesTab: false,
    matchMakersTab: false,
    addPerson: false,
    requests: false,
    singles: false,
    mySingles: false,
    locks: false,
    myContacted: false,
    matchmakers: false,
    myFavorites: false,
    myLocks: false,
    about: false,
    team: false,
    contact: false,
    myProfile: false,
    login: false,
  },
  default: {
    singlesTab: true,
    matchMakersTab: true,
    teamTab: true,
    addPerson: false,
    requests: false,
    singles: false,
    mySingles: false,
    locks: false,
    myContacted: false,
    matchmakers: false,
    myFavorites: false,
    myLocks: false,
    about: false,
    team: true,
    contact: true,
    myProfile: false,
    login: true,
  },
};
export const MONTHS = [
  { value: "01", label: "January" },
  { value: "02", label: "February" },
  { value: "03", label: "March" },
  { value: "04", label: "April" },
  { value: "05", label: "May" },
  { value: "06", label: "June" },
  { value: "07", label: "July" },
  { value: "08", label: "August" },
  { value: "09", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];
export const DAYS = [
  { id: 1, value: "01", label: "1" },
  { id: 2, value: "02", label: "2" },
  { id: 3, value: "03", label: "3" },
  { id: 4, value: "04", label: "4" },
  { id: 5, value: "05", label: "5" },
  { id: 6, value: "06", label: "6" },
  { id: 7, value: "07", label: "7" },
  { id: 8, value: "08", label: "8" },
  { id: 9, value: "09", label: "9" },
  { id: 10, value: "10", label: "10" },
  { id: 11, value: "11", label: "11" },
  { id: 12, value: "12", label: "12" },
  { id: 13, value: "13", label: "13" },
  { id: 14, value: "14", label: "14" },
  { id: 15, value: "15", label: "15" },
  { id: 16, value: "16", label: "16" },
  { id: 17, value: "17", label: "17" },
  { id: 18, value: "18", label: "18" },
  { id: 19, value: "19", label: "19" },
  { id: 20, value: "20", label: "20" },
  { id: 21, value: "21", label: "21" },
  { id: 22, value: "22", label: "22" },
  { id: 23, value: "23", label: "23" },
  { id: 24, value: "24", label: "24" },
  { id: 25, value: "25", label: "25" },
  { id: 26, value: "26", label: "26" },
  { id: 27, value: "27", label: "27" },
  { id: 28, value: "28", label: "28" },
  { id: 29, value: "29", label: "29" },
  { id: 30, value: "30", label: "30" },
  { id: 31, value: "31", label: "31" },
];
export const generateYears = () => {
  const finalArray = [];
  const max = new Date().getFullYear() - 18;
  const min = max - 78;
  const yearsArray = Array.from({ length: max + 1 - min }, (v, k) => k + min);
  yearsArray.forEach((year, i) => {
    finalArray.push({ id: i, value: year, label: year });
  });
  return finalArray.reverse();
};
export const generateAllYears = () => {
  const finalArray = [];
  let max = new Date().getFullYear() + 1; // additional year
  if (new Date().getMonth() >= 12) {
    max += 1;
  }
  const min = max - 78;
  const yearsArray = Array.from({ length: max + 1 - min }, (v, k) => k + min);
  yearsArray.forEach((year, i) => {
    finalArray.push({ id: i, value: year, label: year });
  });
  return finalArray.reverse();
};
export const FAQ = [
  {
    question: "Why would I want to work with a matchmaking company?",
    answer:
      "First, Matchmakers get a ton of information about each person so they can effectively match people based " +
      "on a wide variety of dimensions. Second, Matchmakers work with clients who are typically successful professionals," +
      " so you know you would be meeting a like-minded self-sufficient individual. " +
      "Finally, your profile remains private with matchmaking companies.",
    answerTwo:
      "Keep in mind though that in terms of matches, clients of matchmaking " +
      "companies are looking for a more personal connection looking for a long-term, monogamous relationship building a future",
  },
  {
    question: "What makes you different than dating sites/apps?",
    answer:
      "We are not a dating site or matchmaking comapny. " +
      "We refer you to the right dating site or matchmaking company for you. We do not charge you for services, ever.",
  },
  {
    question: "What is your success rate?",
    answer:
      "It’s tricky for us to calculate success rate because we partner with so many different sites and apps. " +
      "Plus, success comes in different forms, from becoming a better dater to understanding yourself more through " +
      "date feedback to entering a relationship to getting married. " +
      "Please feel free to email us with your own success stories - we love to hear them!",
  },
  {
    question: "How do I cancel my membership?",
    answer:
      "We fully respect your privacy. You can cancel your membership at any point and we will " +
      "not pass along your information to anyone else. Know that we won’t be contacting the companies " +
      "that have already gained access to your information. Refer to the terms and conditions for details.",
  },
  {
    question: "How long will it generally take for a Matchmaker to contact me?",
    answer:
      "The timeframe is completely undetermined. We don’t guarantee a specific time in which you will be contacted. " +
      "It is completely determined by the need of each specific Matchmakers and their current clients.",
  },
  {
    question: "Who are your members?",
    answer:
      "Our members are nationwide and inclusion is one of our core values." +
      " We allow everyone to be a potential match for clients, and we think there is a “lid for every pot.” " +
      "We also strive to provide options for each person specific to their preferences and situation.",
    answerTwo:
      "Keep in mind though that in terms of matches, clients of matchmaking companies are " +
      "looking for a more personal connection looking for a long-term, monogamous relationship building a future",
  },
  {
    question: "What is required of me during the matching process?",
    answer: "We have six simple expectations of you:",
    answerTwo:
      "• Treat matchmakers and their clients with respect at all times.\n \n" +
      "• Follow through with integrity. If you say you will meet a matchmaker’s client," +
      " don’t change your mind unless there is a very good reason.\n\n" +
      "• If you go out with a client and a matchmaker requested feedback, provide that feedback " +
      "as an important part of the iterative matchmaking process. They can shed light on whether " +
      "a second date makes sense. If it turns out to be a great connection, they will better understand why." +
      " If it’s not a lasting connection, the info can be used to better match both the client and you in the future.\n\n" +
      "• Rate the matchmaker you worked with via the email we send out. " +
      "This helps other members select a matchmaker for them if they’re interested in becoming a client.\n\n" +
      "• Respond to the email by clicking a button when we send out asking what " +
      "happened with any particular connection to a matchmaker.\n\n" +
      "• Please keep all information about members, clients and matchmakers confidential." +
      " This is a special platform where everyone’s privacy is respected.\n",
  },
  {
    question: "Do you work with the poly community?",
    answer:
      "It's rare that a matchmaking client is poly or open, but it does occasionally happen. " +
      "Please join the database so that when a person who is more open in relationships does become a client, " +
      "you're a possibility to be matched with them." +
      " People who are polyamorous are generally extremely open, honest and sincere, all of which we appreciate." +
      " They are just as deserving and capable of love as anyone else. And hey, if you’re successfully matched " +
      "through DateSpot, you can give us multiple testimonials!",
  },
  {
    question: "Do you match LGBTQ+ members?",
    answer: "Yes, definitely! We’re inclusive of everyone. ",
  },
];

export const USER_INFO_TABLE_DATA = {
  theBasics: [
    { label: "Gender", key: "gender", type: "object" },
    { label: "Attractiveness", key: "userRating", type: "string" },
    { label: "Interested in", key: "interestedIn", type: "array" },
    { label: "Has Children", key: "hasKids", type: "object" },
    { label: "Age", key: "age", type: "string" },
    {
      label: "Children's Ages (+/- 1 year)",
      key: "kids",
      type: "nestedObject",
    },
    { label: "Height", key: "height", type: "string" },
    {
      label: "Future Child Scenarios Open To",
      key: "futureChildScenarios",
      type: "array",
    },
    { label: "Body Type", key: "bodyTypes", type: "array" },
    { label: "Location", key: "city", type: "string" },
    { label: "Ethnicity", key: "ethnicity", type: "array" },
    { label: "Religion", key: "religionPracticed", type: "array" },
    { label: "Client Lead?", key: "clientLead", type: "object" },
    { label: "Member Type", key: "clientStatus", type: "string" },
  ],
  backGround: [
    { label: "Marriage Status", key: "marriageStatus", type: "object" },
    { label: "Education", key: "education", type: "object" },
    { label: "Employment Status", key: "employmentStatus", type: "object" },
    { label: "Occupation", key: "occupation", type: "string" },
    { label: "School Attended", key: "schoolAttended", type: "string" },
    { label: "Company/ Org", key: "company", type: "string" },
    {
      label: "Where are you from?",
      key: "upbringing",
      type: "string",
    },
  ],
  lifeStyle: [
    { label: "Drinking", key: "drinking", type: "object" },
    { label: "Eating Habits", key: "eatingHabits", type: "array" },
    { label: "Pets", key: "pets", type: "nestedPets" },
    { label: "Smoking", key: "smoking", type: "object" },
    { label: "Politics", key: "politicalOrientation", type: "object" },
    { label: "Fitness Level", key: "workout", type: "object" },
    { label: "Drugs", key: "does_drugs", type: "nestedDrugs" },
    { label: "Do you have any tattoos?", key: "tattoos", type: "object" },
    { label: "Music Genres", key: "music", type: "nestedMusic" },
    { label: "Hobbies", key: "hobbies", type: "array" },
    { label: "Passionate About", key: "passionateAbout", type: "string" },
    {
      label: "Financial Stability",
      key: "financialStability",
      type: "component",
    },
    {
      label: "Average yearly income",
      key: "yearlyIncome",
      type: "object",
      accessor: "label",
    },
    {
      label: "Net worth estimate",
      key: "netWorthEstimate",
      type: "object",
      accessor: "label",
    },
    {
      label: "How important is travel to you?",
      key: "travel.personalImportance",
      type: "object",
    },
    {
      label: "What's your international travel experience?",
      key: "travel.personalExperience",
      type: "object",
    },
    {
      label: "Feel free to elaborate on any travel or living abroad",
      key: "travel.personalComments",
      type: "string",
    },
  ],
  family: [
    {
      label: "Have you frozen any eggs/sperm?",
      key: "personalFrozenEggsOrSperm",
      type: "object",
    },
    {
      label: "Any other details about kids?",
      key: "kidsAnythingElse",
      type: "string",
    },
    {
      label: "Describe your family & upbringing",
      key: "familyAndUpbringing",
      type: "string",
    },
    {
      label: "Are you (or were you) close with the family you grew up with?",
      key: "familyCloseness",
      type: "object",
    },
    {
      label: "Should you loan money to family?",
      key: "familyLoan",
      type: "object",
    },
  ],
  partnerCriteria: [
    {
      label: "Relationship Type(s)",
      key: "relationshipType",
      type: "array",
    },
    { label: "Height", key: "heightPreference", type: "heightNested" },
    { label: "Age range", key: "ageRange", type: "string" },
    { label: "Ethnicities", key: "ethnicityRequired", type: "array" },
    {
      label: "Religious alignment",
      key: "religionPreference",
      type: "component",
    },
    {
      label: "Political alignment",
      key: "politicalPreference",
      type: "component",
    },
    {
      label: "Drugs",
      key: "does_drugs",
      type: "nestedDrugs",
    },
    {
      label: "Could you be with someone who has a tattoo?",
      key: "partnerTattoos",
      type: "object",
    },
    {
      label: "Open to relocation",
      key: "relocationOptions",
      type: "object",
    },
    { label: "Match areas", key: "matchArea", type: "matchAreaArray" },
    {
      label: "Match area details",
      key: "matchAreaDetails",
      type: "string",
    },
    {
      label: "Looking for in a partner",
      key: "partnerPreferences",
      type: "string",
    },
  ],
  other: [
    {
      label: "Of the 5 Love Languages, how do you naturally GIVE appreciation?",
      key: "giveAppreciation",
      type: "object",
    },
    {
      label:
        "Of the 5 Love Languages, how do you like to RECEIVE appreciation?",
      key: "receiveAppreciation",
      type: "object",
    },
    {
      label: "Longest relationship duration",
      key: "longestRelationship",
      type: "object",
    },
    {
      label: "Languages spoken",
      key: "languages",
      type: "array",
    },
    {
      label: "Descriptors (from list)",
      key: "qualities",
      type: "array",
    },
    {
      label: "Habits to work on",
      key: "habitsToWorkOn",
      type: "array",
    },
    {
      label: "Dealbreaker traits (from list)",
      key: "dealbreakers",
      type: "array",
    },
    {
      label: "Open to blind dates",
      key: "blindDates",
      type: "object",
    },
    {
      label: "Anything else?",
      key: "anythingElse",
      type: "string",
    },
  ],
  dateSpotHistory: [
    { label: "Signup Date", key: "signupDate", type: "created" },
    { label: "Referral Source", key: "referralSource", type: "array" },
    {
      label: "Last Known Relationship Status",
      key: "relationshipStatus",
      type: "object",
    },
    { label: "Contact Requests", key: "requests", type: "string" },
    {
      label: "Contact Requests Responded to",
      key: "requestsResponded",
      type: "string",
    },
    {
      label: "Number of dates through DateSpot",
      key: "requestsResponded",
      type: "string",
    },
  ],
};
export const COMPANY = [
  {
    name: "Carla Swiryn",
    job: "FOUNDER",
    text1:
      " A San Francisco Bay Area native, Carla loves living in Silicon Valley, the geographic center " +
      "of modern innovation. She’s also enjoyed stints in Colorado (CU Boulder for college), Florida, Spain, " +
      "Brazil, and India, and recognizes the beauty and connectedness of people worldwide.",
    text2:
      "  Carla built and optimized online advertising accounts for seven years at Google AdWords before" +
      " running her own online ad agency. She then left to enter the more personally rewarding field of matchmaking. " +
      "She brings her experience and interest in marketing analytics with her, integrating uniquely tested data about" +
      " compatibility into the matching process.",
    text3:
      "Carla was the first Matchmaker hired at the nationwide company Three Day Rule and " +
      "helped the brand grow for over 5 years. Wanting to help both matchmakers and singles " +
      "alike in a different way, she then founded DateSpot in 2019.",
    text4:
      "She believes that what makes a great match is attraction, common goals, " +
      "some overlapping interests, and a balance in energy and personality. You don’t want to date yourself!",
    text5: "You can contact her directly with any questions or comments here:",
    alignLeft: true,
    photo: carla,
    contact: true,
  },
  {
    name: "Mike Borozdin",
    job: "ADVISOR",
    currentRole: "Current Role",
    text1: "VP of Engineering at Ethos Lending",
    previousRole: "Previous",
    text2:
      "Engineering Director and VP roles at Microsoft, Docusign and Tempo Automation",
    passionateAbout: "Passionate about",
    text3: "Optimizing processes via engineering",
    whyChoose: "Why We Chose Him",
    text4:
      "When Carla asked for his advice about the idea, he jumped on the opportunity to help." +
      " He’s a whip-smart, patient man with a cool Russian accent…who " +
      "has done enough dating to bring insight on multiple levels.",
    alignLeft: false,
    photo: mike,
    contact: false,
  },
  {
    name: "Navin Ramachandran",
    job: "ADVISOR",
    currentRole: "Current Role",
    text1:
      "Ambassador & Catalyst at Kanthari, which empowers social visionaries worldwide.",
    previousRole: "Previous",
    text2: "CEO, Match APAC & LatAm, COO Match.",
    passionateAbout: "Passionate about",
    text3: "Indie films, politics & helping unserved populations.",
    whyChoose: "Why We Chose Him",
    text4:
      "In addition to his impressive expertise helping to lead the world’s largest " +
      "dating site for years, Navin tells it like it is. Always.",
    alignLeft: true,
    photo: navin,
    contact: false,
  },
];
export const LEFT_PANEL_FILTERS = [
  // remember to modify left panel renderBasicsFilters array slice from to when adding new filter
  // basic filters
  {
    filter: "gender",
    filterName: "Gender",
    modalName: "genderModal",
    search: false,
  },
  {
    filter: "interestedIn",
    filterName: "Interested in",
    modalName: "interestedInModal",
    search: false,
  },
  { filter: "age", filterName: "Age", modalName: "ageModal", search: false },
  {
    filter: "bodyType",
    filterName: "Body type",
    modalName: "bodyType",
    search: false,
  },
  {
    filter: "ethnicity",
    filterName: "Ethnicity",
    modalName: "ethnicity",
    search: false,
  },
  {
    filter: "religionPracticed",
    filterName: "Religion",
    modalName: "religionPracticed",
    search: false,
  },
  // attractiveness --new  { filter: 'attractiveness' },
  {
    filter: "hasKids",
    filterName: "Has Children",
    modalName: "hasKids",
    search: false,
  },
  {
    filter: "futureChildScenarios",
    filterName: "Future Children",
    modalName: "futureChildScenarios",
    search: false,
  },
  // city --new  { filter: 'city', filterName: 'City', modalName: 'cityModal', search: true },
  // region --new  { filter: 'region' },
  {
    filter: "relationshipStatus",
    filterName: "Relationship status",
    modalName: "relationshipStatus",
    search: false,
  },
  // background filters
  {
    filter: "education",
    filterName: "Education",
    modalName: "educationModal",
    search: false,
  },
  // school attended .. needs new implementation
  {
    filter: "longestRelationship",
    filterName: "Last relationship",
    modalName: "longestRelationship",
    search: false,
  },
  // lifestyle filters
  {
    filter: "drinking",
    filterName: "Drinking",
    modalName: "drinkingModal",
    search: false,
  },
  {
    filter: "smoking",
    filterName: "Smoking",
    modalName: "smokingModal",
    search: false,
  },
  {
    filter: "drugs",
    filterName: "Drugs",
    modalName: "drugsModal",
    search: false,
  },
  {
    filter: "eatingHabit",
    filterName: "Eating habits",
    modalName: "eatingHabitModal",
    search: false,
  },
  {
    filter: "politicalOrientation",
    filterName: "Politics",
    modalName: "politicalOrientationModal",
    search: false,
  },
  {
    filter: "hobbies",
    filterName: "Hobbies",
    modalName: "hobbiesModal",
    search: false,
  },
  // pets new => not implemented yet
  {
    filter: "workout",
    filterName: "Fitness Level",
    modalName: "workoutModal",
    search: false,
  },
  {
    filter: "financialStability",
    filterName: "Financial stability",
    modalName: "financialModal",
    search: false,
  },
  // partner criteria
  {
    filter: "relationshipType",
    filterName: "Relationship types",
    modalName: "relationshipInterestModal",
    search: false,
  },
  {
    filter: "religionPreference",
    filterName: "Religious alignment",
    modalName: "religionPreferenceModal",
    search: false,
  },
  {
    filter: "politicalPreference",
    filterName: "Political alignment",
    modalName: "politicalPreferenceModal",
    search: false,
  },
  {
    filter: "ethnicityRequired",
    filterName: "Ethnicities",
    modalName: "ethnicitiesModal",
    search: false,
  },
];
export const MATCH_MAKER_TABLE_HEADERS = {
  userDb: [
    { name: "Member", class: "member", width: 130 },
    { name: "Gender", class: "gender", width: 50 },
    { name: "Age", class: "age", width: 35 },
    { name: "Attractiveness", class: "looks", width: 94 },
    { name: "Location", class: "location", width: 90 },
    {
      name: "Relationship & DateSpot status",
      class: "relationship-status",
      width: 200,
    },
    // { name: '# Connections', class: 'connections', width: 80 },
    { name: "", class: "special float-right", width: 40 },
    { name: "", class: "special", width: 40 },
    { name: "Actions", class: "special special-right", width: 40 },
  ],
  favorites: [
    { name: "Member", class: "member flex-row", width: 130 },
    { name: "Gender", class: "gender", width: 50 },
    { name: "Age", class: "age", width: 35 },
    { name: "Attractiveness", class: "looks", width: 94 },
  ],
  contacted: [
    { name: "Member", class: "member", width: 130 },
    { name: "Gender", class: "gender", width: 50 },
    { name: "Request Status", class: "status", width: 120 },
    { name: "Matchmaking Status", class: "status", width: 150 },
  ],
  locks: [
    { name: "Member", class: "member", width: 130 },
    { name: "Gender", class: "gender", width: 50 },
    { name: "Matchmaking Status", class: "status", width: 150 },
  ],
};
export const ADMIN_TABLE_HEADERS = {
  mm: [
    { name: "Matchmaker", class: "member" },
    { name: "Company", class: "company" },
    { name: "Email", class: "email" },
    { name: "Phone", class: "phone" },
    { name: "Actions", class: "actions" },
  ],
  users: [
    { name: "Photo", class: "thumb" },
    { name: "Name", class: "member" },
    { name: "ID", class: "id" },
    { name: "Location", class: "location" },
    { name: "Age", class: "age" },
    { name: "Member Type", class: "user-type" },
    { name: "Completion", class: "rating" },
    { name: "Atractiveness", class: "userRating" },
    { name: "Reviewed", class: "review" },
  ],
};
export const USER_STATUS_VALUES = [
  { value: "Met, going out again", label: "Met, going out again", id: 1 },
  { value: "Met, may go out again", label: "Met, may go out again", id: 2 },
  {
    value: "Met, not going out again",
    label: "Met, not going out again",
    id: 3,
  },
  { value: "Planning first date", label: "Planning first date", id: 4 },
  {
    value: "Rejected by client, didn't meet",
    label: "Rejected by client, didn't meet",
    id: 5,
  },
  {
    value: "Rejected client, didn't meet",
    label: "Rejected client, didn't meet",
    id: 6,
  },
  {
    value: "Not a match, didn't meet",
    label: "Not a match, didn't meet",
    id: 7,
  },
];
export const ATTRACTIVENESS_RATING = [
  { value: "1", label: "1", id: 1 },
  { value: "2", label: "2", id: 2 },
  { value: "3", label: "3", id: 3 },
  { value: "4", label: "4", id: 4 },
  { value: "5", label: "5", id: 5 },
  { value: "6", label: "6", id: 6 },
  { value: "7", label: "7", id: 7 },
  { value: "8", label: "8", id: 8 },
  { value: "9", label: "9", id: 9 },
  { value: "10", label: "10", id: 10 },
];
export const SINGLE_USER_EMAIL_RESPONSE = {
  yes:
    "Wonderful! We’ll let the Matchmaker know and they should be in touch soon about the opportunity. " +
    "They may want to talk with you over the phone, by video call, or in person. " +
    "Be open to people outside of your standard “type” if you have one as " +
    "the person who you end up with is likely to be different than exactly what you expect. " +
    "Stay tuned, and feel free to contact us if you don’t hear from the Matchmaker" +
    " after 4-5 business days. We hope this leads to a lasting relationship!",
  no:
    "Ok, thanks for letting us know." +
    " We’ve changed your relationship status in your account to reflect that change. " +
    "You won’t hear from us for a while. If you are or end up getting engaged or married, " +
    "we appreciate you updating your account " +
    "[LINK:here] and we won’t contact you again.\n",
  "not-single":
    "Ok, no problem. " +
    "Maybe it’s not the right time. " +
    "Everyone needs some space, so we’re happy to give you some now. " +
    "If you don’t want to hear about matching opportunities ever again, you can cancel your account [LINK: here]." +
    " Otherwise, we may be reaching out in the future about other people!",
};

export const PREMIUM_SERVICE_TERMS = [
  "A video consultation is required so we can get to know you and decide if your search is one we feel confident taking on. We don't want you to have to pay the setup fee if we don't think we can service you well. The consultation is $195 and is non-refundable.",
  "The setup fee is a one-time $595 payment. It is non-refundable once we send your sharable profile. It covers clarifying your search, creating your sharable profile, identifying initial candidates in the database, initial outreach and collecting missing info as needed from candidates.",
  "The fee to meet another member is $499, no matter what their tier or whether they're a DateSpot member or client of a partner matchmaker. By joining the Premium route, you agree that you are willing to pay these prices should we find a suitable match and there is mutual interest.",
  "Premium membership allows you to receive match options from anyone within our network and partner networks when there is mutual interest. They would generally pay $49 or $499 to match with you, depending on their level, but it could be nothing if they are a client of a partner matchmaker, or if we decide to waive the match fee as a rare special case.",
  "There are no guaranteed intros or even match options - we will bring you as many people as we see fit, with no quota as a requirement. The frequency and quantity of Recommended Dates varies per client and is dependent on your unique situation and search parameters. We will conduct an initial search for you but may not report on the results. We may search for you at any time during your membership. Do not expect us to be proactively recruiting and continually searching for you. We'll contact you when we have something of value to provide, but feel free to check in with us anytime.",
  "Introductions are on a pay-per-match basis, where each intro occurs after your payment.",
  "Payment is accepted through Zelle, Venmo, PayPal, or credit card. Pricing may change over time, but your pricing is locked in for at least one year. You also will always have the option to pay at the time of a potential match - we will never automatically charge you.",
  "We generally assess a match based on their account and any info we get from a consultation, if completed. If it is a partner matchmaker's client, and we often go based on multiple photos, account info, and our communication. If you want us to individually meet people for you, we can do that for $699/match through DateSpot Premium VIP level.",
  "In the U.S., introductions are generally done via text to protect privacy of last names and get the fastest response. Internationally, we generally do intros by email to avoid anyone paying phone fees.",
  "Date planning is done by you and the match, which allows you to choose a mutually desirable time and location and easily reach each other in case of last minute changes.",
  "You can pause your membership for an unlimited amount of time if you become exclusive with someone, or for any reason. Please notify us or change your tier in your Account Settings if you ever want to pause, change your tier, or cancel.",
  "We maintain your privacy, and will not post your photo on public-facing social media, but may share your profile with anyone at any time who may be helpful in the search, including any private matchmaker-specific group.",
  "Information about any members is confidential and not to be shared without explicit permission.",
];

export const FREEMIUM_SERVICE_TERMS = [
  "A video consultation is not required but can be helpful in getting to know you. The consultation is $195 and is non-refundable.",
  "Freemium membership allows you to receive curated match options of clients of partner matchmakers and DateSpot members when there is mutual interest.",
  "The fee to meet a DateSpot client (Premium member) is $49. The fee to meet another DateSpot Freemium member is $199. By being in the Freemium route, you agree that you are willing to pay these prices should we find a suitable match and there is mutual interest.",
  "There are no guaranteed matches or even match options. The frequency and quantity of Recommended Dates varies per person and is dependent on your unique situation and search parameters. We may search for you - or not - at any time during your membership. Do not expect us to be proactively recruiting and continually searching for you. We'll contact you when we have something of value to provide.",
  "Introductions are on a pay-per-match basis, where each intro occurs after your payment.",
  "Payment is accepted through Zelle, Venmo, PayPal, or credit card. Pricing may change over time, but your pricing is locked in for one year. You also will always have the option to pay at the time of a potential match - we will never automatically charge you.",
  "We generally assess a match based on their account and any info we get from a consultation, if completed. If it is a partner matchmaker's client, and we often go based on multiple photos, account info, and our communication. If you want us to individually meet people for you, we can do that for $799/match through DateSpot Premium VIP level.",
  "In the U.S., introductions are generally done via text to protect privacy of last names and get the fastest response. Internationally, we generally do intros by email to avoid anyone paying phone fees.",
  "Date planning is done by you and the match, which allows you to choose a mutually desirable time and location and easily reach each other in case of last minute changes.",
  "You can pause your membership for an unlimited amount of time if you become exclusive with someone, or for any reason. Please notify us if you ever want to pause, change your tier, or cancel.",
  "We maintain your privacy, and will not post your photo on public-facing social media, but may share your profile with anyone at any time who may be helpful in the search, including any private matchmaker-specific group.",
  "Information about any members is confidential and not to be shared without explicit permission.",
];
