/*
----------------
variables.scss
----------------

Wherever possible, try to use these variables rather than hardcoding things like
colors, font-sizes, etc.
*/

// Brand Colors
$teal: #65C4C0;
$yellow: #FFDA65;
$lightYellow: #FFDF75;
$darkYellow: #FFBD0C;
$red: #ff596f;
$aqua: #3FC6C0;
$pale: #F0F2ED;
$blue: #013B4D;

// Element colors
$ecolor1: #fff;
$ecolor2: #4a4a4a;
$ecolor3: #cccccc;
$ecolor4: #bbbbbb;
$ecolor5: #aaaaaa;
$bright-teal: #34c7c0;

// Text colors
$tcolor1: $ecolor2;
$tcolor2: white;
$tcolor3: #ddd;
// $tcolor4: #c7ba9b;
$tcolor4: rgb(9, 87, 152);
$redError: #f44336;
$greyDarker: rgba(42, 45, 46, .8);
$greyLight: rgba(42, 45, 46, .6);
$textPrimary: #54585A;
$textLighter: #6B7073;
$helperText: #909193;
$greyLightest: #ffdede;

// Font
$body_font: 'Montserrat', sans-serif;
$heading_font: 'Montserrat', sans-serif;

// Button colors
$bcolor1: rgb(0, 123, 255);
$bcolor2: $ecolor2;

// Link colors
$lcolor1: $tcolor1;
$lcolor2: white;

// Container Width
$max_width: 1100px;
$s_container_width: 90%;
$m_container_width: 90%;
$l_container_width: 86%;
$xl_container_width: 86%;

// Header
$header_height: 5rem; // Set this here so that <main> gets the same margin
$admin_bar_height: 2.7rem;
$main_margin: 3rem;
$nav-bar-height: 78px;
$user-info-header-height: 60px;
$mobile-header-total-height: 149px;
$main-header-padding: 36px;

// Set media query breakpoints
$s_min: 0;       // s: Small, mobile
$m_min: 600px;   // m: Medium, tablet
$l_min: 992px;   // l: Large, Desktop
$xl_min: 1200px; // xl: Extra Large, Big Screen

// Let's also set max sizes which are useful in some media queries
$s_max: $m_min - 1;
$m_max: $l_min - 1;
$l_max: $xl_min - 1;

// Grid
$number_of_columns: 12;
$gutter_width: 2rem;

$mobile: 768px;