import React, { Component } from "react";
import PropTypes from "prop-types";
import RadioButtons from "../radio-button";
import "./style.scss";

class RadioList extends Component {
  state = {
    selected: this.props.selectedValue || null,
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.selectedValue !== this.props.selectedValue) {
      this.setState({ selected: this.props.selectedValue });
    }
  };

  render() {
    const {
      list,
      title,
      stateCallback,
      returnInputValue,
      error,
      required,
      radioWrapperClassName,
      helperText,
      disabled,
    } = this.props;
    const { selected } = this.state;
    return (
      <div className="radio-list flex-column">
        <div className="radio-list__header">
          <div className={`title${selected ? " is-completed-green" : ""}`}>
            {title}
            {required && !selected ? (
              <span className="text-alert-red">*</span>
            ) : (
              ""
            )}
          </div>
          {helperText && <span className="helper-text">{helperText}</span>}
          {error && (
            <div className="right-block-title__error-field">{error}</div>
          )}
        </div>
        <div className="flex-column radio-list__items">
          {list.map((li, l) => {
            const isOtherLabel = li.value === "Other";
            return (
              <RadioButtons
                labelName={li.label}
                className={
                  l === list.length - 1
                    ? `last-element${
                        radioWrapperClassName ? ` ${radioWrapperClassName}` : ""
                      }`
                    : ""
                }
                key={l}
                listElement={li}
                hasInputText={isOtherLabel}
                returnInputValue={(val) => returnInputValue(val)}
                resetInputValue={selected && selected.label !== "Other"}
                selectedInput={selected && selected.value}
                clicked={() => {
                  this.setState({ selected: li });
                  !isOtherLabel && stateCallback(li);
                }}
                checked={selected && selected.value === li.value}
                disabled={disabled}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

RadioList.propTypes = {
  list: PropTypes.array,
  title: PropTypes.string,
  selectedValue: PropTypes.any,
  stateCallback: PropTypes.func,
  returnInputValue: PropTypes.func,
  error: PropTypes.string,
};

export default RadioList;
