import React from "react";

export class Accordion extends React.Component {
  constructor(props) {
    super(props);
    this.panels = [];
    this.state = {
      openPanels: [1],
    };
  }

  componentWillReceiveProps(props) {
    if (props.openPanels) {
      this.setState(
        {
          slide: props.slide,
        },
        () => this.setHeight()
      );
    }
  }

  toggle(div) {
    this.panels.forEach((el) => {
      if (el === div) {
        if (el.state.open) {
          el.setState({ open: false });
        } else {
          el.setState({ open: true });
        }
      } else {
        el.setState({ open: false });
      }
    });
  }

  render() {
    let currentPanel = 0;
    return (
      <div className="accordion">
        <div className="panel-container">
          {this.props.children.map((child) => {
            return React.cloneElement(child, { accordion: this });
          })}
        </div>
      </div>
    );
  }
}

export class AccordionPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open || false,
    };
  }

  componentDidMount() {
    if (this.props.accordion) {
      if (!this.props.accordion.panels.includes(this)) {
        this.props.accordion.panels.push(this);
      }
    }
  }

  toggle() {
    if (this.props.accordion) {
      this.props.accordion.toggle(this);
    } else {
      this.setState({ open: !this.state.open });
    }
  }

  render() {
    return (
      <div className={"accordion-panel" + (this.state.open ? " open" : "")}>
        <h3
          className="panel-toggle"
          onClick={() => {
            this.toggle();
          }}
        >
          {this.props.label}
        </h3>
        <div className="panel-body">{this.props.children}</div>
      </div>
    );
  }
}
