import React, { Component } from "react";
import PropTypes from "prop-types";
import InputText from "../input-text";
import DropDown from "../drop-down";
import { HEIGHT_ATTRIBUTES } from "../../constants/onboarding-data-elements";
import "./style.scss";

class HeightRequirements extends Component {
  constructor(props) {
    super(props);
    const selVal = props.selectedValue;
    const dropDownVal = selVal && selVal.value && selVal.value.attribute;
    const feet =
      selVal &&
      selVal.value &&
      selVal.value.height &&
      selVal.value.height.slice(0, 1);
    const inches =
      selVal &&
      selVal.value &&
      selVal.value.height &&
      selVal.value.height.slice(5, 7);
    const checked = selVal ? (selVal.id === 1 ? "first" : "second") : null;
    this.state = {
      selected: null,
      checked: checked || null,
      inches: inches || null,
      feet: feet || null,
      selectedDropDownValue: dropDownVal || HEIGHT_ATTRIBUTES[0],
      errors: {},
    };
  }

  render() {
    const { list, title, stateCallback, required } = this.props;
    const {
      selected,
      checked,
      inches,
      feet,
      errors,
      selectedDropDownValue,
      height,
    } = this.state;

    return (
      <div className="height-req flex-column">
        <div
          className={`${
            selected || feet || checked ? "title is-completed-green" : "title"
          }`}
        >
          {title}
          {required ? <span className="text-alert-red">*</span> : ""}
        </div>
        <div className="flex-column">
          <div
            className="flex-row height-req__option"
            onClick={() => this.setState({ checked: "first", selected: true })}
          >
            {checked === "first" ? (
              <span className="icon-radio-full" />
            ) : (
              <span className="icon-radio-empty" />
            )}
            <div className="flex-row">
              <div
                className={`flex-row height-input ${
                  inches && feet && !(errors.feetError || errors.inchesError)
                    ? " is-valid"
                    : ""
                }`}
                style={{ marginBottom: "0 " }}
              >
                <InputText
                  wrapperClassName="flex-column no-borders"
                  placeholder="0"
                  value={feet}
                  maxLength="1"
                  error={errors.feetError}
                  onBlur={(e) =>
                    feet && this.processHeight(e.target.value, inches)
                  }
                  onChange={(e) => this.setState({ feet: e.target.value })}
                />
                <span className="input-tag flex-center">ft</span>
                <InputText
                  wrapperClassName="flex-column small no-borders"
                  placeholder="00"
                  maxLength="2"
                  error={errors.inchesError}
                  value={inches}
                  onBlur={(e) =>
                    inches && this.processHeight(feet, e.target.value)
                  }
                  onChange={(e) => this.setState({ inches: e.target.value })}
                />
                <span className="input-tag flex-center">in</span>
              </div>
              <span className="height-req__label" style={{ margin: "0 8px" }}>
                or
              </span>
              <DropDown
                listOfValues={HEIGHT_ATTRIBUTES || null}
                selectedValue={selectedDropDownValue}
                onSelectValue={(val) => {
                  height &&
                    stateCallback({
                      label: "choose",
                      value: {
                        height,
                        attribute: val,
                      },
                      id: 1,
                    });
                  this.setState({ selectedDropDownValue: val });
                }}
              />
            </div>
          </div>

          <div
            className="flex-row height-req__option"
            onClick={() => {
              this.setState({
                checked: "second",
                feet: null,
                inches: null,
                selected: true,
              });
              stateCallback(list[1]);
            }}
          >
            {checked === "second" ? (
              <span className="icon-radio-full" />
            ) : (
              <span className="icon-radio-empty" />
            )}
            <span className="height-req__label">{list[1].label}</span>
          </div>
        </div>
      </div>
    );
  }

  processHeight = () => {
    const { feet, inches, selectedDropDownValue } = this.state;
    let height = "";
    if (feet) {
      height = `${feet} ft`;
    }
    if (feet && inches) {
      height = `${feet} ft ${inches} in`;
    }
    this.setState({ height });
    this.props.stateCallback({
      label: "choose",
      value: {
        height,
        attribute: selectedDropDownValue,
      },
      id: 1,
    });
  };
}

HeightRequirements.propTypes = {
  list: PropTypes.array,
  title: PropTypes.string,
  selectedValue: PropTypes.any,
  stateCallback: PropTypes.func,
  returnInputValue: PropTypes.func,
  error: PropTypes.string,
};

export default HeightRequirements;
