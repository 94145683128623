"use strict";

/**
 * To be used with `onChange` handlers for form elements. This
 * will handle setting deep state paths when `event.target.name` includes `.` dot syntax.
 *
 * Usage: `onChange={LinkState.bind(this, [name])}`
 *
 * It also sets a "lastUpdatedField" property on the state, s.t. we know in "componentDidUpdate" which field was just modified.
 * This information can be used for example to remove error messages for the field that was just edited.
 */
export default function (name, event) {
  if (event === undefined) {
    [event, name] = [name, event];
  }
  const data = {};
  const targetName = name || event.target.name;

  if (targetName.indexOf(".") === -1) {
    if (typeof event === "boolean") {
      data[targetName] = event;
    } else {
      data[targetName] = event.target.value.replace(/^\s+/, "");
    }
    data.lastUpdatedField = targetName;
    this.setState(data);
    return;
  }

  const path = targetName.split(".");
  data[path[0]] = Object.assign({}, this.state[path[0]]);

  path.reduce((memo, part, idx) => {
    if (idx === path.length - 1) {
      memo[part] = event.target.value;
    } else if (memo[part] === undefined || typeof memo[part] !== "object") {
      memo[part] = {};
    }

    return memo[part];
  }, data);

  data.lastUpdatedField = targetName;
  this.setState(data);
}
