@import "../../../../src/scss/variables";

.height-req {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .title {
    color: #ff6666;
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 4px 0;
    &.is-completed-green {
      color: $teal;
    }
  }
  &__option {
    height: 40px;
    .height-input {
      margin-bottom: 0;
    }
    .drop-down-wrapper {
      width: 114px;
    }
  }
  .icon-radio-empty,
  .icon-radio-full {
    cursor: pointer;
    margin-right: 20px;
  }
  &__label {
    color: #54585a;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
  }
}
