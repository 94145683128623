import React, { useState, useEffect } from "react";
import { docs } from "./extracted_docs";
import { fuzzyMatch } from "./../devops/helpers.js";
import { setConfig } from "react-hot-loader";
import ReactMarkdown from "react-markdown";
import { Link, Collapsible } from "widgets";
import { HashLink } from "react-router-hash-link";

function getHash() {
  return decodeURI(window.location.hash.replace("#", ""));
}

function setHash(h) {
  const { history } = require("app");
  history.push(window.location.pathname + `#${h}`);
}

const excludedCats = ["all", "misc", "core"];

// get available categories from extracted docs
const getCategories = () => {
  let categories = [];
  for (let doc of docs) {
    for (let category of doc.categories) {
      if (!categories.includes(category) && !excludedCats.includes(category)) {
        categories.push(category);
      }
    }
  }
  categories.sort();
  return categories;
};

// get list of unique types given a set of docs
const getTypes = (docs) => {
  const types = [...new Set(docs.map((x) => x.type))];
  types.sort();
  return types;
};

// strip comment characters and split into paragraphs
const paragraphs = (text) => {
  for (let regex of [/\/\//g, /\/\*/g, /\*\//g]) {
    text = text.replace(regex, "");
  }
  // allow escaped comments
  text = text.replace(/\\\/\\\//g, "//");

  // automatically add anchor links, skipping code blocks
  const addLinks = (c, link) => {
    const catreg = new RegExp(`\\b(${c})\\b`, "ig");
    return text
      .split("```")
      .map((x, i) => {
        if (i % 2 === 0) {
          return x
            .split("`")
            .map((y, i) => {
              if (i % 2 === 0) {
                return y.replace(catreg, `[$1](#${link || "$1"})`);
              }
              return y;
            })
            .join("`");
        }
        return x;
      })
      .join("```");
  };

  // add links for all categories
  for (const c of getCategories()) text = addLinks(c);
  text = addLinks("api", "api_interaction");

  return text;
};

// process text and render it inside <ReactMarkdown />
const Text = (props) => (
  <ReactMarkdown
    className={`docs-text ${props.className}`}
    source={paragraphs(props.text)}
  />
);

let refs = {};

const LiveDocs = (props) => {
  const [category, _setCategory] = useState("core");
  const [query, _setQuery] = useState("");

  const categories = getCategories();

  const displayed = docs.filter((x) =>
    query
      ? fuzzyMatch(query, x.name)
      : category === "all" || x.categories.includes(category)
  );

  let types = getTypes(displayed);

  const setCategory = (category, skip) => {
    if (!skip) setHash(category);

    _setCategory(
      categories
        .concat(excludedCats)
        .find((x) => x.toLowerCase() === category.toLowerCase())
    );
    _setQuery("");
  };

  const setQuery = (query) => {
    _setQuery(query);
    _setCategory("all");
  };

  useEffect(() => {
    if (getHash())
      setCategory(getHash().replace(/_/g, " ").toLowerCase(), true);
  }, [window.location.hash]);

  useEffect(() => {
    refs.docs_anchor && refs.docs_anchor.scrollIntoView();
  }, [category]);

  const cn = (s) => (s === category ? "active" : "");

  const overview = docs.find(
    (x) => x.type === "overview" && x.name === category
  );

  const location = (f) =>
    f.includes("/launchpad")
      ? "/launchpad" + f.split("launchpad")[1]
      : "/src" + f.split("src")[1];

  const coreItems = docs.filter((x) => x.categories.includes("core"));
  const coreTypes = getTypes(coreItems);

  return (
    <main id="live-docs">
      {/* hack to hide header and footer */}
      <style
        dangerouslySetInnerHTML={{
          __html: `
      header, footer, .sub-footer {display:none !important;};
    `,
        }}
      />

      <div className="sidebar">
        <h2 className={cn("all")} onClick={() => setCategory("all")}>
          All
        </h2>
        <h2 className={cn("core")} onClick={() => setCategory("core")}>
          Core
        </h2>
        <Collapsible open={category === "core"} className="cat-item-list">
          {coreTypes.map((x) => (
            <div key={x}>
              <h4>{x}s</h4>
              {coreItems
                .filter((item) => item.type === x)
                .map((item) => (
                  <div
                    className="item-link"
                    key={item.name}
                    onClick={() =>
                      refs[item.name] &&
                      refs[item.name].scrollIntoView({ behavior: "smooth" })
                    }
                  >
                    {item.name}
                  </div>
                ))}
            </div>
          ))}
        </Collapsible>
        {categories.map((c) => {
          const items = docs.filter((x) => x.categories.includes(c || "core"));
          const types = getTypes(items);

          return (
            <div key={c}>
              <h2 key={c} className={cn(c)} onClick={() => setCategory(c)}>
                {c}
              </h2>
              <Collapsible open={category === c} className="cat-item-list">
                {types.map((x) => (
                  <div key={x}>
                    <h4>{x}s</h4>
                    {items
                      .filter((item) => item.type === x)
                      .map((item) => (
                        <div
                          className="item-link"
                          key={item.name}
                          onClick={() =>
                            refs[item.name] &&
                            refs[item.name].scrollIntoView({
                              behavior: "smooth",
                            })
                          }
                        >
                          {item.name}
                        </div>
                      ))}
                  </div>
                ))}
              </Collapsible>
            </div>
          );
        })}
        <h2 className={cn("misc")} onClick={() => setCategory("misc")}>
          Misc
        </h2>
      </div>
      <div className="docs">
        <h1 className="category-heading">
          {category}
          <input
            placeholder="search"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </h1>
        <div className="elements">
          <div ref={(div) => (refs.docs_anchor = div)} />
          {overview && (
            <div className="overview">
              <h4>Overview</h4>
              <Text className="overview-text" text={overview.text} />
            </div>
          )}
          {types.length === 0 && <p>No matching docs found</p>}
          {types.map((t) => (
            <div className="type" key={t}>
              <h2 className="type-heading">{t + "s"}</h2>
              {displayed
                .filter((x) => x.type === t)
                .map((x) => (
                  <div
                    className="element"
                    key={x.name}
                    ref={(div) => (refs[x.name] = div)}
                    id={`launchpad_item_${x.name}`}
                  >
                    <h3 className="element-heading">
                      <span className="name">{x.name}</span>
                      {console.log(x)}
                      <span className="loc">
                        {x.file}:{x.location}
                      </span>
                      <div className="categories">
                        {x.categories.map((x) => (
                          <Link href={`#${x}`} className="category" key={x}>
                            {x}
                          </Link>
                        ))}
                      </div>
                    </h3>
                    <Text className="element-text" text={x.text} />
                    {x.props.length > 0 && (
                      <div className="detail-group">
                        <h4>Props</h4>
                        <ul>
                          {x.props.map((x) => (
                            <li key={x}>
                              <ReactMarkdown>{x}</ReactMarkdown>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    {x.args.length > 0 && (
                      <div className="detail-group">
                        <h4>Arguments</h4>
                        <ul>
                          {x.args.map((x) => (
                            <li key={x}>
                              <ReactMarkdown>{x}</ReactMarkdown>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    {x.return && (
                      <div className="detail-group">
                        <h4>Return</h4>
                        <ReactMarkdown>{x.return}</ReactMarkdown>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          ))}
        </div>
      </div>
    </main>
  );
};

export default LiveDocs;
