import React, { useState, useEffect } from "react";
import { Slideshow } from "widgets";
import { DropDown } from "./DropDown";

const Thumbnail = ({ src, className }) => {
  return (
      <div
          className={`thumbnail image-contain ${className || ""}`}
          style={{ backgroundImage: `url(${src})` }}
      />
  );
};

export function ImageCarousel({ images, imageClass, optionsMenu }) {
  const [image, setImage] = useState(images[0]);
  const [photoMenu, setPhotoMenu] = useState(false);
  images = [...new Set(images)];
  const slide = images.indexOf(image);

  const setSlide = (s) => {
    s = Math.max(Math.min(s, images.length), 0);
    if (images[s]) setImage(images[s]);
  };

  const offset = Math.max(slide - 2, 0);
  const maxOffset = images.length > 3 ? images.length - 4 : 1;
  const base = images.length > 3 ? 0 : -1;
  const left = (base + Math.min(maxOffset, offset)) * -20;

  return (
      <div className="image-carousel">
        {
            optionsMenu && (
                <div style={{ position: "absolute", top: 0, right: 0 }}>
                  <span className="icon-more" onClick={() => setPhotoMenu(!photoMenu)} />
                  <DropDown className="menu-dropdown" open={photoMenu}>
                    {
                      optionsMenu.map((option, i) => (
                          <div key={i} className={`user-profile-action`} onClick={()=>{
                            option.action(slide)
                            setPhotoMenu(false)
                          }}>
                            {option.label}
                          </div>
                      ))
                    }
                  </DropDown>
                </div>
            )
        }
        <Slideshow slide={slide + 1}>
          {images.map((i) => (
              <Thumbnail className={imageClass} key={i} src={i} />
          ))}
        </Slideshow>
        {images.length > 1 && (
            <div className="selector">
              <div
                  className="fa fa-angle-left"
                  onClick={() => setSlide(slide - 1)}
              />
              <div className="selector-wrapper">
                <div className="selector-container" style={{ left: `${left}%` }}>
                  {images.map((i) => (
                      <div
                          key={i}
                          className={`image-contain select-image ${i === image && "active"
                          } ${imageClass || ""}`}
                          onClick={() => setImage(i)}
                          style={{ backgroundImage: `url(${i})` }}
                      />
                  ))}
                </div>
              </div>
              <div
                  className="fa fa-angle-right"
                  onClick={() => setSlide(slide + 1)}
              />
            </div>
        )}
      </div>
  );
}
