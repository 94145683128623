/*
----------------
launchpad/snips
----------------
A <Snip> component is used wherever dynamic text content is to appear.

*/

import { getGlobal, setGlobal, getSnip, setSnip, PageContext } from "launchpad";
import ContentEditable from "react-contenteditable";
import React from "react";
import { Editor } from "slate-react";
import { Value } from "slate";
import {
  html,
  renderNode,
  renderMark,
  hotKeys,
  setActiveEditor,
  getActiveEditor,
  getActiveEditorComponent,
  editorClick,
  checkActiveBlocks,
} from "./snip_data";
import styled from "styled-components";

// const slateEditorPlugins = [ EditList() ]
const slateEditorPlugins = [];

const snipSchema = { inlines: { image: { isVoid: true } } };

export const getPlainText = (html) => {
  return html.replace(/<[^>]+>/g, "");
};

export class SlateEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.defaultState;
    this.state.defaultValue = "";
  }

  get defaultState() {
    const props = this.props;
    const component = props.inline
      ? props.component || "span"
      : props.component || "p";
    let lorem =
      (getGlobal("is_admin") ? this.props.placeholder : "") ||
      (getGlobal("is_admin") ? getLorem(props.lorem || 3) : "");
    if (!props.lorem) lorem = "Click to edit...";
    const emptyHTML = `<${component}></${component}>`;
    const Component = component;

    return {
      placeholder: lorem,
      value: html.deserialize(props.default || emptyHTML),
      htmlValue: props.defaultValue || "",
      plainText: "",
    };
  }

  shouldComponentUpdate(np, ns) {
    if (!this.focused && np.defaultValue !== this.defaultValue) {
      return true;
    }
    if (getGlobal("is_admin") !== this.admin) {
      this.admin = getGlobal("is_admin");
      //if(!this.state.htmlValue && !this.props.defaultValue) this.setState(this.defaultState)
      return true;
    }
    return !!this.focused;
  }

  componentDidUpdate(prevProps, prevState) {
    const Component =
      this.props.component || (this.props.inline ? "span" : "p");
    const { defaultValue } = this.props;
    const plainDefault = getPlainText(defaultValue);

    // reset container if not focused and a change in the default value is detected
    if (
      (defaultValue && !this.defaultValue) ||
      (!this.focused && plainDefault && defaultValue !== this.defaultValue)
    ) {
      this.setState(
        Object.assign({}, this.defaultState, {
          value: html.deserialize(defaultValue),
        })
      );
      this.defaultValue = defaultValue;
    }
  }

  onChange = ({ value }) => {
    // this.setState({value})
    const htmlValue = html.serialize(value);
    const plainText = getPlainText(htmlValue).replace(/ /g, "");
    this.setState({ htmlValue, plainText, value });

    if (this.debounceTimer) clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      //this.pushChange(v)
      if (this.props.onChange) this.props.onChange(htmlValue);
    }, 300);
  };

  focus = (e, editor) => {
    this.focused = true;
    setTimeout(() => editor.focus());
  };

  blur = (e, editor) => {
    this.focused = false;
    setTimeout(() => {
      if (!this.focused && getActiveEditorComponent() === this) {
        setActiveEditor(null);
      }
    }, 0);
    // update default value
    //this.componentDidUpdate()
  };

  render = () => {
    const props = getGlobal("is_admin")
      ? {
          onChange: this.onChange,
          onClick: (e, editor) => {
            setActiveEditor(editor, this);
            if (!this.props.allowClick) {
              e.stopPropagation();
              e.preventDefault();
            }
            editorClick(e, editor);
          },
          onKeyDown: hotKeys,
          onKeyUp: (e, editor) => checkActiveBlocks(editor),
          onFocus: this.focus,
          onBlur: this.blur,
          placeholder: this.state.placeholder,
        }
      : {
          readOnly: true,
          onClick: () => {},
        };

    return (
      <Editor
        {...props}
        className={`${this.props.className} ${
          !this.state.plainText ? "empty" : ""
        }`}
        style={this.props.style}
        value={
          getGlobal("is_admin")
            ? this.state.value
            : html.deserialize(this.state.htmlValue)
        }
        plugins={slateEditorPlugins}
        renderNode={renderNode}
        renderMark={renderMark}
        schema={snipSchema}
      />
    );
  };
}

/* launchpad component: Snip
   categories: CMS, core
   A `Snip` is a flexible, editable text box (WYSIWYG) that is automatically
   set up for user editing when a launchpad user is logged in
   props:
    page - (String) which page this text, typically unnecessary as it can be picked up from the Page Context
    name - (String) a unique name for this piece of text (or a shared name for shared data)
*/

class SnipClass extends React.Component {
  state = { htmlValue: "" };

  pushChange = (v) => {
    if (v !== this.state.htmlValue) {
      this.setState({ htmlValue: v });
      // TODO: sync with other snips that have the same name/page
      setSnip(v, this.props.name, this.props.page);
    }
  };

  value = "";

  // slate editor is touchy, sometimes focus is lost or misplaced when the
  // component is updated (especially via setGlobal), this mitigates that
  // TODO: invesitgate and hopefully remove this to avoid difficult to debug
  // issues in the future
  shouldComponentUpdate() {
    const snip = getSnip(this.props.name, this.props.page);
    const defaultValue =
      snip && ((getGlobal("is_admin") ? snip.draft : snip.content) || "");
    if (defaultValue !== this.value) {
      this.value = defaultValue;
      return true;
    }
    return false;
  }

  render() {
    const { name, page, inline, className } = this.props;
    const snip = getSnip(this.props.name, this.props.page);
    const plain = getPlainText((snip && snip.content) || "");
    const cn = `${className ? className : ""} snip-container ${
      inline ? "inline" : ""
    } ${getGlobal("is_admin") ? "admin" : ""}`;
    const defaultValue =
      snip && ((getGlobal("is_admin") ? snip.draft : snip.content) || "");
    const C = this.props.component || "div";
    return (
      <SlateEditor
        onClick={(e) => e.stopPropagation()}
        {...this.props}
        defaultValue={defaultValue}
        onChange={this.pushChange}
        className={cn}
        style={this.props.style}
      />
    );
  }
}

export const Snip = (props) => (
  <PageContext.Consumer>
    {(context) => <SnipClass {...props} page={props.page || context.pageId} />}
  </PageContext.Consumer>
);

// get HTML from a string
export const getHTML = (string, component) => {
  const c = component || "p";
  return `<${c}>${string
    .split(/[\r\n]+/)
    .filter((t) => !!t)
    .join(`</${c}><${c}>`)}</${c}>`;
};

// get arbitrarily long lorem ipsum, wrapped in html tags
export const getLorem = (length, component) => {
  const l = lorem.split(" ").slice(0, length).join(" ") + (length ? "..." : "");
  if (!component) return l;
  return getHTML(l, component);
};

// alias SlateEditor for backwards compatibility
export const TextEditor = (props) => {
  return getGlobal("is_admin") ? (
    <SlateEditor {...props} defaultValue={props.value} />
  ) : (
    <div dangerouslySetInnerHTML={{ __html: props.value }} />
  );
};

const lorem = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent mauris. Fusce nec tellus sed augue semper porta. Mauris massa. Vestibulum lacinia arcu eget nulla.

Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur sodales ligula in libero. Sed dignissim lacinia nunc. Curabitur tortor. Pellentesque nibh. Aenean quam. In scelerisque sem at dolor. Maecenas mattis. Sed convallis tristique sem. Proin ut ligula vel nunc egestas porttitor. Morbi lectus risus, iaculis vel, suscipit quis, luctus non, massa. Fusce ac turpis quis ligula lacinia aliquet. Mauris ipsum.

Nulla metus metus, ullamcorper vel, tincidunt sed, euismod in, nibh. Quisque volutpat condimentum velit. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nam nec ante. Sed lacinia, urna non tincidunt mattis, tortor neque adipiscing diam, a cursus ipsum ante quis turpis. Nulla facilisi. Ut fringilla. Suspendisse potenti. Nunc feugiat mi a tellus consequat imperdiet. Vestibulum sapien. Proin quam. Etiam ultrices. Suspendisse in justo eu magna luctus suscipit. Sed lectus.

Integer euismod lacus luctus magna. Quisque cursus, metus vitae pharetra auctor, sem massa mattis sem, at interdum magna augue eget diam. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Morbi lacinia molestie dui. Praesent blandit dolor. Sed non quam. In vel mi sit amet augue congue elementum. Morbi in ipsum sit amet pede facilisis laoreet. Donec lacus nunc, viverra nec, blandit vel, egestas et, augue. Vestibulum tincidunt malesuada tellus. Ut ultrices ultrices enim. Curabitur sit amet mauris. Morbi in dui quis est pulvinar ullamcorper. Nulla facilisi.

Integer lacinia sollicitudin massa. Cras metus. Sed aliquet risus a tortor. Integer id quam. Morbi mi. Quisque nisl felis, venenatis tristique, dignissim in, ultrices sit amet, augue. Proin sodales libero eget ante. Nulla quam. Aenean laoreet. Vestibulum nisi lectus, commodo ac, facilisis ac, ultricies eu, pede. Ut orci risus, accumsan porttitor, cursus quis, aliquet eget, justo.

Sed pretium blandit orci. Ut eu diam at pede suscipit sodales. Aenean lectus elit, fermentum non, convallis id, sagittis at, neque. Nullam mauris orci, aliquet et, iaculis et, viverra vitae, ligula. Nulla ut felis in purus aliquam imperdiet. Maecenas aliquet mollis lectus. Vivamus consectetuer risus et tortor. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.

Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent mauris. Fusce nec tellus sed augue semper porta. Mauris massa. Vestibulum lacinia arcu eget nulla. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur sodales ligula in libero. Sed dignissim lacinia nunc. Curabitur tortor. Pellentesque nibh. Aenean quam.

In scelerisque sem at dolor. Maecenas mattis. Sed convallis tristique sem. Proin ut ligula vel nunc egestas porttitor. Morbi lectus risus, iaculis vel, suscipit quis, luctus non, massa. Fusce ac turpis quis ligula lacinia aliquet. Mauris ipsum. Nulla metus metus, ullamcorper vel, tincidunt sed, euismod in, nibh. Quisque volutpat condimentum velit. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nam nec ante.

Sed lacinia, urna non tincidunt mattis, tortor neque adipiscing diam, a cursus ipsum ante quis turpis. Nulla facilisi. Ut fringilla. Suspendisse potenti. Nunc feugiat mi a tellus consequat imperdiet. Vestibulum sapien. Proin quam. Etiam ultrices. Suspendisse in justo eu magna luctus suscipit. Sed lectus. Integer euismod lacus luctus magna. Quisque cursus, metus vitae pharetra auctor, sem massa mattis sem, at interdum magna augue eget diam.

Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Morbi lacinia molestie dui. Praesent blandit dolor. Sed non quam. In vel mi sit amet augue congue elementum. Morbi in ipsum sit amet pede facilisis laoreet. Donec lacus nunc, viverra nec, blandit vel, egestas et, augue. Vestibulum tincidunt malesuada tellus. Ut ultrices ultrices enim. Curabitur sit amet mauris. Morbi in dui quis est pulvinar ullamcorper. Nulla facilisi. Integer lacinia sollicitudin massa. Cras metus. Sed aliquet risus a tortor.
`;
