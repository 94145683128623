@import "src/scss/variables";

.helpSection {
  margin-top: 24px;

  .helpSection-title {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $blue;
    h2 {
      color: #FFF;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Montserrat;
      font-size: 35px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
    }
  }

  .helpSection-content {
    padding: 16px 36px;
    display: flex;
    justify-content: space-between;

    .helpSection-content__left {
      width: 60%;
      h3 {
        color: #11263C;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
      }
      p {
        color: #303A42;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 25.483px;
      }
      h5 {
        color: #303A42;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 25.483px; /* 159.267% */
        text-decoration-line: underline;
        text-transform: uppercase;
      }
    }

    .helpSection-content__right {
        width: 40%;
        justify-content: end;
        display: flex;
        div {
          margin-top: 24px;
          height: 60%;
          flex-shrink: 0;
          background-color: $bright-teal;
          img {
            position: relative;
            top:12%;
            left: -15%;
          }
        }
    }
  }

}

.databaseSection {
  padding: 32px;
  p {
    color: var(--Text, #54585A);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .databaseSection-content {
    padding: 8px;
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    .databaseSection-content__left {
      width: 45%;
      gap: 22px;
      .databaseSection-content__left-name {
        display: flex;
        gap: 16px;
        margin-bottom: 22px;
      }
      .input-error {
        border: 1px solid red;
        &:focus {
          outline: none;
          border: 1px solid #303A42;
        }
      }
      textarea {
        &::placeholder {
          color: rgba(48, 58, 66, 0.50);
          font-family: Montserrat;
          font-size: 19px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        &:focus {
          outline: none;
          border: 1px solid #303A42;
        }
      }
      button {
        width: 100%;
        border-radius: 10px;
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px;
      }
    }
    .databaseSection-content__image {
      width: 55%;
      justify-content: end;
      display: flex;
      margin-bottom: 24px;
      div {
        margin-top: 24px;
        height: 100%;
        width: 55%;
        flex-shrink: 0;
        background-color: $bright-teal;
        img {
          position: relative;
          width: 100%;
          top:7%;
          left: -9%;
        }
      }
    }
  }
}

@media (max-width: $mobile) {
  .helpSection {
    margin-top: 0;
    .helpSection-title {
      h2 {
        font-size: 20px;
      }
    }
    .helpSection-content {
      padding: 16px;
      display: block;
      .helpSection-content__left {
        width: 100%;
        h3 {
          display: none;
        }
      }
      .helpSection-content__right {
        width: 100%;
        display: flex;
        justify-content: center;
        div {
          width: 70%;
          height: 264px;
          img {
            position: relative;
            width: 90%;
            top: 10%;
            left: -9%
          }
        }
      }
    }
  }
}

@media (max-width: $mobile) {
  .databaseSection {

    .databaseSection-content {
      margin-top: 0;
      padding: 0;
      display: block;
      .databaseSection-content__left {
        width: 100%;
        .databaseSection-content__left-name {
          display: flex;
          flex-direction: column;
          margin-bottom: 22px;

        }
      }
      .databaseSection-content__image {
        width: 100%;
        display: flex;
        justify-content: center;
        div {
          width: 70%;
          height: 242px;
          img {
            position: relative;
            width: 90%;
            top: 10%;
            left: -9%
          }
        }
      }


    }
  }

}

.paymentMethod {
  display: flex;
  align-items: center;
  gap: 32px;
  margin-bottom: 32px;
  div {
    display: flex;
    flex-direction: column;
    width: 32px;
    height: 32px;
    span {
      color: var(--Black, #000);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
    }
  }
  .paymentMethod-mail {
    color: #303A42;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.483px;
  }
}

.sectionSeparator {
  width: 100%;
  background-color: $aqua;
  padding: 16px;
  display: block;
  box-sizing: border-box;

  p {
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    margin: 0;

  }
}

.questionContainer {
  display: flex;
  justify-content: space-between;
  padding: 30px;

  .questionAnswer {
    width: 90%;
    @media (max-width: $mobile) {
      width: 110%;
    }

    .questionAnswer-question {
      color: #303A42;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Montserrat;
      font-size: 17px;
      font-style: normal;
      font-weight: 700;
      line-height: 23.784px;

      &.questionSelected {
        color: $aqua;
      }

    }
    .questionAnswer-answer {
      color: #303A42;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Montserrat;
      font-size: 15.29px;
      font-style: normal;
      font-weight: 400;
      line-height: 25.483px;
    }
  }
  .question-icon {
    margin-right: 24px;
    font-size: 27px;
    font-weight: 600;
    color: $aqua;
    cursor: pointer;

    &.minusIcon {
      font-size: 36px;
    }
  }
}

.questionBorder-container {
  height: 1px;
  display: flex;
  justify-content: center;
  .questionBorder-border {
    width: 96%;
    background-color: $ecolor3;
  }
}

