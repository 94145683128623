export const validateEmail = (email) => {
  if (!email) {
    return "Email is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(email)) {
    return "Invalid email address";
  }
  return "";
};

export const validatePassword = (password) => {
  if (!password) {
    return "Password is required";
  } else if (password.length < 6) {
    return "Password must be at least 6 characters";
  }

  return "";
};

export const validateSignUpForm = (
  {
    email,
    password,
    confirmPassword,
    firstName,
    lastName,
    routes,
    agreeWithTermsAndPolicies,
    companyName,
    websiteUrl,
    referralSource,
  },
  isMatchmakerSignUp
) => {
  let errors = {};

  if (!email) {
    errors.email = "Email is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(email)) {
    errors.email = "Invalid email address";
  }

  if (!password) {
    errors.password = "Password is required";
  } else if (password.length < 6) {
    errors.password = "Password must be at least 6 characters";
  }

  if (!confirmPassword) {
    errors.confirmPassword = "Confirm password is required";
  } else if (confirmPassword !== password) {
    errors.confirmPassword = "Passwords do not match";
  }

  if (!firstName) {
    errors.firstName = "First name is required";
  }

  if (!lastName) {
    errors.lastName = "Last name is required";
  }

  if (!isMatchmakerSignUp && routes.length === 0) {
    errors.route = "You must select a route to continue";
  }

  if (!agreeWithTermsAndPolicies) {
    errors.agreeWithTermsAndPolicies = "Required";
  }

  if (isMatchmakerSignUp) {
    if (!companyName) {
      errors.companyName = "Company Name is Required";
    }
    if (!websiteUrl) {
      errors.websiteUrl = "Website URL is Required";
    } else if (!/[^ "]+\.[^ "]+$/.test(websiteUrl)) {
      errors.websiteUrl = "Invalid website URL";
    }
  }

  if (
      referralSource &&
      referralSource.length != 0 &&
      referralSource.find((item) => item === "Other")
  ) {
    let includesFirstName = false;
    let includesEmail = false;
    referralSource.forEach((item) => {
      if (item === "Other") return;
      if (
          "First Name" in item &&
          typeof item["First Name"] === "string" &&
          item["First Name"].length > 0
      ) {
        includesFirstName = true;
        return;
      }
      if (
          "Email" in item &&
          typeof item["Email"] === "string" &&
          item["Email"].length > 0
      ) {
        includesEmail = true;
        return;
      }
    });
    if (!includesFirstName || !includesEmail) {
      errors.referralSource = "Required";
    }
  }

  return errors;
};
