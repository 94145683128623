import facebookFooter from "../../images/facebook-footer.svg";
import instagramFooter from "../../images/instagram-footer.svg";
import linkedinFooter from "../../images/linkedin-footer.svg";
import twitterFooter from "../../images/twitter-footer.svg";

export const SOCIAL_LINKS = [
  {
    id: "facebook-link-footer",
    url: "https://www.facebook.com/datespot.love/",
    icon: facebookFooter,
    alt: "DateSpot Facebook",
  },
  {
    id: "instagram-link-footer",
    url: "https://www.instagram.com/datespot.love/",
    icon: instagramFooter,
    alt: "DateSpot Instagram",
  },
  {
    id: "linkedin-link-footer",
    url: "https://www.linkedin.com/company/datespot/",
    icon: linkedinFooter,
    alt: "DateSpot LinkedIn",
  },
  {
    id: "twitter-link-footer",
    url: "https://twitter.com/thedatespot",
    icon: twitterFooter,
    alt: "DateSpot Twitter",
  },
];

export const SERVICES_LINKS = [
  {
    id: "paid-matchmaking-footer",
    label: "Matchmaking",
    url: "/for-singles/matchmaking",
  },
  {
    id: "deep-dive-call-footer",
    label: "Deep-Dive Video Call",
    url: "/video-call",
  },
  {
    id: "free-matchmaking-footer",
    label: "Be a candidate",
    url: "/for-singles/become-a-candidate",
  },
  {
    id: "gift-memberships-footer",
    label: "Gift Memberships",
    url: "/gift-memberships",
  },
  {
    id: "enm-matchmaking-footer",
    label: "ENM Matchmaking",
    url: "/for-singles/enm-relationships",
  },
  {
    id: "db-for-mm-footer",
    label: "Database For Matchmakers",
    url: "/for-matchmakers",
  }
];

export const RESOURCES_LINKS = [
  {
    id: "dating-guide-footer",
    label: "Dating Guide",
    url: "/dating-guide",
  },
  {
    id: "dating-dictionary-footer",
    label: "Dating Dictionary",
    url: "/dating-dictionary",
  },
  {
    id: "blog-footer",
    label: "Blog",
    url: "/blog",
  },
  {
    id: "faq-footer",
    label: "FAQ",
    url: "/for-singles/faqs",
  },
  {
    id: "member-discounts-footer",
    label: "Member Discounts",
    url: "/member-discounts",
  },
  {
    id: "refer-friends-footer",
    label: "Refer Friends",
    url: "/referrals",
  },
];

export const COMPANY_LINKS = [
  {
    id: "contact-us-footer",
    label: "Contact",
    url: "/contact",
  },
  {
    id: "careers-footer",
    label: "Careers",
    url: "/careers",
  },
  {
    id: "our-values-footer",
    label: "Our Values",
    url: "/about-us#our-values",
  },
  {
    id: "press-footer",
    label: "Press",
    url: "/press",
  },
  {
    id: "privacy-policy-footer",
    label: "Privacy Policy",
    url: "/privacy-policy",
  },
  {
    id: "site-terms-footer",
    label: "Site Terms",
    url: "/for-singles/terms-of-service",
  },
];
