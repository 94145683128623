import {
  getGlobal,
  PageContext,
  DataStore,
  Image,
  Snip,
  Modal,
} from "launchpad";
import ContentEditable from "react-contenteditable";
import React from "react";
import styled from "styled-components";
import theme from "styles/theme.js";
import Fade from "react-move-stuff";
import { OptionsPanel, LayoutSelector, Section } from "sections";
import { Link, Collapsible } from "widgets";

const BlockOptions = DataStore("CTAOptions");

const Container = styled.div`
  position: relative;
  padding: 25px 0;
  background: #65c4c0;

  .inner {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    padding: 30px 20px;

    > * {
      flex: 1;
      padding: 10px;
    }
    .buttons {
      text-align: center;
      a,
      a:not([href]) {
        margin: 10px;
      }
    }

    &.vertical {
      display: block;
      text-align: center;
    }
  }

  .edit-button {
    position: absolute;
    bottom: 15px;
    right: 15px;
    font-size: 1rem;
    button {
      font-size: 1.7rem;
    }
  }

  @media only screen and (max-width: 812px) {
    .inner {
      display: block;
    }
  }
`;

class Class extends React.Component {
  state = {
    edit: false,
    // only used when editing
    options: false,
  };

  edit = (obj) => {
    const updated = Object.assign({}, this.props.options, obj);
    this.props.onEdit(updated);
  };

  getOptions = () => {
    if (this.state.options) return this.state.options;
    const o = BlockOptions.getAll();
    let options = o.find((op) => {
      return op.page === this.props.page && op.name === this.props.name;
    });
    return options || { page: this.props.page, name: this.props.name };
  };

  setOptions = (options) => {
    const currentOptions = this.getOptions();
    let newOptions = Object.assign({}, currentOptions, options);

    // load into state while editing
    this.setState({ options: newOptions });

    BlockOptions.update(currentOptions.id, newOptions);
  };

  render() {
    const options = this.getOptions();
    const { edit } = this.state;
    const { name, onButtonClick } = this.props;
    const defaults = this.props.defaultOptions || {};
    let layout = (options && options.layout) || defaults.layout || "vertical";

    const text = (
      <div key="text" className="text">
        <Snip
          component="h2"
          className="text-white"
          name={name !== "cta3" ? name + "-cta-heading" : "content-cta-heading"}
          page={this.props.page}
        />
        {layout !== "vertical" && (
          <Snip
            className="lead text-white mb-0"
            name={
              name !== "cta3"
                ? name + "-cta-main-content"
                : "content-cta-main-content"
            }
            page={this.props.page}
          />
        )}
      </div>
    );
    const buttons = (getGlobal("is_admin") ||
      options.button1Text ||
      options.button2Text) && (
      <div key="buttons" className="buttons">
        {(options.button1Text || getGlobal("is_admin")) && (
          <Link
            className="btn btn-primary"
            href={options.button1Link || (!onButtonClick && "#")}
            onClick={onButtonClick}
            style={options.button1Text ? {} : { opacity: 0.5 }}
          >
            {options.button1Text || "Lorem ipsum..."}
          </Link>
        )}
        {options.button2Text && (
          <Link className="btn btn-light" href={options.button2Link || "#"}>
            {options.button2Text}
          </Link>
        )}
      </div>
    );

    const layouts = {
      vertical: [text, buttons],
      "left-text": [text, buttons],
      "right-text": [buttons, text],
      "no-text": [buttons],
      "no-buttons": [text],
    };

    if (!layouts[layout]) layout = "vertical";

    return (
      <Fade up>
        <Section
          editing={this.state.edit}
          className={`cta ${this.props.className || ""}`}
        >
          <Container>
            <div className={`inner ${layout}`}>{layouts[layout]}</div>

            {getGlobal("is_admin") && (
              <div className="edit-button">
                <button
                  className="material-icons"
                  onClick={() => this.setState({ edit: !edit })}
                >
                  {edit ? "close" : "edit"}
                </button>
              </div>
            )}
          </Container>
        </Section>

        {getGlobal("is_admin") && (
          <OptionsPanel
            open={this.state.edit}
            onClose={() => this.setState({ edit: false })}
          >
            <h3>CTA Options</h3>
            <LayoutSelector
              options={Object.keys(layouts)}
              value={layout}
              onChange={(l) => this.setOptions({ layout: l })}
            />
            <div className="option-group">
              <div className="option-row">
                <div>
                  <span className="row-label">Button 1 Text</span>
                  <input
                    placeholder="Leave empty to hide Button 1"
                    value={options.button1Text || ""}
                    onChange={(e) =>
                      this.setOptions({ button1Text: e.target.value })
                    }
                  />
                </div>
                {!this.props.onButtonClick && (
                  <div>
                    <span className="row-label">Button 1 Link</span>
                    <input
                      placeholder="Leave empty to hide Button 1"
                      value={options.button1Link || ""}
                      onChange={(e) =>
                        this.setOptions({ button1Link: e.target.value })
                      }
                    />
                  </div>
                )}
              </div>

              {!this.props.onButtonClick && (
                <div className="option-row">
                  <div>
                    <span className="row-label">Button 2 Text</span>
                    <input
                      placeholder="Leave empty to hide Button 2"
                      value={options.button2Text || ""}
                      onChange={(e) =>
                        this.setOptions({ button2Text: e.target.value })
                      }
                    />
                  </div>
                  <div>
                    <span className="row-label">Button 2 Link</span>
                    <input
                      placeholder="Leave empty to hide Button 2"
                      value={options.button2Link || ""}
                      onChange={(e) =>
                        this.setOptions({ button2Link: e.target.value })
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </OptionsPanel>
        )}
      </Fade>
    );
  }
}

export const CTA = (props) => (
  <PageContext.Consumer>
    {(context) => <Class {...props} page={props.page || context.pageId} />}
  </PageContext.Consumer>
);
