import React from "react";
import Html from "slate-html-serializer";
import { getGlobal } from "launchpad";
import { Selection } from "slate";
import { Link, Title } from "widgets";

// ===== BASIC SCHEMA =================================== //

const BLOCK_TAGS = {
  blockquote: "quote",
  p: "paragraph",
  pre: "code",
  ol: "ol_list",
  ul: "ul_list",
  li: "list_item",
  h1: "heading_1",
  h2: "heading_2",
  h3: "heading_3",
  h4: "heading_4",
};

const INLINE_TAGS = {
  a: "link",
  img: "image",
};

// Add a dictionary of mark tags.
const MARK_TAGS = {
  em: "italic",
  strong: "bold",
  u: "underline",
  span: "span",
};

const rules = [
  {
    deserialize(el, next) {
      const type = BLOCK_TAGS[el.tagName.toLowerCase()];
      if (type) {
        return {
          object: "block",
          type: type,
          data: {
            className: el.getAttribute("class"),
            href: el.getAttribute("href"),
          },
          nodes: next(el.childNodes),
        };
      }
    },
    serialize(obj, children) {
      if (obj.object === "block") {
        //console.log(`block type: ${obj.type}`)
        switch (obj.type) {
          case "code":
            return (
              <pre>
                <code>{children}</code>
              </pre>
            );
          case "paragraph":
            return <p className={obj.data.get("className")}>{children}</p>;
          case "quote":
            return <blockquote>{children}</blockquote>;
          case "ol_list":
            return <ol>{children}</ol>;
          case "ul_list":
            return <ul>{children}</ul>;
          case "list_item":
            return <li>{children}</li>;
          case "heading_1":
            return <h1>{children}</h1>;
          case "heading_2":
            return <h2>{children}</h2>;
          case "heading_3":
            return <h3>{children}</h3>;
          case "heading_4":
            return <h4>{children}</h4>;
        }
      }
    },
  },
  // Add a new rule that handles marks...
  {
    deserialize(el, next) {
      const type = MARK_TAGS[el.tagName.toLowerCase()];
      if (type) {
        return {
          object: "mark",
          type: type,
          nodes: next(el.childNodes),
        };
      }
    },
    serialize(obj, children) {
      if (obj.object === "mark") {
        //console.log(`mark type: ${obj.type}`)
        switch (obj.type) {
          case "bold":
            return <strong>{children}</strong>;
          case "italic":
            return <em>{children}</em>;
          case "underline":
            return <u>{children}</u>;
          case "span":
            return <span>{children}</span>;
        }
      }
    },
  },
  {
    deserialize: (el, next) => {
      const type = INLINE_TAGS[el.tagName.toLowerCase()];
      //console.log(el.tagName.toLowerCase(), type, el.getAttribute && el.getAttribute('href'))
      if (!type) return;
      switch (type) {
        case "link":
          return {
            object: "inline",
            type: type,
            nodes: next(el.childNodes),
            data: {
              href: el.getAttribute("href"),
            },
          };
        case "image":
          return {
            object: "inline",
            type: type,
            data: {
              src: el.getAttribute("src"),
            },
          };
      }
    },
    serialize: (obj, children) => {
      if (obj.object !== "inline") return;
      //console.log(`inline type: ${obj.type}`)
      switch (obj.type) {
        case "link":
          return <Link href={obj.data.get("href")}>{children}</Link>;
        case "image":
          return <img src={obj.data.get("src") || ""} />;
      }
    },
  },
];

export const html = new Html({ rules });

export const renderNode = (props, editor, next) => {
  const { node, attributes, children } = props;
  try {
    switch (node.type) {
      case "code":
        return (
          <pre {...attributes}>
            <code>{children}</code>
          </pre>
        );
      case "paragraph":
        return (
          <p {...attributes} className={node.data.get("className")}>
            {children}
          </p>
        );
      case "quote":
        return <blockquote {...attributes}>"{children}"</blockquote>;
      case "ol_list":
        return <ol {...attributes}>{children}</ol>;
      case "ul_list":
        return <ul {...attributes}>{children}</ul>;
      case "list_item":
        return <li {...attributes}>{children}</li>;
      case "link":
        return (
          <Link {...attributes} href={node.data.get("href")}>
            {children}
          </Link>
        );
      case "image": {
        const src = node.data.get("src");
        return <img {...attributes} src={src} />;
      }
      case "heading_1":
        return <Title {...attributes}>{children}</Title>;
      case "heading_2":
        return <h2 {...attributes}>{children}</h2>;
      case "heading_3":
        return <h3 {...attributes}>{children}</h3>;
      case "heading_4":
        return <h4 {...attributes}>{children}</h4>;
      default:
        return next();
    }
  } catch (e) {
    console.log(e);
  }
};

export const renderMark = (props, editor, next) => {
  const { mark, attributes, children } = props;
  switch (mark.type) {
    case "bold":
      return <strong {...attributes}>{children}</strong>;
    case "italic":
      return <em {...attributes}>{children}</em>;
    case "underline":
      return <u {...attributes}>{children}</u>;
    default:
      return next();
  }
};

// ===== LIST FUNCTIONS ====================================== //

export const isList = (value) =>
  value.blocks.some((block) => block.type === "list_item");

export const hasParentOfType = (value, type) =>
  value.blocks.some(
    (block) =>
      !!value.document.getClosest(block.key, (parent) => parent.type === type)
  );
export const isUnorderedList = (value) => hasParentOfType(value, "ul_list");
export const isOrderedList = (value) => hasParentOfType(value, "ol_list");

export const getNodeOfType = (value, type) =>
  value.blocks.filter((block) => block.type === type).first();
export const getUnorderedListNode = (value) => getNodeOfType(value, "ul_list");
export const getOrderedListNode = (value) => getNodeOfType(value, "ol_list");

export const removeUnorderedList = (change) =>
  change.setBlocks("paragraph").unwrapBlock("ul_list").focus();

export const switchToOrderedList = (change) =>
  change.unwrapBlock("ul_list").wrapBlock("ol_list").focus();

export const removeOrderedList = (change) =>
  change.setBlocks("paragraph").unwrapBlock("ol_list").focus();

export const switchToUnorderedList = (change) =>
  change.wrapBlock("ul_list").unwrapBlock("ol_list").focus();

export const applyList = (change, type) =>
  change.setBlocks("list_item").wrapBlock(type).focus();

const removeList = (change) => {
  const value = change.value;
  const unwrap = isList(value)
    ? isUnorderedList(value)
      ? "ul_list"
      : "ol_list"
    : false;
  if (unwrap) change.setBlocks("paragraph").unwrapBlock(unwrap).focus();
};

export const onlyRemove = (change, type) => change.unwrapBlock(type).focus();
export const onlyRemoveUnorderedList = (change) =>
  onlyRemove(change, "ul_list");
export const onlyRemoveOrderedList = (change) => onlyRemove(change, "ol_list");

export const applyUnorderedList = (change) => applyList(change, "ul_list");
export const applyOrderedList = (change) => applyList(change, "ol_list");

const deepRemoveList = (change) => {
  const { value } = change;
  const { document } = value;
  const node = getNodeOfType(value, "list_item");
  const depth = document.getDepth(node.key);

  Array(depth)
    .fill(".")
    .forEach(() => {
      const parent = document.getParent(node.key);
      if (parent.type === "ul_list") removeUnorderedList(change);
      else removeOrderedList(change);
    });
  return change;
};

export const unorderedListStrategy = (change) => {
  const { value } = change;
  if (!isList(value)) return applyList(change, "ul_list");

  if (isUnorderedList(value)) return deepRemoveList(change);
  if (isOrderedList(value)) return switchToUnorderedList(change);
};

export const orderedListStrategy = (change) => {
  const { value } = change;
  // If it is not a list yet, transform it!
  if (!isList(value)) return applyList(change, "ol_list");

  // If it is already a list, handle it!
  if (isOrderedList(value)) return deepRemoveList(change);
  else if (isUnorderedList(value)) return switchToOrderedList(change);
};

export const increaseListDepthStrategy = (change) => {
  const { value } = change;
  // If it is not a list, kill the action immediately.
  if (!isList(value)) return change;

  if (isUnorderedList(value)) return applyUnorderedList(change);
  if (isOrderedList(value)) return applyOrderedList(change);
  return change;
};

export const decreaseListDepthStrategy = (change) => {
  const { value } = change;
  // If it is not a list, kill the action immediately.
  if (!isList(value)) return change;

  const node = getNodeOfType(value, "list_item");
  const depth = value.document.getDepth(node.key);
  if (isUnorderedList(value) && depth > 2)
    return onlyRemoveUnorderedList(change);
  if (isOrderedList(value) && depth > 2) return onlyRemoveOrderedList(change);
  return change;
};

// ===== LINK FUNCTIONALITY ============================== //
export const linkStrategy = (editor) => {
  const href = window.prompt("Enter a URL:");
  if (!href) return;
  editor.wrapInline({
    type: "link",
    data: { href },
  });
};

export const imgStrategy = (editor) => {
  const { getMedia } = require("launchpad");
  getMedia((src) => {
    editor.insertInline({
      type: "image",
      data: { src },
    });
  });
};

// ===== EDITING ========================================= //

let editState = {
  activeEditor: null,
  activeCommands: [],
  activeBlocks: [],
};

let activeEditor = null;
let editorComponent = null;

export const setActiveEditor = (e, component) => {
  if (component && component.props.name) {
    activeEditor = e;
    editorComponent = component;
    if (!e) {
      setEditorState({ activeCommands: [], activeBlocks: [] });
    }
  }
};

export const getActiveEditor = () => {
  return activeEditor;
};

export const getActiveEditorComponent = () => {
  return editorComponent;
};

let textEditors = [];
export const registerTextTools = (e) => textEditors.push(e);
export const unregisterTextTools = (e) =>
  (textEditors = textEditors.filter((x) => x !== e));

const setEditorState = (state) => {
  Object.assign(editState, state);
  textEditors.forEach((e) => {
    e.setState({ editState });
  });
};

const commands = {
  b: { mark: "bold" },
  i: { mark: "italic" },
  u: { mark: "underline" },
  h1: { block: "heading_1" },
  h2: { block: "heading_2" },
  h3: { block: "heading_3" },
  h4: { block: "heading_4" },
  blockquote: { block: "quote" },
  l: { fn: unorderedListStrategy },
  n: { fn: orderedListStrategy },
  link: { fn: linkStrategy },
  image: { fn: imgStrategy },
};

const isBlock = (value, type) => {
  if (typeof type == "string") {
    type = [type];
  }
  return value.blocks.some((block) => type.includes(block.type));
};

export const checkActiveBlocks = (e) => {
  if (e && e.value) {
    let activeBlocks = [];
    let activeCommands = [];
    e.value.blocks.forEach((v) => {
      activeBlocks.push(v.type);
    });
    e.value.activeMarks.forEach((m) => {
      activeBlocks.push(m.type);
    });
    if (isUnorderedList(e.value)) {
      activeBlocks.push("ul_list");
      activeCommands.push("l");
    }
    if (isOrderedList(e.value)) {
      activeBlocks.push("ol_list");
      activeCommands.push("n");
    }
    Object.keys(commands).forEach((c) => {
      if (
        activeBlocks.includes(commands[c].mark) ||
        activeBlocks.includes(commands[c].block)
      ) {
        activeCommands.push(c);
      }
    });
    setEditorState({ activeBlocks, activeCommands });
  }
};

export const editorClick = (e, editor) => {
  checkActiveBlocks(editor);
};

export const doEditorCommand = (command) => {
  const e = getActiveEditor();
  if (e && commands[command]) {
    const c = commands[command];
    //console.log(`Issuing editor command "${command}":`,c)
    //event.preventDefault()
    if (c.mark) {
      e.toggleMark(c.mark).focus();
    }
    if (c.block) {
      if (isBlock(e.value, c.block)) {
        e.setBlocks("paragraph").focus();
        //e.unwrapBlock(c.block)
      } else {
        e.setBlocks(c.block).focus();
      }
    }
    if (c.fn) {
      c.fn(e);
    }
    checkActiveBlocks(e);
  }
};

export const hotKeys = (event, editor, next) => {
  const value = editor.value;
  if (event.key === "Tab") {
    event.preventDefault();
    if (event.shiftKey) {
      decreaseListDepthStrategy(editor);
    } else {
      increaseListDepthStrategy(editor);
    }
    return;
  }
  if (event.key === "Enter") {
    if (isList(value)) {
      event.preventDefault();
      const texts = value.texts;
      if (texts.size === 1 && texts.get(0).text === "") {
        removeList(editor);
        return;
      }
    }
    if (isBlock(value, ["heading_1", "heading_2"])) {
      editor.splitBlock().setBlocks("paragraph");
      return;
    }
  }
  checkActiveBlocks(editor);
  if (!event.metaKey && !event.ctrlKey) return next();
  const k = event.key;
  if (commands[k]) {
    doEditorCommand(k);
  } else {
    next();
  }
};
