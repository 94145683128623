import React, { Component } from "react";
import PropTypes from "prop-types";
import "./style.scss";

class SearchInput extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    searchCallback: PropTypes.func,
    customClass: PropTypes.string,
  };

  enterPressed = (ev) => {
    const code = ev.keyCode || ev.which;
    if (code === 13) {
      this.props.searchCallback();
    }
  };

  render() {
    const { placeholder, onChange, value, customClass } = this.props;

    return (
      <div className={`search-bar ${customClass || ""} flex-row`}>
        <span className="search-icon icon-search flex-center" />
        <input
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          className={`input-text`}
          onKeyPress={this.enterPressed.bind(this)}
        />
      </div>
    );
  }
}

export default SearchInput;
