.alice-carousel {
    position: relative;
    .alice-carousel__prev-btn-wrapper, .alice-carousel__next-btn-wrapper {
        height: 48px;
        width: 48px;
        .alice-carousel__prev-btn-item, .alice-carousel__next-btn-item {
            height: 48px;
            width: 48px;
        }
    }
    .alice-carousel__prev-btn, .alice-carousel__next-btn {
        background-color: #FFDA65;
        border-radius: 0 24px 24px 0;
        cursor: pointer;
        height: 48px;
        left: 0;
        padding: 0;
        position: absolute;
        top: 43%;
        width: 48px;
    }
    .alice-carousel__next-btn {
        right: 0;
        left: auto;
        transform: rotate(180deg);
    }
    .alice-carousel__prev-btn-item, .alice-carousel__next-btn-item {
        margin: 0;
        color: transparent;
        background: url("assets/slider-arrow.svg") no-repeat 10px 14px;
    }
    .alice-carousel__stage-item img {
        height: 370px;
    }
    @media screen and (max-device-width: 768px) {
        .alice-carousel__stage-item img {
            height: 230px;
        }
        .alice-carousel__prev-btn, .alice-carousel__next-btn {
            top: 90px;
        }
    }

}