// Deps
import React, { useState } from "react";
import "./SignUp.scss";
// Components
import { Button, FormInput } from "../widgets";
import { Link } from "../widgets/Link";
import { /*FacebookIcon,*/ CircleChevronDownIcon } from "../icons";
import RadioCheckboxField, { CheckInput } from "../widgets/RadioCheckboxField";
// Utils
import JackyAndTim from "../../images/Jacky&Tim.jpg";
import UserSession from "../../../_old/src/components/user-session";
import { post } from "../../utils/client";
import { validateSignUpForm } from "../../utils/validation";
import {
  CLIENT_STATUSES,
  SINGLES_ROUTES,
  USER_ROLES,
  SINGLE_ROUTES_MORE_INFO
} from "../../data/constants";
import PriscillaAndAnthony from "../../images/Priscilla&Anthony.jpg";
import { getGlobal } from "../../../launchpad";
// Icons
import { QuestionIcon } from "../icons";
import { Helmet } from "react-helmet";

const RADIO_OPTIONS = [
  {
    id: "client-radio",
    optionName: "Client",
    value: "client",
    moreInfo: SINGLE_ROUTES_MORE_INFO.CLIENT,
  },
  {
    id: "candidate-radio",
    optionName: "Candidate",
    value: "candidate",
    moreInfo: SINGLE_ROUTES_MORE_INFO.CANDIDATE,
  },
];
const REFERRAL_VALUES = {
  carla: { id: 1, label: "Carla", value: "Carla" },
  nicole: { id: 2, label: "Nicole M", value: "nicolemusap@datespot.love" },
  elise: { id: 15, label: "Elise", value: "yourdatingcopilot@gmail.com" },
  other_person: { id: 3, label: "Other person", value: "" },
  matchmaker: { id: 7, label: "Professional Matchmaker", value: "", company: "", email: "" },
  other: { id: 9, label: "Other", value: "", email: "" },
  google: { id: 4, label: "Google Search", value: "Google Search" },
  yelp: { id: 5, label: "Yelp", value: "Yelp" },
  facebook: { id: 6, label: "Facebook Ad", value: "Facebook Ad" },
  article: { id: 8, label: "Article/PR", value: "" },
  instagram: { id: 11, label: "Instagram", value: "Instagram" },
  twitter: { id: 12, label: "Twitter", value: "Twitter" },
  linkedin: { id: 13, label: "LinkedIn", value: "LinkedIn" },
  clubhouse: { id: 14, label: "Clubhouse", value: "Clubhouse" },
}

const getWillingToPayOption = (val) =>
    val
        ? { value: false, label: "Get matched free", id: 1 }
        : { value: true, label: "Contact a matchmaker", id: 2 };

//TODO: Change this.
const ABOUT_US_OPTIONS = [
  /*{
  id: "datespot-team-section",
    sectionName: 'DateSpot Team',
    options: [
      {optionName: "Carla", value: REFERRAL_VALUES[0]},
      {optionName: "Other", input: true, placeholder: "Name", value: REFERRAL_VALUES[3]},
    ],
  },
  {
  id: "other-person-section",
    sectionName: 'Other Person',
    options: [
      {optionName: "Matchmaker", input: true, placeholder: "Name", value: "matchmaker"},
      {optionName: "Dating Advisor", input: true, placeholder: "Name", value: "dating-advisor"},
      {optionName: "Photographer", input: true, placeholder: "Name", value: "photographer"},
      {optionName: "Friend/Family", input: true, placeholder: "Name", value: "friend-family"},
    ],
  },*/
  {
    id: "individual-section",
    sectionName: "Individual",
    options: [
      { optionName: "Carla", value: REFERRAL_VALUES.carla },
      { optionName: "Nicole M.", value: REFERRAL_VALUES.nicole },
      { optionName: "Elise", value: REFERRAL_VALUES.elise },

      {
        optionName: "DateSpot Recruiter",
        input: true,
        placeholder: "First Name",
        value: "",
      },
      {
        optionName: "Matchmaker",
        input: true,
        input2: true,
        input3: true,
        placeholder: "First Name",
        placeholder2: "Company",
        placeholder3: "Email",
        value: REFERRAL_VALUES.matchmaker.value,
        value2: REFERRAL_VALUES.matchmaker.company,
        value3: REFERRAL_VALUES.matchmaker.email,
      },
      {
        optionName: "Other",
        input: true,
        input4: true,
        placeholder: "First Name",
        value: REFERRAL_VALUES.other.value,
        placeholder4: "Email",
        value3: REFERRAL_VALUES.other.email,
      },
    ],
  },
  {
    id: "website-section",
    sectionName: "Website",
    options: [
      { optionName: "Google", value: REFERRAL_VALUES.google },
      { optionName: "Facebook", value: REFERRAL_VALUES.facebook },
      { optionName: "Instagram", value: REFERRAL_VALUES.instagram },
      { optionName: "Twitter", value: REFERRAL_VALUES.twitter },
      { optionName: "Linkedin", value: REFERRAL_VALUES.linkedin },
      { optionName: "Clubhouse", value: REFERRAL_VALUES.clubhouse },
      { optionName: "Yelp", value: REFERRAL_VALUES.yelp },
      {
        optionName: "Article/PR",
        input: true,
        placeholder: "Specifics",
        value: REFERRAL_VALUES.article,
      },
      {
        optionName: "Other",
        input: true,
        placeholder: "Specifics",
        value: REFERRAL_VALUES.other,
      },
    ],
  },
  /*{
  id: "other-section",
    sectionName: 'Other',
    options: [
      {optionName: "Event", input: true, placeholder: "Specifics", value: "event"},
      {optionName: "Podcast", input: true, placeholder: "Specifics", value: "podcast"},
      {optionName: "Other", input: true, placeholder: "Specifics", value: "other-other"},
    ],
  },*/
];

const SIGN_UP_FIELD_NAMES = {
  EMAIL: "email",
  PHONE: "phone",
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  COMPANY_NAME: "companyName",
  WEBSITE_URL: "websiteUrl",
  PASSWORD: "password",
  CONFIRM_PASSWORD: "confirmPassword",
  ROUTES: "routes",
  TERMS_AND_CONDITIONS: "agreeWithTermsAndPolicies",
  REFERRAL_SOURCES: "referralSource",
};

const MainInputFields = ({
                           handleFieldChange,
                           errors,
                           isMatchmakerSignUp,
                           formValues,
                         }) => (
    <>
      <div className="sign-up__input-pair">
        <FormInput
            onChange={handleFieldChange(SIGN_UP_FIELD_NAMES.EMAIL)}
            type="email"
            placeholder="Email"
            style={{ height: "unset" }}
            error={errors.email}
            value={formValues.email}
        />
        {!isMatchmakerSignUp ? (
            <FormInput
                onChange={handleFieldChange(SIGN_UP_FIELD_NAMES.PHONE)}
                type="tel"
                placeholder="Phone Number"
                style={{ height: "unset" }}
                error={errors.phone}
                value={formValues.phone}
                helpText={"DateSpot may communicate via text for potential matches and match introductions"}
            />
        ) : (
            <FormInput
                onChange={handleFieldChange(SIGN_UP_FIELD_NAMES.PHONE)}
                type="tel"
                placeholder="Phone Number"
                style={{ height: "unset" }}
                error={errors.phone}
                value={formValues.phone}
            />
        )}
      </div>
      <div className="sign-up__input-pair">
        <FormInput
            onChange={handleFieldChange(SIGN_UP_FIELD_NAMES.FIRST_NAME)}
            type="text"
            placeholder="First Name"
            style={{ height: "unset" }}
            error={errors.firstName}
            value={formValues.firstName}
        />
        <FormInput
            onChange={handleFieldChange(SIGN_UP_FIELD_NAMES.LAST_NAME)}
            type="text"
            placeholder="Last Name"
            style={{ height: "unset" }}
            error={errors.lastName}
            value={formValues.lastName}
        />
      </div>
      {isMatchmakerSignUp && (
          <div className="sign-up__input-pair">
            <FormInput
                onChange={handleFieldChange(SIGN_UP_FIELD_NAMES.COMPANY_NAME)}
                type="text"
                placeholder="Company Name"
                style={{ height: "unset" }}
                error={errors.companyName}
                value={formValues.companyName}
            />
            <FormInput
                onChange={handleFieldChange(SIGN_UP_FIELD_NAMES.WEBSITE_URL)}
                type="url"
                placeholder="Website URL"
                style={{ height: "unset" }}
                error={errors.websiteUrl}
                value={formValues.websiteUrl}
            />
          </div>
      )}
      <div className="sign-up__input-pair">
        <FormInput
            onChange={handleFieldChange(SIGN_UP_FIELD_NAMES.PASSWORD)}
            type="password"
            placeholder="Password"
            style={{ height: "unset" }}
            error={errors.password}
            value={formValues.password}
        />
        <FormInput
            onChange={handleFieldChange(SIGN_UP_FIELD_NAMES.CONFIRM_PASSWORD)}
            type="password"
            placeholder="Confirm Password"
            style={{ height: "unset" }}
            error={errors.confirmPassword}
            value={formValues.confirmPassword}
        />
      </div>
    </>
);

const RouteField = ({ handleRouteFieldCheck, error, value }) => (
    <div className="sign-up__selects-container">
      <h2 className="sign-up__subtitle">Member Type:</h2>
      <RadioCheckboxField
          options={RADIO_OPTIONS}
          onCheck={handleRouteFieldCheck}
          error={error}
          value={value}
          required={true}
      />
      {error && <p className="error-message">{error}</p>}
    </div>
);

const AboutUsField = ({
                        referralSource,
                        setAboutUsExpanded,
                        aboutUsExpanded,
                        handleAboutUsCheck,
                        handleAboutUsInputChange,
                        error,
                      }) => (
    <div className="sign-up__expandable-container">
      <h2 className="sign-up__about-us-title">How did you hear about us?</h2>
      <div className="sign-up__about-us-content">
        {ABOUT_US_OPTIONS.map((option) => (
            <React.Fragment key={option.id}>
              <h2 className="sign-up__subtitle lighter">{option.sectionName}</h2>
              <RadioCheckboxField
                  referralSource={referralSource}
                  options={option.options}
                  type={"checkbox"}
                  onCheck={handleAboutUsCheck}
                  onInputChange={handleAboutUsInputChange}
                  error={error}
              />
            </React.Fragment>
        ))}
      </div>
    </div>
);

const AgreementField = ({
                          handleFieldChange,
                          isMatchmakerSignUp,
                          error,
                          value,
                        }) => (
    <div className="sign-up__agreement-w-error">
      <div className="sign-up__agreement-container">
        <br />
        <br />
        <CheckInput
            className="sign-up__agree-checkbox"
            id="agree-checkbox"
            type="checkbox"
            onCheck={(e) => {
              const { checked } = e.target;
              handleFieldChange(SIGN_UP_FIELD_NAMES.TERMS_AND_CONDITIONS)(checked);
            }}
            error={error}
            value={value}
        />
        <label htmlFor="agree-checkbox" className="sign-up__agreement-label">
          {isMatchmakerSignUp ? "You agree to the" : "I agree with the"}&nbsp;
          <a
              href={`${process.env.MARKETING_SITE_URL}/for-singles/terms-of-service`}
              target="_blank"
          >
            Terms of Service
          </a>
          &nbsp;and&nbsp;
          {isMatchmakerSignUp ? (
              "if you're applying as a matchmaker, the "
          ) : (
              <a
                  href={`${process.env.MARKETING_SITE_URL}/privacy-policy`}
                  target="_blank"
              >
                Privacy Policy
              </a>
          )}
          {isMatchmakerSignUp ? (
              <a
                  href={`${process.env.MARKETING_SITE_URL}/matchmaker-agreement`}
                  target="_blank"
              >
                Matchmaker Agreement
              </a>
          ) : (
              " and I am over the age of 18"
          )}
          .
        </label>
      </div>
      {error && <p className="error-message">{error}</p>}
    </div>
);

const RightSide = ({ isMatchmakerSignUp }) =>
    isMatchmakerSignUp ? (
        <div className="sign-up__right-side-matchmaker">
          <figure className="sign-up__image-container matchmaker">
            <img
                src={PriscillaAndAnthony}
                alt="Layla and Steve"
                className="sign-up__hero-image"
            />
            <span className="sign-up__image-legend">Priscilla & Anthony</span>
          </figure>
          <div className="sign-up__background-color" />
        </div>
    ) : (
        <div className="sign-up__right-side-singles">
          <figure className="sign-up__image-container">
            <img
                src={JackyAndTim}
                alt="Layla and Steve"
                className="sign-up__hero-image"
            />
            <span className="sign-up__image-legend">Jacky and Tim</span>
          </figure>
        </div>
    );

const SignUp = ({ userRole, history, location }) => {
  const formValuesInitialState = {
    [SIGN_UP_FIELD_NAMES.EMAIL]: "",
    [SIGN_UP_FIELD_NAMES.PHONE]: "",
    [SIGN_UP_FIELD_NAMES.FIRST_NAME]: "",
    [SIGN_UP_FIELD_NAMES.LAST_NAME]: "",
    [SIGN_UP_FIELD_NAMES.COMPANY_NAME]: "",
    [SIGN_UP_FIELD_NAMES.WEBSITE_URL]: "",
    [SIGN_UP_FIELD_NAMES.PASSWORD]: "",
    [SIGN_UP_FIELD_NAMES.CONFIRM_PASSWORD]: "",
    [SIGN_UP_FIELD_NAMES.ROUTES]: [],
    [SIGN_UP_FIELD_NAMES.TERMS_AND_CONDITIONS]: false,
    [SIGN_UP_FIELD_NAMES.REFERRAL_SOURCES]: [],
  };

  const [formValues, setFormValues] = useState(formValuesInitialState);
  const [aboutUsExpanded, setAboutUsExpanded] = useState(null);
  const [errors, setErrors] = useState({});
  const [disableSignUpButton, setDisableSignUpButton] = useState(false);
  const isMatchmakerSignUp = userRole === USER_ROLES.MATCHMAKER;

  const handleAboutUsCheck = (value, checked) => {
    if (checked) {
      setFormValues({
        ...formValues,
        referralSource: [...formValues.referralSource, value],
      });
    } else {
      setFormValues({
        ...formValues,
        referralSource: formValues.referralSource.filter(
            (item) => item.id !== value.id
        ),
      });
    }
  };

  const handleRouteFieldCheck = (value, checked) => {
    if (checked) {
      setFormValues({
        ...formValues,
        routes: [value],
      });
    }
  };

  const handleAboutUsInputChange = (inputValue, optionValue, fieldName) => {
    if (optionValue === 'Other') {
      setDisableSignUpButton(false);
    }
    const tempValues = formValues.referralSource.filter(
        (item) => !item.hasOwnProperty(fieldName)
    );
    setFormValues({
      ...formValues,
      referralSource: [...tempValues, { label: optionValue, [fieldName]: inputValue }],
    });
  };

  const signUpMatchmaker = async (data) => {
    window.gtag("event", "conversion", {
      send_to: "AW-720967997/EvNwCN2H56YBEL2y5NcC",
    });
    window.gtag("config", "AW-753431149");

    const onSuccess = (response) => {
      setFormValues(formValuesInitialState);
      setDisableSignUpButton(false);
      window.location.href = `${process.env.MARKETING_SITE_URL}/partner-signup-confirmation`;
    };

    const onError = (err) => {
      setErrors({
        ...errors,
        signUpError: err.message,
      });
      console.error(err);
    };

    await post({
      url: "/sign-up-matchmaker",
      data,
      onSuccess,
      onError,
    });
  };

  const signUpSingle = async (data) => {
    window.gtag("event", "conversion", {
      send_to: "AW-720967997/EvNwCN2H56YBEL2y5NcC",
    });
    window.gtag("config", "AW-753431149");

    const onSuccess = (response) => {
      const { user, token } = response.data;
      UserSession.setToken(token, user);
      setFormValues(formValuesInitialState);
      setDisableSignUpButton(false);
      // theBasics is the first section in the dashboard
      setTimeout(() => history.push("/dashboard?section=theBasics"), 500);
    };

    const onError = (error) => {
      setErrors({ ...errors, signUpError: error.message });
      setDisableSignUpButton(false);
    };

    await post({
      url: "/sign-up",
      data,
      onSuccess,
      onError,
    });
  };

  const getClientLead = () => {
    if (formValues[SIGN_UP_FIELD_NAMES.ROUTES].includes(SINGLES_ROUTES.CLIENT)) {
      return "Current Client";
    }
    return "Unknown";
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisableSignUpButton(true);
    const cleanEmail = formValues.email.replace(/\s/g, "");
    const errors = validateSignUpForm(
        {
          ...formValues,
          email: cleanEmail,
        },
        isMatchmakerSignUp
    );
    setErrors(errors);

    if (!Object.keys(errors).length) {
      setErrors({});
      if (isMatchmakerSignUp) {
        // When the user is logged in on the admin view (launchpad view) or an admin clicks on "+ Add Matchmaker" this is true.
        const isAdminMode =
            Boolean(getGlobal("is_admin") || getGlobal("user_access")) ||
            location.state?.isAdmin;
        const data = {
          email: cleanEmail,
          password: formValues[SIGN_UP_FIELD_NAMES.PASSWORD],
          firstName: formValues[SIGN_UP_FIELD_NAMES.FIRST_NAME],
          lastName: formValues[SIGN_UP_FIELD_NAMES.LAST_NAME],
          role: userRole,
          phone: formValues[SIGN_UP_FIELD_NAMES.PHONE],
          companyName: formValues[SIGN_UP_FIELD_NAMES.COMPANY_NAME],
          siteUrl: formValues[SIGN_UP_FIELD_NAMES.WEBSITE_URL],
          hasAgreedWithTermsAndConditions:
              formValues[SIGN_UP_FIELD_NAMES.TERMS_AND_CONDITIONS],
          approved: isAdminMode,
        };
        await signUpMatchmaker(data);
      } else {
        const data = {
          email: cleanEmail,
          password: formValues[SIGN_UP_FIELD_NAMES.PASSWORD],
          firstName: formValues[SIGN_UP_FIELD_NAMES.FIRST_NAME],
          lastName: formValues[SIGN_UP_FIELD_NAMES.LAST_NAME],
          role: userRole,
          phone: formValues[SIGN_UP_FIELD_NAMES.PHONE],
          willingToPay: getWillingToPayOption(
              formValues[SIGN_UP_FIELD_NAMES.ROUTES] === SINGLES_ROUTES.CLIENT
          ),
          referralSource: formValues[SIGN_UP_FIELD_NAMES.REFERRAL_SOURCES],
          clientStatus: CLIENT_STATUSES.CANDIDATE,
          clientLead: getClientLead(),
          possibleRoutes: formValues[SIGN_UP_FIELD_NAMES.ROUTES],
          acceptedTC: formValues[SIGN_UP_FIELD_NAMES.TERMS_AND_CONDITIONS],
          suspicion: { profile: false, email: false },
          verification: {
            verified: false,
            date_verified: null,
            verified_by: "",
          },
        };
        await signUpSingle(data);
      }
    } else setErrors(errors);
  };

  const handleFieldChange = (fieldName) => (value) => {
    setDisableSignUpButton(false);
    setFormValues({ ...formValues, [fieldName]: value });
  };

  return (
      <div
          className={`sign-up__container${isMatchmakerSignUp ? " matchmaker" : ""}`}
      >
        <Helmet>
          {/* <!-- Google tag (gtag.js) -->  */}
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-XGDWXDZMFT"></script>
          <script>{`window.dataLayer = window.dataLayer || []; 
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date()); 
          gtag('config', 'G-XGDWXDZMFT'); 
        `}</script>
        </Helmet>
        <div className="sign-up__left-side">
          <div className="sign-up__header">
            <h1 className="sign-up__title">
              {isMatchmakerSignUp ? "Welcome Partners" : "Find your partner"}
            </h1>
            <div className="sign-up__divider" />
          </div>
          <form className="sign-up__form" onSubmit={handleSubmit} noValidate>
            <div className="sign-up__fields-container">
              <MainInputFields
                  handleFieldChange={handleFieldChange}
                  errors={errors}
                  isMatchmakerSignUp={isMatchmakerSignUp}
                  formValues={formValues}
              />
            </div>
            {!isMatchmakerSignUp && (
                <RouteField
                    value={formValues[SIGN_UP_FIELD_NAMES.ROUTES][0]}
                    handleRouteFieldCheck={handleRouteFieldCheck}
                    error={errors.route}
                />
            )}
            <AboutUsField
                referralSource={formValues[SIGN_UP_FIELD_NAMES.REFERRAL_SOURCES]}
                setAboutUsExpanded={setAboutUsExpanded}
                aboutUsExpanded={aboutUsExpanded}
                handleAboutUsCheck={handleAboutUsCheck}
                handleAboutUsInputChange={handleAboutUsInputChange}
                error={errors.referralSource}
            />
            <AgreementField
                handleFieldChange={handleFieldChange}
                isMatchmakerSignUp={isMatchmakerSignUp}
                error={errors.agreeWithTermsAndPolicies}
                value={formValues.agreeWithTermsAndPolicies}
            />
            <div className="sign-up__buttons-container">
              <Button
                  disabled={disableSignUpButton}
                  type="submit"
                  error={!!errors.signUpError}
              >
                Sign Up
              </Button>
              {/*<span className='sign-up__subtitle or'>OR</span>
            <Button type='button' icon={<FacebookIcon/>} variant='facebook' onClick={facebookLogin}>Facebook
              Sign Up</Button>*/}
            </div>
            <span className="sign-up__subtitle">
            Already have an account?&nbsp;
              <Link to={"/log-in"} style={{ fontSize: 16 }}>
              Sign in here.
            </Link>
          </span>
            {/* if errors.signUpError === "Please" print Error */}
            {errors.signUpError && errors.signUpError === "There was an error on our side. Please contact support@datespot.com" ? (
                <span className="error-message">
              There was an error on our side.{" "}
                  Please contact {" "}<a href="mailto:support@datespot.com">support@datespot.com</a>
            </span>
            ) : errors.signUpError && (
                <span className="error-message">{errors.signUpError}</span>
            )}
          </form>
        </div>
        <RightSide isMatchmakerSignUp={isMatchmakerSignUp} />
      </div>
  );
};

export default SignUp;
