import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Button from "../../../components/button/index";
import DropDown from "../../../components/drop-down";
import RatingToolTip from "../../../components/rating-tooltip";
import CheckBox from "../../../components/check-box";
import InputText from "../../../components/input-text";
import {
  USER_INFO_TABLE_DATA,
  ATTRACTIVENESS_RATING,
  MONTHS,
  DAYS,
  generateAllYears,
} from "../../../constants/index";
import { statesIndex } from "data/states";
import Slider from "../../../containers/slider";
import TriangleComponent from "../../../components/triangle-component";
import ExifOrientationImg from "react-exif-orientation-img";
import ToolTip from "../../../components/tooltip/index";
import { isString } from "lodash";
import {
  REGIONS,
  RELATIONSHIP_STATUSES,
  MARRIAGE_STATUSES,
  CLIENT_LEAD_STATUS,
} from "../../../constants/onboarding-data-elements";
import { APPRECIATION_OPTIONS } from "src/data/other-section";
import {
  fromNow,
  shouldRenderLGBTLabel,
  shouldRenderPolyLabel,
  shouldRenderPausedLabel,
} from "src/utils/functions";
import LGBTQ from "src/images/lgbtq-plus.png";
import PausedImg from "src/images/Datespot_Paused.png";
import { Image, ImageCarousel } from "src/components/widgets";
import Select from "../../../../../src/components/Select";
import Api from "../../../actions/api";
import { RotateLoader } from "react-spinners";
import { insertAt } from "src/utils/functions";
import PolyOther from "src/images/poly-other.png";
import UserSession from "_old/src/components/user-session";
import { ACCOUNT_STATUS } from "src/constants/user-info";
import Modal from "_old/src/components/modal";
import { DeletePhotosModal } from "src/components/Modal/CustomModals/DeletePhotosModal";
import { toast } from "react-toastify";

const YEARS = generateAllYears();

class UserInfoModalContent extends React.Component {
  constructor(props) {
    super(props);
    let day = null;
    let month = null;
    let year = null;
    if (this.props.requestedUserData.verification.date_verified) {
      day = DAYS.find(
        (day) =>
          day.value ===
          this.props.requestedUserData.verification.date_verified.slice(3, 5)
      );
      month = MONTHS.find(
        (mo) =>
          mo.value ===
          this.props.requestedUserData.verification.date_verified
            .trim()
            .slice(0, 2)
      );
      year = YEARS.find(
        (y) =>
          y.value ===
          parseInt(
            this.props.requestedUserData.verification.date_verified.slice(
              6,
              10
            ),
            10
          )
      );
    }
    this.state = {
      showRating: false,
      ratingValue: this.props.requestedUserData.userRatingByMe || null,
      isAdminUser: this.props.isAdminUser || false,
      allowsPhotos: this.props.requestedUserData.allowsPhotos || false,
      suspicionProfile: this.props.requestedUserData.suspicion.profile || false,
      suspicionEmail: this.props.requestedUserData.suspicion.email || false,
      verifiedProfile:
        this.props.requestedUserData.verification.verified || false,
      verifiedDate:
        this.props.requestedUserData.verification.date_verified || false,
      verifiedBy:
        this.props.requestedUserData.verification.verified_by || false,
      openPhotoMenu: false,
      infoRequestSent: false,
      contactedUserNumber: null,
      day: day || null,
      month: month || null,
      year: year || null,
      loadingUserPhotos: false,
      userPhotoTimeUpdate: new Date().getTime(),
      accountStatus: this.props.requestedUserData.accountStatus,
      deletePhotoModal: false,
    };
  }

  applyRating = (val) => {
    this.props.matchMakerActions.rateSelectedUser(
      this.props.requestedUserData._id,
      val
    );
    this.setState({ showRating: false, ratingValue: val });
  };

  deletePhotos = async (photos) => {
    try {
      this.props.adminActions.deletePhotos(
        this.props.requestedUserData._id,
        photos
      );
      this.setState({ deletePhotoModal: false });
      toast.success(photos.length + " Photo(s) Deleted, reload to see changes");
    } catch (e) {
      toast.error(e);
    }
  };

  render() {
    const { requestedUserData, closeUserInfoModal, matchMakerActions } =
      this.props;
    const { isAdminUser, deletePhotoModal } = this.state;
    const renderHeader = this._renderHeader(
      requestedUserData,
      matchMakerActions,
      closeUserInfoModal
    );
    const renderProfilePictures =
      this._renderProfilePictures(requestedUserData);
    const renderTheBasics = this._renderTheBasics(requestedUserData);
    const renderBackGround = this._renderBackground(requestedUserData);
    const renderLifeStyle = this._renderLifeStyle(requestedUserData);
    const renderFamily = this._renderFamily(requestedUserData);
    const renderPartnerCriteria =
      this._renderPartnerCriteria(requestedUserData);
    const renderOther = this._renderOther(requestedUserData.other);
    const renderConnections = this._renderConnections(requestedUserData);
    const renderContactInfo = this._renderContactInfo(requestedUserData);
    return (
      <>
        <DeletePhotosModal
          onClose={() => this.setState({ deletePhotoModal: false })}
          requestedUserData={requestedUserData}
          showModal={deletePhotoModal}
          onDelete={this.deletePhotos}
        />
        <div className="flex-colum">
          {renderHeader}
          <div
            className="flex-row"
            style={{ alignItems: "flex-start", marginTop: "40px" }}
          >
            {renderProfilePictures}
            <div className="flex-column right-section">
              {isAdminUser && renderContactInfo}
              {renderTheBasics}
            </div>
          </div>
          {renderBackGround}
          {renderLifeStyle}
          {renderFamily}
          {renderPartnerCriteria}
          {renderOther}
          {renderConnections}
        </div>
      </>
    );
  }

  _renderHeader = (
    requestedUserData,
    matchMakerActions,
    closeUserInfoModal
  ) => {
    const {
      isFavoriteUser,
      isUserContacted,
      isUserLockedByMe,
      loggedMMplan,
      userNumber,
    } = this.props;
    const mmHasConnectionsAvailable =
      loggedMMplan && loggedMMplan.connections > 0;
    const showContactButton = !(
      isUserContacted ||
      isUserLockedByMe ||
      !mmHasConnectionsAvailable
    );
    const showFavoritesButton = !isFavoriteUser;
    const { isAdminUser, showAdminMenu, infoRequestSent, contactedUserNumber } =
      this.state;
    const renderAdminMenu = this._renderAdminMenu(
      requestedUserData,
      closeUserInfoModal
    );
    return (
      <div className="modal__header flex-row">
        <div className="modal__header__name flex-row">
          <span>{requestedUserData.firstName}</span>
          {isAdminUser || requestedUserData.isConnected ? (
            <span>
              {requestedUserData.lastName && requestedUserData.lastName}
            </span>
          ) : (
            <span>
              {requestedUserData.lastName &&
                requestedUserData.lastName.charAt(0)}
            </span>
          )}
          <span>{requestedUserData.user_id}</span>
          {shouldRenderLGBTLabel(requestedUserData) && (
            <Image
              src={LGBTQ}
              alt="LGBTQ+ label"
              className="modal__header__lgbtq-label"
            />
          )}
          {shouldRenderPolyLabel(requestedUserData) && (
            <Image
              src={PolyOther}
              alt="PolyOther label"
              className="modal__header__polyOther-label"
            />
          )}
          {shouldRenderPausedLabel(requestedUserData) && (
            <Image
              src={PausedImg}
              alt="Paused label"
              className="member-header__left-container__member-label__paused-label"
            />
          )}
        </div>
        {isAdminUser ? (
          ""
        ) : (
          <div
            className="action-buttons flex-row float-right"
            style={{ position: "relative" }}
          >
            {infoRequestSent && (
              <ToolTip
                style={{ width: "400px" }}
                text={`We have just emailed Member ${contactedUserNumber} to find out their current status and interest. If we don’t hear back from them, we will follow up. Either way, we’ll let you know within one week whether they’d like to participate with you now!`}
                closeCallback={() =>
                  this.setState({
                    infoRequestSent: false,
                    contactedUserNumber: null,
                  })
                }
              />
            )}
            {showFavoritesButton && (
              <Button
                value="add to potentials"
                customClass="float-right"
                click={() =>
                  matchMakerActions.addUserToFavorites(requestedUserData._id)
                }
              />
            )}
            {showContactButton && (
              <Button
                value="contact member"
                customClass="yellow"
                click={() => {
                  this.setState({
                    infoRequestSent: true,
                    contactedUserNumber: userNumber,
                  });
                  matchMakerActions.requestAccessForUser(requestedUserData);
                }}
              />
            )}
            {!showContactButton &&
              isUserContacted &&
              !requestedUserData.isConnected && (
                <Button
                  value="Cancel request"
                  customClass="yellow"
                  click={() => {
                    matchMakerActions.cancelRequestAccessForUser(
                      requestedUserData._id
                    );
                  }}
                />
              )}
            {!mmHasConnectionsAvailable && !showContactButton && (
              <p style={{ marginLeft: "15px" }}>
                {"(No available Connections)"}
              </p>
            )}
          </div>
        )}
        {isAdminUser && (
          <div
            className="float-right"
            style={{
              marginRight: "20px",
              cursor: "pointer",
              postition: "relative",
            }}
          >
            <span
              className="icon-more"
              onClick={() => this.setState({ showAdminMenu: !showAdminMenu })}
            />
            {showAdminMenu && renderAdminMenu}
          </div>
        )}
        <span
          className={`icon-close-button grey`}
          onClick={() => closeUserInfoModal()}
        />
      </div>
    );
  };

  _renderAdminMenu = (requestedUserData, closeUserInfoModal) => {
    return (
      <div className="admin-menu flex-column">
        <TriangleComponent top={-9} right={23} />
        <span
          className="flex-center"
          onClick={() => {
            this.exportUserData(requestedUserData._id);
          }}
        >
          {" "}
          Export Member Data
        </span>
        <span
          className="flex-center"
          style={{ color: "rgba(84,88,90,0.3)" }}
          onClick={() => {
            closeUserInfoModal();
          }}
        >
          Contact Member
        </span>
        <span
          className="flex-center"
          onClick={() => {}}
          style={{ color: "rgba(84,88,90,0.3)" }}
        >
          Suggest to a Matchmaker
        </span>
        <span
          className="flex-center"
          onClick={() => {}}
          style={{ color: "rgba(84,88,90,0.3)" }}
        >
          Deactivate Account
        </span>
        <span
          className="flex-center"
          onClick={() => {
            this.props.adminActions.inactivateUser(requestedUserData._id);
            closeUserInfoModal();
          }}
        >
          {" "}
          Delete Member
        </span>
      </div>
    );
  };

  //Export user data and pictures to .docx file using docx library
  exportUserData = async (id) => {
    fetch(`/api/upload-doc/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${UserSession.getToken().token}`,
      },
    })
      .then((response) => response.json())
      .then((resp) =>
        window.open(`https://docs.google.com/document/d/${resp.id}`, "_blank")
      );
  };

  _renderProfilePictures = (requestedUserData) => {
    const { isAdminUser, allowsPhotos, openPhotoMenu, activeSlide } =
      this.state;
    const checkBoxLabel = "Open to public display as an example member";
    let userImages = requestedUserData.images
      .filter((x) => x)
      .map((pic) =>
        pic.url
          ? {
              id: pic.position,
              src: decodeURI(pic.url) + "?t=" + this.state.userPhotoTimeUpdate,
            }
          : null
      );
    let photoToChangeId = activeSlide;
    if (activeSlide === undefined) {
      photoToChangeId = 0;
    }
    return (
      <div className="left-section">
        <div className="flex-center left-section__main-image ">
          {isAdminUser && userImages.length > 0 && (
            <div className="photo-actions">
              <span
                className="icon-more"
                onClick={() => this.setState({ openPhotoMenu: !openPhotoMenu })}
              />
              {!this.state.loadingUserPhotos && openPhotoMenu && (
                <div className="flex-column admin-menu photo-menu">
                  <TriangleComponent top={-9} right={28} />
                  <span
                    className="flex-center"
                    onClick={() => {
                      this.props.adminActions.setDeletePhotos(
                        "set",
                        requestedUserData._id,
                        photoToChangeId
                      );
                      this.setState({ openPhotoMenu: false });
                    }}
                  >
                    Set as profile
                  </span>
                  <span
                    className="flex-center"
                    onClick={() => {
                      this.rotateImage(
                        requestedUserData._id,
                        photoToChangeId,
                        "rotate"
                      );
                      this.setState({ openPhotoMenu: false });
                    }}
                  >
                    Rotate 90°
                  </span>
                  <span
                    className="flex-center"
                    onClick={() => {
                      this.setState({ deletePhotoModal: true });
                    }}
                  >
                    Delete
                  </span>
                </div>
              )}
            </div>
          )}

          <Slider
            photos={userImages}
            returnActiveSlide={(s) => this.setState({ activeSlide: s })}
            padding={{ paddingLeft: 0, paddingRight: 0 }}
            responsive={{ 0: { items: 1 } }}
          />
        </div>
        {requestedUserData.images && requestedUserData.images.length > 0 && (
          <div className="flex-row left-section__images-slider">
            {requestedUserData.images.slice(0, 4).map((pic, p) => {
              return (
                <div key={p} className="pics">
                  {this.state.loadingUserPhotos && (
                    //center the loader
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <RotateLoader
                        sizeUnit={"px"}
                        size={20}
                        color={"#f2f2f2"}
                        loading={this.state.loadingUserPhotos}
                      />
                    </div>
                  )}
                  <ExifOrientationImg
                    src={
                      pic
                        ? decodeURI(
                            pic.url + "?t=" + this.state.userPhotoTimeUpdate
                          )
                        : null
                    }
                  />
                </div>
              );
            })}
          </div>
        )}

        {isAdminUser && (
          <div style={{ marginTop: "20px" }}>
            <CheckBox
              labelName={checkBoxLabel}
              checked={allowsPhotos}
              clicked={() => {
                this.props.adminActions.saveUserFieldDataFromAdmin(
                  requestedUserData._id,
                  "allowsPhotos",
                  !allowsPhotos
                );
                this.setState({ allowsPhotos: !allowsPhotos });
              }}
            />
          </div>
        )}
      </div>
    );
  };

  rotateImage = async (userId, photoNr, actionType) => {
    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("photoNr", photoNr);
    formData.append("actionType", actionType);

    this.setState({ loadingUserPhotos: true });

    Api.postForm({
      url: "/api/rotatePhoto",
      data: formData,
    }).then((res) => {
      if (res.data.images) {
        setTimeout(() => {
          this.setState({
            loadingUserPhotos: false,
            userPhotoTimeUpdate: new Date().getTime(),
          });
        }, 3000);
      }
    });
  };

  _renderContactInfo = (requestedUserData) => {
    return (
      <div className="the-basics-section">
        <div className="section-header">Contact Info</div>
        <div className="section-grid">
          <div className="flex-column half-width">
            <span className="the-basics-section__sub-header">Phone Number</span>
            <span className="the-basics-section__value">
              {requestedUserData.phone}
            </span>
          </div>
          <div className="flex-column half-width">
            <span className="the-basics-section__sub-header">
              Email Address
            </span>
            <span className="the-basics-section__value">
              {requestedUserData.email}
            </span>
          </div>
        </div>
      </div>
    );
  };

  _renderTheBasics = (requestedUserData) => {
    const renderUserRating = this._renderUserRating(requestedUserData);
    const showContactInfo = this._showContactInfo(requestedUserData);
    const { isAdminUser } = this.state;
    return (
      <div className="the-basics-section">
        <div className="section-header">Basics</div>

        <div className="section-grid">
          {!isAdminUser && showContactInfo}
          {USER_INFO_TABLE_DATA.theBasics.map((el, k) => {
            const value = this._returnValueTypes(
              el.type,
              requestedUserData[el.key]
            );
            const renderElements = this._renderElements(
              el.key,
              value,
              renderUserRating
            );
            return (
              <div className="flex-column half-width" key={k}>
                <span className="the-basics-section__sub-header">
                  {el.label}
                </span>
                {renderElements}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  _showContactInfo = (userData) => {
    if (userData.email) {
      return (
        <div
          className="flex-row"
          style={{ width: "100%", alignItems: "flex-start" }}
        >
          <div className="flex-column half-width">
            <span className="the-basics-section__sub-header">Email</span>
            {userData.email}
          </div>
          <div className="flex-column half-width">
            <span className="the-basics-section__sub-header">Phone</span>
            {userData.phone || "--"}
          </div>
        </div>
      );
    }
  };

  _renderBackground = (requestedUserData) => {
    const handleRelationshipStatusChange = async (status) => {
      let newStatus = {
        ...status,
        date: new Date(),
      };

      const value = newStatus;
      const field = "relationshipStatus";
      const userChangeId = this.props.requestedUserData._id;

      await Api.post({
        url: "/api/save-user-field-data",
        data: { userId: userChangeId, field, value },
      });
    };

    const handleMarriageStatusChange = async (status) => {
      if (status === "Engaged/married") {
        // this.saveUserFieldData("accountStatus", "inactive");
        const value = "inactive";
        const field = "accountStatus";
        const userChangeId = this.props.requestedUserData._id;

        await Api.post({
          url: "/api/save-user-field-data",
          data: { userId: userChangeId, field, value },
        });

        handleRelationshipStatusChange({
          value: "Not Single",
          label: "Not Single",
        });
      } else if (this.props.requestedUserData.accountStatus === "inactive") {
        //this.saveUserFieldData("accountStatus", "active");
        const value = "active";
        const field = "accountStatus";
        const userChangeId = this.props.requestedUserData._id;

        await Api.post({
          url: "/api/save-user-field-data",
          data: { userId: userChangeId, field, value },
        });
      }

      // this.saveUserFieldData("marriageStatus", status);
      var userStatus;
      if (status === "Engaged/married") {
        userStatus = { value: "Engaged/married", label: "Engaged/married" };
      } else {
        handleRelationshipStatusChange({
          value: "Single",
          label: "Single",
        });

        userStatus = MARRIAGE_STATUSES.find((item) => item.value === status);
      }

      const value = userStatus;
      const field = "marriageStatus";
      const userChangeId = this.props.requestedUserData._id;

      await Api.post({
        url: "/api/save-user-field-data",
        data: { userId: userChangeId, field, value },
      });
    };

    return (
      <div className="background-section" style={{ marginTop: "60px" }}>
        <div className="section-header">Background</div>
        <div className="section-grid">
          {USER_INFO_TABLE_DATA.backGround.map((el, k) => {
            const value = this._returnValueTypes(
              el.type,
              requestedUserData[el.key]
            );
            var prevStat = MARRIAGE_STATUSES.map((item) => item.value);
            prevStat = insertAt(prevStat, -1, "Engaged/married");
            const STATUSES = prevStat;
            const renderElements = this._renderElements(el.key, value, null);
            return (
              // <div className={`flex-column ${k !== 4 ? 'third-width' : ''}`} key={k}>
              el.key === "upbringing" ? (
                <div className={`flex-column wrap`} key={k}>
                  <span className="the-basics-section__sub-header">
                    {el.label}
                  </span>
                  {renderElements}
                </div>
              ) : el.key === "marriageStatus" ? (
                <div className={`flex-column`} key={k}>
                  <span className="the-basics-section__sub-header">
                    {el.label}
                  </span>
                  {
                    <MarriageStatusSelect
                      options={STATUSES}
                      value={
                        requestedUserData.marriageStatus
                          ? requestedUserData.marriageStatus.label
                          : STATUSES[0].label
                      }
                      onChange={handleMarriageStatusChange}
                    />
                  }
                </div>
              ) : el.key === "employmentStatus" ? (
                <div className={`flex-column`} key={k}>
                  <span className="the-basics-section__sub-header">
                    {el.label}
                  </span>
                  <span className="the-basics-section__value">
                    {requestedUserData.employmentStatus
                      ? requestedUserData.employmentStatus.label
                      : "--"}
                  </span>
                </div>
              ) : (
                <div className={`flex-column`} key={k}>
                  <span className="the-basics-section__sub-header">
                    {el.label}
                  </span>
                  {renderElements}
                </div>
              )
            );
          })}
        </div>
      </div>
    );
  };

  _renderLifeStyle = (requestedUserData) => {
    return (
      <div className="lifestyle-section" style={{ marginTop: "60px" }}>
        <div className="section-header">Lifestyle</div>
        <div className="section-grid">
          {USER_INFO_TABLE_DATA.lifeStyle.map((el, k) => {
            let value = Object;
            if (
              el.key === "travel.personalImportance" ||
              el.key === "travel.personalExperience" ||
              el.key === "travel.personalComments"
            ) {
              const travel = requestedUserData.travel;
              if (travel) {
                value = this._returnValueTypes(
                  el.type,
                  travel[el.key.split(".")[1]],
                  el.key.split(".")[1],
                  el.accessor
                );
              }
            } else {
              value = this._returnValueTypes(
                el.type,
                requestedUserData[el.key],
                el.key,
                el.accessor
              );
            }
            const renderElements = this._renderElements(el.key, value, null);
            return (
              <div className={`flex-column`} key={k}>
                <span className="the-basics-section__sub-header">
                  {el.label}
                </span>
                {renderElements}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  _renderFamily = (requestedUserData) => {
    return (
      <div className="lifestyle-section" style={{ marginTop: "60px" }}>
        <div className="section-header">Family</div>
        <div className="section-grid">
          {USER_INFO_TABLE_DATA.family.map((el, k) => {
            const value = this._returnValueTypes(
              el.type,
              requestedUserData[el.key],
              el.key,
              el.accessor
            );
            const renderElements = this._renderElements(el.key, value, null);
            return (
              <div className={`flex-column`} key={k}>
                <span className="the-basics-section__sub-header">
                  {el.label}
                </span>
                {renderElements}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  _renderPartnerCriteria = (requestedUserData) => {
    return (
      <div className="lifestyle-section">
        <div className="section-header" style={{ marginTop: "60px" }}>
          Partner Criteria
        </div>
        <div className="section-grid">
          {USER_INFO_TABLE_DATA.partnerCriteria.map((el, k) => {
            const value = this._returnValueTypes(
              el.type,
              requestedUserData[el.key],
              el.key,
              null,
              true
            );
            const renderElements = this._renderElements(el.key, value, null);
            return ["matchAreaDetails", "partnerPreferences"].find(
              (x) => x === el.key
            ) ? (
              <div
                style={{
                  width: "100%",
                  paddingTop: "1em",
                }}
                className={`flex-column wrap`}
                key={k}
              >
                <span className="the-basics-section__sub-header">
                  {el.label}
                </span>
                {renderElements}
              </div>
            ) : (
              <div className={`flex-column`} key={k}>
                <span className="the-basics-section__sub-header">
                  {el.label}
                </span>
                {renderElements}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  _renderOther = (otherData) => {
    return (
      <div className="other-section-admin">
        <div className="section-header" style={{ marginTop: "60px" }}>
          Other
        </div>
        <div className="section-grid">
          {USER_INFO_TABLE_DATA.other.map((el, k) => {
            const value = this._returnValueTypes(
              el.type,
              otherData[el.key],
              el.key
            );
            const renderElements = this._renderElements(el.key, value, null);
            return (
              <div className={`flex-column`} key={k}>
                <span className="the-basics-section__sub-header">
                  {el.label}
                </span>
                {renderElements}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  _renderConnections = (requestedUserData) => {
    const { isAdminUser } = this.state;
    const isAccountStatusActive = this.state.accountStatus === "active";
    const userInfoTableData = USER_INFO_TABLE_DATA.dateSpotHistory;
    if (!isAdminUser) {
      delete userInfoTableData[1];
    }

    const changeData = (field, value) => {
      const lowerCaseValue = ACCOUNT_STATUS[value];
      this.setState({
        [field]: value,
      });

      const userChangeId = this.props.requestedUserData._id;

      Api.post({
        url: "/api/save-user-field-data",
        data: { userId: userChangeId, field, value: lowerCaseValue },
      });
    };

    const capitalizeFirstLetter = (str) => {
      if (str) return str.charAt(0).toUpperCase() + str.slice(1);
      else return "";
    };

    return (
      <div className="lifestyle-section" style={{ marginTop: "60px" }}>
        <div className="section-header">DateSpot History</div>
        <div className="section-grid">
          {userInfoTableData.map((el, k) => {
            const value = this._returnValueTypes(
              el.type,
              requestedUserData[el.key],
              el.key
            );
            const renderElements = this._renderElements(
              !(el.label === "Referral Source") ? el.key : "referralSource",
              value,
              null
            );
            return (
              <div className={`flex-column`} key={k}>
                <span className="the-basics-section__sub-header">
                  {el.label}
                </span>
                {renderElements}
              </div>
            );
          })}
          {this._renderSuspicionInfo()}
          {/*<AccountStatus*/}
          {/*    changeStatus={() => { changeData("accountStatus", isAccountStatusActive ? "inactive" : "active") }}*/}
          {/*    isAccountStatusActive={isAccountStatusActive}*/}
          {/*/>*/}
          <div style={{ marginTop: "16px", minHeight: "130px" }}>
            <span className="subscription-label">Account status</span>
            <Select
              id="account-status-select"
              defaultValue={capitalizeFirstLetter(this.state.accountStatus)}
              options={Object.keys(ACCOUNT_STATUS)}
              onChange={(option) => {
                changeData("accountStatus", option);
              }}
              placeholder="Select Status"
            />
          </div>
        </div>
      </div>
    );
  };

  _returnValueTypes = (type, data, key, accessor, applyOption) => {
    const { requestedUserData } = this.props;
    let value = "";
    if (type === "object") {
      if (accessor && data) return data[accessor];
      if (["giveAppreciation", "receiveAppreciation"].includes(key)) {
        value =
          data &&
          Object.keys(data)
            .map(
              (dataKey) =>
                `${
                  APPRECIATION_OPTIONS.find((option) => option.key === dataKey)
                    .label
                } (${data[dataKey]})`
            )
            .join(", ");
      } else if (["longestRelationship", "blindDates"].includes(key)) {
        value = data && data.label;
      } else {
        value = data && data.value;
      }
    }
    if (type === "array") {
      if (key === "hobbies") {
        data = data.concat(
          requestedUserData.music && requestedUserData.music.activities
            ? requestedUserData.music.activities
            : []
        );
      }

      if (key === "languages") {
        return Object.values(data)
          .map((lang) => {
            let langWithoutId = {};
            lang.proficiency
              ? (langWithoutId = {
                  language: lang.language,
                  proficiency: `(${lang.proficiency})`,
                })
              : (langWithoutId = {
                  language: lang.language,
                  proficiency: "",
                });
            return Object.values(langWithoutId).join(" ");
          })
          .join(", ");
      }

      if (data && data[0]) {
        value = data
          .map((item) =>
            item.label === "Other" ? item.value || item.label : item.label
          )
          .join(", ");
      }
    }
    if (type === "string") {
      value = data;
    }
    if (type === "nestedObject") {
      value = this._renderKidsAges(data);
    }
    if (type === "nestedPets") {
      value = this._renderPets(data);
    }
    if (type === "component") {
      value = this._renderRadioButtons(data, key);
    }
    if (type === "empty") {
      value = "";
    }
    if (type === "heightNested") {
      value = this._renderHeightPreferences(data);
    }
    if (type === "created") {
      value = moment(requestedUserData.createdAt).format("MM/DD/YYYY");
    }
    if (type === "requests") {
      value =
        (requestedUserData.connectionsRequests &&
          requestedUserData.connectionsRequests.length) ||
        "--";
    }
    if (type === "nestedDrugs") {
      if (applyOption) {
        value = this._renderDrugs(data, applyOption);
      } else {
        value = this._renderDrugs(data);
      }
    }
    if (type === "nestedMusic") {
      value = this._renderMusic(data);
    }
    if (type === "matchAreaArray") {
      if (data.some((e) => e.value === "All U.S. ")) {
        value = "All U.S.";
      } else if (data[0]) {
        value = data.map((item) => item.label).join(", ");
      }
    }
    return value;
  };

  _renderElements = (key, value, renderUserRating) => {
    function getHeight(inches) {
      const inch = inches % 12;
      const feet = Math.floor(inches / 12);
      return `${feet}'${inch}"`;
    }

    const validHeight = (inches) => {
      if (typeof inches === "string") {
        return !inches.includes("ft");
      } else {
        return Number.isFinite(parseInt(inches));
      }
    };

    const changeData = (field, value) => {
      this.setState({
        [field]: value,
      });

      const userChangeId = this.props.requestedUserData._id;

      Api.post({
        url: "/api/save-user-field-data",
        data: { userId: userChangeId, field, value },
      });
    };

    const handleRelationshipStatusChange = (status) => {
      const userStatus = RELATIONSHIP_STATUSES.find(
        (item) => item.value === status
      );
      let newStatus = {
        ...userStatus,
        date: new Date(),
      };

      changeData("relationshipStatus", newStatus);
    };

    const handleClientLeadStatusChange = (status) => {
      if (status === "No (Candidate Only)" || status === "Former Client") {
        changeData("clientStatus", "candidate");
      } else if (status === "Current Client" || status === "Yes") {
        changeData("clientStatus", "client");
      }

      changeData("clientLead", status);
    };

    function getRegion(user) {
      //FIXME: workaround for users with no regionData (it should be an empty array at least)
      // FIX ON BACKEND
      if (!user.regionData) user.regionData = [];
      let closestRegion = {};
      for (let region of user.regionData) {
        if (
          !closestRegion.distance ||
          region.distance < closestRegion.distance
        ) {
          closestRegion = region;
        }
      }
      let label;
      if (closestRegion.distance || closestRegion.distance === 0) {
        label =
          closestRegion.label ||
          `${closestRegion.city}, ${closestRegion.state}`;
      }
      if (!label && user.regions?.length)
        label =
          statesIndex[user.regions[0]] ||
          REGIONS.find((x) => x.value === user.regions[0])?.label ||
          label;
      return label;
    }

    const { requestedUserData } = this.props;
    const zip = requestedUserData.zip || null;
    const state = requestedUserData.state || null;
    const country =
      requestedUserData.country != "United States of America"
        ? requestedUserData.country
        : "";
    const zipString = zip ? `, ${zip}` : "";
    const stateString = state
      ? country
        ? `, ${country}, Region: International`
        : `, ${state}`
      : "";

    let content = value ? (
      <span className="the-basics-section__value">{value}</span>
    ) : (
      <span className="the-basics-section__value">--</span>
    );

    switch (key) {
      case "userRating":
        content = <div>{renderUserRating}</div>;
        break;
      case "height":
        content = value ? (
          validHeight(value) ? (
            <span className="the-basics-section__value">
              {getHeight(value)}
            </span>
          ) : (
            <span className="the-basics-section__value">
              {value.replace("ft", "’").replace("in", "”")}
            </span>
          )
        ) : (
          "--"
        );
        break;
      case "city":
        let region = getRegion(requestedUserData);
        content = value ? (
          <div>
            <p className="the-basics-section__value">
              {value} {stateString} {zipString}{" "}
            </p>
            {region && (
              <p className="the-basics-section__value">Region: {region}</p>
            )}
          </div>
        ) : (
          <span className="the-basics-section__value">--</span>
        );
        break;
      case "relationshipStatus":
        if (!value) break;
        const STATUSES = RELATIONSHIP_STATUSES.map((item) => item.value);
        content = (
          <span>
            {
              <RelationShipStatusSelect
                options={STATUSES}
                value={requestedUserData.relationshipStatus.label}
                onChange={handleRelationshipStatusChange}
              />
            }{" "}
            <div>
              {!!requestedUserData.relationshipStatus?.date && (
                <span className="date-ago">
                  as of{" "}
                  {requestedUserData.relationshipStatus.date &&
                    new Intl.DateTimeFormat("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }).format(
                      new Date(requestedUserData.relationshipStatus.date)
                    )}
                </span>
              )}
              {/* if no date */}
              {!requestedUserData.relationshipStatus?.date && (
                <span className="date-ago">as of Today</span>
              )}
              <span className="date-ago"> (reload for changes)</span>
            </div>
          </span>
        );
        break;
      case "referralSource":
        if (!requestedUserData.referralSource && !value) break;
        if (requestedUserData.referralSource.find((x) => x.label === "Other")) {
          value = value.replace(/Other$/, "");
          if (!value) {
            content = (
              <span className="the-basics-section__value">
                Other:
                {requestedUserData.referralSource.find(
                  (x) => x.label === "Other" && x.firstName
                )
                  ? " " +
                    requestedUserData.referralSource.find(
                      (x) => x.label === "Other"
                    ).firstName +
                    " "
                  : "-- "}
                | Email:{" "}
                {requestedUserData.referralSource.find(
                  (x) => x.label === "Other" && x.email
                )
                  ? requestedUserData.referralSource.find(
                      (x) => x.label === "Other"
                    ).email
                  : "--"}
              </span>
            );
          } else {
            content = (
              <span className="the-basics-section__value">
                {value.trimEnd().endsWith(",") ? value + " " : value + ", "}
                Other:
                {requestedUserData.referralSource.find(
                  (x) => x.label === "Other" && x.firstName
                )
                  ? " " +
                    requestedUserData.referralSource.find(
                      (x) => x.label === "Other"
                    ).firstName +
                    " "
                  : "-- "}
                | Email:{" "}
                {requestedUserData.referralSource.find(
                  (x) => x.label === "Other" && x.email
                )
                  ? requestedUserData.referralSource.find(
                      (x) => x.label === "Other"
                    ).email
                  : "--"}
              </span>
            );
          }
        } else if (
          requestedUserData.referralSource.find((x) => x.label === "Matchmaker")
        ) {
          value = value.replace(/Matchmaker$/, "");
          value = value.replace(/Professional Matchmaker, /g, "");
          if (!value) {
            content = (
              <span className="the-basics-section__value">
                Matchmaker:
                {requestedUserData.referralSource.find(
                  (x) => x.label === "Matchmaker" && x.firstName
                )
                  ? " " +
                    requestedUserData.referralSource.find(
                      (x) => x.label === "Matchmaker"
                    ).firstName +
                    " "
                  : "-- "}
                | Company:{" "}
                {requestedUserData.referralSource.find(
                  (x) => x.label === "Matchmaker" && x.companyName
                )
                  ? " " +
                    requestedUserData.referralSource.find(
                      (x) => x.label === "Matchmaker"
                    ).companyName +
                    " "
                  : "-- "}
                | Email:{" "}
                {requestedUserData.referralSource.find(
                  (x) => x.label === "Matchmaker" && x.email
                )
                  ? requestedUserData.referralSource.find(
                      (x) => x.label === "Matchmaker"
                    ).email
                  : "--"}
              </span>
            );
          } else {
            content = (
              <span className="the-basics-section__value">
                {value.trimEnd().endsWith(",") ? value + " " : value + ", "}
                Matchmaker:
                {requestedUserData.referralSource.find(
                  (x) => x.label === "Matchmaker" && x.firstName
                )
                  ? " " +
                    requestedUserData.referralSource.find(
                      (x) => x.label === "Matchmaker"
                    ).firstName +
                    " "
                  : "-- "}
                | Company:{" "}
                {requestedUserData.referralSource.find(
                  (x) => x.label === "Matchmaker" && x.companyName
                )
                  ? " " +
                    requestedUserData.referralSource.find(
                      (x) => x.label === "Matchmaker"
                    ).companyName +
                    " "
                  : "-- "}
                | Email:{" "}
                {requestedUserData.referralSource.find(
                  (x) => x.label === "Matchmaker" && x.email
                )
                  ? requestedUserData.referralSource.find(
                      (x) => x.label === "Matchmaker"
                    ).email
                  : "--"}
              </span>
            );
          }
        } else if (
          requestedUserData.referralSource.find(
            (x) => x.label === "DateSpot Recruiter"
          )
        ) {
          value = value.replace(/DateSpot Recruiter/g, "");
          value = value.replace(/Professional Matchmaker, /g, "");
          if (!value) {
            content = (
              <span className="the-basics-section__value">
                DateSpot Recruiter:
                {requestedUserData.referralSource.find(
                  (x) => x.label === "DateSpot Recruiter" && x.firstName
                )
                  ? " " +
                    requestedUserData.referralSource.find(
                      (x) => x.label === "DateSpot Recruiter"
                    ).firstName
                  : "--"}
              </span>
            );
          } else {
            content = (
              <span className="the-basics-section__value">
                {value.trimEnd().endsWith(",") ? value + " " : value + ", "}
                DateSpot Recruiter:
                {requestedUserData.referralSource.find(
                  (x) => x.label === "DateSpot Recruiter" && x.firstName
                )
                  ? " " +
                    requestedUserData.referralSource.find(
                      (x) => x.label === "DateSpot Recruiter"
                    ).firstName
                  : "--"}
              </span>
            );
          }
        } else {
          content = value ? (
            <span className="the-basics-section__value">{value}</span>
          ) : (
            <span className="the-basics-section__value">--</span>
          );
        }
        break;
      case "clientStatus":
        if (!requestedUserData.clientStatus) break;
        else if (requestedUserData.clientStatus === "candidate") {
          content = (
            <span className="the-basics-section__value">Candidate</span>
          );
        } else if (requestedUserData.clientStatus === "client") {
          content = <span className="the-basics-section__value">Client</span>;
        }
        break;
      case "clientLead":
        const statuses = [
          "Unknown",
          "Yes",
          "No (Candidate Only)",
          "Current Client",
          "Former Client",
        ];
        content = (
          <span>
            {
              <ClientLeadStatusSelect
                options={statuses}
                value={requestedUserData.clientLead}
                onChange={handleClientLeadStatusChange}
              />
            }
            <div>
              <span className="date-ago"> (reload for changes)</span>
            </div>
          </span>
        );
        break;
      case "travel.personalImportance":
        content = value ? (
          <span className="the-basics-section__value">
            {requestedUserData.travel.personalImportance.value}
          </span>
        ) : (
          <span className="the-basics-section__value">--</span>
        );
        break;
      case "":
        content = <span className="the-basics-section__value" />;
        break;
      default:
        break;
    }

    return content;
  };

  _renderUserRating = (requestedUserData) => {
    const { showRating, ratingValue } = this.state;
    return (
      <div className="flex-column" style={{ position: "relative" }}>
        <div className="flex-row">
          <span className="the-basics-section__small-text">Rating average</span>
          <span className="the-basics-section__value">
            {requestedUserData.userRating
              ? Math.round(requestedUserData.userRating)
              : " -- "}
          </span>
        </div>
        <div className="flex-row">
          <span className="the-basics-section__small-text">Your Rating</span>
          <span className="the-basics-section__value">
            {requestedUserData.userRatingByMe || " -- "}
          </span>
          <span
            className="user-rating"
            onClick={() => this.setState({ showRating: !showRating })}
          >
            {requestedUserData.userRatingByMe ? "Change rating" : "Add rating"}
          </span>
        </div>
        {showRating && (
          <div className="show-rating-tooltip">
            <RatingToolTip
              list={ATTRACTIVENESS_RATING}
              title="Your rating"
              selectedValue={ratingValue}
              stateCallback={(val) => this.applyRating(parseInt(val, 10))}
            />
          </div>
        )}
      </div>
    );
  };

  _renderKidsAges = (el) => {
    if (!el) {
      return <span className="the-basics-section__value">--</span>;
    }
    if (
      !el.kidsAges ||
      (Object.entries(el.kidsAges).length === 0 &&
        el.kidsAges.constructor === Object)
    ) {
      return <span className="the-basics-section__value">--</span>;
    }

    const joinedKidsAges = Object.values(el.kidsAges).join(" & ");

    return (
      <span className="the-basics-section__value">
        {!!joinedKidsAges ? `${joinedKidsAges} years old` : "--"}
      </span>
    );
  };

  _renderPets = (pets) => {
    if (
      !pets ||
      (Object.entries(pets).length === 0 && pets.constructor === Object)
    ) {
      return <span className="the-basics-section__value">--</span>;
    }
    return (
      <span className="flex-column">
        {Object.keys(pets).map((pet, p) => {
          if (pets[pet]) {
            return (
              <span key={p}>
                <span style={{ textTransform: "capitalize" }}>{pet}</span>:
                <span> {pet === "other" ? pets[pet] : pets[pet].value}</span>
              </span>
            );
          }
        })}
      </span>
    );
  };

  _renderMusic = (music) => {
    if (
      !music ||
      (Object.entries(music).length === 0 && music.constructor === Object)
    ) {
      return <span className="the-basics-section__value">--</span>;
    }
    return (
      <div>
        {/* <span>Music Activites</span>
                {music.activities ?
                    <span className='flex-column'>
                        {music.activities.map(val => val.value).join(', ')}
                    </span>
                :
                    <div>--</div>
                } */}

        {/* <span>Music Genres</span> */}
        {music.genres ? (
          <span className="flex-column">
            {music.genres.map((val) => val.value).join(", ")}
          </span>
        ) : (
          <div>--</div>
        )}
      </div>
    );
  };

  _renderDrugs = (does_drugs, isPartner) => {
    function getLabel(type) {
      switch (type) {
        case "marijuana":
          return "Marijuana";
        case "psychedelics":
          return "Psychedelics";
        case "cocaine":
          return "Cocaine";
        case "mdma":
          return "MDMA";
        case "ketamine":
          return "Ketamine";
        default:
          return type;
      }
    }

    if (does_drugs) {
      if (!isPartner) {
        return (
          <div>
            {does_drugs.drugs ? (
              does_drugs.drugs.value ? (
                <div>
                  How often do you do any drugs? {does_drugs.drugs.value}
                </div>
              ) : (
                <div />
              )
            ) : (
              <div />
            )}

            {does_drugs.drugs ? (
              does_drugs.drugs.value && does_drugs.drugs.value !== "Never" ? (
                does_drugs.types ? (
                  <div>
                    {Object.keys(does_drugs.types).map((type) => {
                      if (
                        does_drugs.types[type] &&
                        does_drugs.types[type].value &&
                        type !== "other"
                      ) {
                        return (
                          <div>
                            {getLabel(type)}: {does_drugs.types[type].value}
                          </div>
                        );
                      } else if (
                        type === "other" &&
                        does_drugs.types[type].value
                      ) {
                        const freq =
                          does_drugs.types[type].frequency &&
                          isString(does_drugs.types[type].frequency) &&
                          does_drugs.types[type].frequency;
                        const value =
                          does_drugs.types[type].value &&
                          isString(does_drugs.types[type].value) &&
                          does_drugs.types[type].value;
                        return (
                          <div>
                            {value ? value : ""}{" "}
                            {value ? (freq ? `: ${freq}` : "") : ""}
                          </div>
                        );
                      } else {
                        return "";
                      }
                    })}
                  </div>
                ) : (
                  <div />
                )
              ) : (
                <div />
              )
            ) : (
              <div />
            )}
          </div>
        );
      } else if (isPartner) {
        return (
          <div>
            {does_drugs.partner_drugs ? (
              does_drugs.partner_drugs.value ? (
                <div>
                  How often are you open to your partner doing drugs?{" "}
                  {does_drugs.partner_drugs.value}
                </div>
              ) : (
                <div />
              )
            ) : (
              <div />
            )}

            {does_drugs.partner_drugs ? (
              does_drugs.partner_drugs.value &&
              does_drugs.partner_drugs.value !== "Never" ? (
                does_drugs.partner ? (
                  <div>
                    {Object.keys(does_drugs.partner).map((type) => {
                      if (
                        does_drugs.partner[type] &&
                        does_drugs.partner[type].value &&
                        type !== "other"
                      ) {
                        return (
                          <div>
                            {getLabel(type)}: {does_drugs.partner[type].value}
                          </div>
                        );
                      } else if (
                        type === "other" &&
                        does_drugs.partner[type].value
                      ) {
                        const freq =
                          does_drugs.partner[type].frequency &&
                          isString(does_drugs.partner[type].frequency) &&
                          does_drugs.partner[type].frequency;
                        const value =
                          does_drugs.partner[type].value &&
                          isString(does_drugs.partner[type].value) &&
                          does_drugs.partner[type].value;
                        return (
                          <div>
                            {value ? value : ""}{" "}
                            {value ? (freq ? `: ${freq}` : "") : ""}
                          </div>
                        );
                      } else {
                        return "";
                      }
                    })}
                  </div>
                ) : (
                  <div />
                )
              ) : (
                <div />
              )
            ) : (
              <div />
            )}
          </div>
        );
      } else {
        return <div>--</div>;
      }
    }
  };

  _renderRadioButtons = (data, key) => {
    if (data) {
      return (
        <div className="color-scale flex-row">
          <span>{`${
            key === "financialStability" ? "Low" : "Not important"
          }`}</span>
          <div className="color-scale__container flex-row">
            {Array.from(new Array(5), (_, x) => (
              <span
                key={data.key}
                className={`option flex-center ${
                  data.id === x + 1 ? "selected" : ""
                }`}
              >
                {x + 1}
              </span>
            ))}
          </div>
          <span>{`${
            key === "financialStability" ? "High" : "Very important"
          }`}</span>
        </div>
      );
    } else {
      return <span className="the-basics-section__value">--</span>;
    }
  };

  _renderHeightPreferences = (data) => {
    if (data) {
      return (
        <span className="the-basics-section__value">
          {data.value && data.value.height
            ? `${data.value.height} or ${data.value.attribute.label}`
            : "No preference"}
        </span>
      );
    }
  };

  processDate = (month, day, year) => {
    // const { _id: userId } = this.props.user;
    if (month && day && year) {
      const date_verified = `${month.value}/${day.value}/${year.value}`;
      const formatDate = moment(date_verified, "MM-DD-YYYY");
      this.setState({ verifiedDate: date_verified });
      this.props.adminActions.saveUserFieldDataFromAdmin(
        this.props.requestedUserData._id,
        "verification.date_verified",
        date_verified
      );
    }
  };

  _renderVerificationDate = () => {
    const { month, day, year, verifiedProfile } = this.state;
    return (
      <div className="flex-row">
        <span style={{ paddingRight: "8px", color: "#65C4C0" /*$teal*/ }}>
          Date Met:
        </span>
        <div style={{ gap: "0.5rem" }} className="flex-row">
          <DropDown
            disabled={!verifiedProfile}
            wrapperClassName="month-drop-down"
            listOfValues={MONTHS || []}
            selectedValue={month}
            name="selectedMonth"
            placeholder="Month"
            arrow={true}
            onSelectValue={(val) => {
              this.processDate(val, day, year);
              this.setState({ month: val });
            }}
          />
          <DropDown
            disabled={!verifiedProfile}
            wrapperClassName="day-drop-down"
            listOfValues={DAYS || []}
            selectedValue={day}
            name="selectedDay"
            arrow={true}
            placeholder="Day"
            onSelectValue={(val) => {
              this.processDate(month, val, year);
              this.setState({ day: val });
            }}
          />
          <DropDown
            disabled={!verifiedProfile}
            wrapperClassName="year-drop-down"
            listOfValues={YEARS || []}
            selectedValue={year}
            name="selectedYear"
            arrow={true}
            placeholder="Year"
            onSelectValue={(val) => {
              this.processDate(month, day, val);
              this.setState({ year: val });
            }}
          />
        </div>
      </div>
    );
  };

  _renderSuspicionInfo = () => {
    const {
      suspicionProfile,
      suspicionEmail,
      verifiedBy,
      verifiedProfile,
      unresponsive,
    } = this.state;
    return (
      <>
        <div className={`flex-column`}>
          <CheckBox
            customClass="suspicion"
            checked={suspicionProfile}
            labelName="Suspicious Account"
            clicked={() => {
              this.props.adminActions.saveUserFieldDataFromAdmin(
                this.props.requestedUserData._id,
                "suspicion.profile",
                !suspicionProfile
              );
              this.setState({ suspicionProfile: !suspicionProfile });
            }}
          />
        </div>
        <div style={{ justifyContent: "center" }} className={`flex-column`}>
          <CheckBox
            customClass="suspicion"
            checked={unresponsive}
            labelName="Unresponsive"
            clicked={() => {
              this.props.adminActions.saveUserFieldDataFromAdmin(
                this.props.requestedUserData._id,
                "unresponsive",
                !unresponsive
              );
              this.setState({ unresponsive: !unresponsive });
            }}
          />
        </div>
        <div style={{ justifyContent: "center" }} className={`flex-column`}>
          <CheckBox
            customClass="suspicion"
            checked={suspicionEmail}
            labelName="Bounced Email"
            clicked={() => {
              this.props.adminActions.saveUserFieldDataFromAdmin(
                this.props.requestedUserData._id,
                "suspicion.email",
                !suspicionEmail
              );
              this.setState({ suspicionEmail: !suspicionEmail });
            }}
          />
        </div>
        <div style={{ justifyContent: "center" }} className={`flex-column`}>
          <CheckBox
            customClass="suspicion"
            checked={verifiedProfile}
            labelName="Verified Profile"
            clicked={() => {
              this.props.adminActions.saveUserFieldDataFromAdmin(
                this.props.requestedUserData._id,
                "verification.verified",
                !verifiedProfile
              );
              this.setState({ verifiedProfile: !verifiedProfile });
            }}
          />
        </div>
        <div className={`flex-column`}>
          <InputText
            className="suspicion"
            wrapperClassName="met-with-wrapper"
            classNameForLabel="suspicion-label"
            label="Met with: "
            disabled={!verifiedProfile}
            showLabel={true}
            value={verifiedBy}
            onChange={(e) => {
              this.setState({ verifiedBy: e.target.value });
            }}
            onBlur={() => {
              this.props.adminActions.saveUserFieldDataFromAdmin(
                this.props.requestedUserData._id,
                "verification.verified_by",
                verifiedBy
              );
            }}
          />
        </div>
        <div style={{ justifyContent: "center" }} className={`flex-column`}>
          {this._renderVerificationDate()}
        </div>
      </>
    );
  };
}

const RelationShipStatusSelect = ({ value, onChange, options, fullWidth }) => (
  <div>
    <Select
      id="relationship-status-select"
      fullWidth={false}
      defaultValue={value}
      options={options}
      onChange={onChange}
      expandable={!!options.length}
      placeholder="Select Status"
    />
  </div>
);

const ClientLeadStatusSelect = ({ value, onChange, options, fullWidth }) => (
  <div>
    <Select
      id="clientLead-status-select"
      fullWidth={false}
      defaultValue={value}
      options={options}
      onChange={onChange}
      expandable={!!options.length}
      placeholder="Select Status"
    />
  </div>
);

const MarriageStatusSelect = ({ value, onChange, options, fullWidth }) => (
  <div>
    <Select
      id="relationship-status-select"
      fullWidth={false}
      defaultValue={value}
      options={options}
      onChange={onChange}
      expandable={!!options.length}
      placeholder="Select Status"
    />
  </div>
);

UserInfoModalContent.propTypes = {
  requestedUserData: PropTypes.object,
  matchMakerActions: PropTypes.object,
  adminActions: PropTypes.object,
  loggedMMplan: PropTypes.any,
  closeUserInfoModal: PropTypes.func,
  isFavoriteUser: PropTypes.bool,
  isUserContacted: PropTypes.bool,
  isUserLockedByMe: PropTypes.bool,
  isAdminUser: PropTypes.bool,
  userNumber: PropTypes.number,
};

export default UserInfoModalContent;
