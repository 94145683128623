import React, { Component } from "react";
import PropTypes from "prop-types";
import "./style.scss";

class ToolTip extends Component {
  static propTypes = {
    text: PropTypes.string,
    closeCallback: PropTypes.func,
  };

  render() {
    const { text, closeCallback } = this.props;

    return (
      <div className="tool-tip-component flex-center">
        <span className="icon-close-button" onClick={closeCallback} />
        <span className="tool-tip-component__text">{text}</span>
      </div>
    );
  }
}

export default ToolTip;
