/*

  FADE - automated scroll-based entry transitions

  e.g.:
  // will fade up while spinning to the right when scrolled to
  <Fade up spin-right> TEST </Fade>

  // will zoom in while fading in when scrolled to
  <Fade zoom-in> TEST </Fade>

  // combines the previous examples
  <Fade up spin-right zoom-in> TEST </Fade>

  // will do nothing since the effects cancel out
  <Fade left right up down zoom-in zoom-out> TEST </Fade>

*/

import React from "react";

// SCROLL TRIGGER FUNCTIONS

let config = {
  initted: false,
  toCheck: {},
  els: [],
  curId: 0,
  debounceTimer: null,
  fadeContainer: null,
  ticking: false,
  y: 0,
};

window.__FadeConfig__ = config;

const registerScroller = (el) => {
  tearDown();
  config.fadeContainer = el;
  initFade();
};

export const getFadeTarget = () => {
  return config.fadeContainer;
};

const resetScrollers = () => {
  Object.keys(config.toCheck).forEach((key) => {
    config.toCheck[key].target = getTargetY(config.toCheck[key].el);
  });
  checkScroll(true);
};

let initInterval = null;

const initFade = () => {
  // only attach listener if needed
  if (!config.initted) {
    config.initted = true;
    const tgt = config.fadeContainer || window;
    tgt.addEventListener("scroll", checkScroll);
    if (!config.everInitted) config.everInitted = true;
    initInterval = setInterval(resetScrollers, 1000);
  }
};

const tearDown = () => {
  if (config.initted) {
    config.initted = false;
    config.els = [];
    const tgt = config.fadeContainer || window;
    tgt.removeEventListener("scroll", checkScroll);
    clearInterval(initInterval);
  }
};

const updateScrollers = () => {
  const { y } = config;
  //console.log(config.fadeContainer, y)
  for (let key in config.toCheck) {
    const t = config.toCheck[key];
    const el = t.el;
    if (!el.state.triggered && y > t.target) {
      el.trigger();
    } else if (el.props.repeat && el.state.triggered && y < t.target) {
      el.reset();
    }
  }
  config.ticking = false;
};

const checkScroll = (force) => {
  if (config.initted) {
    const ftgt = config.fadeContainer;
    if (ftgt) {
      config.y = ftgt.scrollY || ftgt.scrollTop;
    } else {
      config.y =
        window.scrollY ||
        document.body.scrollTop ||
        document.documentElement.scrollTop;
    }
    if (!config.ticking) {
      // rate limit to 60 fps
      setTimeout(requestAnimationFrame(updateScrollers), 1000 / 60);
    }
    config.ticking = true;
  }
};

const getTargetY = (el) => {
  const top =
    el.div.getBoundingClientRect().top -
    window.innerHeight * 0.95 -
    (el.props.offset || 0);
  //const tgt = Math.min(top, document.documentElement.scrollHeight - (window.innerHeight))
  return parseInt(top);
};

const addTarget = (el) => {
  config.curId++;
  let key;
  if (!config.els.includes(el)) {
    config.els.push(el);
    const target = getTargetY(el);
    key = "el" + config.curId;
    config.toCheck[key] = {
      el: el,
      target: target,
      triggered: false,
    };
    setTimeout(() => checkScroll(true), 100);
  }
  return key;
};

const removeTarget = (key) => {
  delete config.toCheck[key];
  if (config.initted && Object.keys(config.toCheck).length < 1) {
    //console.log('tearing down')
    tearDown();
  }
};

// COMPONENTS

export class ScrollTrigger extends React.Component {
  constructor(props) {
    super(props);
    this.id = null;
    this.state = {
      triggered: false,
    };
  }

  componentDidMount() {
    this.id = addTarget(this);
    initFade();
  }

  componentDidUpdate(p, prevState) {
    if (this.state.triggered && !prevState.triggered) {
      removeTarget(this.id);
    }
  }

  componentWillUnmount() {
    removeTarget(this.id);
  }

  trigger(reset) {
    if (!this.props.repeat) {
      removeTarget(this.id);
    }
    this.setState({ triggered: reset ? false : true });
    setTimeout(() => {
      let fn = reset ? this.props.onReset : this.props.onTrigger;
      if (typeof fn == "function") {
        fn();
      }
    }, this.props.delay * 1000 || 0);
  }

  reset() {
    this.trigger(true);
  }

  render() {
    let Component = this.props.inline ? "span" : "div";
    if (this.props.tag) Component = this.props.tag;

    let childProps = Object.assign({}, this.props);
    ["onTrigger", "inline", "tag", "delay", "repeat"].forEach((prop) => {
      delete childProps[prop];
    });
    return (
      <Component
        {...childProps}
        ref={(div) => {
          this.div = div;
          if (this.props.ref) this.props.ref(div);
        }}
      >
        {this.props.children}
      </Component>
    );
  }
}

const DEFAULT_DISTANCE = 100;

const getTranslate = (angle, dist) => {
  const distance = dist || DEFAULT_DISTANCE;
  const a = -(90 - angle) * (Math.PI / 180);
  const x = Math.cos(a) * distance;
  const y = Math.sin(a) * distance;
  return {
    x,
    y,
    css: `translate(${x}px, ${y}px)`,
  };
};

// translate props/state into css transforms
const getStyles = (props, state, fade) => {
  const styles = {
    default: { opacity: "0" },
    active: {
      opacity: "1",
      transform: "scale(1) rotate(0deg) translate(0px, 0px)",
    },
  };

  const dur = props.duration || 1;
  const dist = props.distance || DEFAULT_DISTANCE;
  let transition = {
    transition: `opacity ${dur}s, left ${dur}s, top ${dur}s, transform ${dur}s`,
  };

  const transforms = {
    "zoom-in": "scale(.8)",
    "zoom-out": "scale(1.3)",
    "spin-left": "rotate(180deg)",
    "spin-right": "rotate(-180deg)",
    up: `translate(0px, ${dist}px)`,
    down: `translate(0px, -${dist}px)`,
    left: `translate(${dist}px, 0px)`,
    right: `translate(-${dist}px, 0px)`,
  };

  let transform = "";
  Object.keys(transforms).forEach((key) => {
    if (props[key]) transform += transforms[key] + " ";
  });
  if (props.angle || props.angle === 0) {
    transform += " " + getTranslate(props.angle, dist).css;
  }
  transform = { transform };
  let propStyle = Object.assign({}, props.style);
  let style = [propStyle, styles.default, transition, transform];
  if (props.inline) style.push({ display: "inline-block" });

  let Component = props.inline ? "span" : "div";
  if (props.tag) Component = props.tag;
  style.push(state.triggered ? styles.active : {});

  return Object.assign(...style);
};

export class Animator extends React.Component {
  constructor(props) {
    super(props);
    this.state = { triggered: false };
  }

  componentDidMount() {
    if (this.props.triggered) {
      this.setState({ triggered: true });
    }
  }

  componentWillReceiveProps(props) {
    if (this.props.triggered !== props.triggered) {
      if (!props.triggered) {
        this.setState({ triggered: false });
      } else {
        this.trigger();
      }
    }
  }

  trigger(reset) {
    setTimeout(() => {
      this.setState({ triggered: reset ? false : true });
      let fn = reset ? this.props.onReset : this.props.onTrigger;
      if (typeof fn == "function") {
        fn();
      }
    }, this.props.delay * 1000 || 0);
  }

  reset() {
    this.trigger(true);
  }

  render() {
    let Component = this.props.inline ? "span" : "div";
    if (this.props.tag) Component = this.props.tag;
    return (
      <Component
        style={getStyles(this.props, this.state, this.props.fade)}
        className={this.props.className}
      >
        {this.props.children}
      </Component>
    );
  }
}

export class FadeContainer extends React.Component {
  componentDidMount() {
    registerScroller(this.el);
  }

  render() {
    const props = this.props;
    let Tag = "div" || props.tag;
    return (
      <Tag
        ref={(el) => (this.el = el)}
        style={{
          maxWidth: "100%",
          overflowY: "scroll",
          overflowScrolling: "touch",
          WebkitOverflowScrolling: "touch",
        }}
        {...props}
      >
        {props.children}
      </Tag>
    );
  }
}

// main class
export default class Fade extends React.Component {
  constructor(props) {
    super(props);
    this.state = { triggered: false };
  }

  trigger() {
    this.setState({ triggered: true });
    if (typeof this.props.onTrigger == "function") {
      this.props.onTrigger();
    }
  }

  trigger(reset) {
    this.setState({ triggered: reset ? false : true });
    let fn = reset ? this.props.onReset : this.props.onTrigger;
    if (typeof fn == "function") {
      fn();
    }
  }

  reset() {
    this.trigger(true);
  }

  render() {
    let offset = this.props.offset || 0;
    const { up, down, angle, distance } = this.props;
    const dist = distance || DEFAULT_DISTANCE;
    if (angle) offset += getTranslate(angle, dist).y;
    if (up) offset += dist;
    if (down) offset -= dist;

    let scrollProps = [];
    ["inline", "tag", "className", "delay", "repeat"].forEach((prop) => {
      scrollProps[prop] = this.props[prop];
    });
    return (
      <ScrollTrigger
        onTrigger={() => this.trigger()}
        onReset={() => this.reset()}
        {...scrollProps}
        style={getStyles(this.props, this.state, this.props.noFade)}
        offset={offset}
        ref={this.props.ref}
      >
        {this.props.children}
      </ScrollTrigger>
    );
  }
}

// Shortcut to fade all children
export class FadeBlocks extends React.Component {
  render() {
    let key = 0;
    let children = this.props.children;
    if (children && !children.length) {
      children = [children];
    }
    return (children || []).map((child) => {
      key++;
      return (
        <Fade key={key * 1} {...this.props}>
          {child}
        </Fade>
      );
    });
  }
}
