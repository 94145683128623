import React, { Component } from "react";
import PropTypes from "prop-types";
import CheckBox from "../check-box";
import InstrumentCheckBox from "../check-box/instrument";
import "./hobbies-music_style.scss";

class HobbiesMusicCheckboxList extends Component {
  // ! Both filters on the state variables are to remove the null values from the array
  // ! We need to track were those null values are coming from and fix it there
  state = {
    hobbies_selectedOptions:
      this.props.hobbies_selectedValue.filter((item) => item) || [],
    music_selectedOptions:
      this.props.music_selectedValue.filter((item) => item) || [],
  };

  render() {
    const {
      list,
      title,
      hobbies_stateCallback,
      music_stateCallback,
      error,
      extraClass,
    } = this.props;
    const { hobbies_selectedOptions, music_selectedOptions } = this.state;
    const other_textVal = hobbies_selectedOptions.find(
      (s) => s.label === "Other"
    );
    const instrument = music_selectedOptions.find(
      (s) => s.label === "Playing an instrument"
    );

    const wrapAt = Math.ceil(list.length / 2);

    return (
      <div
        className={`hobbies-music_check-box-list flex-column ${
          extraClass || ""
        }`}
      >
        <div className="title is-completed-green">{title}</div>
        {error && <div className="right-block-title__error-field">{error}</div>}
        <div
          className={"hobbies-music_check-box-list__wrapped-list"}
          style={{
            gridTemplateRows: `repeat(${wrapAt}, auto)`,
          }}
        >
          {list.map((li, l) => {
            if (
              li.label === "Concerts" ||
              li.label === "Music festivals" ||
              li.label === "Symphony" ||
              li.label === "Singing" ||
              li.label === "Playing an instrument"
            ) {
              const isChecked = !!music_selectedOptions.find(
                (so) => so.label === li.label
              );
              if (li.label === "Playing an instrument") {
                return (
                  <InstrumentCheckBox
                    labelName={li.label}
                    className={l === list.length - 1 ? "last-element" : ""}
                    key={l}
                    listElement={li}
                    placeholder="Add instrument(s)"
                    hasInputText={true}
                    returnInputValue={(val) =>
                      val.value !== "Playing an instrument"
                        ? this.updateMusicOptions(
                            music_selectedOptions,
                            val,
                            "Playing an instrument"
                          )
                        : this.updateMusicOptions(
                            music_selectedOptions,
                            { ...val, value: null },
                            "Playing an instrument"
                          )
                    }
                    selectedInput={instrument ? instrument.value : null}
                    clicked={
                      isChecked
                        ? () => {
                            this.setState({
                              music_selectedOptions:
                                music_selectedOptions.filter(
                                  (so) => so.label !== li.label
                                ),
                            });
                            music_stateCallback(
                              music_selectedOptions.filter(
                                (so) => so.label !== li.label
                              )
                            );
                          }
                        : () => {
                            this.setState({
                              music_selectedOptions: [
                                ...music_selectedOptions,
                                li,
                              ],
                            });
                            music_stateCallback([...music_selectedOptions, li]);
                          }
                    }
                    checked={isChecked}
                  />
                );
              } else {
                const isOtherLabel = li.label === "Other";
                return (
                  <CheckBox
                    labelName={li.label}
                    className={l === list.length - 1 ? "last-element" : ""}
                    key={l}
                    listElement={li}
                    hasInputText={isOtherLabel}
                    returnInputValue={(val) =>
                      val.value !== "Other"
                        ? this.updateMusicOptions(
                            music_selectedOptions,
                            val,
                            "Other"
                          )
                        : this.updateMusicOptions(
                            music_selectedOptions,
                            { ...val, value: null },
                            "Other"
                          )
                    }
                    resetInputValue={isChecked.label !== "Other"}
                    selectedInput={other_textVal ? other_textVal.value : null}
                    clicked={
                      isChecked
                        ? () => {
                            this.setState({
                              music_selectedOptions:
                                music_selectedOptions.filter(
                                  (so) => so.label !== li.label
                                ),
                            });
                            music_stateCallback(
                              music_selectedOptions.filter(
                                (so) => so.label !== li.label
                              )
                            );
                          }
                        : () => {
                            this.setState({
                              music_selectedOptions: [
                                ...music_selectedOptions,
                                li,
                              ],
                            });
                            music_stateCallback([...music_selectedOptions, li]);
                          }
                    }
                    checked={isChecked}
                  />
                );
              }
            } else {
              const isChecked = !!hobbies_selectedOptions.find(
                (so) => so.label === li.label
              );
              const isOtherLabel = li.label === "Other";
              return (
                <CheckBox
                  labelName={li.label}
                  className={l === list.length - 1 ? "last-element" : ""}
                  key={l}
                  listElement={li}
                  hasInputText={isOtherLabel}
                  returnInputValue={(val) =>
                    val.value !== "Other"
                      ? this.updateHobbyOptions(
                          hobbies_selectedOptions,
                          val,
                          "Other"
                        )
                      : this.updateHobbyOptions(
                          hobbies_selectedOptions,
                          { ...val, value: null },
                          "Other"
                        )
                  }
                  resetInputValue={isChecked.label !== "Other"}
                  selectedInput={other_textVal ? other_textVal.value : null}
                  clicked={
                    isChecked
                      ? () => {
                          this.setState({
                            hobbies_selectedOptions:
                              hobbies_selectedOptions.filter(
                                (so) => so.label !== li.label
                              ),
                          });
                          hobbies_stateCallback(
                            hobbies_selectedOptions.filter(
                              (so) => so.label !== li.label
                            )
                          );
                        }
                      : () => {
                          this.setState({
                            hobbies_selectedOptions: [
                              ...hobbies_selectedOptions,
                              li,
                            ],
                          });
                          hobbies_stateCallback([
                            ...hobbies_selectedOptions,
                            li,
                          ]);
                        }
                  }
                  checked={isChecked}
                />
              );
            }
          })}
        </div>
      </div>
    );
  }

  updateHobbyOptions = (selectedOptions, val, label) => {
    console.log(val);
    this.props.hobbies_returnInputValue([
      ...selectedOptions.filter((o) => o.label !== label),
      val,
    ]);
    this.setState({
      selectedOptions: [
        ...selectedOptions.filter((o) => o.label !== label),
        val,
      ],
    });
  };
  updateMusicOptions = (selectedOptions, val, label) => {
    this.props.music_returnInputValue([
      ...selectedOptions.filter((o) => o.label !== label),
      val,
    ]);
    this.setState({
      selectedOptions: [
        ...selectedOptions.filter((o) => o.label !== label),
        val,
      ],
    });
  };
}

HobbiesMusicCheckboxList.propTypes = {
  title: PropTypes.string,
  extraClass: PropTypes.string,
  error: PropTypes.any,

  list: PropTypes.array,

  hobbies_list: PropTypes.array,
  hobbies_stateCallback: PropTypes.func,
  hobbies_selectedValue: PropTypes.any,
  hobbies_returnInputValue: PropTypes.func,

  music_list: PropTypes.array,
  music_stateCallback: PropTypes.func,
  music_selectedValue: PropTypes.any,
  music_returnInputValue: PropTypes.func,
};

export default HobbiesMusicCheckboxList;
