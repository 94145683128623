@import "../../scss/variables.scss";

.forgot-password__main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 4rem 0;
  width: 320px;
  align-self: center;

  .forgot-password__title {
    font-size: 2.25rem;
    line-height: 2.875rem;
    color: #2a2d2e;
    margin: 0;
    text-transform: capitalize;
  }

  .forgot-password__divider {
    width: 6.25rem;
    height: 0.5rem;
    border-radius: 10rem;
    background-color: $bright-teal;
  }

  .forgot-password__success-message-container {
    margin-top: .5rem;
    width: fit-content;
    height: 60px;
    padding: 20px;
    background-color: $tcolor3;
    box-sizing: border-box;
    text-align: center;

    .forgot-password__success-message {
      margin: 0;
      color: $greyDarker;
      font-size: 14px;
      white-space: nowrap;
    }
  }

  .forgot-password__email-input {
    margin-top: .5rem;
    height: 52px !important;
  }

  .forgot-password__submit-button {
    width: 70%;
  }

  .forgot-password__subtitle {
    margin-top: .5rem;
    font-weight: 600;
    color: $greyDarker;
    font-size: 1rem;
  }
}