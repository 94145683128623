@import "../../../../src/scss/variables";
@import "../../../../src/scss/utilities";

.hobbies-music_check-box-list {
  font-family: Montserrat, sans-serif;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &__wrapped-list {
    display: grid;
    grid-auto-flow: column;
    gap: 0.5rem;

    @include for-desktop {
      grid-template-columns: 250px auto;
    }

    @include for-tablet-down {
      grid-auto-flow: unset;
    }
  }

  &.no-padding-bottom {
    padding-bottom: 0;
  }

  &.half-size-list {
    .checkbox-list-items {
      flex-wrap: wrap;
      max-height: 900px;
      gap: 0.5rem;

      @include for-mobile {
        max-height: unset;
        display: grid;
        grid-template-columns: 1fr;
      }

      .input-checkbox {
        width: 50%;

        @include for-mobile {
          width: 100%;
        }
      }
    }
  }

  .title {
    color: #ff6666;
    font-size: 18px;
    font-weight: 500;
    &.is-completed-green {
      color: $teal;
    }
  }
}
