@import "../../../../src/scss/variables";

.statistical-area {
  &__top-container {
    display: flex;
    flex-direction: column;
    gap: .5rem;
  }

    .add-area {
        color: $teal;
        cursor: pointer;
        font-family: Montserrat, sans-serif;
        font-size: 16px;
        margin: 7px auto 30px;
        user-select: none;
        span:first-child {
            margin-right: 5px;
        }
        span:nth-child(2) {
            text-decoration: underline;
        }
    }
}
