import Cookies from "js-cookie";
import { STORAGE_KEY_SESSION } from "../../constants/index";
import { removeDataFromLocalStorage } from "../utils/local-storage";

const UserSession = {
  /**
   * Sets an auth token to storage
   *
   * @param {string} token user token
   * @param {object} user user for which the session is made
   * @param {boolean} [keepMeLoggedIn] keep me logged in param // to do functionality
   */
  setToken(token, user, keepMeLoggedIn) {
    if (user) {
      const session = {
        token: token,
        id: user._id,
        email: user.email,
        role: user.role,
        firstName: user.firstName,
        lastName: user.lastName,
        ambassadorAccount: user.ambassadorAccount || false,
        referId: user.referId || "",
        // images: user.images,
        keepMeLoggedIn: keepMeLoggedIn,
        setAt: new Date(),
      };
      const options = { expires: 7 };
      if (keepMeLoggedIn) {
        options.expires = 365;
      }
      Cookies.set(STORAGE_KEY_SESSION, JSON.stringify(session), options);
    }
  },
  /**
   * Returns the auth token if exists and it's still valid or NULL
   * @returns { object } session
   */
  getToken() {
    const session = JSON.parse(Cookies.get(STORAGE_KEY_SESSION) || null);
    if (session) {
      // Check if temporary cookie was set more than 12 hours ago
      const twelveHours = 43200000;
      const cookieExpired =
        !session.keepMeLoggedIn &&
        new Date() - new Date(session.setAt) > twelveHours;
      if (cookieExpired) {
        // Clear the token to automatically log out the user
        // The timeout is for avoiding "reducers may not dispatch actions" error
        setTimeout(() => {
          this.logout();
        }, 10);

        return {};
      }
      return session;
    }
    return {};
  },

  /** Clears the auth token from storage + removes also user data */
  clearToken() {
    Cookies.remove(STORAGE_KEY_SESSION);
  },

  /** On logout, clear user data, clear the cookie, and call logout action */
  logout() {
    UserSession.clearToken();
    removeDataFromLocalStorage("onBoardingData");
    removeDataFromLocalStorage("activeUser");
    removeDataFromLocalStorage("currentSection");
    window.location.href = "/";
  },
  /**
   *  Verifies if is there any valid token
   * @returns { object } session
   */
  hasValidToken() {
    const user = this.getToken();
    const token = user && user.token;
    return !!token;
  },
};

export default UserSession;
