@import "src/scss/utilities.scss";

.hero-section {
  height: 645px;
}

// Mobile
@include for-mobile {
  .hero-section {
    height: 100% !important;
  }
}