import React from "react";
import {
  getGlobal,
  getSetting,
  setSetting,
  DraggableLink,
  Input,
  Collapsible,
  reloadSettings,
  Modal,
  confirm,
} from "launchpad";
// import { NavLink } from 'react-router-dom';
import styled from "styled-components";
import { getCachedRouteInfo, getCachedTitles } from "launchpad/routes";
import { Link } from "widgets";
import {
  getLinkId,
  registerMenu,
  unregisterMenu,
  resetMenus,
  checkMenus,
  removeChildren,
  setMenu,
  getMenu,
  menus,
  targetLink,
  activeLink,
  setTarget,
  setActive,
} from "./menu_logic";
import { DynamicMenu, MenuContainer } from "./DynamicMenu";

export default class MenuLink extends React.Component {
  state = {
    highlighted: false,
    submenuOpen: false,
  };

  getMenu = () => {
    return getMenu(this.props.menuId);
  };

  getSubmenuId = () => {
    return this.props.menuId + "=>" + this.props.link.base;
  };

  remove = () => {
    confirm(
      "Are you sure you want to remove this menu item?",
      () => {
        menus[this.props.menuId].forEach((m) => {
          m.removeLink(this.props.link, m.save);
        });
      },
      "Remove"
    );
  };

  // add this link to the active/highlighted menu
  drop = (e) => {
    if (this.props.addLink) this.props.addLink();
    this.setState({ highlighted: false });
  };

  // set this link as the target (for sorting)
  highlight = (e) => {
    e.preventDefault();
    if (!targetLink || targetLink.id !== this.props.link.id) {
      setTarget("link", this.props.link);
      this.props.onHighlight();
    }
  };

  editSubmenu = () => {
    this.setState({ edit: true });
    setActive("menu", this.getSubmenuId());
  };

  quitEditSubmenu = () => {
    this.setState({ edit: false });
    setActive("menu", null);
  };

  openSubmenu = (e) => {
    e.stopPropagation();
    this.setState({ submenuOpen: !this.state.submenuOpen });
  };

  render() {
    const titleMap = getCachedTitles();
    const infoMap = getCachedRouteInfo();

    const { link, layout } = this.props;
    const info = infoMap[link.pageId] || {};
    const submenu = getMenu(this.getSubmenuId());
    const is_vertical = layout === "vertical";
    const property = is_vertical ? "paddingTop" : "paddingLeft";
    const hasSubmenu = submenu && submenu.length > 0;
    if (this.el && !this.offset) {
      this.offset = is_vertical
        ? (this.el && this.el.offsetHeight) || 50
        : (this.el && this.el.offsetWidth) || 100;
    }
    const xy = getGlobal("activeLinkDimensions") || { width: 0, height: 0 };
    const offset = (is_vertical ? xy.height : xy.width) / 3;
    //const style = this.props.highlighted ? {[property]: offset+'px'} : {}
    const isInserted = (activeLink && activeLink.id) === link.id;
    const style = isInserted ? { padding: offset + "px" } : {};

    const subIconStyle = this.state.submenuOpen
      ? { transform: "rotate(90deg)" }
      : { transform: "rotate(0deg)" };

    const submenuLinks =
      submenu &&
      submenu.length &&
      (this.props.toggleSubmenu ? (
        <DynamicMenu layout="vertical" menuId={this.getSubmenuId()} />
      ) : (
        <ul className="sub-menu">
          {submenu.map((l) => (
            <li key={l.id}>
              <Link draggable="false" to={l.url || "#"}>
                {l.title || titleMap[l.url] || l.base}
              </Link>
            </li>
          ))}
        </ul>
      ));

    return (
      <li
        className={"dynamic-link-container" + (isInserted ? " inserted" : "")}
        onDragOver={this.highlight}
        onDrop={this.drop}
      >
        <DraggableLink
          link={link}
          hideWhileDragging
          ref={(el) => (this.el = el && el.el)}
          onStartDrag={this.props.onStartDrag}
          onStopDrag={this.props.onStopDrag}
          className={
            "menu-link " +
            (this.props.highlighted ? "highlighted" : "") +
            (is_vertical ? "vertical" : "")
          }
          style={Object.assign({}, this.props.style || {})}
        >
          <Link draggable="false" to={info.url || link.url || "#"}>
            {link.title || info.title || titleMap[link.url] || link.base}
          </Link>
          {hasSubmenu && this.props.toggleSubmenu && (
            <span
              className="fa fa-chevron-right openMenu"
              style={subIconStyle}
              onClick={this.openSubmenu}
            />
          )}
        </DraggableLink>

        {getGlobal("is_admin") && (
          <div className="menu-tools">
            <div className="fa fa-close" onClick={this.remove} />
            {!this.props.isSubmenu && (
              <div className="fa fa-chevron-down" onClick={this.editSubmenu} />
            )}
          </div>
        )}
        {hasSubmenu && !this.props.toggleSubmenu && submenuLinks}
        {hasSubmenu && this.props.toggleSubmenu && (
          <Collapsible open={this.state.submenuOpen}>
            {submenuLinks}
          </Collapsible>
        )}

        {getGlobal("is_admin") && (
          <Modal
            key={"submenu" + this.getSubmenuId()}
            id={"submenu-" + this.getSubmenuId()}
            open={this.state.edit}
            onClose={this.quitEditSubmenu}
          >
            <MenuContainer className="submenu-edit">
              {this.state.edit && (
                <DynamicMenu layout="vertical" menuId={this.getSubmenuId()} />
              )}
            </MenuContainer>
          </Modal>
        )}
      </li>
    );
  }
}

// if(module.hot) module.hot.accept()
