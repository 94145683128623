import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as authActions from "../../actions/authentication";
import * as generalActions from "../../actions/generalActions";
import SignUpForm from "../../components/sign-up-form/matchmakers";
import logo from "../../components/main-header/assets/date-spot-logo.svg";
import Button from "../../components/button/index";
import "../style.scss";

class MatchMakersSignUpPage extends Component {
  state = {
    signedUpUser: false,
  };

  signUpCallback = (newUser) => {
    if (newUser) {
      this.setState({ signedUpUser: true });
    }
  };

  render() {
    const { history, errors } = this.props;
    const { signUpMatchMaker } = this.props.authActions;
    const { isSignedUpMatchMaker } = this.props.authenticationReducer;
    const comesFromAdmin = history.location.section === "admin";
    return (
      <div className="matchmaker-sign-up-container flex-row">
        <div className="left-section flex-column">
          <div className="logo-and-arrow flex-row">
            <span
              className="icon-back-button"
              onClick={() => {
                this.props.authActions.resetSignupFlag();
                comesFromAdmin
                  ? history.push("/")
                  : history.push("/for-matchmakers");
              }}
            />
            <div className="date-spot-logo flex-center">
              <img src={logo} />
            </div>
          </div>
          {isSignedUpMatchMaker ? (
            <div className="welcome-to-the-team flex-column">
              <span className="title">{`Thanks for signing up to be a partner!`}</span>
              {/* <span className='title'>{`signing up to be a`}</span>
                            <span className='title'>{`partner`}</span> */}
              {comesFromAdmin ? (
                <span className="text">
                  {`You’ve created a Matchmaker account. Matchmaker is already approved in Matchmakers tab `}
                </span>
              ) : (
                <div className="flex-column">
                  {/* <span className='text' style={{ padding: '30px 0'}}>
                                    {`We’ll check out your website and be in touch via email or phone within
                                     48 hours to confirm what you do
                                    and verify your occupation. To protect the privacy of our members,
                                     we want to make sure everyone who has access to the database is a legitimate
                                     matchmaking professional.`}
                                    </span>
                                    <span className='text' style={{ padding: '0 0 30px 0'}}>
                                    {`We hope to be working with you and making your life easier soon!`}
                                    </span> */}
                  <span className="text" style={{ padding: "30px 0" }}>
                    {`You should receive an email shortly to talk about your business. Please respond with your availability. We hope to be working with you soon!`}
                  </span>
                  {/* <span className='text' style={{ padding: '0 0 30px 0'}}>
                                        {`We hope to be working with you soon!`}
                                    </span> */}
                </div>
              )}
              <Button
                value="Back To The Datespot Homepage"
                click={() => {
                  history.push("/");
                  this.props.authActions.resetSignupFlag();
                }}
                customClass="yellow back-to-datespot"
              />
            </div>
          ) : (
            <SignUpForm
              errors={errors}
              comesFromAdmin={comesFromAdmin}
              signUpUser={signUpMatchMaker}
              authActions={this.props.authActions}
              history={history}
              signUpCallback={this.signUpCallback.bind(null)}
              isMatchMakerForm={true}
            />
          )}
        </div>
        <div className="right-section flex-column">
          <div className="sign-up-background flex-column">
            <div className="couple-info flex-column flex-center">
              <span>Jacky &amp; Tim</span>
              <span className="float-right">NYC</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MatchMakersSignUpPage.propTypes = {
  history: PropTypes.object,
  authActions: PropTypes.object,
  generalActions: PropTypes.object,
  errors: PropTypes.object,
  authenticationReducer: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    generalReducer: state.generalReducer,
    authenticationReducer: state.authenticationReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MatchMakersSignUpPage);
