import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import * as adminActions from "../../../../actions/adminActions";
import * as generalActions from "../../../../actions/generalActions";
import DropDownComponent from "../../../../components/drop-down";
import Button from "../../../../components/button";
import InputText from "../../../../components/input-text";
import Checkbox from "../../../../components/check-box";

class MakerDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      saving: false,
      matchmaker: null,
    };
  }

  componentDidMount() {
    const { approvedMatchMakers, plans } = this.props.adminReducer;
    // document.body.classList.remove('no-scroll');
    if (!approvedMatchMakers || approvedMatchMakers.length === 0) {
      this.props.generalActions.loadMatchmakers();
    } else {
      this.loadCurrentMaker();
    }
    if (!plans) {
      adminActions.loadPlans();
    }
  }

  componentWillUnmount() {
    // document.body.classList.add('no-scroll');
  }

  componentDidUpdate() {
    const { approvedMatchMakers } = this.props.adminReducer;
    if (
      !this.state.matchmaker &&
      approvedMatchMakers &&
      approvedMatchMakers.length
    ) {
      this.loadCurrentMaker();
    }
  }

  /** Loads the current matchMaker based on the ID from the URL */
  loadCurrentMaker() {
    const { approvedMatchMakers } = this.props.adminReducer;
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    let maker;
    if (!id) {
      maker = {};
    } else {
      maker = Object.assign(
        {},
        approvedMatchMakers.find((item) => item._id === id)
      );
      maker.plan = maker.plan ? maker.plan._id : null;
    }
    this.setState({ matchmaker: { ...maker } });
  }

  /**
   * Triggered when the plan drop-down value changes
   * @param {object} plan new plan assigned
   */
  onSelectPlan(plan) {
    const { matchmaker } = this.state;
    matchmaker.plan = plan.value;
    this.setState({ matchmaker });
  }

  /**
   * Triggered when the value changes for credits.
   * @param {object} credits new credits assigned
   */
  onChangeCredits(credits) {
    const { matchmaker } = this.state;
    if (isNaN(credits)) {
      console.log("input must be a number...");
    } else {
      matchmaker.referralCredits = +credits;
      this.setState({ matchmaker });
    }
  }

  onReferId(id) {
    const { matchmaker } = this.state;
    matchmaker.referId = id;
    this.setState({ matchmaker });
  }

  onChangeAmbassador() {
    const { matchmaker } = this.state;
    if (!matchmaker.ambassadorAccount) {
      matchmaker.ambassadorAccount = true;
      this.setState({ matchmaker });
    } else {
      matchmaker.ambassadorAccount = !matchmaker.ambassadorAccount;
      this.setState({ matchmaker });
    }
  }

  save = () => {
    this.setState({ saving: true });
    adminActions.updateMatchMaker(this.state.matchmaker).then((res) => {
      this.setState({ saving: false });
      if (!res.data.error) {
        this.props.generalActions.loadMatchmakers();
        this.props.history.push("/matchmakers-list");
      }
    });
  };

  render() {
    const { matchmaker } = this.state;
    const { plans, approvedMatchMakers } = this.props.adminReducer;
    if (!approvedMatchMakers || !matchmaker) {
      return <div className="ds-loader" />;
    }

    const listOfValues = [
      {
        label: "Remove Assigned Plan",
        value: null,
      },
    ];
    let selected = { label: "No Assigned Plan", value: null };
    plans &&
      plans.forEach((plan) => {
        const option = {
          label: plan.name,
          value: plan.id,
        };
        if (matchmaker.plan && matchmaker.plan === plan.id) {
          selected = option;
        }
        listOfValues.push(option);
      });

    const renderForm = this._renderForm(matchmaker, listOfValues, selected);
    return (
      <div className="ds-details ds-form">
        <div className="ds-details__header">
          <span
            className="icon-back-button"
            onClick={() => {
              this.props.history.push("/matchmakers-list");
            }}
          />
          <div className="ds-details__header-title">
            Edit {matchmaker.firstName} {matchmaker.lastName}
          </div>
        </div>
        <hr />

        {renderForm}
      </div>
    );
  }

  _renderForm = (matchmaker, listOfValues, selected) => {
    return (
      <div className="ds-details__form">
        <div className="flex-column">
          <div className={"right-block-title is-completed-blue"}>Name</div>
          <div className={"ds-details__text"}>
            {matchmaker.firstName} {matchmaker.lastName}
          </div>
        </div>
        {/* <div className="flex-column">
                    <div className={"right-block-title is-completed-blue"}>
                        Assigned plan
                    </div>
                    <DropDownComponent
                        listOfValues={listOfValues}
                        selectedValue={selected}
                        onSelectValue={this.onSelectPlan.bind(this)}
                    />
                </div> */}
        <div className="flex-column">
          <div className={"right-block-title is-completed-blue"}>Phone no.</div>
          <div className={"ds-details__text"}>{matchmaker.phone}</div>
        </div>
        <div className="flex-column">
          <div className={"right-block-title is-completed-blue"}>Approved</div>
          <div className={"ds-details__text"}>
            {matchmaker.approved ? "Yes" : "No"}
          </div>
        </div>
        <div className="flex-column">
          <div className={"right-block-title is-completed-blue"}>
            Member since
          </div>
          <div className={"ds-details__text"}>
            {moment(matchmaker.createdAt).format("DD MMM YYYY")}
          </div>
        </div>
        {/* <div className="flex-column">
                    <div className={"right-block-title is-completed-blue"}>
                        Referral Credits
                    </div>
                    <div className={"ds-details__text"}>
                        <InputText
                            onChange={(e) =>
                                this.onChangeCredits(e.target.value)
                            }
                            value={matchmaker.referralCredits}
                        />
                    </div>
                </div> */}
        {/* <div className="flex-column">
          <div className={"right-block-title is-completed-blue"}>Refer ID</div>
          {`https://datespot.love/?referrer=`}
          <div className={"ds-details__text"}>
            <InputText
              onChange={(e) => this.onReferId(e.target.value)}
              value={matchmaker.referId}
            />
          </div>
        </div> */}
        <div className="flex-column">
          <div className={"right-block-title is-completed-blue"}>
            No database access
          </div>
          <div className={"ds-details__text"}>
            <Checkbox
              clicked={() => this.onChangeAmbassador()}
              checked={matchmaker.ambassadorAccount}
            />
          </div>
        </div>
        <Button
          value={"Save"}
          click={this.save.bind(this)}
          disabled={this.state.saving}
        />
      </div>
    );
  };
}

MakerDetails.propTypes = {
  history: PropTypes.object.isRequired,
  adminActions: PropTypes.object.isRequired,
  generalActions: PropTypes.object.isRequired,
  adminReducer: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    adminActions: bindActionCreators(adminActions, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch),
  };
};

const mapStateToProps = (state) => {
  return {
    adminReducer: state.adminReducer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MakerDetails);
