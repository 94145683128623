/*
  launchpad overview: templates

  Templates are components that can be used to generate pages dynamically,
  e.g. a product detail page, which might accept a route like `/products/:id`.
  If a component is only used to generate a particular page, consider adding it
  to the `components/pages` directory instead, where it will be automatically
  integrated with the CMS and router. Templates will have to be integrated
  manually (at least for now)

*/

import React from "react";
import {
  Snip,
  Image,
  ImageGetter,
  getGlobal,
  setGlobal,
  Input,
  getSetting,
  setSetting,
  Modal,
  DataStore,
  CheckBox,
  confirm,
  Collapsible,
  helpers,
} from "launchpad";
import { Page } from "launchpad";
import Fade, { FadeBlocks } from "react-move-stuff";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { ContentBlock, CTA, Table, Hero } from "sections";
import { Link, Title, ShareIcons } from "widgets";
import { savePage } from "pages/admin/PageManager";

const blockTypes = {
  content: ContentBlock,
  hero: Hero,
  table: Table,
  cta: CTA,
};

/*
  launchpad component: CustomPage
  categories: templates

  DO NOT REMOVE THIS TEMPLATE if you are planning on using launchpad's builtin
  page builder, this template is used by launchpad to generate custom pages used
  in

*/

// in lieu of full blown page builder
const defaultBlocks = [
  // {id: 1, type: 'hero', name: 'Hero'},
  { id: 2, type: "content", name: "Content Block 1" },
];

export default class CustomPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      slug: "",

      // temp containers, only used when layout are updated
      layout: null,
      blocks: null,
    };
  }

  get page() {
    return (getGlobal("customPages") || []).find(
      (x) => x._id === this.props.page._id
    );
  }

  getLayout = () => {
    const { layout } = this.state;
    const p = this.page;
    // console.log(p)
    const blocklist = layout && layout.length ? layout : p.layout;
    if (blocklist.length === 1 && blocklist[0] === "empty") return [];
    const defaultLayout =
      p.type === "blog"
        ? [{ id: 2, type: "content", name: "Content Block 1" }]
        : defaultBlocks;
    if (!blocklist || !blocklist.length) return defaultLayout;
    return blocklist;
  };

  setLayout = (layout) => {
    if (layout.length === 0) layout = ["empty"];
    this.setState({ layout }, () => {
      savePage(Object.assign({}, this.page, { layout }), { skipRefresh: true });
    });
  };

  getBlocks = () => {
    if (this.state.blocks) return this.state.blocks;
    const b = this.page.blocks || [];
    const layout =
      b.length && (b.length > 1 || b[0] !== "empty") ? b : defaultBlocks;
    return layout.filter((x) => x !== "empty");
  };

  setBlocks = (layout, blocks, maxBlockId) => {
    maxBlockId = maxBlockId ? { maxBlockId } : {};
    this.setState({ layout, blocks }, () => {
      savePage(Object.assign({}, this.page, { layout, blocks }, maxBlockId), {
        skipRefresh: true,
      });
    });
  };

  addBlock = (type) => {
    let layout = this.getLayout();
    let blocks = this.getBlocks();
    const maxBlockId = this.page.maxBlockId + 1;
    const newBlock = { id: maxBlockId, type, name: helpers.getTitle(type) };
    layout.push(newBlock);
    blocks.push(newBlock);
    this.setBlocks(layout, blocks, maxBlockId);
  };

  remove = (block) => {
    confirm("Are you sure you want to remove this block?", () => {
      let layout = this.getLayout();
      let blocks = this.getBlocks();
      layout = layout.filter((x) => x.id !== block.id);
      blocks = blocks.filter((x) => x.id !== block.id);
      this.setBlocks(layout, blocks);
    });
  };

  moveBlock = (block, dir) => {
    let blocks = this.getBlocks();
    block = blocks.find((x) => x.id === block.id);
    const i = blocks.indexOf(block);
    blocks = blocks.filter((x) => x.id !== block.id);
    blocks.splice(i + dir, 0, block);
    let layout = [];
    blocks.forEach((b) => {
      if (this.getLayout().find((x) => x.id === b.id)) {
        layout.push(b);
      }
    });
    this.setBlocks(layout, blocks);
  };

  toggleBlock = (block) => {
    const layout = this.getLayout();
    let newBlocks = [];
    if (layout.find((x) => x.id === block.id)) {
      newBlocks = layout.filter((x) => x.id !== block.id);
    } else {
      this.getBlocks().forEach((b) => {
        if (layout.find((x) => x.id === b.id) || b.id === block.id) {
          newBlocks.push(b);
        }
      });
    }
    this.setLayout(newBlocks);
  };

  getContentBlock = (block) => {
    const layout = blockTypes;
    const Component = layout[block.type];
    const id = `${block.type}${block.id}`;

    return (
      <Component
        key={id}
        className={block.type === "hero" && "half"}
        page={this.props.page._id}
        name={id}
      >
        {block.type === "hero" && (
          <Snip default="<h2></h2>" name={`hero-text-${id}`} />
        )}
      </Component>
    );
  };

  showAdd = () => {
    if (!this.state.showAdd) this.setState({ showAdd: true });
  };

  hideAdd = () => {
    if (this.state.showAdd) this.setState({ showAdd: false });
  };

  render() {
    const { page } = this.props;
    const { authorData } = page;
    const date = new Date(
      page.posted_date || page.date_created
    ).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    const layout = this.getLayout();
    const excludedBlogTypes = ["table"];
    const availableBlocks = Object.keys(blockTypes).filter(
      (x) => page.type !== "blog" || !excludedBlogTypes.includes(x)
    );
    return (
      <Page id={page._id} className={`launchpad-custom-page ${page.type}`}>
        {page.type === "blog" && (
          <div className="blog-header container">
            <div className="bread-crumbs">
              <Link href="/blog">Blog</Link>{" "}
              <span className="fa fa-chevron-right" /> {page.title}
            </div>
            <Title>{page.title}</Title>
            <div className="attribution">
              {authorData && (
                <>
                  <Attribution author={authorData} />
                  <span className="separator" />
                </>
              )}
              <span className="date">{date}</span>
            </div>
            {!getGlobal("is_admin") && <ShareIcons />}
          </div>
        )}
        {this.getBlocks().map((block) => {
          const show = !!layout.find((x) => x.id === block.id);
          return (
            <div key={block.id} className="page-section">
              {getGlobal("is_admin") ? (
                <div className={`section-edit ${show ? "shown" : ""}`}>
                  <Collapsible open={show}>
                    {this.getContentBlock(block)}
                  </Collapsible>

                  <div className="toggle-section">
                    <div>
                      <button
                        key={block.id}
                        onClick={() => this.toggleBlock(block)}
                      >
                        <em className="material-icons">
                          {show ? "remove" : "add"}
                        </em>
                        {show ? `Hide ${block.name}` : `Show ${block.name}`}
                      </button>
                    </div>

                    <div className="options">
                      <button onClick={() => this.moveBlock(block, -1)}>
                        <em className="fa fa-chevron-up"></em>
                      </button>
                      <button onClick={() => this.moveBlock(block, 1)}>
                        <em className="fa fa-chevron-down"></em>
                      </button>
                      <button onClick={() => this.remove(block)}>
                        <em className="fa fa-times"></em>
                      </button>
                    </div>
                  </div>
                </div>
              ) : show ? (
                this.getContentBlock(block)
              ) : (
                <div />
              )}
            </div>
          );
        })}
        {getGlobal("is_admin") && (
          <div
            className="add-bar"
            onMouseEnter={this.showAdd}
            onMouseLeave={this.hideAdd}
          >
            <div
              className="add-button"
              onClick={() => this.setState({ showAdd: !this.state.showAdd })}
            >
              <span className="fa fa-plus"></span>
            </div>
            <Collapsible className="add-layout" open={this.state.showAdd}>
              {availableBlocks.map((x) => (
                <div key={x} onClick={() => this.addBlock(x)}>
                  {helpers.getTitle(x)}
                </div>
              ))}
            </Collapsible>
          </div>
        )}
      </Page>
    );
  }
}

const Attribution = ({ author }) => {
  const { name, title, company } = author;
  return (
    <>
      <span>{name}</span>
      {company && (
        <>
          <span className="separator" />
          <span>
            {title}
            {title && company && " at "}
            {company}
          </span>
        </>
      )}
    </>
  );
};
