import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import moment from "moment";
import * as generalActions from "../../../actions/generalActions";
import * as authenticationActions from "../../../actions/authentication";
import * as singlesActions from "../../../actions/singlesActions";
import TheBasics from "./the-basics";
import YourPartner from "./your-partner";
import Photos from "./photos";
import Settings from "./account-settings";
import Modal from "../../../components/modal/index";
import CheckBox from "../../../components/check-box/index";
import UserSession from "../../../components/user-session";
import Button from "../../../components/button/index";
import { apiPost } from "launchpad";

import "../style.scss";
import UserInfoHeader from "src/components/UserInfoHeader";
import DropdownMenu from "src/components/DropdownMenu";
import Avatar from "src/components/Avatar";
import OtherSection from "src/components/singles-sections/OtherSection";
import FamilySection from "src/components/singles-sections/FamilySection";
import LifestyleSection from "src/components/singles-sections/LifestyleSection";
import BackgroundSection from "src/components/singles-sections/BackgroundSection";

const SECTIONS_OPTIONS = [
  {
    text: "Basics",
    completed: false,
    value: "theBasics",
  },
  {
    text: "Photos",
    completed: false,
    value: "photos",
  },
  {
    text: "Background",
    completed: false,
    value: "background",
  },
  {
    text: "Lifestyle",
    completed: false,
    value: "lifestyle",
  },
  {
    text: "Family",
    completed: false,
    value: "family",
  },
  {
    text: "Your Partner",
    completed: false,
    value: "partners",
  },
  {
    text: "Other",
    completed: false,
    value: "other",
  },
  {
    text: "Account Settings",
    completed: false,
    value: "settings",
  },
];

class UserDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: "theBasics",
      agreeWithTermsAndPolicies: false,
      emailSent: false,
      isTabletDown: window.matchMedia("(max-width: 768px)").matches,
      sectionsOptions: SECTIONS_OPTIONS,
      onboardingSectionsDisabled: false,
    };
  }

  setOnboardingSectionsDisabledState = (newState) => {
    this.setState({
      onboardingSectionsDisabled: newState,
    });
  };

  mediaQueryListener = () => {
    this.setState({
      isTabletDown: window.matchMedia("(max-width: 768px)").matches,
    });
  };

  componentDidMount() {
    this.props.authenticationActions.getUserData();
    const cookie = document.cookie.split(";").reduce((res, c) => {
      const [key, val] = c.trim().split("=").map(decodeURIComponent);
      const allNumbers = (str) => /^\d+$/.test(str);
      try {
        return Object.assign(res, {
          [key]: allNumbers(val) ? val : JSON.parse(val),
        });
      } catch (e) {
        return Object.assign(res, {
          [key]: val,
        });
      }
    }, {});
    const cookieSetTime = cookie["date-spot-cookie-string"].setAt;
    const time = moment(cookieSetTime).format("X");
    this.setState({
      loginTime: time,
    });
    window.addEventListener("beforeunload", (e) => {
      this.returnLogoutTime();
      // Cancel the event
      e.preventDefault();
      // Chrome requires returnValue to be set
      return undefined;
    });

    window.addEventListener("resize", this.mediaQueryListener);

    this.handleSectionSearchParam();
  }

  componentDidUpdate(prevProps) {
    // Check if user has completed the basics section before moving to other section trough url
    const { profileCompletion: prevProfileCompletion } =
      prevProps.singlesReducer;
    const { profileCompletion: currProfileCompletion } =
      this.props.singlesReducer;

    if (prevProfileCompletion !== currProfileCompletion) {
      this.setState({
        sectionsOptions: SECTIONS_OPTIONS.map((option) => ({
          ...option,
          completed: currProfileCompletion[option.value] === 100,
        })),
      });
    }

    if (
      typeof currProfileCompletion.theBasics === "number" &&
      currProfileCompletion.theBasics < 100
    ) {
      const searchParams = new URLSearchParams(this.props.location.search);
      const sectionValue = searchParams.get("section");
      if (sectionValue !== "theBasics") this.changeSection("theBasics");
    }

    if (
      this.props.authenticationReducer.user?.user_id !==
      prevProps.authenticationReducer.user?.user_id
    ) {
      this.setState({
        onboardingSectionsDisabled:
          this.props.authenticationReducer.user?.relationshipStatus?.value ===
          "Not Single",
      });
    }
    this.handleSectionSearchParam();
  }

  handleSectionSearchParam = () => {
    const searchParams = new URLSearchParams(this.props.location.search);
    const sectionValue = searchParams.get("section");
    if (!sectionValue) {
      this.props.history.replace({
        search: `?section=${
          this.state.onboardingSectionsDisabled
            ? "settings"
            : this.state.selectedTab //Default value here is theBasics
        }`,
      });
      return;
    }
    if (
      this.state.selectedTab !== "settings" &&
      this.state.onboardingSectionsDisabled
    ) {
      this.changeSection("settings");
      return;
    }

    if (sectionValue === this.state.selectedTab) return;

    this.changeSection(sectionValue);
  };

  componentWillUnmount() {
    document.removeEventListener("beforeunload", () => {});

    document.removeEventListener("resize", this.mediaQueryListener);
  }

  returnLogoutTime = () => {
    const { emailSent } = this.state;
    const { profileCompletionPercentage } = this.props.singlesReducer;
    const completionPercentage = parseInt(profileCompletionPercentage, 10);
    if (!emailSent) {
      if (!completionPercentage || completionPercentage < 15) {
        this.sendUserEmails("newUserNoDataFilledIn");
      }
      this.setState({
        emailSent: true,
      });
    }
  };

  scrollToTop = (elementId) => {
    const rightPanel = document.getElementById(elementId);
    if (rightPanel) rightPanel.scrollTop = 0;
  };

  changeSection = (section) => {
    this.scrollToTop("right-panel");
    this.setState({
      selectedTab: section,
    });
    this.props.history.replace({
      search: `?section=${section}`,
    });
  };

  render() {
    const { user } = this.props.authenticationReducer;
    const {
      selectedTab,
      agreeWithTermsAndPolicies,
      isTabletDown,
      sectionsOptions,
      onboardingSectionsDisabled,
    } = this.state;
    const { isOpenTCModal, profileCompletionPercentage, profileCompletion } =
      this.props.singlesReducer;

    const basicsNotCompleted = profileCompletion.theBasics < 100;

    if (!user) {
      return <div className="ds-loader" />;
    }

    if (isOpenTCModal) {
      return this._renderAcceptTermsAndConditionsModal(
        agreeWithTermsAndPolicies,
        user,
        isOpenTCModal
      );
    }

    const desktopUserHeader = this._renderUserHeader(user);
    const progressBar = this._renderProgressBar(profileCompletionPercentage);
    const leftTabs = this._renderLeftTabs(
      selectedTab,
      basicsNotCompleted,
      profileCompletion,
      onboardingSectionsDisabled
    );
    const renderSections = this._renderSections(
      selectedTab,
      user,
      isTabletDown
    );

    const mobileSectionsOptions = sectionsOptions.filter(
      (section) => section.value !== "settings"
    );

    return (
      <div className="user-dashboard-container flex-column">
        <div className="flex-column user-dashboard-container__sections">
          {!isTabletDown && desktopUserHeader}
          <div
            className={isTabletDown ? "flex-column full-height" : "flex-row"}
            style={{
              height: isTabletDown ? "unset" : "calc(100% - 110px)",
            }}
          >
            {!isTabletDown && (
              <div className="user-dashboard-container__left-panel">
                {progressBar}
                {leftTabs}
              </div>
            )}
            {isTabletDown && (
              <div className="user-dashboard-container__sections__mobile-header">
                <UserInfoHeader
                  user={user}
                  progress={profileCompletionPercentage}
                />
                {selectedTab !== "settings" && (
                  <DropdownMenu
                    showCompleted
                    value={selectedTab}
                    onChange={this.changeSection.bind(this)}
                    options={mobileSectionsOptions}
                    disabled={basicsNotCompleted || onboardingSectionsDisabled}
                  />
                )}
              </div>
            )}
            <div
              id="right-panel"
              className="user-dashboard-container__right-panel"
            >
              {renderSections}
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderAcceptTermsAndConditionsModal = (
    agreeWithTermsAndPolicies,
    user,
    isOpenTCModal
  ) => {
    const modalContent = (
      <div className="flex-column">
        <span className="title">
          {" "}
          In order to proceed you need to accept the Terms and Conditions{" "}
        </span>{" "}
        <div className="agree-terms flex-row flex-vertical-center">
          <CheckBox
            checked={agreeWithTermsAndPolicies}
            clicked={() => {
              this.setState({
                agreeWithTermsAndPolicies: !agreeWithTermsAndPolicies,
              });
              this.props.singlesActions.saveUserFieldData(
                user._id,
                "acceptedTC",
                !agreeWithTermsAndPolicies
              );
            }}
          />
          I agree with the
          <span
            onClick={() =>
              this.props.history.push({
                pathname: "/terms-of-service",
              })
            }
            className="underlined"
          >
            {" "}
            Terms of Service{" "}
          </span>{" "}
          <span>
            {" "}
            and{" "}
            <span
              onClick={() =>
                this.props.history.push({
                  pathname: "/privacy-policy",
                })
              }
              className="underlined"
            >
              {" "}
              Privacy Policy.{" "}
            </span>{" "}
          </span>{" "}
        </div>{" "}
        <div className="flex-row">
          <Button
            value="CONTINUE"
            customClass="two-twenty-width"
            disabled={!agreeWithTermsAndPolicies}
            click={() => this.props.singlesActions.closeTCModal()}
          />{" "}
          <Button
            value="REJECT"
            customClass="redish two-twenty-width"
            click={() => UserSession.logout()}
          />{" "}
        </div>
      </div>
    );
    return (
      <Modal
        className="terms-and-conditions-modal"
        isOpen={isOpenTCModal}
        modalContent={modalContent}
      />
    );
  };

  _renderUserHeader = (user) => {
    const { profileCompletionPercentage } = this.props.singlesReducer;

    return (
      <div className="user-dashboard-container__header-first flex-row">
        <Avatar
          src={user.images && user.images[0]?.url}
          alt="Profile image"
          size="large"
          initials={
            user.firstName && user.lastName
              ? `${user.firstName[0]}${user.lastName[0]}`
              : ""
          }
        />
        <div className="flex-column profile-name profile-title">
          {profileCompletionPercentage >= 100
            ? "My Profile"
            : "Create Your Profile"}
          <span>
            {user.firstName} {user.lastName}
          </span>
        </div>
        <div className="profile-title float-right">
          <div className="question-tip">
            <p>
              All questions beyond Basics are optional. However, the more you
              fill in, the more often you'll be identified in searches and the
              better matches you'll receive.
            </p>
          </div>
        </div>
      </div>
    );
  };

  _renderProgressBar = (profileCompletionPercentage) => {
    return (
      <div
        style={{
          margin: "0 20px",
        }}
      >
        <div className="completion-level flex-row">
          <span className="icon-green-bird" />
          <span className="icon-yellow-bird float-right" />
        </div>
        {/*
        <div className="progress-bar">
          <span
            className="completion"
            style={{
              width: `${profileCompletionPercentage}%`,
            }}
          />{" "}
        </div>{" "}
        <div className="progress-bar__text flex-row">
          <span> Profile Completion </span>{" "}
          <span className="float-right"> {profileCompletionPercentage} % </span>{" "}
        </div>{" "}
        */}
      </div>
    );
  };

  _renderLeftTabs = (
    selectedTab,
    basicsNotCompleted,
    profileCompletion,
    onboardingSectionsDisabled
  ) => {
    const isSelectedTab = (section) => selectedTab === section;

    return (
      <div className="user-tabs flex-column">
        {SECTIONS_OPTIONS.map((tab) => (
          <div
            className={`user-dashboard-container__left-tab${
              onboardingSectionsDisabled ||
              (basicsNotCompleted && tab.value !== "theBasics")
                ? " disabled"
                : ""
            }${isSelectedTab(tab.value) ? " is-selected" : ""}`}
            onClick={() => this.changeSection(tab.value)}
            key={tab.value}
          >
            <div className="wrapper flex-row">
              <div className="tab-title"> {tab.text} </div>
              {profileCompletion[tab.value] < 100 && (
                <div className="bubble float-right" />
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };

  calculatePercentage = (componentState, nrOfFields, section) => {
    const { errors, validFields, ...stateWithoutProperties } = componentState;
    if (section === "photos") {
      singlesActions.setPercentage(1, 1, section);
    } else {
      let count = 0;
      Object.values(stateWithoutProperties).forEach((val, index) => {
        if (this.checkVal(val)) {
          count++;
        }
      });
      singlesActions.setPercentage(count, nrOfFields, section);
    }
  };

  sendUserEmails = (type) => {
    if (this.sent && !this.sent.includes(type)) {
      this.sent.push(type);
      const { user } = this.props.authenticationReducer;
      if (user) apiPost(`send-user-email/${user._id}/${type}`);
    }

    // const {
    //     sendNewUserEmails
    // } = this.props.singlesActions;
    // switch ( type ) {
    //     case 'addedPhotos':
    //         sendNewUserEmails( 'userAddedPhotos' );
    //         break;
    //     case 'userNoData':
    //         sendNewUserEmails( 'newUserNoDataFilledIn' );
    //         break;
    //     case 'userWithDataNoPhotos':
    //         sendNewUserEmails( 'newUserDataFilledInNoPhotos' );
    //         break;
    //     default:
    //         break;
    // }
  };

  checkVal = (val) => {
    let isValid = false;
    if (this.isString(val) && val !== "") {
      isValid = true;
    }
    if (this.isObject(val) && !this.isEmptyObject(val)) {
      isValid = true;
    }
    if (this.isArray(val) && val.length > 0) {
      isValid = true;
    }
    if (typeof val === "number") {
      isValid = true;
    }
    return isValid;
  };

  isString = (value) => {
    return typeof value === "string" || value instanceof String;
  };

  isObject = (value) => {
    return value && typeof value === "object" && value.constructor === Object;
  };

  isEmptyObject = (obj) => {
    return Object.entries(obj).length === 0 && obj.constructor === Object;
  };

  isArray = (value) => {
    return value && typeof value === "object" && value.constructor === Array;
  };

  _renderSections = (tab, user, isTabletDown) => {
    const { saveUserFieldData } = this.props.singlesActions;
    const { getUserData } = this.props.authenticationActions;
    const { profileCompletion } = this.props.singlesReducer;
    switch (tab) {
      case "theBasics":
        return (
          <TheBasics
            saveUserFieldData={saveUserFieldData}
            user={user}
            setSectionFromNextButtons={this.changeSection}
            calculatePercentage={(a, b, c) => this.calculatePercentage(a, b, c)}
            isTabletDown={isTabletDown}
            isCompleted={profileCompletion.theBasics === 100}
            setOnboardingSectionsDisabledState={
              this.setOnboardingSectionsDisabledState
            }
          />
        );
      case "photos":
        return (
          <Photos
            user={user}
            generalActions={generalActions}
            history={this.props.history}
            reloadUserData={getUserData}
            setSectionFromNextButtons={this.changeSection}
            calculatePercentage={(a, b, c) => this.calculatePercentage(a, b, c)}
            saveUserFieldData={saveUserFieldData}
            isTabletDown={isTabletDown}
          />
        );
      case "background":
        return (
          <BackgroundSection
            userData={user}
            history={this.props.history}
            saveUserFieldData={(key, value) =>
              saveUserFieldData(user._id, key, value)
            }
            setSectionFromNextButtons={this.changeSection}
            isTabletDown={isTabletDown}
          />
        );
      case "lifestyle":
        return (
          <LifestyleSection
            userData={user}
            history={this.props.history}
            saveUserFieldData={(key, value) =>
              saveUserFieldData(user._id, key, value)
            }
            setSectionFromNextButtons={this.changeSection}
            isTabletDown={isTabletDown}
          />
        );
      case "family":
        return (
          <FamilySection
            userData={user}
            history={this.props.history}
            saveUserFieldData={(key, value) =>
              saveUserFieldData(user._id, key, value)
            }
            setSectionFromNextButtons={this.changeSection}
            isTabletDown={isTabletDown}
          />
        );
      case "partners":
        return (
          <YourPartner
            saveUserFieldData={saveUserFieldData}
            user={user}
            setSectionFromNextButtons={this.changeSection}
            calculatePercentage={(a, b, c) => this.calculatePercentage(a, b, c)}
            isTabletDown={isTabletDown}
          />
        );
      case "other":
        return (
          <OtherSection
            otherUserData={{...user.other, firstSignup:user.firstSignup}}
            history={this.props.history}
            saveUserFieldData={(key, value) =>
              saveUserFieldData(user._id, key, value)
            }
            isTabletDown={isTabletDown}
            setSectionFromNextButtons={this.changeSection}
          />
        );
      case "settings":
        return (
          <Settings
            saveUserFieldData={saveUserFieldData}
            goToRelationshipStatus={() => this.goToRelationshipStatus()}
            user={user}
            isTabletDown={isTabletDown}
            setOnboardingSectionsDisabledState={
              this.setOnboardingSectionsDisabledState
            }
          />
        );
    }
  };

  goToRelationshipStatus = () => {
    this.setState({
      selectedTab: "theBasics",
    });
  };
}

UserDashboard.propTypes = {
  history: PropTypes.object.isRequired,
  generalActions: PropTypes.object.isRequired,
  singlesActions: PropTypes.object.isRequired,
  generalReducer: PropTypes.object.isRequired,
  authenticationReducer: PropTypes.object.isRequired,
  authenticationActions: PropTypes.object.isRequired,
  singlesReducer: PropTypes.object.isRequired,
  profileCompletion: PropTypes.object,
  user: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return {
    generalActions: bindActionCreators(generalActions, dispatch),
    authenticationActions: bindActionCreators(authenticationActions, dispatch),
    singlesActions: bindActionCreators(singlesActions, dispatch),
  };
};

const mapStateToProps = (state) => {
  return {
    generalReducer: state.generalReducer,
    authenticationReducer: state.authenticationReducer,
    singlesReducer: state.singlesReducer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDashboard);
