import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "../util-functions";
import "./style.scss";
import MediaQueryProvider from "src/components/MediaQueryProvider";
import { FOR_TABLET_DOWN_QUERY } from "src/data/constants";

class InputText extends Component {
  state = { focused: null };

  static propTypes = {
    focus: PropTypes.func,
    autoFocus: PropTypes.bool,
    children: PropTypes.node,
    type: PropTypes.string,
    min: PropTypes.any,
    name: PropTypes.string,
    className: PropTypes.string,
    classNameForLabel: PropTypes.string,
    wrapperClassName: PropTypes.string,
    errorIcon: PropTypes.bool,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    afterLabel: PropTypes.string,
    maxLength: PropTypes.string,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    error: PropTypes.any,
    onChange: PropTypes.func,
    errorPosition: PropTypes.string,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyUp: PropTypes.func,
    onKeyDown: PropTypes.func,
    underlineForGeneralError: PropTypes.bool,
    readOnly: PropTypes.bool,
    autocomplete: PropTypes.string,
    onEnterKeyPressed: PropTypes.func,
    onClick: PropTypes.func,
    isValid: PropTypes.any,
    showLabel: PropTypes.bool,
    id: PropTypes.string,
    required: PropTypes.bool,
    style: PropTypes.object,
  };

  componentDidUpdate() {
    if (this.props.focus) {
      this.input.focus();
    }
  }

  value = () => {
    return this.input.value;
  };

  onFocus = () => {
    if (this.props.autocomplete) {
      this.input.removeAttribute("readonly");
    }
    this.setState({ focused: true });
  };

  onBlur = () => {
    this.setState({ focused: false });
  };

  enterPressed = (ev) => {
    const code = ev.keyCode || ev.which;
    if (code === 13) {
      this.props.onEnterKeyPressed && this.props.onEnterKeyPressed();
    }
  };

  render() {
    const {
      classNameForLabel,
      readOnly,
      error,
      wrapperClassName,
      value,
      label,
      type,
      autocomplete,
      name,
      placeholder,
      disabled,
      onFocus,
      onBlur,
      onKeyDown,
      onChange,
      autoFocus,
      min,
      onClick,
      afterLabel,
      maxLength,
      isValid,
      showLabel,
      id,
      required,
      style,
    } = this.props;
    let classes = `input-wrapper${error ? " has-error" : ""}`;
    const customClassForLabel = classNameForLabel || "";
    if (wrapperClassName) {
      classes += ` ${wrapperClassName}`;
    }
    if (this.state.focused) {
      classes += " focused";
    }
    let inputClass = `input-text${error ? " has-error" : ""}`;
    if (!isEmpty(value)) {
      inputClass += " input-text--has-value";
      classes += " input-wrapper--has-value";
    }
    if (isValid) {
      inputClass += " is-valid";
    }
    return (
      <MediaQueryProvider query={FOR_TABLET_DOWN_QUERY}>
        {(isTabletDown) => (
          <div className={classes} style={style}>
            {(showLabel && label) || afterLabel ? (
              <div className="input-labels-container">
                {showLabel && (
                  <span className={`input-name-label ${customClassForLabel}`}>
                    {label ? (
                      <>
                        {label}
                        {required && !value || customClassForLabel === "show-label redish"? (
                          <span className="text-alert-red">*</span>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      "--"
                    )}
                  </span>
                )}
                {isTabletDown && afterLabel ? (
                  <span className={`helper-text ${customClassForLabel}`}>
                    {afterLabel}
                  </span>
                ) : null}
              </div>
            ) : null}
            <input
              ref={(c) => {
                this.input = c;
              }}
              autoFocus={autoFocus || false}
              autoComplete={autocomplete}
              readOnly={readOnly ? true : undefined}
              type={type || "text"}
              min={min}
              maxLength={maxLength || "100"}
              name={name}
              id={id}
              className={inputClass}
              placeholder={placeholder}
              value={isEmpty(value) ? "" : value}
              disabled={disabled ? "disabled" : undefined}
              onClick={!disabled ? onClick : () => {}}
              onChange={!disabled ? onChange : () => {}}
              onFocus={onFocus || this.onFocus.bind(this)}
              onBlur={onBlur || this.onBlur.bind(this)}
              onKeyUp={this.enterPressed.bind(this)}
              onKeyDown={onKeyDown}
            />

            {!isTabletDown && afterLabel ? (
              <span className={`helper-text ${customClassForLabel}`}>
                {afterLabel}
              </span>
            ) : null}

            {this.props.children}

            {error && <span className="error-label">{error}</span>}
          </div>
        )}
      </MediaQueryProvider>
    );
  }
}

export default InputText;
