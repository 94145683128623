import React from "react";
import minusIconSvg from "../../images/minus-icon.svg";

export const MinusIcon = ({
   width = 20,
   height = 20,
   style = {},
   className,
   ...rest
}) => {
  return (
    <img
      style={{ width, height, ...style }}
      src={minusIconSvg}
      alt="Minus icon"
      className={className}
      {...rest}
    />
  );
};
