@import "../../../../src/scss/variables";
@import "../../../../src/scss/utilities";

.input-checkbox {
  align-items: center;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  gap: 1rem;

  &__checkbox-icon {
    height: 20px;
    width: 20px;
  }

  &.suspicion {
    .checkbox__label {
      color: $teal;
      font-size: 16px;
      font-weight: normal;
    }
  }

  .checkbox__label {
    color: $textPrimary;
    cursor: pointer;
    font-size: 1rem;
    position: relative;
    user-select: none;
    font-weight: normal;
    @media screen and (max-device-width: 1200px) {
      font-size: 1em;
    }

    &__instrument {
      @include for-desktop {
        white-space: nowrap;
      }
    }

    .special-label {
      span:first-child {
        color: $textPrimary;
        font-size: 17px;
        letter-spacing: -0.5px;
        max-width: 35rem;
        // margin-bottom: 5px;
      }

      span:nth-child(2) {
        color: $teal;
        font-family: Montserrat, sans-serif;
        font-size: 12px;
        letter-spacing: -0.2px;
        max-width: 27rem;
      }
    }
  }

  .instrument-input {
    width: 100%;
  }

  .icon-square-full,
  .icon-square-empty {
    cursor: pointer;
    user-select: none;
  }

  &.sign-up {
    align-items: center;
    min-height: 50px;

    @media screen and (max-device-width: 1360px) {
      min-height: 60px;
    }
  }

  input[type="checkbox"]:not(:checked),
  input[type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
  }

  &--disabled {
    pointer-events: none;
    cursor: default;
  }
}
