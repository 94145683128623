@import "../../../../src/scss/variables";

.custom-radio-button {
  align-items: center;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  width: fit-content;
  gap: 1rem;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &__label {
    color: $textPrimary;
    cursor: pointer;
    font-size: 1rem;
    position: relative;
    user-select: none;
    font-weight: normal;
  }

  .icon-radio-full,
  .icon-radio-empty {
    cursor: pointer;
    user-select: none;
  }
  .input-wrapper {
    .error-label {
      top: 15px;
    }
  }
}
