import React from "react";
import circleChevronDownSvg from "../../images/circle-chevron-down.svg";

export const CircleChevronDownIcon = ({
  width = 24,
  height = 24,
  style = {},
  className,
  ...rest
}) => {
  return (
    <img
      style={{ width, height, ...style }}
      src={circleChevronDownSvg}
      alt="Circle chevron icon"
      className={className}
      {...rest}
    />
  );
};
