import React, { useMemo, useState } from 'react';
import { CheckBox } from 'src/components/widgets';
import Modal from '../Modal';

export const DeletePhotosModal = ({ user, onClose, showModal, onDelete }) => {
    const [photosToDelete, setPhotosToDelete] = useState([]);
    const handleClick = (id) => {
        if (photosToDelete.includes(id)) {
            setPhotosToDelete(photosToDelete.filter((x) => x !== id));
        } else {
            setPhotosToDelete([...photosToDelete, id]);
        }
    };
    const userImages = useMemo(() => {
        return user.images
            .filter((x) => x)
            .map((pic) =>
                pic.url
                    ? {
                        id: pic.position,
                        src: decodeURI(pic.url) + "?t=" + new Date().getTime(),
                    }
                    : null
            );
    }, [user.images]);

    return (
        <Modal
            open={showModal}
            onClose={onClose}
            header={
                <div className="member-header">
                    <div className="member-header__left-container">
                        <div className="member-header__left-container__member-label">
                            <span>
                                {`${user.firstName} ${user.lastName}`}
                            </span>
                        </div>
                    </div>
                    <div>
                        <button
                            className="delete-photo-button button blue"
                            disabled={photosToDelete.length === 0}
                            onClick={() => onDelete(photosToDelete)}>
                            Delete Photos
                        </button>
                    </div>
                </div>}>

            <div className="delete-images-container">
                {userImages.map((pic) => (
                    <div
                        className="delete-img-container"
                        key={pic.id}
                        onClick={() => handleClick(pic.id)}
                        style={{ backgroundImage: `url(${pic.src})` }}
                    >
                        <CheckBox
                            value={pic.id}
                            checked={photosToDelete.includes(pic.id)}
                            className="delete-img-checkbox"
                        />
                        {photosToDelete.includes(pic.id) && <div className='selected-img'></div>}
                    </div>
                ))}
            </div>
        </Modal>
    );
}