import React, { useEffect, useState, } from 'react';
import Modal from '../../Modal';
import { cancelContact, contactUser, favoriteUser } from 'src/components/pages/dashboards/UserList';
import UserProfile from 'src/components/pages/dashboards/UserProfile';
import { DropDown } from 'src/components/widgets';
import "./UserInfoModal.scss"
import { AdminModalHeader, AdminUserProfile } from './admin/AdminModal';
const UserInfoModal = ({ activeUser, showProfile, onClose, role = "mm", adminActions, generalActions }) => {
    const header = role === 'admin' ? <AdminModalHeader activeUser={activeUser} adminActions={adminActions} generalActions={generalActions}/> : <ModalHeader activeUser={activeUser} />
    const body = role === 'admin' ? <AdminUserProfile user={activeUser} adminActions={adminActions}/> : <UserProfile user={activeUser} />
    return (
        <Modal
            open={showProfile}
            onClose={onClose}
            header={header}
        >
            {showProfile && body}
        </Modal>)
}

const ModalHeader = ({ activeUser }) => {
    const [openMenu, setOpenMenu] = useState(false);
    return (
        activeUser ? (
            <div className="member-header">
                <div className="member-header__left-container">
                    <div className="member-header__left-container__member-label">
                        <span>
                            {activeUser.connected
                                ? activeUser.name
                                : `Member ${activeUser.user_id}`}
                        </span>
                    </div>
                    {activeUser.shareLink ? (
                        <div>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={activeUser.shareLink}
                                style={{ fontSize: "0.8em" }}
                            >
                                Shareable Premium Member Profile
                            </a>
                            <span style={{ fontSize: "0.8em" }}>
                                {" "}
                                (Share this with candidates)
                            </span>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>

                <div className="member-actions">
                    {!activeUser.favorited && (
                        <button
                            className="button blue"
                            onClick={() => favoriteUser(activeUser)}
                        >
                            Add to My Potentials
                        </button>
                    )}
                    {!activeUser.contacted && !activeUser.connected && (
                        <button
                            className="button yellow"
                            onClick={() => contactUser(activeUser)}
                        >
                            Request Connection
                        </button>
                    )}
                    {activeUser.contacted && 72 && (
                        <button
                            className="button yellow"
                            onClick={() => cancelContact(activeUser)}
                        >
                            Cancel Request
                        </button>
                    )}
                    {activeUser.contacted && (
                        <button className="button inactive">Requested</button>
                    )}
                </div>
                <div className='actions-menu'>
                    <span className="icon-more" onClick={() => setOpenMenu(!openMenu)} />
                    <DropDown className="menu-dropdown" open={openMenu}>
                        {!activeUser.favorited && <div className={`user-profile-action`} onClick={() => favoriteUser(activeUser)}>
                            <span className="content">Add to My Potentials</span>
                        </div>}
                        {!activeUser.contacted && !activeUser.connected && <div className={`user-profile-action`} onClick={() => contactUser(activeUser)}>
                            <span className="content">Request Connection</span>
                        </div>}
                        {activeUser.contacted && <div className={`user-profile-action`} onClick={() => cancelContact(activeUser)}>
                            <span className="content">Cancel Request</span>
                        </div>}
                        {activeUser.contacted && <div className={`user-profile-action requested`}>
                            <span className="content">Requested</span>
                        </div>}
                    </DropDown>
                </div>
            </div>
        ) : (
            <></>
        )
    )
}


export default UserInfoModal;