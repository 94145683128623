@import "../../../../src/scss/variables";

.users-list-container {
  // height: 100%;
  // .labels {
  //     display: flex;
  //     -webkit-flex: 0;
  //     border-radius: 2px;
  //     background-color: $teal;
  //     height: 52px;
  //     min-width: max-content;
  //     span {
  //         display: flex;
  //         flex-direction: row;
  //         width: 100%;
  //         align-items: center;
  //         text-align: center;
  //         color: #fff;
  //         font-family: Montserrat, sans-serif;
  //         font-size: 14px;
  //         font-weight: 500;
  //         height: 100%;
  //         justify-content: center;
  //         &.thumb{
  //             margin-left: -25px;
  //             margin-right: -50px;
  //         }
  //         &.special {
  //             margin-right: 0;
  //         }
  //         &.special-right {
  //             margin-right: 21px;
  //         }
  //         &.connections {
  //             text-align: center;
  //         }
  //         &.statuses {
  //             text-align: center;
  //         }
  //         &.rating {
  //             text-align: center;
  //         }
  //         &.user-type {
  //             text-align: right;
  //         }
  //         &.menu {
  //             width: 40px;
  //         }
  //     }
  // }

  .users-list {
    overflow: auto;
    &__item {
      height: 100px;
      flex: 0 0 auto;
      &:hover {
        background-color: rgba(101, 196, 192, 0.2);
        // cursor: pointer;
      }
    }

    &__item-container {
      border-bottom: 1px solid rgba(84, 88, 90, 0.2);
      position: relative;
      &.faded-locked {
        opacity: 0.3;
        pointer-events: none;
      }
    }

    .empty-table {
      text-align: center;
    }

    .user-info {
      display: flex;
      color: #54585A;
      font-size: 16px;
      font-family: 'Montserrat', sans-serif;
      -webkit-flex: 0;
      flex: 0 0 100%;
      margin: 0;
      text-align: center;
      user-select: none;
      .dollar {
        width: 15px;
      }
      .avatar {
        background-size: cover;
        height: 90px;
        width: 90px;
        border-radius: 50%;
        background-color: #fafafa;
        border: 1px solid #aaa;
        color: #aaa;
        margin: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .member {
        width: 130px;
      }
      .gender {
        width: 50px;
      }
      .age {
        width: 35px;
      }
      .looks {
        width: 94px;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        &.big-blue {
          color: $teal;
          font-size: 20px;
        }
        &.red {
          color: #FF596F;
          font-size: 14px;
        }
      }
      .location {
        width: 90px;
      }
      .relationship-status {
        width: 200px;
      }
      .conn-requests {
        width: 80px;
      }
      .numbers {
        width: 100%;
        align-items: center;
        justify-content: center;
      }
      .element {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        margin-right: 20px;
      }
      .connection-status {
        width: 120px;
        &.APPROVED {
          color: #6dbbaa;
        }
        &.REJECTED {
          color: #FF476B;
        }
        &.PENDING {
          color: #53585a;
        }
      }
      .willing-to-pay {
        // z-index: 4;
        .client-status{
          .status{
            &:hover {
              cursor: pointer;
            }
          }
        }
        .client-status-dropdown {
          position: relative;
          z-index: 6;
          width: 75%;
        }
        height: 100%;
        width: 100%;
        font-size: 1em;
        white-space: nowrap;
      }
    }

    .status {
      margin-left: auto;
      // margin-right: 80px;
    }

    .status-icons {
      position: absolute;
      right: 40px;
      span {
        font-size: 18px;
        margin-right: 0;
        width: 40px;
      }
    }

    .show-more {
      font-size: 30px;
      position: absolute;
      right: 20px;

      .icon-more {
        cursor: pointer;
      }

      .icon-more:hover {
        &:before { color: $teal; }
      }

      .user-menu {
        border: 1px solid rgba(84, 88, 90, 0.1);
        border-radius: 2px;
        background-color: #FFF;
        box-shadow: 0 0 10px 0 rgba(84, 88, 90, 0.2);
        height: auto;
        width: 233px;
        position: absolute;
        right: -12px;
        top: 44px;
        z-index: 6;
        &.open-upwards {
          top: auto;
          bottom: 35px;
        }
        > div:not(.triangle-component) {
          border-bottom: 1px solid rgba(84, 88, 90, 0.1);
          cursor: pointer;
          height: 40px;
          padding: 0 21px;
          user-select: none;
          background: #fff;
          span:first-child {
            width: 30px;
            margin-right: 10px;
          }
          span {
            color: #54585A;
            font-family: Montserrat, sans-serif;
            font-size: 14px;
          }
          &:hover {
            background-color: #FFDA65;
          }
        }
        &.admin-menu {
          width: 180px;
        }
      }

      .user-relationship-status {
        width: 253px;
      }

    }
  }

  &.favorites, &.locks, &.contacted {
    .icon-close-button {
      margin-right: 30px;
    }
    .users-list {
      height: calc(100vh - 266px)
    }

  }

  @media screen and (max-width: 1200px) {

  }
}
