import React from "react";
import questionSvg from "../../images/question.svg";

export const QuestionIcon = ({
  width = 24,
  height = 24,
  style = {},
  className,
  ...rest
}) => {
  return (
    <img
      style={{ width, height, ...style }}
      src={questionSvg}
      alt="Question mark icon"
      className={className}
      {...rest}
    />
  );
};
