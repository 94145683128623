/* GLOBAL STATE
======================================== */

// to start with different defaults call setGlobal( defaults ) from app's componentDidMount
export let globalState = {
  snips: [],
  imageInstances: [],
  media: [],
  images: [],
  settings: [],
  services: [],
  AdminBar: null,
  MediaLibrary: null,
  userFilters: { },
};

if (window) {
  // make global state available via window.launchpad_data for debugging
  window.launchpad_data = globalState;
}

// set "global state" by pushing vars to globalState (and window.launchpad_data)
// and updating the virtual DOM
export function setGlobal(obj, update) {
  // accepts an object just like setState, e.g.: setGlobal({codepilot: 'awesome'})
  for (let key in obj) {
    // update the global state object with each value from the obj argument
    globalState[key] = obj[key];
  }

  // we could do globalState = Object.assign(globalState, obj) or similar to
  // make setGlobal immutable, and do something like globalHistory.push(globalState)
  // to add rollback functionality, etc.

  // Object.keys(obj).forEach(key => {
  //   console.log('setting '+key+' to:', obj[key])
  // })

  if (globalState.app && update !== false) {
    // if the app has added itself to the global state (happens automatically on mount)
    // then rerender it so all components pick up the altered state

    //globalState.app.forceUpdate();

    // use this instead to rate limit
    requestUpdate();
  }
}

// rate limiting global state updates (may be redundant, added as insurance)
let minUpdateTime = 10;
let maxUpdateTime = 100;

let forceUpdate = false;
let forceTimer = null;
let updateTimer = null;

const requestUpdate = () => {
  //console.log('requesting update')
  if (updateTimer) {
    clearTimeout(updateTimer);
  } else {
    //console.log('setting up forceTimer')
    forceTimer = setTimeout(() => {
      //console.log('forcing update')
      globalState.app.forceUpdate();
      updateTimer = null;
      forceTimer = null;
    }, maxUpdateTime);
  }
  updateTimer = setTimeout(() => {
    globalState.app.forceUpdate();
    clearTimeout(forceTimer);
    updateTimer = null;
    forceTimer = null;
  }, minUpdateTime);
};

// just delivers the value from the state object
export function getGlobal(key) {
  if (!key) return globalState;
  if (globalState) {
    return globalState[key];
  }
  // else return undefined
}
