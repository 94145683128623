@import "src/scss/utilities.scss";
@import "src/scss/variables.scss";

.user-login__container {
  position: relative;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 6rem;
  align-items: center;

  .user-login__left-side {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;

    .user-login__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;

      .user-login__title {
        font-size: 2.25rem;
        line-height: 2.875rem;
        color: #2a2d2e;
        margin: 0;
        text-transform: capitalize;
      }

      .user-login__divider {
        width: 6.25rem;
        height: 0.5rem;
        border-radius: 10rem;
        background-color: #34c7c0;
      }
    }
  }

  .user-login__form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    .user-login__input {
      height: 52px !important;
    }

    .user-login__forgot-password {
      font-weight: 600;
      text-decoration: none;
      align-self: flex-start;
    }

    .user-login__buttons-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }

    .user-login__subtitle {
      display: flex;
      align-self: center;
      line-height: 1.5;
      font-weight: 600;
      color: $greyDarker;
      font-size: 14px;
      text-transform: uppercase;
      margin: 0;

      &.sign-up {
        font-size: 16px;
        margin-top: 2rem;
        font-weight: 600;
        text-transform: none;
      }
    }
  }

  .user-login__right-side {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    height: 100%;

    .user-login__image-container {
      height: 30rem;
      width: 100%;
      margin: 0;
      z-index: 10;
      filter: grayscale(100%);

      .user-login__hero-image {
        width: 37vw;
        height: 100%;
        object-fit: cover;
        max-width: 538px;
      }
    }

    .user-login__image-legend {
      position: absolute;
      left: 0;
      top: auto;
      right: auto;
      bottom: 0;
      margin-bottom: 0.75rem;
      margin-left: 0.75rem;
      padding: 0.5rem 0.8rem;
      background-color: rgba(0,0,0,.65);
      font-size: .875rem;
      color: white;
    }

    .user-login__background-color {
      position: absolute;
      left: 22.5%;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100vw;
      background-color: $bright-teal;
    }
  }
}

// Tablet
@include for-tablet {
  .user-login__container {
    grid-gap: 3rem;
  }

  .user-login__image-container {
    position: absolute;
  }

  .user-login__hero-image {
    width: 51vw !important;
  }

  .user-login__background-color {
    left: 25% !important;
  }
}

// Mobile
@include for-mobile {
  .user-login__container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 416px;
    grid-gap: 0;
  }

  .user-login__left-side {
    padding: 2rem 0 calc(2rem + 15px) 0;
  }

  .user-login__right-side {
    height: 416px !important;
  }

  .user-login__background-color {
    left: 30% !important;
  }

  .user-login__image-container {
    height: 320px !important;
  }

  .user-login__hero-image {
    width: 100% !important;
  }

  .user-login__button-mobile {
    width: 302px !important;

    &.w-icon-button-mobile {
      display: flex !important;
      justify-content: center !important;
      padding-right: 2.5rem !important;
    }
  }
}