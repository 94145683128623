const ERRORS = {
  REQUIRED: "Required",
  EMAIL_FORMAT: "Invalid format",
  STATE_CHARACTER_FORMAT: "Invalid State format",
  CITY_CHARACTER_FORMAT: "Invalid City format",
  PHONE_NUMBER_FORMAT: "Invalid Phone Number format",
  PASSWORD_FORMAT: "Invalid password",
  NUMBER_FORMAT: "Invalid number format",
  NUMBER_NON_ZERO: "Must be greater than 0",
  DATE_FORMAT: "Invalid date format",
  AMERICAN_DATE_FORMAT: "Invalid date format",
  CARD_DATE_FORMAT: "Invalid date format",
  TIME_FORMAT: "Invalid time format",
};

export default ERRORS;
