@import "../../../../src/scss/variables";

.drug-form {
    position: relative;
    margin-bottom: 20px;
    &__label {
        color: rgba(84,88,90,0.5);
        // color: #54585A;
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        text-align: center;
        min-height: 40px;
        > div {
            width: 100%;
            span {
                width: 50%;
            }
        }
        .p-label {
            color: #ff6666;
            &.green {
                color: $teal;
            }
        }
    }
    &__options {
        max-width: 680px;
        width: 100%;
    }
    &__dropdown { //HN
        max-width: 680px;
        width: 100%;
        padding-top: 1em;
    }
    &__option {
        color: #54585A;
        // cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        font-size: 18px;
        min-height: 40px;
        padding-left: 12px;
        margin: 5px; //HN
        width: 100%;
        &:hover {
            // background-color: rgba(84,88,90,0.1);
            border-radius: 4px;
        }
    }
    &__buttons {
        position: absolute;
        top: 35px;
        right: 0;
        width: calc(50% - 6px);
        .radio-list {
            width: 50%;
            .custom-radio-button {
                justify-content: center;
                width: 90%;
            }
        }
        .label {
            display: none;
        }
    }
}
