@import "src/scss/variables";

.new-progress-bar {
  background-color: $greyLightest;
  border-radius: 30px;
  width: 100%;
  height: 6px;

  &__filler {
    background-color: #FFDA66;
    border-radius: 30px;
    height: 100%;
    width: 0;
    transition: width 0.5s ease-in-out;
  }
}