import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import * as adminActions from "../../../../actions/adminActions";
import * as generalActions from "../../../../actions/generalActions";
import Modal from "../../../../components/modal";
import Button from "../../../../components/button";
import "./style.scss";

class MatchMakerPlans extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedPlanId: null,
      openSubscriptionModal: false,
    };
  }

  componentDidMount() {
    const { plans } = this.props.adminReducer;
    // get MM plans
    if (!plans) {
      adminActions.loadPlans();
    }
    // document.body.classList.add('no-scroll');
  }

  render() {
    const { loggedMMplan, changePlanEmailSend } = this.props.matchmakersReducer;
    const { plans } = this.props.adminReducer;
    const { selectedPlanId, openSubscriptionModal } = this.state;
    const matchMakerHasNoPlan =
      !loggedMMplan || typeof loggedMMplan === "string";
    const confirmationModalContent = this._renderConfirmationModalContent();
    const subscriptionModalContent = this._renderSubscriptionModalContent(
      plans,
      selectedPlanId,
      loggedMMplan
    );
    const sortedPlans = plans && plans.sort((a, b) => a.price - b.price);
    const renderPlans = this._renderPlans(
      sortedPlans,
      selectedPlanId,
      loggedMMplan,
      matchMakerHasNoPlan
    );

    if (changePlanEmailSend) {
      return (
        <Modal
          className="confirmation-change-plan-modal"
          isOpen={changePlanEmailSend}
          modalContent={confirmationModalContent}
        />
      );
    }
    if (openSubscriptionModal) {
      return (
        <Modal
          className="subscription-modal"
          isOpen={openSubscriptionModal}
          modalContent={subscriptionModalContent}
        />
      );
    }
    return (
      <div className="matchmaker-plans flex-column">
        <div className="matchmaker-plans__container">
          <div
            className="matchmaker-plans__text"
            style={{ marginBottom: "30px" }}
          >
            Change Your DateSpot Partner Matchmaker Plan
          </div>
          {renderPlans}
        </div>
      </div>
    );
  }

  _renderPlans = (plans, selectedPlanId, loggedMMplan, matchMakerHasNoPlan) => {
    return (
      <div className="flex-row flex-center wrap" style={{ marginTop: "80px" }}>
        {plans &&
          plans.map((plan, p) => {
            let planClass = `matchmaker-plans__plans flex-column ${
              selectedPlanId === plan._id ? "selected" : ""
            }`;
            let currentPlan = "";
            if (matchMakerHasNoPlan) {
              currentPlan = "";
            } else {
              currentPlan = loggedMMplan && loggedMMplan.plan._id === plan._id;
              if (currentPlan) {
                planClass += " disabled-current-plan";
              }
            }

            if (plan.name === "Beta Tester") {
              return;
            }
            return (
              <div
                className={planClass}
                key={p}
                onClick={() => this.setState({ selectedPlanId: plan._id })}
              >
                <div className="matchmaker-plans__plans__title flex-center">
                  {plan.name}
                </div>
                <div className="matchmaker-plans__plans__description flex-center">
                  {plan.shortDesc}
                </div>
                <div className="matchmaker-plans__plans__price flex-center">
                  <span>${plan.price}</span>
                  {plan.id !== 1 && <span>/month</span>}
                </div>
                <div className="matchmaker-plans__plans__fields flex-column">
                  <span className="connections">
                    Max of {plan.connections} connections
                  </span>
                  <span className="text">{plan.longDesc}</span>
                  <span className="locks">
                    {plan.freeLocks} lock{plan.freeLocks === 1 ? "" : "s"} per
                    month
                  </span>
                  <Button
                    value={`${currentPlan ? "Current Plan" : "Subscribe"}`}
                    customClass={`${currentPlan ? "disabled-plan" : "yellow"}`}
                    click={() => this.setState({ openSubscriptionModal: true })}
                  />
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  _renderSubscriptionModalContent = (plans, selectedPlanId, loggedMMplan) => {
    const selectedPlan =
      plans && plans.find((pla) => pla._id === selectedPlanId);
    const currentDate = moment(new Date()).format("MM/DD/YYYY");
    return (
      <div className="flex-column">
        <span>
          You are about to purchase the “{selectedPlan && selectedPlan.name}”
          monthly subscripton
        </span>
        <span>
          Cancellations and plan downgrades will go into effect the first of the
          following month. For plan upgrades, your plan will change immediately.
          Starting {currentDate}, you’ll pay $
          {selectedPlan && selectedPlan.price} automatically each month, and you
          won’t be double-charged for days you’ve already paid for this month.
        </span>
        <div className="flex-row flex-center">
          <Button
            customClass="yellow"
            click={() => {
              this.setState({ openSubscriptionModal: false });
              this.sendNewPlanSubscription(loggedMMplan, selectedPlan);
            }}
            value="Subscribe"
          />
          <Button
            click={() => this.setState({ openSubscriptionModal: false })}
            value="Cancel"
          />
        </div>
      </div>
    );
  };

  _renderConfirmationModalContent = () => {
    const { history } = this.props;
    return (
      <div className="flex-column">
        <span>Your request to change plans has been received. </span>
        <span>We will email you shortly with a response.</span>
        <div className="flex-row flex-center">
          <Button
            customClass="yellow"
            click={() => history.push("/")}
            value="Got it!"
          />
        </div>
      </div>
    );
  };

  sendNewPlanSubscription = (currentPlan, selectedPlan) => {
    this.props.adminActions.sendChangePlanEmail(currentPlan.plan, selectedPlan);
  };
}

MatchMakerPlans.propTypes = {
  history: PropTypes.object,
  matchmakersReducer: PropTypes.object.isRequired,
  adminReducer: PropTypes.object,
  adminActions: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return {
    adminActions: bindActionCreators(adminActions, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch),
  };
};

const mapStateToProps = (state) => {
  return {
    matchmakersReducer: state.matchmakersReducer,
    adminReducer: state.adminReducer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MatchMakerPlans);
