import React, { useState } from "react";
import Modal from "src/components/Modal/Modal";
import theme from "src/styles/theme"
import "./SavedFilters.scss"
import { FilterIcon } from "src/components/icons";

export const SavedFilters = ({ filters, setFilter, selectedFilter, setAllFilters, isMobile }) => {
    const [openModal, setOpenModal] = useState(false);


    const clearSavedFilters = (filterName) => Object.keys(filters[filterName]).map((key) => setAllFilters({ [key]: null }))

    return (<>
        <Modal
            open={openModal}
            onClose={() => setOpenModal(false)}
            header={
                <span>
                    My Saved Filters
                </span>
            }
            customClass="saved-filters-modal"
            >
            <div>
                {Object.keys(filters).map((filterName, i) => {
                    const isSelected = selectedFilter === filterName;
                    return (
                        <div className="saved-filter" key={`${filterName}-${i}`}>
                            <h3 style={{ color: theme.colors.primaryBlue }}>{filterName}</h3>
                            <div className="toggle-container">
                                <button className={`toggle-btn ${isSelected ? "active-toggle" : ""}`} onClick={() => setFilter(filterName)}>On</button>
                                <button className={`toggle-btn ${!isSelected ? "active-toggle" : ""}`} onClick={isSelected ? () => clearSavedFilters(filterName) : () => { }}>Off</button>
                            </div>
                        </div>
                    )
                })}
            </div>
        </Modal >
        {isMobile ? (
            <div onClick={() => setOpenModal(true)}
                style={{
                    display: "flex",
                    border: "1px solid rgba(0, 0, 0, 0.15)", borderRadius: "5px",
                    padding: "4px 8px", alignItems: "center", width: "170px", justifyContent: "space-between"
                }}>
                <span style={{ color: theme.colors.black, fontSize: "14px", fontWeight: 400 }}>
                    Saved
                </span>
                <FilterIcon />
            </div>
        )
            : <button
                style={{ marginTop: '8px', backgroundColor: "#3FC6C0", fontWeight: 500, color: "white", fontSize: "16px" }}
                onClick={() => setOpenModal(true)}
                className={"filter-button"}
            >
                My Saved Filters
            </button>

        }

    </>)
}