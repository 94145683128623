const photo1 = require("../containers/home-pages/assets/member-photos/j.png");
const photo2 = require("../containers/home-pages/assets/member-photos/k.png");
const photo3 = require("../containers/home-pages/assets/member-photos/member10.png");
const photo4 = require("../containers/home-pages/assets/member-photos/member12.png");
const photo5 = require("../containers/home-pages/assets/member-photos/member5.png");
const photo6 = require("../containers/home-pages/assets/member-photos/member6.png");
const photo7 = require("../containers/home-pages/assets/member-photos/member7.png");
const photo8 = require("../containers/home-pages/assets/member-photos/u111 Cropped.jpg");
const photo9 = require("../containers/home-pages/assets/member-photos/u12.png");
const photo10 = require("../containers/home-pages/assets/member-photos/u126 Cropped.jpg");
const photo11 = require("../containers/home-pages/assets/member-photos/u13.png");
const photo12 = require("../containers/home-pages/assets/member-photos/u16.jpg");
const photo13 = require("../containers/home-pages/assets/member-photos/u18.png");
const photo14 = require("../containers/home-pages/assets/member-photos/u19.png");
const photo15 = require("../containers/home-pages/assets/member-photos/u199 Cropped.jpg");
const photo16 = require("../containers/home-pages/assets/member-photos/u20.png");
const photo17 = require("../containers/home-pages/assets/member-photos/u22.png");
const photo18 = require("../containers/home-pages/assets/member-photos/u223 Cropped.png");
const photo19 = require("../containers/home-pages/assets/member-photos/u232 Cropped.jpg");
const photo20 = require("../containers/home-pages/assets/member-photos/u24.png");
const photo21 = require("../containers/home-pages/assets/member-photos/u243 Cropped.jpg");
const photo22 = require("../containers/home-pages/assets/member-photos/u252 Cropped.jpg");
const photo23 = require("../containers/home-pages/assets/member-photos/u274 Cropped.jpg");
const photo24 = require("../containers/home-pages/assets/member-photos/u279 Cropped.jpg");
const photo25 = require("../containers/home-pages/assets/member-photos/u290 Cropped.jpg");
const photo26 = require("../containers/home-pages/assets/member-photos/u298 Cropped.jpg");
const photo27 = require("../containers/home-pages/assets/member-photos/u309 Cropped.jpg");
const photo28 = require("../containers/home-pages/assets/member-photos/u314 Cropped.png");
// const photo29 = require('../containers/home-pages/assets/member-photos/u316_Cropped.jpg');
const photo30 = require("../containers/home-pages/assets/member-photos/u319 Cropped.jpg");
const photo31 = require("../containers/home-pages/assets/member-photos/u320 Cropped.jpg");
const photo32 = require("../containers/home-pages/assets/member-photos/u328 Cropped.jpg");
const photo33 = require("../containers/home-pages/assets/member-photos/u34.png");
const photo34 = require("../containers/home-pages/assets/member-photos/u340 Cropped.jpg");
const photo35 = require("../containers/home-pages/assets/member-photos/u346 Cropped.jpg");
const photo36 = require("../containers/home-pages/assets/member-photos/u348 Cropped.png");
const photo37 = require("../containers/home-pages/assets/member-photos/u35.png");
const photo38 = require("../containers/home-pages/assets/member-photos/u352 Cropped.jpg");
const photo39 = require("../containers/home-pages/assets/member-photos/u359 Cropped.jpg");
const photo40 = require("../containers/home-pages/assets/member-photos/u360 Cropped.png");
const photo41 = require("../containers/home-pages/assets/member-photos/u378 Cropped.jpg");
const photo42 = require("../containers/home-pages/assets/member-photos/u382 Cropped.png");
const photo43 = require("../containers/home-pages/assets/member-photos/u385 Cropped.jpg");
const photo44 = require("../containers/home-pages/assets/member-photos/u386 Cropped.png");
const photo45 = require("../containers/home-pages/assets/member-photos/u39 Cropped.jpg");
const photo46 = require("../containers/home-pages/assets/member-photos/u392 Cropped.png");
const photo48 = require("../containers/home-pages/assets/member-photos/u407 Cropped.jpg");
const photo49 = require("../containers/home-pages/assets/member-photos/u408 Cropped.jpg");
const photo50 = require("../containers/home-pages/assets/member-photos/u41 Cropped.jpg");
const photo51 = require("../containers/home-pages/assets/member-photos/u415 Cropped.png");
const photo52 = require("../containers/home-pages/assets/member-photos/u426 Cropped.jpg");
const photo53 = require("../containers/home-pages/assets/member-photos/u432 Cropped.jpg");
const photo54 = require("../containers/home-pages/assets/member-photos/u45 Cropped.jpg");
const photo55 = require("../containers/home-pages/assets/member-photos/u608 Cropped.jpg");
const photo56 = require("../containers/home-pages/assets/member-photos/u614 Cropped.jpg");
const photo57 = require("../containers/home-pages/assets/member-photos/u643 Cropped.jpg");
const photo58 = require("../containers/home-pages/assets/member-photos/u651 Cropped.jpg");
const photo59 = require("../containers/home-pages/assets/member-photos/u68.png");
const photo60 = require("../containers/home-pages/assets/member-photos/u69.png");
const photo61 = require("../containers/home-pages/assets/member-photos/u70.jpg");
const photo62 = require("../containers/home-pages/assets/member-photos/u73.png");
const photo63 = require("../containers/home-pages/assets/member-photos/u76.png");
const photo64 = require("../containers/home-pages/assets/member-photos/u82 Cropped.jpg");
const photo65 = require("../containers/home-pages/assets/member-photos/u89 Cropped.jpg");
const photo66 = require("_old/src/containers/home-pages/assets/member-photos/u610_Cropped.jpg");
const photo67 = require("_old/src/containers/home-pages/assets/member-photos/u823_Cropped.jpg");

export const MEMBER_PHOTOS_SLIDER = [
  { id: 1, src: photo1 },
  { id: 39, src: photo39 },
  { id: 12, src: photo12 },
  { id: 11, src: photo11 },
  // { id: 29, src: photo29 },
  { id: 38, src: photo38 },
  { id: 4, src: photo4 },
  { id: 14, src: photo14 },
  { id: 5, src: photo5 },
  { id: 44, src: photo44 },
  { id: 6, src: photo6 },
  { id: 7, src: photo7 },
  { id: 52, src: photo52 },
  { id: 20, src: photo20 },
  { id: 8, src: photo8 },
  { id: 22, src: photo22 },
  { id: 9, src: photo9 },
  { id: 13, src: photo13 },
  { id: 16, src: photo16 },
  { id: 17, src: photo17 },
  { id: 18, src: photo18 },
  { id: 19, src: photo19 },
  { id: 23, src: photo23 },
  { id: 24, src: photo24 },
  { id: 21, src: photo21 },
  { id: 25, src: photo25 },
  { id: 26, src: photo26 },
  { id: 27, src: photo27 },
  { id: 2, src: photo2 },
  { id: 54, src: photo54 },
  { id: 34, src: photo34 },
  { id: 30, src: photo30 },
  { id: 10, src: photo10 },
  { id: 31, src: photo31 },
  { id: 32, src: photo32 },
  { id: 36, src: photo36 },
  { id: 33, src: photo33 },
  { id: 35, src: photo35 },
  { id: 28, src: photo28 },
  { id: 67, src: photo67 },
  { id: 37, src: photo37 },
  { id: 40, src: photo40 },
  { id: 41, src: photo41 },
  { id: 42, src: photo42 },
  { id: 50, src: photo50 },
  { id: 43, src: photo43 },
  { id: 45, src: photo45 },
  { id: 64, src: photo64 },
  { id: 3, src: photo3 },
  { id: 49, src: photo49 },
  { id: 15, src: photo15 },
  { id: 51, src: photo51 },
  { id: 53, src: photo53 },
  { id: 66, src: photo66 },
  { id: 46, src: photo46 },
  { id: 48, src: photo48 },
  { id: 65, src: photo65 },
  { id: 55, src: photo55 },
  { id: 56, src: photo56 },
  { id: 57, src: photo57 },
  { id: 58, src: photo58 },
  { id: 60, src: photo60 },
  { id: 61, src: photo61 },
  { id: 59, src: photo59 },
  { id: 62, src: photo62 },
  { id: 63, src: photo63 },
];
