import React from "react";
import styled from "styled-components";

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease-in-out 0.1s;

  p {
    color: white;
  }

  .indicator {
    .fa {
      font-size: 40px;
      color: rgba(255, 255, 255, 0.7);
    }
  }
`;

const SpinnerContainer = styled.div`
  animation: spin 2s linear infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const Loading = (props) => (
  <Container style={props.active ? {} : { pointerEvents: "none", opacity: 0 }}>
    {props.message && <p>{props.message}</p>}
    <div className="indicator">
      <Spinner />
    </div>
  </Container>
);

export const Spinner = (props) => (
  <SpinnerContainer
    {...props}
    className={"fa fa-spinner spin " + props.className}
  />
);
