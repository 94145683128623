/*
  launchpad overview: routing

  Most basic routing is done automatically (see CMS section), manually adding routes
  can be done in `/src/config/index.jsx`. Consider using the `<Page>` component,
  following the instructions provided in the CMS section to take advantage
  of the automatic CMS integration.
*/

import React from "react";
import global_config from "../../global_config";

/* ==========================================
    DEFINE CONFIG HERE
   ========================================== */

const config = {
  // core configuration vars
};

// combine with global config and export
const combinedConfig = Object.assign({}, global_config, config);
export default combinedConfig;
