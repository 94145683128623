import React, { useRef, useEffect } from "react";
import { Collapsible } from "widgets";

export default function Pagination({ total, page, per_page, onChange, usersLoaded }) {
  const containerRef = useRef(null);
  const innerRef = useRef(null);
  const numberRef = useRef(null);

  const container = containerRef.current;
  const inner = innerRef.current;
  const number = numberRef.current;

  const pages = Math.ceil(total / per_page) || 1;

  const setPage = (p) => {
    if (usersLoaded !== false) {
      onChange(Math.max(0, Math.min(pages, p)));
    }
  }

  useEffect(() => {
    if (page > pages) setPage(pages);
  }, [pages]);

  // list of numbers for pages
  const prevActive = page > 1;
  const nextActive = page < pages;
  const preDots = page > 5;
  const postDots = page < pages - 4;
  const pageBase = Math.min(
    page - 4 > 1 ? page - 4 : 1,
    pages - 9 > 0 ? pages - 8 : 1
  );
  const pageArray = [...Array(pages).keys()].map((i) => i + 1);

  const width = container && container.offsetWidth;
  const parentLeft = container && container.offsetLeft;
  const barwidth = inner && inner.offsetWidth;
  const innerLeft = inner && inner.offsetLeft;
  const left = number && number.offsetLeft;

  let offset = left - width / 2 + 20;
  offset = Math.min(offset, barwidth - width);
  offset = Math.min(-offset, 0);


  //console.log(width, barwidth, left)
  return (
    <div className="pages-container">
      <Collapsible open={pages > 1} className="pages">
        <div
          onClick={() => setPage(page - 1)}
          className={`prev ${prevActive ? "active" : ""} fa fa-chevron-left`}
        />
        <div className="page-container" ref={containerRef}>
          <div className="inner" style={{ left: `${offset}px` }} ref={innerRef}>
            {pageArray.map((i) => (
              <div
                key={i}
                ref={page === i ? numberRef : () => {}}
                onClick={() => setPage(i)}
                className={`page ${page === i ? "active" : ""}`}
              >
                {i}
              </div>
            ))}
          </div>
        </div>
        <div
          onClick={() => setPage(page + 1)}
          className={`next fa fa-chevron-right ${nextActive ? "active" : ""}`}
        />
      </Collapsible>
    </div>
  );
}
