import React from "react";
import Avatar from "./Avatar";
import ProgressBar from "./ProgressBar";

const UserInfoHeader = ({ user = {}, progress = NaN }) => {
  return (
    <div className="single-info-header">
      <Avatar
        src={user.images && user.images[0] ? user.images[0].url : ""}
        alt="Single profile picture"
        initials={
          user.firstName && user.lastName
            ? `${user.firstName[0]}${user.lastName[0]}`
            : ""
        }
      />
      <div className="single-info-header__right-container">
        <p className="single-info-header__right-container__welcome-label">
          Welcome back,
          <span className="single-info-header__right-container__user-name">
            &nbsp;{user.firstName}
          </span>
        </p>
        <p className="single-info-header__right-container__questions-helper">
          All questions beyond Basics are optional, but helpful in identifying
          and matching you.
        </p>
        {/*<p
          className='single-info-header__right-container__completion-label'
        >
          Profile Completion - {progress}%
        </p>
        <ProgressBar progress={progress}/>*/}
      </div>
    </div>
  );
};

export default UserInfoHeader;
