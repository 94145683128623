import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as generalActions from "../../actions/generalActions";
import MainHeader from "../../components/main-header";
import MainFooter from "../../components/main-footer";
import SingleHomePage from "./singles-home-page";
import MatchmakersHomePage from "./matchmakers-home-page";
import HomePage from "./home-page";
import Contact from "../contact";
import TalkToAMMPage from "../talk-to-a-matchmaker";
import Team from "../team";
import Faqs from "../faqs";
import TermsOfService from "../terms-of-service";
import MatchMakerAgreement from "../matchmaker-agreement";
import PrivacyPolicy from "../privacy-policy";
import Button from "../../components/button";
import { FIRST_SECTION_ELEMENTS } from "./texts.js";
import { HOME_PAGES_URL_TO_TABS } from "../../constants";

import "./style.scss";

class HomePageIndex extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      page: props.history.location.pathname,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const { section } = this.props.history.location;
    const urlSection = HOME_PAGES_URL_TO_TABS[window.location.pathname];
    if (urlSection && urlSection !== section) {
      this.props.generalActions.setSelectedHeaderTab(urlSection);
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.history.location.pathname !== state.page) {
      return {
        page: props.history.location.pathname,
      };
    }
    return null;
  }

  render() {
    const { page } = this.state;
    const { history } = this.props;

    return (
      <div className="home-page-container flex-column">
        {page !== "/matchmaker-agreement" && (
          <MainHeader history={history} section="home-pages-white-theme" />
        )}

        <div
          className={`home-page-container__content ${page.replace("/", "")}`}
        >
          {HomePageIndex._renderFirstSection(
            FIRST_SECTION_ELEMENTS[page],
            history
          )}

          {HomePageIndex._renderHomePagesContent(page, history)}

          {page !== "/contact" &&
            page !== "/matchmaker-agreement" &&
            page !== "/talk-to-a-matchmaker" && (
              <MainFooter history={history} />
            )}
        </div>
      </div>
    );
  }

  static _renderFirstSection = (data, history) => {
    if (data.noShow) {
      return null;
    }
    return (
      <div className="section-one">
        <div className="section-title flex-column">
          <span>{data.text}</span>
        </div>
        <Button
          click={() => history.push({ pathname: data.pathname })}
          value={data.buttonValue}
          customClass="yellow large"
        />
        <div className="couple-info flex-column">
          <span>{data.coupleInfo.names}</span>
          <span>{data.coupleInfo.city}</span>
        </div>
      </div>
    );
  };

  static _renderHomePagesContent = (page, history) => {
    let content = "";
    switch (page) {
      case "/":
        content = <HomePage history={history} />;
        break;
      case "/for-singles":
        content = <SingleHomePage history={history} />;
        break;
      case "/for-matchmakers":
        content = <MatchmakersHomePage history={history} />;
        break;
      case "/contact":
        content = <Contact history={history} />;
        break;
      case "/faqs":
        content = <Faqs history={history} />;
        break;
      case "/terms-of-service":
        content = <TermsOfService history={history} />;
        break;
      case "/privacy-policy":
        content = <PrivacyPolicy history={history} />;
        break;
      case "/team":
        content = <Team history={history} />;
        break;
      case "/matchmaker-agreement":
        content = <MatchMakerAgreement history={history} />;
        break;
      case "/talk-to-a-matchmaker":
        content = <TalkToAMMPage history={history} />;
        break;
      default:
        break;
    }
    return content;
  };
}

HomePageIndex.propTypes = {
  history: PropTypes.object,
  generalActions: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return {
    generalActions: bindActionCreators(generalActions, dispatch),
  };
};

const mapStateToProps = (state) => {
  return {
    generalReducer: state.generalReducer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePageIndex);
