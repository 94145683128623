import React from "react";
import { styled, createGlobalStyle } from "styled-components";

const blue = {
  darkest: "#202B5D",
  dark: "#263F8F",
  mediumDark: "#1B45D5",
  mediumLight: "#3B65EF",
  light: "#8893CF",
  lightest: "#A8BCFD",
};

const grey = {
  darkest: "#222",
  dark: "#444",
  mediumDark: "#777",
  mediumLight: "#aaa",
  light: "#ccc",
  lightest: "#eee",
};

export const colors = {
  primary: blue,
  secondary: grey,
  blue,
  grey,
  // Here starts new colors
  white: "#ffffff",
  black: "#2E383A",
  primaryBlue: "#3FC6C0",
  secondaryBlue: "#013B4D",
  yellow: "#FFDA66",
  red:"#ff596f",
};

const theme = {
  colors: colors,
};

createGlobalStyle`
  body {
    /* background-color: ${theme.colors.primary}; */
  }
  /* a {
    color: ${theme.colors.primary.dark};
    &:hover, &:focus {
      text-decoration: none;
      color: ${theme.colors.primary.mediumLight};
    }
  }
  .btn {
    padding: 14px 28px;
    font-weight: 600;
    border-radius: 0;
    border: 0.5px solid #fff;
    &.btn-primary,
    &.btn-light {
      &&& {
        border: 0.5px solid #fff;
      }
    }
  }
  .btn-primary {
    background-color: ${theme.colors.primary.dark};
    color: #fff;
  }
  .btn-light {
    color: ${theme.colors.primary.dark};
    background-color: #fff;
  } */
`;

export default theme;
