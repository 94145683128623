.input-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .input-label {
    color: #303A42;
    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .input-field {
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    padding: 12px 6px;
    &::placeholder {
      color: rgba(48, 58, 66, 0.50);
      font-family: Montserrat;
      font-size: 19px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    &:focus {
      outline: none;
      border: 1px solid #303A42;
    }
  }


}