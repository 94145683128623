import UserSession from '_old/src/components/user-session';
import React, { useEffect } from 'react'
import "./Referrals.scss"
import trophy from "../../../images/trophy.png"
import * as matchMakerActions from '_old/src/actions/matchmakersActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const Referrals = ({ getMatchmakerReferrals, matchMakerReferrals }) => {
    const { ambassadorAccount } = UserSession.getToken();

    useEffect(() => {
        if (!matchMakerReferrals) {
            getMatchmakerReferrals();
        }
    }, [])


    return (
        <section className='referrals-section'>
            <div className='referrals-card'>
                <h2 className='title'>Refer To DateSpot and Everyone Wins!</h2>
                <div className='content'>
                    <div>
                        {ambassadorAccount ? <>
                            <p>
                                When a member you refer becomes a mutual match (turns into a
                                first date), you receive $100, which is 20% of the DateSpot
                                connection free. And of course, the more people you refer to
                                DateSpot, the more likely we are to refer people back to you!
                            </p>
                            <p>
                                For a referral to appear below, the new member needs to complete
                                a minimum percentage of their profile, including photos. If
                                someone you refer has signed up, and they do not appear below,
                                follow up with them or DateSpot to bring the profile to
                                completion for credit.
                            </p>
                        </> :
                            <>
                                <p>
                                    When a member you refer turns into a mutual match with a
                                    DateSpot client (where two people want to meet for a first
                                    date), you receive $100 of the intro fee that the DateSpot
                                    client pays.
                                </p>
                                <p>For a referral to count, the new member needs to: </p>
                                <ul>
                                    <li>Complete a minimum of 75% of their profile</li>
                                    <li>Upload real photo(s)</li>
                                    <li>Not be flagged as suspicious</li>
                                </ul>
                                <p>
                                    If someone you referred has signed up, and they do not appear
                                    below, follow up with them or DateSpot to bring the profile to
                                    completion so you're eligible for the bonus with that person.
                                </p>
                            </>}
                    </div>
                    <img src={trophy} alt='trophy' />
                </div>
                <div className='referrals-table'>
                    <h3>Referrals who signed up</h3>
                    <Table matchMakerReferrals={matchMakerReferrals} />
                </div>
            </div>
        </section>
    )
}

const Table = ({ matchMakerReferrals }) => {
    const getStatus = (clientStatus) => {
        return clientStatus === "client"
            ? "Client Member"
            : clientStatus === "candidate"
                ? "Candidate Member"
                : "--"
    }

    const getProfilePercent = (userProgress) => {
        const sections = Object.keys(userProgress);
        let sum = 0;
        for (const section of sections) {
            if (userProgress[section] > 100) {
                sum += 100;
            } else {
                sum += userProgress[section];
            }
        }
        return (sum / 400) * 100;
    };

    const formatDate = (date) => {
        return !date ? "--" : new Date(date).toLocaleDateString().replaceAll("/", "-")
    }

    const sortedReferrals = matchMakerReferrals ? [...matchMakerReferrals]?.sort((a, b) => (a.user_id - b.user_id)) : []

    return <>
        <table>
            <thead>
                <tr>
                    <th>Member #</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Signup Date</th>
                    <th>Member Type</th>
                    <th>Profile %</th>
                    <th># of Photos</th>
                    <th>Suspicious</th>
                </tr>
            </thead>
            <tbody>
                {matchMakerReferrals?.length > 0 ?
                    sortedReferrals.map((referral, index) => {
                        const { user_id, name, email, createdAt, clientStatus, suspicion, userProgress, images } = referral;
                        return <tr key={index}>
                            <td>{user_id ?? "--"}</td>
                            <td>{name}</td>
                            <td>{email}</td>
                            <td>{formatDate(createdAt)}</td>
                            <td>{getStatus(clientStatus)}</td>
                            <td>{userProgress ? `${getProfilePercent(userProgress)} %` : "--"}</td>
                            <td>{images?.length}</td>
                            <td>{suspicion?.profile ? "Yes" : "No"}</td>
                        </tr>
                    })
                    : <tr><td colSpan={8}>No Referrals</td></tr>}
            </tbody>
        </table>
        <div className='referrals-list'>
            {matchMakerReferrals?.length > 0 ? matchMakerReferrals.map((referral, index) => {
                const { user_id, name, email, createdAt, clientStatus, userProgress, images } = referral;
                return <div key={`user_${index}`} className='user'>
                    {images?.length > 0 && <img src={images[0]?.url} alt={name} />}
                    <div>
                        <h4>{name}</h4>
                        <p><b>ID:</b> {user_id}</p>
                        <p><b>Email:</b> {email}</p>
                        <p><b>Signup Date:</b> {formatDate(createdAt)}</p>
                        <p><b>Member Type:</b> {getStatus(clientStatus)}</p>
                        <p><b>Profile Completion:</b> {userProgress ? `${getProfilePercent(userProgress)} %` : "--"}</p>
                    </div>
                </div>
            }) : <p style={{ textAlign: "center" }}>No Referrals</p>
            }
        </div>
    </>
}

const mapDispatchToProps = (dispatch) => {
    const { getMatchmakerReferrals } = bindActionCreators(matchMakerActions, dispatch)
    return {
        getMatchmakerReferrals
    };
};

const mapStateToProps = (state) => {
    const { matchMakerReferrals } = state.matchmakersReducer;
    return {
        matchMakerReferrals
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Referrals);