import React, { useState } from "react";
import "./Tooltip.scss";
import PropTypes from "prop-types";
import { useMediaQuery } from "src/hooks";

/**
 *
 * @param {React.ReactElement} children - React element to be rendered inside the tooltip
 * @param {string} text - Text to be rendered inside the tooltip
 * @returns {React.ReactNode}
 */
const Tooltip = ({ children, text, className, popOverClassName }) => {
  const [isOpen, setIsOpen] = useState(false);
  const isTabletDown = useMediaQuery({ query: "(max-width: 768px)" });

  const iconElementAttributes = {
    onMouseEnter: !isTabletDown ? () => setIsOpen(true) : undefined,
    onMouseLeave: !isTabletDown ? () => setIsOpen(false) : undefined,
    onClick: isTabletDown ? () => setIsOpen(!isOpen) : undefined,
  };

  return (
    <div className={`tooltip ${className}`}>
      <span>
        <span className="icon-question-mark" {...iconElementAttributes} />
      </span>
      {isOpen && (
        <span
          className={`tooltip__popover${isOpen ? " open" : ""} ${popOverClassName}`}
          onClick={(e) => e.stopPropagation()}
        >
          {children || <span className="tooltip__text">{text}</span>}
        </span>
      )}
    </div>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string,
};

export default Tooltip;
