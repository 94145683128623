import Button from "_old/src/components/button";
import React, { useEffect } from "react";
import * as adminActions from "_old/src/actions/adminActions";

export default function PlanList({ history, adminReducer }) {
    const { plans } = adminReducer;
    useEffect(() => {
        if (!plans) {
            adminActions.loadPlans();
        }
    }, []);

    if (!plans) {
        return <div className="ds-loader" />
    }

    return (
        <div className="users-list-container flex-column flex-column">
            <Button
                value={"Add new"}
                customClass={"float-right"}
                click={() => history.push("/plan-details")}
            />
            <div className="users-list plan">
                {plans.map((plan, key) => {
                    return (
                        <div className="users-list__item flex-row" key={key} style={{ padding: "0 10px" }}>
                            <div className="flex-row  user-info" style={{ flex: "1" }}>
                                <div style={{ width: "40%" }}>
                                    <span className="name">{plan.name}&nbsp;</span>
                                    <span>{plan.shortDesc ? `(${plan.shortDesc})` : ""}</span>
                                </div>
                                <div style={{ fontWeight: "bold", width: "20%" }}>
                                    {plan.freeTrial ? "Free Trial" : `$${plan.price} / mo`}
                                </div>
                                <div>
                                    {plan.connections === Number.MAX_SAFE_INTEGER
                                        ? "Unlimited"
                                        : plan.connections}{" "}
                                    connections
                                </div>
                            </div>
                            <Button
                                value="Edit"
                                customClass="yellow float-right"
                                click={() => history.push(`/plan-details?id=${plan.id}`)}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    )
}