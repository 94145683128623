import React from "react";
import PropTypes from "prop-types";

const TextArea = ({
  name,
  defaultValue = "",
  onChange = () => {},
  placeholder = "",
  className = "",
  afterLabel = "",
  rows = "",
  ...rest
}) => {
  return (
    <div className="text-box">
    <textarea
      name={name}
      className={`text-area${className ? ` ${className}` : ""}`}
      defaultValue={defaultValue}
      onChange={onChange}
      placeholder={placeholder}
      rows={ rows ? rows : "3" }
      {...rest}
    />
    {afterLabel && <span className="helper-text">{afterLabel}</span>}
    </div>
  );
};

TextArea.propTypes = {
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

export default TextArea;
