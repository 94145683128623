import React, { Component } from "react";
import PropTypes from "prop-types";
import PhotoSection from "../../../welcome-onboarding/photo-section/photo-section";

class Photos extends Component {
  render() {
    const {
      user,
      generalActions,
      saveUserFieldData,
      calculatePercentage,
      reloadUserData,
      setSectionFromNextButtons,
      history,
      isTabletDown,
    } = this.props;
    return (
      <div className="photos">
        <PhotoSection
          userId={user._id}
          user={user}
          history={history}
          files={user.images}
          saveUserFieldData={saveUserFieldData}
          calculatePercentage={calculatePercentage}
          setSectionFromNextButtons={setSectionFromNextButtons}
          allowsPhotos={user.allowsPhotos}
          reloadUserData={reloadUserData}
          changedPhotos={user.changedPhotos}
          generalActions={generalActions}
          isTabletDown={isTabletDown}
        />
      </div>
    );
  }
}

Photos.propTypes = {
  user: PropTypes.object.isRequired,
  history: PropTypes.object,
  generalActions: PropTypes.object.isRequired,
  saveUserFieldData: PropTypes.func,
  calculatePercentage: PropTypes.func,
  setSectionFromNextButtons: PropTypes.func,
  reloadUserData: PropTypes.func,
};

export default Photos;
